import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select, TreeSelect } from 'antd';
import { IAgent } from 'interfaces/agents';
import { IRole } from 'interfaces/roles';
import { treePermissions } from 'services/permissions';

export const AgentCreate = () => {
  const { formProps, saveButtonProps } = useForm<IAgent>();

  const { selectProps: roleSelectProps } = useSelect<IRole>({
    resource: 'roles',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 20,

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar usuário">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Login"
          name="login"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Senha"
          name="password"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Grupos" name="role_ids">
          <Select {...roleSelectProps} mode="multiple" />
        </Form.Item>

        <Form.Item label="Permissões" name="permissions">
          <TreeSelect
            treeData={treePermissions()}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_PARENT}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
