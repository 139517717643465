import * as React from 'react';

const Logotype = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 540"
    {...props}
    fill="white"
  >
    <path d="M909.84,247.68a97.54,97.54,0,0,0-89.9-135.56h0a97.58,97.58,0,1,0,0,195.15h0a97.26,97.26,0,0,0,57-18.41l18.41,18.41L906,296.65l11.5-11.5-18.41-18.41A97.15,97.15,0,0,0,909.84,247.68ZM819.94,276h0a66.32,66.32,0,0,1,0-132.63h0a66.32,66.32,0,1,1,0,132.63Z" />
    <path d="M886.18,330.31a66.32,66.32,0,0,1-132.63,0H722.29a97.58,97.58,0,1,0,195.15,0H886.18Z" />
    <path d="M264.09,277.43q0,16.67-14.58,23.74-15,7.29-38.1,7.29-20.61,0-35-6-14-5.84-14-20.61A23.25,23.25,0,0,1,177.06,260a19.56,19.56,0,0,1-7.29-6.77,17.71,17.71,0,0,1-2.71-9.68,21.42,21.42,0,0,1,3.34-11.45q3.33-5.42,10.61-7.5a37.07,37.07,0,0,1-12.7-28.73,39.17,39.17,0,0,1,3.23-16,37.85,37.85,0,0,1,9-12.6A39.91,39.91,0,0,1,194.34,159a52.49,52.49,0,0,1,17.91-2.92A49.9,49.9,0,0,1,236,161.67a23.8,23.8,0,0,1,9.37-9.16,28.39,28.39,0,0,1,14-3.33l3.12.21v14.78a14.82,14.82,0,0,0-3.33-.21q-8.32,0-13.53,5.21,10.2,10.83,10.2,26.65a39.58,39.58,0,0,1-3.12,15.93,35.9,35.9,0,0,1-8.85,12.38,40.82,40.82,0,0,1-13.74,8.12,51.65,51.65,0,0,1-17.8,2.92,54.94,54.94,0,0,1-19.58-3.54q-5.41,0-8.22,2.39a8.45,8.45,0,0,0-.21,12.39q2.61,2.51,8.43,2.5h37.48q16.24,0,25.09,7.7T264.09,277.43Zm-16.87.21q0-7.08-5.1-10.72t-14.05-3.64H190.18q-6.47,1.25-9.58,5.31a14.78,14.78,0,0,0-3.13,9.26A12.63,12.63,0,0,0,180,286a16.43,16.43,0,0,0,7.08,4.89,41.94,41.94,0,0,0,10.93,2.4q6.36.63,14.06.62,17.07,0,26.13-3.33T247.22,277.64ZM185,196a24.42,24.42,0,0,0,7.18,18q7.18,7.17,19.68,7.18,12.69,0,20-7.18a24.27,24.27,0,0,0,7.28-18q0-11.25-7.28-18.64T212,170q-12.49,0-19.78,7.39T185,196Z" />
    <path d="M327.59,157.51a45.93,45.93,0,0,1,10.83,1.25v15.82a50.11,50.11,0,0,0-10.83-1.25q-13.12,0-20.4,8.12t-7.29,22.49v56.21H282.62V159h16l.83,12.7a22.4,22.4,0,0,1,10.93-10.52A39.23,39.23,0,0,1,327.59,157.51Z" />
    <path d="M432.94,260.15q-.62-3.33-1-6.87t-.63-7.28a29.78,29.78,0,0,1-13,12.28,43.51,43.51,0,0,1-19.88,4.37q-16.67,0-28.53-10Q358,242.25,358,220V158.76h17.28v58.57a40.46,40.46,0,0,0,2.08,13.82,24.09,24.09,0,0,0,5.73,9.24,22.32,22.32,0,0,0,8.43,5.19,30.82,30.82,0,0,0,10.2,1.67q12.71,0,20.93-8.42t8.22-22.74V158.76h17.28v82.86q0,4.38.52,9.69a70.64,70.64,0,0,0,1.35,8.84Z" />
    <path d="M533.5,262.65a47.87,47.87,0,0,1-22-5,34.67,34.67,0,0,1-15.1-14.36v61.63H480.2V158.76H495l1,17.07a33.7,33.7,0,0,1,14.47-14.57q9.69-5.2,22.8-5.21a50.22,50.22,0,0,1,20,3.85,42.82,42.82,0,0,1,15.2,10.83,49.13,49.13,0,0,1,9.68,16.86,70.54,70.54,0,0,1,0,43.52A48.09,48.09,0,0,1,568.37,248a44,44,0,0,1-15.19,10.83A48.8,48.8,0,0,1,533.5,262.65Zm30.82-53.3q0-17.07-9-27.48t-24.15-10.41q-15.62,0-24.57,10.41t-8.95,27.48a48.67,48.67,0,0,0,2.29,15.3,34.06,34.06,0,0,0,6.66,12,30.65,30.65,0,0,0,10.62,7.8,33.5,33.5,0,0,0,14,2.82,32.32,32.32,0,0,0,13.64-2.82,30.79,30.79,0,0,0,10.51-7.8,34.08,34.08,0,0,0,6.67-12A49,49,0,0,0,564.32,209.35Z" />
    <path d="M649.68,156.05a52.53,52.53,0,0,1,20.92,4.06,48.1,48.1,0,0,1,16.24,11.24,51.2,51.2,0,0,1,10.52,17,61,61,0,0,1,0,42.06,51.1,51.1,0,0,1-10.52,17,48,48,0,0,1-16.24,11.24,56,56,0,0,1-41.85,0,48.05,48.05,0,0,1-16.24-11.24,51.08,51.08,0,0,1-10.51-17,60.84,60.84,0,0,1,0-42.06,51.18,51.18,0,0,1,10.51-17,48.19,48.19,0,0,1,16.24-11.24A52.54,52.54,0,0,1,649.68,156.05Zm-.11,91.2q15.45,0,24.85-10.62t9.4-27.28q0-16.44-9.4-27.07-9.39-10.82-24.85-10.82-15.24,0-24.64,10.82-9.4,10.62-9.4,27.07,0,16.67,9.4,27.28T649.57,247.25Z" />
  </svg>
);

export default Logotype;
