import DocumentationAssociation from 'pages/graduate_courses/documentations/documentation_association/edit';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'documentation_association',
  label: 'Associação de documentação',
  list: DocumentationAssociation,
  actions: ['list'],
  parentName: 'secretary',
  hide: true
};

export default generateResources(resource);
