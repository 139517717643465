import { Col, Row, Typography } from 'antd';
import { EVALUATION_KIND_MAP } from 'services/graduate-enrollment/evaluations';
import { IClassroomEvaluation } from 'interfaces/classrooms';
import { TextField } from '@refinedev/antd';

export interface ParentEvaluationInfoProps {
  evaluationData?: IClassroomEvaluation;
}

const ParentEvaluationInfo = ({
  evaluationData
}: ParentEvaluationInfoProps) => {
  if (!evaluationData) {
    return null;
  }

  return (
    <Col>
      <Typography.Title level={5}>Informações da 1º chamada</Typography.Title>
      <Row gutter={[16, 16]} className="mb-4">
        <Col md={8}>
          <Row>
            <Typography.Title
              level={5}
              style={{
                color: '#626262',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            >
              Tipo
            </Typography.Title>
          </Row>
          <Row>
            <TextField
              value={EVALUATION_KIND_MAP[evaluationData.evaluation_kind]}
            />
          </Row>
        </Col>
        <Col md={8}>
          <Row>
            <Typography.Title
              level={5}
              style={{
                color: '#626262',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            >
              Avaliação
            </Typography.Title>
          </Row>
          <Row>
            <TextField value={evaluationData.evaluation_name} />
          </Row>
        </Col>
        <Col md={8}>
          <Row>
            <Typography.Title
              level={5}
              style={{
                color: '#626262',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            >
              Nome
            </Typography.Title>
          </Row>
          <Row>
            <TextField value={evaluationData.name} />
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={8}>
          <Row>
            <Typography.Title
              level={5}
              style={{
                color: '#626262',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            >
              Peso
            </Typography.Title>
          </Row>
          <Row>
            <TextField value={evaluationData.weight ?? 'Não aplica'} />
          </Row>
        </Col>
        <Col md={8}>
          <Row>
            <Typography.Title
              level={5}
              style={{
                color: '#626262',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            >
              Nota mínima
            </Typography.Title>
          </Row>
          <Row>
            <TextField value={evaluationData.passing_score} />
          </Row>
        </Col>
        <Col md={8}>
          <Row>
            <Typography.Title
              level={5}
              style={{
                color: '#626262',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            >
              Tentativas
            </Typography.Title>
          </Row>
          <Row>
            <TextField value={evaluationData.max_attempts} />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ParentEvaluationInfo;
