import * as Icons from '@ant-design/icons';
import EquivalencyDetails from './components/show/EquivalencyDetails';
import EquivalencyDocumentModal from '../../../components/modal/equivalencies/EquivalencyDocumentModal';
import React, { useState } from 'react';
import { AcademicRecordDetails } from './components/show/AcademicRecordDetails';
import {
  ApproveEquivalenceData,
  ApproveModal
} from 'components/modal/equivalencies/ApproveModal';
import {
  Button,
  Divider,
  Space,
  Table,
  TableColumnProps,
  Typography
} from 'antd';
import {
  IEquivalency,
  ISolicitationsDocument
} from '../../../interfaces/equivalency';
import { RejectModal } from 'components/modal/equivalencies/RejectModal';
import { Show, useModal } from '@refinedev/antd';
import { SolicitationDetails } from './components/show/SolicitationDetails';
import { StudentInfo } from './components/show/StudentInfo';
import { getAPI } from '../../../requests/api';
import { useNotification, useShow } from '@refinedev/core';

const baseUrl = '/admin/solicitations/equivalencies';

export const EquivalencyShow = () => {
  const { queryResult } = useShow<IEquivalency>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const documents = record?.documents || [];

  const { show, modalProps } = useModal();
  const notification = useNotification();

  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [approveModalVisible, setApproveModalVisible] = useState(false);
  const [documentId, setDocumentId] = useState<string>('');

  const api = getAPI();

  const handleRejectModal = () => {
    setRejectModalVisible(true);
  };

  const handleApproveModal = () => {
    setApproveModalVisible(true);
  };

  const handleCloseRejectModal = () => {
    setRejectModalVisible(false);
  };

  const handleCloseApproveModal = () => {
    setApproveModalVisible(false);
  };

  const handleSubmitReject = async (feedback: string) => {
    try {
      const endpoint = `${baseUrl}/${record?.id}/reject`;
      await api.post(endpoint, { feedback });

      notification.open?.({
        message: '',
        type: 'success',
        description: 'Rejeição realizada com sucesso.'
      });
      handleCloseRejectModal();
      refetch();
    } catch {
      notification.open?.({
        message: 'Ops! Algo deu errado.',
        type: 'error',
        description: 'Não foi possível rejeitar a solicitação. Tente novamente.'
      });
    }
  };

  const handleSubmitApprove = async (data: ApproveEquivalenceData) => {
    try {
      const endpoint = `${baseUrl}/${record?.id}/approve`;
      await api.post(endpoint, data);

      notification.open?.({
        message: 'Aproveitamento está sendo processado.',
        type: 'success',
        description: 'Aprovação realizada com sucesso.'
      });
      handleCloseApproveModal();
      refetch();
    } catch {
      notification.open?.({
        message: 'Ops! Algo deu errado.',
        type: 'error',
        description: 'Não foi possível aprovar a solicitação. Tente novamente.'
      });
    }
  };

  const columns: TableColumnProps<ISolicitationsDocument>[] = [
    {
      title: 'Nome do Arquivo',
      dataIndex: 'file_name',
      key: 'file_name'
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (_, document: ISolicitationsDocument) => (
        <Button
          size={'small'}
          className="mr-2"
          onClick={() => {
            setDocumentId(document.id);
            show();
          }}
        >
          <Icons.EyeOutlined />
          Visualizar
        </Button>
      )
    }
  ];

  if (!isLoading && !record) {
    <Show isLoading={isLoading} title="Análise do Aproveitamento">
      <Typography.Text>Nenhuma informação encontrada</Typography.Text>
    </Show>;
  }

  return (
    <Show title="Análise do Aproveitamento" isLoading={isLoading}>
      {record && (
        <>
          <Divider orientation="left" orientationMargin="0">
            Informações do Aluno
          </Divider>

          <StudentInfo
            user={record?.course_enrollment.user}
            course={record?.course_enrollment.course}
          />

          <Divider orientation="left" orientationMargin="0" className="!pt-4">
            Informações da Solicitação
          </Divider>

          <SolicitationDetails solicitation={record} />

          <Divider orientation="left" orientationMargin="0" className="!pt-4">
            Dados do Formulário
          </Divider>

          <EquivalencyDetails solicitable={record?.solicitable} />

          {record?.academic_records_equivalency && (
            <>
              <Divider
                orientation="left"
                orientationMargin="0"
                className="!pt-4"
              >
                Dados do Histórico Escolar
              </Divider>

              <AcademicRecordDetails
                record={record?.academic_records_equivalency}
              />
            </>
          )}

          <Divider orientation="left" orientationMargin="0" className="!pt-4">
            Anexos
          </Divider>

          {documents && (
            <>
              <Table
                dataSource={documents}
                columns={columns}
                size="small"
                pagination={false}
                style={{ padding: 8 }}
              />
            </>
          )}

          <Divider />
          {record?.status === 'submitted' && (
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              <Button
                danger
                icon={<Icons.CloseOutlined />}
                onClick={handleRejectModal}
              >
                Rejeitar
              </Button>
              <Button
                type="primary"
                icon={<Icons.CheckOutlined />}
                onClick={handleApproveModal}
              >
                Aprovar
              </Button>
            </Space>
          )}

          <EquivalencyDocumentModal
            {...modalProps}
            bodyTitle="Anexos do Aproveitamento"
            documentId={documentId}
          />

          <RejectModal
            visible={rejectModalVisible}
            onCancel={handleCloseRejectModal}
            onSubmit={handleSubmitReject}
          />

          <ApproveModal
            data={record?.solicitable}
            visible={approveModalVisible}
            onCancel={handleCloseApproveModal}
            onSubmit={handleSubmitApprove}
          />
        </>
      )}
    </Show>
  );
};
