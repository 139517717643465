import { Col, Row, Typography } from 'antd';
import { IProfessor } from 'interfaces/professor';
import { ShowButton } from '@refinedev/antd';

const { Text, Title } = Typography;

export interface InstructorInfoProps {
  professorData: IProfessor;
}

const InstructorInfo = ({ professorData }: InstructorInfoProps) => {
  return (
    <>
      {professorData?.instructor ? (
        <Row className="border rounded p-2">
          <Title level={5} style={{ margin: '0 0 8px 0' }}>
            Perfil do instrutor selecionado
          </Title>
          <ShowButton
            size="small"
            resource="instructors"
            recordItemId={professorData?.instructor?.id}
            className="ml-4"
          >
            Visualizar
          </ShowButton>
          <Row gutter={[16, 16]} className="w-full mb-1">
            <Col>
              <Row>
                <Text style={{ fontWeight: 'bold' }}>Nome</Text>
              </Row>
              <Row className="ml-1">
                <Text>{professorData?.instructor?.name}</Text>
              </Row>
            </Col>
            <Col>
              <Row>
                <Text style={{ fontWeight: 'bold' }}>Slug</Text>
              </Row>
              <Row className="ml-1">
                <Text>
                  {professorData?.instructor?.slug ?? 'Não Informado'}
                </Text>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="w-full">
            <Col>
              <Row>
                <Text style={{ fontWeight: 'bold' }}>Biografia</Text>
              </Row>
              <Row className="ml-1">
                <Text>{professorData?.instructor?.bio}</Text>
              </Row>
            </Col>
          </Row>
        </Row>
      ) : (
        <Row gutter={[16, 16]} className="w-full">
          <Col>
            <Row>
              <Text style={{ fontWeight: 'bold' }}>Perfil do instrutor</Text>
            </Row>
            <Row>
              <Text>Nenhum perfil de instrutor selecionado</Text>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default InstructorInfo;
