import axios, { AxiosInstance } from 'axios';
import { ISession } from 'interfaces/auth';
import { ITenant } from 'interfaces/tenants';
import { getTenant } from 'services/tenants';

const apiMap = new Map<string, AxiosInstance>();

export const getAPI = () => {
  const tenant = getTenant();

  const api = apiMap.get(tenant.name) || generateAPI(tenant);
  apiMap.set(tenant.name, api);

  return api;
};

const generateAPI = (tenant: ITenant) =>
  axios.create({
    baseURL: tenant.apiUrl
  });

interface loginProps {
  username: string;
  password: string;
}

interface refreshProps {
  refreshToken: string;
}

interface googleProps {
  code: string;
  signupPage: string;
}

export const login = async ({
  username,
  password
}: loginProps): Promise<ISession> => {
  const { data } = await getAPI().post('/admin/auth/login', {
    username,
    password
  });

  return {
    token: data.token,
    refreshToken: data.refresh_token
  };
};

export const refresh = async ({
  refreshToken
}: refreshProps): Promise<ISession> => {
  const { data } = await getAPI().post('/admin/auth/refresh', {
    token: refreshToken
  });

  return {
    token: data.token,
    refreshToken: data.refresh_token
  };
};

export const google = async ({
  code,
  signupPage
}: googleProps): Promise<ISession> => {
  const { data } = await getAPI().post('/admin/auth/google', {
    code,
    signup_page: signupPage
  });

  return {
    token: data.token,
    refreshToken: data.refresh_token
  };
};
