import 'react-quill/dist/quill.snow.css';
import AssetUpload from 'components/AssetUpload';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { ITestimonial } from 'interfaces/testimonials';

export const TestimonialEdit = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ITestimonial>({
    redirect: 'edit'
  });

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar depoimento">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Imagem para Depoimento"
          name={['image', 'testimonial_section_image']}
          rules={[
            {
              required: false
            }
          ]}
        >
          <AssetUpload
            accept="image/png, image/jpeg"
            type="testimonial"
            src={queryResult?.data?.data?.image?.testimonial_section_image}
          />
        </Form.Item>

        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Subtítulo"
          name="subtitle"
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Edit>
  );
};
