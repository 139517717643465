export * from './list';
export * from './create';
export * from './show';

const EQUIVALENCY_STATUS_MAP = {
  failed: 'Reprovado',
  approved: 'Aprovado',
  exempted: 'Isento',
  unknown: 'Desconhecido'
};

export const translateEquivalencyStatus = (text: string): string => {
  const statusKey = text as keyof typeof EQUIVALENCY_STATUS_MAP;
  return EQUIVALENCY_STATUS_MAP[statusKey] ?? EQUIVALENCY_STATUS_MAP['unknown'];
};

const ENROLLMENT_STATUS_MAP = {
  inactive: 'Inativo',
  active: 'Ativo',
  cancelled: 'Cancelado',
  unknown: 'Desconhecido'
};

export const translateEnrollmentStatus = (text: string): string => {
  const statusKey = text as keyof typeof ENROLLMENT_STATUS_MAP;
  return ENROLLMENT_STATUS_MAP[statusKey] ?? ENROLLMENT_STATUS_MAP['unknown'];
};

const CLASSROOM_ENROLLMENT_STATUS_MAP = {
  inactive: 'Inativa',
  active: 'Ativa',
  cancelled: 'Cancelada',
  unknown: 'Desconhecido'
};

export const translateClassroomEnrollmentStatus = (text: string): string => {
  const statusKey = text as keyof typeof CLASSROOM_ENROLLMENT_STATUS_MAP;
  return (
    CLASSROOM_ENROLLMENT_STATUS_MAP[statusKey] ??
    CLASSROOM_ENROLLMENT_STATUS_MAP['unknown']
  );
};

const CLASSROOM_ENROLLMENT_KIND_MAP = {
  regular: 'Regular',
  replacement: 'Substituição',
  unknown: 'Desconhecido'
};

export const translateClassroomEnrollmentKind = (text: string): string => {
  const statusKey = text as keyof typeof CLASSROOM_ENROLLMENT_KIND_MAP;
  return (
    CLASSROOM_ENROLLMENT_KIND_MAP[statusKey] ??
    CLASSROOM_ENROLLMENT_KIND_MAP['unknown']
  );
};
