import { IInstructor } from 'interfaces/instructors';
import { Show } from '@refinedev/antd';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const InstructorShow = () => {
  const { queryResult } = useShow<IInstructor>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar instrutor">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Nome</Title>
      <Text>{record?.name}</Text>
      <Title level={5}>Avatar</Title>
      <Text>{record?.avatar}</Text>
      <Title level={5}>Cabeçalho</Title>
      <Text>{record?.headline}</Text>
      <Title level={5}>Biografia</Title>
      <Text>{record?.bio}</Text>
    </Show>
  );
};
