import { ClassroomCard } from './components/ClassroomCards';
import { Empty, Form, Pagination, Radio, Row, Space } from 'antd';
import { HttpError } from '@refinedev/core';
import { IProfessorClassroom } from 'interfaces/professor_area/classrooms';
import { List, useTable } from '@refinedev/antd';
import { useState } from 'react';

interface IFilter {
  by_status: string;
}

export const ClassroomList = () => {
  const [filter, setFilter] = useState<'active' | 'not_started' | 'finished'>(
    'active'
  );
  const { searchFormProps, tableQueryResult } = useTable<
    IProfessorClassroom,
    HttpError,
    IFilter
  >({
    filters: {
      initial: [
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: 'active'
        }
      ]
    },
    onSearch: ({ by_status }) => {
      return [
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: by_status
        }
      ];
    }
  });

  const classroomsList =
    tableQueryResult?.data?.data ?? ([] as IProfessorClassroom[]);

  if (!classroomsList) {
    return null;
  }

  return (
    <List>
      <Space direction="vertical" className="w-full !gap-4">
        <Form {...searchFormProps}>
          <Form.Item name="by_status">
            <Radio.Group
              buttonStyle="solid"
              defaultValue={filter}
              onChange={e => {
                searchFormProps.form?.submit();
                setFilter(e.target.value);
              }}
            >
              <Radio.Button value="active">Em andamento</Radio.Button>
              <Radio.Button value="not_started">Não iniciadas</Radio.Button>
              <Radio.Button value="finished">Finalizadas</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>

        <div className="grid md:grid-cols-3 gap-2">
          {classroomsList?.map(classroom => (
            <ClassroomCard key={classroom.id} classroom={classroom} />
          ))}
        </div>
        {classroomsList && classroomsList?.length < 1 && (
          <Row className="w-full flex justify-center">
            <Empty description="Você não possui nenhuma turma vinculada ao seu registro." />
          </Row>
        )}
        <Pagination
          className="justify-self-end"
          hideOnSinglePage
          total={tableQueryResult.data?.total}
          pageSize={12}
        />
      </Space>
    </List>
  );
};
