import { Form, Input, InputNumber, Modal } from 'antd';
import { IAcademicRecord } from 'interfaces/academic_record';
import { useCallback, useState } from 'react';
import { useModalReturnType, useUpdate } from '@refinedev/core';

interface AcademicRecordEditProps {
  modal: useModalReturnType;
  academicRecord: IAcademicRecord;
}

export const AcademicRecordEdit = ({
  modal,
  academicRecord
}: AcademicRecordEditProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { mutateAsync: academicRecordMutate } = useUpdate<IAcademicRecord>();

  const onMutateScore = useCallback(async () => {
    await academicRecordMutate({
      resource: 'graduate/academic_records',
      id: academicRecord.id,
      values: {
        score: Number(form.getFieldValue('score')),
        justification: form.getFieldValue('justification')
      },
      errorNotification: () => {
        return {
          type: 'error',
          description: 'Ops! Algo de errado',
          message: 'Não foi possível editar a nota'
        };
      },
      successNotification: () => ({
        type: 'success',
        description: 'Sucesso!',
        message: 'Nota alterada com sucesso'
      })
    });

    modal.close();
    form.resetFields();
  }, [academicRecord.id, academicRecordMutate, form, modal]);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await form.validateFields();
      await onMutateScore();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [form, onMutateScore]);

  return (
    <Modal
      visible={modal.visible}
      onCancel={modal.close}
      onOk={onSubmit}
      confirmLoading={loading}
      title="Editar nota do histórico acadêmico"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nota"
          name="score"
          initialValue={academicRecord?.score}
          rules={[
            {
              required: true,
              message: 'Campo obrigatório'
            },
            {
              validator(rule, value, callback) {
                if (value === academicRecord.score) {
                  callback('A nota deve ser diferente da atual');
                }

                callback();
              }
            }
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Justificativa"
          name="justification"
          rules={[
            {
              required: true,
              message: 'Por favor, insira uma justificativa'
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
