/* eslint-disable unicorn/filename-case */
export const helpIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.06065 6.00065C6.21739 5.5551 6.52675 5.17939 6.93395 4.94007C7.34116 4.70076 7.81991 4.61328 8.28544 4.69313C8.75096 4.77297 9.1732 5.015 9.47737 5.37634C9.78154 5.73767 9.94802 6.195 9.94732 6.66732C9.94732 8.00065 7.94732 8.66732 7.94732 8.66732M8.00065 11.334H8.00732M14.6673 8.00065C14.6673 11.6826 11.6826 14.6673 8.00065 14.6673C4.31875 14.6673 1.33398 11.6826 1.33398 8.00065C1.33398 4.31875 4.31875 1.33398 8.00065 1.33398C11.6826 1.33398 14.6673 4.31875 14.6673 8.00065Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
