import { Col, FormProps, Row, Table, TableProps } from 'antd';
import {
  IAssessmentAttempt,
  IAssessmentQuestion
} from 'interfaces/assessments';
import { ShowButton, TagField, TextField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateAssessmentAttemptStatus } from 'services/assessments';

interface IFilter {
  assessment_id: string;
  student: string;
  email: string;
}

interface AssessmentQuestionListProps {
  searchFormProps: FormProps<IFilter>;
  tableProps: TableProps<IAssessmentAttempt>;
}

export const ObjectiveAttemptsTable = ({
  tableProps
}: AssessmentQuestionListProps) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex={['user', 'name']}
              key="student"
              title="Aluno"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex={['user', 'email']}
              key="email"
              title="E-mail do aluno"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              title="Iniciada em"
              dataIndex="created_at"
              key="created_at"
              render={value =>
                value ? (
                  <TextField value={formatDate(value, 'DD/MM/YYYY')} />
                ) : (
                  '--'
                )
              }
            />

            <Table.Column
              title="Finalizada em"
              dataIndex="completed_at"
              key="completed_at"
              render={value =>
                value ? (
                  <TextField value={formatDate(value, 'DD/MM/YYYY')} />
                ) : (
                  '--'
                )
              }
            />

            <Table.Column
              dataIndex="score"
              key="score"
              title="Nota"
              align="center"
              render={value => <TextField value={value ?? '--'} />}
            />

            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={value => (
                <TagField
                  color={tagColorByStatus(value)}
                  value={translateAssessmentAttemptStatus(value)}
                />
              )}
            />

            <Table.Column
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record: IAssessmentQuestion) => {
                return (
                  <ShowButton
                    resource="assessments/attempts"
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                );
              }}
            />
          </Table>
        </Col>
      </Row>
    </>
  );
};
