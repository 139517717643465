import FilterForm from 'components/forms/FilterForm';
import { Form, Select, Table, Typography } from 'antd';
import { HttpError } from '@refinedev/core';
import { IClassroom } from 'interfaces/classrooms';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { TextField, useSelect, useTable } from '@refinedev/antd';
import { useParams } from 'react-router-dom';

interface IFilter {
  courseId: string;
}

const CourseTypeMap = {
  'Graduate::Course': 'Curso regulado',
  Course: 'Curso livre'
};

const CoursesList = () => {
  const { id } = useParams();
  const { tableProps, searchFormProps } = useTable<
    IClassroom,
    HttpError,
    IFilter
  >({
    resource: `professors/courses`,

    onSearch: ({ courseId }) => {
      return [
        {
          field: 'filter[by_course_id]',
          operator: 'eq',
          value: courseId
        },
        {
          field: 'filter[by_professor_id]',
          operator: 'eq',
          value: id
        }
      ];
    },

    filters: {
      initial: [
        {
          field: 'filter[by_professor_id]',
          operator: 'eq',
          value: id
        }
      ]
    }
  });

  const { selectProps: courseSelectProps } = useSelect<IGraduateCourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Coordenação de cursos
      </Typography.Title>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item
          colon
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
          label="Curso"
          name="courseId"
        >
          <Select
            allowClear
            {...courseSelectProps}
            style={{ minWidth: 250 }}
            placeholder="Selecione ou busque o curso"
          />
        </Form.Item>
      </FilterForm>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="course_type"
          key="course_type"
          title="Tipo do Curso"
          render={value => (
            <TextField
              value={CourseTypeMap[value as keyof typeof CourseTypeMap]}
            />
          )}
        />
        <Table.Column
          dataIndex="course"
          key="course"
          title="Curso"
          render={value => <TextField value={value.name} />}
        />
      </Table>
    </>
  );
};

export default CoursesList;
