import { getAPI } from 'requests/api';

const ATTEMPTS_MAP = new Map([
  ['unknown', 'Desconhecida'],
  ['started', 'Iniciada'],
  ['evaluation_started', 'Em correção'],
  ['pending_evaluation', 'Aguardando correção'],
  ['evaluated', 'Corrigida'],
  ['passed', 'Aprovada'],
  ['failed', 'Reprovada'],
  ['scored', 'Nota calculada']
]);

export const translateAttemptStatus = (status: string) => {
  return ATTEMPTS_MAP.get(status);
};

export const fetchAssignmentsAttemptsLogs = async (attemptId: string) => {
  const { data } = await getAPI().get(
    `/admin/assignments/attempts/${attemptId}/logs`
  );

  return data;
};
