import {
  ProductBundleCreate,
  ProductBundleList,
  ProductBundleShow
} from 'pages/ecommerce/product_bundles';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'products/bundles',
  label: 'Combos',
  list: ProductBundleList,
  create: ProductBundleCreate,
  show: ProductBundleShow,
  actions: ['list', 'create', 'show'],
  parentName: 'ecommerce'
};

export default generateResources(resource);
