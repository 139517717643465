import { Button, Col, Dropdown, Row, Tag, Typography } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { HybridInput } from './HybridInput';
import { IAgent } from 'interfaces/agents';
import { IPost } from 'interfaces/forum';
import { MenuInfo } from 'rc-menu/lib/interface';
import { formatDate } from 'services/date';
import { styled } from '@qcx/ui';
import { useCallback, useState } from 'react';
import { useUpdate } from '@refinedev/core';
import type { MenuProps } from 'antd';

export interface PostProps {
  post: IPost;
  refetch: () => void;
  user: IAgent | undefined;
  deleteComment: () => void;
}

export const Post = ({ post, user, refetch, deleteComment }: PostProps) => {
  const [editAnswer, setEditAnswer] = useState<boolean>(false);
  const { mutateAsync: editPost } = useUpdate();

  const items: MenuProps['items'] = [
    {
      label: 'Editar resposta',
      key: '1'
    },
    {
      label: 'Excluir resposta',
      key: '2',
      danger: true,
      disabled: user?.id !== post.author_id
    }
  ];

  const handleMenuClick = useCallback(
    (e?: MenuInfo) => {
      if (e?.key === '2') {
        return deleteComment();
      }
      setEditAnswer(!editAnswer);
    },
    [editAnswer, deleteComment]
  );

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  const handleEditPost = useCallback(
    (value: string) => {
      editPost({
        resource: `forums/${post?.forum_id}/posts`,
        id: post.id,
        values: {
          text: value
        },
        successNotification: () => ({
          type: 'success',
          description: 'Tudo certo!',
          message: 'Resposta editada com sucesso'
        }),
        errorNotification: () => {
          return {
            description: 'Algo deu errado',
            message: 'Não foi possível editar a resposta',
            type: 'error'
          };
        }
      }).then(() => {
        handleMenuClick();
        refetch();
      });
    },
    [editPost, handleMenuClick, refetch, post]
  );

  const getTag = () => {
    if (post.author?.professor && post.author_type === 'Admin::Agent') {
      return <Tag color="orange">Professor</Tag>;
    }

    if (post.author_type === 'Admin::Agent') {
      return <Tag color="orange">Time acadêmico</Tag>;
    }
    return null;
  };

  return (
    <Row className="w-full mb-4">
      <Row className="flex items-center justify-between w-full !text-neutral-500">
        <Col>
          <Row className="gap-2">
            {post.author.name}
            {getTag()}
            {post.deleted_at && (
              <Tag color="red">{`Excluído em ${formatDate(
                post?.deleted_at,
                'lll'
              )}`}</Tag>
            )}
          </Row>
          <Row>
            {formatDate(
              post.updated_at ?? post.created_at,
              'DD/MM/YYYY à[s] HH:mm'
            )}
          </Row>
        </Col>
        {!editAnswer && post?.author_id === user?.id && (
          <Col>
            <Dropdown menu={menuProps}>
              <Button type="text" icon={<EllipsisOutlined rotate={90} />} />
            </Dropdown>
          </Col>
        )}
      </Row>
      <Row className="mt-2 w-full">
        <HybridInput
          showInput={editAnswer}
          value={post?.text}
          handleState={handleMenuClick}
          handleMutation={handleEditPost}
        >
          <StyledParagraph>{post.text}</StyledParagraph>
        </HybridInput>
      </Row>
    </Row>
  );
};

const StyledParagraph = styled(Typography.Paragraph, {
  marginBottom: '0 !important',
  whiteSpace: 'pre-line'
});
