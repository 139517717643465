import LibraryContentUpload from 'components/LibraryContentUpload';
import { Form, Input, Modal } from 'antd';
import { IHtmlContainer } from 'interfaces/prometheus';
import { useForm } from '@refinedev/antd';
import { useModalReturnType } from '@refinedev/core';

interface CreateHtmlContainerModalProps {
  modal: useModalReturnType;
  libraryId: string;
  onFinish?: () => void;
}

const CreateHtmlContainerModal = ({
  modal,
  libraryId,
  onFinish
}: CreateHtmlContainerModalProps) => {
  const onClose = () => {
    modal.close();
  };

  const { form, formProps } = useForm<IHtmlContainer>({
    resource: `prometheus/v2/libraries/${libraryId}/html_containers`,
    onMutationSuccess: () => {
      modal.close();
      form.resetFields();
      onFinish?.();
    }
  });

  return (
    <Modal
      open={modal?.visible}
      title="Criar pasta de HTMLs"
      onCancel={onClose}
      onOk={form.submit}
    >
      <Form
        {...formProps}
        initialValues={{ library_id: libraryId }}
        layout="vertical"
      >
        <Form.Item name="library_id" hidden>
          <Input type="hidden" value={libraryId} />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Nome é obrigatório' }]}
        >
          <Input placeholder="Nome" />
        </Form.Item>
        <Form.Item
          label="Arquivo (zip)"
          name="download_url"
          rules={[{ required: true, message: 'Arquivo  é obrigatório' }]}
        >
          <LibraryContentUpload type="zip" accept="application/x-zip" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateHtmlContainerModal;
