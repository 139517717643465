import { Col, Row, Typography } from 'antd';
import { ICompany } from 'interfaces/companies';

const { Text } = Typography;

interface TabCompanyInfoProps {
  company: ICompany;
}

export const TabCompanyInfo = ({ company }: TabCompanyInfoProps) => {
  return (
    <div className="space-y-6">
      <InfoComponent title="Nome da empresa" data={company.name} />
      <InfoComponent title="CNPJ" data={company.cnpj} />
      <InfoComponent
        title="Inscrição Municipal"
        data={company.city_registration || '-'}
      />
      <InfoComponent
        title="Inscrição Estadual"
        data={company.state_registration || '-'}
      />
      <InfoComponent
        title="Nome do responsável"
        data={company.responsible_name || '-'}
      />
      <InfoComponent
        title="E-mail do responsável"
        data={company.responsible_email || '-'}
      />
      <InfoComponent
        title="Telefone do responsável"
        data={company.phone_number || '-'}
      />
      <InfoComponent title="Endereço" data={company.address || '-'} />
      <InfoComponent title="Observações" data={company.notes || '-'} />
    </div>
  );
};

const InfoComponent = ({ title, data }: { title: string; data: string }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col md={6}>
        <Text>{title}</Text>
      </Col>
      <Col md={16}>
        <Text strong>{data}</Text>
      </Col>
    </Row>
  );
};
