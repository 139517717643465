import { ICoreContentOption } from 'interfaces/core_content';

const OPTION_STATUS_TRANSLATIONS = new Map([
  ['disabled', 'Desativado'],
  ['enabled', 'Ativado'],
  ['processing', 'Processando'],
  ['error', 'Erro inesperado']
]);

export const translateOptionStatus = (
  status: ICoreContentOption['status']
): string => OPTION_STATUS_TRANSLATIONS.get(status) || status;
