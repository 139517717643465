import { IState } from 'interfaces/address';
import { Select, SelectProps } from 'antd';
import { useSelect } from '@refinedev/antd';

export const StateSelectField = (props: SelectProps) => {
  const { selectProps } = useSelect<IState>({
    resource: 'states',
    optionLabel: 'uf',
    optionValue: 'id',
    fetchSize: 100,
    liveMode: 'manual',

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Select {...selectProps} {...props} allowClear style={{ minWidth: 70 }} />
  );
};
