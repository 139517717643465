import { Card, Col, Row, Tabs, Typography } from 'antd';
import { ICompany } from 'interfaces/companies';
import { Show } from '@refinedev/antd';
import { TabCompanyContracts } from './components/TabCompanyContracts';
import { TabCompanyInfo } from './components/TabCompanyInfo';
import { useSearchParams } from 'react-router-dom';
import { useShow } from '@refinedev/core';

const { Text } = Typography;

export const CompanyShow = () => {
  const { queryResult } = useShow<ICompany>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const [searchParams, _] = useSearchParams();
  const currentTab = searchParams.get('tab');

  if (!record) {
    return null;
  }

  return (
    <Show isLoading={isLoading} title="Visualizar empresa parceira">
      <Card className="!mb-8">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Text>
              Empresa: <Text strong>{record?.name}</Text>
            </Text>
          </Col>
          <Col xs={24} md={12}>
            <Text>
              CNPJ: <Text strong>{record?.cnpj}</Text>
            </Text>
          </Col>
        </Row>
      </Card>

      <Tabs defaultActiveKey={currentTab ?? 'info'}>
        <Tabs.TabPane tab="Informações Gerais" key="info">
          <TabCompanyInfo company={record} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Contratos" key="contracts">
          <TabCompanyContracts company={record} />
        </Tabs.TabPane>
      </Tabs>
    </Show>
  );
};
