import PostModal from 'components/modal/PostModal';
import { Button, Divider, Table, Typography } from 'antd';
import { IDiscussionThread } from 'interfaces/discussion_threads';
import { Show, TagField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { tagColorByStatus } from '../../../utils/statusColorMapping';
import { translateDiscussionStatus } from '.';
import { useModal, useShow } from '@refinedev/core';

const { Title, Text } = Typography;

const onUpdate = () => window.location.reload();

export const DiscussionThreadShow = () => {
  const { queryResult } = useShow<IDiscussionThread>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const modal = useModal();

  return (
    <Show isLoading={isLoading} title="Visualizar dúvida">
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Title level={5}>Disciplina</Title>
          <Text>{record?.grandparent.name}</Text>

          <Title level={5}>Conteúdo</Title>
          <Text>{record?.parent_name || 'Conteúdo Excluído'}</Text>
        </div>

        <div style={{ flex: 1 }}>
          {record?.parent_name && (
            <>
              <Title level={5}> Status</Title>
              <TagField
                color={tagColorByStatus(record?.status ?? 'unknown')}
                value={translateDiscussionStatus(record?.status ?? 'unknown')}
              />
            </>
          )}
        </div>
      </div>
      <Divider />

      {record?.posts && (
        <div className="flex-col items-center mt-8">
          <Title level={5}>Registro da conversa</Title>
          <Table dataSource={record?.posts}>
            <Table.Column
              title="Nome do autor"
              key="author"
              dataIndex="author"
              render={author => <Text>{author.name}</Text>}
            />
            <Table.Column
              title="Data de postagem"
              key="create_at"
              dataIndex="created_at"
              render={createdAt => <Text>{formatDate(createdAt)}</Text>}
            />
            <Table.Column title="Texto" key="text" dataIndex="text" />
          </Table>
          {record?.status === 'pending' && (
            <Button onClick={() => modal.show()} style={{ marginTop: '12px' }}>
              Nova resposta
            </Button>
          )}
          <PostModal threadId={record.id} modal={modal} onUpdate={onUpdate} />
        </div>
      )}
    </Show>
  );
};
