import GoogleLoginButton from 'components/login/GoogleLoginButton';
import PartnerLogo from '../../assets/PartnerLogo';
import React from 'react';
import { Button, Card, Col, Divider, Form, Input, Layout, Row } from 'antd';
import { containerStyles, imageContainer, layoutStyles } from './styles';
import { getTenant } from 'services/tenants';
import { useLogin } from '@refinedev/core';

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const LoginPage: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();
  const { mutate: login, isLoading } = useLogin<ILoginForm>();
  const { googleId } = getTenant();

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh'
        }}
      >
        <Col xs={22}>
          <div style={containerStyles}>
            <div style={imageContainer}>
              <PartnerLogo style={{ minWidth: 220, maxWidth: 280 }} />
            </div>
            <Card styles={{ header: { borderBottom: 0 } }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={values => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false
                }}
              >
                <Form.Item
                  name="username"
                  label="Usuário"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="usuario" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Senha"
                  rules={[{ required: true }]}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <Button
                  style={{ marginTop: 32 }}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                >
                  Entrar
                </Button>
              </Form>
              {googleId && (
                <>
                  <Divider />
                  <GoogleLoginButton />
                </>
              )}
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
