import { Col, Row, Space, Typography } from 'antd';
import { IUser } from 'interfaces/users';
import { getTenant } from 'services/tenants';
import { useList } from '@refinedev/core';
import { useMemo } from 'react';

interface UserDetailsProps {
  userData: IUser;
}

export const UserDetails = ({ userData }: UserDetailsProps) => {
  const { data: states } = useList({
    resource: 'states',

    pagination: {
      pageSize: 30
    }
  });

  const stateId = userData?.address?.city?.state_id;

  const userCity = useMemo(() => {
    if (stateId) {
      const uf = states?.data?.find(state => state.id === stateId)?.uf;
      return `${userData?.address?.city.name}/${uf}`;
    }
    return '--';
  }, [userData, stateId, states]);

  const userInfo = useMemo(
    () => [
      { label: 'Aluno', value: userData?.name },
      { label: 'Email', value: userData?.email },
      { label: 'CPF', value: userData?.personal_info?.document_info },
      { label: 'Telefone', value: userData?.personal_info?.phone_number },
      { label: 'CEP', value: userData?.address?.postal_code },
      { label: 'Endereço do aluno', value: userData?.address?.street },
      { label: 'Bairro', value: userData?.address?.neighborhood },
      { label: 'Número', value: userData?.address?.address_number },
      { label: 'Cidade', value: userCity },
      {
        label: 'ID externo',
        value: userData?.estacio_id ?? 'Não informado'
      }
    ],
    [userData, userCity]
  );

  return (
    <Row gutter={[16, 24]} className="pt-4">
      {userInfo.map(({ label, value }, index) => {
        if (getTenant().name !== 'Estácio' && label === 'ID externo') {
          return;
        }
        return (
          <Col xs={24} md={6} key={index}>
            <Space direction="vertical">
              <Typography.Text strong>{label}</Typography.Text>
              <Typography.Text>{value ?? '--'}</Typography.Text>
            </Space>
          </Col>
        );
      })}
    </Row>
  );
};
