import { CourseEnrollmentSHow } from 'pages/academic/users/course_enrollment/show';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'course_enrollment',
  label: 'Matrícula',
  show: CourseEnrollmentSHow,
  actions: ['show'],
  parentName: 'users'
};

export default generateResources(resource);
