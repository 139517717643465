/* eslint-disable unicorn/filename-case */
interface UseCustomSearchParamsProps {
  searchParams: string;
}

export const useCustomSearchParams = (props: UseCustomSearchParamsProps) => {
  const { href } = window.location;
  const url = new URL(href);
  const currentSearchParams = url.searchParams.get(props.searchParams);

  function setSearchParams(param: string) {
    url.searchParams.set(props.searchParams, param);
    history.pushState({}, '', url);
  }

  return {
    currentSearchParams,
    setSearchParams
  };
};
