import { IAssessment } from 'interfaces/assessments';
import { Show } from '@refinedev/antd';
import { TabDiscursiveAttempts } from './components/tab_discursive_attempts/TabDiscursiveAttempts';
import { TabInfo } from './components/TabInfo';
import { TabObjectiveAttempts } from './components/tab_objective_attempts/TabObjectiveAttempts';
import { TabQuestions } from './components/TabQuestions';
import { Tabs } from 'antd';
import { useCustomSearchParams } from 'hooks/useSearchParams';
import { useShow } from '@refinedev/core';

export const AssessmentShow = () => {
  const { queryResult } = useShow<IAssessment>();
  const { currentSearchParams: currentTab, setSearchParams } =
    useCustomSearchParams({ searchParams: 'tab' });

  const { data, isLoading } = queryResult;
  const assessment = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar avaliação">
      {assessment ? (
        <>
          <Tabs
            defaultActiveKey={currentTab || 'info'}
            onChange={tab => setSearchParams(tab)}
          >
            <Tabs.TabPane tab="Informações gerais" key="info">
              <TabInfo assessment={assessment} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Questões" key="questions">
              <TabQuestions />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Avaliações realizadas" key="attempts">
              {assessment.kind === 'objective' ? (
                <TabObjectiveAttempts />
              ) : (
                <TabDiscursiveAttempts />
              )}
            </Tabs.TabPane>
          </Tabs>
        </>
      ) : null}
    </Show>
  );
};
