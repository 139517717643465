import React from 'react';
import { IGraduateCourse } from '../../../../../interfaces/graduate_courses';
import { IUser } from '../../../../../interfaces/users';
import { Space, Typography } from 'antd';

interface StudentInfoProps {
  user?: IUser;
  course?: IGraduateCourse;
}

export const StudentInfo: React.FC<StudentInfoProps> = ({ user, course }) => (
  <Space direction="vertical" size={16} className="pl-2">
    <Space direction="horizontal" align="start" size="middle">
      <Space>
        <Typography.Text strong>Nome:</Typography.Text>
        <Typography.Text>{user?.name}</Typography.Text>
      </Space>
      <Space>
        <Typography.Text strong>E-mail:</Typography.Text>
        <Typography.Text>{user?.email}</Typography.Text>
      </Space>
    </Space>
    <Space>
      <Typography.Text strong>Curso:</Typography.Text>
      <Typography.Text>{course?.name}</Typography.Text>
    </Space>
  </Space>
);
