import { HttpError, useModalReturnType } from '@refinedev/core';
import { IAcademicRecord } from 'interfaces/academic_record';
import { Modal, Table } from 'antd';
import { TagField, TextField, useTable } from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { tagColorByStatus } from 'utils/statusColorMapping';
import {
  translateAcademicRecordEventStatus,
  translateAcademicRecordStatus
} from 'services/academic-records';

interface AcademicRecordLogModalProps {
  academicRecordId: string;
  modal: useModalReturnType;
}

export const AcademicRecordLogModal = ({
  academicRecordId,
  modal
}: AcademicRecordLogModalProps) => {
  const { tableProps } = useTable<IAcademicRecord, HttpError>({
    resource: `graduate/academic_records/${academicRecordId}/logs`
  });

  return (
    <Modal
      title="Histórico de Alterações"
      width={1200}
      visible={modal.visible}
      onCancel={modal.close}
      footer={null}
    >
      <Table
        size="small"
        {...tableProps}
        columns={[
          {
            title: 'Alterado em',
            dataIndex: 'created_at',
            key: 'created_at',
            render: createdAt => (
              <TextField value={formatDate(createdAt, 'lll')} />
            )
          },
          {
            title: 'Alterado por',
            dataIndex: 'author',
            key: 'author',
            render: author => <span>{author.name}</span>
          },
          {
            title: 'Evento',
            dataIndex: 'event',
            key: 'event',
            render: (event: string) => (
              <TextField value={translateAcademicRecordEventStatus(event)} />
            )
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
              <>
                {status ? (
                  <div className="flex flex-col gap-1">
                    <span>
                      De:{' '}
                      {status[0] ? (
                        <TagField
                          color={tagColorByStatus(status[0])}
                          value={translateAcademicRecordStatus(status[0])}
                        />
                      ) : (
                        '-'
                      )}
                    </span>
                    <span>
                      Para:{' '}
                      <TagField
                        color={tagColorByStatus(status[1]) || ''}
                        value={translateAcademicRecordStatus(status[1])}
                      />
                    </span>
                  </div>
                ) : (
                  '-'
                )}
              </>
            )
          },
          {
            title: 'Nota',
            dataIndex: 'score',
            key: 'score',
            render: score => (
              <>
                {score ? (
                  <div className="flex flex-col gap-1">
                    <span>
                      De: <TextField value={score[0] || '-'} />
                    </span>
                    <span>
                      Para: <TextField value={score[1] || '-'} />
                    </span>
                  </div>
                ) : (
                  '-'
                )}
              </>
            )
          },
          {
            title: 'Justificativa',
            dataIndex: 'justification',
            key: 'justification',
            render: justification => (
              <>
                {justification ? (
                  <div className="flex flex-col gap-1">
                    <span>
                      De: <TextField value={justification[0] || '-'} />
                    </span>
                    <span>
                      Para: <TextField value={justification[1] || '-'} />
                    </span>
                  </div>
                ) : (
                  '-'
                )}
              </>
            )
          }
        ]}
      />
    </Modal>
  );
};
