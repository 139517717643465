import { ContractBreadcrumbs } from './components/ContractBreadcrumbs';
import { ContractForm } from './components/ContractForm';
import { Edit, useForm } from '@refinedev/antd';
import { HttpError, useOne } from '@refinedev/core';
import { ICompany } from 'interfaces/companies';
import { IContract } from 'interfaces/contracts';
import { useParams } from 'react-router-dom';

export const ContractEdit = () => {
  const params = useParams();
  const { id: contractId, companyId } = params;

  const { formProps, saveButtonProps, form } = useForm<
    IContract,
    HttpError,
    IContract
  >({
    resource: `companies/${companyId}/contracts`,
    action: 'edit',
    id: contractId,

    successNotification: () => ({
      type: 'success',
      description: 'Sucesso!',
      message: 'Contrato editado com sucesso'
    }),
    errorNotification: () => ({
      type: 'error',
      description: 'Ops!',
      message: 'Erro ao editar contrato. Tente novamente.'
    })
  });

  const { data, isLoading } = useOne<ICompany>({
    resource: 'companies',
    id: companyId!
  });

  form.setFieldValue('course_type', 'Course');

  return (
    <Edit
      isLoading={isLoading}
      saveButtonProps={saveButtonProps}
      title="Editar contrato"
      breadcrumb={<ContractBreadcrumbs />}
    >
      {data?.data.id && (
        <ContractForm form={form} formProps={formProps} company={data?.data} />
      )}
    </Edit>
  );
};
