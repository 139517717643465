import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { ILibrary } from 'interfaces/libraries';

export const LibrariesCreate = () => {
  const { formProps, saveButtonProps } = useForm<ILibrary>({
    resource: 'prometheus/v2/libraries',
    redirect: 'edit',
    successNotification: {
      message: 'Biblioteca criada com sucesso',
      description: 'A biblioteca foi criada com sucesso',
      type: 'success'
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar biblioteca">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
