import { TopicShow } from 'pages/academic/forum';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'forums',
  label: 'Fóruns',
  show: TopicShow,
  actions: ['list', 'create', 'delete', 'show'],
  parentName: 'communication'
};

export default generateResources(resource);
