import { DateField, Show } from '@refinedev/antd';
import { IProductBonus } from 'interfaces/product_bonuses';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const BonusShow = () => {
  const { queryResult } = useShow<IProductBonus>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar bônus">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Usuário</Title>
      <Text>{record?.user.name}</Text>
      <Title level={5}>Produto</Title>
      <Text>{record?.product.name}</Text>
      <Title level={5}>Motivo</Title>
      <Text>{record?.motive}</Text>
      <Title level={5}>Expiração</Title>
      <DateField value={record?.expires_at}></DateField>
    </Show>
  );
};
