import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Typography
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { IAssessmentQuestion } from 'interfaces/assessments';
import { useCallback, useMemo, useState } from 'react';
import { useUpdate } from '@refinedev/core';

const { Text } = Typography;

interface AttemptQuestionProps {
  index: number;
  question: IAssessmentQuestion;
  onChangeScore: (index: number, value: number) => void;
}

export const AttemptQuestion = ({
  index,
  question,
  onChangeScore
}: AttemptQuestionProps) => {
  const [showGabarito, setShowGabarito] = useState(false);

  const [form] = Form.useForm<IAssessmentQuestion>();
  const { mutateAsync: updateAnswer, isLoading } = useUpdate();
  const [corrected, setCorrected] = useState(false);

  const isCorrected = useMemo(
    () => question.status !== 'pending' || corrected,
    [corrected, question.status]
  );

  const onUpdateAnswer = useCallback(async () => {
    await updateAnswer({
      resource: `assessments/answers`,
      id: question.id, // the back need's to fix it. the question.id is the answer.id
      values: {
        evaluator_score: String(form.getFieldValue('evaluator_score')),
        evaluator_feedback: form.getFieldValue('evaluator_feedback')
      },
      successNotification: () => ({
        type: 'success',
        description: 'Tudo certo!',
        message: 'Correção realizada com sucesso'
      }),
      errorNotification: () => ({
        description: 'Algo deu errado',
        message: 'Erro ao realizar correção. Tente novamente',
        type: 'error'
      })
    }).then(() => setCorrected(true));
  }, [form, updateAnswer, question]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onUpdateAnswer}
      initialValues={{
        evaluator_score: question.evaluator_score,
        evaluator_feedback: question.evaluator_feedback
      }}
      onValuesChange={value => {
        if (Object.keys(value).includes('evaluator_score')) {
          onChangeScore(index, Number(Object.values(value)));
        }
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text strong style={{ fontSize: 12 }}>
          Questão {index + 1}
        </Text>

        <div
          style={{
            display: 'block',
            fontWeight: 600,
            marginBottom: 8
          }}
          dangerouslySetInnerHTML={{
            __html: question.statement
          }}
        />

        <Space direction="vertical" style={{ width: '100%', marginBottom: 8 }}>
          <Text style={{ fontSize: 12 }}>Resposta do aluno</Text>
          <Card size="small">
            <Text type="secondary" style={{ whiteSpace: 'break-spaces' }}>
              {question.answer}
            </Text>
          </Card>
        </Space>

        {showGabarito && (
          <Space
            direction="vertical"
            style={{
              marginBottom: 8
            }}
          >
            <Text style={{ fontSize: 12 }}>Gabarito</Text>
            <Card size="small">
              <Text type="secondary">{question.feedback}</Text>
            </Card>
          </Space>
        )}

        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col xs={24} md={4}>
            <Form.Item
              label="Nota (0-100)"
              name="evaluator_score"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <InputNumber
                type="number"
                placeholder="70"
                min={0}
                max={100}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={20}>
            <Form.Item
              name="evaluator_feedback"
              label="Comentário do professor"
              initialValue={question.evaluator_feedback}
            >
              <Input placeholder="Inserir comentário..." />
            </Form.Item>
          </Col>
        </Row>

        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button
            type="text"
            htmlType="button"
            onClick={() => setShowGabarito(!showGabarito)}
          >
            {showGabarito ? 'Esconder gabarito' : 'Visualizar gabarito'}
          </Button>

          <Space>
            <Button htmlType="submit" loading={isLoading}>
              {isCorrected ? 'Correção salva' : 'Salvar correção'}
            </Button>
            {isCorrected && <CheckCircleOutlined style={{ color: 'green' }} />}
          </Space>
        </Space>
      </Space>
    </Form>
  );
};
