import { Col, Row, Space, Table } from 'antd';
import {
  EditButton,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { ICertifiers } from 'interfaces/certifiers';
import { ICourseCertificate } from 'interfaces/course_certificate';

export const CertifiersList = () => {
  const { tableProps } = useTable<ICertifiers>({});

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="code"
              key="code"
              title="Código"
              render={value => <TextField value={value} />}
            />
            <Table.Column<ICourseCertificate>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
