import { Col, DatePicker, Form, Row, Select, Space, Typography } from 'antd';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { IDocumentation } from 'interfaces/documentation/documentations';
import { IEnrollment } from 'interfaces/enrollments';
import {
  ISignaturePartners,
  ISignaturePartnersGet
} from 'interfaces/signature_partners';
import { PreviewCertificate } from 'components/certificates/PreviewCertificate';
import { ShowFieldData } from 'components/ShowFieldData';
import { formatDate, replaceTimeZone } from 'services/date';
import { getAPI } from 'requests/api';
import {
  handleIssueCertificate,
  transformAttributes
} from 'services/certificate';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotification, useOne, useParsed } from '@refinedev/core';

export const CreatePendingCertificate = () => {
  const { open } = useNotification();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [signaturePartner, setSignaturePartner] =
    useState<ISignaturePartnersGet>();
  const { params } = useParsed();
  const { enrollmentId } = params as { enrollmentId: string };
  const { selectProps } = useSelect<ISignaturePartners>({
    resource: 'certifiers/signature_partners',
    optionLabel: 'name',
    optionValue: 'id'
  });
  const { form, formProps, saveButtonProps } = useForm({
    successNotification: () => ({
      type: 'success',
      description: 'Sucesso!',
      message: 'Assinatura criada com sucesso!'
    }),
    errorNotification: () => ({
      type: 'error',
      description: 'Error!',
      message: 'Não foi possível criar a assinatura!'
    })
  });
  const signaturePartnerId = Form.useWatch('signature_partner_id', form);

  const { data: enrrolmentRequest } = useOne<IEnrollment>({
    resource: 'graduate/courses/enrollments',
    id: enrollmentId
  });
  const { data: documentationRequest } = useOne<IDocumentation>({
    resource: 'documentations',
    id: enrrolmentRequest?.data?.documentation_id
  });
  const enrrolment = enrrolmentRequest?.data;
  const documents = transformAttributes(documentationRequest?.data?.documents);

  const getSignaturePartner = async (signaturePartnerId: string) => {
    await getAPI()
      .get(`admin/certifiers/signature_partners/${signaturePartnerId}`)
      .then(response => {
        setSignaturePartner(response?.data);
      });
  };

  useEffect(() => {
    if (signaturePartnerId) {
      getSignaturePartner(signaturePartnerId);
    }
  }, [signaturePartnerId]);

  const handleFinishAction = useCallback(() => {
    setIsLoading(false);
    navigate(`/certificate_management/graduate/courses/certificates`);
  }, [navigate]);

  const handleCreateCertificate = useCallback(() => {
    setIsLoading(true);
    handleIssueCertificate(
      {
        signature_partner_id: form.getFieldValue('signature_partner_id'),
        issued_at: replaceTimeZone(form.getFieldValue('issued_at')),
        registered_at: replaceTimeZone(form.getFieldValue('registered_at')),
        enrollment_id: enrollmentId
      },
      open!,
      handleFinishAction
    );
  }, [open, handleFinishAction, form, enrollmentId]);

  return (
    <Create
      title="Criar certificado"
      isLoading={isLoading}
      saveButtonProps={{
        ...saveButtonProps,
        loading: isLoading,
        children: isLoading ? 'Gerando certificado...' : 'Emitir certificado',
        icon: undefined
      }}
    >
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Informações do aluno
      </Typography.Title>
      <Space direction="vertical" className="w-full !gap-4">
        <Row gutter={[16, 16]} className="w-full">
          <Col span={8}>
            <ShowFieldData
              label="Nome de usuário"
              value={enrrolment?.user.name}
            />
          </Col>
          <Col span={8}>
            <ShowFieldData label="E-mail" value={enrrolment?.user.email} />
          </Col>
          <Col span={8}>
            <ShowFieldData
              label="Telefone"
              value={enrrolment?.user.personal_info?.phone_number ?? '--'}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col span={8}>
            <ShowFieldData label="Curso" value={enrrolment?.course?.name} />
          </Col>
        </Row>
      </Space>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Identidade
      </Typography.Title>
      <Space direction="vertical" className="w-full !gap-4">
        <Row gutter={[16, 16]} className="w-full">
          <Col span={12}>
            <ShowFieldData
              label="Nome civil"
              value={documents['Nome completo'] ?? '--'}
            />
          </Col>
          <Col span={12}>
            <ShowFieldData
              label="CPF"
              value={documents['Número do CPF'] ?? '--'}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col span={6}>
            <ShowFieldData
              label="RG"
              value={documents['Número do RG'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="Órgão expeditor"
              value={documents['Órgão emissor'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="UF da identidade"
              value={documents['UF'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="Data de emissão"
              value={
                (documents['Data de Emissão'] &&
                  formatDate(documents['Data de Emissão'], 'DD/MM/YYYY')) ??
                '--'
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col span={6}>
            <ShowFieldData
              label="Nacionalidade"
              value={documents['Nacionalidade'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="Naturalidade"
              value={documents['Naturalidade'] ?? '--'}
            />
          </Col>
        </Row>
      </Space>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Certificado
      </Typography.Title>
      <Form {...formProps} onFinish={handleCreateCertificate} layout="vertical">
        <Space direction="vertical" className="w-full !gap-4">
          <Row gutter={[16, 16]} className="w-full flex items-end">
            <Col span={4}>
              <Form.Item
                label="Data de expedição"
                name="issued_at"
                rules={[
                  { required: true, message: 'Data de expedição obrigatória.' }
                ]}
              >
                <DatePicker
                  className="w-full"
                  placeholder="dd/mm/aaaa"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Data de registro"
                name="registered_at"
                rules={[
                  { required: true, message: 'Data de registro obrigatória.' }
                ]}
              >
                <DatePicker
                  className="w-full"
                  placeholder="dd/mm/aaaa"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
        </Space>
        <Typography.Title level={5} style={{ margin: '0 0 24px' }}>
          Assinaturas
        </Typography.Title>
        <Space direction="vertical" className="w-full !gap-4">
          <Row gutter={[16, 16]} className="w-full">
            <Col span={8}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
                label="Modelo"
                name="signature_partner_id"
                rules={[{ required: true, message: 'Modelo obrigatório.' }]}
              >
                <Select {...selectProps} />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form>
      {signaturePartner && (
        <>
          <Typography.Paragraph style={{ margin: '0 0 8px' }}>
            Pré-visualização
          </Typography.Paragraph>
          <Space
            direction="horizontal"
            className="border solid border-1 border-gray rounded-md p-4 w"
          >
            {signaturePartner?.front_signature && (
              <PreviewCertificate
                type="front"
                signaturePartner={signaturePartner}
              />
            )}
            {signaturePartner?.back_signature &&
              signaturePartner?.record_back_signature && (
                <PreviewCertificate
                  type="back"
                  signaturePartner={signaturePartner}
                />
              )}
          </Space>
        </>
      )}
    </Create>
  );
};
