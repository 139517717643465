import Backend from 'i18next-http-backend';
import i18n from 'i18next'; // adding lazy loading for translations, more information here: https://github.com/i18next/i18next-http-backend
import { initReactI18next } from 'react-i18next'; // auto detect the user language, more information here: https://github.com/i18next/i18next-browser-languageDetector

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: ['pt-BR'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json' // locale files path
    },
    defaultNS: 'common',
    fallbackLng: ['pt-BR']
  });

export { default } from 'i18next';
