import { ecommerceIcon } from '../assets/icons/ecommerce';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'ecommerce',
  label: 'Loja Online',
  icon: ecommerceIcon
};

export default generateResources(resource);
