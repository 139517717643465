import FilterForm from 'components/forms/FilterForm';
import { Col, DatePicker, Form, FormProps, Input, Row, Select } from 'antd';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { IProfessor } from 'interfaces/professor';
import { useSelect } from '@refinedev/antd';

export interface IInteractiveClassFilterForm {
  id: string;
  title: string;
  professor_id: string;
  discipline_id: string;
  status: string;
  date: string;
}

interface UsersFilterFormProps {
  searchFormProps: FormProps<IInteractiveClassFilterForm>;
}

export const InteractiveClassListFilter = ({
  searchFormProps
}: UsersFilterFormProps) => {
  const { selectProps: professorSelectProps } = useSelect<IProfessor>({
    resource: 'professors',
    optionLabel: 'name',
    optionValue: 'id',
    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ]
  });

  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',
      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ]
    }
  );
  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={[16, 16]} className="w-full">
        <Col xs={24} md={8}>
          <Form.Item label="ID da reunião" name="id">
            <Input placeholder="ID da reunião" />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label="Título da aula" name="title">
            <Input placeholder="Título" />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label="Professor" name="professor_id">
            <Select
              {...professorSelectProps}
              placeholder="Selecione o professor"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="w-full">
        <Col xs={24} md={8}>
          <Form.Item label="Disciplina" name="discipline_id">
            <Select
              {...disciplineSelectProps}
              placeholder="Selecione a disciplina"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label="Status" name="status">
            <Select defaultValue="">
              <Select.Option value="">Todos</Select.Option>
              <Select.Option value="created">Criada</Select.Option>
              <Select.Option value="started">Iniciada</Select.Option>
              <Select.Option value="cancelled">Cancelada</Select.Option>
              <Select.Option value="finished">Finalizada</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label="Data" name="date">
            <DatePicker
              className="w-full"
              format={'DD/MM/YYYY'}
              placeholder="DD/MM/AAAA"
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
