import { TagGroupCreate, TagGroupEdit, TagGroupList } from 'pages/tag-groups';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'tag-groups',
  label: 'Gerenciador de tags',
  list: TagGroupList,
  edit: TagGroupEdit,
  create: TagGroupCreate,
  actions: ['list', 'edit', 'create'],
  parentName: 'teaching_resources/libraries/:libraryId'
};

export default generateResources(resource);
