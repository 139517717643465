import { ContractForm } from './components/ContractForm';
import { Create, useForm } from '@refinedev/antd';
import { HttpError, useOne } from '@refinedev/core';
import { ICompany } from 'interfaces/companies';
import { IContract } from 'interfaces/contracts';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const ContractCreate = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const { formProps, saveButtonProps, form } = useForm<
    IContract,
    HttpError,
    IContract
  >({
    resource: `companies/${companyId}/contracts`,
    redirect: false,
    successNotification: () => ({
      type: 'success',
      description: 'Sucesso!',
      message: 'Contrato cadastrado com sucesso'
    }),
    errorNotification: () => ({
      type: 'error',
      description: 'Ops!',
      message: 'Erro ao cadastrar contrato. Tente novamente.'
    }),
    onMutationSuccess: () => {
      navigate(`/b2b/companies/show/${companyId}?tab=contracts`);
    }
  });

  const { data, isLoading } = useOne<ICompany>({
    resource: 'companies',
    id: companyId!
  });

  useEffect(() => {
    form.setFieldValue('company', data?.data.name);
  }, [data?.data.name, form]);

  return (
    <Create
      isLoading={isLoading}
      saveButtonProps={saveButtonProps}
      title="Novo contrato"
    >
      {data?.data.id && (
        <ContractForm form={form} formProps={formProps} company={data?.data} />
      )}
    </Create>
  );
};
