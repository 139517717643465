import RangeTable from 'components/offers/gateways/rules/RangeTable';
import RuleConfig from 'components/offers/gateways/Config';
import { IGatewayRule } from 'interfaces/gateways';
import { Show } from '@refinedev/antd';
import { Typography } from 'antd';
import { translateRuleKind } from 'services/gateways';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const GatewayRuleShow = () => {
  const { queryResult } = useShow<IGatewayRule>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  if (!record) {
    return <Show isLoading={isLoading} />;
  }

  return (
    <Show isLoading={isLoading} title="Visualizar regras de gateway">
      <Title level={5}>ID</Title>
      <Text>{record.id}</Text>
      <Title level={5}>Nome</Title>
      <Text>{record.name}</Text>
      <Title level={5}>Tipo</Title>
      <Text>{translateRuleKind(record.kind)}</Text>
      <Title level={4}>Configurações</Title>
      <RuleConfig config={record.config} />

      {record.kind === 'zip_code' && <RangeTable name="CEP" rule={record} />}
    </Show>
  );
};
