import FilterForm from 'components/forms/FilterForm';
import React from 'react';
import { Form, FormProps, Select } from 'antd';

interface ReportsFilterProps {
  searchFormProps: FormProps;
}

export interface IReportsFilter {
  by_reason: string;
  by_status: string;
}

export const ReportsFilter = ({ searchFormProps }: ReportsFilterProps) => {
  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Form.Item label="Motivo" name="by_reason">
        <Select placeholder="Selecione o motivo" allowClear>
          <Select.Option value="spam">
            Conteúdo comercial indesejado ou spam
          </Select.Option>
          <Select.Option value="offensive">
            Discurso de ódio ou violência explícita
          </Select.Option>
          <Select.Option value="harassment">Assédio ou bullying</Select.Option>
          <Select.Option value="misinformation">Desinformação</Select.Option>
          <Select.Option value="other">Outro</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Status" name="by_status" initialValue="pending">
        <Select placeholder="Selecione o status" style={{ minWidth: 200 }}>
          <Select.Option value="">Todos</Select.Option>
          <Select.Option value="pending">Pendente</Select.Option>
          <Select.Option value="accepted">Aprovado</Select.Option>
          <Select.Option value="rejected">Rejeitado</Select.Option>
        </Select>
      </Form.Item>
    </FilterForm>
  );
};
