import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Typography
} from 'antd';
import { IAcademicRecord } from '../../../../interfaces/academic_record';
import { IGraduateCourseEnrollment } from '../../../../interfaces/graduate_course_enrollments';
import { IGraduateDiscipline } from '../../../../interfaces/graduate_disciplines';
import { IUser } from '../../../../interfaces/users';
import { TagField } from '@refinedev/antd';
import {
  checkAcademicRecordAndGetDisciplines,
  fetchAcademicRecorsByCourseId,
  fetchGraduateCoursesEnrollments,
  fetchGraduateDisciplines
} from 'services/academic-record-equivalences';
import { tagColorByStatus } from '../../../../utils/statusColorMapping';
import { translateEnrollmentStatus } from '../index';

interface EquivalencyFormProps {
  user: IUser;
  academic_record?: IAcademicRecord;
  form: FormInstance;
}

const EquivalencyForm: React.FC<EquivalencyFormProps> = ({
  user,
  academic_record,
  form
}) => {
  const [selectedCourseEnrollment, setSelectedCourseEnrollment] =
    useState<IGraduateCourseEnrollment>();
  const [disciplines, setDisciplines] = useState<IGraduateDiscipline[]>([]);
  const [formData, setFormData] = useState({
    status: 'exempted',
    score: undefined as number | undefined,
    text: '',
    origin_academic_record_id: academic_record?.id
  });

  const { courseSelectProps, coursesEnrollments } =
    fetchGraduateCoursesEnrollments(user.id);

  const academicRecords = fetchAcademicRecorsByCourseId(
    selectedCourseEnrollment?.id
  );
  const graduateDisciplines = fetchGraduateDisciplines(
    selectedCourseEnrollment?.course.id
  );

  const handleCourseChange = useCallback(
    (value: string) => {
      const selected = coursesEnrollments.find(
        enrollment => enrollment.id === value
      );

      if (selected) {
        setSelectedCourseEnrollment(selected);
      }

      form.setFieldsValue({ discipline_id: null });
    },
    [coursesEnrollments, form]
  );

  useEffect(() => {
    if (selectedCourseEnrollment?.id) {
      const disciplinesWithAcademicRecord =
        checkAcademicRecordAndGetDisciplines(
          graduateDisciplines,
          academicRecords
        ) || [];

      setDisciplines(disciplinesWithAcademicRecord);
    }
  }, [academicRecords, graduateDisciplines, selectedCourseEnrollment]);

  const customCourseLabel = (enrollment: IGraduateCourseEnrollment) => {
    return (
      <div className="flex flex-col justify-center">
        <Typography.Text>
          {`📚 ${enrollment.course.name}`}{' '}
          <TagField
            color={tagColorByStatus(enrollment.status || '')}
            value={translateEnrollmentStatus(enrollment.status || '')}
          />
        </Typography.Text>
      </div>
    );
  };

  const courseEnrollmentOptions = coursesEnrollments.map(enrollment => ({
    label: customCourseLabel(enrollment),
    value: enrollment.id
  }));

  const customDisciplineLabel = (discipline: IGraduateDiscipline) => {
    return (
      <div className="flex flex-col justify-center">
        <Typography.Text>
          {`${discipline.name}`}
          {` ⌛ ${Math.floor(discipline.duration_in_minutes / 60)} horas`}
        </Typography.Text>
      </div>
    );
  };

  const disciplineOptions = useMemo(() => {
    return (
      disciplines?.map(discipline => ({
        label: customDisciplineLabel(discipline),
        value: discipline.id
      })) || []
    );
  }, [disciplines]);

  const onChangeStatus = (e: RadioChangeEvent) => {
    setFormData({
      ...formData,
      status: e.target.value,
      score: e.target.value === 'exempted' ? undefined : academic_record?.score
    });
  };

  const onChangeScore = (value: number | undefined) => {
    setFormData({
      ...formData,
      score: value
    });
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      text: e.target.value
    });
  };

  return (
    <div className="mt-10">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            name="origin_academic_record_id"
            initialValue={academic_record?.id}
            hidden
          >
            <Input />
          </Form.Item>

          <Form.Item
            required
            label="1. Selecione o curso do aluno"
            name="course_enrollment_id"
            rules={[
              {
                required: true,
                message: 'O curso é obrigatório.'
              }
            ]}
          >
            <Select
              allowClear
              onClear={() => setSelectedCourseEnrollment}
              {...courseSelectProps}
              placeholder="Selecione o curso de destino"
              options={courseEnrollmentOptions}
              onChange={handleCourseChange as any}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            required
            label="2. Selecione a disciplina de destino equivalente"
            name="discipline_id"
            rules={[
              {
                required: true,
                message: 'A disciplina é obrigatória.'
              }
            ]}
          >
            <Select
              disabled={!selectedCourseEnrollment}
              allowClear
              placeholder="Selecione a disciplina"
              options={disciplineOptions}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            required
            label="3. Situação da disciplina no histórico"
            name="status"
            initialValue="exempted"
          >
            <Radio.Group onChange={onChangeStatus} value={formData.status}>
              <Radio value="exempted">Isento</Radio>
              <Radio value="approved">Aprovado</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        {formData.status === 'approved' && (
          <Col span={12}>
            <Form.Item
              required
              label="Nota para o histórico (0 - 100)"
              name="score"
              initialValue={formData.score}
              rules={[
                {
                  required: formData.status === 'approved',
                  message: 'A nota é obrigatória.'
                }
              ]}
            >
              <InputNumber
                value={formData.score}
                placeholder="Ex.: 100"
                onChange={onChangeScore}
                min={0}
                max={100}
                required
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Form.Item label="Observações" name="text">
        <Input.TextArea
          placeholder="Caso necessário, escreva aqui algum comentário."
          value={formData.text}
          onChange={handleFeedbackChange}
        />
      </Form.Item>
    </div>
  );
};

export default EquivalencyForm;
