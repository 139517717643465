import FilterForm from 'components/forms/FilterForm';
import {
  Button,
  Col,
  Form,
  Input,
  Select,
  Space,
  Table,
  Typography
} from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { ICoupon } from 'interfaces/coupons';
import { IOrder } from 'interfaces/orders';
import { IUser } from 'interfaces/users';
import { ShowButton, TagField, TextField, useTable } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { formatMoney } from 'services/money';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateStatus } from 'services/orders';

interface IFilter {
  orderNumber: string;
  byProduct: string;
  byStatus: string;
  fromAgent: boolean;
}

interface UserOrdersProps {
  userData: IUser;
}

export const UserOrders = ({ userData }: UserOrdersProps) => {
  const { tableProps, searchFormProps } = useTable<IOrder, HttpError, IFilter>({
    resource: 'orders',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { byProduct, fromAgent, byStatus, orderNumber } = params;

      filters.push(
        {
          field: 'filter[by_order_number]',
          operator: 'eq',
          value: orderNumber
        },
        {
          field: 'filter[by_user_id]',
          operator: 'eq',
          value: userData.id
        },
        {
          field: 'filter[by_product]',
          operator: 'eq',
          value: byProduct
        },
        {
          field: 'filter[from_agent]',
          operator: 'eq',
          value: fromAgent
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: byStatus
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_user_id]',
          operator: 'eq',
          value: userData.id
        }
      ]
    }
  });

  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Typography.Title level={5}>Pedidos do aluno</Typography.Title>
      <FilterForm searchFormProps={searchFormProps}>
        <Col xs={24} md={4}>
          <Form.Item label="Nº do pedido" name="orderNumber">
            <Input placeholder="Nº do pedido" />
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item label="Produto" name="byProduct">
            <Input placeholder="Nome do produto" />
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item label="Status" name="byStatus">
            <Select placeholder="Selecione...">
              <Select.Option key="initial">Não Recebido</Select.Option>
              <Select.Option key="created">Criado</Select.Option>
              <Select.Option key="approved">Aprovado</Select.Option>
              <Select.Option key="returned">Retornado</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item label="Tipo" name="fromAgent">
            <Select placeholder="Selecione...">
              <Select.Option key="true">Manual (Gerencial)</Select.Option>
              <Select.Option key="false">Vitrine</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </FilterForm>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          title="Nº do pedido"
          key="order_number"
          dataIndex="order_number"
          render={value => <TextField value={value} />}
        />

        <Table.Column
          title="Status"
          key="status"
          dataIndex="status"
          render={value => (
            <TagField
              color={tagColorByStatus(value)}
              value={translateStatus(value)}
            />
          )}
        />

        <Table.Column
          title="Valor"
          key="amount"
          dataIndex="amount"
          render={amount => <TextField value={formatMoney(amount)} />}
        />

        <Table.Column
          title="Cupom"
          key="coupon"
          dataIndex="coupon"
          render={(coupon: ICoupon) => (
            <Button href={`/coupons/edit/${coupon?.id}`} target="_blank">
              {coupon?.code}
            </Button>
          )}
        />

        <Table.Column
          title="Criação"
          key="created_at"
          dataIndex="created_at"
          render={value => (
            <TextField value={formatDate(value, 'DD/MM/YY - hh:mm')} />
          )}
        />

        <Table.Column<IOrder>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton
                  hideText
                  size="small"
                  resource="orders"
                  recordItemId={record.id}
                />
              </Space>
            );
          }}
        />
      </Table>
    </Space>
  );
};
