import { IOrder } from 'interfaces/orders';

export const STATUS_TRANSLATIONS = new Map([
  ['requested', 'Aberto'],
  ['approved', 'Aprovado'],
  ['rejected', 'Rejeitado'],
  ['processing', 'Processando'],
  ['processed', 'Processado']
]);

export const translateStatus = (status: IOrder['status']): string =>
  STATUS_TRANSLATIONS.get(status) || status;
