import * as Icons from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { IFiscalReceipt } from 'interfaces/orders';
import { TagField, TextField } from '@refinedev/antd';
import { download } from 'services/receipts';
import { formatDate } from 'services/date';
import { translateStatus } from 'services/fiscals';
import { useState } from 'react';

interface ActionsProps {
  receipt: IFiscalReceipt;
}

const Actions = ({ receipt }: ActionsProps) => {
  const [loading, setLoading] = useState(false);

  const downloadFile = async (receipt: IFiscalReceipt) => {
    try {
      setLoading(true);
      const { url } = await download({ receipt });
      window.open(url, '_blank');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex gap-2">
      <Space>
        <Button
          onClick={() => downloadFile(receipt)}
          loading={loading}
          className="!flex items-center"
          icon={<Icons.DownloadOutlined />}
        >
          Download
        </Button>
      </Space>
    </div>
  );
};

interface InvoiceTableProps {
  receipts: IFiscalReceipt[];
}

const InvoiceTable = ({ receipts }: InvoiceTableProps) => (
  <Table
    dataSource={receipts}
    rowKey="id"
    pagination={false}
    bordered
    scroll={{ x: true }}
  >
    <Table.Column
      dataIndex="status"
      key="status"
      title="Status"
      render={(receipt: IFiscalReceipt['status']) => (
        <TagField value={translateStatus(receipt)} />
      )}
    />
    <Table.Column
      dataIndex="process_after"
      key="process_after"
      title="Data de criação"
      render={(value: string) => <TextField value={formatDate(value)} />}
    />
    <Table.Column<IFiscalReceipt>
      title="Ações"
      render={(_, receipt: IFiscalReceipt) =>
        receipt.status === 'issued' ? <Actions receipt={receipt} /> : null
      }
    />
  </Table>
);

export default InvoiceTable;
