import { IFiscalReceipt } from 'interfaces/orders';
import { getAPI } from 'requests/api';

interface Props {
  receipt: IFiscalReceipt;
}

interface Download {
  url: string;
}

export const download = async ({ receipt }: Props): Promise<Download> => {
  const { data } = await getAPI().get(
    `/admin/fiscal_receipts/${receipt.id}/download`
  );

  return data;
};
