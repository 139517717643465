import { ClassroomList, ClassroomShow } from 'pages/teacher_space/classrooms';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'professor_area/classrooms',
  label: 'Turmas',
  list: ClassroomList,
  show: ClassroomShow,
  actions: ['list', 'show'],
  parentName: 'teacher_space'
};

export default generateResources(resource);
