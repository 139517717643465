import * as Icons from '@ant-design/icons';
import { Button, Modal, Tag, Typography } from 'antd';
import { IReportShow } from 'interfaces/reports';
import {
  REPORT_REASON_MAP,
  REPORT_STATUSES_WITH_COLOR_MAP
} from 'services/report';
import { Show } from '@refinedev/antd';
import { acceptReport, rejectReport } from 'requests/reports';
import { formatDate } from 'services/date';
import { useCallback, useState } from 'react';
import { useNotification, useShow } from '@refinedev/core';

const { Text } = Typography;

export const ReportShow = () => {
  const { queryResult } = useShow<IReportShow>();
  const { data, isLoading, refetch } = queryResult;
  const report = data?.data as IReportShow;

  const [loading, setLoading] = useState(false);

  const notification = useNotification();
  const [modal, contextHolder] = Modal.useModal();

  const showConfirmApproveReport = () => {
    modal.confirm({
      title: 'Deseja aprovar a denúncia e desativar comentário?',
      content:
        'Ao confirmar essa ação, os comentários relacionados à essa denúncia serão desativados e não serão mais exibidos para os alunos.',
      closable: true,
      maskClosable: true,
      icon: <Icons.WarningOutlined />,
      okText: 'Aprovar denúncia',
      okButtonProps: {
        danger: true
      },
      cancelText: 'Voltar',
      autoFocusButton: null,
      onOk() {
        handleApproveReport();
      }
    });
  };

  const showConfirmRejectReport = () => {
    modal.confirm({
      title: 'Deseja arquivar a denúncia e manter comentário?',
      content:
        'Ao confirmar essa ação, o comentário relacionado à essa denúncia continuará sendo exibido para os alunos e a denúncia será arquivada.',
      closable: true,
      maskClosable: true,
      icon: <Icons.WarningOutlined />,
      okText: 'Rejeitar denúncia',
      cancelText: 'Voltar',
      autoFocusButton: null,
      onOk() {
        handleRejectReport();
      }
    });
  };

  const handleApproveReport = useCallback(async () => {
    try {
      setLoading(true);
      await acceptReport(report.id);
      notification.open?.({
        message: 'Mensagem',
        type: 'success',
        description: 'Denúncia aprovada com sucesso.'
      });
    } catch {
      notification.open?.({
        message: 'Mensagem',
        type: 'error',
        description:
          'Ops! Não foi possível aprovar esta denúncia. Tente novamente'
      });
    } finally {
      setLoading(false);
      refetch();
    }
  }, [notification, report, refetch]);

  const handleRejectReport = useCallback(async () => {
    try {
      setLoading(true);
      await rejectReport(report.id);
      notification.open?.({
        message: 'Denúncia rejeitada com sucesso.',
        type: 'success',
        description: 'Sucesso'
      });
    } catch {
      notification.open?.({
        message:
          'Ops! Não foi possível rejeitar esta denúncia. Tente novamente',
        type: 'error',
        description: 'Erro'
      });
    } finally {
      setLoading(false);
      refetch();
    }
  }, [notification, refetch, report]);

  return (
    <Show isLoading={isLoading} title="Visualizar denúncia">
      {report && (
        <>
          <div className="flex justify-between w-full mb-4">
            <div>
              <Text strong className="block">
                ID
              </Text>
              <Text>{report.id}</Text>
            </div>

            <div>
              <Text strong className="block">
                Data de envio
              </Text>
              <Text>{formatDate(report.created_at)}</Text>
            </div>

            <div>
              <Text strong className="block">
                Status
              </Text>
              <Tag color={REPORT_STATUSES_WITH_COLOR_MAP[report.status].color}>
                {REPORT_STATUSES_WITH_COLOR_MAP[report.status].text}
              </Tag>
            </div>
          </div>

          <div className="border p-4 rounded-md mb-8">
            <div className="grid md:grid-cols-2 gap-4 mb-4">
              <div>
                <Text strong className="block">
                  Autor da denúncia
                </Text>
                <Text>{report.reporter.name}</Text>
              </div>
              <div>
                <Text strong className="block">
                  ID do autor
                </Text>
                <Text>{report.reporter.id}</Text>
              </div>
              <div>
                <Text strong className="block">
                  Moderador
                </Text>
                <Text>{report.moderator?.name || '--'}</Text>
              </div>
              <div>
                <Text strong className="block">
                  Data da moderação
                </Text>
                <Text>
                  {report.status === 'pending'
                    ? '--'
                    : formatDate(report.updated_at)}
                </Text>
              </div>
              <div>
                <Text strong className="block">
                  Motivo
                </Text>
                <Text>{REPORT_REASON_MAP[report.reason]}</Text>
              </div>
            </div>

            <Text strong className="block">
              Texto
            </Text>
            <div className="bg-gray-50 p-3 rounded-md mt-1">
              <Text>{report.text}</Text>
            </div>

            <div className="mt-4">
              <Text strong className="block mb-2">
                Comentário denunciado
              </Text>

              <div className="border py-4 px-3 rounded-md">
                <div>
                  <div className="md:flex items-center gap-2">
                    <Text strong className="block">
                      {report.comment?.user.name}
                    </Text>
                    <span className="block text-gray-500 font-normal">
                      {formatDate(report.comment?.created_at, 'lll')}
                    </span>
                  </div>
                  <Text>{report.comment?.text}</Text>
                </div>
              </div>
            </div>

            {report.status === 'pending' && (
              <div className="flex flex-wrap md:justify-end mt-6 gap-4">
                <Button
                  danger
                  icon={<Icons.InboxOutlined />}
                  onClick={showConfirmRejectReport}
                  loading={loading}
                >
                  Rejeitar denúnica
                </Button>
                <Button
                  danger
                  type="primary"
                  icon={<Icons.ExclamationCircleOutlined />}
                  onClick={showConfirmApproveReport}
                  loading={loading}
                >
                  Aprovar denúnica
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      {contextHolder}
    </Show>
  );
};
