import { ASSESSMENT_KIND_MAP, QUESTION_STATE_MAP } from 'services/assessments';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  EditButton,
  List,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { IAssessment, IAssessmentQuestion } from 'interfaces/assessments';
import { IProductBonus } from '../../../interfaces/product_bonuses';
import { IQuestionFilter, QuestionsFilter } from './components/QuestionsFilter';
import { Space, Table } from 'antd';

export const AssessmentQuestionList = () => {
  const { tableProps, searchFormProps } = useTable<
    IAssessmentQuestion,
    HttpError,
    IQuestionFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { assessment_id, statement, status, by_kind } = params;

      filters.push(
        {
          field: 'filter[assessment]',
          operator: 'eq',
          value: assessment_id
        },
        {
          field: 'filter[statement]',
          operator: 'eq',
          value: statement
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        },
        {
          field: 'filter[by_kind]',
          operator: 'eq',
          value: by_kind
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[status]',
          operator: 'eq',
          value: 'pending_evaluation'
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: 'active'
        }
      ]
    }
  });

  return (
    <List>
      <QuestionsFilter searchFormProps={searchFormProps} />

      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="plain_statement"
          key="plain_statement"
          title="Questão"
          render={value => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="assessment_name"
          key="assessment_name"
          title="Curso | Avaliação"
          render={value => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="kind"
          key="kind"
          title="Tipo"
          width={120}
          render={(value: IAssessment['kind']) => (
            <TextField value={ASSESSMENT_KIND_MAP[value] || ''} />
          )}
        />

        <Table.Column
          dataIndex="alternatives"
          key="alternatives"
          title="Alternativas"
          align="center"
          width={120}
          render={value => (
            <TextField value={value.length > 0 ? value.length : 1} />
          )}
        />

        <Table.Column
          dataIndex="status"
          key="status"
          title="Status"
          width={120}
          render={value => (
            <TagField
              color={QUESTION_STATE_MAP[value].color}
              value={QUESTION_STATE_MAP[value].label}
            />
          )}
        />

        <Table.Column<IProductBonus>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
