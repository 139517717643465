import { Form, FormItemProps } from 'antd';

export const FormItem = (props: FormItemProps) => (
  <Form.Item
    colon={false}
    labelCol={{ className: 'w-[150px]' }}
    labelAlign="left"
    {...props}
  />
);
