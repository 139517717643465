import { Box, Stack, Text } from '@qcx/ui';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Switch
} from 'antd';
import { useEffect, useState } from 'react';
import { useForm } from '@refinedev/antd';
import { useModalReturnType } from '@refinedev/core';

interface CreateFeeRuleModalProps {
  modal: useModalReturnType;
  onUpdate?: () => void;
}

type FeeSwitchTypes = 'interest' | 'fine';
interface IFeeForm {
  name: string;
  config: {
    late_fines: number;
    due_interests: number;
  };
}
type FeeSwitch = {
  [Key in FeeSwitchTypes]: boolean;
};

const CreateFeeRuleModal = ({ modal }: CreateFeeRuleModalProps) => {
  const [toggle, setToggle] = useState<FeeSwitch>({
    interest: true,
    fine: true
  });

  const { formProps, formLoading } = useForm<IFeeForm>({
    resource: 'offers/fees',
    successNotification: () => ({
      type: 'success',
      description: 'Tudo certo!',
      message: 'Regra criada com sucesso'
    }),
    errorNotification: () => ({
      type: 'error',
      description: 'Opss',
      message: 'Algo deu errado'
    })
  });

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onFinish = (values: {}) => {
    const data = values as IFeeForm;
    const payload = {
      name: data.name,
      status: 'created',
      config: {
        late_fines: toggle.fine
          ? [
              {
                percent_amount: data.config.late_fines,
                due_interval: { type: 'day', value: 1 }
              }
            ]
          : [],
        due_interests: toggle.interest
          ? [
              {
                percent_amount: data.config.due_interests,

                due_interval: { type: 'year', value: 1 },
                charge_interval: { type: 'day', value: 1 }
              }
            ]
          : []
      }
    };

    formProps.onFinish!({ ...payload });
    formProps.form?.resetFields();
    modal.close();
  };

  const handleSwitch = (checked: boolean, type: FeeSwitchTypes) => {
    const updateToggle = { [type]: checked };
    setToggle(prev => ({ ...prev, ...updateToggle }));
  };

  useEffect(() => {
    formProps.form?.setFieldsValue({
      config: {
        due_interests: 12,
        late_fines: 2
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title="Criar Nova Regra"
      visible={modal.visible}
      onCancel={modal.close}
      footer={[
        <Button key="back" onClick={modal.close}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={formLoading}
          disabled={formLoading}
          onClick={() => formProps.form?.submit()}
        >
          Criar
        </Button>
      ]}
    >
      <Form {...formProps} layout="horizontal" onFinish={onFinish}>
        <Text
          variant="sm"
          weight="medium"
          css={{ mb: '$2', d: 'inline-block' }}
        >
          Nome da Regra
        </Text>
        <Form.Item
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Divider />

        <Text
          variant="sm"
          weight="medium"
          css={{ mb: '$2', d: 'inline-block' }}
        >
          Configuração da Regra
        </Text>
        <Card>
          <Stack
            css={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Box css={{ flex: 1 }}>
              <Text css={{ d: 'block' }} weight="semibold">
                Juros
              </Text>
              <Text css={{ d: 'block', color: '$textMuted' }}>
                Valor máximo: 12%
              </Text>
            </Box>
            <Stack css={{ flex: 1, gap: '$6', alignItems: 'center' }}>
              <Form.Item
                style={{ marginBottom: '0' }}
                name={['config', 'due_interests']}
              >
                <InputNumber
                  disabled={!toggle.interest}
                  addonAfter="% a.a"
                  min={0}
                  max={12}
                  step={0.1}
                />
              </Form.Item>
              <Switch
                defaultChecked
                onChange={checked => handleSwitch(checked, 'interest')}
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack
            css={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Box css={{ flex: 1 }}>
              <Text css={{ d: 'block' }} weight="semibold">
                Multa
              </Text>
              <Text css={{ d: 'block', color: '$textMuted' }}>
                Valor máximo: 2%
              </Text>
            </Box>
            <Stack css={{ flex: 1, gap: '$6', alignItems: 'center' }}>
              <Form.Item
                style={{ marginBottom: '0' }}
                name={['config', 'late_fines']}
              >
                <InputNumber
                  disabled={!toggle.fine}
                  addonAfter="%"
                  min={0}
                  max={2}
                  step={0.1}
                />
              </Form.Item>
              <Switch
                defaultChecked
                onChange={checked => handleSwitch(checked, 'fine')}
              />
            </Stack>
          </Stack>
        </Card>
      </Form>
    </Modal>
  );
};

export default CreateFeeRuleModal;
