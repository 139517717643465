import { Col, Form, Input, Row } from 'antd';
import { Create, useForm } from '@refinedev/antd';
import { IUser } from 'interfaces/users';
import { InputMask } from 'components/form-items/InputMask';

export const UserCreate = () => {
  const { formProps, saveButtonProps } = useForm<IUser>();

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar aluno">
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={12}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Telefone"
              name="phone_number"
              rules={[
                {
                  pattern: new RegExp(/^\d{2} \d{5}-\d{4}$/),
                  message: 'Insira um numero de telefone válido'
                }
              ]}
            >
              <InputMask mask="00 00000-0000" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input type="password" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
