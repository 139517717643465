import { getTenant } from 'services/tenants';

interface ImageRestriction {
  size: number;
  message: string;
}

type ImageType =
  | 'avatar'
  | 'bannerMobile'
  | 'bannerDesktop'
  | 'card'
  | 'cardExibicao'
  | 'cardConteudoGratuito'
  | 'capa'
  | 'saibaMais'
  | 'oQueEsperar';

type TenantRestrictions = Record<string, Record<string, ImageRestriction>>;

export const applyImageSizeRestriction = (
  imageType: ImageType
): ImageRestriction | undefined => {
  const tenant = getTenant();
  const restrictions: TenantRestrictions = {
    themis: {
      avatar: { size: 0.3, message: 'O arquivo deve ter no máximo 300KB' },
      bannerMobile: {
        size: 0.3,
        message: 'O arquivo deve ter no máximo 300KB'
      },
      bannerDesktop: {
        size: 0.7,
        message: 'O arquivo deve ter no máximo 700KB'
      },
      capa: {
        size: 0.5,
        message: 'O arquivo deve ter no máximo 500KB'
      },
      card: { size: 0.5, message: 'O arquivo deve ter no máximo 500KB' },
      cardExibicao: {
        size: 0.5,
        message: 'O arquivo deve ter no máximo 500KB'
      },
      cardConteudoGratuito: {
        size: 0.5,
        message: 'O arquivo deve ter no máximo 500KB'
      },
      oQueEsperar: {
        size: 0.5,
        message: 'O arquivo deve ter no máximo 500KB'
      },
      saibaMais: {
        size: 0.5,
        message: 'O arquivo deve ter no máximo 500KB'
      }
    }
  };

  return restrictions[tenant.project]?.[imageType];
};
