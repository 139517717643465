import * as Icons from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { useModalReturnType } from '@refinedev/core';

const { Title, Text } = Typography;

interface IConfirmationModalProps {
  modal: useModalReturnType;
  type: 'warning' | 'success';
  onCreate: () => void;
}

const WarningIcon = () => (
  <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#FFFAEB]">
    <div className="w-[30px] h-[30px] flex items-center justify-center rounded-full bg-[#FEF0C7]">
      <Icons.WarningOutlined style={{ color: '#DC6803' }} />
    </div>
  </div>
);

const SuccessIcon = () => (
  <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#ECFDF3]">
    <div className="w-[30px] h-[30px] flex items-center justify-center rounded-full bg-[#D1FADF]">
      <Icons.CheckCircleOutlined style={{ color: '#039855' }} />
    </div>
  </div>
);

const ConfirmationModal = ({
  modal,
  onCreate,
  type
}: IConfirmationModalProps) => {
  const onClose = () => {
    modal.close();
  };

  const onCreateOrder = async () => {
    onCreate();
  };

  const contentMap = {
    warning: {
      title: 'Confirmação de criação de pedido',
      body: 'Ao confirmar essa ação um novo pedido será criado. Deseja continuar? ',
      footer: [
        <Button key="back" onClick={onClose}>
          Não
        </Button>,
        <Button key="submit" type="primary" onClick={onCreateOrder}>
          Sim
        </Button>
      ]
    },
    success: {
      title: 'Pedido criado com sucesso',
      body: 'Para concluir o pagamento é necessário ir até a área “meus pedidos” no ambiente do aluno.',
      footer: [
        <Button key="submit" type="ghost" onClick={modal.close}>
          Entendi
        </Button>
      ]
    }
  };

  return (
    <Modal
      title={type === 'warning' ? <WarningIcon /> : <SuccessIcon />}
      visible={modal.visible}
      onCancel={onClose}
      width={400}
      centered
      footer={contentMap[type].footer}
    >
      <Title level={4}>{contentMap[type].title}</Title>
      <Text>{contentMap[type].body}</Text>
    </Modal>
  );
};

export default ConfirmationModal;
