import { IDocument } from 'interfaces/documentation/document';
import { getAPI } from 'requests/api';

const DOCUMENT_STATUS_TRANSLATIONS = new Map<string, string>([
  ['pending', 'Pendente'],
  ['approved', 'Aprovado'],
  ['rejected', 'Rejeitado']
]);

export const DOCUMENT_STATUSES: string[] = [
  ...DOCUMENT_STATUS_TRANSLATIONS.keys()
];

export const translateDocumentStatus = (status: string): string =>
  DOCUMENT_STATUS_TRANSLATIONS.get(status) || status;

export const download = async (
  document_id: string
): Promise<{ url: string }> => {
  const { data } = await getAPI().get(
    `/admin/document_downloads/${document_id}`
  );

  return data;
};

export const getApproveDocumentsPayload = (
  document: IDocument,
  form: Record<string, unknown>
) => {
  const newDocument = { ...document };
  Object.keys(form).map(el => {
    const matchingField = document.attribute_fields.find(
      field => field.name === el
    );

    if (matchingField) {
      matchingField.value = form[el] as string;
    }
  });

  return newDocument;
};
