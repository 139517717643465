import ClassroomList from './components/ClassroomList';
import CoursesList from './components/CoursesList';
import ProfessorInfo from './components/ProfessorInfo';
import { Form, Tabs } from 'antd';
import { IProfessor } from 'interfaces/professor';
import { Show } from '@refinedev/antd';
import { useShow } from '@refinedev/core';
import AgentList from "./components/AgentList";

export const ProfessorShow = () => {
  const { queryResult } = useShow<IProfessor>();

  return (
    <Show title="Visualizar professor" isLoading={queryResult.isLoading}>
      {!queryResult.isLoading && (
        <Tabs defaultActiveKey="info">
          <Tabs.TabPane tab="Informações Gerais" key="info">
            <Form initialValues={queryResult?.data?.data} layout="vertical">
              <ProfessorInfo
                professorData={queryResult?.data?.data}
                type="show"
              />
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Responsável por turmas" key="classrooms">
            <ClassroomList />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Coordenação de Cursos" key="coordenation">
            <CoursesList />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Usuário" key="agent">
            <AgentList
              professorData={queryResult?.data?.data}
              type="show"
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </Show>
  );
};
