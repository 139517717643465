import routerProvider from '@refinedev/react-router-v6/legacy';
import { Button, Col, Row, Table, TableProps } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { IAssessmentQuestion, IAssessmentUser } from 'interfaces/assessments';
import { TagField, TextField } from '@refinedev/antd';
import { useParams } from 'react-router-dom';

interface AssessmentQuestionListProps {
  tableProps: TableProps<IAssessmentUser>;
}

const StatusColorMap: Record<string, string> = {
  'Nenhuma Pendência': 'success',
  'Aguardando Correção': 'warning'
};

export const DiscursiveAttemptsTable = ({
  tableProps
}: AssessmentQuestionListProps) => {
  const { Link } = routerProvider;
  const params = useParams();
  const assessmentId = params.id;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Aluno"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="email"
              key="email"
              title="E-mail do aluno"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="pending_assessment_attempts_count"
              key="pending_assessment_attempts_count"
              title="A corrigir"
              align="center"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="evaluation_started_assessment_attempts_count"
              key="evaluation_started_assessment_attempts_count"
              title="Em correção"
              align="center"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="evaluated_assessment_attempts_count"
              key="evaluated_assessment_attempts_count"
              title="Corrigidas"
              align="center"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="assessment_attempts_count"
              key="assessment_attempts_count"
              title="Total"
              align="center"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="assessment_attempts_status"
              key="assessment_attempts_status"
              title="Status"
              render={(value: IAssessmentQuestion['status']) => (
                <TagField
                  color={StatusColorMap[value]}
                  style={{ border: '1px solid #d9d9d9' }}
                  value={value}
                />
              )}
            />

            <Table.Column
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record: IAssessmentQuestion) => {
                return (
                  <Link
                    to={`/evaluations/assessments/${assessmentId}/user_attempts/show/${record.id}`}
                  >
                    <Button icon={<EyeOutlined />} size="small" />
                  </Link>
                );
              }}
            />
          </Table>
        </Col>
      </Row>
    </>
  );
};
