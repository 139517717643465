import { ManualOrderCreate } from 'pages/ecommerce/manual_order';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'manual_orders',
  label: 'Criação novos pedidos',
  create: ManualOrderCreate,
  list: ManualOrderCreate,
  actions: ['list', 'create'],
  parentName: 'sales'
};

export default generateResources(resource);
