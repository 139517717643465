import { IAcademicRecord } from 'interfaces/academic_records';
import { IClassroomProfessor } from 'interfaces/professor';
import { IGraduateDisciplineModule } from 'interfaces/graduate_discipline_module';
import { getAPI } from 'requests/api';

export type AcademicHistoryData = IAcademicRecord & {
  professor?: IClassroomProfessor['professor'];
  disciplineModule?: IGraduateDisciplineModule;
};

export const fetchAcademicHistoryData = async (
  courseId: string,
  enrollmentId: string
): Promise<AcademicHistoryData[]> => {
  const [academicRecords, disciplineModulesRes] = await Promise.all([
    getAPI().get(
      `/admin/graduate/academic_records?items=100&page=1&filter[by_course_enrollment_id]=${enrollmentId}`
    ),
    getAPI().get(
      `/admin/graduate/disciplines_modules?items=100&page=1&filter[by_course]=${courseId}`
    )
  ]);

  return mountAcademicHistoryData(
    academicRecords.data,
    disciplineModulesRes.data
  );
};

export const mountAcademicHistoryData = (
  academicRecords: IAcademicRecord[],
  disciplineModules: IGraduateDisciplineModule[]
): AcademicHistoryData[] => {
  return academicRecords.reduce((acc: AcademicHistoryData[], record) => {
    let professor;

    if ('classroom' in record.parent) {
      professor = record.parent.classroom?.classroom_professors?.[0]?.professor;
    }

    const disciplineModule = disciplineModules.find(
      dm => dm.discipline.id === record.discipline.id
    );

    const academicHistoryItem: AcademicHistoryData = {
      ...record,
      professor,
      disciplineModule
    };

    return [...acc, academicHistoryItem];
  }, []);
};
