import * as Icons from '@ant-design/icons';
import { Button, Row, Space, Table } from 'antd';
import { DeleteButton, EditButton, TextField } from '@refinedev/antd';
import { IClassroomEvaluation } from 'interfaces/classrooms';
import { formatDate } from 'services/date';
import { useState } from 'react';

export interface EvaluationsReplacementsProps {
  evaluationData: IClassroomEvaluation;
  replacementCreateModal: (id: string) => void;
  replacementEditModal: (parentId: string, replacementId: string) => void;
  replacementAssociationModalShow: (replacementId: string) => void;
  resource: string;
}

const EvaluationsReplacements = ({
  evaluationData,
  replacementCreateModal,
  replacementEditModal,
  replacementAssociationModalShow,
  resource
}: EvaluationsReplacementsProps) => {
  const [showReplacement, setShowReplacement] = useState(false);
  return (
    <>
      <Row
        gutter={16}
        style={{ margin: 0 }}
        className="flex items-center gap-4 relative p-4"
      >
        <Space align="center" className="justify-between w-full">
          <Button
            className="!flex-row-reverse gap-2 !m-0"
            type="text"
            icon={
              showReplacement ? <Icons.UpOutlined /> : <Icons.DownOutlined />
            }
            onClick={() => setShowReplacement(!showReplacement)}
          >
            Substitutivas
          </Button>
          <Button
            icon={<Icons.PlusOutlined />}
            onClick={() => replacementCreateModal(evaluationData.id)}
          >
            Adicionar substitutiva
          </Button>
        </Space>
      </Row>
      <Row gutter={[16, 16]} className="flex items-center gap-4 relative p-4">
        {showReplacement && (
          <Table
            dataSource={evaluationData?.replacements}
            rowKey="id"
            className="w-full"
          >
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="evaluation_type"
              key="evaluation_type"
              title="Tipo da avaliação"
              render={value => (
                <TextField
                  value={value === 'Assignment' ? 'Atividade' : 'Avaliação'}
                />
              )}
            />
            <Table.Column<IClassroomEvaluation>
              key="course"
              title="Período de acesso"
              render={(_, record) => {
                const { rules } = record.requirements[0];
                const { value: ends_at } =
                  rules.find(rule => rule.operator === 'before') || {};
                const { value: starts_at } =
                  rules.find(rule => rule.operator === 'after') || {};

                return (
                  <TextField
                    value={
                      <Space className="flex flex-col text-xs">
                        <div>De: {formatDate(String(starts_at), 'lll')}</div>
                        <div>Até: {formatDate(String(ends_at), 'lll')}</div>
                      </Space>
                    }
                  />
                );
              }}
            />
            <Table.Column<IClassroomEvaluation>
              dataIndex="evaluation-actions"
              key="evaluation-actions"
              title="Ações"
              render={(_, record) => {
                return (
                  <Row className="flex gap-2">
                    {record.rule === 'classroom_enrollment' && (
                      <Button
                        size="small"
                        icon={<Icons.UserAddOutlined />}
                        onClick={e => {
                          e.stopPropagation();
                          replacementAssociationModalShow(record.id);
                        }}
                      />
                    )}
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={evaluationData.id}
                      onClick={e => {
                        e.stopPropagation();
                        replacementEditModal(evaluationData.id, record.id);
                      }}
                    />
                    <DeleteButton
                      className="self-end"
                      style={{
                        color: '#626262',
                        borderColor: '#d9d9d9'
                      }}
                      hideText
                      size="small"
                      resource={resource}
                      recordItemId={record.id}
                    />
                  </Row>
                );
              }}
            />
          </Table>
        )}
      </Row>
    </>
  );
};

export default EvaluationsReplacements;
