import { ContractsFilter, ICompanyContractsFilter } from './ContractsFilter';
import { ContractsTable } from './ContractsTable';
import { CreateButton, useTable } from '@refinedev/antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { ICompany } from 'interfaces/companies';
import { IContract } from 'interfaces/contracts';
import { useParams } from 'react-router-dom';

interface TabCompanyContractsProps {
  company: ICompany;
}

export const TabCompanyContracts = ({ company }: TabCompanyContractsProps) => {
  const { id } = useParams();

  const { tableProps, searchFormProps } = useTable<
    IContract,
    HttpError,
    ICompanyContractsFilter
  >({
    resource: `companies/${id}/contracts`,

    onSearch: params => {
      const filters: CrudFilters = [];
      const { by_course_type, by_code, by_course_id } = params;

      filters.push(
        {
          field: 'filter[by_course_type]',
          operator: 'eq',
          value: by_course_type
        },
        {
          field: 'filter[company_id]',
          operator: 'eq',
          value: id
        },
        {
          field: 'filter[by_code]',
          operator: 'eq',
          value: by_code
        },
        {
          field: 'filter[by_course_id]',
          operator: 'eq',
          value: by_course_id
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_course_type]',
          operator: 'eq',
          value: null
        }
      ]
    }
  });

  return (
    <div className="space-y-6">
      <ContractsFilter searchFormProps={searchFormProps} />

      <CreateButton
        resource="contracts"
        meta={{ companyId: company.id }}
        style={{ marginLeft: 'auto', display: 'block', marginTop: 16 }}
      >
        Novo contrato
      </CreateButton>

      <ContractsTable tableProps={tableProps} />
    </div>
  );
};
