import { ISession } from 'interfaces/auth';
import { clearPermissions, refreshPermissions } from 'services/permissions';
import type { IAgent } from 'interfaces/agents';

export const TOKEN_KEY = 'auth-token';
export const REFRESH_TOKEN_KEY = 'auth-refresh-token';
export const AUTH_USER_ID = 'auth-user-id';

export const setSession = async (session: ISession) => {
  localStorage.setItem(TOKEN_KEY, session.token);
  localStorage.setItem(REFRESH_TOKEN_KEY, session.refreshToken);
  await refreshPermissions();
};

export const setUser = async (user: IAgent) => {
  localStorage.setItem(AUTH_USER_ID, user.id);
};

export const clearSession = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(AUTH_USER_ID);
  clearPermissions();
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const getUserId = () =>
  localStorage.getItem(AUTH_USER_ID) as string | null;
