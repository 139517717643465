import dayjs from 'dayjs';
import { DatePicker, Divider, Form, Input, Select } from 'antd';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { ICertificateKey } from 'interfaces/courses';
import { ICourseCertificate } from 'interfaces/course_certificate';

export const CertificateEdit = () => {
  const { formProps, saveButtonProps } = useForm<ICourseCertificate>();

  const { selectProps: certificateKeySelectProps } = useSelect<ICertificateKey>(
    {
      resource: 'certificate_keys',
      optionLabel: 'key',
      optionValue: 'key',

      pagination: {
        mode: 'server'
      }
    }
  );

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar certificado">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="recipient_name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Curso"
          name="course_name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Duração (horas)"
          name="duration_in_hours"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Data de conclusão"
          name="completed_at"
          rules={[
            {
              required: true
            }
          ]}
          getValueProps={value => ({ value: value ? dayjs(value) : '' })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Template"
          name="template_key"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...certificateKeySelectProps} />
        </Form.Item>
      </Form>

      <Divider />
    </Edit>
  );
};
