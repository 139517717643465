import dayjs, { Dayjs } from 'dayjs';
import { CitySelectField as CitySelect } from 'components/forms/fields/CitySelectField';
import {
  DatePicker,
  Input,
  InputNumber,
  InputNumberProps,
  InputProps,
  Select,
  SelectProps
} from 'antd';
import { IAttributeField } from 'interfaces/documentation/document-attribute-field';
import { StateSelectField as StateSelect } from 'components/forms/fields/StateSelectField';
import { useMemo } from 'react';

type InputFieldProps = IAttributeField & InputProps;
type InputNumberFieldProps = IAttributeField & InputNumberProps;
type InputDateFieldProps = IAttributeField;
type TextAreaFieldProps = IAttributeField;
type SelectFieldProps = IAttributeField & SelectProps;

export const InputField = (attributes: InputFieldProps) => {
  return <Input {...attributes} />;
};

export const InputNumberField = (attributes: InputNumberFieldProps) => {
  return <InputNumber {...attributes} />;
};

export const TextAreaField = (attributes: TextAreaFieldProps) => {
  return <Input.TextArea {...attributes} />;
};

export const InputDateField = (attributes: InputDateFieldProps) => {
  const date = dayjs(attributes?.value);
  const dateAttr = attributes?.value ? { value: date as Dayjs } : {};
  const data = { ...attributes, ...dateAttr };

  const value = useMemo(
    () => (data.value ? dayjs(data.value) : undefined),
    [data.value]
  );

  return (
    <DatePicker
      {...data}
      value={value}
      format="DD/MM/YYYY"
      picker="date"
      allowClear
    />
  );
};

export const SelectField = (attributes: SelectFieldProps) => {
  const options = useMemo(
    () =>
      attributes?.select_options &&
      attributes.select_options.map(option => ({
        label: option,
        value: option
      })),
    [attributes.select_options]
  );

  const maxLength =
    attributes?.select_options?.reduce((a, b) => (a.length > b.length ? a : b))
      .length ?? 10;

  return (
    <Select
      options={options}
      {...attributes}
      allowClear
      style={{ minWidth: maxLength * 8 }}
    />
  );
};

export const CitySelectField = (attributes: SelectFieldProps) => {
  return <CitySelect {...attributes} allowClear />;
};

export const StateSelectField = (attributes: SelectFieldProps) => {
  return <StateSelect {...attributes} allowClear />;
};
