import { LogDeletedUserList } from 'pages/admin/log_deleted_users';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'log_deleted_users',
  label: 'Alunos excluídos',
  list: LogDeletedUserList,
  actions: ['list'],
  parentName: 'configurations'
};

export default generateResources(resource);
