import * as Icons from '@ant-design/icons';
import { Button, Divider, Form, Select, Space, Table } from 'antd';
import { CreateDisciplineModal } from 'components/modal/courses/CreateDisciplineModal';
import {
  DeleteButton,
  ShowButton,
  useForm,
  useModal,
  useSelect,
  useTable
} from '@refinedev/antd';
import { IDisciplineModule } from 'interfaces/graduate_discipline_module';
import {
  IGraduateDiscipline,
  IGraduateDisciplineShow
} from 'interfaces/graduate_disciplines';
import { Stack } from '@qcx/ui';
import { useCallback } from 'react';
import { useCreate, useNotification } from '@refinedev/core';
import { useParams } from 'react-router-dom';

export const disciplineModulesKind = {
  mandatory: 'Obrigatória',
  optional: 'Optativa'
};

export const TabDisciplines = () => {
  const { id: courseId } = useParams();
  const { open } = useNotification();
  const resource = 'graduate_disciplines';

  const {
    show: disciplineCreateModalShow,
    close: disciplineCreateModalClose,
    modalProps: disciplineCreateModalProps
  } = useModal();

  const { tableProps, tableQueryResult } = useTable<IGraduateDiscipline>({
    resource,

    filters: {
      initial: [
        {
          field: 'filter[by_course_id]',
          operator: 'eq',
          value: courseId
        }
      ]
    }
  });

  const { form } = useForm();
  const {
    selectProps: disciplineSelectProps,
    queryResult: disciplineQueryResult
  } = useSelect<IGraduateDisciplineShow>({
    resource,
    optionLabel: (value: IGraduateDisciplineShow) =>
      `${
        value.external_reference == null ? '' : value.external_reference + ' -'
      } ${value.name}`,
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });
  const { mutate: createDiscipline } = useCreate();
  const createResource = 'courses/disciplines';
  const destroyResource = 'courses/disciplines';
  const handleSubmit = useCallback(async () => {
    await form
      .validateFields()
      .then(() => {
        const disciplineId = form.getFieldValue('discipline_id');

        createDiscipline(
          {
            resource: createResource,
            values: {
              discipline_id: disciplineId,
              course_id: courseId
            },
            successNotification: () => {
              return {
                description: 'Sucesso!',
                message: 'Disciplina vinculada ao módulo com sucesso!',
                type: 'success'
              };
            }
          },
          {
            onSuccess: () => {
              tableQueryResult.refetch();
              form.resetFields();
            }
          }
        );
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: `Favor conferir todos os campos obrigatórios.`,
          description: 'Error ao criar registro!'
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, disciplineQueryResult, courseId]);

  if (!courseId) {
    return null;
  }

  return (
    <>
      <Form form={form}>
        <Stack css={{ gap: '$2', justifyContent: 'space-between' }}>
          <Form.Item
            style={{ flex: 1 }}
            label="Disciplina"
            name="discipline_id"
            rules={[{ required: true, message: 'Disciplina é obrigatória' }]}
          >
            <Select
              placeholder="Selecione a disciplina ..."
              {...disciplineSelectProps}
              style={{ minWidth: 200 }}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <Button
                    className="w-full !text-left"
                    type="text"
                    icon={<Icons.PlusOutlined />}
                    onClick={disciplineCreateModalShow}
                  >
                    Nova disciplina
                  </Button>
                </>
              )}
            />
          </Form.Item>
          <Button onClick={handleSubmit}>Inserir</Button>
        </Stack>
      </Form>
      <Table {...tableProps}>
        <Table.Column
          className="w-[60%]"
          title="Nome"
          dataIndex="name"
          key="name"
        />
        <Table.Column
          title="Código externo"
          dataIndex="external_reference"
          key="external_reference"
        />
        <Table.Column<IDisciplineModule>
          align="right"
          title="Ações"
          dataIndex="actions"
          key="actions"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton
                  size="small"
                  hideText
                  resource="graduate_disciplines"
                  recordItemId={record.id}
                />
                <DeleteButton
                  danger={false}
                  hideText
                  icon={<Icons.DeleteOutlined />}
                  size="small"
                  resource={destroyResource}
                  recordItemId={`${record.id}?course_id=${courseId}`}
                  onSuccess={() => {
                    tableQueryResult.refetch();
                  }}
                  successNotification={() => {
                    return {
                      description: 'Sucesso!',
                      message: 'Disciplina removida do módulo com sucesso',
                      type: 'success'
                    };
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
      <CreateDisciplineModal
        modalClose={disciplineCreateModalClose}
        modalProps={disciplineCreateModalProps}
        refetch={tableQueryResult.refetch}
        courseId={courseId}
      />
    </>
  );
};
