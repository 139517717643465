import React, { useState } from 'react';
import {
  Alert,
  Button,
  Input,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Typography
} from 'antd';
import { ISolicitable } from 'interfaces/equivalency';

type EquivalenceStatus = 'exempted' | 'approved';

export interface ApproveEquivalenceData {
  feedback: string;
  status: EquivalenceStatus;
  score?: number | null;
}

interface ApproveModalProps {
  data?: ISolicitable;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (data: ApproveEquivalenceData) => void;
}

export const ApproveModal = ({
  data,
  visible,
  onCancel,
  onSubmit
}: ApproveModalProps) => {
  const [formData, setFormData] = useState<ApproveEquivalenceData>({
    feedback: '',
    status: 'exempted',
    score: null
  });

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData(prev => ({ ...prev, feedback: e.target.value }));
  };

  const onChangeStatus = (e: RadioChangeEvent) => {
    setFormData(prev => ({ ...prev, status: e.target.value }));
  };

  const onChangeScore = (value: string) => {
    setFormData(prev => ({ ...prev, score: Number(value) }));
  };

  const durationInMinutes =
    Number(data?.desired_discipline.duration_in_minutes) / 60;

  return (
    <Modal
      title="Concluir Aproveitamento"
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => onSubmit(formData)}
          disabled={formData.status === 'approved' && formData.score === null}
        >
          Concluir Aproveitamento
        </Button>
      ]}
    >
      <Alert
        description={
          <div className="text-sm">
            <p className="mb-2">
              Ao concluir o aproveitamento da disciplina, os seguintes dados
              serão incluídos no histórico escolar do aluno:
            </p>
            <ul className="space-y-1">
              <li>
                <strong>Disciplina:</strong> {data?.desired_discipline.name}
              </li>
              <li>
                <strong>Carga Horária:</strong> {durationInMinutes} horas
              </li>
            </ul>
          </div>
        }
        type="info"
        style={{ marginBottom: 16, paddingBottom: 4 }}
      />

      <Space direction="vertical" size="small" className="w-full">
        <div>
          <span className="block font-semibold mb-1">Situação</span>
          <Radio.Group
            onChange={onChangeStatus}
            value={formData.status}
            defaultValue="Course"
          >
            <Radio value="exempted">Isento</Radio>
            <Radio value="approved">Aprovado</Radio>
          </Radio.Group>
        </div>

        {formData.status === 'approved' && (
          <div>
            <span className="block font-semibold mb-1">
              Nota <Typography.Text type="danger">*</Typography.Text>
            </span>
            <InputNumber
              value={formData.score?.toString()}
              placeholder="Ex.: 100"
              onChange={onChangeScore}
              min="0"
              max="100"
              required
            />
          </div>
        )}

        <div>
          <span className="block font-semibold mb-1">
            Feedback para o aluno:
          </span>
          <Input.TextArea
            placeholder="Caso necessário, escreva aqui algum retorno para o aluno."
            value={formData.feedback}
            onChange={handleFeedbackChange}
          />
        </div>
      </Space>
    </Modal>
  );
};
