import React from 'react';
import { IEquivalency } from '../../../../../interfaces/equivalency';
import { Space, Typography } from 'antd';
import { TagField } from '@refinedev/antd';
import { formatDate } from '../../../../../services/date';
import { tagColorByStatus } from '../../../../../utils/statusColorMapping';
import { translateSolicitationStatus } from '../../index';

interface SolicitationDetailsProps {
  solicitation?: IEquivalency;
}

export const SolicitationDetails = ({
  solicitation
}: SolicitationDetailsProps) => {
  const moderator = solicitation?.moderator;

  return (
    <Space direction="vertical" size={16} className="pl-2">
      <Space>
        <Typography.Text strong>Status:</Typography.Text>
        <TagField
          value={translateSolicitationStatus(solicitation?.status || '')}
          color={tagColorByStatus(solicitation?.status || '')}
        />
      </Space>

      <Space direction="horizontal" size="middle">
        <Space>
          <Typography.Text strong>Data da Solicitação:</Typography.Text>
          <Typography.Text>
            {formatDate(String(solicitation?.created_at), 'lll')}
          </Typography.Text>
        </Space>

        <Space>
          <Typography.Text strong>Última atualização:</Typography.Text>
          <Typography.Text>
            {formatDate(String(solicitation?.updated_at), 'lll')}
          </Typography.Text>
        </Space>
      </Space>

      {solicitation?.feedback && moderator && (
        <>
          <Space direction="vertical" align="start" size={4}>
            <Typography.Text strong>
              Retorno Acadêmico{' '}
              <span style={{ fontSize: 'smaller', color: 'gray' }}>
                ({moderator.name} -{' '}
                {formatDate(String(solicitation.updated_at))})
              </span>
            </Typography.Text>
            <Typography.Text>{solicitation.feedback}</Typography.Text>
          </Space>
        </>
      )}
    </Space>
  );
};
