import { buildingsIcon } from 'assets/icons/buildings';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'b2b',
  label: 'B2B',
  icon: buildingsIcon
};

export default generateResources(resource);
