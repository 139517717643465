import {
  FaqCoordinatorsList,
  FaqCoordinatorsShow
} from 'pages/academic/faq_coordinators';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'faq_coordinators',
  label: 'Suporte coordenação',
  list: FaqCoordinatorsList,
  show: FaqCoordinatorsShow,
  actions: ['list', 'show'],
  parentName: 'communication'
};

export default generateResources(resource);
