import IssuedCertificates from './components/IssuedCertificates';
import PendingCertificates from './components/PendingCertificates';
import { List } from '@refinedev/antd';
import { Tabs } from 'antd';
import { useCustomSearchParams } from 'hooks/useSearchParams';

export const CertificateList = () => {
  const { currentSearchParams: currentTab, setSearchParams } =
    useCustomSearchParams({ searchParams: 'tab' });

  return (
    <List canCreate={false}>
      <Tabs
        defaultActiveKey={currentTab || 'toIssue'}
        onChange={activeKey => setSearchParams(activeKey)}
      >
        <Tabs.TabPane tab="Certificados a emitir" key="toIssue">
          <PendingCertificates />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Certificados registrados" key="Issued">
          <IssuedCertificates />
        </Tabs.TabPane>
      </Tabs>
    </List>
  );
};
