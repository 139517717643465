import CreateFeeRuleModal from './CreateFeeRuleModal';
import { Button, Table } from 'antd';
import { IOffersFee } from '../../../interfaces/offers_fees';
import { IResourceComponentsProps, useModal } from '@refinedev/core';
import { Icon } from '@qcx/ui';
import { List, TextField, useTable } from '@refinedev/antd';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';

export const FeeRuleList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IOffersFee>();
  const modal = useModal();

  const percentAmountDecorator = (
    record: IOffersFee,
    field: 'late_fines' | 'due_interests'
  ) => {
    const feeConfig = record.config[field] as [{ percent_amount: number }];
    const value =
      feeConfig.length > 0 ? `${feeConfig[0].percent_amount}%` : '-';
    return <TextField value={value} />;
  };

  return (
    <>
      <List
        headerButtons={
          <Button
            type="primary"
            icon={<Icon icon={faPlusCircle} css={{ mr: '$2' }} />}
            onClick={() => modal.show()}
          >
            Criar Nova Regra
          </Button>
        }
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="name"
            key="name"
            title="Nome da Regra"
            render={value => <TextField value={value} />}
          />
          <Table.Column<IOffersFee>
            dataIndex={['config', 'due_interests']}
            key="interest"
            title="Valor do Juros (a.a)"
            render={(_, record) =>
              percentAmountDecorator(record, 'due_interests')
            }
          />
          <Table.Column<IOffersFee>
            dataIndex={['config', 'late_fines']}
            key="fee"
            title="Valor da Multa"
            render={(_, record) => percentAmountDecorator(record, 'late_fines')}
          />
        </Table>
      </List>
      <CreateFeeRuleModal modal={modal} />
    </>
  );
};
