import * as Icons from '@ant-design/icons';
import { Button, Card, Space, Typography } from 'antd';
import { IProfessorClassroom } from 'interfaces/professor_area/classrooms';
import { Stack } from '@qcx/ui';
import { TagField } from '@refinedev/antd';
import { useShowButton } from '@refinedev/core';

export interface ClassroomCardProps {
  classroom: IProfessorClassroom;
}

export const ClassroomCard = ({ classroom }: ClassroomCardProps) => {
  const { to, disabled, LinkComponent } = useShowButton({
    id: classroom.id
  });

  return (
    <Card
      className="min-w-[300px] p-4 flex"
      styles={{ body: { width: '100%' } }}
    >
      <Space direction="vertical" className="w-full">
        <Stack flow="row" className="justify-between items-center">
          <Typography.Title level={5} className="!mb-0 mr-4">
            {classroom.discipline_name}
          </Typography.Title>
          <LinkComponent
            to={to}
            replace={false}
            onClick={(e: React.PointerEvent<HTMLButtonElement>) => {
              if (disabled) {
                e.preventDefault();
              }
            }}
          >
            <Button
              icon={<Icons.ArrowRightOutlined className="text-white" />}
              type="primary"
            />
          </LinkComponent>
        </Stack>
        <Stack flow="row">
          <Space>
            <Icons.TeamOutlined />
            <Typography.Paragraph className="!mb-0">
              {`${classroom.active_enrollments_count} aluno(s)`}
            </Typography.Paragraph>
          </Space>
        </Stack>
        <Stack flow="row">
          <TagField value={classroom.code} color="default" />
          <TagField
            value={`${classroom.academic_year}.${classroom.academic_period}`}
            color="default"
          />
        </Stack>
      </Space>
    </Card>
  );
};
