import { SignaturesCreate } from 'pages/certificates_management/signatures/create';
import { SignaturesEdit } from 'pages/certificates_management/signatures/edit';
import { SignaturesList } from 'pages/certificates_management/signatures/list';
import { SignaturesShow } from 'pages/certificates_management/signatures/show';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'certifiers/signatures',
  list: SignaturesList,
  create: SignaturesCreate,
  edit: SignaturesEdit,
  show: SignaturesShow,
  label: 'Assinaturas',
  actions: ['list', 'show', 'edit', 'create'],
  parentName: 'certificate_management'
};

export default generateResources(resource);
