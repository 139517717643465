import FilterForm from '../../../components/forms/FilterForm';
import { Alert, Form, Input, Space, Table } from 'antd';
import { HttpError } from '@refinedev/core';
import { IProductBundle } from 'interfaces/product_bundles';
import { List, ShowButton, TextField, useTable } from '@refinedev/antd';

export const ProductBundleList = () => {
  const { tableProps, searchFormProps } = useTable<
    IProductBundle,
    HttpError,
    { byName: string }
  >({
    onSearch: ({ byName }) => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value: byName
      }
    ]
  });

  return (
    <List>
      <Alert
        style={{ marginBottom: '12px' }}
        showIcon
        type="info"
        message="A edição de informações de prateleira do combo é feita através do produto de vitrine, não é possível editar quais cursos pertencem ao combo após sua criação"
      />
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Nome" name="byName">
          <Input placeholder="Busque pelo nome aqui" />
        </Form.Item>
      </FilterForm>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="product_ids"
          key="product_ids"
          title="Total de cursos"
          render={({ length }) => <TextField value={length} />}
        />
        <Table.Column<IProductBundle>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
