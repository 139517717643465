import { CommentOutlined } from '@ant-design/icons';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'communication',
  label: 'Comunicação',
  icon: <CommentOutlined />
};

export default generateResources(resource);
