import * as Icons from '@ant-design/icons';
import Node from './Node';
import React, { useState } from 'react';
import { Tree as AtdTree, Button, Form, Input } from 'antd';
import { IClassroom } from 'interfaces/classrooms';
import {
  IDataContent,
  IDataNode,
  IDataNodeDrop,
  ITree
} from '../../interfaces/tree';
import { TreeStoreProvider } from './tree.store';
import {
  calculateNodeRemoval,
  calculatePositionChange,
  convertContentNodes,
  convertDataContents,
  convertDataNodes,
  convertNodes,
  replaceNodeMatch
} from 'services/courses/tree';
import { generateUUID } from 'services/uuid';
import { useForm } from '@refinedev/antd';

interface Props {
  tree: ITree;
  onUpdate: (tree: ITree) => void;
  classroom: IClassroom;
}

const Tree: React.FC<Props> = ({ tree, onUpdate, classroom }) => {
  const [gData, setGData] = useState([
    ...convertNodes(tree.nodes),
    ...convertContentNodes(tree.contents)
  ]);
  const [expandedKeys] = useState([]);

  const update = (data: IDataNode[]) => {
    setGData(data);

    const newTree = {
      ...tree,
      nodes: convertDataNodes(data.filter(item => !item.isLeaf)),
      contents: convertDataContents(
        (data.filter(item => item.isLeaf) || []) as IDataContent[]
      )
    };

    onUpdate(newTree);
  };

  const onDrop = (info: IDataNodeDrop) => {
    const data = calculatePositionChange(gData, info);
    update(data);
  };

  const onRemove = (node: IDataNode) => {
    const data = calculateNodeRemoval(gData, node);
    update(data);
  };

  const titleRender = (node: IDataNode) => (
    <Node
      node={node}
      updateNode={updateNode}
      updateNodeContent={updateNode}
      onRemove={onRemove}
      classroom={classroom}
    />
  );

  const updateNode = (node: IDataNode) => {
    const newGData = gData.map(data => replaceNodeMatch(data, node));
    update(newGData);
  };

  const createNode = ({ name }: { name: string }) => {
    update([...gData, { title: name, key: generateUUID(), isLeaf: false }]);
    form.resetFields();
  };

  const createContent = ({ name }: { name: string }) => {
    update([...gData, { title: name, key: generateUUID(), isLeaf: true }]);
    form.resetFields();
  };

  const { form } = useForm();

  return (
    <TreeStoreProvider>
      <div className="flex flex-col gap-4">
        <AtdTree
          className="draggable-tree"
          defaultExpandedKeys={expandedKeys}
          draggable
          blockNode
          selectable={false}
          onDrop={onDrop}
          treeData={gData}
          titleRender={titleRender}
        />
        <Form form={form} layout="horizontal">
          <div className="flex flex-row gap-4">
            <Form.Item name="name">
              <Input placeholder="Nome" />
            </Form.Item>
            <Button
              className="!flex items-center justify-center"
              htmlType="reset"
              onClick={() => {
                createNode({ name: form.getFieldValue('name') });
              }}
            >
              <Icons.PlusCircleOutlined />
              Criar Seção
            </Button>
            <Button
              className="!flex items-center justify-center"
              htmlType="reset"
              onClick={() => {
                createContent({ name: form.getFieldValue('name') });
              }}
            >
              <Icons.PlusCircleOutlined />
              Criar Conteúdo
            </Button>
          </div>
        </Form>
      </div>
    </TreeStoreProvider>
  );
};

export default Tree;
