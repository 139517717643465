import React from 'react';
import { ISolicitable } from '../../../../../interfaces/equivalency';
import { Space, Typography } from 'antd';

interface DisciplineInfoProps {
  solicitable: ISolicitable | undefined;
}

const DisciplineInfo: React.FC<DisciplineInfoProps> = ({ solicitable }) => {
  return (
    <Space direction="vertical" size={16} className="ml-2">
      <div>
        <Typography.Text strong>Disciplina pretendida: </Typography.Text>
        <Typography.Text>
          {solicitable?.desired_discipline.name}
        </Typography.Text>
      </div>

      <div>
        <Typography.Text strong>Instituição de Ensino: </Typography.Text>
        <Typography.Text>{solicitable?.institution}</Typography.Text>
      </div>

      <div>
        <Typography.Text strong>Disciplina cursada: </Typography.Text>
        <Typography.Text>{solicitable?.name}</Typography.Text>
      </div>

      <Space size="large">
        <div>
          <Typography.Text strong>Cód. Disciplina: </Typography.Text>
          <Typography.Text>{solicitable?.code}</Typography.Text>
        </div>

        <div>
          <Typography.Text strong>Carga horária: </Typography.Text>
          <Typography.Text>
            {solicitable?.duration_in_hours} horas
          </Typography.Text>
        </div>

        <div>
          <Typography.Text strong>Nota: </Typography.Text>
          <Typography.Text>{solicitable?.score}</Typography.Text>
        </div>
      </Space>

      <Space direction="vertical" size={4}>
        <Typography.Text strong>Observações: </Typography.Text>
        <Typography.Text>{solicitable?.text}</Typography.Text>
      </Space>
    </Space>
  );
};

export default DisciplineInfo;
