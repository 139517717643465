const STATUS_TRANSLATIONS = new Map([
  ['cancelled', 'Cancelado'],
  ['created', 'Ativo']
]);

export const paymentPlanStatus = (status: string): string =>
  STATUS_TRANSLATIONS.get(status) || status;

const INVOICE_STATUS_TRANSLATIONS = new Map([
  ['cancelled', 'Interrompida'],
  ['dealed', 'Negociada'],
  ['paid', 'Paga'],
  ['due', 'Inadimplente'],
  ['initial', 'Em aberto']
]);

export const paymentPlanInvoicesStatus = (status: string): string =>
  INVOICE_STATUS_TRANSLATIONS.get(status) || status;
