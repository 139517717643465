import * as Icons from '@ant-design/icons';
import { Button, Progress, Space, Table, Tooltip } from 'antd';
import { IEnrollment } from 'interfaces/enrollments';
import { Stack } from '@qcx/ui';
import { TextField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { show } from 'requests/certificates';
import { useNavigation } from '@refinedev/core';
import { useState } from 'react';

interface Props {
  enrollments: IEnrollment[];
}

interface ActionsProps {
  enrollment: IEnrollment;
}

const Actions = ({ enrollment }: ActionsProps) => {
  const [loading, setLoading] = useState(false);

  const downloadCertificate = async () => {
    setLoading(true);

    try {
      const { url } = await show({ enrollmentId: enrollment.id });

      window.open(url, '_blank');
    } catch {}
    setLoading(false);
  };

  return (
    <div className="flex gap-2">
      <Space>
        <Button
          size="small"
          className="!flex items-center"
          onClick={downloadCertificate}
          loading={loading}
          icon={<Icons.DownloadOutlined />}
        >
          Download
        </Button>
      </Space>
    </div>
  );
};

const EnrollmentTable = ({ enrollments }: Props) => {
  const navigate = useNavigation();

  return (
    <Table
      dataSource={enrollments}
      rowKey="id"
      pagination={false}
      bordered
      scroll={{ x: true }}
    >
      <Table.Column
        dataIndex="course"
        key="course"
        title="Curso"
        render={({ name }) => <TextField value={name} />}
      />
      <Table.Column
        dataIndex="progress_percentage"
        key="progress_percentage"
        title="Progresso"
        render={progress => (
          <Tooltip title={`${progress}%`}>
            <Progress
              percent={progress}
              showInfo={false}
              strokeColor={{
                '0%': '#0322e2',
                '100%': '#08adff'
              }}
            />
          </Tooltip>
        )}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Início"
        render={createdAt => <TextField value={formatDate(createdAt, 'l')} />}
      />
      <Table.Column
        dataIndex="expires_at"
        key="expires_at"
        title="Expiração"
        render={expiresAt => (
          <TextField value={expiresAt && formatDate(expiresAt, 'l')} />
        )}
      />
      <Table.Column
        dataIndex="certificate"
        key="certificate"
        title="Emissão do certificado"
        render={certificate => (
          <TextField
            value={
              certificate?.issuedAt && formatDate(certificate.issuedAt, 'l')
            }
          />
        )}
      />
      <Table.Column<IEnrollment>
        title="Certificado"
        dataIndex="actions"
        render={(_, enrollment) => {
          return (
            <>{enrollment.certificate && <Actions enrollment={enrollment} />}</>
          );
        }}
      />
      <Table.Column<IEnrollment>
        title="Ações"
        dataIndex="actions"
        render={(_, enrollment) => {
          return (
            <Button
              icon={<Icons.EyeOutlined />}
              size="small"
              onClick={() =>
                navigate.show(`course_enrollment`, enrollment.id, 'push')
              }
            />
          );
        }}
      />
    </Table>
  );
};

export default EnrollmentTable;
