import { EditButton, ShowButton, TextField } from '@refinedev/antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { IContract } from 'interfaces/contracts';
import { Space, Table, TableProps } from 'antd';
import { formatDate } from 'services/date';

export interface ContractsTableProps {
  tableProps: TableProps<IContract>;
}

export const ContractsTable = ({ tableProps }: ContractsTableProps) => {
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="code"
        key="code"
        title="Contrato"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="course_name"
        key="course_name"
        title="Curso"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="course_type"
        key="course_type"
        title="Tipo"
        render={value => (
          <TextField value={value === 'Course' ? 'Curso' : 'Pós-graduação'} />
        )}
      />
      <Table.Column
        dataIndex="number_enrollments"
        key="number_enrollments"
        title="Qtde de alunos"
        render={(value, record: IContract) => (
          <TextField value={`${value} / ${record.max_enrollments}`} />
        )}
      />
      <Table.Column
        dataIndex="starts_at"
        key="starts_at"
        title="Data de início"
        render={value => <TextField value={formatDate(value, 'DD/MM/YYYY')} />}
      />
      <Table.Column
        dataIndex="expires_at"
        key="expires_at"
        title="Data término"
        render={value => <TextField value={formatDate(value, 'DD/MM/YYYY')} />}
      />
      {/* <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <TagField
            color={CONTRACT_STATUSES_MAP[value].color}
            value={CONTRACT_STATUSES_MAP[value].label}
          />
        )}
      /> */}
      <Table.Column<IContract>
        title="Ações"
        dataIndex="actions"
        align="center"
        render={(_, record) => {
          return (
            <Space>
              <ShowButton
                style={{ marginLeft: 'auto', display: 'block' }}
                size="small"
                hideText
                resource="contracts"
                meta={{ companyId: record.company.id }}
                recordItemId={record.id}
                icon={<EyeOutlined />}
              />

              <EditButton
                style={{ marginLeft: 'auto', display: 'block' }}
                size="small"
                hideText
                resource="contracts"
                meta={{ companyId: record.company.id }}
                recordItemId={record.id}
                icon={<EditOutlined />}
              />
            </Space>
          );
        }}
      />
    </Table>
  );
};
