import * as Icons from '@ant-design/icons';
import { Button, Form, FormInstance, Select, Table } from 'antd';
import { IClassroom } from 'interfaces/classrooms';
import { TextField, useSelect } from '@refinedev/antd';
import { useCallback, useEffect, useState } from 'react';
import { useNotification } from '@refinedev/core';

interface FormClassroomsProps {
  form: FormInstance;
  onChange: (itemsvoid: IClassroom[]) => void;
}

export const FormClassrooms = ({ form, onChange }: FormClassroomsProps) => {
  const [items, setItems] = useState<IClassroom[]>([]);
  const notification = useNotification();

  const {
    selectProps: classroomSelectProps,
    queryResult: classroomQueryResult
  } = useSelect<IClassroom>({
    resource: 'classrooms',
    optionLabel: 'code',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[search_code]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const getItems = useCallback(() => {
    if (items) {
      onChange(items);
    }
  }, [items, onChange]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const onAddNewClassroom = () => {
    const classroomId = form.getFieldValue('classroom');
    const classrooms =
      (classroomQueryResult.data?.data as IClassroom[]) || undefined;

    if (items.some(cls => cls.id === classroomId)) {
      return notification.open?.({
        message: '',
        type: 'error',
        description: 'Essa turma já foi adicionada!'
      });
    }

    const classroom = classrooms?.find(cls => cls.id === classroomId);

    if (classroom) {
      setItems(prevState => {
        const prevStateArray = prevState || [];
        return [...prevStateArray, classroom];
      });

      form.resetFields(['classroom']);
    }
  };

  const removeClassroom = (id: string) => {
    setItems(prevState => prevState.filter(cls => cls.id !== id));
  };

  return (
    <>
      <div className="flex items-center gap-2 w-full mb-4">
        <Form.Item
          name="classroom"
          rules={[
            {
              required: items.length === 0,
              message: 'A turma é obrigatória.'
            }
          ]}
          className="w-full !mb-0"
        >
          <Select {...classroomSelectProps} placeholder="Selecionar a turma" />
        </Form.Item>
        <Button onClick={onAddNewClassroom}>Inserir</Button>
      </div>

      <Table dataSource={items} rowKey="id" className="w-full">
        <Table.Column
          dataIndex="discipline"
          key="discipline"
          title="Turma"
          render={value => <TextField value={value.name} />}
        />

        <Table.Column
          dataIndex="code"
          key="code"
          title="Código"
          render={value => <TextField value={value} />}
        />

        <Table.Column<IClassroom>
          key="action"
          title="Ação"
          align="center"
          width={100}
          render={(text, record) => (
            <Button
              icon={<Icons.DeleteOutlined />}
              size="small"
              onClick={() => removeClassroom(record.id)}
            />
          )}
        />
      </Table>
    </>
  );
};
