import { IUser } from 'interfaces/users';
import { getAPI } from 'requests/api';

const findUser = async (email: string): Promise<{ data: IUser[] }> => {
  return getAPI().get(
    `/admin/users?filter[by_email]=${encodeURIComponent(email)}`
  );
};

export const getUserInfo = async (
  email: string
): Promise<{ data: IUser } | null> => {
  return findUser(email).then(response => {
    if (response.data.length > 0) {
      const { id } = response.data[0];
      return getAPI().get(`/admin/users/${id}`);
    }
    return null;
  });
};
