export * from './list';
export * from './show';

const DISCUSSION_STATUS_MAP = {
  pending: 'Não resolvida',
  answered: 'Respondida',
  closed: 'Encerrada',
  unknown: 'Desconhecida'
};

export const translateDiscussionStatus = (
  text: keyof typeof DISCUSSION_STATUS_MAP
) => {
  return DISCUSSION_STATUS_MAP[text ?? 'unknown'];
};
