import { TOfferKind, TOfferStatus } from 'interfaces/offers';

const STATUS_TRANSLATIONS = new Map<TOfferStatus, string>([
  ['active', 'Ativo'],
  ['disabled', 'Desabilitado'],
  ['expired', 'Expirado'],
  ['hidden', 'Escondido e ativo']
]);

const KIND_TRANSLATIONS = new Map<TOfferKind, string>([
  ['online', 'Vitrine'],
  ['management', 'Pedido Manual']
]);

export const STATUSES: TOfferStatus[] = [...STATUS_TRANSLATIONS.keys()];
export const KINDS: TOfferKind[] = [...KIND_TRANSLATIONS.keys()];

export const translateStatus = (status: TOfferStatus): string =>
  STATUS_TRANSLATIONS.get(status) || status;

export const translateKind = (kind: TOfferKind): string =>
  KIND_TRANSLATIONS.get(kind) || kind;
