import { Col, Row, Space, Table } from 'antd';
import { EditButton, List, TextField, useTable } from '@refinedev/antd';
import { ICourseCertificate } from 'interfaces/course_certificate';
import { ISignaturePartners } from 'interfaces/signature_partners';
import { ReportKindMap } from 'services/signature-partners';

export const SignaturePartnersList = () => {
  const { tableProps } = useTable<ISignaturePartners>({});

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Modelo"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="report_kind"
              key="report_kind"
              title="Local"
              render={value => (
                <TextField
                  value={ReportKindMap[value as keyof typeof ReportKindMap]}
                />
              )}
            />
            <Table.Column<ICourseCertificate>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
