import ConfirmationModal from 'components/modal/ConfirmationModal';
import { Badge, Box, Button, Checkbox, Stack, Text } from '@qcx/ui';
import { Col, Row, Table, Tabs, Typography } from 'antd';
import { CopyToClipboard } from 'components/CopyToClipboard';
import { IMoney } from 'interfaces/money';
import { IOrderPayment, IOrderPaymentPlansDetail } from 'interfaces/orders';
import { TextField, useModal } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { formatMoney } from 'services/money';
import { paymentPlanInvoicesStatus } from 'services/payment-plan';
import { translateKind } from 'services/payments';
import { useApiUrl, useCan, useCustomMutation } from '@refinedev/core';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const { Title } = Typography;

const BOLETO_MOCK = 1;

const currentAmount = (
  invs: {
    id: string;
    index: number;
    amount: IMoney;
    planName?: string;
    totalAmount?: IMoney;
  }[]
) =>
  invs.reduce(
    (accumulator, currentValue) => {
      const money = currentValue.totalAmount ?? currentValue.amount;
      const sum = money.cents + accumulator.cents;

      accumulator.cents = sum;
      accumulator.currency_iso = money.currency_iso;

      return accumulator;
    },
    { cents: 0, currency_iso: 'BRL' }
  );

const planTotalFee = (plan: IOrderPaymentPlansDetail): IMoney => {
  const totalFee = plan.invoices.reduce((sum, invoice) => {
    return sum + (invoice.total_amount.cents - invoice.amount.cents);
  }, 0);
  return { cents: totalFee, currency_iso: 'BRL' };
};

const planTags = (plan: IOrderPaymentPlansDetail) => {
  const cAmount = currentAmount(
    plan.invoices.filter(i => i.status === 'paid')
  ) as IMoney;

  return [
    { title: 'Valor do Pedido', text: formatMoney(plan.amount) },
    { title: 'Juros e Multa', text: formatMoney(planTotalFee(plan)) },
    { title: 'Valor pago', text: formatMoney(cAmount) },
    { title: 'Qtd. Parcelas', text: plan.invoices.length },
    {
      title: 'Pagamento',
      text: plan.preferences['kind']
        ? translateKind(plan.preferences['kind'])
        : '-'
    },
    { title: 'Data inicial', text: formatDate(plan.created_at, 'DD/MM/YYYY') }
  ];
};

export const PlanContentTable = ({
  orderId,
  plan
}: {
  orderId: string;
  plan: IOrderPaymentPlansDetail;
}) => {
  const [selectedInvoices, setSelectedInvoices] = useState<
    { id: string; index: number; amount: IMoney; planName?: string }[]
  >([]);

  const navigate = useNavigate();

  const setInvoiceSelection = (
    shoudlSelect: boolean,
    i: IOrderPaymentPlansDetail['invoices'][number]
  ) => {
    const isSelected = selectedInvoices.find(({ id }) => id === i.id);

    if (isSelected && !shoudlSelect) {
      const newSelected = selectedInvoices.filter(({ id }) => id !== i.id);
      setSelectedInvoices(newSelected);
      return;
    }

    if (shoudlSelect && !isSelected) {
      setSelectedInvoices([
        ...selectedInvoices,
        { id: i.id, index: i.index + 1, amount: i.amount }
      ]);
    }
  };

  const planInvoices = reduceInvoicesByStatus(plan.invoices);
  const pendingInvoices = (planInvoices['due'] || [])
    ?.concat(planInvoices['initial'] || [])
    ?.concat(planInvoices['cancelled'] || [])
    ?.sort((a, b) => a.index - b.index);

  const createOrderDeal = () => {
    navigate(`/deal/payment_plans/create?id=${orderId}`, {
      state: {
        planDealData: {
          invoice_ids: selectedInvoices.map(({ id }) => id),
          amount: currentAmount(selectedInvoices),
          preferences: {
            kind: plan?.preferences?.kind,
            info: plan?.preferences?.info,
            gateway_config: plan?.preferences?.gateway_config,
            installments: BOLETO_MOCK
          },
          installments: plan?.invoices.length
        },
        initialPlanData: {
          debitInvoicesCount: selectedInvoices.length,
          debitAmount: currentAmount(selectedInvoices),
          paymentKind: plan.preferences.kind,
          totalInvoices: plan.invoices.length,
          planValue: plan.amount,
          selectedInstallments: selectedInvoices
            ?.map(inv => inv.index)
            ?.join(', ')
        }
      }
    });
  };

  return (
    <>
      <Stack
        css={{
          p: '$5',
          w: '$full',
          flexWrap: 'wrap',
          '@lg': { flexWrap: 'nowrap' }
        }}
      >
        <div className="border rounded-lg p-2 mb-2 w-full">
          <Row>
            {planTags(plan).map(item => (
              <Col key={item.title} md={4} style={{ margin: 10 }}>
                <Title level={5}>{item.title}</Title>

                <div className="flex gap-2">
                  <Text>{item.text ? `${item.text}` : '-'}</Text>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Stack>
      <Box css={{ p: '$5', width: '100%' }}>
        <Tabs style={{ width: '100%' }}>
          <Tabs.TabPane
            style={{ width: '100%' }}
            tab="Parcelas pendentes"
            key="due-invoices"
          >
            <Stack
              flow={{ '@initial': 'column', '@xl': 'row' }}
              css={{
                flexWrap: 'wrap',
                w: '$full',
                gap: '$5',
                '@xl': { flexWrap: 'nowrap' }
              }}
            >
              <InvoiceTable
                data={pendingInvoices}
                onSelect={setInvoiceSelection}
                showPayments
                orderId={orderId}
                preferences={plan.preferences}
                plan={plan}
              />
              <Box css={{ w: '100%', '@lg': { maxW: '384px' } }}>
                <Stack
                  flow="column"
                  css={{
                    rounded: '$lg',
                    bgColor: '$neutral100',
                    p: '$6',
                    gap: '$6',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box>
                    <Stack
                      css={{
                        whiteSpace: 'nowrap',
                        justifyContent: 'space-between',
                        gap: '$4'
                      }}
                    >
                      <Text
                        variant="xs"
                        css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                      >
                        Selecionadas
                      </Text>
                      <Text
                        weight="semibold"
                        variant="xs"
                        css={{ whiteSpace: 'initial' }}
                      >
                        {selectedInvoices.length === 0
                          ? 'Nenhuma'
                          : selectedInvoices
                              .map(({ index }) => `${index}`)
                              .join(', ')}
                      </Text>
                    </Stack>
                    <Stack
                      css={{
                        whiteSpace: 'nowrap',
                        justifyContent: 'space-between',
                        gap: '$4'
                      }}
                    >
                      <Text
                        variant="xs"
                        css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                      >
                        Valor
                      </Text>
                      <Text weight="semibold" variant="xs" css={{ d: 'block' }}>
                        {selectedInvoices.length === 0
                          ? '-'
                          : formatMoney(
                              currentAmount(selectedInvoices) as IMoney
                            )}
                      </Text>
                    </Stack>
                  </Box>

                  <Button
                    variant="primary"
                    disabled={selectedInvoices.length === 0}
                    onClick={createOrderDeal}
                  >
                    Renegociar parcelas
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Tabs.TabPane>
          <Tabs.TabPane
            style={{ width: '100%' }}
            tab="Parcelas pagas"
            key="paid-invoices"
          >
            <Stack
              flow={{ '@initial': 'column', '@xl': 'row' }}
              css={{
                flexWrap: 'wrap',
                w: '$full',
                gap: '$5',
                '@xl': { flexWrap: 'nowrap' }
              }}
            >
              <InvoiceTable
                data={planInvoices['paid']?.sort((a, b) => a.index - b.index)}
                orderId={orderId}
                preferences={plan.preferences}
                plan={plan}
              />
              <Box css={{ w: '100%', '@lg': { maxW: '384px' } }}>
                <Stack
                  flow="column"
                  css={{
                    rounded: '$lg',
                    bgColor: '$neutral100',
                    p: '$6',
                    gap: '$6',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box>
                    <Stack
                      css={{
                        whiteSpace: 'nowrap',
                        justifyContent: 'space-between',
                        gap: '$4'
                      }}
                    >
                      <Text
                        variant="xs"
                        css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                      >
                        Parcelas pagas
                      </Text>
                      <Text
                        weight="semibold"
                        variant="xs"
                        css={{ whiteSpace: 'initial' }}
                      >
                        {!planInvoices['paid'] ||
                        planInvoices['paid']?.length === 0
                          ? 'Nenhuma'
                          : planInvoices['paid']
                              ?.map(({ index }) => `${index}`)
                              .join(', ')}
                      </Text>
                    </Stack>
                    <Stack
                      css={{
                        whiteSpace: 'nowrap',
                        justifyContent: 'space-between',
                        gap: '$4'
                      }}
                    >
                      <Text
                        variant="xs"
                        css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                      >
                        Valor pago
                      </Text>
                      <Text weight="semibold" variant="xs" css={{ d: 'block' }}>
                        {!planInvoices['paid'] ||
                        planInvoices['paid']?.length === 0
                          ? '-'
                          : formatMoney(
                              currentAmount(planInvoices['paid']) as IMoney
                            )}
                      </Text>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Tabs.TabPane>
          <Tabs.TabPane
            style={{ width: '100%' }}
            tab="Parcelas renegociadas"
            key="dealed-invoices"
          >
            <Stack
              flow={{ '@initial': 'column', '@xl': 'row' }}
              css={{
                w: '$full',
                gap: '$5',
                flexWrap: 'wrap',
                '@xl': { flexWrap: 'nowrap' }
              }}
            >
              <InvoiceTable
                data={planInvoices['dealed']?.sort((a, b) => a.index - b.index)}
                orderId={orderId}
                preferences={plan.preferences}
                plan={plan}
              />
              <Box css={{ w: '100%', '@lg': { maxW: '384px' } }}>
                <Stack
                  flow="column"
                  css={{
                    rounded: '$lg',
                    bgColor: '$neutral100',
                    p: '$6',
                    gap: '$6',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box>
                    <Stack
                      css={{
                        whiteSpace: 'nowrap',
                        justifyContent: 'space-between',
                        gap: '$4'
                      }}
                    >
                      <Text
                        variant="xs"
                        css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                      >
                        Parcelas renegociadas
                      </Text>
                      <Text
                        weight="semibold"
                        variant="xs"
                        css={{ whiteSpace: 'initial' }}
                      >
                        {!planInvoices['dealed'] ||
                        planInvoices['dealed']?.length === 0
                          ? 'Nenhuma'
                          : planInvoices['dealed']
                              ?.sort((a, b) => a.index - b.index)
                              .map(({ index }) => `${index}`)
                              .join(', ')}
                      </Text>
                    </Stack>
                    <Stack
                      css={{
                        whiteSpace: 'nowrap',
                        justifyContent: 'space-between',
                        gap: '$4'
                      }}
                    >
                      <Text
                        variant="xs"
                        css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                      >
                        Valor Renegociado
                      </Text>
                      <Text weight="semibold" variant="xs" css={{ d: 'block' }}>
                        {!planInvoices['dealed'] ||
                        planInvoices['dealed']?.length === 0
                          ? '-'
                          : formatMoney(
                              currentAmount(planInvoices['dealed']) as IMoney
                            )}
                      </Text>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Tabs.TabPane>
        </Tabs>
      </Box>
    </>
  );
};

const handleInvoiceFee = (
  invoice: IOrderPaymentPlansDetail['invoices'][number]
) => {
  const { fines_amount: fines, interest_amount: interest } = invoice;
  const sum = fines.cents + interest.cents;
  return sum === 0 ? '-' : formatMoney({ cents: sum, currency_iso: 'BRL' });
};

const InvoiceTable = ({
  data,
  onSelect,
  showPayments,
  orderId,
  preferences,
  plan
}: {
  data: IOrderPaymentPlansDetail['invoices'];
  onSelect?: (
    value: boolean,
    currentInv: IOrderPaymentPlansDetail['invoices'][number]
  ) => void;
  showPayments?: boolean;
  orderId: string;
  preferences: Record<string, unknown>;
  plan: IOrderPaymentPlansDetail;
}) => {
  return (
    <Table
      dataSource={data as IOrderPaymentPlansDetail['invoices']}
      rowKey="id"
      pagination={false}
      bordered
      scroll={{ x: true }}
      style={{ width: '100%', maxWidth: 'unset' }}
    >
      <Table.Column
        dataIndex="index"
        key="index"
        title="Parcela"
        render={(amount, currentInvoice) => {
          const onChange = (v: boolean) => {
            onSelect?.(
              v,
              currentInvoice as IOrderPaymentPlansDetail['invoices'][number]
            );
          };

          return (
            <Stack css={{ gap: '$2' }}>
              {onSelect ? (
                <Checkbox label={`${amount + 1}`} onCheckedChange={onChange} />
              ) : (
                <TextField value={amount + 1} />
              )}
            </Stack>
          );
        }}
      />
      <Table.Column
        dataIndex="total_amount"
        key="total_amount"
        title="Valor total"
        render={amount => <TextField value={formatMoney(amount)} />}
      />
      <Table.Column<IOrderPaymentPlansDetail['invoices'][number]>
        dataIndex="fee"
        key="fee"
        title="Juros e Multa"
        render={(_, record) => <TextField value={handleInvoiceFee(record)} />}
      />
      <Table.Column
        dataIndex="due_at"
        key="due_at"
        title="Vencimento"
        render={amount => (
          <TextField value={formatDate(amount, 'DD/MM/YYYY')} />
        )}
      />
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={status => renderStatusTag(status)}
      />
      {showPayments && (
        <Table.Column
          dataIndex="payments"
          key="payments"
          title="Pagamento"
          render={(
            payments: IOrderPayment[],
            invoice: IOrderPaymentPlansDetail['invoices'][number]
          ) => {
            const currentPayment = payments.find(
              payment =>
                payment['expires_at'] &&
                new Date(payment['expires_at']) > new Date()
            );

            const externalPayment = payments.find(p => p.kind === 'external');

            if (externalPayment && externalPayment.status !== 'approved') {
              return <>Baixa em processamento</>;
            }

            if (externalPayment && externalPayment.status === 'approved') {
              return <>Baixa realizada</>;
            }

            if (currentPayment && currentPayment.kind === 'boleto') {
              return (
                <Stack
                  flow="column"
                  css={{ alignItems: 'flex-start', gap: '$5' }}
                >
                  {invoice.status !== 'cancelled' && (
                    <Button
                      variant="neutralLink"
                      size="sm"
                      as="a"
                      target="blank"
                      href={(currentPayment?.info?.url as string) || '404'}
                    >
                      Visualizar boleto
                    </Button>
                  )}
                  {plan.status === 'cancelled' &&
                    invoice.status !== 'cancelled' && (
                      <CancelInvoiceButton
                        invoice={invoice}
                        orderId={orderId}
                      />
                    )}
                </Stack>
              );
            }

            if (currentPayment && currentPayment.kind === 'pix') {
              return (
                <Stack
                  flow="column"
                  css={{ alignItems: 'flex-start', gap: '$5' }}
                >
                  {invoice.status !== 'cancelled' && (
                    <CopyToClipboard
                      size="sm"
                      text={
                        currentPayment?.info?.qr_code || 'Código não encontrado'
                      }
                    >
                      Copiar código
                    </CopyToClipboard>
                  )}
                  {plan.status === 'cancelled' &&
                    invoice.status !== 'cancelled' && (
                      <CancelInvoiceButton
                        invoice={invoice}
                        orderId={orderId}
                      />
                    )}
                </Stack>
              );
            }

            if (['due'].includes(invoice.status)) {
              return (
                <Stack
                  flow="column"
                  css={{ alignItems: 'flex-start', gap: '$5' }}
                >
                  <CreatePaymentButton
                    invoice={invoice}
                    orderId={orderId}
                    preferences={preferences}
                  />
                  <CreateExternalPaymentButton
                    invoice={invoice}
                    orderId={orderId}
                    preferences={preferences}
                  />
                  {plan.status === 'cancelled' && (
                    <CancelInvoiceButton invoice={invoice} orderId={orderId} />
                  )}
                </Stack>
              );
            }

            if (['initial'].includes(invoice.status)) {
              return (
                <Stack
                  flow="column"
                  css={{ alignItems: 'flex-start', gap: '$5' }}
                >
                  <CreatePaymentButton
                    invoice={invoice}
                    orderId={orderId}
                    preferences={preferences}
                  />
                  <CreateExternalPaymentButton
                    invoice={invoice}
                    orderId={orderId}
                    preferences={preferences}
                  />
                </Stack>
              );
            }

            return <></>;
          }}
        />
      )}
    </Table>
  );
};

const CreatePaymentButton = ({
  invoice,
  orderId,
  preferences
}: {
  orderId: string;
  invoice: IOrderPaymentPlansDetail['invoices'][number];
  preferences: Record<string, unknown>;
}) => {
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();

  const [loading, setLoading] = useState(false);

  const generatePayment = () => {
    setLoading(true);
    mutate(
      {
        url: `${apiUrl}/orders/${orderId}/invoices/${invoice.id}/pay`,
        method: 'post',
        values: {
          invoice_id: invoice.id,
          payer: preferences['info']?.['payer'],
          kind: preferences['kind'],
          installments: preferences['installments']
        },
        successNotification: () => {
          return {
            description: 'Tudo certo!',
            message:
              'Seu pagamento foi gerado e o link estará disponível em alguns instantes',
            type: 'success'
          };
        },
        errorNotification: () => {
          return {
            description: 'Algo deu errado',
            message: 'Não foi possível concluir esta ação',
            type: 'error'
          };
        }
      },
      {
        onError: error => {
          // eslint-disable-next-line no-console
          console.log(error);
        },
        onSuccess: () => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    );

    setLoading(false);
  };

  return (
    <Button
      size="sm"
      loading={loading}
      onClick={generatePayment}
      variant="neutralLink"
    >
      Gerar pagamento
    </Button>
  );
};

const CreateExternalPaymentButton = ({
  invoice,
  orderId,
  preferences
}: {
  orderId: string;
  invoice: IOrderPaymentPlansDetail['invoices'][number];
  preferences: Record<string, unknown>;
}) => {
  const { data: permission } = useCan({
    resource: 'payment_plans',
    action: 'cancel'
  });

  const showBtn = permission?.can;
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();

  const [loading, setLoading] = useState(false);
  const { show, modalProps, close } = useModal();

  const generatePayment = () => {
    setLoading(true);
    mutate(
      {
        url: `${apiUrl}/orders/${orderId}/invoices/${invoice.id}/resolve`,
        method: 'post',
        values: {
          payer: preferences?.['info']?.['payer'],
          installments: preferences['installments']
        },
        successNotification: () => {
          return {
            description: 'Tudo certo!',
            message: 'A baixa foi realizada e o está sendo processada',
            type: 'success'
          };
        },
        errorNotification: () => {
          return {
            description: 'Algo deu errado',
            message: 'Não foi possível concluir esta ação',
            type: 'error'
          };
        }
      },
      {
        onError: error => {
          setLoading(false);
          close();
          // eslint-disable-next-line no-console
          console.log(error);
        },
        onSuccess: () => {
          setLoading(false);
          close();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    );
  };

  if (!showBtn) {
    return <></>;
  }

  return (
    <>
      <Button
        size="sm"
        loading={loading}
        onClick={() => show()}
        variant="neutralLink"
      >
        Dar baixa
      </Button>
      <ConfirmationModal
        closable={false}
        okText="Confirmar"
        onOk={generatePayment}
        bodyTitle="Dar baixa"
        bodyText="Ao confirmar essa ação, você se responsabiliza pela garantia de que esse pagamento já foi efetuado. Esta ação não poderá ser desfeita. Deseja continuar?"
        width={500}
        {...modalProps}
      />
    </>
  );
};

const CancelInvoiceButton = ({
  invoice,
  orderId
}: {
  orderId: string;
  invoice: IOrderPaymentPlansDetail['invoices'][number];
}) => {
  const { data: permission } = useCan({
    resource: 'payment_plans',
    action: 'cancel'
  });

  const showBtn = permission?.can;

  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();
  const { show, modalProps, close } = useModal();

  const [loading, setLoading] = useState(false);

  const generatePayment = () => {
    setLoading(true);
    mutate(
      {
        url: `${apiUrl}/orders/${orderId}/invoices/${invoice.id}/cancel`,
        method: 'post',
        values: {},
        successNotification: () => {
          return {
            description: 'Tudo certo!',
            message: 'A cobrança da parcela foi cancelada com sucesso!',
            type: 'success'
          };
        },
        errorNotification: () => {
          return {
            description: 'Algo deu errado',
            message: 'Não foi possível concluir esta ação',
            type: 'error'
          };
        }
      },
      {
        onError: error => {
          setLoading(false);
          close();
          // eslint-disable-next-line no-console
          console.log(error);
        },
        onSuccess: () => {
          setLoading(false);
          close();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    );
  };

  if (!showBtn) {
    return <></>;
  }

  return (
    <>
      <Button
        size="sm"
        loading={loading}
        onClick={() => show()}
        variant="neutralLink"
      >
        Cancelar cobrança
      </Button>
      <ConfirmationModal
        closable={false}
        okText="Confirmar"
        onOk={generatePayment}
        bodyTitle="Cancelar cobrança"
        bodyText="Ao confirmar essa ação, você se responsabiliza pela garantia de que esta cobrança foi indevida. Essa ação não poderá ser desfeita. Deseja continuar?"
        width={500}
        {...modalProps}
      />
    </>
  );
};

const INVOICE_STATUS_MAP: Record<
  string,
  'neutral' | 'success' | 'warning' | 'danger' | 'primary'
> = {
  initial: 'neutral',
  paid: 'success',
  cancelled: 'warning',
  due: 'danger',
  dealed: 'warning'
};

const renderStatusTag = (status: string) => {
  const translated = paymentPlanInvoicesStatus(status);
  const variant = INVOICE_STATUS_MAP[status] || 'primary';

  return <Badge variant={variant}>{translated}</Badge>;
};

const reduceInvoicesByStatus = (
  invoices: IOrderPaymentPlansDetail['invoices']
) => {
  return invoices.reduce((acc, invoice) => {
    const { status } = invoice;

    if (!acc[status]) {
      acc[status] = [];
    }

    acc[status].push(invoice);

    return acc;
  }, {} as Record<string, IOrderPaymentPlansDetail['invoices']>);
};
