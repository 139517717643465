import Details from 'components/videos-management/Details';
import { Edit, useForm } from '@refinedev/antd';
import { IDamVideo } from 'interfaces/dam_videos';

export const VideoEdit = () => {
  const { id } = useForm<IDamVideo>();
  const { formProps, saveButtonProps, queryResult } = useForm<IDamVideo>({
    resource: 'prometheus/videos',
    redirect: 'edit',
    action: 'edit',
    id: id!
  });
  const record = queryResult?.data?.data;

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar vídeo">
      {record && <Details video={record} formProps={formProps} />}
    </Edit>
  );
};
