import * as Icons from '@ant-design/icons';
import { Button } from 'antd';
import { getTenant } from 'services/tenants';

const authorizationUri = 'https://accounts.google.com/o/oauth2/auth';

const GoogleLoginButton: React.FC = () => {
  const tenant = getTenant();
  const { origin } = window.location;

  const params = {
    client_id: tenant.googleId!,
    redirect_uri: `${origin}/oauth/google`,
    scope: 'email profile',
    response_type: 'code',
    access_yype: 'offline',
    prompt: 'consent',
    include_granted_scopes: 'true'
  };

  const qs = new URLSearchParams(Object.entries(params));

  const url = `${authorizationUri}?${qs.toString()}`;

  return (
    <Button
      href={url}
      className="!flex items-center justify-center w-full ant-btn-lg !p-0"
    >
      <Icons.GoogleOutlined />
      Login com Google
    </Button>
  );
};

export default GoogleLoginButton;
