import * as Icons from '@ant-design/icons';
import { BulkUserCreate } from 'pages/academic/bulk_users';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'bulk_users',
  label: 'Lote Aluno',
  create: BulkUserCreate,
  actions: ['create'],
  icon: <Icons.UserOutlined />,
  parentName: 'academic'
};

export default generateResources(resource);
