import * as Icons from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { CrudFilters, HttpError, useNotification } from '@refinedev/core';
import { ICourse } from 'interfaces/courses';
import { ICourseCertificate } from 'interfaces/course_certificate';
import { List, ShowButton, TextField, useTable } from '@refinedev/antd';
import { PendingCertificatesFilter } from './PendingCertificatesFilter';
import { downloadFileFromBlob } from 'utils/donwload-from-blob';
import { formatDate } from 'services/date';
import { generateURLWithFilters } from 'services/certificate';
import { getAPI } from 'requests/api';
import { useCallback } from 'react';

export interface IFilter {
  by_registration: string;
  search_user_name: string;
  by_cpf: string;
  by_course_id: string;
  started_after: string;
  started_before: string;
}

const PendingCertificates = () => {
  const notification = useNotification();
  const { tableProps, searchFormProps } = useTable<
    ICourseCertificate,
    HttpError,
    IFilter
  >({
    resource: 'graduate/courses/enrollments',
    filters: {
      permanent: [
        {
          field: 'filter[ready_for_certificate]',
          operator: 'eq',
          value: 'true'
        }
      ]
    },
    onSearch: params => {
      const filters: CrudFilters = [];
      const {
        by_registration,
        search_user_name,
        by_cpf,
        by_course_id,
        started_after,
        started_before
      } = params;

      filters.push(
        {
          field: 'filter[by_registration]',
          operator: 'eq',
          value: by_registration
        },
        {
          field: 'filter[search_user_name]',
          operator: 'eq',
          value: search_user_name
        },
        {
          field: 'filter[by_cpf]',
          operator: 'eq',
          value: by_cpf
        },
        {
          field: 'filter[by_course_id]',
          operator: 'eq',
          value: by_course_id
        },
        {
          field: 'filter[started_after]',
          operator: 'eq',
          value: started_after
        },
        {
          field: 'filter[started_before]',
          operator: 'eq',
          value: started_before
        }
      );

      return filters;
    }
  });

  const handleDownloadCertificate = useCallback(async () => {
    try {
      const response = await getAPI().get(
        generateURLWithFilters<IFilter>(
          searchFormProps.form?.getFieldsValue(),
          'admin/graduate/courses/enrollments/report'
        ),
        {
          responseType: 'blob'
        }
      );

      downloadFileFromBlob(
        new Blob([response.data]),
        'relatorio_certificados_a_emitir.csv'
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === 404) {
        notification.open?.({
          type: 'error',
          message: 'Download Error',
          description: 'Não há relatório de certificados disponível.'
        });
      } else {
        notification.open?.({
          type: 'error',
          message: 'Download Error',
          description: 'Erro ao baixar o relatório de certificados.'
        });
      }
    }
  }, [notification, searchFormProps]);

  return (
    <List
      breadcrumb={null}
      title={''}
      canCreate={false}
      resource="graduate/courses/pending_certificates"
    >
      <PendingCertificatesFilter searchFormProps={searchFormProps} />
      <Space className="w-full justify-end mb-6">
        <Button
          onClick={handleDownloadCertificate}
          icon={<Icons.DownloadOutlined />}
        >
          Baixar relatório
        </Button>
      </Space>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="registration"
          key="registration"
          title="Nº de matrícula"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="user"
          key="name"
          title="Nome do aluno"
          render={({ name }) => <TextField value={name} />}
        />
        <Table.Column
          dataIndex="user"
          key="cpf"
          title="CPF"
          render={({ personal_info }) => (
            <TextField value={personal_info?.document_info ?? '-'} />
          )}
        />
        <Table.Column<ICourse>
          dataIndex="course"
          key="course"
          title="Curso"
          render={({ name }) => <TextField value={name} />}
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title="Data de início"
          render={value => (
            <TextField value={formatDate(value, 'DD/MM/YYYY')} />
          )}
        />
        <Table.Column<ICourseCertificate>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <ShowButton
                resource="graduate/courses/pending_certificates"
                hideText
                size="small"
                recordItemId={record.id}
              />
            );
          }}
        />
      </Table>
    </List>
  );
};

export default PendingCertificates;
