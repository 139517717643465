import FilterForm from 'components/forms/FilterForm';
import dayjs from 'dayjs';
import {
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography
} from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  EditButton,
  ShowButton,
  TagField,
  TextField,
  useSelect,
  useTable
} from '@refinedev/antd';
import { IDocumentation } from 'interfaces/documentation/documentations';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { formatDate } from 'services/date';
import {
  getStatusColor,
  translateDocumentationStatus
} from 'services/documentations';

interface IFilter {
  courseId: string;
  byStatus: string;
  byName: string;
  byEmail: string;
  byDocumentInfo: string;
  byBeforeDate: string;
  byAfterDate: string;
}

interface FilterFormProps {
  searchFormProps: FormProps<IFilter>;
}

const Filter = ({ searchFormProps }: FilterFormProps) => {
  const { selectProps: courseSelectProps } = useSelect<IGraduateCourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={16} className="w-full">
        <Col xs={24} md={8}>
          <Form.Item label="Nome" name="byName">
            <Input placeholder="Nome" />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="E-mail" name="byEmail">
            <Input placeholder="e-mail do aluno" />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="CPF" name="byDocumentInfo">
            <Input placeholder="Somente números" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="w-full">
        <Col xs={24} md={8}>
          <Form.Item label="Curso" name="courseId">
            <Select placeholder="Digite..." allowClear {...courseSelectProps} />
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item label="Status" name="byStatus">
            <Select placeholder="Selecione" allowClear defaultValue="submitted">
              <Select.Option key={'pending'}>Pendente</Select.Option>
              <Select.Option key={'submitted'}>Submetido</Select.Option>
              <Select.Option key={'approved'}>Aprovado</Select.Option>
              <Select.Option key={'rejected'}>Rejeitado</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={5}>
          <Form.Item
            colon
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label="Início a partir de"
            name="byAfterDate"
          >
            <DatePicker
              className="w-full"
              placeholder="dia/mês/ano"
              format="DD/MM/YYYY"
              picker="date"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={5}>
          <Form.Item
            colon
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label="Término em"
            name="byBeforeDate"
          >
            <DatePicker
              className="w-full"
              placeholder="dia/mês/ano"
              format="DD/MM/YYYY"
              picker="date"
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};

const Audit = () => {
  const { tableProps, searchFormProps } = useTable<
    IDocumentation,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const {
        courseId,
        byStatus,
        byName,
        byEmail,
        byDocumentInfo,
        byAfterDate,
        byBeforeDate
      } = params;

      filters.push(
        {
          field: 'filter[by_course]',
          operator: 'eq',
          value: courseId
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: byStatus
        },
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value: byName
        },
        {
          field: 'filter[by_email]',
          operator: 'eq',
          value: byEmail
        },
        {
          field: 'filter[by_document_info]',
          operator: 'eq',
          value: byDocumentInfo
        },
        {
          field: 'filter[enrollment_created_after]',
          operator: 'eq',
          value: byAfterDate && dayjs(byAfterDate).format('YYYY-MM-DD')
        },
        {
          field: 'filter[enrollment_created_before]',
          operator: 'eq',
          value: byBeforeDate && dayjs(byBeforeDate).format('YYYY-MM-DD')
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: 'submitted'
        }
      ]
    }
  });
  return (
    <>
      <Filter searchFormProps={searchFormProps} />
      <Table {...tableProps} rowKey="id">
        <Table.Column<IDocumentation>
          dataIndex={['parent', 'user']}
          key="user_name"
          title="Informações do aluno"
          render={(_, record) => {
            return (
              <Space direction="vertical">
                <Space direction="horizontal">
                  <Typography.Text strong>Nome:</Typography.Text>
                  <TextField value={`${record.parent.user.name}`} />
                </Space>

                <Space direction="horizontal">
                  <Typography.Text strong>E-mail:</Typography.Text>
                  <TextField value={`${record.parent.user.email}`} />
                </Space>

                <Space direction="horizontal">
                  <Typography.Text strong>CPF:</Typography.Text>
                  <TextField
                    value={`${record.user_document_info || 'Não informado'}`}
                  />
                </Space>

                <Space direction="horizontal">
                  <Typography.Text strong>Data de início:</Typography.Text>
                  <TextField
                    value={`${formatDate(record.parent.created_at, 'lll')}`}
                  />
                </Space>
              </Space>
            );
          }}
        />
        <Table.Column
          dataIndex={['parent', 'course', 'name']}
          key="course_name"
          title="Curso"
          render={value => <TextField value={value} />}
        />
        <Table.Column<IDocumentation>
          key="total"
          title="Documentos"
          render={(_, record) => {
            const {
              approved_documents_count,
              rejected_documents_count,
              pending_documents_count
            } = record;

            return (
              <>
                <Space align="start" direction="vertical">
                  <TextField
                    value={`✅ ${approved_documents_count} deferidos`}
                  />

                  <TextField
                    value={`❌ ${rejected_documents_count} indeferidos`}
                  />

                  <TextField
                    value={`🕒 ${pending_documents_count} aguardando validação`}
                  />
                </Space>
              </>
            );
          }}
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title="Status"
          render={value => (
            <TagField
              color={getStatusColor(value)}
              value={translateDocumentationStatus(value)}
            />
          )}
        />
        <Table.Column<IDocumentation>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space>
                <Tooltip title="Validar documentos">
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Tooltip>
                <Tooltip title="Visualizar detalhes">
                  <ShowButton hideText size="small" recordItemId={record.id} />
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
    </>
  );
};

export default Audit;
