/* eslint-disable unicorn/filename-case */
import { CrudFilters } from '@refinedev/core';
import { IProductCategory } from '../interfaces/product_categories';
import { useSelect } from '@refinedev/antd';

interface ParentCategorySelectProps {
  forbiddenId?: string;
  defaultValue?: string;
}

function useParentCategoriesSelect({
  forbiddenId,
  defaultValue
}: ParentCategorySelectProps) {
  const filterChildrenOut: CrudFilters = [
    {
      field: 'filter[without_parent]',
      operator: 'eq',
      value: true
    }
  ];

  const {
    selectProps: { options, ...subCategorySelectProps }
  } = useSelect<IProductCategory>({
    resource: 'products/categories',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: defaultValue,
    filters: filterChildrenOut,
    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ]
  });

  const filteredOptions = () => {
    return forbiddenId
      ? options?.filter(opt => opt.value !== forbiddenId)
      : options;
  };

  return {
    options: filteredOptions(),
    ...subCategorySelectProps
  };
}

export default useParentCategoriesSelect;
