import { IUser } from 'interfaces/users';
import { Show } from '@refinedev/antd';
import { Tabs, Typography } from 'antd';
import { UserBonuses } from './components/show/UserBonuses';
import { UserDetails } from './components/show/UserDetails';
import { UserEnrollments } from './components/show/UserEnrollments';
import { UserOrders } from './components/show/UserOrders';
import { UserPossessions } from './components/show/UserPossessions';
import { useShow } from '@refinedev/core';

export const UserShow = () => {
  const { queryResult } = useShow<IUser>({
    errorNotification: {
      type: 'error',
      message: 'Erro ao carregar dados do usuário. Tente novamente!'
    }
  });
  const { data, isLoading } = queryResult;
  const userData = data?.data;

  if (!isLoading && !userData) {
    <Show isLoading={isLoading} title="Visualizar aluno">
      <Typography.Text>Nenhuma informação encontrada</Typography.Text>
    </Show>;
  }

  return (
    <Show
      isLoading={isLoading}
      title="Visualizar aluno"
      contentProps={{ className: '[&>div:bg-red-200' }}
    >
      <Tabs defaultActiveKey="info">
        <Tabs.TabPane tab="Informações gerais" key="info">
          <UserDetails userData={userData!} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pedidos" key="orders">
          <UserOrders userData={userData!} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cursos" key="enrollments">
          <UserEnrollments userData={userData!} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Acessos" key="access">
          <UserPossessions userData={userData!} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Bônus" key="bonus">
          <UserBonuses userData={userData!} />
        </Tabs.TabPane>
      </Tabs>
    </Show>
  );
};
