import * as Icons from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import { IOrderItem } from 'interfaces/manual_offer';
import { TOffer } from '../../pages/ecommerce/manual_order';
import { formatMoney } from '../../services/money';
import { pluralize } from '../../utils/pluralize';
import type { ColumnsType } from 'antd/es/table';

interface IProductTableList {
  data?: TOffer[];
  removeFn: (itemIndex: number) => void;
}

const ProductTableList = ({ data, removeFn }: IProductTableList) => {
  const tableColumns: ColumnsType<IOrderItem> = [
    {
      title: 'Nome da oferta',
      dataIndex: 'product_name',
      key: 'product_name'
    },
    {
      title: 'Nome do item',
      dataIndex: 'item_name',
      key: 'item_name'
    },
    {
      title: 'Acesso',
      dataIndex: 'time_in_days',
      key: 'time_in_days',
      render: value => <span>{value} dia(s)</span>
    },
    {
      title: 'Duração',
      dataIndex: 'service_duration_in_months',
      key: 'service_duration_in_months',
      render: value => (
        <span>{value ? pluralize(value, 'mes', 'meses') : '-'}</span>
      )
    },
    {
      title: 'Preço',
      dataIndex: 'product_price',
      key: 'product_price',
      render: value => (
        <span>{formatMoney({ cents: value, currency_iso: 'BRL' })}</span>
      )
    },
    {
      title: 'Tipo da oferta',
      dataIndex: 'kind',
      key: 'kind',
      render: value => (
        <Tag color={value === 'online' ? 'blue' : 'green'}>
          {value === 'online' ? 'Online' : 'Gerencial'}
        </Tag>
      )
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      render: (_, __, index) => (
        <Button size="small" onClick={() => removeFn(index)}>
          <Icons.DeleteOutlined />
        </Button>
      )
    }
  ];
  return (
    <Table
      bordered={true}
      tableLayout="auto"
      columns={tableColumns}
      dataSource={data}
      pagination={false}
    />
  );
};

export default ProductTableList;
