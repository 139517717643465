import * as Icons from '@ant-design/icons';
import { Button, Card, Form, Space, Typography } from 'antd';
import { DocumentItemField } from './DocumentItemField';
import { DocumentItemImage } from './DocumentItemImage';
import { IDocument } from 'interfaces/documentation/document';
import { formatDate } from 'services/date';
import { getApproveDocumentsPayload } from 'services/documents';
import { useCallback } from 'react';
import { useDocumentationStore } from '../documentation-store';

const { Text } = Typography;

interface DocumentItemProps {
  document: IDocument;
}

export const DocumentItem = ({ document }: DocumentItemProps) => {
  const [form] = Form.useForm();
  const { created_at, attribute_fields, feedback } = document;
  const openModal = useDocumentationStore(s => s.openModal);

  const submit = useCallback(
    (values: Record<string, unknown>) => {
      openModal('approve', getApproveDocumentsPayload(document, values));
    },
    [document, openModal]
  );
  const isApproved = document.status === 'approved';
  const isRejected = document.status === 'rejected';

  return (
    <Form form={form} layout="vertical" onFinish={submit}>
      <Space direction="vertical" size="middle" className="w-full">
        <Space size={['middle', undefined]} wrap>
          {attribute_fields?.map((field, index) => (
            <DocumentItemField key={index} field={field} />
          ))}
        </Space>

        <DocumentItemImage document={document} />

        <Text>
          Documento enviado em {formatDate(created_at, 'DD/MM/YYYY - HH:mm')}
        </Text>

        {feedback && (
          <Card
            type="inner"
            size={'small'}
            title={'Por que este documento foi indeferido?'}
          >
            <Typography.Text>{feedback}</Typography.Text>
          </Card>
        )}

        <Space className="w-full justify-between pt-2">
          <Button
            type="text"
            icon={<Icons.SwapOutlined />}
            onClick={() => openModal('setKind', document)}
          >
            Trocar categoria do documento
          </Button>

          <Space>
            <Button
              danger
              icon={<Icons.CloseOutlined />}
              onClick={() => openModal('reject', document)}
              disabled={isRejected}
            >
              Rejeitar
            </Button>
            <Button
              style={{
                backgroundColor: isApproved ? '#f5f5f5' : '#039855',
                borderColor: isApproved ? '#d9d9d9' : '#039855'
              }}
              type="primary"
              htmlType="submit"
              icon={<Icons.CheckOutlined />}
              disabled={isApproved}
            >
              Aprovar
            </Button>
          </Space>
        </Space>
      </Space>
    </Form>
  );
};
