import { EVALUATION_KIND_MAP } from 'services/graduate-enrollment/evaluations';
import { IClassroomEvaluation } from 'interfaces/classrooms';
import { TextField } from '@refinedev/antd';
import { parseToDayjs } from 'services/date';

export interface EvaluationsHeaderProps {
  evaluationData: IClassroomEvaluation;
}

const EvaluationsHeader = ({ evaluationData }: EvaluationsHeaderProps) => {
  const getRangeDate = (type: 'start' | 'end') => {
    const { requirements } = evaluationData;
    const operator = type === 'start' ? 'after' : 'before';
    const date = requirements?.[0].rules.find(
      element => element.operator === operator
    )?.value;

    return date ? parseToDayjs(String(date), 'DD/MM/YYYY') : null;
  };

  return (
    <>
      {Array.from({ length: 5 })
        .fill(null)
        // eslint-disable-next-line sonarjs/cognitive-complexity
        .map((_, index) => (
          <div key={index}>
            <span className="text-gray-700 text-14 font-Inter font-light">
              {index === 0 ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <TextField
                    value={`Tipo: ${
                      evaluationData.evaluation_type === 'Assessment'
                        ? 'Avaliação ' +
                          EVALUATION_KIND_MAP[evaluationData.evaluation_kind]
                        : 'Atividade'
                    }`}
                  />
                </div>
              ) : index === 1 && evaluationData.kind !== 'final' ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <TextField value={`Peso: ${evaluationData.weight}`} />
                </div>
              ) : index === 2 ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <TextField
                    value={`Nota mínima: ${evaluationData.passing_score}`}
                  />
                </div>
              ) : index === 3 ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <TextField
                    value={`Tentativas: ${evaluationData.max_attempts}`}
                  />
                </div>
              ) : index === 4 ? (
                <div className="flex items-center justify-center gap-1 text-black">
                  <TextField
                    value={`Acesso: ${getRangeDate('start')} - ${getRangeDate(
                      'end'
                    )}`}
                  />
                </div>
              ) : null}
            </span>
          </div>
        ))}
    </>
  );
};

export default EvaluationsHeader;
