import React, { useState } from 'react';
import { IDamVideo } from 'interfaces/dam_videos';
import { Modal, Spin } from 'antd';
import { useModalReturnType } from '@refinedev/core';

interface Props {
  modal: useModalReturnType;
  video: IDamVideo;
}

const VideoPreviewModal = ({ modal, video }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <Modal
      title="Video Preview"
      width={600}
      footer={[]}
      visible={modal.visible}
      centered
      onCancel={modal.close}
    >
      <div className="w-full flex alingn-center justify-center">
        {isLoading && <Spin className="m-auto" tip="Loading" size="large" />}
        <iframe
          className="w-full"
          style={{ display: `${isLoading ? 'none' : 'block'}` }}
          height={300}
          src={video.preview_url}
          title={video.title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          onLoad={() => setIsLoading(false)}
        />
      </div>
    </Modal>
  );
};

export default VideoPreviewModal;
