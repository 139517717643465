import { EditButton, TextField } from '@refinedev/antd';
import { IGroup } from 'interfaces/epimetheus/groups';
import { Space, Table, TableProps } from 'antd';
import { useParams } from 'react-router-dom';

interface TagGroupsTableProps {
  tableProps: TableProps;
}

export const TagGroupsTable = ({ tableProps }: TagGroupsTableProps) => {
  const { id: libraryId } = useParams();
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="id"
        width={350}
        key="id"
        title="Id"
        render={value => <TextField value={value} />}
      />

      <Table.Column
        dataIndex="name"
        key="name"
        title="Nome"
        render={value => <TextField value={value} />}
      />

      <Table.Column<IGroup>
        title="Ações"
        width={100}
        dataIndex="actions"
        render={(_, record) => {
          return (
            <Space>
              <EditButton
                hideText
                size="small"
                resource="tag-groups"
                meta={{ libraryId: libraryId }}
                recordItemId={record.id}
              />
            </Space>
          );
        }}
      />
    </Table>
  );
};
