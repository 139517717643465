import { createStore } from '@qcx/store';

export interface TreeStoreState {
  resourceId: string;
}

const initialState: TreeStoreState = { resourceId: 'Store is working 🚀' };

const { Provider, useStore } = createStore(initialState, ({ set }) => ({
  setResourceId: (resourceId: string) => set({ resourceId })
}));

export const TreeStoreProvider = Provider;

export const useTreeStore = useStore;
