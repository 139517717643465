import FilterForm from 'components/forms/FilterForm';
import { CrudFilters, HttpError } from '@refinedev/core';
import { Form, Input } from 'antd';
import { IAssessmentQuestion } from 'interfaces/assessments';
import { QuestionsTable } from './QuestionsTable';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';

interface IFilter {
  statement: string;
}

export const TabQuestions = () => {
  const params = useParams();
  const assessmentId = params.id;

  const { tableProps, searchFormProps } = useTable<
    IAssessmentQuestion,
    HttpError,
    IFilter
  >({
    resource: 'assessment_questions',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { statement } = params;

      filters.push(
        {
          field: 'filter[assessment][]',
          operator: 'eq',
          value: [assessmentId]
        },
        {
          field: 'filter[statement]',
          operator: 'eq',
          value: statement
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[assessment][]',
          operator: 'eq',
          value: [assessmentId]
        }
      ]
    }
  });

  return (
    <>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Enunciado" name="statement">
          <Input placeholder="Digite parte do enunciado" />
        </Form.Item>
      </FilterForm>

      <QuestionsTable tableProps={tableProps} />
    </>
  );
};
