/* eslint-disable unicorn/filename-case */
export const RecurrenceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M18.333 8.333H1.667m7.5 7.5h6.5c.933 0 1.4 0 1.756-.181.314-.16.569-.415.729-.729.181-.356.181-.823.181-1.756V6.833c0-.933 0-1.4-.181-1.756a1.667 1.667 0 0 0-.729-.729c-.356-.181-.823-.181-1.756-.181h-1.5m-5 11.666 1.666 1.667m-1.666-1.667 1.666-1.666m-5 1.666h-1.5c-.933 0-1.4 0-1.756-.181a1.667 1.667 0 0 1-.729-.729c-.181-.356-.181-.823-.181-1.756V6.833c0-.933 0-1.4.181-1.756.16-.314.415-.569.729-.729.356-.181.823-.181 1.756-.181h6.5m0 0L9.167 5.833m1.666-1.666L9.167 2.5"
      />
    </svg>
  );
};
