import { getAPI } from 'requests/api';

export const downloadSolicitationDocument = async (
  document_id: string
): Promise<{ url: string }> => {
  const { data } = await getAPI().get(
    `/admin/solicitations/document_downloads/${document_id}`
  );

  return data;
};
