import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Edit, useForm } from '@refinedev/antd';
import { FormAssessment } from './components/FormAssessment';
import { IAssessment } from 'interfaces/assessments';

dayjs.extend(duration);

export const AssessmentEdit = () => {
  const form = useForm<IAssessment>({
    redirect: 'edit'
  });

  return (
    <Edit saveButtonProps={form.saveButtonProps} title="Editar Avaliação">
      <FormAssessment formInstance={form} />
    </Edit>
  );
};
