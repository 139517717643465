import FilterForm from '../../../components/forms/FilterForm';
import { Alert, Col, Form, Row, Select, Space, Table, Tooltip } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IOrder, IPaymentRefund } from 'interfaces/orders';
import {
  List,
  ShowButton,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { formatDate } from 'services/date';
import { formatMoney } from 'services/money';
import { translateStatus } from 'services/refunds';

interface IFilter {
  status: string;
}

export const PaymentRefundList = () => {
  const { tableProps, searchFormProps } = useTable<IOrder, HttpError, IFilter>({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { status } = params;

      filters.push({
        field: 'status',
        operator: 'eq',
        value: status
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'status',
          operator: 'eq',
          value: 'requested'
        }
      ]
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Tooltip title="O tempo de processamento de novas tentativas de reembolso é 24h">
              <Alert
                message="Tentativas de reembolso parcial (valor avulso ou item) do pedido."
                type="info"
                showIcon
              />
            </Tooltip>
            <FilterForm searchFormProps={searchFormProps}>
              <Form.Item label="Status" name="status">
                <Select
                  placeholder="Selecione..."
                  style={{ minWidth: 200 }}
                  defaultValue="requested"
                >
                  <Select.Option key="requested">Solicitado</Select.Option>
                  <Select.Option key="approved">Aprovado</Select.Option>
                  <Select.Option key="rejected">Rejeitado</Select.Option>
                  <Select.Option key="processing">Processando</Select.Option>
                  <Select.Option key="processed">Processado</Select.Option>
                  <Select.Option key="error">Erro</Select.Option>
                </Select>
              </Form.Item>
            </FilterForm>
          </Space>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={status => <TagField value={translateStatus(status)} />}
            />
            <Table.Column
              dataIndex="motive_kind"
              key="motive_kind"
              title="Motivo"
              render={motive => (
                <TagField
                  value={
                    motive === 'single_refund' ? 'Avulso' : 'Item do pedido'
                  }
                />
              )}
            />
            <Table.Column
              dataIndex="amount"
              key="amount"
              title="amount"
              render={amount => <TextField value={formatMoney(amount)} />}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Criação"
              render={createdAt => (
                <TextField value={formatDate(createdAt, 'DD/MM/YY HH:mm')} />
              )}
            />
            <Table.Column<IPaymentRefund>
              title="Actions"
              dataIndex="actions"
              align="center"
              render={(_, record) => (
                <Space>
                  <ShowButton
                    hideText
                    size="large"
                    resource="orders"
                    recordItemId={record.payment.order_id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
