import * as Icons from '@ant-design/icons';
import SupplementaryMaterialForm from 'components/forms/SupplementaryMaterialForm';
import { Alert } from 'antd';
import { IClassroom } from 'interfaces/classrooms';
import { Stack, Text } from '@qcx/ui';

export interface ClassroomSupplementarMaterialProps {
  classroom: IClassroom;
}

const ClassroomSupplementarMaterial = ({
  classroom
}: ClassroomSupplementarMaterialProps) => {
  return (
    <>
      <Alert
        style={{ marginBottom: '2rem', maxWidth: 600 }}
        type="info"
        showIcon
        icon={<Icons.InfoCircleFilled />}
        description={
          <Stack flow="column">
            <Text>• Clique em adicionar para criar um novo material.</Text>
            <Text>• Clique em upload para subir o material.</Text>
            <Text>• Adicione um nome ao material.</Text>
            <Text>
              • Clique em "Compartilhar arquivos com a turma" para publicar o
              material.
            </Text>
          </Stack>
        }
      />
      <SupplementaryMaterialForm
        parent_reference={String(classroom.id)}
        parent_kind="classroom"
      />
    </>
  );
};

export default ClassroomSupplementarMaterial;
