import InstructorInfo from 'components/professors/InstructorInfo';
import ProfessorInfo from 'components/professors/ProfessorInfo';
import { Button, Col, Form, Row, Select, Typography } from 'antd';
import { ICourseShow } from 'interfaces/courses';
import { IGraduateCourseShow } from 'interfaces/graduate_courses';
import { IProfessorCourse } from 'interfaces/professor';
import { Store } from 'antd/lib/form/interface';
import { useCallback } from 'react';
import { useDelete, useList } from '@refinedev/core';
import { useForm, useSelect } from '@refinedev/antd';

export interface CourseProfessorProps {
  course: ICourseShow | IGraduateCourseShow;
  courseType?: string;
}

const CourseProfessor = ({ course, courseType }: CourseProfessorProps) => {
  const resource = 'professors/courses';
  const { mutateAsync } = useDelete();
  const { data, refetch } = useList<IProfessorCourse>({
    resource,

    filters: [
      {
        field: 'filter[by_course_id]',
        operator: 'eq',
        value: course.id
      }
    ]
  });
  const courseData = data?.data[0];
  const { formProps, saveButtonProps, onFinish } = useForm({
    resource,
    redirect: false,
    action: 'create',
    onMutationError: () => null,
    errorNotification: () => {
      return {
        message: 'Esse curso ja possui um coordenador associado.',
        description: 'Erro',
        type: 'error'
      };
    }
  });
  const { selectProps } = useSelect({
    resource: 'professors',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 9999,

    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const handleOnFinish = (values: Store) => {
    onFinish({
      ...values,
      course_id: course.id,
      course_type: courseType
    });
    refetch();
  };

  const handleDelete = useCallback(() => {
    mutateAsync({
      resource,
      id: courseData!.id
    });
  }, [courseData, mutateAsync]);

  return (
    <>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Responsável de turma
      </Typography.Title>
      {!courseData?.professor && (
        <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Definir professor" name="professor_id">
                <Select {...selectProps} placeholder="Selecione o professor" />
              </Form.Item>
            </Col>
            <Col span={12} className="self-center mt-[5px]">
              <Button {...saveButtonProps} type="primary">
                Associar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {courseData?.professor && (
        <>
          <Button type="primary" onClick={handleDelete} className="mb-2">
            Desassociar coordenador
          </Button>
          <ProfessorInfo professorData={courseData.professor} />
          <InstructorInfo professorData={courseData.professor} />
        </>
      )}
    </>
  );
};

export default CourseProfessor;
