import FilterForm from 'components/forms/FilterForm';
import { Col, DatePicker, Form, FormProps, Input, Row } from 'antd';

export interface IContractStudentFilter {
  by_user_name: string;
  by_user_email: string;
  created_after: Date;
  created_before: Date;
}

interface ContractsFilterProps {
  searchFormProps: FormProps<IContractStudentFilter>;
}

export const ContractStudentsFilter = ({
  searchFormProps
}: ContractsFilterProps) => {
  return (
    <FilterForm
      searchFormProps={searchFormProps}
      okButtonProps={{
        type: 'default'
      }}
    >
      <Row gutter={[16, 16]} className="w-full">
        <Col xs={24} md={6}>
          <Form.Item label="Aluno" name="by_user_name">
            <Input placeholder="Digite..." />
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item label="E-mail do aluno" name="by_user_email">
            <Input placeholder="Digite..." />
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item label="Inclusão (a partir de)" name="created_after">
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
              allowClear
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item label="Inclusão (término em)" name="created_before">
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
              allowClear
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
