/* eslint-disable unicorn/prefer-switch */

/* eslint-disable sonarjs/cognitive-complexity */
import * as Icons from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import SupplementaryMaterialForm from 'components/forms/SupplementaryMaterialForm';
import axios from 'axios';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Progress,
  Row,
  Select,
  Typography,
  Upload
} from 'antd';
import { ContentKind, ICourseShow, IDataContent } from 'interfaces/courses';
import { CopyToClipboard } from '../CopyToClipboard';
import { ICoreContent } from 'interfaces/core_content';
import { IDocumentCreate } from 'interfaces/documentation/document';
import { IHyperTextCreate } from 'interfaces/hyper_texts';
import { IVideoCreate } from 'interfaces/videos';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  createDocument,
  createDocumentOption,
  createHyperText,
  createHyperTextOption,
  createVideo,
  createVideoOption,
  updateDocument,
  updateHyperText,
  updateVideo
} from 'requests/mutation';
import { translateOptionStatus } from 'services/contents';
import { useList, useModalReturnType } from '@refinedev/core';

interface Props {
  course: ICourseShow;
  modal: useModalReturnType;
  node: IDataContent;
  onUpdate: (node: IDataContent) => void;
}

const CourseNodeModal: React.FC<Props> = ({
  course,
  modal,
  node,
  onUpdate
}) => {
  const providerOptions = {
    video: [
      { label: 'Cloudflare', value: 'cloudflare' },
      { label: 'Cloudflare com Link', value: 'cloudflare_with_link' }
    ],
    hyper_text: [
      { label: 'URL', value: 'url' },
      { label: 'AWS S3', value: 's3' }
    ],
    document: [
      { label: 'URL', value: 'url' },
      { label: 'AWS S3', value: 's3' }
    ]
  };

  const [form] = Form.useForm();

  const [providers, setProviders] = useState(
    providerOptions[node.core_kind as ContentKind] || []
  );
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [videoFile, setVideoFile] = useState<UploadFile | null>();
  const [hyperTextFile, setHyperTextFile] = useState<UploadFile | null>();
  const [documentFile, setDocumentFile] = useState<UploadFile | null>();

  const coreContentResult = useList<ICoreContent>({
    resource: 'core_contents',

    filters: [
      {
        field: 'filter[id][]',
        operator: 'eq',
        value: [node.core_id]
      },

      {
        field: 'kind[]',
        operator: 'eq',
        value: [node.core_kind]
      }
    ]
  });

  const cores: ICoreContent[] | undefined = coreContentResult.data?.data;
  const core = cores?.[0];

  useEffect(() => {
    if (core) {
      const initialValues = {
        ...node,
        core_id: core?.id,
        core_reference: core?.option.reference,
        core_url: core?.option.url,
        core_status: core?.option.status,
        core_provider: core?.option.provider,
        name: node.title
      };

      form.setFieldsValue(initialValues);
    }
  }, [core, form, node]);

  const onClose = () => {
    modal.close();
  };

  const onSubmit = async () => {
    try {
      setLoading(true);

      const { name, core_kind, core_reference, core_provider } =
        await form.validateFields();

      node.core_kind = core_kind;
      node.core_reference = core_reference;
      node.core_provider = core_provider;
      node.name = name;
      node.title = name;

      if (node.core_id) {
        const updatedNode = {
          ...node,
          isLeaf: true
        };

        onUpdate(updatedNode);
        return node;
      }

      if (node.core_kind === 'assessment') {
        node.core_id = node.core_reference;
      }

      if (node.core_kind === 'video') {
        const video = node.core_id
          ? await updateVideo(node.core_id, name)
          : await createVideo(name);

        node.core_id = video.id;

        if (videoFile) {
          await uploadVideo({ video, file: videoFile! });
          const option = await createVideoOption(video.id, 'cloudflare');

          node.core_url = option.url;
          node.core_status = option.status;
        }

        if (node.core_provider === 'cloudflare_with_link') {
          const option = await createVideoOption(
            video.id,
            'cloudflare_with_link',
            node.core_reference
          );

          node.core_url = option.url;
          node.core_status = option.status;
        }
      } else if (node.core_kind === 'hyper_text') {
        const hyper_text = node.core_id
          ? await updateHyperText(node.core_id, name)
          : await createHyperText(name);

        if (hyperTextFile) {
          await uploadHyperText({
            hyper_text: hyper_text,
            file: hyperTextFile!
          });
        }

        const option = await createHyperTextOption(
          hyper_text.id,
          node.core_provider,
          node.core_reference
        );

        node.core_id = hyper_text.id;
        node.core_url = option.url;
        node.core_status = option.status;
      } else if (node.core_kind === 'document') {
        const document = node.core_id
          ? await updateDocument(node.core_id, name)
          : await createDocument(name);

        if (documentFile) {
          await uploadDocument({
            document: document,
            file: documentFile!
          });
        }

        const option = await createDocumentOption(
          document.id,
          node.core_provider,
          node.core_reference
        );

        node.core_id = document.id;
        node.core_url = option.url;
        node.core_status = option.status;
      }

      form.setFields([
        { name: ['core_id'], value: node.core_id },
        { name: ['core_url'], value: node.core_url },
        {
          name: ['core_status'],
          value: node.core_status
        }
      ]);

      const updatedNode = {
        ...node,
        title: name,
        name
      };

      onUpdate(updatedNode);
    } finally {
      onClose();
      setUploading(false);
      setUploadPercentage(0);
      setLoading(false);
      setVideoFile(null);
    }
  };

  const isVideoUpload = () =>
    node.core_kind === 'video' && core?.option.provider === 'cloudflare';
  const isAssessment = () => node.core_kind === 'assessment';

  const isHyperTextUpload = () => {
    return node?.core_kind === 'hyper_text' && core?.option.provider === 's3';
  };

  const isDocumentUpload = () => {
    return node.core_kind === 'document' && node.core_provider === 's3';
  };

  const hasCoreId = (): boolean => !!node.core_id;

  const isEnabled = (): boolean => core?.option.status === 'enabled';

  const uploadVideo = ({
    video,
    file
  }: {
    video: IVideoCreate;
    file: UploadFile;
  }) => {
    const { url } = video.upload;

    setUploading(true);

    return axios.put(url, file, {
      onUploadProgress: ({ loaded, total }) =>
        setUploadPercentage(Math.round((100 * loaded) / total))
    });
  };

  const uploadDocument = ({
    document,
    file
  }: {
    document: IDocumentCreate;
    file: UploadFile;
  }) => {
    const { url } = document.upload;

    setUploading(true);

    return axios.put(url, file, {
      headers: {
        'Content-Type': 'application/pdf'
      },
      onUploadProgress: ({ loaded, total }) =>
        setUploadPercentage(Math.round((100 * loaded) / total))
    });
  };

  const uploadHyperText = ({
    hyper_text,
    file
  }: {
    hyper_text: IHyperTextCreate;
    file: UploadFile;
  }) => {
    const { url } = hyper_text.upload;

    setUploading(true);

    return axios.put(url, file, {
      onUploadProgress: ({ loaded, total }) =>
        setUploadPercentage(Math.round((100 * loaded) / total))
    });
  };

  return (
    <Modal
      title="Editar conteúdo"
      visible={modal.visible}
      width={1000}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item name={['core_id']} style={{ height: 0, margin: 0 }}>
          <Input type="hidden" />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col md={23}>
            <Form.Item
              name={['name']}
              label="Nome"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={1}>
            {node.core_id && (
              <CopyToClipboard
                text={String(node.core_id)}
                tooltipText={'Copiar core_id'}
              />
            )}
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col md={8}>
            <Form.Item
              name={['core_kind']}
              label="Tipo"
              rules={[{ required: true }]}
            >
              <Select
                disabled={hasCoreId()}
                onChange={value => {
                  setProviders(
                    providerOptions[
                      value as 'video' | 'hyper_text' | 'document'
                    ]
                  );
                  return value;
                }}
              >
                <Select.Option key="video">Vídeo</Select.Option>
                <Select.Option key="hyper_text">HTML</Select.Option>
                <Select.Option key="document">PDF</Select.Option>
                <Select.Option key="assessment">Simulado</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item
              name={['core_provider']}
              label="Provedor"
              hidden={isAssessment()}
              rules={[{ required: !hasCoreId() && !isAssessment() }]}
            >
              <Select disabled={hasCoreId()}>
                {providers?.map(provider => (
                  <Select.Option key={provider.value}>
                    {provider.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              className="w-full"
              name={['core_reference']}
              label="Simulado"
              rules={[
                {
                  required: isAssessment()
                }
              ]}
              hidden={!isAssessment()}
            >
              <Select defaultValue={node.core_id}>
                {course.assessments.map(assessment => (
                  <>
                    <Select.Option key={assessment.id}>
                      {assessment.name}
                    </Select.Option>
                  </>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item
              name={['core_status']}
              label="Status"
              hidden={isAssessment()}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              getValueProps={(value: any) => ({
                value: translateOptionStatus(value)
              })}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item
              name={['core_url']}
              label="URL"
              hidden={!hasCoreId() || !isEnabled()}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Form.Item
              name={['core_reference_html']}
              required
              hidden={!isVideoUpload() || hasCoreId()}
              label="Upload"
            >
              <Upload
                maxCount={1}
                accept="video/mp4"
                fileList={videoFile! ? [videoFile!] : []}
                onRemove={() => setVideoFile(null)}
                beforeUpload={newFile => {
                  setVideoFile(newFile);

                  return false;
                }}
              >
                <Button className="!flex items-center" disabled={loading}>
                  <Icons.UploadOutlined /> Vídeo (mp4)
                </Button>
              </Upload>
              {uploading && <Progress percent={uploadPercentage} />}
            </Form.Item>
            <Form.Item
              name={['core_reference']}
              required
              hidden={!isHyperTextUpload() || hasCoreId()}
              label="Upload"
            >
              <Upload
                maxCount={1}
                accept="application/zip"
                fileList={hyperTextFile! ? [hyperTextFile!] : []}
                onRemove={() => setHyperTextFile(null)}
                beforeUpload={newFile => {
                  setHyperTextFile(newFile);

                  return false;
                }}
              >
                <Button className="!flex items-center" disabled={loading}>
                  <Icons.UploadOutlined /> Zip File (zip)
                </Button>
              </Upload>
              {uploading && <Progress percent={uploadPercentage} />}
            </Form.Item>

            <Form.Item
              name={['core_reference']}
              required
              hidden={!isDocumentUpload() || hasCoreId()}
              label="Upload"
            >
              <Upload
                maxCount={1}
                accept="application/pdf"
                fileList={documentFile! ? [documentFile!] : []}
                onRemove={() => setDocumentFile(null)}
                beforeUpload={newFile => {
                  setDocumentFile(newFile);

                  return false;
                }}
              >
                <Button className="!flex items-center" disabled={loading}>
                  <Icons.UploadOutlined /> PDF (pdf)
                </Button>
              </Upload>
              {uploading && <Progress percent={uploadPercentage} />}
            </Form.Item>

            <Form.Item
              name={['core_reference']}
              label="Referência"
              rules={[
                {
                  required: !(
                    isHyperTextUpload() ||
                    isVideoUpload() ||
                    isDocumentUpload() ||
                    hasCoreId()
                  )
                }
              ]}
              hidden={
                isHyperTextUpload() ||
                isVideoUpload() ||
                isDocumentUpload() ||
                hasCoreId() ||
                isAssessment()
              }
            >
              <Input disabled={hasCoreId()} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Typography.Title level={5}>Material Complementar</Typography.Title>

      <SupplementaryMaterialForm
        parent_reference={String(node.key)}
        parent_kind="content"
      />
    </Modal>
  );
};

export default CourseNodeModal;
