import PossessionTable from './PossessionTable';
import { IUser } from 'interfaces/users';
import { Space } from 'antd';

interface UserPossessionsProps {
  userData: IUser;
  onSuccess?: () => void;
}

export const UserPossessions = ({
  userData,
  onSuccess
}: UserPossessionsProps) => {
  return (
    <Space direction="vertical" size="large" className="w-full">
      <PossessionTable userData={userData} />
    </Space>
  );
};
