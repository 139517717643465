import { Card, Col, Row, Tabs, Typography } from 'antd';
import { ContractBreadcrumbs } from './components/ContractBreadcrumbs';
import { IContract } from 'interfaces/contracts';
import { Show } from '@refinedev/antd';
import { TabContractInfo } from './components/TabContractInfo';
import { TabContractStudents } from './components/TabContractStudents';
import { useParams, useSearchParams } from 'react-router-dom';
import { useShow } from '@refinedev/core';

const { Text } = Typography;

export const ContractShow = () => {
  const params = useParams();
  const { id: contractId, companyId } = params;

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');

  const { queryResult } = useShow<IContract>({
    resource: `companies/${companyId}/contracts`,
    id: contractId
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      isLoading={isLoading}
      title="Visualizar contrato"
      breadcrumb={<ContractBreadcrumbs />}
    >
      {record && (
        <>
          <Card className="!mb-8">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <Text>
                  Nº do Contrato: <Text strong>{record?.code}</Text>
                </Text>
              </Col>
              <Col xs={24} md={8}>
                <Text>
                  {/* Empresa: <Text strong>{record?.company?.name}</Text> */}
                </Text>
              </Col>
              <Col xs={24} md={8}>
                <Text>
                  CNPJ: <Text strong>{record?.company?.cnpj}</Text>
                </Text>
              </Col>
            </Row>
          </Card>

          <Tabs
            defaultActiveKey={currentTab ?? 'info'}
            onChange={e => setSearchParams({ tab: e })}
          >
            <Tabs.TabPane tab="Informações Gerais" key="info">
              <TabContractInfo contract={record} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Aluno" key="contracts">
              <TabContractStudents />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </Show>
  );
};
