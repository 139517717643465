import 'react-quill/dist/quill.snow.css';
import { Create, useForm } from '@refinedev/antd';
import { FormQuestions } from './components/FormQuestions';
import { IAssessmentQuestion } from 'interfaces/assessments';

export const AssessmentQuestionCreate = () => {
  const form = useForm<IAssessmentQuestion>({
    redirect: 'edit',
    errorNotification: (error, values) => {
      const question = values as IAssessmentQuestion;

      if (!question.correct_alternative_id) {
        return {
          description: 'Alternativa correta',
          message: 'É necessário informar uma alterantiva correta',
          type: 'error'
        };
      }

      return {
        description: 'Ops! Algo de errado',
        message: 'Não foi possível criar a questão. Tente novamente',
        type: 'error'
      };
    }
  });

  return (
    <Create saveButtonProps={form.saveButtonProps}>
      <FormQuestions formInstance={form} />
    </Create>
  );
};
