import { getAPI } from './api';

export const deleteClassroomEnrollments = ({
  classroomId,
  classroom_enrollment_ids
}: {
  classroomId: string;
  classroom_enrollment_ids: string[] | React.Key[];
}): Promise<null> => {
  const api = getAPI();

  return api.delete(`admin/classrooms/${classroomId}/enrollments`, {
    data: { classroom_enrollment_ids }
  });
};
