import {
  ProductCreate,
  ProductEdit,
  ProductList,
  ProductShow
} from 'pages/ecommerce/products';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'products',
  label: 'Produtos',
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  show: ProductShow,
  actions: ['list', 'show', 'edit', 'create', 'delete', 'slug'],
  parentName: 'ecommerce'
};

export default generateResources(resource);
