import FilterForm from '../../../components/forms/FilterForm';
import { Col, Form, Input, Row, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { IEcommerceDivision } from 'interfaces/ecommerce_divisions';

interface IFilter {
  code: string;
}

export const EcommerceDivisionList = () => {
  const { tableProps, searchFormProps } = useTable<
    IEcommerceDivision,
    HttpError,
    IFilter
  >({
    resource: 'ecommerce/divisions',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { code } = params;

      filters.push({
        field: 'filter[code]',
        operator: 'eq',
        value: code
      });

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Código" name="code">
              <Input placeholder="código" />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="code"
              key="code"
              title="Código"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="description"
              key="description"
              title="Descrição"
              render={value => <TextField value={value} />}
            />
            <Table.Column<IEcommerceDivision>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <DeleteButton
                      size="small"
                      resource="ecommerce/divisions"
                      recordItemId={record.id}
                      hideText
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
