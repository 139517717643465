import { IClassroom } from '../../../interfaces/classrooms';
import { ReactNode } from 'react';
import { Space, Table, TableProps } from 'antd';
import { TextField } from '@refinedev/antd';
import { formatDate } from '../../../services/date';

interface ClassroomTableProps {
  tableProps: TableProps<IClassroom>;
  children: ReactNode;
}

export const ClassroomTable = ({
  tableProps,
  children
}: ClassroomTableProps) => {
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="code"
        key="code"
        title="Código"
        render={value => <TextField value={value} />}
      />
      <Table.Column<IClassroom>
        dataIndex="discipline"
        key="discipline"
        title="Disciplina"
        render={value => <TextField value={value.name} />}
      />
      <Table.Column<IClassroom>
        dataIndex="auto_enroll"
        key="auto_enroll"
        title="Inscrição automática"
        render={value => <TextField value={value ? '✅' : '❌'} />}
      />
      <Table.Column<IClassroom>
        key="seats"
        title="Vagas Ocupadas / Total de vagas"
        render={(_, record) => (
          <TextField
            value={`${Number(record.active_enrollments_count)} de ${Number(
              record.seats_total
            )}`}
          />
        )}
      />
      <Table.Column<IClassroom>
        width={70}
        dataIndex="academic_year"
        key="academic_year"
        title="Ano"
        render={value => <TextField value={value ?? '-'} />}
      />
      <Table.Column<IClassroom>
        width={70}
        dataIndex="academic_period"
        key="academic_period"
        title="Período"
        render={value => <TextField value={value ?? '-'} />}
      />
      <Table.Column<IClassroom>
        key="duration"
        title="Vigência"
        render={(_, record) => (
          <TextField
            value={
              <Space direction="vertical" align="start" className="text-xs">
                <div>De: {formatDate(record.starts_at, 'lll')}</div>
                <div>Até: {formatDate(String(record.ends_at), 'lll')}</div>
              </Space>
            }
          />
        )}
      />
      {children}
    </Table>
  );
};
