import { IOrderPayment } from 'interfaces/orders';

const STATUS_TRANSLATIONS = new Map([
  ['initial', 'Iniciado'],
  ['processing', 'Em processamento'],
  ['approved', 'Aprovado'],
  ['expired', 'Expirado'],
  ['rejected', 'Rejeitado'],
  ['error', 'Erro inesperado']
]);

const GATEWAY_STATUS_DETAIL_TRANSLATIONS = new Map([
  ['cc_rejected_bad_filled_date', 'A data de validade do cartão é inválida'],
  ['cc_rejected_bad_filled_other', 'Algum dado preenchido é inválido'],
  ['cc_rejected_bad_filled_security_code', 'O código de segurança é inválido'],
  ['cc_rejected_blacklist', 'Pagamento foi bloqueado, não pode ser processado'],
  ['cc_rejected_call_for_authorize', 'O valor selecionado não foi autorizado'],
  [' cc_rejected_card_disabled', 'O cartão esta desabilitado'],
  ['cc_rejected_card_error', 'Erro no cartão, pagamento não processado'],
  [
    'cc_rejected_duplicated_payment',
    'Pagamento duplicado, use outro cartão ou método de pagamento'
  ],
  [
    'cc_rejected_high_risk',
    'Seu pagamento foi rejeitado, use outro método de pagamento'
  ],
  ['cc_rejected_insufficient_amount', 'Cartão sem fundos'],
  ['cc_rejected_invalid_installments', 'Opção de parcelamento indisponível'],
  ['cc_rejected_max_attempts', 'Limite máximo de tentativas'],
  ['cc_rejected_other_reason', 'Não foi possível processar o pagamento'],
  ['pending_review_manual', 'Revisão de pagamento pendente'],
  ['pending_contingency', 'Pagamento em contingência'],
  ['cc_rejected', 'Pagamento rejeitado'],
  ['accredited', 'Pagamento aprovado']
]);

const KINDS_TRANSLATIONS = new Map([
  ['credit_card', 'Cartão de crédito'],
  ['boleto', 'Boleto'],
  ['pix', 'Pix']
]);

export const translateStatus = (status: IOrderPayment['status']): string =>
  STATUS_TRANSLATIONS.get(status) || status;

export const translateKind = (kind: IOrderPayment['kind']): string =>
  KINDS_TRANSLATIONS.get(kind) || kind;

export const translateGatewayStatusDetail = (
  kind: IOrderPayment['gateway_status_detail']
): string => GATEWAY_STATUS_DETAIL_TRANSLATIONS.get(kind) || kind;
