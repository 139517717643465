import { CouponCreate, CouponEdit, CouponList } from 'pages/ecommerce/coupons';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'coupons',
  label: 'Cupons',
  list: CouponList,
  create: CouponCreate,
  edit: CouponEdit,
  actions: ['list', 'edit', 'create'],
  parentName: 'sales'
};

export default generateResources(resource);
