import {
  ContractCreate,
  ContractEdit,
  ContractShow
} from 'pages/admin/contracts';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'contracts',
  label: 'Contratos',
  create: ContractCreate,
  edit: ContractEdit,
  show: ContractShow,
  actions: ['show', 'create', 'edit'],
  parentName: 'b2b/companies/:companyId'
};

export default generateResources(resource);
