import React from 'react';
import { Card, Typography } from 'antd';
import { IAcademicRecord } from '../../../../interfaces/academic_record';
import { convertMinutesToHoursAndMinutes } from 'utils/convert-minutes-hours-minutes';
import { formatDate } from '../../../../services/date';

interface AcademicRecordProps {
  academic_record: IAcademicRecord;
}

export const AcademicRecordInfo: React.FC<AcademicRecordProps> = ({
  academic_record
}) => {
  return (
    <>
      <Card
        size="small"
        title="Dados do Histórico Acadêmico de Origem"
        type="inner"
        style={{ marginTop: 16 }}
      >
        <div style={{ fontSize: 'smaller' }}>
          <Typography.Text strong>Curso de origem: </Typography.Text>
          <Typography.Text>
            {academic_record.course_enrollment.course.name}
          </Typography.Text>
        </div>
        <div style={{ fontSize: 'smaller' }}>
          <Typography.Text strong>Disciplina de origem: </Typography.Text>
          <Typography.Text>{academic_record.discipline.name}</Typography.Text>
        </div>
        <div style={{ fontSize: 'smaller' }}>
          <Typography.Text strong>Carga Horária: </Typography.Text>
          <Typography.Text>
            {convertMinutesToHoursAndMinutes(
              academic_record.discipline.duration_in_minutes
            )}
          </Typography.Text>
        </div>
        <div style={{ fontSize: 'smaller' }}>
          <Typography.Text strong>Turma: </Typography.Text>
          <Typography.Text>
            {academic_record.parent.classroom.code}
          </Typography.Text>
        </div>
        <div style={{ fontSize: 'smaller' }}>
          <Typography.Text strong>Nota: </Typography.Text>
          <Typography.Text>{academic_record.score}</Typography.Text>
        </div>
        <div style={{ fontSize: 'smaller' }}>
          <Typography.Text strong>Histórico criado em: </Typography.Text>
          <Typography.Text>
            {formatDate(academic_record.created_at, 'DD/MM/YYYY - hh:mm')}
          </Typography.Text>
        </div>
      </Card>
    </>
  );
};
