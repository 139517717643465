import {
  EcommerceProfitCenterCreate,
  EcommerceProfitCenterEdit,
  EcommerceProfitCenterList,
  EcommerceProfitCenterShow
} from '../pages/ecommerce/ecommerce_profit_centers';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'ecommerce_profit_centers',
  label: 'Centro de Resultados',
  list: EcommerceProfitCenterList,
  create: EcommerceProfitCenterCreate,
  show: EcommerceProfitCenterShow,
  edit: EcommerceProfitCenterEdit,
  actions: ['list', 'show', 'create', 'edit', 'delete'],
  parentName: 'finance'
};

export default generateResources(resource);
