import * as Icons from '@ant-design/icons';
import React from 'react';
import { Button, Col, Form, Input, Row, Select, Tooltip } from 'antd';
import { IApiError } from 'interfaces/error';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { useCreate } from '@refinedev/core';
import { useSelect } from '@refinedev/antd';

export interface AssociationFormProps {
  resource: string;
  templateId: string | null;
  name: string;
}

const AssociationForm = ({
  resource,
  templateId,
  name
}: AssociationFormProps) => {
  const [form] = Form.useForm();
  const { mutateAsync: associateCourse } = useCreate();
  const { selectProps: courseSelectProps } = useSelect<IGraduateCourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const handleInsert = (values: Record<string, string | boolean>) => {
    associateCourse({
      resource,
      values: { ...values, template_id: templateId },
      errorNotification: error => {
        if (
          typeof error === 'object' &&
          error !== null &&
          (error as IApiError).response.data.code === 'record_invalid'
        ) {
          return {
            type: 'error',
            message: 'O curso ja esta associado a esta documentação.',
            description: 'Erro ao associar curso!'
          };
        }
        return {
          type: 'error',
          message: 'Ocorreu algum erro interno no serviço',
          description: 'Erro ao associar curso!'
        };
      }
    })
      .then(() => {
        form.resetFields();
      })
      .catch(() => null);
  };

  return (
    <Form form={form} onFinish={handleInsert} onError={undefined}>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item label="Nome:" colon={false}>
            <Input disabled value={name} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="course_id" label="Curso" required>
            <Select placeholder="Selecione o curso" {...courseSelectProps} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name="required" label="Obrigatoriedade" required>
            <Select placeholder="Selecione a obrigatoriedade">
              <Select.Option value={true}>Obrigatório</Select.Option>
              <Select.Option value={false}>Opcional</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={1}>
          <Tooltip title="Associar curso a documentação">
            <Button
              style={{
                backgroundColor: '#039855',
                borderColor: '#039855'
              }}
              type="primary"
              htmlType="submit"
              icon={<Icons.PlusCircleOutlined />}
            >
              Associar
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </Form>
  );
};

export default AssociationForm;
