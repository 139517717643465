import {
  ClassroomCreate,
  ClassroomEdit,
  ClassroomList,
  ClassroomShow
} from 'pages/classrooms';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'classrooms',
  label: 'Turmas',
  list: ClassroomList,
  show: ClassroomShow,
  edit: ClassroomEdit,
  create: ClassroomCreate,
  actions: ['list', 'show', 'edit', 'create'],
  parentName: 'course_creation'
};

export default generateResources(resource);
