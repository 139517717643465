import { CompanyForm } from './components/CompanyForm';
import { Create, useForm } from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { ICompany } from 'interfaces/companies';

export const CompanyCreate = () => {
  const { formProps, saveButtonProps } = useForm<
    ICompany,
    HttpError,
    ICompany
  >();

  return (
    <Create saveButtonProps={saveButtonProps} title="Nova empresa">
      <CompanyForm formProps={formProps} />
    </Create>
  );
};
