import AssetUpload from 'components/AssetUpload';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select, Typography } from 'antd';
import { ICertifiers } from 'interfaces/certifiers';
import { ISignatures } from 'interfaces/signatures';

export const SignaturesEdit = () => {
  const { selectProps } = useSelect<ICertifiers>({
    resource: 'certifiers',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 100,
    liveMode: 'manual',

    pagination: {
      mode: 'server'
    }
  });
  const { formProps, saveButtonProps } = useForm<ISignatures>({
    successNotification: () => ({
      type: 'success',
      description: 'Sucesso!',
      message: 'Assinatura editada com sucesso!'
    }),
    errorNotification: () => ({
      type: 'error',
      description: 'Error!',
      message: 'Não foi possível editar a assinatura!'
    })
  });
  return (
    <Create saveButtonProps={saveButtonProps} title="Editar assinatura">
      <Form {...formProps} layout="vertical">
        <Typography.Title level={5} style={{ margin: '0 0 24px' }}>
          Instituição
        </Typography.Title>
        <Form.Item
          label="Nome da instituição"
          name="certifier_id"
          rules={[
            {
              required: true,
              message: 'Nome da instituição obrigatório'
            }
          ]}
        >
          <Select {...selectProps} allowClear style={{ minWidth: 100 }} />
        </Form.Item>
        <Typography.Title level={5} style={{ margin: '24px 0' }}>
          Assinatura
        </Typography.Title>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: 'Título obrigatório'
            }
          ]}
        >
          <Input className="max-w-[200px]" />
        </Form.Item>
        <Form.Item
          label="Matrícula"
          name="registration"
          rules={[
            {
              required: true,
              message: 'Matrícula obrigatória'
            }
          ]}
        >
          <Input className="max-w-[200px]" />
        </Form.Item>
        <Form.Item
          label="Nome para impressão"
          name="name"
          rules={[
            {
              required: true,
              message: 'Nome obrigatório'
            }
          ]}
        >
          <Input className="max-w-[500px]" />
        </Form.Item>

        <Form.Item
          label="Assinatura digital"
          name="signature"
          rules={[
            {
              required: true,
              message: 'Assinatura digital obrigatória'
            }
          ]}
        >
          <AssetUpload
            accept="image/png"
            type="certifier_signature"
            src={formProps?.initialValues?.signature}
          />
        </Form.Item>

        <Form.Item
          label="ATO/Portaria"
          name="decree"
          rules={[
            {
              required: true,
              message: 'ATO/Portaria obrigatório'
            }
          ]}
        >
          <Input className="max-w-[200px]" />
        </Form.Item>
      </Form>
    </Create>
  );
};
