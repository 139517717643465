import { IAssignmentAttempt, IAssignmentUser } from 'interfaces/assignments';
import { getAPI } from './api';

export const getAssignmentUsers = async (assignmentId: string) => {
  const { data } = await getAPI().get<IAssignmentUser[]>(
    `admin/assignments/${assignmentId}/users`
  );

  return data;
};

export const getAssignmentAttemptsByUser = async (
  userId: string,
  assignmentId: string
): Promise<IAssignmentAttempt[]> => {
  try {
    const { data } = await getAPI().get<IAssignmentAttempt[]>(
      `admin/assignment_attempts?filter[by_user_id]=${userId}&filter[by_assignment_id]=${assignmentId}`
    );

    return data;
  } catch {
    throw new Error('Deu alguma merda');
  }
};
