import FilterForm from '../../../components/forms/FilterForm';
import { Col, Form, Input, Row, Space, Table, Tag } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IAgent } from '../../../interfaces/agents';
import { ILive } from 'interfaces/lives';
import { List, ShowButton, TextField, useTable } from '@refinedev/antd';
import { LiveStatusMap } from 'services/prometheus';
import { formatDate } from 'services/date';

interface IFilter {
  title: string;
}

export const LiveList = () => {
  const { tableProps, searchFormProps } = useTable<ILive, HttpError, IFilter>({
    resource: 'prometheus/lives',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { title } = params;

      filters.push({
        field: 'filter[q]',
        operator: 'eq',
        value: title
      });

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Titulo" name="title">
              <Input placeholder="Titulo da live..." />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="id"
              key="id"
              title="ID"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="title"
              key="title"
              title="Título"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex={['stream', 'state']}
              key="status"
              title="Status"
              render={value => (
                <Tag color={value !== 'published' ? 'error' : 'success'}>
                  {LiveStatusMap[value]}
                </Tag>
              )}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Data de criação"
              render={value => <TextField value={formatDate(value)} />}
            />
            <Table.Column<IAgent>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
