import {
  DeleteButton,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { IGatewayRule } from 'interfaces/gateways';
import { Space, Table } from 'antd';
import { translateRuleKind } from 'services/gateways';

export const GatewayRuleList = () => {
  const { tableProps } = useTable<IGatewayRule>();

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="kind"
          key="kind"
          title="Tipo"
          render={value => <TextField value={translateRuleKind(value)} />}
        />
        <Table.Column<IGatewayRule>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton size="small" recordItemId={record.id} hideText />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
