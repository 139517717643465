import { IPost } from 'interfaces/discussion_threads';
import { getAPI } from 'requests/api';

const api = getAPI();

interface CreateProps {
  text: string;
  threadId: string;
}

export const createPost = async ({
  threadId,
  text
}: CreateProps): Promise<IPost> => {
  const { data } = await api.post(
    `/admin/discussion_threads/${threadId}/posts`,
    {
      text
    }
  );

  return data;
};
