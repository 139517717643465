import * as Icons from '@ant-design/icons';
import { IDamVideo } from 'interfaces/dam_videos';
import { Select } from 'antd';
import { useSelect } from '@refinedev/antd';
import { useShow } from '@refinedev/core';

export interface PrometheusVideoSelectProps {
  onChange?: (value: string | undefined) => void;
  value?: string;
  preview?: boolean;
}

export const PrometheusVideoSelect = ({
  onChange,
  value,
  preview = true
}: PrometheusVideoSelectProps) => {
  const { queryResult, showId, setShowId } = useShow<IDamVideo>({
    resource: 'prometheus/videos'
  });

  const { selectProps } = useSelect({
    resource: 'prometheus/videos',
    defaultValue: value,
    filters: [{ field: 'filter[status]', operator: 'eq', value: 'published' }],
    optionLabel: 'title',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value
      },
      { field: 'filter[status]', operator: 'eq', value: 'published' }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <>
      <Select
        {...selectProps}
        onChange={value => {
          setShowId(value.toString());
          onChange?.(value.toString());
        }}
      />
      {preview && showId && (
        <>
          {queryResult?.isFetching ? (
            <div className="flex items-center justify-center py-8 gap-2">
              <Icons.LoadingOutlined className="animate-spin" />
              <span>Carregando...</span>
            </div>
          ) : (
            <iframe
              style={{ width: '100%', marginTop: 20 }}
              src={queryResult?.data?.data.preview_url}
              className="w-full h-96"
            />
          )}
        </>
      )}
    </>
  );
};
