import { PaymentRefundList } from 'pages/ecommerce/payment_refunds';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'order_payment_refunds',
  label: 'Reembolso Parcial',
  list: PaymentRefundList,
  actions: ['list'],
  parentName: 'finance'
};

export default generateResources(resource);
