import * as Icons from '@ant-design/icons';
import ReactQuill from 'react-quill';
import { Button, Form, Radio, Space } from 'antd';
import { IAssessmentQuestion } from 'interfaces/assessments';
import { textValidator } from 'utils/validators';
import { useMemo } from 'react';

interface ObjectiveQuestionProps {
  question?: IAssessmentQuestion;
}

export const Alternatives = ({ question }: ObjectiveQuestionProps) => {
  const nullifiedQuestion = Boolean(question?.status === 'nullified');
  const editForm = useMemo(() => Boolean(question?.id), [question]);

  return (
    <Form.Item
      name={editForm ? ['correct_alternative_id'] : ['correct_alternative']}
    >
      <Radio.Group>
        <Form.List name={['alternatives']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((fieldAlternative, index) => (
                <Space
                  size="middle"
                  key={fieldAlternative.key}
                  style={{ width: '100%' }}
                >
                  <Radio
                    value={
                      editForm
                        ? question?.alternatives[fieldAlternative.name].id
                        : fieldAlternative.name
                    }
                  />

                  <Form.Item
                    label={`Alternativa ${index + 1}`}
                    name={[fieldAlternative.name, 'statement']}
                    required
                    rules={[
                      {
                        validator: (_, value, cb) =>
                          textValidator(value, cb, 'Campo obrigatório')
                      }
                    ]}
                  >
                    <ReactQuill
                      theme="snow"
                      style={{
                        color: nullifiedQuestion ? 'gray' : 'unset'
                      }}
                      readOnly={nullifiedQuestion}
                    />
                  </Form.Item>

                  {!editForm && (
                    <Button
                      size="small"
                      onClick={() => remove(fieldAlternative.name)}
                      title="Excluir alternativa"
                      icon={<Icons.MinusCircleOutlined />}
                    />
                  )}
                </Space>
              ))}

              {!editForm && (
                <Button
                  type="dashed"
                  className="!flex items-center justify-center"
                  onClick={() => add()}
                  block
                >
                  <Icons.PlusOutlined />
                  Add Alternativa
                </Button>
              )}
            </>
          )}
        </Form.List>
      </Radio.Group>
    </Form.Item>
  );
};
