import * as Icons from '@ant-design/icons';
import ContentUpload, { ContentUploadProps } from '../ContentUpload';
import { Button, Form, Input } from 'antd';

interface fileUploadProps {
  name: string;
  accept: string;
  type: ContentUploadProps['type'];
  btnLabel?: string;
}

const FileUpload = ({
  name,
  accept,
  type,
  btnLabel = 'Adicionar'
}: fileUploadProps) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(field => (
            <div key={field.key} className="flex items-center gap-5 w-full">
              <Form.Item
                name={[field.name, 'name']}
                rules={[{ required: true, message: 'Não pode ficar vazio' }]}
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item
                name={[field.name, 'url']}
                rules={[{ required: true, message: 'Selecione um arquivo' }]}
              >
                <ContentUpload type={type} accept={accept} />
              </Form.Item>

              <Icons.DeleteFilled
                className="mb-6"
                onClick={() => remove(field.name)}
              />
            </div>
          ))}
          <Button
            type="dashed"
            className="!flex items-center justify-center"
            onClick={() => add()}
          >
            <Icons.PlusOutlined />
            {btnLabel}
          </Button>
        </>
      )}
    </Form.List>
  );
};

export default FileUpload;
