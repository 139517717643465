import { IGatewayRangeTable, TGatewayRuleKind } from 'interfaces/gateways';
import { RuleConfig } from 'components/offers/gateways/rules/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TConfigElement = (value: any) => JSX.Element;

const RULE_KIND_TRANSLATIONS = new Map<TGatewayRuleKind, string>([
  ['simple', 'Simples'],
  ['zip_code', 'Por CEP']
]);

const CONFIG_COMPONENT = new Map<string, TConfigElement>([
  ['gateway_id', RuleConfig.GatewayId],
  ['marketplace_fee', RuleConfig.MarketplaceFee]
]);

export const RULE_KINDS: TGatewayRuleKind[] = [
  ...RULE_KIND_TRANSLATIONS.keys()
];

export const translateRuleKind = (kind: TGatewayRuleKind): string =>
  RULE_KIND_TRANSLATIONS.get(kind) || kind;

export const ruleConfigElement = (key: string): TConfigElement => {
  return CONFIG_COMPONENT.get(key) || RuleConfig.defaultRender(key);
};

export const parseRangeFile = async (
  file: File
): Promise<IGatewayRangeTable[]> => {
  const json = await file.text();

  return JSON.parse(json);
};
