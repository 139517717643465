import { IManualOfferRequestData, IOrderItem } from 'interfaces/manual_offer';
import { IUser } from 'interfaces/users';

export const parseUserInformation = (
  userData: IUser
): Partial<IManualOfferRequestData> => {
  return {
    email: userData.email,
    personal_info_attributes: {
      ...userData.personal_info
    },
    address_attributes: {
      ...userData.address
    },
    offer_ids: [],
    coupon_code: ''
  };
};

export const parseManualOrderRequest = (
  orderData: Partial<IManualOfferRequestData>,
  orderItemList: IOrderItem[]
) => {
  const orderItems = orderItemList.map(item => item.key);
  return {
    email: orderData.email,
    name: orderData.personal_info_attributes!.full_name,
    billing_postal_code: orderData.billing_postal_code,
    personal_info_attributes: {
      phone_number: orderData.personal_info_attributes!.phone_number,
      full_name: orderData.personal_info_attributes!.full_name,
      document_info: orderData.personal_info_attributes!.document_info,
      document_type: 'cpf'
    },
    address_attributes: {
      street: orderData.address_attributes!.street,
      city_id: orderData.address_attributes!.city.id,
      address_number: orderData.address_attributes!.address_number,
      address_complement: orderData.address_attributes!.address_complement,
      postal_code: orderData.address_attributes!.postal_code,
      neighborhood: orderData.address_attributes!.neighborhood
    },
    offer_ids: orderItems,
    coupon_code: orderData.coupon_code
  };
};
