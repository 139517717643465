import FilterForm from 'components/forms/FilterForm';
import { Col, DatePicker, Form, FormProps, Input, Row, Select } from 'antd';
import { IFilter } from '../list';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { useSelect } from '@refinedev/antd';

export interface FaqCoordinatorFilterProps {
  searchFormProps: FormProps<IFilter>;
}

export const FaqCoordinatorFilter = ({
  searchFormProps
}: FaqCoordinatorFilterProps) => {
  const { selectProps: courseSelectProps } = useSelect<IGraduateCourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });
  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={[16, 16]} className="w-full">
        <Col span={6}>
          <Form.Item
            colon
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label="Título do tópico"
            name="title"
          >
            <Input placeholder="Título do tópico" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Curso" name="course">
            <Select
              {...courseSelectProps}
              placeholder="Selecione uma curso"
              defaultValue={null}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Status" name="status">
            <Select placeholder="Selecione um status" defaultValue={null}>
              <Select.Option>Todos</Select.Option>
              <Select.Option key="pending">Não respondido</Select.Option>
              <Select.Option key="answered">Em andamento</Select.Option>
              <Select.Option key="closed">Concluído</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Nome do aluno" name="userName">
            <Input placeholder="Nome do aluno" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="w-full">
        <Col span={6} className="self-end">
          <Form.Item colon label="E-mail do aluno" name="userEmail">
            <Input placeholder="Email do aluno" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label="Data de postagem (a partir de)"
            name="startsAt"
          >
            <DatePicker
              className="w-full"
              placeholder="dd/mm/aaaa"
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label="Data de postagem (término em)"
            name="endsAt"
          >
            <DatePicker
              className="w-full"
              placeholder="dd/mm/aaaa"
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
