import React from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useModalReturnType } from '@refinedev/core';

interface Props {
  modal: useModalReturnType;
  onCreate: (node: { name: string }) => void;
  onClose: () => void;
}

export const CreateSectionModal: React.FC<Props> = ({
  modal,
  onClose,
  onCreate
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  const onCloseModal = () => {
    form.resetFields();
    modal.close();
    onClose();
  };

  const onSubmit = async () => {
    const { name } = await form.validateFields();
    setLoading(true);

    try {
      onCreate({
        name: name
      });

      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Criar seção"
      visible={modal.visible}
      width={1000}
      onCancel={onCloseModal}
      footer={[
        <Button key="back" onClick={onCloseModal} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item name={['core_id']} style={{ height: 0, margin: 0 }}>
          <Input type="hidden" />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Form.Item
              name={['name']}
              label="Nome"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
