import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps
} from '@refinedev/core';
import { IInteractiveClass } from 'interfaces/interactive_classes';
import {
  IInteractiveClassFilterForm,
  InteractiveClassListFilter
} from './components/ListFilter';
import { List, useTable } from '@refinedev/antd';
import { ListTable } from './components/ListTable';

export const translateKind = (kind: string) => {
  switch (kind) {
    case 'general': {
      return 'Todos';
    }

    case 'graduate_course': {
      return 'Curso regulado';
    }

    case 'classroom': {
      return 'Turma';
    }

    default: {
      return kind;
    }
  }
};

export const translatePriority = (priority: string) => {
  switch (priority) {
    case 'regular': {
      return 'Normal';
    }

    case 'high': {
      return 'Alta';
    }

    default: {
      return priority;
    }
  }
};

export const InteractiveClassesList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps, searchFormProps } = useTable<
    IInteractiveClass,
    HttpError,
    IInteractiveClassFilterForm
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { id, title, professor_id, discipline_id, status, date } = params;

      filters.push(
        {
          field: 'filter[by_id]',
          operator: 'eq',
          value: id
        },
        {
          field: 'filter[by_title]',
          operator: 'eq',
          value: title
        },
        {
          field: 'filter[by_professor_id]',
          operator: 'eq',
          value: professor_id
        },
        {
          field: 'filter[by_discipline_id]',
          operator: 'eq',
          value: discipline_id
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        },
        {
          field: 'filter[by_date]',
          operator: 'eq',
          value: date
        }
      );

      return filters;
    }
  });
  return (
    <List>
      <InteractiveClassListFilter searchFormProps={searchFormProps} />
      <ListTable tableProps={tableProps} />
    </List>
  );
};
