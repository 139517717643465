import FilterForm from 'components/forms/FilterForm';
import RuleConfig from 'components/offers/gateways/Config';
import { CrudFilters } from '@refinedev/core';
import { Divider, Form, Input, Table, Typography } from 'antd';
import { IGatewayRangeTable, IGatewayRule } from 'interfaces/gateways';
import { TextField, useTable } from '@refinedev/antd';

const { Title } = Typography;

interface Props {
  rule: IGatewayRule;
  name: string;
}

const RangeTable = ({ rule, name }: Props) => {
  const { tableProps, searchFormProps } = useTable<IGatewayRangeTable>({
    resource: 'offers/gateways/range_tables',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { value } = params as { value: number };

      filters.push({
        field: 'filter[value]',
        operator: 'eq',
        value: value
      });

      return filters;
    },

    filters: {
      permanent: [
        {
          field: 'filter[rule_id]',
          operator: 'eq',
          value: rule.id
        }
      ]
    }
  });

  return (
    <>
      <Divider />
      <Title level={4}>Tabela de {name}</Title>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label={name} name="value">
          <Input placeholder="0" />
        </Form.Item>
      </FilterForm>
      <Table {...tableProps}>
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="value_start"
          key="value_start"
          title="Início"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="value_end"
          key="value_end"
          title="Fim"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="config"
          key="config"
          title="Configuração"
          render={config => <RuleConfig config={config} />}
        />
      </Table>
    </>
  );
};

export default RangeTable;
