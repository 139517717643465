import FilterForm from '../../components/forms/FilterForm';
import { Col, Form, Input, Row, Space, Table } from 'antd';
import {
  EditButton,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { IGraduateCourse } from 'interfaces/graduate_courses';

interface IFilter {
  name: string;
  externalRef: string;
  code: string;
}

export const GraduateCourseList = () => {
  const { tableProps, searchFormProps } = useTable<
    IGraduateCourse,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const { name, externalRef, code } = params;

      return [
        {
          field: 'filter[name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_code]',
          operator: 'eq',
          value: code
        },
        {
          field: 'filter[search_external_reference]',
          operator: 'eq',
          value: externalRef
        }
      ];
    }
  });

  return (
    <List title="Cursos Regulados">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Nome" name="name">
              <Input placeholder="Nome do curso..." />
            </Form.Item>
            <Form.Item label="Código" name="code">
              <Input placeholder="Digite..." />
            </Form.Item>
            <Form.Item label="Código externo" name="externalRef">
              <Input placeholder="Digite..." />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="kind"
              key="kind"
              title="Tipo"
              render={value => (
                <TextField
                  value={value === 'graduate' ? 'Pós Graduação' : 'Graduação'}
                />
              )}
            />
            <Table.Column
              dataIndex="duration_in_hours"
              key="duration_in_hours"
              title="Duração (horas)"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="code"
              key="code"
              title="Código"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="emec"
              key="emec"
              title="Código e-MEC"
              render={value => <TextField value={value ?? '-'} />}
            />
            <Table.Column
              dataIndex="external_reference"
              key="external_reference"
              title="Código externo"
              render={value => <TextField value={value ?? '-'} />}
            />
            <Table.Column<IGraduateCourse>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </Col>
      </Row>
    </List>
  );
};
