import * as Icons from '@ant-design/icons';
import DocumentationInfoModal from 'components/modal/documents/DocumentationInfoModal';
import { Button, Col, Row, Space, Table, Tooltip, Typography } from 'antd';
import { IDocumentTemplate } from 'interfaces/documentation/document-template';
import { Link } from 'react-router-dom';
import { TextField } from '@refinedev/antd';
import { useList, useModal } from '@refinedev/core';
import { useState } from 'react';

const categoryMAP = {
  identification: 'Documento de indentificação',
  academic: 'Documento academico',
  supplementary: 'Documento complementar'
};

const DocumentTypes = () => {
  const [showGeneralDocuments, setShowGeneralDocuments] = useState(true);
  const [showComplementaryDocuments, setShowComplementaryDocuments] =
    useState(true);
  const [contextDocumentTemplate, setContextDocumentTemplate] =
    useState<IDocumentTemplate>();

  const modal = useModal();

  const { data: supplementary } = useList<IDocumentTemplate>({
    resource: 'documents/templates',

    filters: [
      {
        field: 'filter[by_category]',
        operator: 'eq',
        value: ['supplementary']
      }
    ]
  });

  const { data: statics } = useList<IDocumentTemplate>({
    resource: 'documents/templates',

    filters: [
      {
        field: 'filter[by_category][]',
        operator: 'eq',
        value: ['academic', 'identification']
      }
    ]
  });

  return (
    <>
      <Typography.Title level={4} className="block !mb-6">
        Tipos de documentos
      </Typography.Title>

      <Row
        style={{ gap: '8px' }}
        onClick={() => setShowGeneralDocuments(!showGeneralDocuments)}
      >
        <Col>
          <div className="flex items-center justify-center w-[20px] h-[20px] rounded-lg bg-[#F9F5FF]">
            {showGeneralDocuments ? (
              <Icons.UpOutlined style={{ fontSize: 10, color: '#9E77ED' }} />
            ) : (
              <Icons.DownOutlined style={{ fontSize: 10, color: '#9E77ED' }} />
            )}
          </div>
        </Col>
        <Col>
          <Row>
            <Typography.Title level={5}>Documentos gerais</Typography.Title>
          </Row>
          <Row>
            <Typography.Paragraph>
              Os documentos listados abaixo são validos para todos os cursos
              regulados cadastrados
            </Typography.Paragraph>
          </Row>
        </Col>
      </Row>

      {showGeneralDocuments && (
        <Table dataSource={statics?.data} rowKey={record => record.id}>
          <Table.Column
            title="Categoria"
            dataIndex="category"
            key="category"
            render={(value: keyof typeof categoryMAP) => (
              <TextField value={categoryMAP[value]} />
            )}
          />
          <Table.Column title="Nome do documento" dataIndex="name" key="name" />
          <Table.Column
            title="Obrigatório"
            dataIndex="required"
            key="required"
            render={(value: boolean) => (
              <TextField value={value ? 'Sim' : 'Não'} />
            )}
          />
          <Table.Column<IDocumentTemplate>
            title="Ações"
            dataIndex="actions"
            render={(_, record) => {
              return (
                <Space>
                  <Button
                    icon={<Icons.EyeOutlined />}
                    size="small"
                    onClick={() => {
                      setContextDocumentTemplate(record);
                      modal.show();
                    }}
                  />
                </Space>
              );
            }}
          />
        </Table>
      )}

      <Row
        style={{ gap: '8px' }}
        onClick={() =>
          setShowComplementaryDocuments(!showComplementaryDocuments)
        }
      >
        <Col>
          <div className="flex items-center justify-center w-[20px] h-[20px] rounded-lg bg-[#F9F5FF]">
            {showComplementaryDocuments ? (
              <Icons.UpOutlined style={{ fontSize: 10, color: '#9E77ED' }} />
            ) : (
              <Icons.DownOutlined style={{ fontSize: 10, color: '#9E77ED' }} />
            )}
          </div>
        </Col>
        <Col>
          <Row>
            <Typography.Title level={5}>
              Documentos complementares
            </Typography.Title>
          </Row>
          <Row>
            <Typography.Paragraph>
              Não achou o documento que precisa na lista? Entre em contato
              conosco pela{' '}
              <a href="https://grupoq.zendesk.com/hc/pt-br">central de ajuda</a>
              .
            </Typography.Paragraph>
          </Row>
        </Col>
      </Row>

      {showComplementaryDocuments && (
        <Table dataSource={supplementary?.data} rowKey={record => record.id}>
          <Table.Column
            title="Categoria"
            dataIndex="category"
            key="category"
            render={(value: keyof typeof categoryMAP) => (
              <TextField value={categoryMAP[value]} />
            )}
          />
          <Table.Column title="Nome do documento" dataIndex="name" key="name" />
          <Table.Column
            title="Cursos"
            dataIndex="courses_count"
            key="courses_count"
            render={(value: boolean) => <TextField value={value} />}
          />
          <Table.Column<IDocumentTemplate>
            title="Ações"
            dataIndex="actions"
            render={(_, record) => {
              return (
                <Space>
                  <Button
                    icon={<Icons.EyeOutlined />}
                    size="small"
                    onClick={() => {
                      setContextDocumentTemplate(record);
                      modal.show();
                    }}
                  />
                  <Tooltip title="Associar curso">
                    <Link
                      to={`/secretary/documentation_association?record=${record.id}`}
                    >
                      <Button icon={<Icons.FileDoneOutlined />} size="small" />
                    </Link>
                  </Tooltip>
                </Space>
              );
            }}
          />
        </Table>
      )}

      <DocumentationInfoModal
        modal={modal}
        documentTemplate={contextDocumentTemplate}
      />
    </>
  );
};

export default DocumentTypes;
