import FilterForm from '../../../components/forms/FilterForm';
import { Col, Form, Input, Row, Space, Table } from 'antd';
import {
  EditButton,
  List,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { IGraduateModule } from 'interfaces/graduate_courses';

interface IFilter {
  name: string;
  externalRef: string;
}

export const GraduateDisciplineList = () => {
  const { tableProps, searchFormProps } = useTable<
    IGraduateDiscipline,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const { name, externalRef } = params;

      return [
        {
          field: 'filter[name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[search_external_reference]',
          operator: 'eq',
          value: externalRef
        }
      ];
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List title="Disciplinas">
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Nome" name="name">
              <Input placeholder="Nome da disciplina..." />
            </Form.Item>
            <Form.Item label="Código externo" name="externalRef">
              <Input placeholder="Digite..." />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="code"
              key="code"
              title="Código"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="duration_in_minutes"
              key="duration_in_minutes"
              title="Duração (minutos)"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="modules"
              key="modules"
              title="Modulos"
              render={modules =>
                modules.map((module: IGraduateModule, idx: number) => (
                  <TagField key={`modulo-${idx}`} value={module.name} />
                ))
              }
            />
            <Table.Column
              dataIndex="external_reference"
              key="external_reference"
              title="Código externo"
              render={value => <TextField value={value ?? '-'} />}
            />
            <Table.Column<IGraduateDiscipline>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
