import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';

export const ContractBreadcrumbs = () => {
  const params = useParams();
  const companyId = params.companyId;

  return (
    <Breadcrumb
      items={[
        { title: 'B2B' },
        {
          title: 'Empresas parceiras',
          href: '/b2b/companies'
        },
        {
          title: 'Empresa',
          href: `/b2b/companies/show/${companyId}?tab=contracts`
        },
        {
          title: 'Contrato'
        }
      ]}
    />
  );
};
