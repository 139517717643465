import * as Icons from '@ant-design/icons';
import VideoPreviewModal from 'components/modal/VideoPreviewModal';
import {
  Button,
  Col,
  Divider,
  Form,
  FormProps,
  Input,
  Row,
  Select,
  Tag,
  Typography
} from 'antd';
import { IDamFile, IDamVideo } from 'interfaces/dam_videos';
import { ITag } from 'interfaces/epimetheus/tags';
import { ShowFieldData } from 'components/ShowFieldData';
import { StatusMap } from 'services/prometheus';
import { VideoSubtitleEditorModal } from 'components/modal/VideoSubtitleEditorModal';
import {
  downloadFileOption,
  videoPublish,
  videoUnpublish
} from 'requests/phometheus';
import { formatBytes } from 'services/conversions';
import { useModal } from '@refinedev/core';
import { useSelect } from '@refinedev/antd';
import { useState } from 'react';

const { Title } = Typography;

interface Props {
  video: IDamVideo;
  show?: boolean;
  formProps?: FormProps<Record<string, unknown>>;
}

const VideoDetails = ({ video, show, formProps }: Props) => {
  const videoDetailsModal = useModal();
  const videoSubtitleEditorModal = useModal();

  const onFinish = (data: Record<string, unknown>) => {
    const { status: _, ...rest } = data;

    if (formProps?.onFinish) {
      formProps.onFinish(rest);
    }
  };

  const { selectProps: tagsSelectProps } = useSelect<ITag>({
    resource: 'prometheus/v2/tags/search',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: video.tags?.map(value => value.id),
    onSearch: value => [
      {
        field: 'q',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <>
      <Form
        {...formProps}
        initialValues={video}
        onFinish={onFinish}
        layout="vertical"
      >
        <Title level={3}>Stream</Title>
        <Row gutter={[16, 16]}>
          <Col xs={12}>
            <Form.Item label="Título" name="title">
              <Input disabled={show} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Status" name="status">
              <Tag color={video.status !== 'published' ? 'error' : 'success'}>
                {StatusMap[video.status!]}
              </Tag>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={12}>
            <Form.Item
              label="Tags"
              name="tag_ids"
              initialValue={video.tags?.map(value => value.id)}
            >
              <Select {...tagsSelectProps} disabled={show} mode="multiple" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Link público" name="embed_url">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={12}></Col>
        </Row>
      </Form>
      {video?.preview_url && (
        <div className="flex gap-2 items-center">
          <Button
            type="primary"
            className="!flex items-center"
            onClick={videoDetailsModal.show}
          >
            <Icons.VideoCameraFilled />
            Visualizar Preview
          </Button>
          <Button
            type="default"
            className="!flex items-center"
            onClick={videoSubtitleEditorModal.show}
          >
            <Icons.FileTextFilled />
            Editar Legendas
          </Button>
        </div>
      )}
      <Title level={4}>Stream Providers</Title>
      {video.options?.map(el => (
        <div key={el.id} className="border rounded p-2 mb-2">
          <Title level={5}>{el.provider.name}</Title>
          <ShowFieldData
            label="Status"
            value={
              <Tag color={el.state !== 'processed' ? 'error' : 'success'}>
                {StatusMap[el.state]}
              </Tag>
            }
          />
        </div>
      ))}

      <VideoPreviewModal modal={videoDetailsModal} video={video} />
      <VideoSubtitleEditorModal
        modal={videoSubtitleEditorModal}
        videoId={video.options![0].reference}
      />
    </>
  );
};

const downloadFile = async (file: IDamFile, option_id: string | undefined) => {
  if (!option_id || !file) {
    return;
  }

  const option = await downloadFileOption(file.id, option_id);

  const downloadLink = document.createElement('a');
  downloadLink.href = option.download_url!;
  downloadLink.target = '_blank';
  downloadLink.download = file.name!;
  downloadLink.click();
};

const VideoAsset = ({ video }: Props) => {
  if (!video.file) {
    return null;
  }

  return (
    <>
      <Title level={3}>Arquivo</Title>
      <Form
        initialValues={{ ...video.file, size: formatBytes(video.file?.size) }}
        layout="vertical"
      >
        <Row gutter={[16, 16]} align="middle">
          <Col xs={8}>
            <Form.Item label="Nome do arquivo" name="name">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Tipo do arquivo" name="content_type">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Tamanho do arquivo" name="size">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={4}>
            <Button
              onClick={async () =>
                downloadFile(video.file!, video.file?.options?.at(0)?.id)
              }
            >
              <Icons.DownloadOutlined /> Baixar
            </Button>
          </Col>
        </Row>
      </Form>
      <Title level={4}>Storage Providers</Title>
      {video.file?.options?.map(el => (
        <div key={el.id} className="border rounded p-2 mb-2">
          <Title level={5}>{el.provider.name}</Title>
          <ShowFieldData
            label="Status"
            value={
              <Tag color={el.state !== 'uploaded' ? 'error' : 'success'}>
                {StatusMap[el.state]}
              </Tag>
            }
          />
        </div>
      ))}
    </>
  );
};

const VideoActions = ({ video, show }: Props) => {
  const [loading, setLoading] = useState(false);

  const onPublish = async () => {
    setLoading(true);

    try {
      await videoPublish(video.id!);
    } catch {
      setLoading(false);
    } finally {
      window.location.reload();
    }
  };

  const onUnpublish = async () => {
    setLoading(true);

    try {
      await videoUnpublish(video.id!);
    } catch {
      setLoading(false);
    } finally {
      window.location.reload();
    }
  };

  if (!video || !show) {
    return null;
  }

  return (
    <>
      <Title level={3}>Ações</Title>
      <Row gutter={[16, 16]}>
        <Col xs={3}>
          <Button
            disabled={video.status === 'published' || !video.preview_url}
            type="primary"
            className="!flex items-center justify-center mx-auto"
            onClick={onPublish}
            loading={loading}
          >
            <Icons.SendOutlined />
            Publicar
          </Button>
        </Col>
        <Col xs={3}>
          <Button
            disabled={video.status !== 'published' || !video.preview_url}
            danger
            className="!flex items-center justify-center mx-auto"
            loading={loading}
            onClick={onUnpublish}
          >
            <Icons.UndoOutlined />
            Desativar
          </Button>
        </Col>
      </Row>
    </>
  );
};

const Details = ({ video, show, formProps }: Props) => {
  return (
    <>
      <div className="flex gap-4 flex-col">
        <VideoDetails video={video} show={show} formProps={formProps} />
        <Divider />
        <VideoAsset video={video} show={show} />
        <Divider />
        <VideoActions video={video} show={!show} />
      </div>
    </>
  );
};

export default Details;
