/* eslint-disable unicorn/filename-case */
export const coursesIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00065 14L7.93395 13.8999C7.47085 13.2053 7.23931 12.858 6.93339 12.6065C6.66256 12.3839 6.35049 12.2169 6.01505 12.1151C5.63615 12 5.21872 12 4.38387 12H3.46732C2.72058 12 2.34721 12 2.062 11.8547C1.81111 11.7268 1.60714 11.5229 1.47931 11.272C1.33398 10.9868 1.33398 10.6134 1.33398 9.86667V4.13333C1.33398 3.3866 1.33398 3.01323 1.47931 2.72801C1.60714 2.47713 1.81111 2.27316 2.062 2.14532C2.34721 2 2.72058 2 3.46732 2H3.73398C5.22746 2 5.97419 2 6.54463 2.29065C7.04639 2.54631 7.45434 2.95426 7.71 3.45603C8.00065 4.02646 8.00065 4.77319 8.00065 6.26667M8.00065 14V6.26667M8.00065 14L8.06735 13.8999C8.53045 13.2053 8.762 12.858 9.06792 12.6065C9.33875 12.3839 9.65081 12.2169 9.98625 12.1151C10.3652 12 10.7826 12 11.6174 12H12.534C13.2807 12 13.6541 12 13.9393 11.8547C14.1902 11.7268 14.3942 11.5229 14.522 11.272C14.6673 10.9868 14.6673 10.6134 14.6673 9.86667V4.13333C14.6673 3.3866 14.6673 3.01323 14.522 2.72801C14.3942 2.47713 14.1902 2.27316 13.9393 2.14532C13.6541 2 13.2807 2 12.534 2H12.2673C10.7738 2 10.0271 2 9.45668 2.29065C8.95491 2.54631 8.54696 2.95426 8.2913 3.45603C8.00065 4.02646 8.00065 4.77319 8.00065 6.26667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
