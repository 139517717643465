import * as Icons from '@ant-design/icons';
import { Col, Row } from 'antd';
import { IDocument } from 'interfaces/documentation/document';
import { useDocumentationStore } from '../documentation-store';

interface DocumentItemImageProps {
  document: IDocument;
}

export const DocumentItemImage = ({ document }: DocumentItemImageProps) => {
  const openModal = useDocumentationStore(s => s.openModal);

  return (
    <Row gutter={16} className="w-full mb-4">
      <Col md={6}>
        <div
          className="rounded-md overflow-hidden cursor-pointer"
          onClick={() => openModal('show', document)}
        >
          <div className="flex items-center justify-end text-white text-xs w-full bg-gray-500 p-2">
            <Icons.ZoomInOutlined />
          </div>
          <div className="flex items-center justify-center h-4 w-full text-3xl bg-gray-200 px-4 py-8">
            <Icons.FileSearchOutlined />
          </div>
          <div className="flex items-center w-full bg-gray-500 text-white text-xs p-2">
            {document.name}
          </div>
        </div>
      </Col>
      <Col md={6}>
        <div className="w-full h-full rounded-sm bg-gray-50" />
      </Col>
      <Col md={6}>
        <div className="w-full h-full rounded-sm bg-gray-50" />
      </Col>
      <Col md={6}>
        <div className="w-full h-full rounded-sm bg-gray-50" />
      </Col>
    </Row>
  );
};
