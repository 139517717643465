import * as Icons from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Table,
  Upload
} from 'antd';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { IProduct } from 'interfaces/products';
import { UserRow } from 'interfaces/bulk_users';
import { parseFile } from 'services/bulk-users';
import { useState } from 'react';

const FileTable = ({ userList }: { userList: UserRow[] }) => (
  <Table
    dataSource={userList}
    columns={[
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email'
      }
    ]}
    pagination={{ pageSize: 5 }}
  />
);

const checkField = (field: string) => field?.length && field.length > 0;

export const BulkBonusCreate = () => {
  const { formProps, saveButtonProps } = useForm({ redirect: false });

  const { selectProps: productSelectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const [userList, setUserList] = useState<UserRow[]>([]);

  const valid = userList.every(({ email }) => {
    return checkField(email);
  });

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onFinish = (values: {}) => {
    const emails = userList.map(({ email }) => email);
    formProps.onFinish!({ ...values, user_emails: emails });
  };

  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        disabled: !valid || userList.length === 0
      }}
    >
      <Form {...formProps} layout="vertical" onFinish={onFinish}>
        <Form.Item>
          <Upload
            accept="text/csv"
            fileList={[]}
            onRemove={() => setUserList([])}
            beforeUpload={async newFile => {
              const rows = await parseFile(newFile);
              const filteredRows = rows.filter(row => checkField(row.email));
              setUserList(filteredRows);

              return false;
            }}
          >
            <Button icon={<Icons.UploadOutlined />}>Escolher arquivo</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Produto"
          name="product_id"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...productSelectProps} />
        </Form.Item>
        <Form.Item
          label="Motivo"
          name="motive"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Expiração"
          name="expires_at"
          getValueProps={value => {
            return {
              value: value ? dayjs(value) : ''
            };
          }}
          rules={[
            {
              required: true
            }
          ]}
        >
          <DatePicker />
        </Form.Item>
      </Form>
      <FileTable userList={userList} />
      {!valid && <Alert type="error" message="Arquivo inválido" />}
    </Create>
  );
};
