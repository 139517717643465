import * as Icons from '@ant-design/icons';
import RuleConfig from 'components/offers/gateways/Config';
import { Button, Form, Table, Typography, Upload } from 'antd';
import { Edit, TextField, useForm } from '@refinedev/antd';
import { IGatewayRangeTable, IGatewayRule } from 'interfaces/gateways';
import { parseRangeFile } from 'services/gateways';
import { useState } from 'react';

const { Title } = Typography;

interface RangeTableProps {
  rangeList: IGatewayRangeTable[];
}

const RangeTable = ({ rangeList }: RangeTableProps) => (
  <Table dataSource={rangeList} pagination={{ pageSize: 5 }}>
    <Table.Column
      dataIndex="name"
      key="name"
      title="Nome"
      render={value => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="value_start"
      key="value_start"
      title="Início"
      render={value => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="value_end"
      key="value_end"
      title="Fim"
      render={value => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="config"
      key="config"
      title="Configuração"
      render={config => <RuleConfig config={config} />}
    />
  </Table>
);

export const GatewayRuleEdit = () => {
  const [rangeList, setRangeList] = useState<IGatewayRangeTable[]>([]);
  const { formProps, saveButtonProps, queryResult } = useForm<IGatewayRule>();

  const data = queryResult?.data?.data;

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onFinish = (values: {}) => {
    const range_table = data?.kind === 'zip_code' ? rangeList : [];

    formProps.onFinish!({ ...values, range_table });
  };

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar regras de gateway">
      <Form {...formProps} layout="horizontal" onFinish={onFinish}>
        {data?.kind === 'zip_code' && (
          <>
            <Title level={4}>Tabela de CEP</Title>
            <Form.Item>
              <Upload
                accept="application/json"
                fileList={[]}
                onRemove={() => setRangeList([])}
                beforeUpload={async newFile => {
                  const rows = await parseRangeFile(newFile);

                  setRangeList(rows);

                  return false;
                }}
              >
                <Button icon={<Icons.UploadOutlined />}>
                  Escolher arquivo
                </Button>
              </Upload>
            </Form.Item>
            <RangeTable rangeList={rangeList} />
          </>
        )}
      </Form>
    </Edit>
  );
};
