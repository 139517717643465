import AgentInfo from './AgentInfo';
import { BaseKey } from '@refinedev/core';
import { Col, Form, Row, Select, Typography } from 'antd';
import { IProfessor } from '../../../../interfaces/professor';
import { useCallback } from 'react';
import { useSelect } from '@refinedev/antd';

export interface ProfessorInfoProps {
  professorData?: IProfessor;
  type?: 'show' | 'edit';
}

const AgentList = ({ professorData, type }: ProfessorInfoProps) => {
  const { queryResult, selectProps } = useSelect({
    resource: 'agents',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ],
    pagination: {
      mode: 'server'
    }
  });

  const options = useCallback(() => {
    let tempOptions = queryResult.data?.data.map(item => ({
      name: item.name,
      login: item.login,
      value: item.id
    }));
    tempOptions = tempOptions && [
      {
        name: '⛔️ Desassociar usuário',
        login: undefined,
        value: null as unknown as BaseKey
      },
      ...tempOptions
    ];
    return tempOptions;
  }, [queryResult]);

  return (
    <>
      <Row gutter={[16, 16]} className="w-full">
        <Col className="flex justify-between">
          <Typography.Title level={5} style={{ margin: '12px 0' }}>
            Associação do professor com um usuário
          </Typography.Title>
        </Col>
      </Row>
      {type !== 'show' && (
        <Row gutter={[16, 16]} className="w-full">
          <Col className="flex justify-between">
            <Form.Item label="Usuário do admin" name="agent_id">
              <Select
                {...selectProps}
                placeholder="Associar ou desassociar a um usuário"
                style={{ minWidth: 400 }}
                options={undefined}
              >
                {options()?.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {`${option.name}${
                      option.login ? ` (${option.login})` : ''
                    }`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      {professorData && <AgentInfo professorData={professorData} type={type} />}
    </>
  );
};

export default AgentList;
