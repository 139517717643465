import { Col, Row, Space, Table } from 'antd';
import {
  EditButton,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { ICourseCertificate } from 'interfaces/course_certificate';
import { ISignatures } from 'interfaces/signatures';

export const SignaturesList = () => {
  const { tableProps } = useTable<ISignatures>({});

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="title"
              key="title"
              title="Título"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex={['certifier', 'name']}
              key="certifier"
              title="Nome da instituição"
              render={value => <TextField value={value} />}
            />
            <Table.Column<ICourseCertificate>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
