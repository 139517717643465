import FilterForm from '../../../components/forms/FilterForm';
import { Col, Form, Input, Row, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  DeleteButton,
  EditButton,
  ImageField,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { ITestimonial } from 'interfaces/testimonials';

interface IFilter {
  title: string;
  subtitle: string;
}

export const TestimonialList = () => {
  const { tableProps, searchFormProps } = useTable<
    ITestimonial,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { title, subtitle } = params;

      filters.push(
        {
          field: 'filter[title]',
          operator: 'eq',
          value: title
        },
        {
          field: 'filter[subtitle]',
          operator: 'eq',
          value: subtitle
        }
      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Título" name="title">
              <Input placeholder="Título" />
            </Form.Item>
            <Form.Item label="Subtítulo" name="subtitle">
              <Input placeholder="Subtítulo" />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="title"
              key="title"
              title="Título"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="subtitle"
              key="subtitle"
              title="Subtítulo"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="description"
              key="description"
              title="Descrição"
              render={value => <TextField value={value} />}
            />

            <Table.Column<ITestimonial>
              title="Imagem"
              dataIndex="image"
              render={value => <ImageField value={value} width={100} />}
              width="20%"
            />

            <Table.Column<ITestimonial>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <DeleteButton
                      size="small"
                      recordItemId={record.id}
                      hideText
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
