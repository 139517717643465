import {
  NoticeBoardCreate,
  NoticeBoardList,
  NoticeBoardShow
} from 'pages/academic/notice_boards';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'notice_boards',
  label: 'Mural de avisos',
  list: NoticeBoardList,
  create: NoticeBoardCreate,
  show: NoticeBoardShow,
  actions: ['list', 'create', 'show'],
  parentName: 'communication'
};

export default generateResources(resource);
