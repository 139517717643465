import SlugInput from '../../../components/form-items/SlugInput';
import { Alert, Form, Input, Select } from 'antd';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { ICourse } from 'interfaces/courses';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { IProduct, ProductableType } from 'interfaces/products';
import { IProductBundle } from 'interfaces/product_bundles';
import { IProductSet } from 'interfaces/product_sets';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { PRODUCTABLE_TYPES } from 'constants/admin/products';
import { slugify } from '../../../utils/slugify';
import { useLocation } from 'react-router-dom';

export const ProductCreate = () => {
  const location = useLocation();

  const [productType, setProductType] = useState<ProductableType>(
    PRODUCTABLE_TYPES[0].value
  );

  const { form, formProps, saveButtonProps } = useForm<IProduct>({
    redirect: 'edit'
  });

  useEffect(() => {
    if (location.state && location.state.productData) {
      form.setFieldsValue(location.state.productData);
    }
  }, [location.state, form]);

  const changeProductType = (value: ProductableType) => {
    setProductType(value);
    form.setFieldsValue({ productable_id: null });
  };

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: graduateCourseSelectProps } = useSelect<IGraduateCourse>(
    {
      resource: 'graduate_courses',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const { selectProps: setSelectProps } = useSelect<IProductSet>({
    resource: 'products/sets',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: bundleSelectProps } = useSelect<IProductBundle>({
    resource: 'products/bundles',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const createSlug = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const value: string = (event.target as HTMLInputElement).value;

      form.setFieldsValue({ slug: slugify(value) });
    },
    [form]
  );

  const selectProps = (() => {
    switch (productType) {
      case 'Course': {
        return courseSelectProps;
      }

      case 'Graduate::Course': {
        return graduateCourseSelectProps;
      }

      case 'Products::Set': {
        return setSelectProps;
      }

      case 'Products::Bundle': {
        return bundleSelectProps;
      }
    }
  })();

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar produto">
      <Alert
        message="Cada curso, pós, combo ou set só pode ser associado a um produto. Caso contrário, a criação não será efetuada"
        showIcon
        type="warning"
        style={{ marginBottom: '14px' }}
      />
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Tipo de produto"
          name="productable_type"
          initialValue={productType}
          className="max-w-xs"
        >
          <Select onChange={changeProductType}>
            {PRODUCTABLE_TYPES.map(type => (
              <Select.Option key={type.value}>{type.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input onKeyUp={createSlug} />
        </Form.Item>

        <SlugInput />

        <Form.Item
          label="Produto"
          name="productable_id"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...selectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
