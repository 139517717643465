import { SettingOutlined } from '@ant-design/icons';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'configurations',
  label: 'Configurações',
  icon: <SettingOutlined />
};

export default generateResources(resource);
