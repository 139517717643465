import { IGroup } from 'interfaces/epimetheus/groups';
import { ShowButton, TextField } from '@refinedev/antd';
import { Space, Table, TableProps, Tag } from 'antd';
import { useParams } from 'react-router-dom';

interface HtmlContainersTableProps {
  tableProps: TableProps;
}

const translateState = (state: string) => {
  switch (state) {
    case 'pending': {
      return 'Pendente';
    }

    case 'processing': {
      return 'Processando';
    }

    case 'processed': {
      return 'Concluído';
    }

    default: {
      return 'Desconhecido';
    }
  }
};

export const HtmlContainersTable = ({
  tableProps
}: HtmlContainersTableProps) => {
  const { id: libraryId } = useParams();
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="name"
        key="name"
        title="Nome"
        render={value => <TextField value={value} />}
      />

      <Table.Column
        dataIndex="state"
        key="state"
        title="Status"
        render={value => <Tag>{translateState(value)}</Tag>}
      />
    </Table>
  );
};
