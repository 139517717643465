import { Col, Row, Typography } from 'antd';
import { IProfessor } from 'interfaces/professor';
import { ShowButton } from '@refinedev/antd';
import { TitleMap } from 'services/professors';

const { Text, Title } = Typography;

export interface ProfessorInfoProps {
  professorData: IProfessor;
}

const ProfessorInfo = ({ professorData }: ProfessorInfoProps) => {
  return (
    <>
      <Row className="border rounded p-2 mb-2">
        <Title level={5} style={{ margin: '0 0 8px 0' }}>
          Dados do professor
        </Title>
        <ShowButton
          size="small"
          resource="professors"
          recordItemId={professorData.id}
          className="ml-4"
        >
          Visualizar
        </ShowButton>
        <Row gutter={[16, 16]} className="w-full mb-1">
          <Col>
            <Row>
              <Text style={{ fontWeight: 'bold' }}>Nome</Text>
            </Row>
            <Row className="ml-1">
              <Text>{professorData.name}</Text>
            </Row>
          </Col>
          <Col>
            <Row>
              <Text style={{ fontWeight: 'bold' }}>Título</Text>
            </Row>
            <Row className="ml-1">
              <Text>
                {TitleMap[professorData.title as keyof typeof TitleMap]}
              </Text>
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ProfessorInfo;
