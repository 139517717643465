import { ICity } from 'interfaces/users';
import { Select, SelectProps } from 'antd';
import { useSelect } from '@refinedev/antd';

export const CitySelectField = (props: SelectProps) => {
  const { selectProps } = useSelect<ICity>({
    resource: 'cities',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 50,

    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ],

    filters: [
      {
        field: 'filter[by_id]',
        operator: 'eq',
        value: props.value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Select {...props} {...selectProps} allowClear style={{ minWidth: 250 }} />
  );
};
