import * as Icons from '@ant-design/icons';
import { Alert, Button, Col, Space, Typography } from 'antd';
import { IDocumentation } from 'interfaces/documentation/documentations';
import { IUser } from 'interfaces/users';
import {
  getUserDocumentation,
  handleDownloadDeclaration,
  hasPersonalInfo
} from 'services/report';
import { useCallback, useEffect, useState } from 'react';
import { useNotification } from '@refinedev/core';

const { Title } = Typography;

export interface TabDeclarationsProps {
  courseEnrollmentId: string;
  user: IUser;
}

const Warnings = {
  personalInfo:
    'Para que você consiga emitir as suas declarações ou seu certificado, é necessário que o(s) campo(s) de CPF e nome completo do aluno estejam cadastrados.',
  documentation:
    'Para que você consiga emitir os certificados e declarações, é necessário que a documentação esteja aprovada.'
};

export const TabDeclarations = ({
  courseEnrollmentId,
  user
}: TabDeclarationsProps) => {
  const { open } = useNotification();
  const [userDocumentation, setUserDocumentation] = useState<IDocumentation>();

  const getDocumentation = useCallback(async () => {
    const documentation = await getUserDocumentation(courseEnrollmentId, open!);
    setUserDocumentation(documentation?.[0]);
  }, [courseEnrollmentId, open]);

  useEffect(() => {
    getDocumentation();
  }, [getDocumentation]);

  const handleCreateReport = useCallback(() => {
    handleDownloadDeclaration(courseEnrollmentId, open!);
  }, [open, courseEnrollmentId]);

  return (
    <Col>
      <Space className="mb-2">
        {!hasPersonalInfo(user) && (
          <Alert showIcon type="warning" message={Warnings.personalInfo} />
        )}
        {userDocumentation?.status !== 'approved' && (
          <Alert showIcon type="warning" message={Warnings.documentation} />
        )}
      </Space>
      <div className="flex justify-between p-8 rounded-lg border  ">
        <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
          Declaração de histórico acadêmico
        </Title>

        <Button
          disabled={
            !hasPersonalInfo(user) || userDocumentation?.status !== 'approved'
          }
          icon={<Icons.DownloadOutlined />}
          onClick={handleCreateReport}
        >
          Emitir
        </Button>
      </div>
    </Col>
  );
};
