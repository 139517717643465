/* eslint-disable unicorn/filename-case */
export const salesIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.68 1.76065L2.64 3.14732C2.43401 3.42197 2.33102 3.5593 2.33341 3.67425C2.33549 3.77428 2.38239 3.86808 2.46117 3.92977C2.55169 4.00065 2.72335 4.00065 3.06667 4.00065H12.9333C13.2766 4.00065 13.4483 4.00065 13.5388 3.92977C13.6176 3.86808 13.6645 3.77428 13.6666 3.67425C13.669 3.5593 13.566 3.42197 13.36 3.14732L12.32 1.76065M3.68 1.76065C3.79733 1.60421 3.856 1.52599 3.93035 1.46957C3.9962 1.4196 4.07077 1.38232 4.15026 1.35961C4.24 1.33398 4.33778 1.33398 4.53333 1.33398H11.4667C11.6622 1.33398 11.76 1.33398 11.8497 1.35961C11.9292 1.38232 12.0038 1.4196 12.0697 1.46957C12.144 1.52599 12.2027 1.60421 12.32 1.76065M3.68 1.76065L2.42667 3.43176C2.26836 3.64284 2.18921 3.74838 2.133 3.8646C2.08312 3.96773 2.04675 4.07686 2.02477 4.18929C2 4.31599 2 4.44792 2 4.71176L2 12.534C2 13.2807 2 13.6541 2.14532 13.9393C2.27316 14.1902 2.47713 14.3942 2.72801 14.522C3.01323 14.6673 3.3866 14.6673 4.13333 14.6673L11.8667 14.6673C12.6134 14.6673 12.9868 14.6673 13.272 14.522C13.5229 14.3942 13.7268 14.1902 13.8547 13.9393C14 13.6541 14 13.2807 14 12.534V4.71176C14 4.44792 14 4.31599 13.9752 4.18929C13.9533 4.07686 13.9169 3.96773 13.867 3.8646C13.8108 3.74838 13.7316 3.64284 13.5733 3.43176L12.32 1.76065M10.6667 6.66732C10.6667 7.37456 10.3857 8.05284 9.88562 8.55293C9.38552 9.05303 8.70724 9.33398 8 9.33398C7.29276 9.33398 6.61448 9.05303 6.11438 8.55293C5.61428 8.05284 5.33333 7.37456 5.33333 6.66732"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
