import { FolderOutlined } from '@ant-design/icons';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'secretary',
  label: 'Secretaria',
  icon: <FolderOutlined />
};

export default generateResources(resource);
