import * as Icons from '@ant-design/icons';
import Evaluation from 'components/classroom/Evaluation';
import EvaluationCreateModal from 'components/modal/evaluations/EvaluationCreateModal';
import EvaluationEditModal from 'components/modal/evaluations/EvaluationEditModal';
import ReplacementAssociateStudentModal from 'components/modal/evaluations/ReplacementAssociateStudentModal';
import ReplacementCreateModal from 'components/modal/evaluations/ReplacementCreateModal';
import ReplacementEditModal from 'components/modal/evaluations/ReplacementEditModal';
import { Button, Space, Typography } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IAssessment } from 'interfaces/assessments';
import { IAssignment } from 'interfaces/assignments';
import { IClassroomEvaluation } from 'interfaces/classrooms';
import { sanitizeEvaluations } from 'services/classroom/evaluations';
import { useModal, useSelect, useTable } from '@refinedev/antd';
import { useState } from 'react';

export const ClassroomEvaluations = ({
  classroomId,
  disciplineId
}: {
  classroomId: string;
  disciplineId: string;
}) => {
  const [evaluationId, setEvaluationId] = useState<string>('');
  const [parentId, setParentId] = useState<string>('');
  const resource = `classrooms/${classroomId}/evaluations`;
  const { tableProps } = useTable<IClassroomEvaluation, HttpError, unknown>({
    resource,
    pagination: {
      pageSize: 100
    }
  });

  const filters: CrudFilters = [
    {
      field: 'filter[by_parent_type]',
      operator: 'eq',
      value: 'Graduate::Discipline'
    },
    {
      field: 'filter[by_parent_id]',
      operator: 'eq',
      value: disciplineId
    }
  ];

  const {
    show: evaluationEditModalShow,
    close: evaluationEditModalClose,
    modalProps: evaluationEditModalProps
  } = useModal();

  const {
    show: evaluationCreateModalShow,
    close: evaluationCreateModalClose,
    modalProps: evaluationCreateModalProps
  } = useModal();

  const {
    show: replacementCreateModalShow,
    close: replacementCreateModalClose,
    modalProps: replacementCreateModalProps
  } = useModal();

  const {
    show: replacementEditModalShow,
    close: replacementEditModalClose,
    modalProps: replacementEditModalProps
  } = useModal();

  const {
    show: replacementAssociationModalShow,
    close: replacementAssociationModalClose,
    modalProps: replacementAssociationModalProps
  } = useModal();

  const { selectProps: assessmentSelectProps } = useSelect<
    IAssessment,
    HttpError
  >({
    resource: 'assessments',
    optionLabel: 'name',
    optionValue: 'id',
    filters,

    onSearch: value => [
      {
        field: 'filter[search_name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: assignmentSelectProps } = useSelect<
    IAssignment,
    HttpError
  >({
    resource: 'assignments',
    optionLabel: 'name',
    optionValue: 'id',
    filters,

    onSearch: value => [
      {
        field: 'filter[search_name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const handleReplacementCreate = (parentId: string) => {
    setParentId(parentId);
    replacementCreateModalShow();
  };

  const handleReplacementEdit = (parentId: string, replacementId: string) => {
    setParentId(parentId);
    setEvaluationId(replacementId);
    replacementEditModalShow();
  };

  const handleReplacementAssociation = (parentId: string) => {
    setParentId(parentId);
    replacementAssociationModalShow();
  };

  return (
    <>
      <Space align="end" className="my-4 justify-between w-full">
        <Typography.Title level={5}>Avaliações da turma</Typography.Title>
        <Button
          icon={<Icons.PlusOutlined />}
          onClick={() => {
            evaluationCreateModalShow();
          }}
        >
          Nova avaliação
        </Button>
      </Space>
      <div className="flex flex-col items-center justify-center gap-6">
        {sanitizeEvaluations(
          tableProps.dataSource as IClassroomEvaluation[]
        )?.map((evaluation, id) => (
          <Evaluation
            key={id}
            evaluationData={evaluation}
            resource={resource}
            evaluationEditModal={() => {
              setEvaluationId(evaluation.id);
              evaluationEditModalShow();
            }}
            replacementCreateModal={id => handleReplacementCreate(id)}
            replacementEditModal={(parentId, replacementId) =>
              handleReplacementEdit(parentId, replacementId)
            }
            replacementAssociationModalShow={replacementId =>
              handleReplacementAssociation(replacementId)
            }
          />
        ))}

        {!tableProps.dataSource?.length && (
          <Typography.Paragraph>
            Nenhuma atividade cadastrada para esta turma!
          </Typography.Paragraph>
        )}
      </div>

      {evaluationId && (
        <EvaluationEditModal
          modalProps={evaluationEditModalProps}
          modalClose={evaluationEditModalClose}
          evaluationResource={resource}
          assignmentSelectorProps={assignmentSelectProps}
          assesmentSelectorProps={assessmentSelectProps}
          evaluationId={evaluationId}
        />
      )}
      <EvaluationCreateModal
        modalProps={evaluationCreateModalProps}
        modalClose={evaluationCreateModalClose}
        evaluationResource={resource}
        assignmentSelectorProps={assignmentSelectProps}
        assesmentSelectorProps={assessmentSelectProps}
      />
      <ReplacementCreateModal
        modalProps={replacementCreateModalProps}
        modalClose={replacementCreateModalClose}
        evaluationResource={resource}
        assignmentSelectorProps={assignmentSelectProps}
        assesmentSelectorProps={assessmentSelectProps}
        parentId={parentId}
      />
      <ReplacementEditModal
        modalProps={replacementEditModalProps}
        modalClose={replacementEditModalClose}
        evaluationResource={resource}
        assignmentSelectorProps={assignmentSelectProps}
        assesmentSelectorProps={assessmentSelectProps}
        parentId={parentId}
        evaluationId={evaluationId}
      />
      <ReplacementAssociateStudentModal
        modalProps={replacementAssociationModalProps}
        modalClose={replacementAssociationModalClose}
        parentId={parentId}
        resource={`classrooms/${classroomId}/enrollments`}
      />
    </>
  );
};
