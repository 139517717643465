import * as Icons from '@ant-design/icons';
import { Button, Space, Table, TableProps } from 'antd';
import { EditButton, ShowButton, TextField } from '@refinedev/antd';
import { IAssignment } from 'interfaces/assignments';
import { ICourse } from 'interfaces/courses';

interface ListAssignmentsTableProps {
  tableProps: TableProps<IAssignment>;
  type: 'Course' | 'Graduate::Discipline';
  updateAttemptStatus: (id: string) => void;
}

export const ListAssignmentsTable = ({
  tableProps,
  type,
  updateAttemptStatus
}: ListAssignmentsTableProps) => {
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="name"
        key="name"
        title="Tarefa"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="parent"
        key="parent"
        title={type === 'Graduate::Discipline' ? 'Disciplina' : 'Curso'}
        render={(course: ICourse) => <TextField value={course?.name} />}
      />
      <Table.Column
        key="total"
        title="Tarefas realizadas"
        render={(_, record: IAssignment) => {
          const {
            passed_attempts_count,
            failed_attempts_count,
            pending_evaluation_attempts_count,
            evaluation_started_attempts_count
          } = record;

          const total =
            passed_attempts_count +
            failed_attempts_count +
            pending_evaluation_attempts_count +
            evaluation_started_attempts_count;
          return (
            <>
              {total ? (
                <>
                  <Space align="start" direction="vertical">
                    <TextField
                      value={`✅ ${
                        passed_attempts_count + failed_attempts_count
                      } corrigida(s)`}
                    />

                    {!!evaluation_started_attempts_count && (
                      <TextField
                        value={`📝 ${evaluation_started_attempts_count} em correção`}
                      />
                    )}

                    {!!pending_evaluation_attempts_count && (
                      <TextField
                        value={`🕒 ${pending_evaluation_attempts_count} aguardando correção`}
                      />
                    )}
                  </Space>
                </>
              ) : (
                <TextField value="⚠️ Aguardando respostas" />
              )}
            </>
          );
        }}
      />
      <Table.Column<IAssignment>
        title="Ações"
        dataIndex="actions"
        width={150}
        render={(_, record: IAssignment) => {
          return (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <Button
                size="small"
                disabled={
                  record.pending_evaluation_attempts_count === 0 &&
                  record.evaluation_started_attempts_count === 0
                }
                type="primary"
                icon={<Icons.FileSearchOutlined />}
                onClick={() => updateAttemptStatus(record.id)}
              >
                Corrigir
              </Button>
            </Space>
          );
        }}
      />
    </Table>
  );
};
