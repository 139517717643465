import * as Icons from '@ant-design/icons';
import ContentUpload from 'components/ContentUpload';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Typography,
  message
} from 'antd';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { IAssignment } from 'interfaces/assignments';
import { ICourse } from 'interfaces/courses';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';

export const AssignmentEdit = () => {
  const { form, formProps, saveButtonProps, queryResult } =
    useForm<IAssignment>({
      redirect: 'edit'
    });

  const assignment = queryResult?.data?.data;

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const assessmentType = form.getFieldValue('parent_type');

  const onUploadFile = (key: number, url: string, download_url: string) => {
    const fields = form.getFieldsValue();
    const { instructions } = fields as IAssignment;
    Object.assign(instructions[key], { url, download_url });

    form.setFieldsValue({ instructions });
  };

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar atividade">
      <Form form={form} {...formProps} layout="vertical">
        <Form.Item name="parent_type" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Quantidade de tentativas"
          name="max_attempts"
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Nota mínima (0 - 100)"
          name="passing_score"
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber disabled />
        </Form.Item>

        <Form.Item
          label={
            assessmentType === 'Graduate::Discipline' ? 'Disciplina' : 'Curso'
          }
          name="parent_id"
          required
        >
          {assessmentType === 'Graduate::Discipline' ? (
            <Select {...disciplineSelectProps} />
          ) : (
            <Select {...courseSelectProps} />
          )}
        </Form.Item>

        <Form.Item
          label="Liberar após"
          name="course_progress_percentage"
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber addonAfter="% do curso" />
        </Form.Item>

        <Typography.Title level={5}>Instruções</Typography.Title>
        <Form.List name={['instructions']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <div key={field.key} className="flex gap-5 w-full">
                  <Form.Item
                    name={[field.name, 'name']}
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                  >
                    <Input placeholder="Nome" />
                  </Form.Item>

                  <Form.Item name={[field.name, 'url']} hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item name={[field.name, 'download_url']} hidden>
                    <Input />
                  </Form.Item>

                  <ContentUpload
                    type="assignment_instruction"
                    accept="application/pdf"
                    onUpload={(url, downloadUrl) =>
                      onUploadFile(field.name, url, downloadUrl!)
                    }
                    src={assignment?.instructions[field.name]?.download_url}
                  />

                  <Icons.MinusCircleOutlined
                    className="mb-4"
                    onClick={() => remove(field.name)}
                  />
                </div>
              ))}
              <Button
                type="dashed"
                className="!flex items-center justify-center"
                onClick={() => add()}
                block
              >
                <Icons.PlusOutlined />
                Adicionar Instrução
              </Button>
            </>
          )}
        </Form.List>

        <br></br>

        <Typography.Title level={5}>Critérios</Typography.Title>
        <Form.List
          name={['criteria']}
          initialValue={[{ name: '' }]}
          rules={[
            {
              validator: async (_, names) => {
                if (names.length === 0) {
                  message.error('É necessário adicionar no mínimo 1 critério');
                  throw new Error(
                    'É necessário adicionar no mínimo 1 critério'
                  );
                }
              }
            }
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <div key={field.key} className="flex gap-5 w-full">
                  <div className="w-full md:w-1/3">
                    <Form.Item
                      name={[field.name, 'name']}
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                      label="Enunciado"
                      labelCol={{ span: 24 }}
                    >
                      <Input.TextArea placeholder="Escreva um enunciado, questão ou tarefa" />
                    </Form.Item>
                  </div>
                  <div className="w-full md:w-1/4">
                    <Form.Item
                      name={[field.name, 'answer_type']}
                      label={
                        <span>
                          Tipo de Resposta {'  '}
                          <Tooltip title="Escolha a forma de envio das respostas dos alunos">
                            <Icons.QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      labelCol={{ span: 24 }}
                      required
                    >
                      <Select placeholder="Selecione o tipo de resposta">
                        <Select.Option key="text">Texto</Select.Option>
                        <Select.Option key="file">Arquivo PDF</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <Tooltip title="Remover Enunciado">
                    <Icons.MinusCircleOutlined
                      className="mb-4"
                      style={{ color: 'red' }}
                      onClick={() => remove(field.name)}
                    />
                  </Tooltip>
                </div>
              ))}
              <Button
                type="dashed"
                className="!flex items-center justify-center"
                onClick={() => add()}
                block
              >
                <Icons.PlusOutlined />
                Adicionar novo Enunciado
              </Button>
            </>
          )}
        </Form.List>
      </Form>
    </Edit>
  );
};
