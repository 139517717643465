import React from 'react';
import { IAcademicRecordEquivalency } from 'interfaces/equivalency';
import { Space, Typography } from 'antd';
import { TagField } from '@refinedev/antd';
import { tagColorByStatus } from '../../../../../utils/statusColorMapping';
import { translateEquivalencyStatus } from '../../../academic_record_equivalencies';

interface StudentInfoProps {
  record?: IAcademicRecordEquivalency;
}

export const AcademicRecordDetails: React.FC<StudentInfoProps> = ({
  record
}) => {
  return (
    <Space direction="vertical" size={16} className="pl-2">
      <Space direction="horizontal" align="start" size="middle">
        <Space>
          <Typography.Text strong>Situação:</Typography.Text>
          <TagField
            color={tagColorByStatus(String(record?.status))}
            value={translateEquivalencyStatus(record?.status || '')}
          />
        </Space>
        {record?.status === 'approved' && (
          <Space>
            <Typography.Text strong>Nota:</Typography.Text>
            <Typography.Text>{record?.score}</Typography.Text>
          </Space>
        )}
      </Space>
    </Space>
  );
};
