import {
  DeleteButton,
  EditButton,
  List,
  TextField,
  useTable
} from '@refinedev/antd';
import { IGroup } from 'interfaces/epimetheus/groups';
import { ILibrary } from 'interfaces/libraries';
import { Space, Table } from 'antd';
import { useOne } from '@refinedev/core';
import { useParams } from 'react-router-dom';

export const TagGroupList = () => {
  const { libraryId } = useParams();
  const { tableProps } = useTable<IGroup>({
    resource: 'epimetheus/groups',
    meta: {
      libraryId
    }
  });

  const { data } = useOne<ILibrary>({
    resource: 'prometheus/v2/libraries',
    id: libraryId!
  });

  return (
    <List title={`Tags da biblioteca ${data?.data.name}`}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="Id"
          render={value => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />

        <Table.Column<IGroup>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButton size="small" recordItemId={record.id} hideText />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
