import { IDocumentation } from 'interfaces/documentation/documentations';
import { getAPI } from 'requests/api';

export const fetchSummaryDocumentation = async (
  enrollmentId: string
): Promise<IDocumentation[]> => {
  const res = await getAPI().get(
    `/admin/documentations?filter[by_enrollment_id]=${enrollmentId}`
  );

  return res.data;
};
