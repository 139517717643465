import qs from 'qs';
import { IClassroom, IClassroomEvaluation } from 'interfaces/classrooms';
import { getAPI } from './api';

export const getClassroomEvaluations = async (
  evaluationIds: string[]
): Promise<IClassroomEvaluation[]> => {
  const { data } = await getAPI().get(
    `admin/classrooms/evaluations?${qs.stringify(
      {
        filter: {
          by_id: evaluationIds
        }
      },
      {
        arrayFormat: 'brackets'
      }
    )}`
  );

  return data;
};

export const getClassrooms = async (
  classroomIds: string[]
): Promise<IClassroom[]> => {
  const { data } = await getAPI().get(
    `admin/classrooms?${qs.stringify(
      {
        filter: {
          by_id: classroomIds
        }
      },
      {
        arrayFormat: 'brackets'
      }
    )}`
  );

  return data;
};
