import React, { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { IDataNode } from 'interfaces/courses';
import { useModalReturnType } from '@refinedev/core';

interface NodeModalProps {
  modal: useModalReturnType;
  node: IDataNode;
  onUpdate: (node: IDataNode) => void;
}

const NodeModal = ({ modal, node, onUpdate }: NodeModalProps) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: node.title
  };

  const onClose = () => {
    modal.close();
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const { name: title } = await form.validateFields();

      const updatedNode = {
        ...node,
        title: title
      };

      onUpdate(updatedNode);
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      title="Editar nó"
      visible={modal.visible}
      width={1000}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        preserve={false}
      >
        <Form.Item label="Nome" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NodeModal;
