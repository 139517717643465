import * as Icons from '@ant-design/icons';
import { Button, Card, Form, FormProps, Row } from 'antd';
import { ButtonProps } from 'antd/lib';

interface FilterFormProps {
  searchFormProps: FormProps;
  children: React.ReactNode;
  title?: string;
  okButtonProps?: ButtonProps;
}

const FilterForm = ({
  searchFormProps,
  children,
  title = 'Filtro',
  okButtonProps
}: FilterFormProps) => {
  const handleClear = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  return (
    <Card title={title} size="small" style={{ marginBottom: 20 }}>
      <Form {...searchFormProps} layout="vertical">
        <Row className="gap-x-4">{children}</Row>

        <Row className="justify-end">
          <Button type="text" onClick={handleClear}>
            Limpar filtro
          </Button>
          <Button
            type="primary"
            className="ml-4"
            htmlType="submit"
            icon={<Icons.FilterOutlined />}
            {...okButtonProps}
          >
            Filtrar
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

export default FilterForm;
