import { GraduateEnrollmentShow } from 'pages/academic/users/graduate_enrollment/show';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'graduate_enrollment',
  label: 'Matrícula',
  show: GraduateEnrollmentShow,
  actions: ['show'],
  parentName: 'users'
};

export default generateResources(resource);
