import { TOfferCampaignKind } from 'interfaces/offers';

type TConfigFieldsKeys =
  | 'fixed_price'
  | 'percentage'
  | 'product_ids'
  | 'category_ids';
type TConfigFields = Map<TConfigFieldsKeys, { name: string; input: string }>;
interface IOfferCampaignKind {
  name: string;
  configFields: TConfigFields;
}

const KIND_CONFIGS = new Map<TConfigFieldsKeys, IOfferCampaignKind>([
  [
    'fixed_price',
    {
      name: 'Preço Fixo',
      configFields: new Map([
        ['fixed_price', { name: 'Preço', input: 'price' }],
        ['product_ids', { name: 'Produtos', input: 'product_ids' }],
        ['category_ids', { name: 'Categorias', input: 'category_ids' }]
      ])
    }
  ],
  [
    'percentage',
    {
      name: 'Porcentagem',
      configFields: new Map([
        ['percentage', { name: 'Porcentagem', input: 'percentage' }],
        ['product_ids', { name: 'Produtos', input: 'product_ids' }],
        ['category_ids', { name: 'Categorias', input: 'category_ids' }]
      ])
    }
  ]
]);

export const KINDS: TOfferCampaignKind[] = ['fixed_price', 'percentage'];

const findKindConfig = (key: TOfferCampaignKind): IOfferCampaignKind =>
  KIND_CONFIGS.get(key)!;

export const translateKind = (key: TOfferCampaignKind): string =>
  findKindConfig(key).name;

export const getConfigFields = (key: TOfferCampaignKind): TConfigFields =>
  findKindConfig(key).configFields;
