import FilterForm from 'components/forms/FilterForm';
import {
  Col,
  Form,
  FormProps,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select
} from 'antd';
import { ICourse } from 'interfaces/courses';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { useCallback, useMemo, useState } from 'react';
import { useSelect } from '@refinedev/antd';

export interface IListAssignmentFilter {
  by_parent_type: string;
  by_parent_id: string;
  by_name: string;
  with_pending_attempts: string;
}

export interface ListAssignmentFilterFormProps {
  searchFormProps: FormProps<IListAssignmentFilter>;
}

export const ListAssignmentFilter = ({
  searchFormProps
}: ListAssignmentFilterFormProps) => {
  const [selectedType, setSelectedType] = useState('Course');

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const onChangeType = useCallback((e: RadioChangeEvent) => {
    const value = e.target.value;
    setSelectedType(value);
  }, []);

  const selectProps = useMemo(() => {
    return selectedType === 'Course'
      ? courseSelectProps
      : disciplineSelectProps;
  }, [courseSelectProps, disciplineSelectProps, selectedType]);

  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={[24, 12]} className="w-full">
        <Col span={24}>
          <Form.Item
            label="Tipo de curso"
            name="by_parent_type"
            className="!mb-0"
          >
            <Radio.Group
              onChange={onChangeType}
              value={selectedType}
              defaultValue="Course"
            >
              <Radio value="Course">Curso</Radio>
              <Radio value="Graduate::Discipline">Disciplina</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label={selectedType === 'Course' ? 'Curso' : 'Disciplina'}
            name="by_parent_id"
            className="w-full"
          >
            <Select placeholder="Digite..." allowClear {...selectProps} />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="Nome da tarefa" name="by_name" className="w-full">
            <Input placeholder="Buscar" />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item
            label="Exibir"
            name="with_pending_attempts"
            className="w-full"
          >
            <Select placeholder="Selecione..." defaultValue={null}>
              <Select.Option value={null}>Todas as tarefas</Select.Option>
              <Select.Option value={'true'} key={'true'}>
                Aguardando correção
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
