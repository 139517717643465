import {
  CertificateList,
  CertificateShow
} from 'pages/certificates_management/certificates_regulated_courses';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'graduate/courses/certificates',
  label: 'Certificados cursos regulares',
  list: CertificateList,
  show: CertificateShow,
  actions: ['list', 'show'],
  parentName: 'certificate_management'
};

export default generateResources(resource);
