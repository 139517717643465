import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import FilterForm from 'components/forms/FilterForm';
import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import { IClassroomAvailableEnrollment } from '../../../interfaces/classrooms';
import { TextField, useModal, useTable } from '@refinedev/antd';
import { formatDate, replaceTimeZone } from 'services/date';
import { useCreate } from '@refinedev/core';

interface IFilter {
  name: string;
  email: string;
  startsAfter: dayjs.Dayjs;
  endsBefore: dayjs.Dayjs;
}

export const ClassroomStudentsAvailableForEnrollment = ({
  classroomId
}: {
  classroomId: string;
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedType, setSelectedType] = useState(
    'Graduate::Courses::Enrollment'
  );

  const resource = `classrooms/${classroomId}/available_enrollments`;

  const onChangeType = useCallback((e: RadioChangeEvent) => {
    const value = e.target.value;
    setSelectedType(value);
  }, []);

  const {
    tableProps,
    tableQueryResult: { refetch },
    searchFormProps
  } = useTable({
    resource,

    onSearch: ({ name, email, startsAfter, endsBefore }: IFilter) => {
      return [
        {
          field: 'course_enrollment_type',
          operator: 'eq',
          value: selectedType
        },
        {
          field: 'filter[search_user_name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_user_email]',
          operator: 'eq',
          value: email
        },
        {
          field: 'filter[created_after]',
          operator: 'eq',
          value: startsAfter && replaceTimeZone(startsAfter)
        },
        {
          field: 'filter[created_before]',
          operator: 'eq',
          value: endsBefore && replaceTimeZone(endsBefore)
        }
      ];
    },

    filters: {
      initial: [
        {
          field: 'course_enrollment_type',
          operator: 'eq',
          value: selectedType
        }
      ]
    }
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const { mutate: createEnrollments } = useCreate();

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  const hasSelected = selectedRowKeys.length > 0;
  const { show, modalProps, close } = useModal();

  const handleBatchEnrollment = useCallback(async () => {
    const enrollments = selectedRowKeys.map(item => {
      const type = tableProps.dataSource?.find(e => e.id === item)?.kind;

      return {
        enrollment_id: item,
        enrollment_type: type
      };
    });
    createEnrollments(
      {
        resource: `classrooms/${classroomId}/enrollments`,
        values: { enrollments },
        errorNotification: () => {
          return {
            message: 'Ocorreu um problema ao tentar matricular',
            description: 'Algo deu errado!',
            type: 'error'
          };
        },
        successNotification: () => {
          return {
            message: `${enrollments.length} alunos foram matriculados com sucesso`,
            description: 'Tudo certo!',
            type: 'success'
          };
        }
      },

      {
        onSuccess: () => {
          setSelectedRowKeys([]);
          refetch();
        }
      }
    );
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys]);

  const handleCreateEnrollment = useCallback(
    (enrollment_id: string, enrollment_type: string) => () => {
      const enrollments = [{ enrollment_id, enrollment_type: enrollment_type }];
      createEnrollments(
        {
          resource: `classrooms/${classroomId}/enrollments`,
          values: { enrollments },
          errorNotification: () => {
            return {
              message: 'Ocorreu um problema ao tentar matricular',
              description: 'Algo deu errado!',
              type: 'error'
            };
          },
          successNotification: () => {
            return {
              message: `Aluno matriculado com sucesso`,
              description: 'Tudo certo!',
              type: 'success'
            };
          }
        },
        { onSuccess: () => refetch() }
      );
    },
    [classroomId, createEnrollments, refetch]
  );

  return (
    <>
      <ConfirmationModal
        closable={false}
        okText="Confirmar"
        onOk={handleBatchEnrollment}
        bodyTitle="Confirmação de matrículas"
        bodyText="Ao confirmar essa ação, ela não poderá ser desfeita. Deseja continuar?"
        width={500}
        {...modalProps}
      />
      <FilterForm searchFormProps={searchFormProps}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Tipo de curso"
                name="course_enrollment_type"
                className="!mb-0"
              >
                <Radio.Group
                  onChange={onChangeType}
                  value={selectedType}
                  defaultValue="Graduate::Courses::Enrollment"
                >
                  <Radio value="Graduate::Courses::Enrollment">
                    Pós-graduação
                  </Radio>
                  <Radio value="Courses::Enrollment">Curso livre</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={6}>
              <Form.Item label="Nome do aluno" name="name">
                <Input placeholder="Informe o nome do aluno" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Form.Item label="Email do aluno" name="email">
                <Input placeholder="Informe o email do aluno" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Form.Item label="Início a partir de" name="startsAfter">
                <DatePicker
                  className="w-full"
                  placeholder="dia/mês/ano"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6}>
              <Form.Item label="Término em" name="endsBefore">
                <DatePicker
                  className="w-full"
                  placeholder="dia/mês/ano"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </FilterForm>

      <Space align="center" className="my-4 justify-between w-full">
        <Typography.Title level={5} style={{ margin: 0, color: 'GrayText' }}>
          Alunos disponíveis para matrícula
        </Typography.Title>
        <Button onClick={() => show()} disabled={!hasSelected}>
          Matricular alunos
        </Button>
      </Space>
      <Table
        rowSelection={rowSelection}
        {...tableProps}
        pagination={{
          ...tableProps.pagination,
          totalBoundaryShowSizeChanger: 9
        }}
        rowKey="id"
      >
        <Table.Column
          dataIndex={['user', 'name']}
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={['user', 'email']}
          key="email"
          title="Email"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="registration"
          key="registration"
          title="Matrícula"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={['course', 'name']}
          key="course"
          title="Curso"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="created_at"
          key="course"
          title="Data início"
          render={value => (
            <TextField value={formatDate(value, 'DD/MM/YYYY')} />
          )}
        />
        <Table.Column<IClassroomAvailableEnrollment>
          dataIndex="evaluation-actions"
          key="evaluation-actions"
          title="Ações"
          render={(_, record) => {
            return (
              <Button
                size="small"
                onClick={handleCreateEnrollment(record.id, selectedType)}
              >
                Matricular aluno
              </Button>
            );
          }}
        />
      </Table>
    </>
  );
};
