import React from 'react';
import { IAgent } from 'interfaces/agents';
import { Show } from '@refinedev/antd';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const AgentShow = () => {
  const { queryResult } = useShow<IAgent>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar usuário">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Nome</Title>
      <Text>{record?.name}</Text>
      <Title level={5}>Login</Title>
      <Text>{record?.login}</Text>
    </Show>
  );
};
