import { IGatewayRangeTable } from 'interfaces/gateways';
import { getAPI } from 'requests/api';

const api = getAPI();

interface GetProps {
  zipCode: string;
}

export const getRanges = async ({
  zipCode
}: GetProps): Promise<IGatewayRangeTable[]> => {
  const { data } = await api.get(
    `/admin/offers/gateways/range_tables?filter[value]=${zipCode}`
  );

  return data;
};
