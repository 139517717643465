import qs from 'qs';
import { IAssessmentQuestion } from 'interfaces/assessments';
import { getAPI } from 'requests/api';

export const listQuestionsByAssessments = async (
  assessmentIds: string[]
): Promise<IAssessmentQuestion[]> => {
  if (assessmentIds.length === 0) {
    return [];
  }

  const { data } = await getAPI().get<IAssessmentQuestion[]>(
    `/admin/assessment_questions?${qs.stringify(
      {
        items: 9999,
        page: 1,
        filter: {
          assessment: assessmentIds
        }
      },
      {
        arrayFormat: 'brackets'
      }
    )}`
  );

  return data;
};
