import InstructorInfo from 'components/professors/InstructorInfo';
import ProfessorInfo from 'components/professors/ProfessorInfo';
import { Button, Col, Form, Row, Select } from 'antd';
import { IClassroom } from 'interfaces/classrooms';
import { Store } from 'antd/lib/form/interface';
import { useCallback } from 'react';
import { useDelete, useList } from '@refinedev/core';
import { useForm, useSelect } from '@refinedev/antd';

export interface ClassroomProfessorProps {
  classroom: IClassroom;
}

const ClassroomProfessor = ({ classroom }: ClassroomProfessorProps) => {
  const resource = 'classrooms/professors';
  const { mutateAsync } = useDelete();
  const { data, refetch } = useList<IClassroom>({
    resource,

    filters: [
      {
        field: 'filter[by_classroom_id]',
        operator: 'eq',
        value: classroom.id
      }
    ]
  });
  const classroomData = data?.data[0];
  const { formProps, saveButtonProps, onFinish } = useForm({
    resource,
    redirect: false,
    action: 'create',
    onMutationError: () => null,
    errorNotification: () => {
      return {
        message: 'Essa turma ja possui um professor associado.',
        description: 'Erro',
        type: 'error'
      };
    }
  });
  const { selectProps } = useSelect({
    resource: 'professors',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 9999,

    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const handleOnFinish = (values: Store) => {
    onFinish({
      ...values,
      classroom_id: classroom.id,
      role: null
    });
    refetch();
  };

  const handleDelete = useCallback(() => {
    mutateAsync({
      resource,
      id: classroomData!.id
    });
  }, [classroomData, mutateAsync]);

  return (
    <>
      {!classroomData?.professor && (
        <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Definir professor" name="professor_id">
                <Select {...selectProps} placeholder="Selecione o professor" />
              </Form.Item>
            </Col>
            <Col span={12} className="self-center mt-[5px]">
              <Button {...saveButtonProps} type="primary">
                Associar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {classroomData?.professor && (
        <>
          <Button type="primary" onClick={handleDelete} className="mb-2">
            Desassociar
          </Button>
          <ProfessorInfo professorData={classroomData.professor} />
          <InstructorInfo professorData={classroomData.professor} />
        </>
      )}
    </>
  );
};

export default ClassroomProfessor;
