import EnrollmentTable from 'components/user/EnrollmentTable';
import GraduateCourseEnrollmentTable from 'components/user/GraduateCourseEnrollmentTable';
import { IUser } from 'interfaces/users';
import { Space, Typography } from 'antd';

interface UserEnrollmentsProps {
  userData: IUser;
}

export const UserEnrollments = ({ userData }: UserEnrollmentsProps) => {
  return (
    <Space direction="vertical" size="large" className="w-full">
      <div>
        <Typography.Title level={5}>Cursos do aluno</Typography.Title>
        <EnrollmentTable enrollments={userData.enrollments} />
      </div>

      <div>
        <Typography.Title level={5}>Curso regulado</Typography.Title>
        <GraduateCourseEnrollmentTable
          enrollments={userData.graduate_courses_enrollments}
        />
      </div>
    </Space>
  );
};
