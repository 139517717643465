import {
  AssignmentAttemptEdit,
  AssignmentAttemptList,
  AssignmentAttemptShow
} from 'pages/evaluations/assignment_attempts';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'assignment_attempts',
  label: 'Histórico de Atividades',
  list: AssignmentAttemptList,
  edit: AssignmentAttemptEdit,
  show: AssignmentAttemptShow,
  actions: ['list', 'edit', 'show'],
  parentName: 'evaluations'
};

export default generateResources(resource);
