import * as Icons from '@ant-design/icons';
import ClassroomNodeModal from './ClassroomNodeModal';
import ContentModal from 'components/modal/ContentModal';
import DeleteModal from 'components/modal/DeleteNodeModal';
import React, { useMemo } from 'react';
import { IClassroom } from 'interfaces/classrooms';
import { IDataContent, IDataNode } from 'interfaces/courses';
import { useModal } from '@refinedev/core';

interface NodeProps {
  node: IDataNode;
  updateNode: (node: IDataNode) => void;
  onRemove: (node: IDataNode) => void;
  updateNodeContent: (node: IDataContent) => void;
  classroom: IClassroom;
}

const Node: React.FC<NodeProps> = ({
  node,
  classroom,
  onRemove,
  updateNode,
  updateNodeContent
}) => {
  const nodeModal = useModal();
  const contentModal = useModal();
  const deleteModal = useModal();

  const nodeModalOpened = useMemo(() => nodeModal.visible, [nodeModal]);
  const contentModalOpened = useMemo(
    () => contentModal.visible,
    [contentModal]
  );

  // TODO refactor modals for each node
  return (
    <div className="flex gap-2 items-center">
      <span className={node.isLeaf ? 'font-normal' : 'font-medium'}>
        {node.title as string}
      </span>

      <Icons.EditOutlined
        onClick={() => {
          node.isLeaf ? contentModal.show() : nodeModal.show();
        }}
      />

      <Icons.DeleteOutlined
        onClick={() => {
          deleteModal.show();
        }}
      />

      {node.isLeaf && contentModalOpened && (
        <ContentModal
          modal={contentModal}
          node={node as IDataContent}
          onUpdate={updateNodeContent}
        />
      )}

      {!node.isLeaf && nodeModalOpened && (
        <ClassroomNodeModal
          modal={nodeModal}
          node={node}
          onUpdate={updateNode}
          classroom={classroom}
        />
      )}

      <DeleteModal modal={deleteModal} node={node} onUpdate={onRemove} />
    </div>
  );
};

export default Node;
