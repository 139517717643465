import { PermissionsGroup } from './permissions-group';
import { parsePermissions } from 'services/permissions';
import { useMemo } from 'react';
import { usePermissions } from 'hooks/usePermissions';

interface PermissionsProps {
  permissions: string[];
  disabled?: boolean;
  onChange?: (permissions: string[]) => void;
}

export const Permissions = ({
  permissions,
  disabled,
  onChange
}: PermissionsProps) => {
  const permissionsList = useMemo(() => parsePermissions(), []);

  const { selectedPermission, toggleAllPermissions, togglePermission } =
    usePermissions(permissions, permissionsList, disabled, onChange);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
        width: '100%'
      }}
    >
      {permissionsList.map(permission => (
        <PermissionsGroup
          key={permission.name}
          permission={permission}
          selectedPermission={selectedPermission}
          onToggleAll={toggleAllPermissions}
          onTogglePermission={togglePermission}
          disabled={disabled}
        />
      ))}
    </div>
  );
};
