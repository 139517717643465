import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { Button, Modal, Slider, Spin } from 'antd';
import { Cue, WebVTTParser, WebVTTSerializer } from 'webvtt-parser';
import { PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { Stream, StreamPlayerApi } from '@cloudflare/stream-react';
import { useModalReturnType, useNotification } from '@refinedev/core';

const SUCELLUS_API_KEY = '9b24ef76-bf59-4a8d-bb2d-f989f6669780';

interface Props {
  modal: useModalReturnType;
  videoId: string;
}

const parser = new WebVTTParser();
const serializer = new WebVTTSerializer();

export const VideoSubtitleEditorModal = ({ modal, videoId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [cues, setCues] = useState<Cue[]>([]);
  const streamRef = useRef<StreamPlayerApi>(null!);

  const { open: openNotification } = useNotification();

  const currentCue = useMemo(() => {
    return cues.find(
      cue => cue.startTime <= currentTime && cue.endTime >= currentTime
    );
  }, [currentTime, cues]);

  useEffect(() => {
    fetch(`https://sucellus.qconcursos.workers.dev/api/subtitles/${videoId}`, {
      headers: {
        'X-API-KEY': SUCELLUS_API_KEY
      }
    })
      .then(res => res.json())
      .then(data => {
        const tree = parser.parse(data.vtt, 'metadata');
        setCues(tree.cues);
      });
  }, [videoId]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    const serialized = serializer.serialize(cues);

    await fetch(
      `https://sucellus.qconcursos.workers.dev/api/subtitles/${videoId}`,
      {
        method: 'PUT',
        body: JSON.stringify({ vtt: serialized }),
        headers: {
          'X-API-KEY': SUCELLUS_API_KEY,
          'Content-Type': 'application/json'
        }
      }
    )
      .then(() => {
        openNotification!({
          type: 'success',
          message: 'Legendas salvas com sucesso'
        });
      })
      .catch(() => {
        openNotification!({
          type: 'error',
          message: 'Erro ao salvar legendas',
          description: 'Por favor, tente novamente mais tarde'
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [cues, openNotification, videoId]);

  return (
    <Modal
      width="auto"
      title="Editar Legendas"
      afterOpenChange={open => {
        if (!open) {
          streamRef.current.pause();
        }
      }}
      footer={
        <div className="flex items-center justify-end gap-2">
          <Button onClick={modal.close} disabled={isSaving}>
            Fechar
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            loading={isSaving}
            disabled={isSaving}
          >
            Salvar
          </Button>
        </div>
      }
      open={modal.visible}
      centered
      classNames={{
        content: '!w-screen !max-w-screen-lg'
      }}
      onCancel={modal.close}
    >
      <div className="w-full">
        <div className="flex items-center justify-center">
          {isLoading && <Spin className="m-auto" tip="Loading" size="large" />}
        </div>

        <div
          className={clsx(
            'max-w-[640px] mx-auto relative',
            isLoading && 'hidden'
          )}
        >
          <Stream
            streamRef={streamRef}
            controls={false}
            className={isLoading ? 'hidden' : ''}
            src={videoId}
            onTimeUpdate={() => {
              setCurrentTime(streamRef.current.currentTime);
            }}
            onPlay={() => {
              setIsPlaying(true);
            }}
            onPause={() => {
              setIsPlaying(false);
            }}
            onLoadStart={() => {
              setIsLoading(false);
            }}
          />

          {currentCue && (
            <div className="absolute text-lg font-medium bottom-0 left-0 w-full text-center bg-black/50 text-white p-2">
              {currentCue.text}
            </div>
          )}
        </div>

        <div className="flex items-center gap-4 mt-4">
          <Button
            size="large"
            shape="circle"
            icon={
              isPlaying ? (
                <PauseCircleFilled size={24} />
              ) : (
                <PlayCircleFilled size={24} />
              )
            }
            onClick={() => {
              if (streamRef.current.paused) {
                streamRef.current.play();
              } else {
                streamRef.current.pause();
              }
            }}
          />

          <Slider
            value={currentTime}
            min={0}
            max={streamRef.current?.duration}
            className="flex-1 !m-0"
            step={0.1}
            marks={Object.fromEntries(
              cues.map(cue => [cue.startTime, { label: ' ' }])
            )}
            onChange={value => {
              streamRef.current!.currentTime = value;
            }}
          />
        </div>

        <div className="w-full max-w-full mt-4">
          <div className="h-[300px] max-h-[300px] w-full border rounded-xl overflow-y-auto p-4">
            {cues.map((item, index) => (
              <div
                key={item.startTime}
                className="flex items-center gap-3 py-1"
              >
                <div className="flex items-center gap-1 min-w-[120px]">
                  <span className="text-xs text-gray-500">
                    {item.startTime}s
                  </span>
                  -
                  <span className="text-xs text-gray-500">{item.endTime}s</span>
                </div>
                <input
                  disabled={isSaving}
                  className={clsx(
                    'w-full',
                    item.startTime === currentCue?.startTime &&
                      item.endTime === currentCue?.endTime &&
                      'bg-yellow-500/10 font-bold'
                  )}
                  value={item.text}
                  onFocus={() => {
                    streamRef.current!.currentTime = item.startTime;
                  }}
                  onChange={e => {
                    setCues(prev => {
                      const text = (e.target as HTMLInputElement).value;
                      prev[index].text = text;
                      prev[index].tree.children[0].value = text;
                      return [...prev];
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};
