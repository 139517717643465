import { Col, Row, Space, Table } from 'antd';
import { HttpError } from '@refinedev/core';
import { IAssessmentAttempt } from 'interfaces/assessments';
import { IAssignmentAttempt } from '../../../interfaces/assignments';
import {
  List,
  ShowButton,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { tagColorByStatus } from '../../../utils/statusColorMapping';
import { translateAttemptStatus } from '../../../services/assignments';

export const AssessmentAttemptsList = () => {
  const { tableProps } = useTable<IAssessmentAttempt, HttpError>();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              title="Nome da avaliação"
              dataIndex={['assessment', 'name']}
              key="name"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              title="Tipo do curso"
              dataIndex={['assessment', 'parent_type']}
              key="parent_type"
              render={value => (
                <TextField
                  value={value === 'Course' ? 'Curso' : 'Disciplina'}
                />
              )}
            />

            <Table.Column
              title="Nome do curso"
              dataIndex={['assessment', 'course_name']}
              key="course_name"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              title="Iniciada em"
              dataIndex="created_at"
              key="created_at"
              render={value =>
                value ? (
                  <TextField value={formatDate(value, 'DD/MM/YYYY')} />
                ) : (
                  '--'
                )
              }
            />

            <Table.Column
              title="Finalizada em"
              dataIndex="completed_at"
              key="completed_at"
              render={value =>
                value ? (
                  <TextField value={formatDate(value, 'DD/MM/YYYY')} />
                ) : (
                  '--'
                )
              }
            />

            <Table.Column
              dataIndex="score"
              key="score"
              title="Nota"
              align="center"
              render={value => <TextField value={value ?? '--'} />}
            />

            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={value => (
                <TagField
                  color={tagColorByStatus(value)}
                  value={translateAttemptStatus(value)}
                />
              )}
            />

            <Table.Column<IAssignmentAttempt>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
