import FilterForm from 'components/forms/FilterForm';
import React from 'react';
import { Col, DatePicker, Form, FormProps, Input, Row, Select } from 'antd';
import { IFilter } from './IssuedCertificates';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { useSelect } from '@refinedev/antd';

export interface IssuedCertificatesFilterProps {
  searchFormProps: FormProps<IFilter>;
}

export const IssuedCertificatesFilter = ({
  searchFormProps
}: IssuedCertificatesFilterProps) => {
  const { selectProps: courseSelectProps } = useSelect<IGraduateCourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });
  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={[16, 16]} className="w-full flex items-end">
        <Col span={6}>
          <Form.Item label="Número de registro" name="by_registration_number">
            <Input placeholder="Número de registro" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Nome do aluno" name="by_user_name">
            <Input placeholder="Nome do aluno" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Número da matrícula"
            name="by_enrollment_registration"
          >
            <Input placeholder="Número da matrícula" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="CPF" name="by_cpf">
            <Input placeholder="Somente números" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="w-full flex items-end">
        <Col span={6}>
          <Form.Item label="Curso" name="by_course_id">
            <Select {...courseSelectProps} placeholder="Somente números" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Registro á partir de" name="registered_after">
            <DatePicker
              className="w-full"
              placeholder="dia/mês/ano"
              format="DD/MM/YYYY"
              picker="date"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Registro até" name="registered_before">
            <DatePicker
              className="w-full"
              placeholder="dia/mês/ano"
              format="DD/MM/YYYY"
              picker="date"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Expedição á partir de" name="issued_after">
            <DatePicker
              className="w-full"
              placeholder="dia/mês/ano"
              format="DD/MM/YYYY"
              picker="date"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Expedição até" name="issued_before">
            <DatePicker
              className="w-full"
              placeholder="dia/mês/ano"
              format="DD/MM/YYYY"
              picker="date"
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
