export const textValidator = (
  value: string,
  cb: (m?: string) => void,
  message: string,
  minLength = 0
) => {
  const text = value?.replace(/<[^>]*>/g, '').trim() as string;

  if (!value || text === '' || text.length < minLength) {
    cb(message);
  }

  cb();
};
