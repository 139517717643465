import { getAPI } from './api';

interface Props {
  enrollmentId: string;
}

interface CertificateProps {
  certificateId: string;
}

interface Download {
  url: string;
}

export const show = async ({ enrollmentId }: Props): Promise<Download> => {
  const { data } = await getAPI().get(
    `/admin/enrollments/${enrollmentId}/certificate`
  );

  return data;
};

export const getCourseCertificateUrl = async ({
  certificateId
}: CertificateProps): Promise<Download> => {
  const { data } = await getAPI().get(
    `/admin/course_certificates/${certificateId}/download`
  );

  return data;
};
