import {
  PaymentMethodRulesCreate,
  PaymentMethodRulesEdit,
  PaymentMethodRulesList
} from 'pages/deal/payment_method_rules';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'offers/payment_method_rules',
  label: 'Regras de Parcelamento',
  list: PaymentMethodRulesList,
  edit: PaymentMethodRulesEdit,
  create: PaymentMethodRulesCreate,
  actions: ['list', 'create', 'edit'],
  parentName: 'deal'
};

export default generateResources(resource);
