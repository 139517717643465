const STATUS_TRANSLATIONS = new Map<string, string>([
  ['active', 'Ativo'],
  ['disabled', 'Desabilitado'],
  ['expired', 'Expirado']
]);

export const STATUSES: string[] = [...STATUS_TRANSLATIONS.keys()];

export const translateStatus = (status: string): string =>
  STATUS_TRANSLATIONS.get(status) || status;
