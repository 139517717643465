import { ICertifiers } from 'interfaces/certifiers';
import { Show } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { Space } from 'antd';
import { useShow } from '@refinedev/core';

export const CertifierShow = () => {
  const { queryResult } = useShow<ICertifiers>();
  const certifier = queryResult?.data?.data;

  if (!certifier) {
    return null;
  }

  return (
    <Show title="Visualizar instituição certificadora">
      <Space direction="vertical" size="middle">
        <ShowFieldData label="Nome" value={certifier?.name} />
        <ShowFieldData label="Código" value={certifier?.code} />
      </Space>
    </Show>
  );
};
