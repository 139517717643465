import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { IRole } from 'interfaces/roles';
import { Space, Table } from 'antd';

export const RoleList = () => {
  const { tableProps } = useTable<IRole>();

  return (
    <List
      title="Perfis e regras de acesso"
      headerButtons={<CreateButton> Novo Perfil</CreateButton>}
    >
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{ hideOnSinglePage: true }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Perfil"
          width="100%"
          render={value => <TextField value={value} />}
        />
        <Table.Column<IRole>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
