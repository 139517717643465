import {
  InteractiveClassCreate,
  InteractiveClassesList
} from 'pages/academic/interactive_classes';
import { InteractiveClassEdit } from 'pages/academic/interactive_classes/edit';
import { InteractiveClassShow } from 'pages/academic/interactive_classes/show';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'interactive_classes',
  label: 'Aulas interativas',
  list: InteractiveClassesList,
  create: InteractiveClassCreate,
  show: InteractiveClassShow,
  edit: InteractiveClassEdit,
  actions: ['list', 'create'],
  parentName: 'teaching_resources'
};

export default generateResources(resource);
