import { Modal, ModalProps, Typography } from 'antd';
import { useCreate } from '@refinedev/core';

export interface CloseThreadModalProps {
  modalClose: () => void;
  modalProps: ModalProps;
  refetch: () => void;
  forumId: string | undefined;
}

export const CloseThreadModal = ({
  modalProps,
  forumId,
  modalClose,
  refetch
}: CloseThreadModalProps) => {
  const { mutateAsync: closeTopic } = useCreate();

  const handleCreate = async () => {
    const resource = `faq_coordinators/${forumId}/close`;

    return closeTopic({
      resource,
      values: {},
      successNotification: () => ({
        type: 'success',
        description: 'Tudo certo!',
        message: 'Tópico finalizado com sucesso'
      }),
      errorNotification: () => {
        return {
          description: 'Algo deu errado',
          message:
            'Não foi possível finalizar o tópico, verifique se existe uma resposta de professor!',
          type: 'error'
        };
      }
    }).then(() => {
      refetch();
      modalClose();
    });
  };

  return (
    <Modal
      style={{ maxWidth: 600 }}
      {...modalProps}
      title="Deseja marcar como concluído"
      cancelText="Voltar"
      okText="Marcar como concluído"
      onOk={handleCreate}
      className="!w-[1022px]"
      destroyOnClose
    >
      <Typography.Paragraph>
        Essa ação não pode ser revertida
      </Typography.Paragraph>
    </Modal>
  );
};
