import { Edit, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { ICertifiers } from 'interfaces/certifiers';

export const CertifierEdit = () => {
  const { formProps, saveButtonProps } = useForm<ICertifiers>({
    successNotification: () => ({
      type: 'success',
      description: 'Sucesso!',
      message: 'Instituição certificadora criada com sucesso!'
    })
  });
  return (
    <Edit
      saveButtonProps={saveButtonProps}
      title="Editar instituição certificadora"
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome da instituição"
          name="name"
          rules={[
            {
              required: true,
              message: 'Nome obrigatório'
            }
          ]}
        >
          <Input style={{ maxWidth: 600 }} />
        </Form.Item>
        <Form.Item
          label="Código"
          name="code"
          rules={[
            {
              required: true,
              message: 'Código obrigatório'
            }
          ]}
        >
          <Input style={{ maxWidth: 200 }} />
        </Form.Item>

        <Form.Item
          label="Credenciamento"
          name="accreditation"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input.TextArea rows={4} style={{ maxWidth: 600 }} />
        </Form.Item>

        <Form.Item label="Recredenciamento" name="reaccreditation">
          <Input.TextArea rows={4} style={{ maxWidth: 600 }} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
