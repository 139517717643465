import { IVideo } from 'interfaces/libraries';
import { getAPI } from 'requests/api';

export const translateKind = (kind: string | undefined) => {
  switch (kind) {
    case 'document': {
      return 'Documento';
    }

    case 'video': {
      return 'Vídeo';
    }

    case 'audio': {
      return 'Áudio';
    }

    case 'hyper_doc': {
      return 'Hyper Doc';
    }

    case 'html_doc': {
      return 'HTML';
    }

    default: {
      return kind;
    }
  }
};

export const getVideo = async (contentId: string): Promise<IVideo> => {
  const { data } = await getAPI().get(
    `/admin/prometheus/v2/videos/${contentId}`
  );

  return data;
};

export const getAudio = async (contentId: string) => {
  const { data } = await getAPI().get(
    `/admin/prometheus/v2/audios/${contentId}`
  );

  return data;
};

export const getDocument = async (contentId: string) => {
  const { data } = await getAPI().get(
    `/admin/prometheus/v2/documents/${contentId}`
  );

  return data;
};

export const getHtmlDoc = async (contentId: string) => {
  const { data } = await getAPI().get(
    `/admin/prometheus/v2/html_docs/${contentId}`
  );

  return data;
};
