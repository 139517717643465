import { Button, Input } from 'antd';
import { Stack } from '@qcx/ui';
import { useApiUrl, useCustomMutation, useDelete } from '@refinedev/core';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export interface RuleTypeI {
  operator?: string;
  products: { id: string; name?: string }[];
  invoice_due?: number;
  discount_type: string;
  discount_value: number;
}

export interface DiscountCampaign {
  id: string;
  name: string;
  kind: string;
  status: string;
  discount_config: {
    behavior: string;
    type: string;
    value: string[] | string;
    start_at?: string;
    end_at?: string;
  };
  rules: [
    {
      operator: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any;
      resource_type: string;
      resource_key: string;
      required: string;
    }
  ];
}

export const ResumeManyDiscountForm = ({
  timeRule,
  productRule,
  rulesToCancel,
  operator,
  onCreate,
  showCancel
}: {
  timeRule: RuleTypeI;
  productRule: RuleTypeI;
  rulesToCancel: string[];
  operator: string;
  showCancel?: boolean;
  onCreate?: (resource: DiscountCampaign) => void;
}) => {
  const [discountName, setDiscountName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [produtData, setProductData] = useState<DiscountCampaign>({});
  const [timeData, setTimeData] = useState<DiscountCampaign>({});

  const { mutate } = useCustomMutation();
  const { mutate: mutateDelete } = useDelete();
  const apiUrl = useApiUrl();
  const navigate = useNavigate();

  const deleteOldDiscounts = () => {
    rulesToCancel.map(rule =>
      mutateDelete({
        resource: 'discount_campaigns',
        id: rule
      })
    );
  };

  const createDefaultDiscount = (rule: RuleTypeI, callback?: () => void) => {
    if (!rule?.['discount_type'] && callback) {
      callback()
      return;
    } 

    if(!rule?.['discount_type']) {
      return;
    }

    return mutate(
      {
        url: `${apiUrl}/discount_campaigns`,
        method: 'post',
        values: {
          name: discountName,
          kind: 'payment_plan_deal',
          discount_config: {
            type: rule.discount_type,
            value: rule.discount_value,
            behavior: 'cumulative'
          },
          rules: [
            {
              operator: operator || '!=',
              value: rule.products?.map(({ id }) => id),
              resource_type: 'product_id'
            }
          ]
        }
      },
      {
        onError: () => {
          setIsLoading(false);
        },
        onSuccess: data => {
          setIsLoading(false);
          setProductData(data as unknown as DiscountCampaign);

          if (onCreate && produtData && !callback) {
            return onCreate(data as unknown as DiscountCampaign);
          }

          if (callback) {
            callback();
          }
        }
      }
    );
  };

  const createTimeDiscount = (rule: RuleTypeI) => {
    return mutate(
      {
        url: `${apiUrl}/discount_campaigns`,
        method: 'post',
        values: {
          name: discountName,
          kind: 'payment_plan_deal',
          discount_config: {
            type: rule.discount_type,
            value: rule.discount_value,
            behavior: 'cumulative'
          },
          rules: [
            {
              operator: operator || '!=',
              value: rule.products?.map(({ id }) => id),
              resource_type: 'product_id'
            },
            {
              operator: '>',
              value: [rule.invoice_due],
              resource_type: 'invoice_due'
            }
          ]
        }
      },
      {
        onError: () => {
          setIsLoading(false);
        },
        onSuccess: data => {
          setIsLoading(false);
          setTimeData(data as unknown as DiscountCampaign);

          if (onCreate && produtData) {
            return onCreate(data as unknown as DiscountCampaign);
          }
        }
      }
    );
  };

  const onSubmit = async () => {
    setIsLoading(true);
    deleteOldDiscounts();

    if (Boolean(timeRule?.invoice_due)) {
      createDefaultDiscount(productRule, () => createTimeDiscount(timeRule));
    } else {
      createDefaultDiscount(productRule);
    }
  };

  return (
    <Stack
      css={{
        w: '$full',
        maxW: 400,
        h: '$full',
        minH: 200,
        maxH: 280,
        gap: '$6',
        flexWrap: 'wrap',
        '@lg': { flexWrap: 'nowrap' }
      }}
    >
      <Stack
        flow="column"
        css={{
          rounded: '$lg',
          bgColor: '$neutral100',
          p: '$4',
          gap: '$1',
          w: '$full',
          flexDir: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <div className="border-b pb-3 flex items-center justify-between">
            <p className="m-0 font-semibold text-base leading-6 text-[rgba(0,0,0,0.85)]">
              Resumo
            </p>
            <Input
              placeholder="Digite o nome da regra"
              className="max-w-[180px]"
              onChange={value => setDiscountName(value.target.value)}
              required
            />
          </div>
          <div className="mt-3">
            <div className="flex justify-between items-center">
              <p className="font-medium text-sm leading-5 text-[#667085]">
                Produto
              </p>
              <p className="font-normal text-sm leading-5 text-right text-[#1D2939]">
                {productRule?.discount_value &&
                  `${productRule?.discount_value}%`}
              </p>
            </div>
          </div>
          <div className="mt-3">
            <div className="flex justify-between items-center">
              <p className="font-medium text-sm leading-5 text-[#667085]">
                Tempo de inadimplência
              </p>
              <p className="font-normal text-sm leading-5 text-right text-[#1D2939]">
                {timeRule?.discount_value && `${timeRule?.discount_value}%`}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-evenly justify-self-end gap-3">
          <Button
            disabled={isLoading || (!productRule?.['discount_type'] && !timeRule?.['invoice_due'])}
            type="primary"
            className="w-full"
            onClick={() => onSubmit()}
          >
            Salvar regra
          </Button>
          <Button
            disabled={isLoading}
            type="default"
            className={`w-full`}
            style={{ display: `${showCancel ? 'inline' : 'none'}` }}
            onClick={() => navigate('/sales/discount_campaigns')}
          >
            Cancelar regra
          </Button>
        </div>
      </Stack>
    </Stack>
  );
};
