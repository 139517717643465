import {
  AcademicRecordEquivalenciesFilter,
  IAcademicRecordEquivalenciesFilterForm
} from './components/list-filter';
import { Col, Row, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IAcademicRecordEquivalency } from 'interfaces/academic_record_equivalencies';
import {
  List,
  ShowButton,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { tagColorByStatus } from '../../../utils/statusColorMapping';
import { translateEquivalencyStatus } from './index';

export const AcademicRecordEquivalencyList = () => {
  const { tableProps, searchFormProps } = useTable<
    IAcademicRecordEquivalency,
    HttpError,
    IAcademicRecordEquivalenciesFilterForm
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { disciplineId, by_status, search_user_name, by_user_email } =
        params;

      filters.push(
        {
          field: 'filter[by_discipline_id]',
          operator: 'eq',
          value: disciplineId
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: by_status
        },
        {
          field: 'filter[search_user_name]',
          operator: 'eq',
          value: search_user_name
        },
        {
          field: 'filter[by_user_email]',
          operator: 'eq',
          value: by_user_email
        }
      );
      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List title="Equivalências Internas">
          <AcademicRecordEquivalenciesFilter
            searchFormProps={searchFormProps}
          />

          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex={'user'}
              key="userInfo"
              title="Aluno"
              render={value => (
                <>
                  <TextField value={value.name} />
                  <div style={{ fontSize: 'smaller', color: 'gray' }}>
                    <TextField value={value.email} />
                  </div>
                </>
              )}
            />
            <Table.Column
              dataIndex="discipline"
              key="discipline_name"
              title="Disciplina pretendida"
              render={value => <TextField value={value.name} />}
            />
            <Table.Column
              dataIndex={'agent'}
              key="agent_name"
              title="Responsável"
              render={value => <TextField value={value.name} />}
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title="Situação no histórico"
              render={status => (
                <TagField
                  value={translateEquivalencyStatus(status || '')}
                  color={tagColorByStatus(status || '')}
                />
              )}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Data da equivalência"
              render={value => (
                <TextField value={formatDate(value, 'DD/MM/YYYY - hh:mm')} />
              )}
            />
            <Table.Column<IAcademicRecordEquivalency>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
