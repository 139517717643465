import FilterForm from 'components/forms/FilterForm';
import React from 'react';
import { Col, Form, FormProps, Input, Row, Select } from 'antd';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { useSelect } from '@refinedev/antd';

export interface IAcademicRecordEquivalenciesFilterForm {
  disciplineId: string;
  by_status: string;
  search_user_name: string;
  by_user_email: string;
  by_agent: string;
}

interface AcademicRecordEquivalenciesFilterProps {
  searchFormProps: FormProps<IAcademicRecordEquivalenciesFilterForm>;
}

export const AcademicRecordEquivalenciesFilter = ({
  searchFormProps
}: AcademicRecordEquivalenciesFilterProps) => {
  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server',
        mode: 'server'
      }
    }
  );

  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={16} className="w-full">
        <Col xs={24} md={8}>
          <Form.Item label="Nome" name="search_user_name">
            <Input placeholder="Nome do aluno" />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="E-mail" name="by_user_email">
            <Input placeholder="e-mail do aluno" />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="Situação" name="by_status">
            <Select placeholder="Selecione" allowClear>
              <Select.Option>Todos</Select.Option>
              <Select.Option key={'approved'}>Aprovado</Select.Option>
              <Select.Option key={'exempted'}>Isento</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="w-full">
        <Col xs={24} md={16}>
          <Form.Item
            colon
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label="Disciplina"
            name="disciplineId"
          >
            <Select
              allowClear
              {...disciplineSelectProps}
              style={{ minWidth: 250 }}
              placeholder="Selecione ou busque a disciplina"
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
