import { SignaturePartnersCreate } from 'pages/certificates_management/signature_partners/create';
import { SignaturePartnersEdit } from 'pages/certificates_management/signature_partners/edit';
import { SignaturePartnersList } from 'pages/certificates_management/signature_partners/list';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'certifiers/signature_partners',
  label: 'Modelos de assinaturas',
  list: SignaturePartnersList,
  create: SignaturePartnersCreate,
  edit: SignaturePartnersEdit,
  actions: ['list', 'edit', 'create'],
  parentName: 'certificate_management'
};

export default generateResources(resource);
