import { ReportShow, ReportsList } from 'pages/admin/reports';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'comments/resource_reports',
  label: 'Denúncias de comentários',
  list: ReportsList,
  show: ReportShow,
  actions: ['list', 'show'],
  parentName: 'communication'
};

export default generateResources(resource);
