import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'document_templates',
  label: 'Templates de Documentos',
  actions: ['list', 'show'],
  parentName: 'secretary'
};

export default generateResources(resource);
