import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Tooltip } from 'antd';
import { IAssessment, IAssessmentQuestion } from 'interfaces/assessments';
import { ICourseShow, IDataContent } from 'interfaces/courses';
import { generateUUID } from 'services/uuid';
import { listQuestionsByAssessments } from '../../../requests/questions';
import { useModalReturnType } from '@refinedev/core';

interface CreateContentModalProps {
  course: ICourseShow;
  node?: IDataContent;
  modal: useModalReturnType;
  onCreate: (node: IDataContent) => void;
  onClose: () => void;
}

export const ContentAssessmentModal: React.FC<CreateContentModalProps> = ({
  course,
  node,
  modal,
  onClose,
  onCreate
}) => {
  const [form] = Form.useForm();

  const onCloseModal = () => {
    form.resetFields();
    modal.close();
  };

  const onSubmit = async () => {
    const { title, core_reference } = await form.validateFields();

    const core_id = core_reference;
    const key = node ? node.key : generateUUID();

    onCreate({
      name: title,
      title,
      core_id,
      core_kind: 'assessment',
      core_reference,
      core_provider: '',
      key,
      isLeaf: true
    });

    onClose();
  };

  const [questions, setQuestions] = useState<IAssessmentQuestion[]>();
  const assessmentIds = course.assessments.map(assessment => assessment.id);

  useEffect(() => {
    const fetchData = async () => {
      const response = await listQuestionsByAssessments(assessmentIds);
      setQuestions(response);
    };

    if (modal.visible) {
      fetchData().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasQuestionsByAssessment = (
    questions: IAssessmentQuestion[] | undefined,
    assessment: IAssessment
  ) => {
    if (questions && questions?.length > 0) {
      const questionsByAssessment = questions?.filter(
        question => question.assessment_id === assessment.id
      );
      return questionsByAssessment.length > 0;
    } else {
      return false;
    }
  };

  return (
    <Modal
      title={node ? 'Editar simulado' : 'Adicionar simulado'}
      visible={modal.visible}
      onCancel={onCloseModal}
      footer={[
        <Button key="back" onClick={onCloseModal}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit}>
          Salvar
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        preserve={false}
        initialValues={{
          core_id: node?.core_id,
          title: node?.title,
          core_reference: node?.core_reference
        }}
      >
        <Form.Item
          name={['title']}
          label="Nome"
          rules={[{ required: true }]}
          key="title"
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="w-full"
          name={['core_reference']}
          label="Simulado"
          key="core_reference"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select defaultValue={node?.core_id}>
            {course.assessments.map(assessment => (
              <Select.Option
                key={assessment.id}
                disabled={!hasQuestionsByAssessment(questions, assessment)}
              >
                <Tooltip
                  key={assessment.id}
                  title={
                    !hasQuestionsByAssessment(questions, assessment) &&
                    'É necessário ter questões vinculadas a esse simulado para poder adicioná-lo ao conteúdo'
                  }
                >
                  {assessment.name}
                  {!hasQuestionsByAssessment(questions, assessment) &&
                    ' - sem questões'}
                </Tooltip>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
