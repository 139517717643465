import { TeamOutlined } from '@ant-design/icons';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'students_teachers',
  label: 'Alunos e docentes',
  icon: <TeamOutlined />
};

export default generateResources(resource);
