import { Modal, Typography } from 'antd';
import { useCreate } from '@refinedev/core';
import { useDocumentationStore } from '../documentation-store';
import { useParams } from 'react-router-dom';

export const ModalApproveDocument = () => {
  const { id } = useParams();
  const { mutateAsync: approveTemplate } = useCreate();
  const modal = useDocumentationStore(s => s.modal);
  const editDocument = useDocumentationStore(s => s.editDocument);
  const closeModal = useDocumentationStore(s => s.closeModal);
  const queryHandler = useDocumentationStore(s => s.queryHandler);

  const handleOk = () => {
    if (!editDocument) {
      return null;
    }
    approveTemplate({
      resource: `documentations/${id}/documents/${editDocument.id}/approve`,
      values: { attribute_fields: editDocument.attribute_fields },
      errorNotification: {
        type: 'error',
        message: 'Ocorreu algum erro interno no serviço',
        description: 'Erro ao aprovar documento!'
      },
      successNotification: {
        type: 'success',
        description: 'Documento aprovado',
        message: `O documento foi aprovado com sucesso`
      }
    })
      .then(() => {
        queryHandler();
        closeModal();
      })
      .catch(() => null);
  };

  return (
    <Modal
      visible={modal === 'approve'}
      title="Confirmação de aprovação"
      width={400}
      okText="Sim"
      cancelText="Não"
      onCancel={() => closeModal()}
      onOk={() => handleOk()}
    >
      <Typography.Text>
        Ao confirmar essa ação, ela não poderá ser desfeita. Deseja continuar?
      </Typography.Text>
    </Modal>
  );
};
