import Audit from './components/Audit';
import DocumentTypes from './components/DocumentTypes';
import { Col, Row, Tabs } from 'antd';
import { List } from '@refinedev/antd';
import { useEffect, useState } from 'react';

export const DocumentationList = () => {
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get('tab');
  const [activeTab, setActiveTab] = useState(tabParam || 'audit');

  useEffect(() => {
    const tabFromURL = searchParams.get('tab') || 'audit';
    setActiveTab(tabFromURL);
  }, [location]);

  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
    searchParams.set('tab', activeKey);
    const newURL = `${location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newURL }, '', newURL);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Tabs defaultActiveKey={activeTab} onChange={handleTabChange}>
            <Tabs.TabPane tab="Auditorias" key="audit">
              <Audit />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Tipo de documentos" key="documentTypes">
              <DocumentTypes />
            </Tabs.TabPane>
          </Tabs>
        </List>
      </Col>
    </Row>
  );
};
