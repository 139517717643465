import React from 'react';
import { DeleteButton, ShowButton, TextField } from '@refinedev/antd';
import { IClassroom } from 'interfaces/classrooms';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { INoticeBoard } from 'interfaces/notice_board';
import { Space, Table, TableProps, Tag } from 'antd';
import { formatDate } from 'services/date';
import { translateKind, translatePriority } from '../list';

interface ListTableProps {
  tableProps: TableProps<INoticeBoard>;
}

export const ListTable = ({ tableProps }: ListTableProps) => {
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="title"
        key="title"
        title="Título"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        key="target"
        title="Destino"
        className="max-w-[500px]"
        render={value => (
          <Space direction="vertical" size="small" className="w-full">
            <Tag color="#f0f0f0" style={{ color: 'black' }}>
              {translateKind(value.kind)}
            </Tag>
            {value.kind === 'classroom' && (
              <TextField
                ellipsis={true}
                value={value.classrooms
                  .map(
                    (classroom: IClassroom) =>
                      `${classroom.discipline.name} - ${classroom.code}`
                  )
                  .join(', ')
                  .slice(0, 100)}
              />
            )}
            {value.kind === 'graduate_course' && (
              <TextField
                ellipsis={true}
                value={value.graduate_courses
                  .map((course: IGraduateCourse) => `${course.name}`)
                  .join(', ')
                  .slice(0, 100)}
              />
            )}
          </Space>
        )}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Data de criação"
        render={value => <TextField value={formatDate(value, 'LL')} />}
      />
      <Table.Column
        dataIndex="starts_at"
        key="starts_at"
        title="Data de publicação"
        render={value => <TextField value={formatDate(value)} />}
      />
      <Table.Column
        dataIndex="priority"
        key="priority"
        title="Prioridade"
        render={value => (
          <>
            {value === 'regular' && (
              <Tag color="#f0f0f0" style={{ color: 'black' }}>
                {translatePriority(value)}
              </Tag>
            )}
            {value === 'high' && (
              <Tag color="red">{translatePriority(value)}</Tag>
            )}
          </>
        )}
      />
      <Table.Column<INoticeBoard>
        title="Ações"
        dataIndex="actions"
        render={(_, record) => {
          return (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />

              <DeleteButton
                size="small"
                recordItemId={record.id}
                hideText
                successNotification={{
                  type: 'success',
                  description: 'Sucesso!',
                  message: 'Aviso excluído com sucesso'
                }}
              />
            </Space>
          );
        }}
      />
    </Table>
  );
};
