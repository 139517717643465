import { ISignatures } from 'interfaces/signatures';
import { Show } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { Space, Typography } from 'antd';
import { useShow } from '@refinedev/core';

export const SignaturesShow = () => {
  const { queryResult } = useShow<ISignatures>();
  const signature = queryResult?.data?.data;

  if (!signature) {
    return null;
  }

  return (
    <Show title="Visualizar assinatura">
      <Space direction="vertical" size="middle">
        <ShowFieldData
          label="Instituição certificadora"
          value={signature?.certifier?.name}
        />
      </Space>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Assinatura
      </Typography.Title>

      <Space
        direction="vertical"
        className="w-full border-solid border-[1px] rounded-md p-2"
      >
        <Typography.Title level={5} style={{ margin: '0 0 24px' }}>
          Reitor
        </Typography.Title>
        <Space
          direction="horizontal"
          className="flex w-full justify-between !items-start"
        >
          <ShowFieldData label="Nome para impressão" value={signature?.name} />
          <ShowFieldData label="Matrícula" value={signature?.registration} />
          <ShowFieldData label="ATO/Portaria" value={signature?.decree} />
          <Space direction="vertical" className="w-full whitespace-pre-line">
            <Typography.Text className="font-medium">
              Assinatura
            </Typography.Text>
            <img src={signature?.signature} className="w-64" alt="asset" />
          </Space>
        </Space>
      </Space>
    </Show>
  );
};
