import ReactQuill from 'react-quill';
import { ASSESSMENT_KIND_OPTIONS } from 'services/assessments';
import { Alternatives } from './Alternatives';
import { Form, Select } from 'antd';
import { IAssessment, IAssessmentQuestion } from 'interfaces/assessments';
import { UseFormReturnType, useSelect } from '@refinedev/antd';
import { textValidator } from 'utils/validators';
import { useMemo, useState } from 'react';

interface FormQuestionsProps {
  formInstance: UseFormReturnType<IAssessmentQuestion>;
}

export const FormQuestions = ({ formInstance }: FormQuestionsProps) => {
  const { formProps, queryResult } = formInstance;
  const question = queryResult?.data?.data;

  const nullifiedQuestion = Boolean(question?.status === 'nullified');

  const assessmentName = question?.assessment_name;
  const filterValue = assessmentName?.slice(assessmentName?.indexOf('|') + 2);

  const [questionKind, setQuestionKind] = useState(
    question?.kind || 'objective'
  );

  const editForm = useMemo(() => Boolean(question?.id), [question]);

  const initialValues = useMemo(() => {
    return (
      question ?? {
        kind: 'objective'
      }
    );
  }, [question]);

  const { selectProps: assessmentsQueryProps } = useSelect<IAssessment>({
    resource: 'assessments',
    optionLabel: 'name',
    optionValue: 'id',

    filters: [
      {
        field: 'filter[full_name]',
        operator: 'eq',
        value: filterValue
      },
      {
        field: 'filter[by_kind]',
        operator: 'eq',
        value: questionKind
      }
    ],

    onSearch: value => [
      {
        field: 'filter[full_name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Form
      {...formProps}
      initialValues={initialValues}
      layout="vertical"
      disabled={question?.status === 'nullified'}
    >
      <Form.Item
        label="Tipo"
        name="kind"
        initialValue="objective"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Select options={ASSESSMENT_KIND_OPTIONS} onChange={setQuestionKind} />
      </Form.Item>

      <Form.Item
        name="assessment_id"
        label="Avaliação associada"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Select {...assessmentsQueryProps} disabled={editForm} />
      </Form.Item>

      <Form.Item
        name={['statement']}
        label="Enunciado da questão"
        required
        rules={[
          {
            validator: (_, value, cb) =>
              textValidator(
                value,
                cb,
                'Deve conter no mínimo 10 caracteres',
                10
              )
          }
        ]}
      >
        <ReactQuill
          theme="snow"
          readOnly={nullifiedQuestion}
          style={{
            color: nullifiedQuestion ? 'gray' : 'unset'
          }}
        />
      </Form.Item>

      <Form.Item name={['feedback']} label="Gabarito">
        <ReactQuill
          theme="snow"
          readOnly={nullifiedQuestion}
          style={{
            color: nullifiedQuestion ? 'gray' : 'unset'
          }}
        />
      </Form.Item>

      {questionKind === 'objective' && <Alternatives question={question} />}
    </Form>
  );
};
