import { ActionLogList, ActionLogShow } from 'pages/ecommerce/action_logs';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'action_logs',
  label: 'Log de ações',
  list: ActionLogList,
  show: ActionLogShow,
  actions: ['list', 'show'],
  parentName: 'configurations'
};

export default generateResources(resource);
