import { Form, Input, Modal, ModalProps, Row, Typography } from 'antd';
import { IAgent } from 'interfaces/agents';
import { IClassroom } from 'interfaces/classrooms';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { useCreate, useGetIdentity, useNotification } from '@refinedev/core';
import { useForm } from '@refinedev/antd';

const { Paragraph, Text } = Typography;

export interface CreateTopicModalProps {
  modalClose: () => void;
  modalProps: ModalProps;
  kind: 'Classroom' | 'Graduate::Course';
  parent: IClassroom | IGraduateCourse | undefined;
}

export const CreateTopicModal = ({
  modalProps,
  modalClose,
  parent,
  kind
}: CreateTopicModalProps) => {
  const { data: user } = useGetIdentity<IAgent>();
  const { mutateAsync: createTopic } = useCreate();
  const { open } = useNotification();
  const { form, formProps } = useForm();

  const getDescription = () => {
    if (kind === 'Classroom') {
      const typedParent = parent as IClassroom;
      return `Tópico na turma ${typedParent?.code} > ${typedParent?.discipline?.name}`;
    }
    return null;
  };

  const handleCreate = async () => {
    let resource = `graduate/courses/${parent?.id}/forums`;

    if (kind === 'Classroom') {
      resource = `classrooms/${parent?.id}/forums`;
    }

    return form
      .validateFields()
      .then(async () => {
        return createTopic({
          resource,
          values: {
            ...form.getFieldsValue()
          },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Tópico criado com sucesso'
          }),
          errorNotification: () => {
            return {
              description: 'Algo deu errado',
              message: 'Não foi possível criar o tópico',
              type: 'error'
            };
          }
        }).then(() => {
          modalClose();
          form.resetFields();
        });
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: 'Favor conferir todos os campos obrigatórios.',
          description: 'Error ao criar registro!'
        });
      });
  };

  return (
    <Modal
      style={{ maxWidth: 600 }}
      {...modalProps}
      title="Novo tópico"
      cancelText="Cancelar"
      okText="Criar tópico"
      onOk={handleCreate}
      className="!w-[1022px]"
      destroyOnClose
    >
      <Row style={{ marginTop: '24px' }}>
        <Paragraph style={{ margin: 0 }}>Autor: &nbsp; </Paragraph>
        <Text strong>{user?.name}</Text>
      </Row>
      <Row>
        <Paragraph>{getDescription()}</Paragraph>
      </Row>
      <Form
        form={form}
        {...formProps}
        layout="vertical"
        style={{ marginTop: 16 }}
      >
        <Row>
          <Form.Item
            style={{ width: '100%' }}
            label="Título do tópico"
            name="title"
            rules={[
              {
                required: true,
                message: 'Campo título em branco'
              }
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            style={{ width: '100%' }}
            label="Texto"
            name={['post_attributes', 'text']}
            rules={[
              {
                required: true,
                message: 'Campo texto em branco'
              }
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
