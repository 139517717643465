import React, { useState } from 'react';
import { Alert, Button, Input, Modal, Typography } from 'antd';

interface RejectModalProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (feedback: string) => void;
}

export const RejectModal = ({
  visible,
  onCancel,
  onSubmit
}: RejectModalProps) => {
  const [feedback, setFeedback] = useState('');

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(e.target.value);
  };

  return (
    <Modal
      title={<>Rejeitar Aproveitamento</>}
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="submit"
          danger
          onClick={() => onSubmit(feedback)}
          disabled={!feedback}
        >
          Rejeitar
        </Button>
      ]}
    >
      <Alert
        description={
          <ul style={{ fontSize: 'small' }}>
            <li>
              Adicione um comentário para explicar o motivo da rejeição e
              forneça um retorno acadêmico para o aluno.
            </li>
          </ul>
        }
        type="info"
        style={{ marginBottom: 16, paddingBottom: 4 }}
      />

      <span className="block font-semibold mb-1">
        Motivo da rejeição: <Typography.Text type="danger">*</Typography.Text>
      </span>
      <Input.TextArea
        placeholder="Escreva aqui o motivo da rejeição"
        value={feedback}
        required
        onChange={handleFeedbackChange}
      />
    </Modal>
  );
};
