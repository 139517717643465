export const downloadFileFromBlob = (blobData: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blobData);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.append(link);

  link.click();

  window.URL.revokeObjectURL(url);
  link.remove();
};
