import {
  TestimonialCreate,
  TestimonialEdit,
  TestimonialList,
  TestimonialShow
} from 'pages/admin/testimonials';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'testimonials',
  label: 'Depoimentos',
  list: TestimonialList,
  create: TestimonialCreate,
  edit: TestimonialEdit,
  show: TestimonialShow,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'ecommerce'
};

export default generateResources(resource);
