import React, { useEffect, useState } from 'react';
import { IDocumentLog } from 'interfaces/documentation/documentations';
import { Modal, Table } from 'antd';
import { TagField, TextField } from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { getDocumentLogs } from 'requests/documents/logs';
import {
  translateDocumentationStatus,
  translateEventStatus
} from '../../../services/documentations';
import { useModalReturnType } from '@refinedev/core';

interface Props {
  documentationId: string | undefined;
  documentId: string | null;
  modal: useModalReturnType;
}

const LogsModal: React.FC<Props> = ({ documentationId, documentId, modal }) => {
  const [logs, setLogs] = useState<IDocumentLog[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await getDocumentLogs({ documentationId, documentId });
        setLogs(response);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    if (modal.visible) {
      fetchData().then();
    }
  }, [modal.visible, documentationId, documentId]);

  return (
    <Modal
      title="Histórico de Alterações"
      width={1200}
      visible={modal.visible}
      onCancel={modal.close}
      footer={null}
    >
      <Table
        size="small"
        dataSource={logs}
        columns={[
          {
            title: 'Alterado em',
            dataIndex: 'created_at',
            key: 'created_at',
            render: createdAt => (
              <TextField value={formatDate(createdAt, 'lll')} />
            )
          },
          {
            title: 'Alterado por',
            dataIndex: 'author',
            key: 'author',
            render: author => <span>{author.name}</span>
          },
          {
            title: 'Evento',
            dataIndex: 'event',
            key: 'event',
            render: (event: string) => (
              <TagField value={translateEventStatus(event)} />
            )
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
              <span>
                {status ? (
                  <span>
                    De:
                    <TagField
                      color="red"
                      value={translateDocumentationStatus(status[0])}
                    />
                    <br />
                    Para:
                    <TagField
                      color="green"
                      value={translateDocumentationStatus(status[1])}
                    />
                  </span>
                ) : (
                  ''
                )}
              </span>
            )
          },
          {
            title: 'Feedback',
            dataIndex: 'feedback',
            key: 'feedback',
            render: feedback => (
              <span>
                {feedback ? (
                  <span>
                    De:
                    <span style={{ color: 'red' }}>{feedback[0]}</span>
                    <br />
                    Para:
                    <span style={{ color: 'green' }}>{feedback[1]}</span>
                  </span>
                ) : (
                  ''
                )}
              </span>
            )
          }
        ]}
        loading={loading}
        pagination={false}
      />
    </Modal>
  );
};

export default LogsModal;
