import * as Icons from '@ant-design/icons';
import React from 'react';
import { Badge, Typography } from 'antd';
import { DOCUMENT_STATUSES_MAP } from './statuses-map';
import { DocumentStatuses } from 'interfaces/documentation/document';
import { TagField } from '@refinedev/antd';
import { pluralize } from '../../../../utils/pluralize';

interface DocumentPanelHeaderProps {
  title: string;
  status: DocumentStatuses;
  required: boolean;
  document_count: number;
}

export const DocumentPanelHeader = ({
  title,
  status,
  required,
  document_count
}: DocumentPanelHeaderProps) => {
  const documentStatus = DOCUMENT_STATUSES_MAP[status];
  const IconComponent = documentStatus?.icon ?? <Icons.CheckCircleOutlined />;
  const color = documentStatus?.color;

  return (
    <div className="w-full flex items-center justify-between gap-4">
      <div className="flex items-center gap-2">
        <div className="flex items-center" style={{ color }}>
          {IconComponent}
        </div>
        <Typography.Text strong>{title}</Typography.Text>
        <TagField
          color={document_count === 0 ? 'default' : 'blue'}
          value={
            document_count === 0
              ? 'Documentos não enviados'
              : `${pluralize(document_count, 'documento', 'documentos')}`
          }
        />
      </div>
      <TagField
        style={{ margin: 0, border: 0 }}
        color={required ? 'red' : 'gold'}
        value={required ? 'Obrigatório' : 'Opcional'}
      />
    </div>
  );
};
