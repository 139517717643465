import { FeeRuleList } from 'pages/deal/fees';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'offers/fees',
  label: 'Regras de Juros e Multa',
  list: FeeRuleList,
  actions: ['list', 'create', 'delete'],
  parentName: 'deal'
};

export default generateResources(resource);
