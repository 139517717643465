import CreateAudioModal from './components/modals/CreateAudioModal';
import CreateDocumentModal from './components/modals/CreateDocumentModal';
import CreateVideoModal from './components/modals/CreateVideoModal';
import {
  AudioOutlined,
  DownOutlined,
  FileTextOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Typography
} from 'antd';
import {
  CreateButton,
  Edit,
  ListButton,
  ShowButton,
  TextField,
  useForm,
  useTable
} from '@refinedev/antd';
import { HttpError, useModal } from '@refinedev/core';
import { ILibrary, ILibraryContent } from 'interfaces/libraries';
import { MenuProps } from 'antd/lib';
import { translateKind } from '../../services/libraries/contents';
import { useParams } from 'react-router-dom';

export const LibrariesEdit = () => {
  const { id } = useParams();

  const { formProps } = useForm<ILibrary>({
    resource: 'prometheus/v2/libraries',
    id,
    action: 'edit',
    redirect: false,
    autoSave: {
      enabled: true,
      debounce: 1000
    }
  });

  return (
    <Edit title="Editar biblioteca">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
