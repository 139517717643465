import * as Icons from '@ant-design/icons';
import {
  Alert,
  Button,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import { EditButton, Show, ShowButton } from '@refinedev/antd';
import { IProduct } from 'interfaces/products';
import { IProductBundle } from 'interfaces/product_bundles';
import { getProductableTypeTranslation } from 'utils/product-utils';
import { slugify } from 'utils/slugify';
import { useList, useShow } from '@refinedev/core';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const productColumns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Tipo',
    dataIndex: 'productable_type',
    key: 'productable_type',
    render: (_: unknown, record: IProduct) => {
      const type = getProductableTypeTranslation(record);
      return <Tag color={type === 'Curso livre' ? 'green' : 'red'}>{type}</Tag>;
    }
  },
  {
    title: 'Identificador do curso',
    dataIndex: 'productable_id',
    key: 'productable_id',
    render: (_: unknown, record: IProduct) => {
      const type = getProductableTypeTranslation(record);
      return (
        <Tag color={type === 'Curso livre' ? 'green' : 'red'}>{record?.id}</Tag>
      );
    }
  },
  {
    title: 'Produto avulso',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: unknown, record: IProduct) => {
      return (
        <Space>
          <ShowButton
            resource="products"
            hideText
            size="small"
            recordItemId={record.id}
          />
        </Space>
      );
    }
  }
];

const bundleProductColumns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Tipo',
    dataIndex: 'productable_type',
    key: 'productable_type',
    render: (_: unknown, record: IProduct) => {
      const type = getProductableTypeTranslation(record);
      return <Tag color={type === 'Curso livre' ? 'green' : 'red'}>{type}</Tag>;
    }
  },
  {
    title: 'Ações',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: unknown, record: IProduct) => {
      return (
        <Space>
          <ShowButton
            resource="products"
            hideText
            size="small"
            recordItemId={record.id}
          />
          <EditButton
            resource="products"
            hideText
            size="small"
            recordItemId={record.id}
          />
        </Space>
      );
    }
  }
];

export const ProductBundleShow = () => {
  const { queryResult: bundleShow } = useShow<IProductBundle>();
  const { data, isLoading } = bundleShow;
  const record = data?.data;

  const { data: productData, isLoading: areProductsLoading } =
    useList<IProduct>({
      resource: 'products',

      filters: [
        {
          field: 'by_id[]',
          operator: 'eq',
          value: record?.product_ids ?? []
        }
      ]
    });

  const { data: productsWithBundle, isLoading: areProductsWithBundleLoading } =
    useList<IProduct>({
      resource: 'products',

      filters: [
        {
          field: 'filter[productable_id]',
          operator: 'eq',
          value: record?.id
        },
        {
          field: 'filter[productable_type]',
          operator: 'eq',
          value: 'Products::Bundle'
        }
      ]
    });

  const productsList = record?.product_ids?.length ? productData?.data : [];
  const navigate = useNavigate();

  const createProductFromBundle = () => {
    navigate('/ecommerce/products/create', {
      state: {
        productData: {
          productable_type: 'Products::Bundle',
          name: record?.name,
          productable_id: record?.id,
          slug: record?.name && slugify(record?.name)
        }
      }
    });
  };

  return (
    <Show isLoading={isLoading} title="Visualizar combo">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={4}>Nome</Title>
      <Text>{record?.name}</Text>
      <Title
        level={4}
        style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
      >
        Produto do combo na vitrine
        <Tooltip title="Aqui é listado o produto para venda desse combo, precisa ser único">
          <Icons.QuestionCircleOutlined />
        </Tooltip>
      </Title>
      <Alert
        message="Para serem vendidos, os combos precisam estar associados com uma oferta através de um produto"
        showIcon
        type="warning"
      />
      {areProductsWithBundleLoading ? (
        <div className="w-full flex alingn-center justify-center p-10">
          <Spin className="m-auto" tip="Loading" size="large" />
        </div>
      ) : (
        <Table
          style={{ marginTop: '10px' }}
          dataSource={productsWithBundle?.data}
          columns={bundleProductColumns}
        />
      )}
      {productsWithBundle?.data?.length === 0 && (
        <div
          style={{
            marginTop: '14px',
            marginBottom: '14px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button type="primary" onClick={createProductFromBundle}>
            Associar combo com oferta
          </Button>
        </div>
      )}
      <Title level={4}>Conteúdo do combo</Title>
      {areProductsLoading ? (
        <div className="w-full flex alingn-center justify-center p-10">
          <Spin className="m-auto" tip="Loading" size="large" />
        </div>
      ) : (
        <>
          <Alert
            message="Cursos aos quais o aluno terá acesso após adquirir o combo"
            showIcon
            type="info"
            style={{ marginBottom: '12px' }}
          />
          {productsList?.map(product => (
            <Tag style={{ margin: '4px' }} key={product.id} color="blue">
              {product.name}
            </Tag>
          ))}

          <Table
            style={{ marginTop: '10px' }}
            dataSource={productsList}
            columns={productColumns}
          />
        </>
      )}
    </Show>
  );
};
