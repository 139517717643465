import { UserCreate, UserEdit, UserList, UserShow } from 'pages/academic/users';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'users',
  label: 'Alunos',
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
  actions: ['list', 'show', 'create', 'edit'],
  parentName: 'students_teachers'
};

export default generateResources(resource);
