import { TagEdit } from 'pages/tags';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'tags',
  label: 'Tags',
  edit: TagEdit,
  actions: ['list', 'show', 'edit', 'create'],
  parentName: 'teaching_resources/libraries/:libraryId'
};

export default generateResources(resource);
