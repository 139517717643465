import { Button, Form, Input, Row } from 'antd';
import { ReactNode, useEffect } from 'react';
import { useForm } from '@refinedev/antd';
import { useNotification } from '@refinedev/core';

export interface HybridInputProps {
  value: string | undefined;
  showInput: boolean;
  children: ReactNode;
  handleState: () => void;
  handleMutation: (value: string) => void;
}

export const HybridInput = ({
  value,
  showInput,
  children,
  handleState,
  handleMutation
}: HybridInputProps) => {
  const { form, formProps } = useForm();
  const { open } = useNotification();

  const handleConfirm = async () => {
    return form
      .validateFields()
      .then(async () => {
        return handleMutation(form.getFieldValue('text'));
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: 'Favor conferir todos os campos obrigatórios.',
          description: 'Erro ao criar registro!'
        });
      })
      .finally(() => {
        form.resetFields();
      });
  };

  useEffect(() => {
    form.setFieldValue('text', value);
  }, [showInput]);

  return (
    <>
      {showInput ? (
        <Form
          form={form}
          {...formProps}
          layout="vertical"
          initialValues={{ text: value }}
          className="w-full"
        >
          <Form.Item colon name="text">
            <Input.TextArea rows={4} style={{ minWidth: 250 }} />
          </Form.Item>
          <Row className="flex w-full justify-end gap-1">
            <Button
              type="primary"
              className="shadow-none"
              onClick={handleConfirm}
            >
              Confirmar
            </Button>
            <Button type="default" onClick={handleState}>
              Cancelar
            </Button>
          </Row>
        </Form>
      ) : (
        children
      )}
    </>
  );
};
