import {
  CitySelectField,
  InputDateField,
  InputField,
  InputNumberField,
  SelectField,
  StateSelectField,
  TextAreaField
} from './Fields';
import { Form } from 'antd';
import { IAttributeField } from 'interfaces/documentation/document-attribute-field';
import { useMemo } from 'react';

interface DocumentItemFieldProps {
  field: IAttributeField;
}

const FIELDS_MAP = {
  text: InputField,
  number: InputNumberField,
  select: SelectField,
  text_area: TextAreaField,
  state_select: StateSelectField,
  city_select: CitySelectField,
  date: InputDateField
};

export const DocumentItemField = ({ field }: DocumentItemFieldProps) => {
  const { field_type, name, value, required, min, max } = field;

  const Component = useMemo(() => FIELDS_MAP[field_type], [field_type]);

  const generateRules = () => {
    const rules = [];

    if (min) {
      rules.push({ min, message: `Mínimo ${min} caracteres` });
    }

    if (max) {
      rules.push({ max, message: `Máximo ${max} caracteres` });
    }

    if (required) {
      rules.push({ required, message: 'Campo obrigatório' });
    }

    return rules;
  };

  const rules = useMemo(generateRules, [max, min, required]);

  return (
    <Form.Item label={name} name={name} initialValue={value} rules={rules}>
      <Component {...field} />
    </Form.Item>
  );
};
