import FilterForm from '../../../components/forms/FilterForm';
import { EditButton, List, TextField, useTable } from '@refinedev/antd';
import { Form, Input, Space, Table } from 'antd';
import { HttpError } from '@refinedev/core';
import { IProductSet } from 'interfaces/product_sets';

export const ProductSetList = () => {
  const { tableProps, searchFormProps } = useTable<
    IProductSet,
    HttpError,
    { byName: string }
  >({
    onSearch: ({ byName }) => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value: byName
      }
    ]
  });

  return (
    <List>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Nome" name="byName">
          <Input placeholder="Busque pelo nome aqui" />
        </Form.Item>
      </FilterForm>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="product_ids"
          key="product_ids"
          title="Produtos"
          render={({ length }) => <TextField value={length} />}
        />
        <Table.Column<IProductSet>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
