interface OptionsType {
  label: string;
  value: string | null;
  color: string;
}
export const forumStatusOptions: OptionsType[] = [
  {
    label: 'Todos',
    value: null,
    color: 'default'
  },
  {
    value: 'opened',
    label: 'Aberto',
    color: 'default'
  },
  {
    value: 'closed',
    label: 'Fechado',
    color: 'default'
  },
  {
    value: 'deleted',
    label: 'Excluído',
    color: 'red'
  },
  {
    value: 'archived',
    label: 'Arquivado',
    color: 'red'
  }
];
