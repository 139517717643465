import React from 'react';
import { Form, Input, Modal, ModalProps, Space, Typography } from 'antd';
import { useCreate, useNotification } from '@refinedev/core';
import { useForm } from '@refinedev/antd';

interface Props extends ModalProps {
  bodyTitle: string;
  bodyText: string;
  onClose: () => void;
  refetch?: () => void;
  questionId?: string;
}

const NullifyQuestionModal: React.FC<Props> = ({
  bodyTitle,
  bodyText,
  questionId,
  onClose,
  refetch,
  ...modalProps
}) => {
  const { mutateAsync: nullifyQuestion } = useCreate();
  const { form, formProps } = useForm();
  const { open } = useNotification();

  const handleAction = async () => {
    const resource = `assessment_questions/${questionId}/nullify`;

    return form
      .validateFields()
      .then(async () => {
        return nullifyQuestion({
          resource,
          values: {
            ...form.getFieldsValue()
          },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Questão anulada com sucesso!'
          }),
          errorNotification: () => {
            return {
              description: 'Algo deu errado',
              message: 'Não foi possível anular a questão',
              type: 'error'
            };
          }
        }).then(() => {
          refetch?.();
          onClose();
          form.resetFields();
        });
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: 'Favor conferir todos os campos obrigatórios.',
          description: 'Error ao criar registro!'
        });
      });
  };

  return (
    <Modal {...modalProps} onOk={handleAction}>
      <Space direction="vertical">
        <Typography.Title level={5} style={{ fontWeight: 500, margin: 0 }}>
          {bodyTitle}
        </Typography.Title>
        <Typography.Text>{bodyText}</Typography.Text>
      </Space>

      <Form
        form={form}
        {...formProps}
        layout="vertical"
        style={{ marginTop: 16 }}
      >
        <Form.Item
          label="Justificativa"
          name="nullified_feedback"
          rules={[
            {
              required: true,
              message: 'Campo Justificativa em branco'
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NullifyQuestionModal;
