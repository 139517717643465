import * as React from 'react';
import Logotype from './Logotype';
import { Image } from 'antd';
import { getTenant } from 'services/tenants';

const PartnerLogo = ({ style }: { style?: React.CSSProperties }) => {
  const logo = getTenant().logoUrl;
  return Boolean(logo) ? (
    <Image preview={false} src={logo} style={style} />
  ) : (
    <Logotype style={style} />
  );
};

export default PartnerLogo;
