import { Col, Form, Input, Row } from 'antd';
import { Edit, useForm } from '@refinedev/antd';
import { IUser } from 'interfaces/users';
import { InputMask } from 'components/form-items/InputMask';

export const UserEdit = () => {
  const { formProps, saveButtonProps, queryResult, formLoading } =
    useForm<IUser>({
      redirect: 'edit'
    });

  const data = queryResult?.data;
  const record = data?.data;

  return (
    <Edit
      title="Editar aluno"
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      {record && (
        <Form layout="vertical" {...formProps}>
          <Row gutter={[16, 16]}>
            <Col xs={12}>
              <Form.Item label="Nome" name="name" initialValue={record.name}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="E-mail"
                name="email"
                initialValue={record.email}
                rules={[
                  {
                    type: 'email',
                    message: 'Preencha com um email válido!'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Telefone"
                name={['personal_info_attributes', 'phone_number']}
                initialValue={record.personal_info?.phone_number}
                rules={[
                  {
                    pattern: new RegExp(/^\d{2} \d{5}-\d{4}$/),
                    message: 'Insira um numero de telefone válido'
                  }
                ]}
              >
                <InputMask mask="00 00000-0000" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Senha"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório'
                  }
                ]}
              >
                <Input.Password type="password" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Edit>
  );
};
