import { CrudFilters, HttpError } from '@refinedev/core';
import {
  DiscussionThreadsFilter,
  IDiscussionThreadsFilter
} from './components/list-filter';
import { DiscussionThreadsTable } from './components/list-table';
import { IDiscussionThread } from 'interfaces/discussion_threads';
import { List, useTable } from '@refinedev/antd';

export const DiscussionThreadList = () => {
  const { tableProps, searchFormProps } = useTable<
    IDiscussionThread,
    HttpError,
    IDiscussionThreadsFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const {
        by_grandparent_type,
        by_grandparent_id,
        by_status,
        by_user_name,
        by_user_email
      } = params;

      filters.push(
        {
          field: 'filter[by_grandparent_type]',
          operator: 'eq',
          value: by_grandparent_type
        },
        {
          field: 'filter[by_grandparent_id]',
          operator: 'eq',
          value: by_grandparent_id
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: by_status
        },
        {
          field: 'filter[by_user_name]',
          operator: 'eq',
          value: by_user_name
        },
        {
          field: 'filter[by_user_email]',
          operator: 'eq',
          value: by_user_email
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_grandparent_type]',
          operator: 'eq',
          value: 'Course'
        }
      ]
    }
  });

  return (
    <List>
      <DiscussionThreadsFilter searchFormProps={searchFormProps} />
      <DiscussionThreadsTable tableProps={tableProps} />
    </List>
  );
};
