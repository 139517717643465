import { Create, useForm, useSelect } from '@refinedev/antd';
import { DatePicker, Form, Input, Select, Typography } from 'antd';
import { IInteractiveClass } from 'interfaces/interactive_classes';
import { IResourceComponentsProps } from '@refinedev/core';

export const InteractiveClassCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { selectProps: professorSelectProps } = useSelect({
    resource: 'professors',
    optionLabel: (item: any) => `${item.name} - (${item.email})`,
    optionValue: (item: any) => item.id,
    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ]
  });

  const { selectProps: classroomsSelectProps } = useSelect({
    resource: 'classrooms',
    optionLabel: (item: any) => `${item.discipline.name} - ${item.code}`,
    optionValue: (item: any) => item.id,
    onSearch: value => [
      {
        field: 'filter[search_code]',
        operator: 'eq',
        value
      }
    ]
  });

  const { form, formProps, saveButtonProps, onFinish } =
    useForm<IInteractiveClass>({
      redirect: 'list',
      successNotification: () => {
        return {
          description: 'Sucesso!',
          message: 'Aviso criado com sucesso',
          type: 'success'
        };
      }
    });
  return (
    <Create saveButtonProps={saveButtonProps} title="Criar aula interativa">
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="title"
          label="Título da aula"
          rules={[{ required: true, message: 'O título é obrigatório' }]}
        >
          <Input placeholder="Informe o título da aula" />
        </Form.Item>
        <Form.Item name="description" label="Descrição">
          <Input.TextArea placeholder="Adicionar descrição" />
        </Form.Item>
        <Form.Item
          name="start_time"
          label="Data da aula"
          help="Agendar com 24 horas de antecedência"
          rules={[{ required: true, message: 'A data da aula é obrigatório' }]}
        >
          <DatePicker
            showTime
            format={'DD/MM/YYYY HH:mm'}
            placeholder="dd/mm/aaaa hh:mm"
          />
        </Form.Item>
        <Form.Item
          name="duration_in_minutes"
          label="Duração"
          rules={[{ required: true, message: 'A duração é obrigatório' }]}
        >
          <Select>
            <Select.Option value={30}>30 minutos</Select.Option>
            <Select.Option value={60}>1 hora</Select.Option>
            <Select.Option value={90}>1 hora e 30 minutos</Select.Option>
            <Select.Option value={120}>2 horas</Select.Option>
            <Select.Option value={150}>2 horas e 30 minutos</Select.Option>
            <Select.Option value={180}>3 horas</Select.Option>
            <Select.Option value={210}>3 horas e 30 minutos</Select.Option>
            <Select.Option value={240}>4 horas</Select.Option>
            <Select.Option value={270}>4 horas e 30 minutos</Select.Option>
            <Select.Option value={300}>5 horas</Select.Option>
            <Select.Option value={330}>5 horas e 30 minutos</Select.Option>
            <Select.Option value={360}>6 horas</Select.Option>
            <Select.Option value={390}>6 horas e 30 minutos</Select.Option>
            <Select.Option value={420}>7 horas</Select.Option>
            <Select.Option value={450}>7 horas e 30 minutos</Select.Option>
            <Select.Option value={480}>8 horas</Select.Option>
            <Select.Option value={510}>8 horas e 30 minutos</Select.Option>
            <Select.Option value={540}>9 horas</Select.Option>
            <Select.Option value={570}>9 horas e 30 minutos</Select.Option>
            <Select.Option value={600}>10 horas</Select.Option>
            <Select.Option value={630}>10 horas e 30 minutos</Select.Option>
            <Select.Option value={660}>11 horas</Select.Option>
            <Select.Option value={690}>11 horas e 30 minutos</Select.Option>
            <Select.Option value={720}>12 horas</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="professor_ids"
          label="Professor"
          rules={[{ required: true, message: 'O professor é obrigatório' }]}
        >
          <Select {...professorSelectProps} mode="multiple" />
        </Form.Item>
        <Form.Item
          name="classroom_ids"
          label="Turmas"
          rules={[{ required: true, message: 'A turma é obrigatória' }]}
        >
          <Select {...classroomsSelectProps} mode="multiple" />
        </Form.Item>
      </Form>
    </Create>
  );
};
