import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select, TreeSelect } from 'antd';
import { IAgent } from 'interfaces/agents';
import { IRole } from 'interfaces/roles';
import { treePermissions } from 'services/permissions';
import { useMemo } from 'react';

export const AgentEdit = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IAgent>();

  const { selectProps: roleSelectProps } = useSelect<IRole>({
    resource: 'roles',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: queryResult?.data?.data.role_ids,

    pagination: {
      mode: 'server'
    }
  });

  const permissions = useMemo(() => {
    if (!queryResult?.data?.data.permissions) {
      return [];
    }

    return queryResult?.data?.data.permissions;
  }, [queryResult?.data?.data.permissions]);

  return (
    <Edit title="Editar usuário" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Login"
          name="login"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Grupos" name="role_ids">
          <Select {...roleSelectProps} mode="multiple" />
        </Form.Item>

        <Form.Item label="Permissões" name="permissions">
          <TreeSelect
            treeData={treePermissions(permissions)}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_PARENT}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
