import FilterForm from '../../../components/forms/FilterForm';
import { Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IComment } from 'interfaces/comments';
import { IUser } from '../../../interfaces/users';
import { List, ShowButton, TextField, useTable } from '@refinedev/antd';
import { formatDate } from 'services/date';

const translateParentKind = (parentKind: string) => {
  switch (parentKind) {
    case 'content': {
      return 'Na Aula';
    }

    case 'course': {
      return 'No Curso';
    }

    case 'comment': {
      return 'Resposta de comentário';
    }

    default: {
      return parentKind;
    }
  }
};

interface IFilter {
  parent_kind: string;
  email: string;
}

export const CommentsList = () => {
  const { tableProps, searchFormProps } = useTable<
    IComment,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { email, parent_kind } = params;

      filters.push(
        {
          field: 'filter[email]',
          operator: 'eq',
          value: email
        },
        {
          field: 'filter[parent_kind]',
          operator: 'eq',
          value: parent_kind
        }
      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List title="Comentários de aulas">
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="E-mail" name="email">
              <Input placeholder="e-mail do aluno" />
            </Form.Item>
            <Form.Item label="Localização" name="parent_kind">
              <Select placeholder="Selecione..." style={{ minWidth: 200 }}>
                <Select.Option value="content">Na Aula</Select.Option>
                <Select.Option value="course">No Curso</Select.Option>
                <Select.Option value="comment">
                  Resposta de comentário
                </Select.Option>
              </Select>
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex={['user', 'email']}
              key="email"
              title="Autor"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="parent_kind"
              key="parent_kind"
              title="Localização"
              render={value => <TextField value={translateParentKind(value)} />}
            />
            <Table.Column
              dataIndex="text"
              key="text"
              title="Texto"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Data de envio"
              render={value => <TextField value={formatDate(value)} />}
            />
            <Table.Column<IUser>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
