import { Create, useForm } from '@refinedev/antd';
import { FormAssessment } from './components/FormAssessment';
import { IAssessment } from 'interfaces/assessments';

export const AssessmentCreate = () => {
  const form = useForm<IAssessment>({
    redirect: 'edit'
  });

  return (
    <Create saveButtonProps={form.saveButtonProps} title="Criar avaliação">
      <FormAssessment formInstance={form} />
    </Create>
  );
};
