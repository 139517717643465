import { Col, Form, Input, Modal, ModalProps, Row, Space } from 'antd';
import { getTenant } from 'services/tenants';
import { useCreate } from '@refinedev/core';
import { useForm } from '@refinedev/antd';

export interface CreateDisciplineModal {
  modalClose: () => void;
  modalProps: ModalProps;
  refetch: () => void;
  courseId: string;
}

export const CreateDisciplineModal = ({
  modalClose,
  modalProps,
  refetch,
  courseId
}: CreateDisciplineModal) => {
  const { name } = getTenant();
  const { mutateAsync: createDiscipline } = useCreate();
  const { mutateAsync: insertDiscipline } = useCreate();
  const { form, formProps } = useForm();

  const handleCreate = async () => {
    const resource = `graduate_disciplines`;

    return form
      .validateFields()
      .then(async () => {
        return createDiscipline({
          resource,
          values: {
            ...form.getFieldsValue(),
            name: form.getFieldValue('disciplineName'),
            code: name?.slice(0, 3)?.toUpperCase() + form.getFieldValue('code')
          },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Disciplina criada com sucesso'
          }),
          errorNotification: error => {
            if (
              error?.message ===
              'Validation failed: Code has already been taken'
            ) {
              return {
                type: 'error',
                description: 'Ops!',
                message: 'Código da disciplina duplicado.'
              };
            }

            return {
              type: 'error',
              description: 'Ops!',
              message: 'Não foi possivel cadastrar a disciplina.'
            };
          }
        }).then(async response => {
          const disciplineResource = 'courses/disciplines';
          insertDiscipline(
            {
              resource: disciplineResource,
              values: {
                discipline_id: response.data.id,
                course_id: courseId
              },
              successNotification: () => {
                return {
                  description: 'Sucesso!',
                  message: 'Disciplina vinculada ao módulo com sucesso!',
                  type: 'success'
                };
              }
            },
            {
              onSuccess: () => {
                refetch();
                form.resetFields();
                modalClose();
              }
            }
          );
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <Modal
      style={{ maxWidth: 600, zIndex: 9999 }}
      {...modalProps}
      title="Criar disciplina"
      cancelText="Cancelar"
      okText="Criar e inserir"
      onOk={handleCreate}
      className="!w-[1022px]"
      destroyOnClose
      onCancel={() => {
        form.resetFields();
        modalClose();
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="disciplineName"
          rules={[
            {
              required: true,
              message: 'Digite um nome.'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Código da disciplina"
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Digite um código.'
                },
                {
                  pattern: new RegExp(/^\d{4}$/),
                  message: 'Insira um numero válido de 4 digitos'
                }
              ]}
            >
              <Input
                className="w-[50%]"
                maxLength={4}
                addonBefore={name.slice(0, 3).toUpperCase()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Duração (minutos)"
              name="duration_in_minutes"
              rules={[
                {
                  required: true,
                  message: 'Digite a duração.'
                }
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
