import {
  DocumentationEdit,
  DocumentationList,
  DocumentationShow
} from 'pages/graduate_courses/documentations';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'documentations',
  label: 'Documentações',
  list: DocumentationList,
  edit: DocumentationEdit,
  show: DocumentationShow,
  actions: ['list', 'edit', 'show'],
  parentName: 'secretary'
};

export default generateResources(resource);
