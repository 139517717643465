import useSelectCourseByType from 'hooks/useSelectCourseByType';
import { ASSESSMENT_KIND_OPTIONS } from 'services/assessments';
import { Col, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import { IAssessment } from 'interfaces/assessments';
import { UseFormReturnType } from '@refinedev/antd';
import { useCallback, useMemo } from 'react';

interface AssessmentFormProps {
  formInstance: UseFormReturnType<IAssessment>;
}

const selectOptions = [
  { label: 'Curso', value: 'Course' },
  { label: 'Disciplina', value: 'Graduate::Discipline' }
];

export const FormAssessment = ({ formInstance }: AssessmentFormProps) => {
  const { form, formProps, queryResult } = formInstance;
  const assessment = queryResult?.data?.data;

  const editForm = useMemo(() => Boolean(assessment?.id), [assessment]);

  const { selectProps, selectedCourseType, setSelectedCourseType } =
    useSelectCourseByType(
      form.getFieldValue('parent_type'),
      'graduate_disciplines',
      assessment?.parent_id
    );

  const onChangeType = useCallback(
    (type: 'Course' | 'Graduate::Discipline') => {
      form.resetFields(['parent_id']);
      setSelectedCourseType(type);
    },
    [form, setSelectedCourseType]
  );

  const initialValues = useMemo(() => {
    return assessment
      ? {
          ...assessment,
          time_in_minutes: Number(assessment?.time_in_seconds) / 60
        }
      : {
          time_in_minutes: null
        };
  }, [assessment]);

  return (
    <Form
      form={form}
      {...formProps}
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        label="Nome"
        name="name"
        rules={[{ required: true, message: 'Obrigatório' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Tipo de avaliação" name="kind">
        <Select options={ASSESSMENT_KIND_OPTIONS} defaultValue="objective" />
      </Form.Item>

      <Typography.Text style={{ display: 'block', marginBottom: 16 }} strong>
        Local da avaliação
      </Typography.Text>

      <Row gutter={16}>
        <Col xs={24} md={4}>
          <Form.Item label="Tipo" name="parent_type" initialValue="Course">
            <Select
              options={selectOptions}
              defaultValue={assessment?.parent_type || 'Course'}
              onChange={onChangeType}
              disabled={editForm}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={20}>
          <Form.Item
            label={selectedCourseType === 'Course' ? 'Curso' : 'Disciplina'}
            name="parent_id"
            rules={[
              {
                required: true,
                message: 'Obrigatório'
              }
            ]}
          >
            <Select {...selectProps} disabled={editForm} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item hidden name="time_in_seconds">
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Duração"
            name="time_in_minutes"
            rules={[
              {
                required: true,
                message: 'Obrigatório'
              }
            ]}
          >
            <InputNumber
              addonAfter="minutos"
              min={0}
              onChange={value =>
                form.setFieldsValue({ time_in_seconds: Number(value) * 60 })
              }
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item
            label="Qtde. de questões"
            tooltip="É necessário ter no mínimo essa quantidade de questões para gerar a prova."
            name="question_amount"
            rules={[
              {
                required: true,
                message: 'Obrigatório'
              }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={24} md={3}>
          <Form.Item
            label="Peso"
            name="weight"
            rules={[
              {
                required: true,
                message: 'Obrigatório'
              }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item
            label="Nota mínima (0-100)"
            name="passing_score"
            rules={[
              {
                required: true,
                message: 'Obrigatório'
              }
            ]}
          >
            <InputNumber disabled={editForm} style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col xs={24} md={3}>
          <Form.Item
            label="Tentativas"
            name="max_attempts"
            rules={[
              {
                required: false
              }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
