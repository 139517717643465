import { IOrder } from 'interfaces/orders';

const STATUS_TRANSLATIONS = new Map([
  ['initial', 'Pagamento não recebido'],
  ['created', 'Pedido criado'],
  ['approved', 'Pedido aprovado'],
  ['returned', 'Pedido devolvido']
]);

export const translateStatus = (status: IOrder['status']): string =>
  STATUS_TRANSLATIONS.get(status) || status;
