import { Divider, Typography } from 'antd';
import { IClassroom } from 'interfaces/classrooms';
import { formatDate } from 'services/date';

const { Title, Text } = Typography;

export interface ClassroomShowInfoProps {
  classroom: IClassroom;
}

export const ClassroomShowInfo = ({ classroom }: ClassroomShowInfoProps) => {
  return (
    <>
      <div
        style={{
          marginRight: '20px',
          marginBottom: '12px',
          fontSize: '12px'
        }}
      >
        <Text>ID: {classroom.number}</Text>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div style={{ marginRight: '20px' }}>
          <Title level={5}>Código</Title>
          <Text>{classroom.code}</Text>
        </div>
        <div>
          <Title level={5}>N° de vagas</Title>
          <Text>{classroom.seats_total}</Text>
        </div>
      </div>
      <Divider orientation="left" orientationMargin="0">
        <strong>Descrição</strong>
      </Divider>
      <Text>{classroom?.description}</Text>

      <Title level={3}>
        Datas da turma
        <Text type="secondary" style={{ fontSize: '13px' }}>
          {' '}
          (Horário de Brasília)
        </Text>
      </Title>

      <div>
        <Divider orientation="left" orientationMargin="0">
          <strong>Vigência</strong>
        </Divider>
        <div>
          <strong>Início: </strong>
          <Text>{formatDate(classroom.starts_at)}</Text>
        </div>
        <div style={{ marginBottom: '26px' }}>
          <strong>Fim: </strong>
          <Text>{formatDate(classroom.ends_at)}</Text>
        </div>

        <Divider orientation="left" orientationMargin="0">
          <strong>Acesso ao tira dúvidas</strong>
        </Divider>
        <div>
          <strong>Início: </strong>
          <Text>
            {classroom.discussion_starts_at
              ? formatDate(classroom.discussion_starts_at)
              : '-'}
          </Text>
        </div>
        <div style={{ marginBottom: '26px' }}>
          <strong>Fim: </strong>
          <Text>
            {classroom.discussion_ends_at
              ? formatDate(classroom.discussion_ends_at)
              : '-'}
          </Text>
        </div>

        <Divider orientation="left" orientationMargin="0">
          <strong>Acesso à visualização do conteúdo</strong>
        </Divider>
        <div>
          <strong>Início: </strong>
          <Text>
            {classroom.content_access_starts_at
              ? formatDate(classroom.content_access_starts_at)
              : '-'}
          </Text>
        </div>
        <div style={{ marginBottom: '26px' }}>
          <strong>Fim: </strong>
          <Text>
            {classroom.content_access_ends_at
              ? formatDate(classroom.content_access_ends_at)
              : '-'}
          </Text>
        </div>
      </div>
    </>
  );
};
