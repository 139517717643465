import { Col, Row, Space, Table, Tooltip } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  EquivalenciesFilter,
  IEquivalenciesFilterForm
} from './components/list-filter';
import { IEquivalency } from 'interfaces/equivalency';
import {
  List,
  ShowButton,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { tagColorByStatus } from '../../../utils/statusColorMapping';
import { translateSolicitationStatus } from './index';

export const EquivalencyList = () => {
  const { tableProps, searchFormProps } = useTable<
    IEquivalency,
    HttpError,
    IEquivalenciesFilterForm
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { courseId, by_status, by_name, by_email } = params;

      filters.push(
        {
          field: 'filter[by_course_id]',
          operator: 'eq',
          value: courseId
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: by_status
        },
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value: by_name
        },
        {
          field: 'filter[by_email]',
          operator: 'eq',
          value: by_email
        }
      );
      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: 'submitted'
        }
      ]
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List title="Solicitações de Aproveitamento de Disciplinas">
          <EquivalenciesFilter searchFormProps={searchFormProps} />

          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex={['course_enrollment', 'user']}
              key="userInfo"
              title="Aluno"
              render={value => (
                <>
                  <TextField value={value.name} />
                  <div style={{ fontSize: 'smaller', color: 'gray' }}>
                    <TextField value={value.email} />
                  </div>
                </>
              )}
            />
            <Table.Column
              dataIndex={['course_enrollment', 'course', 'name']}
              key="course"
              title="Curso"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex={['solicitable', 'desired_discipline', 'name']}
              key="name"
              title="Disciplina pretendida"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex={['documents_count']}
              key="documents_count"
              title="Anexos"
              render={value => <TextField value={`📝 ${value} documentos`} />}
            />

            <Table.Column
              key="timestamps"
              title="Datas"
              render={({ created_at, updated_at }) => (
                <>
                  <div style={{ fontSize: 'smaller' }}>
                    <strong>Data da solicitação:</strong>{' '}
                    <TextField
                      value={formatDate(created_at, 'DD/MM/YYYY - hh:mm')}
                    />
                  </div>
                  <div style={{ fontSize: 'smaller' }}>
                    <strong>Última atualização:</strong>{' '}
                    <TextField
                      value={formatDate(updated_at, 'DD/MM/YYYY - hh:mm')}
                    />
                  </div>
                </>
              )}
            />

            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={status => (
                <TagField
                  value={translateSolicitationStatus(status || '')}
                  color={tagColorByStatus(status || '')}
                />
              )}
            />

            <Table.Column<IEquivalency>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <Tooltip title="Validar solicitação">
                      <ShowButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                      />
                    </Tooltip>
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

export default EquivalencyList;
