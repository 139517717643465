import { AxiosInstance } from 'axios';
import {
  CrudFilters,
  CrudOperators,
  CrudSorting,
  DataProvider
} from '@refinedev/core';
import { getAPI } from 'requests/api';
import { stringify } from 'query-string';

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case 'ne':
    case 'gte':

    case 'lte': {
      return `_${operator}`;
    }

    case 'contains': {
      return '_like';
    }

    default: {
      return '';
    }
  }
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = [];
    const _order: string[] = [];

    for (const item of sort) {
      _sort.push(item.field);
      _order.push(item.order);
    }

    return {
      _sort,
      _order
    };
  }
};

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: Record<string, string> = {};

  if (filters) {
    for (const filter of filters) {
      if (filter.operator !== 'or') {
        const { field, operator, value } = filter;

        if (field === 'q') {
          queryFilters[field] = value;
          continue;
        }

        const mappedOperator = mapOperator(operator);
        queryFilters[`${field}${mappedOperator}`] = value;
      }
    }
  }

  return queryFilters;
};

const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = getAPI()
): DataProvider => ({
  getList: async ({ resource, pagination, filters, sort }) => {
    const url = `${apiUrl}/${resource}`;

    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;

    const queryFilters = generateFilter(filters);

    const query: {
      page: number;
      items: number;
      _sort?: string;
      _order?: string;
    } = {
      page: current,
      items: pageSize
    };

    const generatedSort = generateSort(sort);

    if (generatedSort) {
      const { _sort, _order } = generatedSort;
      query._sort = _sort.join(',');
      query._order = _order.join(',');
    }

    const { data, headers } = await httpClient.get(
      `${url}?${stringify(query)}&${stringify(queryFilters)}`
    );

    const total = headers['x-total-count'];

    return {
      data,
      total
    };
  },

  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${stringify({ 'by_id[]': ids })}`
    );

    return {
      data
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    return {
      data
    };
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async param => {
        const { data } = await httpClient.post(`${apiUrl}/${resource}`, param);
        return data;
      })
    );

    return { data: response };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.patch(url, variables);

    return {
      data
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async id => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}`,
          variables
        );
        return data;
      })
    );

    return { data: response };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url);

    return { data };
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url);

    return { data };
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async id => {
        const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);
        return data;
      })
    );
    return { data: response };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const generatedSort = generateSort(sort);

      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(','),
          _order: _order.join(',')
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers
      };
    }

    let axiosResponse;

    switch (method) {
      case 'put':
      case 'post':

      case 'patch': {
        axiosResponse = await httpClient[method](url, payload);
        break;
      }

      case 'delete': {
        axiosResponse = await httpClient.delete(url);
        break;
      }

      default: {
        axiosResponse = await httpClient.get(requestUrl);
        break;
      }
    }

    const { data } = axiosResponse;

    return { data };
  }
});

export default dataProvider;
