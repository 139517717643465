import * as Icons from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import { DeleteButton, TextField } from '@refinedev/antd';
import { IDocumentTemplateCourse } from 'interfaces/documentation/document-template';
import { useModalReturnType } from '@refinedev/core';

export interface AssociationsTableProps {
  modal: useModalReturnType;
  templateCourses?: IDocumentTemplateCourse[];
  setAssociationContext: (record: IDocumentTemplateCourse) => void;
  resource: string;
}

const AssociationsTable = ({
  modal,
  templateCourses,
  setAssociationContext,
  resource
}: AssociationsTableProps) => {
  return (
    <Table dataSource={templateCourses} rowKey={record => record.id}>
      <Table.Column title="Curso" dataIndex={['course', 'name']} key="name" />
      <Table.Column
        title="Obrigatoriedade"
        dataIndex="required"
        key="required"
        render={(value: boolean) => (
          <TextField value={value ? 'Obrigatório' : 'Opcional'} />
        )}
      />
      <Table.Column<IDocumentTemplateCourse>
        title="Ações"
        dataIndex="actions"
        render={(_, record) => {
          return (
            <Space>
              <Tooltip title="Editar obrigatoriedade">
                <Button
                  icon={<Icons.EditOutlined />}
                  size="small"
                  onClick={() => {
                    setAssociationContext(record);
                    modal.show();
                  }}
                />
              </Tooltip>
              <Tooltip title="Remover associação">
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  resource={resource}
                />
              </Tooltip>
            </Space>
          );
        }}
      />
    </Table>
  );
};

export default AssociationsTable;
