import * as Icons from '@ant-design/icons';
import { Button, Space, Table, TableProps } from 'antd';
import { IAssignmentUser } from 'interfaces/assignments';
import { Link } from 'react-router-dom';
import { TagField, TextField } from '@refinedev/antd';

interface ListAssignmentsTableProps {
  tableProps: TableProps<IAssignmentUser[]>;
  assignmentId?: string;
}

const StatusColorMap: Record<string, string> = {
  'Nenhuma Pendência': 'success',
  'Aguardando Correção': 'warning'
};

export const ShowAssignmentsTable = ({
  tableProps,
  assignmentId
}: ListAssignmentsTableProps) => {
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="name"
        key="name"
        title="Aluno"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="email"
        key="email"
        title="E-mail do aluno"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="pending_assignment_attempts_count"
        key="pending_assignment_attempts_count"
        title="A corrigir"
        align="center"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="evaluation_started_assignment_attempts_count"
        key="evaluation_started_assignment_attempts_count"
        title="Em correção"
        align="center"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="assignment_attempts_count"
        key="assignment_attempts_count"
        title="Total"
        align="center"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="assignment_attempts_status"
        key="assignment_attempts_status"
        title="Status"
        align="center"
        render={status => {
          return <TagField color={StatusColorMap[status]} value={status} />;
        }}
      />
      <Table.Column
        title="Ações"
        dataIndex="actions"
        width={50}
        align="center"
        render={(_, record: IAssignmentUser) => {
          return (
            <Space>
              <Link
                to={`/evaluations/assignments/user/attempts/show/${record.id}?assignmentId=${assignmentId}`}
              >
                <Button icon={<Icons.EyeOutlined />} size="small" />
              </Link>
            </Space>
          );
        }}
      />
    </Table>
  );
};
