import * as Icons from '@ant-design/icons';
import { ACADEMIC_RECORD_STATUSES_MAP } from 'services/graduate-enrollment';
import { AcademicRecordEdit } from './edit';
import { AcademicRecordLogModal } from 'components/modal/academic-records/AcademicRecordLogModal';
import { Button, Space, Typography } from 'antd';
import { IAcademicRecord } from 'interfaces/academic_record';
import { Show, TagField, useModal as useModalAntd } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { UpdateAttendanceModal } from 'components/modal/attendance/UpdateAttendanceModal';
import { disciplineModulesKind } from 'pages/graduate_courses/components/TabDisciplines';
import { formatDate } from 'services/date';
import { useCallback, useMemo, useState } from 'react';
import { useCan, useModal, useShow } from '@refinedev/core';
import { useDisciplineModules } from 'hooks/useDisciplineModules';

export const AcademicRecordShow = () => {
  const [toBeUpdated, setToBeUpdated] = useState<IAcademicRecord>();
  const {
    show: showAttendanceModal,
    modalProps: attendanceModalProps,
    close: closeAttendanceModal
  } = useModalAntd();
  const { queryResult } = useShow<IAcademicRecord>({
    errorNotification: {
      type: 'error',
      message: 'Erro ao carregar dados. Tente novamente!'
    }
  });
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  const { data: canEdit } = useCan({
    resource: 'academic_records',
    action: 'edit'
  });
  const editScoreModal = useModal();
  const showLogModal = useModal();

  const disciplineModules = useDisciplineModules({
    filters: {
      by_course: record?.course_enrollment.course.id
    }
  }).data?.data;

  const disciplineModule = useMemo(() => {
    if (record && disciplineModules?.length) {
      return disciplineModules.find(
        dm => dm.discipline.id === record.discipline.id
      );
    }
  }, [disciplineModules, record]);

  const isClassroom = useMemo(
    () => (record && record.parent_type === 'Classrooms::Enrollment') ?? false,
    [record]
  );

  const handleUpdateAttendanceRate = useCallback(() => {
    setToBeUpdated(record);
    showAttendanceModal();
  }, [record, showAttendanceModal]);

  return (
    <>
      <Show
        isLoading={isLoading}
        title="Visualizar histórico acadêmico"
        headerButtons={
          <>
            <Button
              icon={<Icons.EditOutlined />}
              onClick={handleUpdateAttendanceRate}
            >
              Editar Frequência
            </Button>
            {canEdit?.can && (
              <Button
                icon={<Icons.EditOutlined />}
                onClick={editScoreModal.show}
                disabled={record?.status === 'exempted'}
              >
                Editar nota
              </Button>
            )}

            <Button icon={<Icons.EyeOutlined />} onClick={showLogModal.show}>
              Visualizar alterações
            </Button>
          </>
        }
      >
        {!record ? (
          <Typography.Text>Nenhuma informação encontrada</Typography.Text>
        ) : (
          <Space direction="vertical" size="middle">
            <ShowFieldData
              label="Status"
              value={
                <TagField
                  color={ACADEMIC_RECORD_STATUSES_MAP[record?.status].color}
                  value={ACADEMIC_RECORD_STATUSES_MAP[record?.status].label}
                />
              }
            />

            <ShowFieldData label="Disciplina" value={record?.discipline.name} />

            <ShowFieldData
              label="Tipo"
              value={
                disciplineModulesKind[
                  disciplineModule?.kind as keyof typeof disciplineModulesKind
                ]
              }
            />

            {isClassroom && 'classroom' in record.parent && (
              <>
                <ShowFieldData
                  label="Professor"
                  value={
                    record?.parent.classroom?.classroom_professors.length > 0
                      ? record?.parent.classroom?.classroom_professors[0]
                          .professor.name
                      : '-'
                  }
                />

                <ShowFieldData
                  label="Turma"
                  value={record?.parent.classroom.code}
                />

                <ShowFieldData
                  label="Vigência"
                  value={
                    <>
                      {formatDate(
                        record?.parent.classroom?.starts_at,
                        'DD/MM/YYYY'
                      )}{' '}
                      -{' '}
                      {formatDate(
                        record?.parent.classroom?.ends_at,
                        'DD/MM/YYYY'
                      )}
                    </>
                  }
                />
              </>
            )}

            <ShowFieldData label="Nota" value={record?.score || '--'} />
            <ShowFieldData
              label="Frequência"
              value={record?.attendance_rate || 'Sem frequência'}
            />
          </Space>
        )}
      </Show>

      {record && editScoreModal.visible && (
        <AcademicRecordEdit modal={editScoreModal} academicRecord={record} />
      )}

      {record && showLogModal.visible && (
        <AcademicRecordLogModal
          modal={showLogModal}
          academicRecordId={record.id}
        />
      )}
      <UpdateAttendanceModal
        modalClose={closeAttendanceModal}
        modalProps={attendanceModalProps}
        refetch={refetch}
        resourceType="AcademicRecord"
        academicRecords={toBeUpdated}
      />
    </>
  );
};
