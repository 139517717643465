import * as Icons from '@ant-design/icons';
import ContentModal from './ContentModal';
import NodeModal from './NodeModal';
import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { ContentAssessmentModal } from 'components/modal/content-tree/ContentAssessmentModal';
import { CopyToClipboard } from '../CopyToClipboard';
import {
  ICourseRequirement,
  ICourseShow,
  IDataContent,
  IDataNode
} from 'interfaces/courses';
import { useModal } from '@refinedev/core';

interface NodeProps {
  course: ICourseShow;
  node: IDataNode | IDataContent;
  loading: boolean;
  updateNode: (node: IDataNode, requirement?: ICourseRequirement) => void;
  onRemove: (node: IDataNode) => void;
  updateNodeContent: (node: IDataContent) => void;
}

export const TreeNode: React.FC<NodeProps> = ({
  course,
  node,
  loading,
  onRemove,
  updateNode,
  updateNodeContent
}) => {
  const nodeModal = useModal();
  const contentModal = useModal();
  const assessmentModal = useModal();

  const [openModal, setOpenModal] = useState<string | null>(null);
  const [modal, contextHolder] = Modal.useModal();

  const onDelete = (node: IDataNode) => {
    modal.confirm({
      title: 'Excluir item',
      content: (
        <span>
          Deseja realmente excluir o item <strong>{String(node.title)}</strong>?
        </span>
      ),
      okText: 'Excluir',
      cancelText: 'Cancelar',
      onOk: () => onRemove(node)
    }).destroy;
  };

  const onEdit = (node: IDataNode | IDataContent) => {
    const content = node as IDataContent;

    if (!node.isLeaf) {
      setOpenModal('node');
      return nodeModal.show();
    }

    if (content.core_kind === 'assessment') {
      setOpenModal('assessment');
      return assessmentModal.show();
    } else {
      setOpenModal('content');
      contentModal.show();
    }
  };

  return (
    <>
      <div className="flex gap-2 items-center">
        <span>{String(node.title)}</span>
        <>
          <Button
            size="small"
            disabled={loading}
            loading={loading}
            icon={<Icons.EditOutlined />}
            onClick={() => onEdit(node)}
          />
          <Button
            size="small"
            disabled={loading}
            loading={loading}
            icon={<Icons.DeleteOutlined />}
            onClick={() => onDelete(node)}
          />
          {'core_id' in node && node.core_id && (
            <CopyToClipboard
              text={String(node.core_id)}
              tooltipText={'Copiar core_id'}
            />
          )}
          {contextHolder}
        </>

        {openModal === 'node' && (
          <NodeModal
            course={course}
            modal={nodeModal}
            node={node}
            onUpdate={updateNode}
            onCloseModal={() => setOpenModal(null)}
          />
        )}

        {openModal === 'content' && (
          <ContentModal
            course={course}
            modal={contentModal}
            node={node as IDataContent}
            onUpdate={node => {
              updateNodeContent(node);
              setOpenModal(null);
            }}
          />
        )}

        {openModal === 'assessment' && (
          <ContentAssessmentModal
            course={course}
            modal={assessmentModal}
            node={node as IDataContent}
            onClose={() => setOpenModal(null)}
            onCreate={node => {
              updateNodeContent(node);
              setOpenModal(null);
            }}
          />
        )}
      </div>
    </>
  );
};
