import { IClassroom } from 'interfaces/classrooms';
import { Table } from 'antd';
import { TextField } from '@refinedev/antd';

interface ShowTableClassroomProps {
  classrooms: IClassroom[];
}

export const ShowTableClassroom = ({ classrooms }: ShowTableClassroomProps) => {
  return (
    <Table
      dataSource={classrooms}
      rowKey="id"
      className="w-full"
      pagination={false}
    >
      <Table.Column
        dataIndex="discipline"
        key="discipline"
        title="Turma"
        render={value => <TextField value={value.name} />}
      />

      <Table.Column
        dataIndex="code"
        key="code"
        title="Código"
        render={value => <TextField value={value} />}
      />
    </Table>
  );
};
