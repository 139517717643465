import FilterForm from 'components/forms/FilterForm';
import { Col, DatePicker, Form, FormProps, Input } from 'antd';
import { InputMask } from 'components/form-items/InputMask';

export interface ICompaniesFilter {
  by_name: string;
  by_cnpj: string;
  created_after: string;
  created_before: string;
}

interface CompaniesFilterProps {
  searchFormProps: FormProps<ICompaniesFilter>;
}

export const CompaniesFilter = ({ searchFormProps }: CompaniesFilterProps) => {
  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Col xs={24} md={7}>
        <Form.Item label="Empresa" name="by_name">
          <Input placeholder="Empresa..." />
        </Form.Item>
      </Col>

      <Col xs={24} md={5}>
        <Form.Item label="CNPJ" name="by_cnpj">
          <InputMask mask="00.000.000/0000-00" />
        </Form.Item>
      </Col>

      <Col xs={24} md={5}>
        <Form.Item label="Cadastro (a partir de)" name="created_after">
          <DatePicker
            placeholder="dd/mm/aaaa"
            style={{ width: '100%' }}
            format={'DD/MM/YYYY'}
          />
        </Form.Item>
      </Col>

      <Col xs={24} md={5}>
        <Form.Item label="Cadastro (término em)" name="created_before">
          <DatePicker
            placeholder="dd/mm/aaaa"
            style={{ width: '100%' }}
            format={'DD/MM/YYYY'}
          />
        </Form.Item>
      </Col>
    </FilterForm>
  );
};
