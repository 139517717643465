import { IResource } from 'interfaces/resources';
import { Route } from 'react-router-dom';

export interface ResourceParamType {
  name: string;
  label: string;
  list?: React.FC;
  show?: React.FC;
  edit?: React.FC;
  create?: React.FC;
  actions: string[];
  parentName?: string;
}

export const generateResources = (
  resource: IResource
): { resource: IResource; routes: JSX.Element[] } => {
  const {
    name,
    label,
    parentName,
    list,
    show,
    showConfig,
    edit,
    create,
    route,
    ...rest
  } = resource;

  const resourceData: IResource = {
    name,
    label,
    parentName,
    route,
    actions: [],
    ...rest
  };

  const routes = [];

  if (create) {
    const path = route ? `${route}/create` : `${parentName}/${name}/create`;

    if (!resourceData.actions?.includes('create')) {
      (resourceData.actions as string[]).push('create');
    }
    resourceData.create = `/${path}`;
    const CreateElement = create;
    routes.push(
      <Route key={`${name}-create`} path={path} element={<CreateElement />} />
    );
  }

  if (list) {
    const path = route ?? `${parentName}/${name}`;

    if (!resourceData.actions?.includes('list')) {
      (resourceData.actions as string[]).push('list');
    }
    resourceData.list = `/${path}`;
    const ListElement = list;
    routes.push(
      <Route index key={`${name}-list`} path={path} element={<ListElement />} />
    );
  }

  if (show || showConfig) {
    const path = route ? `${route}/show/:id` : `${parentName}/${name}/show/:id`;

    if (!resourceData.actions?.includes('show')) {
      (resourceData.actions as string[]).push('show');
    }
    resourceData.show = `/${showConfig?.path || path}`;
    const ShowElement = show || showConfig!.component;
    routes.push(
      <Route
        key={`${name}-show`}
        path={showConfig?.path || path}
        element={<ShowElement />}
      />
    );
  }

  if (edit) {
    const path = route ? `${route}/edit/:id` : `${parentName}/${name}/edit/:id`;

    if (!resourceData.actions?.includes('edit')) {
      (resourceData.actions as string[]).push('edit');
    }
    resourceData.edit = `/${path}`;
    const EditElement = edit;
    routes.push(
      <Route key={`${name}-edit`} path={path} element={<EditElement />} />
    );
  }

  return { resource: resourceData, routes: routes };
};
