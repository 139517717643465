/* eslint-disable unicorn/filename-case */
export const teachingIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00065 14.6673C11.6826 14.6673 14.6673 11.6826 14.6673 8.00065C14.6673 4.31875 11.6826 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065C1.33398 11.6826 4.31875 14.6673 8.00065 14.6673Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33398 5.97754C6.33398 5.65935 6.33398 5.50025 6.40048 5.41144C6.45843 5.33403 6.54713 5.28561 6.64357 5.27872C6.75424 5.27082 6.88807 5.35685 7.15572 5.52891L10.3028 7.55202C10.535 7.70133 10.6512 7.77598 10.6913 7.87091C10.7263 7.95385 10.7263 8.04745 10.6913 8.13039C10.6512 8.22532 10.535 8.29997 10.3028 8.44928L7.15572 10.4724C6.88807 10.6445 6.75424 10.7305 6.64357 10.7226C6.54713 10.7157 6.45843 10.6673 6.40048 10.5899C6.33398 10.501 6.33398 10.342 6.33398 10.0238V5.97754Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
