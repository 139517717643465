import {
  GraduateModuleCreate,
  GraduateModuleEdit,
  GraduateModuleList,
  GraduateModuleShow
} from 'pages/graduate_courses/graduate_modules';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'graduate_modules',
  label: 'Módulos',
  list: GraduateModuleList,
  show: GraduateModuleShow,
  edit: GraduateModuleEdit,
  create: GraduateModuleCreate,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'course_creation'
};

export default generateResources(resource);
