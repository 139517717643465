import * as Icons from '@ant-design/icons';
import ApproveRefundModal from 'components/orders/refund/ApproveRefundModal';
import CreateRefundModal from 'components/orders/refund/CreateRefundModal';
import RejectRefundModal from 'components/orders/refund/RejectRefundModal';
import { Button, Space, Table } from 'antd';
import { IOrder, IOrderRefundShallow } from 'interfaces/orders';
import { TagField, TextField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { translateStatus } from 'services/refunds';
import { useCan, useModal } from '@refinedev/core';

interface RefundProps {
  order: IOrder;
  onUpdate: () => void;
}

interface ActionsProps {
  refund: IOrderRefundShallow;
  onUpdate: () => void;
}

const Actions = ({ refund, onUpdate }: ActionsProps) => {
  const approveModal = useModal();
  const rejectModal = useModal();
  const { data } = useCan({
    resource: 'order_refunds',
    action: 'approve'
  });

  if (!data) {
    return null;
  }

  const { can } = data;

  if (refund.status !== 'requested') {
    return null;
  }

  return (
    <div className="flex gap-2">
      <Space>
        <Button
          className="!flex items-center"
          onClick={approveModal.show}
          icon={<Icons.CheckOutlined />}
          disabled={!can}
        >
          Aprovar
        </Button>
      </Space>
      <Space>
        <Button
          className="!flex items-center"
          danger
          onClick={rejectModal.show}
          icon={<Icons.CloseOutlined />}
          disabled={!can}
        >
          Rejeitar
        </Button>
      </Space>
      <ApproveRefundModal
        refund={refund}
        modal={approveModal}
        onUpdate={onUpdate}
      />
      <RejectRefundModal
        refund={refund}
        modal={rejectModal}
        onUpdate={onUpdate}
      />
    </div>
  );
};

const RefundTable = ({ order, onUpdate }: RefundProps) => (
  <Table
    dataSource={order.refunds}
    rowKey="id"
    pagination={false}
    bordered
    scroll={{ x: true }}
  >
    <Table.Column
      dataIndex="status"
      key="status"
      title="Status"
      render={status => <TagField value={translateStatus(status)} />}
    />
    RefundTable
    <Table.Column
      dataIndex="created_at"
      key="created_at"
      title="Data de Criação"
      render={createdAt => <TextField value={formatDate(createdAt, 'l')} />}
    />
    <Table.Column
      dataIndex="motive"
      key="motive"
      title="Motivo"
      render={motive => <TextField value={motive} />}
    />
    <Table.Column
      dataIndex="approved_at"
      key="approved_at"
      title="Data de Aprovação"
      render={approvedAt =>
        approvedAt ? (
          <TextField value={formatDate(approvedAt, 'l')} />
        ) : (
          <TextField value="-" />
        )
      }
    />
    <Table.Column
      dataIndex="response_motive"
      key="response_motive"
      title="Motivo resposta"
      render={motive => <TextField value={motive} />}
    />
    <Table.Column<IOrderRefundShallow>
      title="Ações"
      dataIndex="actions"
      render={(_, record) => <Actions refund={record} onUpdate={onUpdate} />}
    />
  </Table>
);

const RefundCreate = ({ order, onUpdate }: RefundProps) => {
  const modal = useModal();

  const liveRefunds = order.refunds.filter(
    refund => !['rejected', 'error'].includes(refund.status)
  );
  const allowed = order.status === 'approved' && liveRefunds.length === 0;

  if (!allowed) {
    return null;
  }

  return (
    <>
      <Button className="w-min" onClick={modal.show}>
        Criar reembolso
      </Button>
      <CreateRefundModal modal={modal} order={order} onUpdate={onUpdate} />
    </>
  );
};

const RefundTableWrapper = ({ order, onUpdate }: RefundProps) => (
  <>
    <RefundTable order={order} onUpdate={onUpdate} />
    <RefundCreate order={order} onUpdate={onUpdate} />
  </>
);

export default RefundTableWrapper;
