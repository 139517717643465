import { OrderNotRefundList } from 'pages/ecommerce/order_not_refunds';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'order_not_refunds',
  label: 'Reembolsos com Erro',
  list: OrderNotRefundList,
  actions: ['list'],
  parentName: 'finance',
  route: 'order_not_refunds'
};

export default generateResources(resource);
