import FilterForm from 'components/forms/FilterForm';
import { Col, Form, Input, Row, Table, Tabs, Typography } from 'antd';
import { CrudFilters, HttpError, useShow } from '@refinedev/core';
import { IAgent } from 'interfaces/agents';
import { IRole } from 'interfaces/roles';
import { Permissions } from './components/permissions';
import { Show, TextField, useTable } from '@refinedev/antd';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

interface IFilter {
  name: string;
  login: string;
}

export const RolesShow = () => {
  const { queryResult } = useShow<IRole>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { id } = useParams();
  const { tableProps, searchFormProps } = useTable<IAgent, HttpError, IFilter>({
    resource: 'agents',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { name, login } = params;

      filters.push(
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_login]',
          operator: 'eq',
          value: login
        }
      );

      return filters;
    },

    filters: {
      permanent: [
        {
          field: 'filter[by_role_id]',
          operator: 'eq',
          value: id
        }
      ]
    }
  });

  return (
    <Show isLoading={isLoading} title="Visualizar perfil">
      <Row gutter={68} style={{ marginBottom: 24 }}>
        <Col>
          <Title level={5}>Nome</Title>
          <Text>{record?.name}</Text>
        </Col>
        <Col>
          <Title level={5}>ID</Title>
          <Text>{record?.id}</Text>
        </Col>
      </Row>

      <Tabs defaultActiveKey="users">
        <Tabs.TabPane tab="Usuários do perfil" key="users">
          <FilterForm searchFormProps={searchFormProps}>
            <Col xs={24} md={8}>
              <Form.Item label="Nome" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item label="Login" name="login">
                <Input />
              </Form.Item>
            </Col>

            {/* <Col xs={24} md={8}> // TODO uncomment this after backend adjustment
              <Form.Item label="E-mail" name="email">
                <Input type="email" />
              </Form.Item>
            </Col> */}
          </FilterForm>

          <Table
            {...tableProps}
            rowKey="id"
            pagination={{ hideOnSinglePage: true }}
          >
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="login"
              key="login"
              title="Login"
              render={value => <TextField value={value} />}
            />

            {/* <Table.Column // TODO uncomment this after backend adjustment
              dataIndex="email"
              key="email"
              title="E-mail"
              render={value => <TextField value={value} />}
            /> */}

            {/* <Table.Column<IAgent> // TODO uncomment this after backend adjustment
              title="Ações"
              align="center"
              width={100}
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <ShowButton
                    resource="users"
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                );
              }}
            /> */}
          </Table>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Regras de acesso" key="permissions">
          <Permissions permissions={record?.permissions || []} disabled />
        </Tabs.TabPane>
      </Tabs>
    </Show>
  );
};
