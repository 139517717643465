import FilterForm from '../../../components/forms/FilterForm';
import dayjs from 'dayjs';
import { Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  DateField,
  EditButton,
  List,
  TextField,
  useTable
} from '@refinedev/antd';
import { ICoupon } from 'interfaces/coupons';
import { STATUSES, translateStatus } from 'services/coupons';
import { replaceTimeZone } from '../../../services/date';

interface IFilter {
  name: string;
  status: string;
  campaignId: string;
  code: string;
  byExpiresAt: dayjs.Dayjs;
}

export const CouponList = () => {
  const { tableProps, searchFormProps } = useTable<ICoupon, HttpError, IFilter>(
    {
      onSearch: params => {
        const filters: CrudFilters = [];
        const { status, code, byExpiresAt } = params;

        filters.push(
          {
            field: 'filter[status]',
            operator: 'eq',
            value: status
          },
          {
            field: 'filter[code]',
            operator: 'eq',
            value: code
          },
          {
            field: 'filter[by_expires_at]',
            operator: 'eq',
            value: byExpiresAt && replaceTimeZone(byExpiresAt)
          }
        );

        return filters;
      }
    }
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Código" name="code">
              <Input placeholder="Código do cupom" />
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Select placeholder="Selecione..." style={{ minWidth: 200 }}>
                {STATUSES.map(status => (
                  <Select.Option key={status}>
                    {translateStatus(status)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              colon
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              label="Expira em"
              name="byExpiresAt"
            >
              <DatePicker
                className="w-full"
                style={{ minWidth: 250 }}
                placeholder="dia/mês/ano"
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="code"
              key="code"
              title="Código"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="description"
              key="description"
              title="Descrição"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={value => <TextField value={translateStatus(value)} />}
            />
            <Table.Column
              dataIndex="expires_at"
              key="expires_at"
              title="Expiração"
              render={value => <DateField value={value} />}
            />
            <Table.Column<ICoupon>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
