import FilterForm from 'components/forms/FilterForm';
import dayjs from 'dayjs';
import { Col, Form, Input, Space, Table, Tag, Typography } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IProductBonus } from 'interfaces/product_bonuses';
import { IUser } from 'interfaces/users';
import { TextField, useTable } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { useCallback } from 'react';

interface IFilter {
  byProduct: string;
}

interface UserBonusesProps {
  userData: IUser;
}

export const UserBonuses = ({ userData }: UserBonusesProps) => {
  const { tableProps, searchFormProps } = useTable<
    IProductBonus,
    HttpError,
    IFilter
  >({
    resource: 'product_bonuses',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { byProduct } = params;

      filters.push(
        {
          field: 'filter[by_user_id]',
          operator: 'eq',
          value: userData.id
        },
        {
          field: 'filter[by_product_name]',
          operator: 'eq',
          value: byProduct
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_user_id]',
          operator: 'eq',
          value: userData.id
        }
      ]
    }
  });

  const status = useCallback((date: string) => {
    if (dayjs(date).isAfter(new Date())) {
      return <Tag color="green">Ativo</Tag>;
    }

    return <Tag color="red">Expirado</Tag>;
  }, []);

  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Typography.Title level={5}>Bônus do aluno</Typography.Title>

      <FilterForm searchFormProps={searchFormProps}>
        <Col xs={24} md={8}>
          <Form.Item label="Produto" name="byProduct">
            <Input placeholder="Nome do produto" />
          </Form.Item>
        </Col>
      </FilterForm>

      <Table {...tableProps}>
        <Table.Column
          title="Produto"
          key="product"
          dataIndex="product"
          render={({ name }) => <TextField value={name} />}
        />

        <Table.Column
          title="Expiração"
          key="expires_at"
          dataIndex="expires_at"
          render={value => (
            <TextField value={formatDate(value, 'DD/MM/YYYY')} />
          )}
        />

        <Table.Column
          title="Motivo"
          key="motive"
          dataIndex="motive"
          render={value => <TextField value={value} />}
        />

        <Table.Column
          title="Status"
          key="expires_at"
          dataIndex="expires_at"
          render={value => status(value)}
        />
      </Table>
    </Space>
  );
};
