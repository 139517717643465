import { Edit, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { IEcommerceDivision } from 'interfaces/ecommerce_divisions';
import { useParams } from 'react-router-dom';

export const EcommerceDivisionEdit = () => {
  const { id } = useParams();

  const { formProps, saveButtonProps, queryResult } =
    useForm<IEcommerceDivision>({
      resource: 'ecommerce/divisions',
      id,
      redirect: false
    });

  const data = queryResult?.data?.data;

  if (!data) {
    return <Edit saveButtonProps={saveButtonProps} />;
  }

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar divisão">
      <Form
        {...formProps}
        initialValues={data}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          label="Código"
          name="code"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Edit>
  );
};
