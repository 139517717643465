/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import * as Icons from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import SupplementaryMaterialForm from '../forms/SupplementaryMaterialForm';
import axios from 'axios';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Progress, // RcFile,
  Row,
  Select,
  Typography,
  Upload
} from 'antd';
import { ContentKind, IDataContent } from 'interfaces/courses';
import { IAudio, IDocument, IVideo } from 'interfaces/libraries';
import { ICoreContent } from 'interfaces/core_content';
import { IDocumentCreate } from 'interfaces/documentation/document';
import { IHyperTextCreate } from 'interfaces/hyper_texts';
import { IPrometheusContent } from 'interfaces/dam_videos';
import { IProviderOption, PROVIDER_OPTIONS } from './content-tree/constants';
import { IVideoCreate } from 'interfaces/videos';
import { LibrarySelect } from 'components/prometheus/LibrarySelect';
import { PrometheusVideoSelect } from '../prometheus/PrometheusVideoSelect';
import { Text } from '@qcx/ui';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  createDocument,
  createDocumentOption,
  createHyperText,
  createHyperTextOption,
  createLTI,
  createLTIOption,
  createPrometheusContent,
  createVideo,
  createVideoOption,
  updateDocument,
  updateHyperText,
  updateLTI,
  updateVideo
} from 'requests/mutation';
import { getAudio, getDocument, getVideo } from 'services/libraries/contents';
import { getPrometheusContent } from 'requests/phometheus';
import { translateOptionStatus } from 'services/contents';
import { useList, useModalReturnType } from '@refinedev/core';

interface Props {
  modal: useModalReturnType;
  node: IDataContent;
  onUpdate: (node: IDataContent) => void;
}

const ContentModal: React.FC<Props> = ({ modal, node, onUpdate }) => {
  const [form] = Form.useForm();

  const [providers, setProviders] = useState(
    PROVIDER_OPTIONS[node.core_kind as ContentKind] || []
  );
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [videoFile, setVideoFile] = useState<UploadFile | null>();
  const [hyperTextFile, setHyperTextFile] = useState<UploadFile | null>();
  const [documentFile, setDocumentFile] = useState<UploadFile | null>();
  const [prometheusContent, setPrometheusContent] =
    useState<IPrometheusContent | null>();
  const [contentsState, setContentsState] = useState<IDataContent>(node);

  const [video, setVideo] = useState<IVideo | null>(null);
  const [audio, setAudio] = useState<IAudio | null>(null);
  const [document, setDocument] = useState<IDocument | null>(null);

  useEffect(() => {
    if (contentsState.core_kind === 'content') {
      getPrometheusContent(contentsState.core_id).then(setPrometheusContent);
    }
  }, [contentsState.core_provider, contentsState.core_reference]);

  useEffect(() => {
    if (prometheusContent?.content_type === 'video') {
      getVideo(prometheusContent.reference).then(video => setVideo(video));
    }

    if (prometheusContent?.content_type === 'audio') {
      getAudio(prometheusContent.reference).then(audio => setAudio(audio));
    }

    if (prometheusContent?.content_type === 'document') {
      getDocument(prometheusContent.reference).then(document =>
        setDocument(document)
      );
    }

    if (prometheusContent?.content_type === 'lti') {
      getDocument(prometheusContent.reference).then(document =>
        setDocument(document)
      );
    }
  }, [prometheusContent]);

  const coreContentResult = useList<ICoreContent>({
    resource: 'core_contents',

    filters: [
      {
        field: 'filter[id][]',
        operator: 'eq',
        value: [node.core_id]
      },

      {
        field: 'kind[]',
        operator: 'eq',
        value: [node.core_kind]
      }
    ]
  });

  if (modal.visible) {
    const cores: ICoreContent[] | undefined = coreContentResult.data?.data;
    const core = cores?.[0];

    if (core && form.getFieldsValue().core_id !== core.id) {
      const initialValues = {
        ...node,
        core_id: core?.id,
        core_reference: core?.option?.reference,
        core_url: core?.option?.url,
        core_status: core?.option?.status,
        core_provider: core?.option?.provider,
        name: node.title
      };

      form.setFieldsValue(initialValues);
    }
  }

  useEffect(() => {
    const coreContentGroupedById: Record<string, ICoreContent> = {};

    if (coreContentResult.data) {
      for (const item of coreContentResult.data?.data) {
        coreContentGroupedById[item.id] = item;
      }
    }

    const core = coreContentGroupedById[node.core_id];

    if (core?.option) {
      node.core_reference = core.option.reference;
      node.core_provider = core.option.provider;
      node.core_url = core.option.url;
      node.core_status = core.option.status;
    }
  }, [coreContentResult]);

  const onClose = () => {
    // form.resetFields();
    modal.close();
  };

  const onSubmit = async () => {
    try {
      setLoading(true);

      if (contentsState.core_kind === 'content') {
        form.setFieldsValue({
          core_provider: 'prometheus_content'
        });
      }

      const {
        name: title,
        core_kind,
        core_reference,
        core_provider
      } = await form.validateFields();

      node.core_kind = core_kind;
      node.core_reference = core_reference;
      node.core_provider = core_provider;

      if (node.core_id) {
        const updatedNode = {
          ...node,
          title: title,
          isLeaf: true
        };

        onUpdate(updatedNode);
        return node;
      }

      if (node.core_kind === 'assessment') {
        node.core_id = node.core_reference;
      }

      switch (node.core_kind) {
        case 'video': {
          const video = node.core_id
            ? await updateVideo(node.core_id, title)
            : await createVideo(title);

          node.core_id = video.id;

          if (videoFile) {
            await uploadVideo({ video, file: videoFile! });
            const option = await createVideoOption(video.id, 'cloudflare');

            node.core_url = option.url;
            node.core_status = option.status;
          }

          if (node.core_provider === 'cloudflare_with_link' || 'prometheus') {
            const option = await createVideoOption(
              video.id,
              node.core_provider,
              node.core_reference
            );

            node.core_url = option.url;
            node.core_status = option.status;
          }

          break;
        }

        case 'hyper_text': {
          const hyper_text = node.core_id
            ? await updateHyperText(node.core_id, title)
            : await createHyperText(title);

          if (hyperTextFile) {
            await uploadHyperText({
              hyper_text: hyper_text,
              file: hyperTextFile!
            });
          }

          const option = await createHyperTextOption(
            hyper_text.id,
            node.core_provider,
            node.core_reference
          );

          node.core_id = hyper_text.id;
          node.core_url = option.url;
          node.core_status = option.status;

          break;
        }

        case 'document': {
          const document = node.core_id
            ? await updateDocument(node.core_id, title)
            : await createDocument(title);

          if (documentFile) {
            await uploadDocument({
              document: document,
              file: documentFile!
            });
          }

          const option = await createDocumentOption(
            document.id,
            node.core_provider,
            node.core_reference
          );

          node.core_id = document.id;
          node.core_url = option.url;
          node.core_status = option.status;

          break;
        }

        case 'content': {
          if (!node.core_id) {
            const content = await createPrometheusContent(
              node.core_reference,
              title
            );
            node.core_id = content.id;
          }

          break;
        }

        case 'lti': {
          const lti = node.core_id
            ? await updateLTI(node.core_id, title)
            : await createLTI(title);

          const option = await createLTIOption(
            lti.id,
            node.core_provider,
            node.core_reference
          );

          node.core_id = lti.id;
          node.core_url = option.url;
          node.core_status = option.status;

          break;
        }
        // No default
      }

      form.setFields([
        { name: ['core_id'], value: node.core_id },
        { name: ['core_url'], value: node.core_url },
        {
          name: ['core_status'],
          value: node.core_status
        }
      ]);

      syncForm();

      const updatedNode = {
        ...node,
        title: title
      };

      onUpdate(updatedNode);
    } finally {
      onClose();
      setUploading(false);
      setUploadPercentage(0);
      setLoading(false);
      setVideoFile(null);
    }
  };

  const isVideoUpload = () =>
    contentsState?.core_kind === 'video' &&
    contentsState?.core_provider === 'cloudflare';
  const isAssessment = () => contentsState?.core_kind === 'assessment';

  const isHyperTextUpload = () => {
    return (
      contentsState?.core_kind === 'hyper_text' &&
      contentsState?.core_provider === 's3'
    );
  };

  const isDocumentUpload = () => {
    return (
      contentsState?.core_kind === 'document' &&
      contentsState?.core_provider === 's3'
    );
  };

  const hasCoreId = (): boolean => !!contentsState?.core_id;

  const isEnabled = (): boolean => contentsState?.core_status === 'enabled';

  const uploadVideo = ({
    video,
    file
  }: {
    video: IVideoCreate;
    file: UploadFile;
  }) => {
    const { url } = video.upload;

    setUploading(true);

    return axios.put(url, file, {
      onUploadProgress: ({ loaded, total }) =>
        setUploadPercentage(Math.round((100 * loaded) / total))
    });
  };

  const uploadDocument = ({
    document,
    file
  }: {
    document: IDocumentCreate;
    file: UploadFile;
  }) => {
    const { url } = document.upload;

    setUploading(true);

    return axios.put(url, file, {
      headers: {
        'Content-Type': 'application/pdf'
      },
      onUploadProgress: ({ loaded, total }) =>
        setUploadPercentage(Math.round((100 * loaded) / total))
    });
  };

  const uploadHyperText = ({
    hyper_text,
    file
  }: {
    hyper_text: IHyperTextCreate;
    file: UploadFile;
  }) => {
    const { url } = hyper_text.upload;

    setUploading(true);

    return axios.put(url, file, {
      onUploadProgress: ({ loaded, total }) =>
        setUploadPercentage(Math.round((100 * loaded) / total))
    });
  };

  const syncForm = () => {
    setContentsState(form.getFieldsValue());
  };

  return (
    <Modal
      title="Editar conteúdo"
      visible={modal.visible}
      width={1000}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item name={['core_id']} style={{ height: 0, margin: 0 }}>
          <Input type="hidden" />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <Form.Item
              name={['name']}
              label="Nome"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item
              name={['core_kind']}
              label="Tipo"
              rules={[{ required: true }]}
            >
              <Select
                disabled={hasCoreId()}
                onChange={value => {
                  setProviders(
                    PROVIDER_OPTIONS[
                      value as 'video' | 'hyper_text' | 'document'
                    ]
                  );
                  syncForm();
                  return value;
                }}
              >
                <Select.Option key="video">Vídeo</Select.Option>
                <Select.Option key="hyper_text">HTML</Select.Option>
                <Select.Option key="document">PDF</Select.Option>
                <Select.Option key="content">Biblioteca</Select.Option>
                <Select.Option key="lti">LTI</Select.Option>
                {/* <Select.Option key="assessment">Simulado</Select.Option> */}
              </Select>
            </Form.Item>
          </Col>

          <Col md={4}>
            <Form.Item
              name="core_provider"
              label="Provedor"
              hidden={isAssessment() || contentsState.core_kind === 'content'}
              rules={[
                {
                  required:
                    !hasCoreId() &&
                    !isAssessment() &&
                    contentsState.core_kind !== 'content'
                }
              ]}
            >
              <Select
                disabled={hasCoreId()}
                onSelect={value => {
                  syncForm();
                  return value;
                }}
              >
                {providers
                  ?.filter((provider: IProviderOption) => !provider.disabled)
                  ?.map((provider: IProviderOption) => (
                    <Select.Option key={provider.value}>
                      {provider.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item
              name={['core_status']}
              label="Status"
              hidden={isAssessment() || contentsState.core_kind === 'content'}
              getValueProps={(value: any) => ({
                value: translateOptionStatus(value)
              })}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name={['core_url']}
              label="URL"
              hidden={!hasCoreId() || !isEnabled()}
            >
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              name={['core_reference_html']}
              required
              hidden={!isVideoUpload() || hasCoreId()}
              label="Upload"
            >
              <Upload
                maxCount={1}
                accept="video/mp4"
                fileList={videoFile! ? [videoFile!] : []}
                onRemove={() => setVideoFile(null)}
                beforeUpload={newFile => {
                  setVideoFile(newFile);

                  return false;
                }}
              >
                <Button className="!flex items-center" disabled={loading}>
                  <Icons.UploadOutlined /> Vídeo (mp4)
                </Button>
              </Upload>
              {uploading && <Progress percent={uploadPercentage} />}
            </Form.Item>
            <Form.Item
              name={['core_reference']}
              required
              hidden={!isHyperTextUpload() || hasCoreId()}
              label="Upload"
            >
              <Upload
                maxCount={1}
                accept="application/zip"
                fileList={hyperTextFile! ? [hyperTextFile!] : []}
                onRemove={() => setHyperTextFile(null)}
                beforeUpload={newFile => {
                  setHyperTextFile(newFile);

                  return false;
                }}
              >
                <Button className="!flex items-center" disabled={loading}>
                  <Icons.UploadOutlined /> Zip File (zip)
                </Button>
              </Upload>
              {uploading && <Progress percent={uploadPercentage} />}
            </Form.Item>

            <Form.Item
              name={['core_reference']}
              required
              hidden={!isDocumentUpload() || hasCoreId()}
              label="Upload"
            >
              <Upload
                maxCount={1}
                accept="application/pdf"
                fileList={documentFile! ? [documentFile!] : []}
                onRemove={() => setDocumentFile(null)}
                beforeUpload={newFile => {
                  setDocumentFile(newFile);

                  return false;
                }}
              >
                <Button className="!flex items-center" disabled={loading}>
                  <Icons.UploadOutlined /> PDF (pdf)
                </Button>
              </Upload>
              {uploading && <Progress percent={uploadPercentage} />}
            </Form.Item>

            <Form.Item
              name={['core_reference']}
              label="Referência"
              rules={[
                {
                  required: !(
                    isHyperTextUpload() ||
                    isVideoUpload() ||
                    isDocumentUpload() ||
                    hasCoreId()
                  )
                }
              ]}
              hidden={
                isHyperTextUpload() ||
                isVideoUpload() ||
                isDocumentUpload() ||
                hasCoreId() ||
                isAssessment() ||
                contentsState.core_provider === 'prometheus' ||
                contentsState.core_kind === 'content' ||
                contentsState.core_kind === 'lti'
              }
            >
              <Input disabled={hasCoreId()} />
            </Form.Item>
            {contentsState.core_provider === 'prometheus' && (
              <Form.Item
                hidden={hasCoreId()}
                name={['core_reference']}
                label="Buscar vídeos"
                rules={[{ required: true }]}
              >
                <PrometheusVideoSelect />
              </Form.Item>
            )}
            {contentsState.core_kind === 'content' &&
              !(
                isHyperTextUpload() ||
                isVideoUpload() ||
                isDocumentUpload() ||
                isAssessment() ||
                hasCoreId() ||
                contentsState.core_provider === 'prometheus'
              ) && (
                <Form.Item
                  hidden={hasCoreId()}
                  name={['core_reference']}
                  label="Biblioteca"
                  rules={[{ required: true }]}
                >
                  <LibrarySelect />
                </Form.Item>
              )}

            {contentsState.core_kind === 'lti' &&
              !(
                isHyperTextUpload() ||
                isVideoUpload() ||
                isDocumentUpload() ||
                isAssessment() ||
                hasCoreId() ||
                contentsState.core_provider === 'prometheus'
              ) && (
                <Form.Item
                  hidden={hasCoreId()}
                  name={['core_reference']}
                  label="LTI URL"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              )}
          </Col>
        </Row>
        <Divider />
      </Form>
      {prometheusContent && (
        <>
          <Typography.Title level={5}>Preview</Typography.Title>
          {prometheusContent?.content_type === 'video' && (
            <>
              {video?.delivery_option &&
                video?.delivery_option.provider.kind === 'aws_s3' && (
                  <video src={video?.delivery_option.delivery_url} controls />
                )}

              {video?.delivery_option &&
                video?.delivery_option.provider.kind ===
                  'cloudflare_stream' && (
                  <iframe
                    src={video?.delivery_option.delivery_url}
                    width="400"
                    height="250"
                  />
                )}

              {!video?.delivery_option && (
                <Text>
                  <Icons.LoadingOutlined />
                  Em processamento
                </Text>
              )}
            </>
          )}

          {prometheusContent?.content_type === 'audio' && (
            <>
              {audio?.delivery_option && (
                <audio src={audio?.delivery_option.delivery_url} controls />
              )}
              {!audio?.delivery_option && (
                <Text>
                  <Icons.LoadingOutlined />
                  Em processamento
                </Text>
              )}
            </>
          )}

          {prometheusContent?.content_type === 'document' && (
            <>
              {document?.delivery_option && (
                <Button
                  href={document?.delivery_option.delivery_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Baixar documento
                </Button>
              )}
              {!document?.delivery_option && (
                <Text>
                  <Icons.LoadingOutlined />
                  Em processamento
                </Text>
              )}
            </>
          )}
          <Divider />
        </>
      )}

      <Typography.Title level={5}>Material Complementar</Typography.Title>

      <SupplementaryMaterialForm
        parent_reference={String(node.key)}
        parent_kind="content"
      />
    </Modal>
  );
};

export default ContentModal;
