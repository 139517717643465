import { IOrderRefund } from 'interfaces/orders';
import { getAPI } from 'requests/api';

const api = getAPI();

interface ActionProps {
  refundId: string;
  motive: string;
}

interface CreateProps {
  orderId: string;
  motive: string;
}

export const create = async ({
  orderId,
  motive
}: CreateProps): Promise<IOrderRefund> => {
  const { data } = await api.post(`/admin/order_refunds`, {
    motive,
    order_id: orderId
  });

  return data;
};

export const approve = async ({
  refundId,
  motive
}: ActionProps): Promise<IOrderRefund> => {
  const { data } = await api.post(`/admin/order_refunds/${refundId}/approve`, {
    motive
  });

  return data;
};

export const reject = async ({
  refundId,
  motive
}: ActionProps): Promise<IOrderRefund> => {
  const { data } = await api.post(`/admin/order_refunds/${refundId}/reject`, {
    motive
  });

  return data;
};
