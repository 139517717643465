import * as React from 'react';

const Logotype = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    fill="currentColor"
    {...props}
  >
    <path d="M696.72,501.1A170,170,0,0,0,540.07,264.88h0a170,170,0,1,0,0,340.06h0a169.41,169.41,0,0,0,99.41-32.07l32.08,32.07,18.5-18.51,20-20L678,534.33A168.56,168.56,0,0,0,696.72,501.1ZM540.07,550.47h0a115.56,115.56,0,0,1,0-231.12h0a115.56,115.56,0,0,1,0,231.12Z" />
    <path d="M655.49,645.09a115.56,115.56,0,0,1-231.12,0H369.9a170,170,0,1,0,340.06,0H655.49Z" />
  </svg>
);

export default Logotype;
