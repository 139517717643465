import { ReactNode } from 'react';
import { Space, Typography } from 'antd';

interface ShowFieldDataProps {
  label: ReactNode;
  value: ReactNode;
}

export const ShowFieldData = ({ label, value }: ShowFieldDataProps) => {
  return (
    <Space direction="vertical" className="w-full whitespace-pre-line">
      <Typography.Text className="font-medium">{label}</Typography.Text>
      <Typography.Text>{value}</Typography.Text>
    </Space>
  );
};
