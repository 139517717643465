import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select, Typography } from 'antd';
import { IProduct } from 'interfaces/products';
import { IProductCategory } from 'interfaces/product_categories';
import {
  KINDS,
  getConfigFields,
  translateKind
} from 'services/offers/campaigns';
import { STATUSES, translateStatus } from 'services/offers';
import { TOfferCampaign } from 'interfaces/offers';
import { formatMoney } from 'services/money';

const { Text, Title } = Typography;

export const OfferCampaignEdit = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<TOfferCampaign>();

  const data = queryResult?.data;

  const { selectProps: productSelectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: queryResult?.data?.data.config.product_ids,

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: productCategoriesSelectProps } =
    useSelect<IProductCategory>({
      resource: 'products/categories',
      optionLabel: 'name',
      optionValue: 'id',
      defaultValue: queryResult?.data?.data.config.category_ids,

      onSearch: value => [
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    });

  if (!data) {
    return <Edit saveButtonProps={saveButtonProps} />;
  }

  const { config, kind } = data.data;
  const configFields = getConfigFields(kind);

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar campanha">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select>
            {STATUSES.map(status => (
              <Select.Option key={status}>
                {translateStatus(status)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="kind"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select disabled>
            {KINDS.map(kind => (
              <Select.Option key={kind}>{translateKind(kind)}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Title level={4}>Configurações</Title>
        {[...configFields].map(([key, configField]) => (
          <>
            <Title level={5}>{configField.name}</Title>
            {configField.input === 'price' && key === 'fixed_price' ? (
              <Text>{config[key] && formatMoney(config[key])}</Text>
            ) : configField.input === 'percentage' ? (
              <Text>{config[key] as number}%</Text>
            ) : configField.input === 'product_ids' ? (
              <Form.Item name={['config', 'product_ids']}>
                <Select
                  {...productSelectProps}
                  disabled={true}
                  mode="multiple"
                />
              </Form.Item>
            ) : configField.input === 'category_ids' ? (
              <Form.Item name={['config', 'category_ids']}>
                <Select
                  {...productCategoriesSelectProps}
                  disabled={true}
                  mode="multiple"
                />
              </Form.Item>
            ) : (
              <Text>
                <>{config[key]}</>
              </Text>
            )}
          </>
        ))}
      </Form>
    </Edit>
  );
};
