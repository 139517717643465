import * as Icons from '@ant-design/icons';
import { ASSESSMENT_KIND_MAP } from 'services/assessments';
import { Col, Row, Space, Tooltip, Typography } from 'antd';
import { IAssessment } from 'interfaces/assessments';

interface ShowTabInfoProps {
  assessment: IAssessment;
}

export const TabInfo = ({ assessment }: ShowTabInfoProps) => {
  const assessmentInfo = [
    { label: 'Nome', value: assessment.name },
    { label: 'Tipo de avaliação', value: ASSESSMENT_KIND_MAP[assessment.kind] },
    {
      label: assessment.parent_type === 'Course' ? 'Curso' : 'Disciplina',
      value: assessment.course_name
    },
    {
      label: 'Duração (minutos)',
      value: assessment.time_in_seconds ? assessment.time_in_seconds / 60 : null
    },
    { label: 'Tentativas', value: assessment.max_attempts },
    { label: 'Peso', value: assessment.weight },
    { label: 'Nota mínima (0-100)', value: assessment.passing_score },
    {
      label: 'Quantidade de questões',
      value: assessment.question_amount,
      tooltip:
        'É necessário ter no mínimo essa quantidade de questões para gerar a prova'
    }
  ];

  return (
    <Row gutter={[16, 24]} className="py-4">
      {assessmentInfo.map(({ label, value, tooltip }, index) => (
        <Col xs={24} md={6} key={index}>
          <Space direction="vertical">
            <Space direction="horizontal">
              <Typography.Text strong>{label}</Typography.Text>
              {tooltip && (
                <Tooltip className="mb-1" title={tooltip}>
                  <Icons.QuestionCircleOutlined />
                </Tooltip>
              )}
            </Space>
            <Typography.Text>{value ?? '--'}</Typography.Text>
          </Space>
        </Col>
      ))}
    </Row>
  );
};
