import { ITestimonial } from 'interfaces/testimonials';
import { Show } from '@refinedev/antd';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const TestimonialShow = () => {
  const { queryResult } = useShow<ITestimonial>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <Show isLoading={isLoading} title="Visualizar depoimento">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Imagem</Title>
      <Text>
        <>{record?.image}</>
      </Text>
      <Title level={5}>Título</Title>
      <Text>{record?.title}</Text>
      <Title level={5}>Subtitulo</Title>
      <Text>{record?.subtitle}</Text>
      <Title level={5}>Descrição</Title>
      <Text>{record?.description}</Text>
    </Show>
  );
};
