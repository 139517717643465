import { Col, Row, Space, Tag, Typography } from 'antd';
import { INoticeBoardShow } from 'interfaces/notice_board';
import { Show } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { ShowTableClassroom } from './components/ShowTableClassroom';
import { ShowTableCourses } from './components/ShowTableCourses';
import { formatDate } from 'services/date';
import { translatePriority } from './list';
import { useShow } from '@refinedev/core';

export const NoticeBoardShow = () => {
  const { queryResult } = useShow<INoticeBoardShow>();
  const result = queryResult.data?.data;

  if (!result) {
    return null;
  }

  return (
    <Show title="Visualizar professor" isLoading={queryResult.isLoading}>
      {!queryResult.isLoading && (
        <Space direction="vertical" size="large" className="w-full">
          <Row gutter={[16, 24]}>
            <Col xs={24} md={6}>
              <ShowFieldData
                label="Data de criação"
                value={formatDate(result?.created_at, 'DD/MM/YYYY')}
              />
            </Col>

            <Col xs={24} md={6}>
              <ShowFieldData
                label="Data de publicação"
                value={formatDate(result?.starts_at, 'DD/MM/YYYY hh:mm')}
              />
            </Col>

            <Col xs={24} md={6}>
              <ShowFieldData
                label="Prioridade"
                value={
                  <Tag
                    color={result.priority === 'regular' ? 'default' : 'error'}
                  >
                    {translatePriority(result.priority)}
                  </Tag>
                }
              />
            </Col>
          </Row>

          <ShowFieldData label="Título" value={result?.title} />
          <ShowFieldData label="Descrição" value={result?.description} />

          {result?.link_url && (
            <Row gutter={[16, 24]}>
              <Col xs={24} md={12}>
                <ShowFieldData label="Link" value={result?.link_url} />
              </Col>

              <Col xs={24} md={12}>
                <ShowFieldData
                  label="Texto do link"
                  value={result?.link_text}
                />
              </Col>
            </Row>
          )}

          <div>
            <Typography.Title level={5} className="block !mb-3">
              Destino
            </Typography.Title>

            {result.kind === 'general' && (
              <Typography.Text>Todos</Typography.Text>
            )}

            {result.kind === 'classroom' && (
              <ShowTableClassroom classrooms={result.classrooms} />
            )}

            {result.kind === 'course' && (
              <ShowTableCourses courses={result.courses} />
            )}

            {result.kind === 'graduate_course' && (
              <ShowTableCourses courses={result.graduate_courses} />
            )}
          </div>
        </Space>
      )}
    </Show>
  );
};
