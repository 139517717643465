import { IFiscal } from 'interfaces/orders';

const STATUS_TRANSLATIONS = new Map([
  ['initial', 'Pedido'],
  ['processing', 'Processando'],
  ['issued', 'Emitido'],
  ['cancelled', 'Cancelado'],
  ['error', 'Erro']
]);

export const translateStatus = (status: IFiscal['status']): string =>
  STATUS_TRANSLATIONS.get(status) || status;
