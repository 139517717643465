import { IPaymentRefund } from 'interfaces/orders';
import { getAPI } from 'requests/api';

const api = getAPI();

interface ActionProps {
  refundId: string;
  motive: string;
}

interface CreateProps {
  hermesPaymentId: string;
  motive: string;
  motiveKind: string;
  motiveReference?: string;
  amount: number;
  orderId: string;
}

export const create = async ({
  hermesPaymentId,
  motive,
  amount,
  motiveKind,
  motiveReference,
  orderId
}: CreateProps): Promise<IPaymentRefund> => {
  const { data } = await api.post(`/admin/order_external_payment_refunds`, {
    order_external_payment_refund: {
      order: {
        order_id: orderId
      },
      motive,
      hermes_payment_id: hermesPaymentId,
      motive_kind: motiveKind,
      motive_reference: motiveReference,
      amount: amount
    }
  });

  return data;
};

export const approve = async ({
  refundId,
  motive
}: ActionProps): Promise<IPaymentRefund> => {
  const { data } = await api.post(
    `/admin/order_external_payment_refunds/${refundId}/approve`,
    { motive }
  );

  return data;
};

export const reject = async ({
  refundId,
  motive
}: ActionProps): Promise<IPaymentRefund> => {
  const { data } = await api.post(
    `/admin/order_external_payment_refunds/${refundId}/reject`,
    { motive }
  );

  return data;
};
