import { Alert, Form, Input } from 'antd';
import { Create, useForm } from '@refinedev/antd';
import { IPersonification } from 'interfaces/personifications';
import { getTenant } from 'services/tenants';
import { useEffect, useState } from 'react';

export const PersonificationCreate = () => {
  const { formProps, saveButtonProps, mutationResult } =
    useForm<IPersonification>();

  const [showInfo, setShowInfo] = useState(false);
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');

  const { lmsUrl } = getTenant();

  useEffect(() => {
    if (mutationResult.data) {
      setShowInfo(true);

      const { token, user } = mutationResult.data.data;
      setDescription(`Você será redirecionado para ${lmsUrl}`);
      setMessage(`Personificando ${user.name} (${user.email})`);
      const url = new URL(`${lmsUrl}/personificar`);

      url.search = new URLSearchParams({ token }).toString();
      setTimeout(() => {
        setShowInfo(false);
        window.open(url, '_blank');
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationResult.data]);

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar simulação de aluno">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>

      {showInfo && (
        <Alert message={message} description={description} type="info" />
      )}
    </Create>
  );
};
