import * as Icons from '@ant-design/icons';
import FileUpload from '../form-items/FileUpload';
import { Button, Form, Input, Spin } from 'antd';
import { ISupplementaryMaterial } from '../../interfaces/supplementary_materials';
import {
  download_url as downloadSupplementaryMaterials,
  list as listSupplementaryMaterials
} from 'requests/supplementary-materials';
import { useCallback, useEffect, useState } from 'react';
import { useCreateMany, useDelete } from '@refinedev/core';
import { useForm } from '@refinedev/antd';

interface Props {
  parent_reference: string;
  parent_kind: string;
}

interface SupplementaryMaterialItem {
  id?: string;
  url: string;
  name: string;
}

const SupplementaryMaterialForm = ({
  parent_reference,
  parent_kind
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [savedValues, setSavedValues] = useState<SupplementaryMaterialItem[]>(
    []
  );
  const { mutateAsync: createSupplementaryMaterial } =
    useCreateMany<ISupplementaryMaterial>();
  const { mutateAsync: deleteSupplementaryMaterial } =
    useDelete<ISupplementaryMaterial>();
  const { form } = useForm();

  const getSavedItems = useCallback(() => {
    listSupplementaryMaterials({
      filter: { parent_reference },
      items: 100
    }).then(res => {
      const values = res.map(({ id, url, title }) => ({
        id,
        name: title,
        url
      }));
      setSavedValues(values);
      setLoading(false);
    });
  }, [parent_reference]);

  useEffect(() => {
    getSavedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent_reference]);

  const handleDelete = async (id: string) => {
    await deleteSupplementaryMaterial({
      resource: 'supplementary_materials',
      id
    });
    getSavedItems();
  };

  const handleDownload = useCallback((id: string) => {
    downloadSupplementaryMaterials(id).then(res => window.open(res.url));
  }, []);

  const handleCreate = async () => {
    const items = await form.validateFields();

    const values =
      (
        items as { supplementary_material: SupplementaryMaterialItem[] }
      ).supplementary_material?.map(item => ({
        title: item.name,
        url: item.url,
        parent_reference,
        parent_kind
      })) || [];

    const res = await createSupplementaryMaterial({
      resource: 'supplementary_materials',
      values,
      errorNotification: () => {
        return {
          message: 'Ocorreu um problema ao tentar anexar o(s) arquivo(s).',
          description: 'Algo deu errado!',
          type: 'error'
        };
      }
    });

    for (const item of res.data) {
      setSavedValues(prev => [...prev, { ...item, name: item.title }]);
    }

    form.resetFields();
  };

  return loading ? (
    <Spin />
  ) : (
    <>
      {savedValues?.map((file, idx) => (
        <div key={idx} className="flex items-center">
          <Form.Item>
            <Input value={file.name} disabled />
          </Form.Item>
          <Icons.DownloadOutlined
            className="ml-6 mb-6"
            onClick={() => handleDownload(file.id!)}
          />
          <Icons.DeleteFilled
            className="ml-6 mb-6"
            onClick={() => handleDelete(file.id!)}
          />
        </div>
      ))}
      <Form form={form}>
        <FileUpload
          name="supplementary_material"
          type="content_supplementary_material"
          accept="application/pdf"
        />
        <Button type="primary" className="mt-4" onClick={handleCreate}>
          {parent_kind === 'classroom'
            ? 'Compartilhar arquivos com a turma'
            : 'Vincular ao conteúdo'}
        </Button>
      </Form>
    </>
  );
};

export default SupplementaryMaterialForm;
