import * as Icons from '@ant-design/icons';
import ChangePasswordModal from '../../modal/ChangePasswordModal';
import React, { useState } from 'react';
import {
  Layout as AntdLayout,
  Avatar,
  Dropdown,
  Menu,
  Space,
  Typography
} from 'antd';
import { IAgent } from 'interfaces/agents';
import { styled } from '@qcx/ui';
import { useGetIdentity, useLogout } from '@refinedev/core';

const { Text } = Typography;

export const Header: React.FC = () => {
  const { data: user } = useGetIdentity<IAgent>();
  const { mutate: logout } = useLogout();
  const [modalVisibility, setModalVisibility] = useState(false);

  return user?.name ? (
    <>
      <AntdLayout.Header
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '0px 24px',
          height: '64px',
          backgroundColor: '#FFF'
        }}
      >
        <Dropdown
          overlayStyle={{ minWidth: 200 }}
          trigger={['click']}
          overlay={
            <Menu>
              <StyledDiv>
                <Text>
                  <b>Nome:</b> {user.name}
                </Text>
                <Text>
                  <b>Login:</b> {user.login}
                </Text>
              </StyledDiv>
              <Menu.Divider />
              <Menu.Item
                icon={<Icons.SafetyOutlined />}
                onClick={() => setModalVisibility(true)}
              >
                Alterar senha
              </Menu.Item>
              <Menu.Item
                icon={<Icons.LoginOutlined />}
                onClick={() => logout()}
              >
                Sair
              </Menu.Item>
            </Menu>
          }
        >
          <StyledSpace>
            <Avatar size={32} icon={<Icons.UserOutlined />} />
            <Text strong>{user.login}</Text>
          </StyledSpace>
        </Dropdown>
      </AntdLayout.Header>
      <ChangePasswordModal
        modalVisibility={modalVisibility}
        changeVisibility={setModalVisibility}
      />
    </>
  ) : null;
};

const StyledDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  py: '$2',
  px: '$4'
});

const StyledSpace = styled(Space, {
  cursor: 'pointer',
  alignItems: 'center'
});
