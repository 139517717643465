import {
  VideoCreate,
  VideoEdit,
  VideoList,
  VideoShow
} from 'pages/prometheus/videos';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'prometheus/videos',
  label: 'Videos',
  list: VideoList,
  edit: VideoEdit,
  create: VideoCreate,
  show: VideoShow,
  actions: ['list', 'show', 'edit', 'create'],
  parentName: 'teaching_resources'
};

export default generateResources(resource);
