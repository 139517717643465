import React, { useCallback, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { IEnrollment } from 'interfaces/enrollments';
import { ResponseError } from 'interfaces/api-error';
import {
  useModalReturnType,
  useNotification,
  useUpdate
} from '@refinedev/core';

interface EnrrolmentObservationModalProps {
  enrrolment: IEnrollment;
  modal: useModalReturnType;
}

export const EnrrolmentObservationModal = ({
  enrrolment,
  modal
}: EnrrolmentObservationModalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { open } = useNotification();

  const { mutateAsync } = useUpdate();

  const onMutate = useCallback(async () => {
    setLoading(true);
    await form
      .validateFields()
      .then(async () => {
        await mutateAsync({
          resource: 'graduate/courses/enrollments',
          id: enrrolment.id,
          values: {
            observation: form.getFieldValue('observation')
          },
          errorNotification: error => {
            const err = error as unknown as ResponseError;

            if (
              'code' in err &&
              err.code === 'classrooms_not_available_error'
            ) {
              return {
                type: 'error',
                description: 'Ops! Algo de errado',
                message: 'Não é possível adicionar a sua observação!'
              };
            }

            return {
              type: 'error',
              description: 'Ops! Algo de errado',
              message: 'Não é possível adicionar a sua observação!'
            };
          },
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Observação adicionada com sucesso'
          })
        });
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: 'Error ao adicionar observação.',
          description: 'Favor conferir todos os campos.'
        });
      })
      .finally(() => {
        setLoading(false);
        form.resetFields();
        modal.close();
      });
  }, [mutateAsync, open, modal, enrrolment.id, form]);

  return (
    <Modal
      title="Observações do histórico escolar"
      visible={modal.visible}
      onCancel={modal.close}
      destroyOnClose
      footer={[
        <Button key="back" onClick={() => modal.close()} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onMutate}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item
          name="observation"
          initialValue={enrrolment.observation}
          rules={[
            {
              max: 450,
              message: 'A observação deve possuir no máximo 450 caracteres.'
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
