import { Col, Form, FormProps, Input, Row } from 'antd';
import { ICompany } from 'interfaces/companies';
import { InputMask } from 'components/form-items/InputMask';

interface CompanyFormProps {
  formProps: FormProps<ICompany>;
}

export const CompanyForm = ({ formProps }: CompanyFormProps) => {
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label="Nome da empresa"
        name="name"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Form.Item
            label="CNPJ"
            name="cnpj"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputMask mask="00.000.000/0000-00" />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="Inscrição municipal" name="city_registration">
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="Inscrição estadual" name="state_registration">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={6}>
          <Form.Item
            label="CEP"
            name="zipcode"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputMask mask="00.000-000" />
          </Form.Item>
        </Col>

        <Col xs={24} md={18}>
          <Form.Item label="Endereço" name="address">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Form.Item
            label="Nome do responsável"
            name="responsible_name"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item
            label="E-mail do responsável"
            name="responsible_email"
            rules={[
              {
                type: 'email',
                message: 'Email incorreto'
              }
            ]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="Telefone do responsável" name="phone_number">
            <InputMask mask="(00) [0]0000-0000" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Observações" name="notes">
        <Input.TextArea rows={4} />
      </Form.Item>
    </Form>
  );
};
