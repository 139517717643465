import { RightOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';

export const InteractiveClassItem = () => {
  return (
    <Space className="group w-full p-4 hover:cursor-pointer hover:bg-neutral-100 hover:rounded-lg items-center justify-between transition">
      <Space>
        <div className="flex flex-col bg-neutral-100 rounded-lg p-2 text-center">
          <Typography.Text className="!text-sm">12</Typography.Text>
          <Typography.Text className="!text-xs">MAR</Typography.Text>
        </div>

        <div className="flex-1 flex flex-col">
          <Typography.Text className="!text-md">
            Origem e Evolução do Direito do Trabalho
          </Typography.Text>
          <Typography.Text className="!text-neutral-500">
            12/03/2024 13:00
          </Typography.Text>
        </div>
      </Space>

      <RightOutlined className="opacity-0 group-hover:opacity-100 transition" />
    </Space>
  );
};
