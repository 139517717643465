import AssetUpload from 'components/AssetUpload';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { ITestimonial } from 'interfaces/testimonials';

export const TestimonialCreate = () => {
  const { formProps, saveButtonProps } = useForm<ITestimonial>();

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar depoimento">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Imagem para depoimento"
          name="image"
          rules={[
            {
              required: false
            }
          ]}
        >
          <AssetUpload accept="image/png, image/jpeg" type="testimonial" />
        </Form.Item>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Subtítulo"
          name="subtitle"
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Create>
  );
};
