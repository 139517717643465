import { tagColorByStatus } from '../utils/statusColorMapping';

const STATUS_TRANSLATIONS = new Map<string, string>([
  ['pending', 'Pendente'],
  ['submitted', 'Submetido'],
  ['approved', 'Aprovado'],
  ['rejected', 'Rejeitado'],
  ['canceled', 'Cancelado'],
  ['validating', 'Validando']
]);

const DOCUMENTATION_CATEGORIES = new Map<string, string>([
  ['academic', 'Acadêmico'],
  ['identification', 'Identificação']
]);

const EVENTS_TRANSLATIONS = new Map<string, string>([
  ['create', 'Criado'],
  ['update', 'Atualizado'],
  ['delete', 'Deletado']
]);

export const translateEventStatus = (status: string): string =>
  EVENTS_TRANSLATIONS.get(status) || status;

export const translateDocumentationStatus = (status: string): string =>
  STATUS_TRANSLATIONS.get(status) || status;

export const getStatusColor = (status: string): string =>
  tagColorByStatus(status);

export const translateDocumentationCategories = (status: string): string =>
  DOCUMENTATION_CATEGORIES.get(status) || status;
