import { Collapse, Empty, Spin, Table, Typography } from 'antd';
import {
  CurriculumGridData,
  fetchCurriculumGridData
} from 'services/graduate-enrollment/curriculum-grid';
import { IAcademicRecord } from 'interfaces/academic_records';
import { TagField, TextField } from '@refinedev/antd';
import { convertMinutesToHoursAndMinutes } from 'utils/convert-minutes-hours-minutes';
import { disciplineModulesKind } from 'pages/graduate_courses/components/TabDisciplines';
import { useCallback, useEffect, useState } from 'react';
import { useNotification } from '@refinedev/core';

interface CurriculumGridProps {
  enrollmentId: string;
  courseId: string;
}

export const TabCurriculumGrid = ({
  courseId,
  enrollmentId
}: CurriculumGridProps) => {
  const [data, setData] = useState<CurriculumGridData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { open } = useNotification();

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchCurriculumGridData(courseId, enrollmentId);
      setData(res);
    } catch {
      open?.({
        type: 'error',
        message: 'Erro ao recuperar informações da grade curriculuar.',
        description: 'Ops!'
      });
    } finally {
      setIsLoading(false);
    }
  }, [courseId, enrollmentId, open]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-16">
        <Spin />
      </div>
    );
  }

  if (!isLoading && data.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Nenhuma grade curricular encontrada"
      />
    );
  }

  return (
    <Collapse defaultActiveKey={['1']}>
      {data?.map((module, index) => (
        <Collapse.Panel
          key={index}
          header={<Typography.Text strong>{module.name}</Typography.Text>}
        >
          <Table
            dataSource={module.disciplines}
            className="w-full"
            size="small"
            style={{ borderRadius: 10, overflow: 'hidden' }}
            pagination={false}
          >
            <Table.Column
              title="Disciplina"
              dataIndex="name"
              key="name"
              className="min-w-[350px] max-w-[350px]"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              title="Tipo"
              dataIndex="disciplineModuleKind"
              key="disciplineModuleKind"
              className="min-w-[100px] max-w-[100px]"
              render={value => (
                <TextField
                  value={
                    disciplineModulesKind[
                      value as keyof typeof disciplineModulesKind
                    ]
                  }
                />
              )}
            />
            <Table.Column
              title="Carga horária"
              dataIndex="duration_in_minutes"
              key="duration_in_minutes"
              className="min-w-[150px] max-w-[150px]"
              render={value => (
                <TextField value={convertMinutesToHoursAndMinutes(value)} />
              )}
            />
            <Table.Column
              title="Status"
              dataIndex="academicRecordStatus"
              key="academicRecordStatus"
              className="w-[150px]"
              render={(value: IAcademicRecord['status']) => (
                <TagField
                  color={value === 'approved' ? 'success' : 'default'}
                  value={value === 'approved' ? 'Aprovado' : 'Não concluído'}
                />
              )}
            />
          </Table>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};
