import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IProduct } from 'interfaces/products';
import { IProductSet } from 'interfaces/product_sets';

export const ProductSetEdit = () => {
  const { formProps, saveButtonProps } = useForm<IProductSet>({
    redirect: 'edit'
  });

  const { selectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar set">
      <Form {...formProps} layout="vertical">
        <Form.Item label="Nome" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Produtos" name="product_ids">
          <Select {...selectProps} allowClear mode="multiple" />
        </Form.Item>
      </Form>
    </Edit>
  );
};
