import * as Icons from '@ant-design/icons';
import RuleConfig from 'components/offers/gateways/Config';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Typography,
  Upload
} from 'antd';
import { Create, TextField, useForm, useSelect } from '@refinedev/antd';
import { IFiscalTemplate } from 'interfaces/fiscals';
import {
  IGateway,
  IGatewayRangeTable,
  IGatewayRule
} from 'interfaces/gateways';
import {
  RULE_KINDS,
  parseRangeFile,
  translateRuleKind
} from 'services/gateways';
import { useState } from 'react';

const { Title } = Typography;

interface RangeTableProps {
  rangeList: IGatewayRangeTable[];
}

const RangeTable = ({ rangeList }: RangeTableProps) => (
  <Table dataSource={rangeList} pagination={{ pageSize: 5 }}>
    <Table.Column
      dataIndex="name"
      key="name"
      title="Nome"
      render={value => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="value_start"
      key="value_start"
      title="Início"
      render={value => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="value_end"
      key="value_end"
      title="Fim"
      render={value => <TextField value={value} />}
    />
    <Table.Column
      dataIndex="config"
      key="config"
      title="Configuração"
      render={config => <RuleConfig config={config} />}
    />
  </Table>
);

export const GatewayRuleCreate = () => {
  const [kindKey, setKindKey] = useState(null);
  const [rangeList, setRangeList] = useState<IGatewayRangeTable[]>([]);
  const { formProps, saveButtonProps } = useForm<IGatewayRule>();
  const { selectProps: gatewaySelectProps } = useSelect<IGateway>({
    resource: 'hermes/gateways',
    optionLabel: 'name',
    optionValue: 'id',

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: fiscalTemplateSelectProps } = useSelect<IFiscalTemplate>(
    {
      resource: 'fiscal_templates',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onFinish = (values: {}) => {
    const range_table = kindKey === 'zip_code' ? rangeList : [];

    formProps.onFinish!({ ...values, range_table });
  };

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar regras de gateway">
      <Form {...formProps} layout="horizontal" onFinish={onFinish}>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="kind"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select onChange={setKindKey}>
            {RULE_KINDS.map(kind => (
              <Select.Option key={kind}>
                {translateRuleKind(kind)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Gateway"
          name={['config', 'gateway_id']}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...gatewaySelectProps} />
        </Form.Item>

        <Form.Item
          label="Fiscal Template"
          name={['config', 'fiscal_template_id']}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...fiscalTemplateSelectProps} />
        </Form.Item>

        <Title level={5}>Taxa de Marketplace</Title>

        <Form.Item name={['config', 'marketplace_fee', 'amount', 'cents']}>
          <InputNumber
            addonBefore="R$"
            formatter={value => {
              const string = `${value}`;

              switch (string.length) {
                case 0: {
                  return '';
                }

                case 1: {
                  return `0,0${string}`;
                }

                case 2: {
                  return `0,${string}`;
                }

                default: {
                  return string.replace(/\B(?=(\d{2})(?!\d))/g, ',');
                }
              }
            }}
            parser={value => value!.replace(',', '')}
          />
        </Form.Item>

        <Form.Item name={['config', 'marketplace_fee', 'percentage']}>
          <InputNumber addonAfter="%" min={0} max={100} />
        </Form.Item>

        {kindKey === 'zip_code' && (
          <>
            <Title level={4}>Tabela de CEP</Title>
            <Form.Item>
              <Upload
                accept="application/json"
                fileList={[]}
                onRemove={() => setRangeList([])}
                beforeUpload={async newFile => {
                  const rows = await parseRangeFile(newFile);

                  setRangeList(rows);

                  return false;
                }}
              >
                <Button icon={<Icons.UploadOutlined />}>
                  Escolher arquivo
                </Button>
              </Upload>
            </Form.Item>
            <RangeTable rangeList={rangeList} />
          </>
        )}
      </Form>
    </Create>
  );
};
