import React from 'react';
import { Layout as AntdLayout } from 'antd';
import { LayoutProps } from '@refinedev/core';
import { ThemedLayoutV2 } from '@refinedev/antd';

export const Layout: React.FC<LayoutProps> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea
}) => {
  return (
    <ThemedLayoutV2
      Sider={Sider}
      Header={Header}
      Footer={Footer}
      OffLayoutArea={OffLayoutArea}
    >
      <AntdLayout.Content>{children}</AntdLayout.Content>
    </ThemedLayoutV2>
  );
};
