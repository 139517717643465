import FilterForm from '../../../components/forms/FilterForm';
import {
  Alert,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip
} from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IOrder, IOrderItem, IOrderRefund } from 'interfaces/orders';
import {
  List,
  ShowButton,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { formatDate } from 'services/date';
import { translateStatus } from 'services/refunds';

interface IFilter {
  status: string;
  order_number: number;
}

export const OrderRefundList = () => {
  const { tableProps, searchFormProps } = useTable<IOrder, HttpError, IFilter>({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { status, order_number } = params;

      filters.push(
        {
          field: 'status',
          operator: 'eq',
          value: status
        },
        {
          field: 'order_number',
          operator: 'eq',
          value: order_number
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'status',
          operator: 'eq',
          value: 'requested'
        }
      ]
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Tooltip title="O tempo de processamento de novas tentativas de reembolso é 24h">
              <Alert
                message="Tentativas de reembolso no valor cheio do pedido"
                type="info"
                showIcon
              />
            </Tooltip>
            <FilterForm searchFormProps={searchFormProps}>
              <Form.Item label="Status" name="status">
                <Select
                  placeholder="Selecione..."
                  style={{ minWidth: 200 }}
                  defaultValue="requested"
                >
                  <Select.Option key="requested">Solicitado</Select.Option>
                  <Select.Option key="approved">Aprovado</Select.Option>
                  <Select.Option key="rejected">Rejeitado</Select.Option>
                  <Select.Option key="processing">Processando</Select.Option>
                  <Select.Option key="processed">Processado</Select.Option>
                  <Select.Option key="error">Erro</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Número do pedido" name="order_number">
                <Input placeholder="Digite aqui o número do pedido" />
              </Form.Item>
            </FilterForm>
          </Space>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="order"
              key="order_number"
              title="N. do pedido"
              render={({ order_number }) => (
                <TagField key={order_number} value={order_number} />
              )}
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={status => <TagField value={translateStatus(status)} />}
            />
            <Table.Column
              dataIndex="order"
              key="items"
              title="Items"
              render={({ items }) =>
                items.map((item: IOrderItem) => (
                  <TagField key={item.id} value={translateStatus(item.name)} />
                ))
              }
            />
            <Table.Column
              dataIndex="motive"
              key="motive"
              title="Motivo"
              render={motive => <TextField value={motive} />}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Criação"
              render={createdAt => (
                <TextField value={formatDate(createdAt, 'DD/MM/YY HH:mm')} />
              )}
            />
            <Table.Column<IOrderRefund>
              title="Actions"
              dataIndex="actions"
              align="center"
              render={(_, record) => (
                <Space>
                  <ShowButton
                    hideText
                    size="large"
                    resource="orders"
                    recordItemId={record.order.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
