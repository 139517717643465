import TagTree from 'components/epimetheus/groups/TagTree';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { HttpError } from '@refinedev/core';
import { IGroup } from 'interfaces/epimetheus/groups';
import { useParams } from 'react-router-dom';

export const TagGroupEdit = () => {
  const { id, libraryId } = useParams();
  const { formProps, saveButtonProps } = useForm<IGroup, HttpError, IGroup>({
    resource: `prometheus/v2/libraries/${libraryId}/tag_groups`,
    action: 'edit',
    redirect: false,
    id: id
  });

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar gerenciador de tag">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>

      <TagTree groupId={id as string} libraryId={libraryId as string} />
    </Edit>
  );
};
