/* eslint-disable unicorn/filename-case */
import { useList } from '@refinedev/core';

interface UseDisciplineModulesProps {
  filters: {
    by_course?: string;
  };
}

export const useDisciplineModules = (props: UseDisciplineModulesProps) => {
  return useList({
    resource: `graduate/disciplines_modules`,
    config: {
      filters: [
        {
          field: 'filter[by_course]',
          operator: 'eq',
          value: props.filters.by_course
        }
      ]
    },
    errorNotification: () => ({
      type: 'error',
      description: 'Ops! Algo deu errado',
      message:
        'Não foi possível carregar os dados da disciplina. Tente novamente'
    })
  });
};
