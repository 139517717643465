import { Button, Form, Modal, Select } from 'antd';
import { IUser } from 'interfaces/users';
import { useMemo } from 'react';
import { useModalReturnType } from '@refinedev/core';
import { useSelect } from '@refinedev/antd';

interface UsersModalProps {
  modal: useModalReturnType;
  selectedUsers?: string[];
  isLoading?: boolean;
  onSubmit: (userId: string) => Promise<unknown>;
}

export const UsersModal = ({
  modal,
  isLoading,
  selectedUsers,
  onSubmit
}: UsersModalProps) => {
  const [form] = Form.useForm();

  const { selectProps: usersSelectProps, queryResult } = useSelect<IUser>({
    resource: 'users',
    optionLabel: 'name',
    optionValue: 'id',
    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const searchLoading = useMemo(() => {
    return queryResult.isFetching;
  }, [queryResult.isFetching]);

  const optionsWithoutSelectedUsers = useMemo(() => {
    if (selectedUsers && selectedUsers.length > 0) {
      return queryResult.data?.data.filter(d => !selectedUsers?.includes(d.id));
    }
    return queryResult.data?.data;
  }, [queryResult.data?.data, selectedUsers]);

  const options = useMemo(() => {
    return optionsWithoutSelectedUsers?.map(user => ({
      label: user.name,
      value: user.id,
      email: user.email
    }));
  }, [optionsWithoutSelectedUsers]);

  const onFinish = async () => {
    const { userId } = await form.validateFields();
    await onSubmit(userId);
    modal.close();
  };

  return (
    <Modal
      title="Adicionar aluno"
      open={modal.visible}
      width={420}
      onCancel={() => modal.close()}
      footer={[
        <Button key="back" onClick={() => modal.close()}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={form.submit}
        >
          Adicionar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false} onFinish={onFinish}>
        <Form.Item
          label="Selecionar aluno"
          name="userId"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select
            {...usersSelectProps}
            className="w-full"
            placeholder="Selecionar..."
            options={options}
            loading={isLoading || searchLoading}
            labelRender={label => <>{label.label}</>}
            optionRender={option => (
              <div className="flex flex-col">
                <span>{option.data?.label}</span>
                <small className="text-gray-500">{option.data?.email}</small>
              </div>
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
