import FilterForm from 'components/forms/FilterForm';
import { Col, Form, FormProps, Input, Row } from 'antd';

export interface IShowAssignmentFilter {
  search_name: string;
  search_email: string;
}

interface ShowFilterFormProps {
  searchFormProps: FormProps<IShowAssignmentFilter>;
}

export const ShowAssignmentsFilter = ({
  searchFormProps
}: ShowFilterFormProps) => {
  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={24} className="w-full">
        <Col xs={24} md={12}>
          <Form.Item label="Aluno" name="search_name">
            <Input placeholder="Insira o nome do aluno" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="E-mail" name="search_email">
            <Input placeholder="Insira o e-mail do aluno" />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
