import { DeleteButton, EditButton, ShowButton, TextField } from '@refinedev/antd';
import { IClassroom } from 'interfaces/classrooms';
import { IInteractiveClass } from 'interfaces/interactive_classes';
import { IProfessor } from 'interfaces/professor';
import { Space, Table, TableProps, Tag } from 'antd';
import { formatDate } from 'services/date';

interface ListTableProps {
  tableProps: TableProps<IInteractiveClass>;
}

export const ListTable = ({ tableProps }: ListTableProps) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'created': {
        return 'grey';
      }

      case 'started': {
        return 'green';
      }

      case 'finished': {
        return 'grey';
      }

      case 'cancelled': {
        return 'red';
      }

      default: {
        return 'default';
      }
    }
  };

  const translateStatus = (status: string) => {
    switch (status) {
      case 'created': {
        return 'Criado';
      }

      case 'started': {
        return 'Iniciado';
      }

      case 'finished': {
        return 'Finalizado';
      }

      case 'cancelled': {
        return 'Cancelado';
      }

      default: {
        return status;
      }
    }
  };

  const formatEllipsis = (text: string, maxSize: number) => {
    if (text.length > maxSize) {
      return text.slice(0, maxSize) + '...';
    }
    return text;
  };

  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="id"
        key="id"
        title="ID da reunião"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="title"
        key="title"
        title="Título"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="professors"
        key="professors"
        title="Professor"
        render={professors => (
          <TextField
            value={formatEllipsis(
              professors
                .map((professor: IProfessor) => professor.name)
                .join(', '),
              30
            )}
          />
        )}
      />
      <Table.Column
        dataIndex="classrooms"
        key="classrooms"
        title="Turmas"
        render={classrooms => (
          <TextField
            value={formatEllipsis(
              classrooms
                .map(
                  (classroom: IClassroom) =>
                    classroom.discipline.name + ' - ' + classroom.code
                )
                .join(', '),
              30
            )}
          />
        )}
      />
      <Table.Column
        dataIndex="start_time"
        key="start_time"
        title="Data"
        render={value => <TextField value={formatDate(value)} />}
      />
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <Tag color={getStatusColor(value)}>{translateStatus(value)}</Tag>
        )}
      />
      <Table.Column<IInteractiveClass>
        title="Ações"
        dataIndex="actions"
        render={(_, record) => {
          return (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />

              {['created'].includes(record.status) && (
                <>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                    successNotification={{
                      type: 'success',
                      description: 'Sucesso!',
                      message: 'Aula interativa cancelada com sucesso.'
                    }}
                  />
                </>
              )}
            </Space>
          );
        }}
      />
    </Table>
  );
};
