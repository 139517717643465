/* eslint-disable unicorn/filename-case */
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { useEffect, useState } from 'react';
import { useSelect } from '@refinedev/antd';

function useSelectCourseByType(
  defaultType?: 'Course' | 'Graduate::Discipline',
  graduateResource?: 'graduate_courses' | 'graduate_disciplines',
  defaultValue?: string
) {
  const [selectedCourseType, setSelectedCourseType] = useState(
    defaultType || 'Course'
  );

  useEffect(() => {
    if (defaultType) {
      setSelectedCourseType(defaultType);
    }
  }, [defaultType]);

  const { selectProps } = useSelect<IGraduateCourse | IGraduateDiscipline>({
    resource:
      selectedCourseType === 'Course'
        ? 'courses'
        : graduateResource ?? 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue,

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  // const { selectProps: courseSelectProps } = useSelect<ICourse>({
  //   resource: 'courses',
  //   optionLabel: 'name',
  //   optionValue: 'id',

  //   onSearch: value => [
  //     {
  //       field: 'filter[name]',
  //       operator: 'eq',
  //       value
  //     }
  //   ],

  //   filters: [
  //     {
  //       field: 'by_id',
  //       operator: 'eq',
  //       value: defaultValue
  //     }
  //   ],

  //   pagination: {
  //     mode: 'server'
  //   }
  // });

  // const selectProps = useMemo(() => {
  //   return selectedCourseType === 'Course'
  //     ? courseSelectProps
  //     : disciplineSelectProps;
  // }, [courseSelectProps, disciplineSelectProps, selectedCourseType]);

  return {
    selectedCourseType,
    setSelectedCourseType,
    selectProps
  };
}

export default useSelectCourseByType;
