import { EditButton, ShowButton, TextField } from '@refinedev/antd';
import { IUser } from 'interfaces/users';
import { Space, Table, TableProps } from 'antd';
import { formatDate } from 'services/date';
import { getTenant } from 'services/tenants';

interface UsersListTableProps {
  tableProps: TableProps<IUser>;
}

export const UsersListTable = ({ tableProps }: UsersListTableProps) => {
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="name"
        key="name"
        title="Nome"
        render={value => <TextField value={value} />}
      />

      <Table.Column
        dataIndex="email"
        key="email"
        title="E-mail"
        render={value => <TextField value={value} />}
      />

      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Data de registro"
        render={value => <TextField value={formatDate(value)} />}
      />
      <Table.Column
        dataIndex={['personal_info', 'document_info']}
        key="document_info"
        title="CPF"
        render={value => <TextField value={value ?? 'Não informado'} />}
      />
      {getTenant().name === 'Estácio' && (
        <Table.Column
          dataIndex="estacio_id"
          key="estacio_id"
          title="ID externo"
          render={value => <TextField value={value ?? 'Não informado'} />}
        />
      )}
      <Table.Column<IUser>
        title="Ações"
        dataIndex="actions"
        align="center"
        render={(_, record) => {
          return (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          );
        }}
      />
    </Table>
  );
};
