import { Button, Form, Input, Modal } from 'antd';
import { IOrder } from 'interfaces/orders';
import { create } from 'requests/refunds';
import { useModalReturnType } from '@refinedev/core';
import { useState } from 'react';

interface CreateRefundModalProps {
  modal: useModalReturnType;
  order: IOrder;
  onUpdate: () => void;
}

const CreateRefundModal = ({
  modal,
  order,
  onUpdate
}: CreateRefundModalProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = async ({ motive }: { motive: string }) => {
    setLoading(true);

    try {
      await create({ orderId: order.id, motive });
      modal.close();
      onUpdate();
    } catch {
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const onClick = async () => {
    const { motive } = await form.validateFields();

    onSubmit({ motive });
  };

  return (
    <Modal
      title="Criar Reembolso"
      visible={modal.visible}
      onCancel={modal.close}
      footer={[
        <Button key="back" onClick={modal.close}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onClick}>
          Criar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item label="Motivo" name="motive" required>
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateRefundModal;
