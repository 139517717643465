import React from 'react';
import { Button, Modal } from 'antd';
import { useModalReturnType, useUpdate } from '@refinedev/core';

interface Props {
  documentationId: string | undefined;
  modal: useModalReturnType;
}

export const ModalRejectDocumentation: React.FC<Props> = ({
  documentationId,
  modal
}) => {
  const { mutateAsync: rejectDocumentation } = useUpdate();

  const handleReject = () => {
    if (!documentationId) {
      return;
    }

    rejectDocumentation({
      resource: 'documentations',
      id: documentationId,
      values: { status: 'rejected' },
      errorNotification: {
        type: 'error',
        message: 'Ocorreu algum erro interno no serviço',
        description: 'Erro ao rejeitar documentação!'
      },
      successNotification: {
        type: 'success',
        description: 'Documentação rejeitada',
        message: `A documentação foi rejeitada com sucesso`
      }
    })
      .then(() => {
        modal.close();
      })
      .catch(() => null);
  };
  return (
    <Modal
      visible={modal.visible}
      onCancel={modal.close}
      footer={[
        <Button key="cancel" onClick={modal.close}>
          Não
        </Button>,
        <Button key="reject" danger onClick={handleReject}>
          Rejeitar
        </Button>
      ]}
    >
      Rejeitar documentação?
    </Modal>
  );
};
