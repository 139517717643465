import TagTree from 'components/epimetheus/tags/TagTree';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { ITag } from 'interfaces/epimetheus/tags';
import { useParams } from 'react-router-dom';

export const TagEdit = () => {
  const { libraryId, id } = useParams();
  const { formProps, saveButtonProps, query } = useForm<ITag>({
    resource: `prometheus/v2/libraries/${libraryId}/tags`,
    action: 'edit',
    id: id!
  });

  const tag = query?.data?.data;

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar tag">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>

      {tag && <TagTree tag={tag} libraryId={libraryId as string} />}
    </Edit>
  );
};
