/* eslint-disable unicorn/filename-case */
import {
  IDisciplineModule,
  IGraduateDisciplineModule
} from 'interfaces/graduate_discipline_module';

export interface IModuleWithDiscipline {
  id: string;
  name: string;
  disciplines: IDisciplineModule[];
}

export function modulesWithDisciplines(
  disciplineModules: IGraduateDisciplineModule[],
  targetCourseId: string
) {
  return disciplineModules.reduce(
    (result: Record<string, IModuleWithDiscipline>, disciplineModule) => {
      if (disciplineModule.module.course_id !== targetCourseId) {
        return result;
      }
      const module = disciplineModule.module;

      if (!result[module.id]) {
        result[module.id] = {
          id: module.id,
          name: module.name,
          disciplines: []
        };
      }

      result[module.id].disciplines.push({
        name: disciplineModule.discipline.name,
        id: disciplineModule.discipline.id,
        external_reference: disciplineModule.discipline.external_reference,
        duration_in_minutes: disciplineModule.discipline.duration_in_minutes,
        disciplineModuleId: disciplineModule.id,
        disciplineModuleKind: disciplineModule.kind
      });

      return result;
    },
    {}
  );
}
