import * as Icons from '@ant-design/icons';
import LogsDocumentationModal from '../../../components/modal/documentations/LogsDocumentationModal';
import LogsModal from '../../../components/modal/documents/LogsModal';
import React, { useState } from 'react';
import {
  Button,
  Divider,
  Space,
  Table,
  TableColumnProps,
  Typography
} from 'antd';
import { IDocument } from 'interfaces/documentation/document';
import { IDocumentation } from 'interfaces/documentation/documentations';
import { Show, TagField, TextField } from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import {
  translateDocumentationCategories,
  translateDocumentationStatus
} from 'services/documentations';
import { useCreate, useModal, useShow } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

export const DocumentationShow = () => {
  const { id } = useParams();
  const { mutateAsync } = useCreate();

  const { queryResult } = useShow<IDocumentation>({
    resource: 'documentations',
    id
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;
  const modal = useModal();
  const documentationModal = useModal();

  const columns: TableColumnProps<IDocument>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Nome',
      dataIndex: 'file_name',
      key: 'file_name'
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
      key: 'category',
      render: (category: string) => (
        <TagField value={translateDocumentationCategories(category)} />
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <TagField value={translateDocumentationStatus(status)} />
      )
    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback'
    },
    {
      title: 'Data de criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) => (
        <TextField value={formatDate(created_at, 'lll')} />
      )
    },
    {
      title: 'Alterações',
      key: 'action',
      align: 'center',
      render: (text: string, document: IDocument) => (
        <Button
          icon={<Icons.EyeOutlined />}
          size="small"
          onClick={() => handleViewClick(document.id)}
        />
      )
    }
  ];

  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null
  );

  const handleViewClick = (documentId: string) => {
    setSelectedDocumentId(documentId);
    modal.show();
  };

  const handleDocumentationViewClick = () => {
    documentationModal.show();
  };

  const refreshDocumentation = async () => {
    return await mutateAsync({
      resource: `documentations/${id}/refresh`,
      values: {},
      successNotification: () => ({
        type: 'success',
        description: 'Sucesso!',
        message: 'Documentação adicionada para reavaliação!'
      }),
      errorNotification: () => ({
        type: 'error',
        description: 'Ops!',
        message:
          'Não foi possível adicionar a documentação para reavaliação. Tente novamente.'
      })
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  };

  return (
    <Show isLoading={isLoading} title="Visualizar documentação">
      <div className="flex gap-8 justify-between mb-6">
        <div>
          <Title level={5}>ID da documentação</Title>
          <Text>{record?.id}</Text>
        </div>

        <div>
          <Title level={5}>Data de criação</Title>
          {record?.created_at && (
            <TextField value={formatDate(record?.created_at, 'lll')} />
          )}
        </div>

        <div>
          <Title level={5}>Status</Title>
          {record?.status && (
            <TagField value={translateDocumentationStatus(record?.status)} />
          )}
        </div>
        <Space direction="vertical">
          <Button
            size="small"
            icon={<Icons.SyncOutlined />}
            onClick={() => refreshDocumentation()}
          >
            Reavaliar documentação
          </Button>
          <Button
            size="small"
            icon={<Icons.EyeOutlined />}
            onClick={() => handleDocumentationViewClick()}
          >
            Visualizar alterações
          </Button>
        </Space>
      </div>

      <Title level={5}>Dados do Aluno</Title>
      <Text>
        <strong>Nome:</strong> {record?.parent.user.name} <br />
        <strong>E-mail:</strong> {record?.parent.user.email} <br />
        <strong>Curso:</strong> {record?.parent.course.name} <br />
      </Text>

      {documentationModal.visible && (
        <LogsDocumentationModal
          documentationId={id}
          modal={documentationModal}
        />
      )}
      <Divider />

      {record?.documents && (
        <>
          <Title level={5}>Lista de arquivos</Title>
          <Table dataSource={record.documents} columns={columns} size="small" />
        </>
      )}
      {modal.visible && (
        <LogsModal
          documentationId={id}
          documentId={selectedDocumentId}
          modal={modal}
        />
      )}
    </Show>
  );
};
