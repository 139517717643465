/* eslint-disable unicorn/filename-case */
export const htmlIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1259_20781)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.586 2C14.0556 2.00011 14.5101 2.16543 14.87 2.467L15 2.586L19.414 7C19.746 7.33202 19.9506 7.77028 19.992 8.238L20 8.414V20C20.0002 20.5046 19.8096 20.9906 19.4665 21.3605C19.1234 21.7305 18.6532 21.9572 18.15 21.995L18 22H6C5.49542 22.0002 5.00943 21.8096 4.63945 21.4665C4.26947 21.1234 4.04284 20.6532 4.005 20.15L4 20V4C3.99984 3.49542 4.19041 3.00943 4.5335 2.63945C4.87659 2.26947 5.34684 2.04284 5.85 2.005L6 2H13.586ZM12 4H6V20H18V10H13.5C13.1271 9.99998 12.7676 9.86108 12.4916 9.61038C12.2156 9.35968 12.0428 9.01516 12.007 8.644L12 8.5V4ZM14 4.414V8H17.586L14 4.414Z"
        fill="currentColor"
      />
      <path
        d="M14.1327 17.0889C14.017 17.1323 13.9159 17.1395 13.8291 17.1106C13.7472 17.0817 13.6798 17.0311 13.6268 16.9588C13.5738 16.8817 13.5448 16.7998 13.54 16.7131C13.5352 16.6215 13.5545 16.5372 13.5978 16.4601C13.646 16.3782 13.7231 16.3156 13.8291 16.2722L16.8792 15.1013V15.7301L13.8291 14.5593C13.7231 14.5159 13.646 14.4557 13.5978 14.3786C13.5545 14.2967 13.5352 14.2099 13.54 14.1184C13.5448 14.0268 13.5738 13.9449 13.6268 13.8726C13.6798 13.7955 13.7472 13.7425 13.8291 13.7136C13.9159 13.6847 14.017 13.692 14.1327 13.7353L16.7997 14.7833C17.0984 14.899 17.2478 15.1061 17.2478 15.4049C17.2478 15.5591 17.2116 15.6892 17.1394 15.7952C17.0671 15.8964 16.9539 15.9783 16.7997 16.0409L14.1327 17.0889Z"
        fill="currentColor"
      />
      <path
        d="M11.6715 17.6453C11.6281 17.7706 11.5631 17.8621 11.4763 17.9199C11.3896 17.9778 11.2956 18.0043 11.1944 17.9994C11.0933 17.9994 10.9993 17.9729 10.9126 17.9199C10.8307 17.8718 10.7704 17.7971 10.7319 17.6959C10.6933 17.5995 10.6933 17.4863 10.7319 17.3562L12.3364 12.3547C12.375 12.2294 12.4376 12.1379 12.5243 12.0801C12.6159 12.0222 12.7098 11.9957 12.8062 12.0006C12.9074 12.0006 13.0013 12.0271 13.0881 12.0801C13.1748 12.1282 13.2374 12.2005 13.276 12.2969C13.3145 12.3933 13.3121 12.5089 13.2688 12.6438L11.6715 17.6453Z"
        fill="currentColor"
      />
      <path
        d="M9.86728 17.0889L7.20031 16.0409C6.90157 15.9253 6.7522 15.7133 6.7522 15.4049C6.7522 15.2507 6.78834 15.123 6.86061 15.0218C6.93771 14.9206 7.05094 14.8411 7.20031 14.7833L9.86728 13.7353C9.98292 13.692 10.0841 13.6847 10.1708 13.7136C10.2576 13.7425 10.325 13.7955 10.3732 13.8726C10.4262 13.9449 10.4551 14.0268 10.4599 14.1184C10.4696 14.2099 10.4503 14.2967 10.4021 14.3786C10.3588 14.4557 10.2817 14.5159 10.1708 14.5593L7.12803 15.7301V15.1013L10.1708 16.2722C10.2817 16.3156 10.3588 16.3782 10.4021 16.4601C10.4503 16.5372 10.4696 16.6215 10.4599 16.7131C10.4551 16.7998 10.4262 16.8817 10.3732 16.9588C10.325 17.0311 10.2576 17.0817 10.1708 17.1106C10.0841 17.1395 9.98292 17.1323 9.86728 17.0889Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1259_20781">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
