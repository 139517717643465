import { TrackerOptions } from 'services/tracker';
import { getTenant } from 'services/tenants';
import { getUserId } from 'services/auth';

export const trackingCurrentOptions: TrackerOptions = {
  amplitudeOptions: {
    environment:
      process.env.REACT_APP_TENANT_ENV === 'production'
        ? 'production'
        : 'development',
    client: {
      configuration: {
        defaultTracking: {
          pageViews: {
            trackOn: 'attribution',
            trackHistoryChanges: 'all'
          }
        },
        userId: getUserId() || 'undefined'
      }
    }
  },
  tenant: getTenant().project || undefined
};
