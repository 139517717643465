import { CreateButton, List, useTable } from '@refinedev/antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { Divider, Space } from 'antd';
import { IUser } from 'interfaces/users';
import {
  IUsersFilterForm,
  UsersListFilter
} from './components/list/list-filter';
import { UsersListTable } from './components/list/list-table';

export const UserList = () => {
  const { tableProps, searchFormProps } = useTable<
    IUser,
    HttpError,
    IUsersFilterForm
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { name, email, cpf, estacio_id } = params;

      filters.push(
        {
          field: 'filter[name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[email]',
          operator: 'eq',
          value: email
        },
        {
          field: 'filter[cpf]',
          operator: 'eq',
          value: cpf?.replace(/\D/g, '')
        },
        {
          field: 'filter[by_estacio_id]',
          operator: 'eq',
          value: estacio_id
        }
      );

      return filters;
    }
  });

  return (
    <List>
      <UsersListFilter searchFormProps={searchFormProps} />
      <UsersListTable tableProps={tableProps} />
      <Divider />
      <Space>
        <CreateButton resource="bulk_users">Lote</CreateButton>
        <CreateButton resource="bulk_user_suspends">Suspender</CreateButton>
      </Space>
    </List>
  );
};
