import FilterForm from '../../../components/forms/FilterForm';
import { Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  EditButton,
  List,
  ShowButton,
  TextField,
  useSelect,
  useTable
} from '@refinedev/antd';
import { ICourse } from 'interfaces/courses';
import { IProfessor } from 'interfaces/professor';
import { TitleMap } from 'services/professors';

interface IFilter {
  name: string;
  courseId: string;
  title: string;
  email: string;
}

export const ProfessorsList = () => {
  const { tableProps, searchFormProps } = useTable<
    IProfessor,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { name, courseId, title, email } = params;

      filters.push(
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_course_id]',
          operator: 'eq',
          value: courseId
        },
        {
          field: 'filter[by_title]',
          operator: 'eq',
          value: title
        },
        {
          field: 'filter[by_email]',
          operator: 'eq',
          value: email
        }
      );

      return filters;
    }
  });
  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Nome" name="name">
              <Input placeholder="Nome" />
            </Form.Item>
            <Form.Item label="E-mail" name="email">
              <Input placeholder="E-mail" />
            </Form.Item>
            <Form.Item label="Curso" name="courseId">
              <Select
                placeholder="Digite..."
                style={{ minWidth: 400 }}
                {...courseSelectProps}
              />
            </Form.Item>
            <Form.Item label="Título" name="title">
              <Select placeholder="Selecione..." style={{ minWidth: 400 }}>
                <Select.Option key="specialist">Especialista</Select.Option>
                <Select.Option key="master">Mestre</Select.Option>
                <Select.Option key="doctor">Doutor</Select.Option>
              </Select>
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="title"
              key="title"
              title="Título"
              render={value => (
                <TextField value={TitleMap[value as keyof typeof TitleMap]} />
              )}
            />
            <Table.Column
              dataIndex="courses_count"
              key="courses_count"
              title="Cursos"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="classrooms_count"
              key="classrooms_count"
              title="Turmas"
              render={value => <TextField value={value} />}
            />
            <Table.Column<IProfessor>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
