import AssetUpload from 'components/AssetUpload';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, InputNumber, Select } from 'antd';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { getTenant } from 'services/tenants';

export const GraduateCourseCreate = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IGraduateCourse>({
    redirect: 'edit'
  });

  const tenant = getTenant();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Criar curso regulado"
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="kind"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select>
            <Select.Option value="graduate">Pós Graduação</Select.Option>
            {!tenant.disabledFeatures?.includes('undergraduate_courses') && (
              <Select.Option value="undergraduate">Graduação</Select.Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item label="Código e-MEC" name="emec" className="max-w-xs">
          <Input />
        </Form.Item>
        <Form.Item
          label="Duração (horas)"
          name="duration_in_hours"
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          label="Nota mínima (0 - 100)"
          name="min_evaluation_score"
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber min={0} max={100} />
        </Form.Item>
        <Form.Item
          label="Card"
          name={['media', 'card_image']}
          rules={[
            {
              required: false
            }
          ]}
        >
          <AssetUpload
            accept="image/png, image/jpeg"
            type="course"
            src={queryResult?.data?.data?.media?.card_image}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
