import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IEcommerceDivision } from 'interfaces/ecommerce_divisions';
import { IEcommerceProfitCenter } from 'interfaces/ecommerce_profit_centers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const EcommerceProfitCenterEdit = () => {
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const { formProps, saveButtonProps, queryResult } =
    useForm<IEcommerceProfitCenter>({
      resource: 'ecommerce/profit_centers',
      id,
      redirect: false
    });

  const {
    selectProps: ecommerceDivisionSelectProps,
    queryResult: divisionQueryResult
  } = useSelect<IEcommerceDivision>({
    resource: 'ecommerce/divisions',
    optionLabel: 'code',
    optionValue: 'id',
    defaultValue: queryResult?.data?.data.division_id,

    onSearch: value => [
      {
        field: 'filter[code]',
        operator: 'eq',
        value
      },
      {
        field: 'filter[description]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  useEffect(() => {
    if (divisionQueryResult.data?.data.length) {
      const res = divisionQueryResult.data?.data.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, division) => {
          acc.push({
            label: `${division.code} - ${division.description}`,
            value: division.id
          });
          return acc;
        },
        []
      );
      setOptions(res);
    }
  }, [divisionQueryResult?.data?.data]);

  const data = queryResult?.data?.data;

  if (!data) {
    return <Edit saveButtonProps={saveButtonProps} />;
  }

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar centro de resultados">
      <Form
        {...formProps}
        initialValues={data}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          label="Código"
          name="code"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Divisão"
          name="division_id"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...ecommerceDivisionSelectProps} options={options} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
