import { Form, Modal, Select } from 'antd';
import { IDocumentTemplateCourse } from 'interfaces/documentation/document-template';
import { useEffect } from 'react';
import { useModalReturnType, useUpdate } from '@refinedev/core';

interface DocumentationAssociationModalProps {
  modal: useModalReturnType;
  associationCouse?: IDocumentTemplateCourse;
  resource: string;
}

const DocumentationAssociationModal = ({
  modal,
  associationCouse,
  resource
}: DocumentationAssociationModalProps) => {
  const { mutateAsync: updateRecord } = useUpdate();
  const [form] = Form.useForm();

  useEffect(() => {
    if (associationCouse) {
      form.setFieldsValue(associationCouse);
    }
  }, [associationCouse, form]);

  if (!associationCouse) {
    return null;
  }

  const handleUpdate = () => {
    updateRecord({
      resource,
      id: associationCouse.id,
      values: { required: form.getFieldValue('required') },
      errorNotification: {
        type: 'error',
        message: 'Ocorreu algum erro interno no serviço',
        description: 'Erro ao associar curso!'
      }
    })
      .then(() => {
        form.resetFields();
        modal.close();
      })
      .catch(() => null);
  };

  return (
    <Modal
      title="Editar obrigatoriedade"
      width={400}
      visible={modal.visible}
      onCancel={modal.close}
      onOk={handleUpdate}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="required" label="Obrigatoriedade" required>
          <Select placeholder="Selecione a obrigatoriedade">
            <Select.Option value={true}>Obrigatório</Select.Option>
            <Select.Option value={false}>Opcional</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DocumentationAssociationModal;
