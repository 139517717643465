import { Collapse, CollapsePanelProps } from 'antd';
import { DOCUMENT_STATUSES_MAP } from './statuses-map';
import { DocumentItem } from './DocumentItem';
import { DocumentItemHistory } from './DocumentItemHistory';
import { DocumentPanelHeader } from './DocumentPanelHeader';
import { DocumentsByTemplateMap } from './DocumentList';
import { useCallback, useMemo } from 'react';

interface DocumentPanelBodyProps extends Omit<CollapsePanelProps, 'header'> {
  data: DocumentsByTemplateMap;
}

export const DocumentPanelBody = ({
  data,
  ...props
}: DocumentPanelBodyProps) => {
  const getStatusByType = useCallback(
    (type: string) => {
      return data.documents.find(document => document.status === type)?.status;
    },
    [data.documents]
  );

  const status = useMemo(() => {
    return (
      getStatusByType('approved') ??
      getStatusByType('pending') ??
      getStatusByType('rejected') ??
      'draft'
    );
  }, [getStatusByType]);

  const style = useMemo(
    () => ({
      backgroundColor: '#fff',
      borderTop: 0,
      borderLeftWidth: 2,
      borderLeftColor: DOCUMENT_STATUSES_MAP[status]?.color ?? 'transparent'
    }),
    [status]
  );

  return (
    <Collapse.Panel
      {...props}
      style={style}
      header={
        <DocumentPanelHeader
          title={data.name}
          status={status}
          required={data.required}
          document_count={data.documents?.length}
        />
      }
    >
      {data.documents?.length > 0 && (
        <DocumentItem document={data.documents[0]} />
      )}

      {data.documents?.length > 1 && (
        <DocumentItemHistory documents={data.documents.slice(1)} />
      )}
    </Collapse.Panel>
  );
};
