import { ByProductPaymentPlanDealDiscount } from './ByProductRule';
import { GeneralManyPaymentPlanDealsDiscounts } from './GeneralRule';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const items = [
  {
    key: 'regra-geral',
    label: 'Regra geral',
    content: () => <GeneralManyPaymentPlanDealsDiscounts />
  },
  {
    key: 'regra-produto',
    label: 'Regra por produto',
    content: () => <ByProductPaymentPlanDealDiscount />
  }
];

export const PaymentPlanDealsDiscountsList = () => {
  return (
    <div className="bg-white items-start p-6 rounded-lg">
      <div className="flex flex-col gap-2">
        <h3 className="flex items-center gap-2">
          <span className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
            Inadimplência /
          </span>
          <span className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.85)]">
            Regras de desconto
          </span>
        </h3>
        <h1 className="font-bold text-xl leading-7 text-[rgba(0,0,0,0.85)]">
          Regras de desconto
        </h1>
      </div>
      <Tabs defaultActiveKey={items[0].key} size="middle">
        {items.map(tab => {
          return (
            <TabPane tab={tab.label} key={tab.key}>
              {tab.content()}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};
