import React, { useEffect, useState } from 'react';
import { IDocumentationLog } from 'interfaces/documentation/documentations';
import { Modal, Table } from 'antd';
import { TagField, TextField } from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { getDocumentationLogs } from 'requests/documentations/logs';
import {
  getStatusColor,
  translateDocumentationStatus,
  translateEventStatus
} from '../../../services/documentations';
import { useModalReturnType } from '@refinedev/core';

interface Props {
  documentationId: string | undefined;
  modal: useModalReturnType;
}

const LogsDocumentationModal: React.FC<Props> = ({
  documentationId,
  modal
}) => {
  const [logs, setLogs] = useState<IDocumentationLog[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await getDocumentationLogs({ documentationId });
        setLogs(response);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    if (modal.visible) {
      fetchData().then();
    }
  }, [modal.visible, documentationId]);

  return (
    <Modal
      title="Histórico de Alterações"
      width={800}
      visible={modal.visible}
      onCancel={modal.close}
      footer={null}
    >
      <Table
        size="small"
        dataSource={logs}
        columns={[
          {
            title: 'Alterado em',
            dataIndex: 'created_at',
            key: 'created_at',
            render: createdAt => (
              <TextField value={formatDate(createdAt, 'lll')} />
            )
          },
          {
            title: 'Alterado por',
            dataIndex: 'author',
            key: 'author',
            render: author => <span>{author.name}</span>
          },
          {
            title: 'Evento',
            dataIndex: 'event',
            key: 'event',
            render: (event: string) => (
              <TextField value={translateEventStatus(event)} />
            )
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
              <>
                {status ? (
                  <div className="flex flex-col gap-1">
                    <span>
                      De:{' '}
                      <TagField
                        color={getStatusColor(status[0])}
                        value={translateDocumentationStatus(status[0])}
                      />
                    </span>
                    <span>
                      Para:{' '}
                      <TagField
                        color={getStatusColor(status[1])}
                        value={translateDocumentationStatus(status[1])}
                      />
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </>
            )
          }
        ]}
        loading={loading}
        pagination={false}
      />
    </Modal>
  );
};

export default LogsDocumentationModal;
