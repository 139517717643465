import { PersonificationCreate } from 'pages/admin/personifications';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'personifications',
  label: 'Simulação do aluno',
  list: PersonificationCreate,
  actions: ['create'],
  parentName: 'students_teachers'
};

export default generateResources(resource);
