import { TeamOutlined } from '@ant-design/icons';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'teacher_space',
  label: 'Área do professor',
  icon: <TeamOutlined />
};

export default generateResources(resource);
