import { Col, Row, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IReport, IReportReason, IReportStatus } from 'interfaces/reports';
import { IReportsFilter, ReportsFilter } from './components/list-filter';
import { IUser } from '../../../interfaces/users';
import {
  List,
  RefreshButton,
  ShowButton,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import {
  REPORT_REASON_MAP,
  REPORT_STATUSES_WITH_COLOR_MAP
} from 'services/report';
import { formatDate } from 'services/date';
import { truncate } from 'lodash-es';

export const ReportsList = () => {
  const { tableProps, searchFormProps } = useTable<
    IReport,
    HttpError,
    IReportsFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { by_reason, by_status } = params;

      filters.push(
        {
          field: 'filter[by_reason]',
          operator: 'eq',
          value: by_reason
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: by_status
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: 'pending'
        }
      ]
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List
          title="Denúncias de comentários"
          headerButtons={<RefreshButton />}
        >
          <ReportsFilter searchFormProps={searchFormProps} />

          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="reason"
              key="reason"
              title="Motivo"
              render={value => (
                <TextField value={REPORT_REASON_MAP[value as IReportReason]} />
              )}
            />

            <Table.Column
              dataIndex="text"
              key="text"
              title="Texto"
              render={value => (
                <TextField value={truncate(value, { length: 150 })} />
              )}
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={value => (
                <TagField
                  color={
                    REPORT_STATUSES_WITH_COLOR_MAP[value as IReportStatus].color
                  }
                  value={
                    REPORT_STATUSES_WITH_COLOR_MAP[value as IReportStatus].text
                  }
                />
              )}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Data de criação"
              render={value => <TextField value={formatDate(value)} />}
            />
            <Table.Column<IUser>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
