import * as Icons from '@ant-design/icons';
import React, { useState } from 'react';
import { Button, Tree } from 'antd';
import { ContentAssessmentModal } from 'components/modal/content-tree/ContentAssessmentModal';
import { CreateContentModal } from 'components/modal/content-tree/CreateContentModal';
import { CreateSectionModal } from 'components/modal/content-tree/CreateSectionModal';
import {
  ICourseRequirement,
  ICourseShow,
  ICourseTree,
  IDataContent,
  IDataNode,
  IDataNodeDrop
} from 'interfaces/courses';
import { TreeNode } from './TreeNode';
import {
  calculateNodeRemoval,
  calculatePositionChange,
  convertContentNodes,
  convertDataContents,
  convertDataNodes,
  convertNodes,
  replaceNodeMatch
} from 'services/courses/tree';
import { generateUUID } from 'services/uuid';
import { useModal } from '@refinedev/core';

interface Props {
  course: ICourseShow;
  tree: ICourseTree;
  loading: boolean;
  onChange?: (tree: ICourseTree) => void;
  onUpdate?: (tree: ICourseTree, requirement?: ICourseRequirement) => void;
}

const CourseTree: React.FC<Props> = ({
  course,
  tree,
  loading,
  onUpdate,
  onChange
}) => {
  const [gData, setGData] = useState([
    ...convertNodes(tree.nodes),
    ...convertContentNodes(tree.contents)
  ]);
  const [expandedKeys] = useState([]);
  const [openModal, setOpenModal] = useState<string | null>(null);

  const newNodeModal = useModal();
  const newContentModal = useModal();
  const newAssessmentModal = useModal();

  const update = (data: IDataNode[], requirement?: ICourseRequirement) => {
    setGData(data);

    const newTree = {
      ...tree,
      nodes: convertDataNodes(data.filter(item => !item.isLeaf)),
      contents: convertDataContents(
        (data.filter(item => item.isLeaf) || []) as IDataContent[]
      )
    };

    onUpdate?.(newTree, requirement);
    onChange?.(newTree);
  };

  const onDrop = (info: IDataNodeDrop) => {
    const data = calculatePositionChange(gData, info);

    update(data);
  };

  const onRemove = (node: IDataNode) => {
    const data = calculateNodeRemoval(gData, node);

    update(data);
  };

  const titleRender = (node: IDataNode) => (
    <TreeNode
      course={course}
      node={node}
      updateNode={updateNode}
      updateNodeContent={updateNode}
      onRemove={onRemove}
      loading={loading}
    />
  );

  const updateNode = (node: IDataNode, requirement?: ICourseRequirement) => {
    const newGData = gData.map(data => replaceNodeMatch(data, node));
    update(newGData, requirement);
  };

  const createNode = ({ name }: { name: string }) => {
    update([...gData, { title: name, key: generateUUID(), isLeaf: false }]);
    setOpenModal(null);
  };

  const createContent = (node: IDataContent) => {
    update([...gData, { ...node, isLeaf: true }]);
    setOpenModal(null);
  };

  const createNewNode = () => {
    setOpenModal('node');
    newNodeModal.show();
  };

  const createNewContent = () => {
    setOpenModal('content');
    newContentModal.show();
  };

  const addAssessmentContent = () => {
    setOpenModal('assessment');
    newAssessmentModal.show();
  };

  const onCloseModal = () => {
    setOpenModal(null);
  };

  return (
    <div className="flex flex-col gap-4">
      <Tree
        className="draggable-tree"
        defaultExpandedKeys={expandedKeys}
        draggable
        blockNode
        selectable={false}
        onDrop={onDrop}
        treeData={gData}
        titleRender={titleRender}
        switcherIcon={<Icons.DownOutlined />}
      />

      <div className="flex flex-row gap-4">
        <Button
          className="!flex items-center justify-center"
          htmlType="button"
          onClick={createNewNode}
        >
          <Icons.PlusCircleOutlined />
          Criar Seção
        </Button>
        <Button
          className="!flex items-center justify-center"
          htmlType="button"
          onClick={createNewContent}
        >
          <Icons.PlusCircleOutlined />
          Criar Conteúdo
        </Button>

        <Button
          className="!flex items-center justify-center"
          htmlType="button"
          onClick={addAssessmentContent}
        >
          <Icons.PlusCircleOutlined />
          Adicionar Simulado
        </Button>
      </div>

      {openModal === 'node' && (
        <CreateSectionModal
          modal={newNodeModal}
          onCreate={createNode}
          onClose={onCloseModal}
        />
      )}

      {openModal === 'content' && (
        <CreateContentModal
          modal={newContentModal}
          onCreate={createContent}
          onClose={onCloseModal}
        />
      )}

      {openModal === 'assessment' && (
        <ContentAssessmentModal
          modal={newAssessmentModal}
          course={course}
          onCreate={createContent}
          onClose={onCloseModal}
        />
      )}
    </div>
  );
};

export default CourseTree;
