import {
  LibrariesCreate,
  LibrariesEdit,
  LibrariesList,
  LibrariesShow
} from 'pages/libraries';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'libraries',
  label: 'Bibliotecas',
  list: LibrariesList,
  show: LibrariesShow,
  create: LibrariesCreate,
  edit: LibrariesEdit,
  actions: ['list', 'create', 'edit'],
  parentName: 'teaching_resources'
};

export default generateResources(resource);
