import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IEcommerceDivision } from 'interfaces/ecommerce_divisions';
import { IEcommerceProfitCenter } from 'interfaces/ecommerce_profit_centers';
import { useEffect, useState } from 'react';
import { useNavigation } from '@refinedev/core';

export const EcommerceProfitCenterCreate = () => {
  const navigate = useNavigation();
  const [options, setOptions] = useState([]);
  const { formProps, saveButtonProps } = useForm<IEcommerceProfitCenter>({
    resource: 'ecommerce/profit_centers',
    redirect: false,
    onMutationSuccess: () => {
      navigate.push('/ecommerce/ecommerce_profit_centers');
    }
  });

  const { selectProps: ecommerceDivisionSelectProps, queryResult } =
    useSelect<IEcommerceDivision>({
      resource: 'ecommerce/divisions',

      onSearch: value => [
        {
          field: 'filter[code]',
          operator: 'eq',
          value
        },
        {
          field: 'filter[description]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    });

  useEffect(() => {
    if (queryResult.data?.data.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = queryResult.data?.data.reduce((acc: any, division) => {
        acc.push({
          label: `${division.code} - ${division.description}`,
          value: division.id
        });
        return acc;
      }, []);
      setOptions(res);
    }
  }, [options, queryResult?.data?.data]);

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Criar centro de resultados"
    >
      <Form
        {...formProps}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          label="Código"
          name="code"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Divisão"
          name="division_id"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...ecommerceDivisionSelectProps} options={options} />
        </Form.Item>
      </Form>
    </Create>
  );
};
