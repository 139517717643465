import {
  CertifierCreate,
  CertifierEdit,
  CertifierShow,
  CertifiersList
} from 'pages/certificates_management/certifiers';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'certifiers',
  label: 'Instituições Certificadoras',
  list: CertifiersList,
  create: CertifierCreate,
  edit: CertifierEdit,
  show: CertifierShow,
  actions: ['list', 'show', 'edit', 'create'],
  parentName: 'certificate_management'
};

export default generateResources(resource);
