import { AuditOutlined } from '@ant-design/icons';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'certificate_management',
  label: 'Gestão de certificados',
  icon: <AuditOutlined />
};

export default generateResources(resource);
