import InstructorInfo from 'components/professors/InstructorInfo';
import { BaseKey } from '@refinedev/core';
import { Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import { IProfessor } from 'interfaces/professor';
import { Text } from '@qcx/ui';
import { TitleMap } from 'services/professors';
import { useSelect } from '@refinedev/antd';

export interface ProfessorInfoProps {
  professorData?: IProfessor;
  type?: 'show' | 'edit';
}

const ProfessorInfo = ({ professorData, type }: ProfessorInfoProps) => {
  const { selectProps: instructorSelectProps } = useSelect({
    resource: 'instructors',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const options = () => {
    let tempOptions = queryResult.data?.data.map(item => ({
      label: item.name,
      value: item.id,
      slug: item.slug
    }));
    tempOptions = tempOptions && [
      {
        label: '-- Desassociar perfil --',
        value: null as unknown as BaseKey,
        slug: undefined
      },
      ...tempOptions
    ];
    return tempOptions;
  };

  return (
    <>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Informações gerais do professor
      </Typography.Title>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true
              }
            ]}
          >
            {type !== 'show' ? <Input /> : <Text>{professorData?.name}</Text>}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Título"
            name="title"
            rules={[
              {
                required: true
              }
            ]}
          >
            {type !== 'show' ? (
              <Select
                placeholder="Selecione um titulo..."
                style={{ minWidth: 400 }}
              >
                <Select.Option value="specialist">Especialista</Select.Option>
                <Select.Option value="master">Mestre</Select.Option>
                <Select.Option value="doctor">Doutor</Select.Option>
              </Select>
            ) : (
              <Text>
                {TitleMap[professorData?.title as keyof typeof TitleMap]}
              </Text>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="E-mail" name="email">
            {type !== 'show' ? <Input /> : <Text>{professorData?.email}</Text>}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]} className="w-full">
        <Col className="flex justify-between">
          <Typography.Title level={5} style={{ margin: '12px 0' }}>
            Perfil na vitrine
          </Typography.Title>
        </Col>
      </Row>
      {type !== 'show' && (
        <Row gutter={[16, 16]} className="w-full">
          <Col className="flex justify-between w-full" md={6}>
            <Form.Item label="Associar perfil da vitrine" name="instructor_id">
              <Select {...instructorSelectProps} />
            </Form.Item>
          </Col>
        </Row>
      )}

      {professorData && <InstructorInfo professorData={professorData} />}
    </>
  );
};

export default ProfessorInfo;
