/* eslint-disable unicorn/filename-case */
export const dealIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0002 10.6661L14.3335 13.9994M14.3335 10.6661L11.0002 13.9994M14.6668 6.6661H1.3335M14.6668 7.99943V5.4661C14.6668 4.71936 14.6668 4.34599 14.5215 4.06078C14.3937 3.80989 14.1897 3.60592 13.9388 3.47809C13.6536 3.33276 13.2802 3.33276 12.5335 3.33276H3.46683C2.72009 3.33276 2.34672 3.33276 2.06151 3.47809C1.81063 3.60592 1.60665 3.80989 1.47882 4.06078C1.3335 4.34599 1.3335 4.71936 1.3335 5.4661V10.5328C1.3335 11.2795 1.3335 11.6529 1.47882 11.9381C1.60665 12.189 1.81063 12.3929 2.06151 12.5208C2.34672 12.6661 2.72009 12.6661 3.46683 12.6661H8.00016"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
