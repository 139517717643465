/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable  */
import {
  ICourseContent,
  ICourseNode,
  IDataContent,
  IDataNode,
  IDataNodeDrop
} from 'interfaces/courses';

export const convertContents = (nodes: ICourseContent[]): IDataNode[] =>
  nodes.map(node => ({
    key: node.id,
    title: node.name,
    core_id: node.core_id,
    core_kind: node.core_kind,
    core_provider: node.core_provider,
    core_reference: node.core_reference,
    core_status: node.core_status,
    core_url: node.core_url,
    children: [],
    contents: [],
    isLeaf: true
  }));

export const convertNodes = (nodes: ICourseNode[]): IDataNode[] =>
  nodes.map(node => ({
    key: node.id,
    title: node.name,
    children: [...convertNodes(node.nodes), ...convertContents(node.contents)],
    isLeaf: false
  }));

export const convertContentNodes = (
  contents: ICourseContent[]
): IDataContent[] =>
  contents.map(content => ({
    key: content.id,
    title: content.name,
    name: content.name,
    core_id: content.core_id,
    core_kind: content.core_kind,
    core_reference: content.core_reference,
    core_provider: content.core_provider,
    core_url: content.core_url,
    core_status: content.core_status,
    children: [],
    isLeaf: true
  }));

export const convertDataNodes = (dataNodes: IDataNode[]): ICourseNode[] =>
  dataNodes.map(dataNode => ({
    id: dataNode.key as string,
    name: dataNode.title as string,
    nodes: convertDataNodes(
      dataNode.children?.filter(item => !item.isLeaf) || []
    ),
    contents: convertDataContents(
      (dataNode.children?.filter(item => item.isLeaf) || []) as IDataContent[]
    )
  }));

export const convertDataContents = (
  contentNodes: IDataContent[]
): ICourseContent[] =>
  contentNodes.map(dataContent => ({
    id: dataContent.key as string,
    name: dataContent.title as string,
    core_id: dataContent.core_id,
    core_kind: dataContent.core_kind,
    core_reference: dataContent.core_reference,
    core_provider: dataContent.core_provider,
    core_url: dataContent.core_url,
    core_status: dataContent.core_status
  }));

export const replaceNodeMatch = (data: IDataNode, node: IDataNode): IDataNode =>
  data.key === node.key
    ? {
        ...node
      }
    : {
        ...data,
        children: (data.children || []).map(child =>
          replaceNodeMatch(child, node)
        )
      };

export const calculateNodeRemoval = (
  dataNodes: IDataNode[],
  dataNode: IDataNode
) => {
  const removeKey = dataNode.key;

  const loop = (
    data: IDataNode[],
    key: React.Key,
    callback: (node: IDataNode, i: number, data: IDataNode[]) => void
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }

      if (data[i].children) {
        loop(data[i].children!, key, callback);
      }
    }
  };

  const data = [...dataNodes];

  loop(data, removeKey, (item, index, arr) => {
    arr.splice(index, 1);
  });

  return data;
};

export const calculatePositionChange = (
  dataNodes: IDataNode[],
  info: IDataNodeDrop
): IDataNode[] => {
  const dropKey = info.node.key;
  const dragKey = info.dragNode.key;
  const dropPos = info.node.pos.split('-');
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

  if (info.node.isLeaf && dropPosition === 0) {
    // We cannot put nodes inside leafs
    return dataNodes;
  }

  const loop = (
    data: IDataNode[],
    key: React.Key,
    callback: (node: IDataNode, i: number, data: IDataNode[]) => void
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }

      if (data[i].children) {
        loop(data[i].children!, key, callback);
      }
    }
  };
  const data = [...dataNodes];

  // Find dragObject
  let dragObj: IDataNode;
  loop(data, dragKey, (item, index, arr) => {
    arr.splice(index, 1);
    dragObj = item;
  });

  if (!info.dropToGap) {
    // Drop on the content
    loop(data, dropKey, item => {
      item.children = item.children || [];
      item.children.unshift(dragObj);
    });
  } else if (
    ((info.node as any).props.children || []).length > 0 && // Has children
    (info.node as any).props.expanded && // Is expanded
    dropPosition === 1 // On the bottom gap
  ) {
    loop(data, dropKey, item => {
      item.children = item.children || [];
      item.children.unshift(dragObj);
      // in previous version, we use item.children.push(dragObj) to insert the
      // item to the tail of the children
    });
  } else {
    let ar: IDataNode[] = [];
    let i: number;
    loop(data, dropKey, (_item, index, arr) => {
      ar = arr;
      i = index;
    });

    if (dropPosition === -1) {
      ar.splice(i!, 0, dragObj!);
    } else {
      ar.splice(i! + 1, 0, dragObj!);
    }
  }

  return data;
};
