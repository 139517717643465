const colors = require('tailwindcss/colors');

// @ts-nocheck
/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      colors: {
        primary: colors.purple
      }
    }
  },
  plugins: [],
  corePlugins: {
    preflight: false
  }
};
