import { AcademicRecordDetails } from './components/show/AcademicRecordDetails';
import { AcademicRecordEquivalencyDetails } from './components/show/AcademicRecordEquivalencyDetails';
import { Divider } from 'antd';
import { IAcademicRecordEquivalency } from 'interfaces/academic_record_equivalencies';
import { Show } from '@refinedev/antd';
import { StudentInfo } from './components/show/StudentInfo';
import { useShow } from '@refinedev/core';

export const AcademicRecordEquivalencyShow = () => {
  const { queryResult } = useShow<IAcademicRecordEquivalency>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar Equivalência">
      {record && (
        <>
          <Divider orientation="left" orientationMargin="0">
            Informações do Aluno
          </Divider>

          <StudentInfo
            user={record?.course_enrollment.user}
            course={record?.course_enrollment.course}
          />

          <Divider orientation="left" orientationMargin="0" className="!pt-4">
            Histórico Acadêmico de Origem
          </Divider>

          {record?.origin_academic_record && (
            <>
              <AcademicRecordDetails
                academic_record={record?.origin_academic_record}
              />
            </>
          )}

          <Divider orientation="left" orientationMargin="0" className="!pt-4">
            Histórico Acadêmico de Destino
          </Divider>

          <AcademicRecordEquivalencyDetails record={record} />
        </>
      )}
    </Show>
  );
};
