import { GetListResponse, GetManyResponse } from '@refinedev/core';
import { QueryObserverResult } from '@tanstack/query-core';

export const getSelectOptionWithDefault = <T>(
  defaultValueQueryResult: QueryObserverResult<GetManyResponse<T>>,
  modulesQueryResult: QueryObserverResult<GetListResponse<T>>
) => {
  return defaultValueQueryResult?.data?.data && modulesQueryResult?.data?.data
    ? [...defaultValueQueryResult.data.data, ...modulesQueryResult.data.data]
    : modulesQueryResult?.data?.data;
};
