import {
  ICertificate,
  ICertificateDownload,
  IIssueCertificate,
  IIssuedCertificate
} from 'interfaces/course_certificate';
import { IDocument } from 'interfaces/documentation/document';
import { OpenNotificationParams } from '@refinedev/core';
import { getAPI } from 'requests/api';

type DocumentsObject = Record<string, string>;

export const transformAttributes = (
  data: IDocument[] | undefined
): DocumentsObject | Record<string, never> => {
  const result: DocumentsObject = {};

  if (!data) {
    return {};
  }

  for (const item of data) {
    if (item.status === 'approved') {
      for (const attribute of item.attribute_fields) {
        result[attribute.name] = attribute.value;
      }
    }
  }

  return result;
};

const handleDownloadURL = (certificateData: ICertificateDownload) => {
  if (certificateData.url) {
    window.open(certificateData.url, '_self');
    return null;
  }
};

export const downloadCertificate = async (
  certificateData: ICertificate | undefined
) => {
  if (!certificateData) {
    return null;
  }

  const documentUrl = await getAPI().get<ICertificateDownload>(
    `/admin/graduate/courses/certificates/${certificateData?.id}/download`
  );

  return handleDownloadURL(documentUrl.data);
};

export const handleCertificateStatusAndDownload = async (
  certificate: IIssuedCertificate,
  errorCallback: (params: OpenNotificationParams) => void,
  setLoading: () => void
): Promise<null | undefined> => {
  try {
    const certificateData = await getAPI().get<ICertificate>(
      `/admin/graduate/courses/certificates/${certificate.id}`
    );

    if (certificateData.data.status === 'error') {
      throw new Error('Error ao gerar certificado!');
    }

    if (certificateData.data.status === 'processed') {
      setLoading();
      return downloadCertificate(certificateData.data);
    } else {
      setTimeout(
        () =>
          handleCertificateStatusAndDownload(
            certificate,
            errorCallback,
            setLoading
          ),
        1000
      );
    }
  } catch {
    errorCallback({
      type: 'error',
      message:
        'Erro ao gerar seu certificado, tente novamente em alguns instantes.',
      description: 'Ops!'
    });
  }
};

export const handleIssueCertificate = async (
  data: IIssueCertificate,
  open: (params: OpenNotificationParams) => void,
  setLoading: () => void
) => {
  const certificateRes = await getAPI()
    .post<IIssuedCertificate>('/admin/graduate/courses/certificates', data)
    .catch(() => {
      open({
        type: 'error',
        description: 'Ops!',
        message:
          'Erro ao gerar seu certificado, tente novamente em alguns instantes.'
      });
    });

  if (certificateRes?.data) {
    return handleCertificateStatusAndDownload(
      certificateRes.data,
      open!,
      setLoading
    );
  }
  return null;
};

export const generateURLWithFilters = <T>(
  filters: T | undefined,
  url: string
): string => {
  if (!filters) {
    return url;
  }

  const queryString = Object.keys(filters)
    .filter(
      key =>
        filters[key as keyof T] !== undefined &&
        filters[key as keyof T] !== null
    )
    .map(
      key =>
        `filter[${encodeURIComponent(key)}]=${encodeURIComponent(
          String(filters[key as keyof T])
        )}`
    )
    .join('&');

  const separator = url.includes('?') ? '&' : '?';

  return queryString ? `${url}${separator}${queryString}` : url;
};
