import { IDocumentLog } from 'interfaces/documentation/documentations';
import { getAPI } from 'requests/api';

export const getDocumentLogs = async ({
  documentationId,
  documentId
}: {
  documentationId: string | undefined;
  documentId: string | null;
}): Promise<IDocumentLog[]> => {
  const { data } = await getAPI().get(
    `/admin/documentations/${documentationId}/documents/${documentId}/logs`
  );

  return data;
};
