import { IDocumentTemplate } from 'interfaces/documentation/document-template';
import { Modal } from 'antd';
import { download } from 'services/documents';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDocumentationStore } from '../documentation-store';
import { useOne } from '@refinedev/core';

export const ModalShowDocument = () => {
  const modal = useDocumentationStore(s => s.modal);
  const editDocument = useDocumentationStore(s => s.editDocument);
  const closeModal = useDocumentationStore(s => s.closeModal);

  const [fileUrl, setFileUrl] = useState<string>('');

  const visible = useMemo(() => modal === 'show', [modal]);

  const { data } = useOne<IDocumentTemplate>({
    resource: 'documents/templates',
    id: editDocument?.template_id || ''
  });

  const getFile = useCallback(async () => {
    if (editDocument) {
      const fileData = await download(editDocument?.id);
      setFileUrl(fileData.url);
    }
    return null;
  }, [editDocument]);

  useEffect(() => {
    if (visible) {
      getFile();
    }
  }, [editDocument, getFile, modal, visible]);

  return (
    <Modal
      visible={visible}
      title={data?.data.name || 'Documento não classificado'}
      footer={null}
      width={1200}
      bodyStyle={{ height: 'calc(100vh - 200px)' }}
      onCancel={() => {
        closeModal();
        setFileUrl('');
      }}
      destroyOnClose
    >
      {!fileUrl ? (
        'Nenhum documento disponível'
      ) : (
        <iframe
          className="flex items-center justify-center w-full h-full"
          src={fileUrl}
        />
      )}
    </Modal>
  );
};
