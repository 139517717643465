import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IProduct } from 'interfaces/products';
import { IProductSet } from 'interfaces/product_sets';

export const ProductSetCreate = () => {
  const { formProps, saveButtonProps } = useForm<IProductSet>();
  const { selectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar set">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Produtos" name="product_ids">
          <Select {...selectProps} allowClear mode="multiple" />
        </Form.Item>
      </Form>
    </Create>
  );
};
