import { IActionLog } from 'interfaces/action_log';
import { Show } from '@refinedev/antd';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const ActionLogShow = () => {
  const { queryResult } = useShow<IActionLog>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar bônus">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Id do recurso</Title>
      <Text>{record?.resource_id}</Text>
      <Title level={5}>Tipo do recurso</Title>
      <Text>{record?.resource_kind}</Text>
      <Title level={5}>Informações do recurso</Title>
      <Text>{record?.resource_info.toString()}</Text>
    </Show>
  );
};
