import FilterForm from '../../../components/forms/FilterForm';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { CrudFilters, HttpError, useNavigation } from '@refinedev/core';
import {
  EditButton,
  List,
  TagField,
  TextField,
  useSelect,
  useTable
} from '@refinedev/antd';
import { ICourse } from 'interfaces/courses';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { IOffer } from '../../../interfaces/offers';
import { IProduct } from 'interfaces/products';
import { IProductCategory } from 'interfaces/product_categories';
import { PRODUCTABLE_TYPES } from 'constants/admin/products';
import { useState } from 'react';

interface IFilter {
  name: string;
  productable_type: string;
  productable_id: string;
}

export const ProductList = () => {
  const { edit } = useNavigation();
  const [productableType, setProductableType] = useState<string>('Course');

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: graduateCourseSelectProps } = useSelect<IGraduateCourse>(
    {
      resource: 'graduate_courses',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const { tableProps, searchFormProps } = useTable<
    IProduct,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { name, productable_type, productable_id } = params;

      filters.push(
        {
          field: 'filter[name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[productable_type]',
          operator: 'eq',
          value: productable_type
        },
        {
          field: 'filter[productable_id]',
          operator: 'eq',
          value: productable_id
        }
      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Nome" name="name">
              <Input placeholder="Nome do produto..." />
            </Form.Item>

            <Form.Item
              label="Tipo de produto"
              name="productable_type"
              initialValue={PRODUCTABLE_TYPES[0].value}
            >
              <Select
                placeholder="Selecione..."
                style={{ minWidth: 200 }}
                onChange={value => setProductableType(value)}
              >
                {PRODUCTABLE_TYPES.map(type => (
                  <Select.Option key={type.value}>{type.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Curso" name="productable_id">
              {productableType === 'Course' ? (
                <Select {...courseSelectProps} style={{ minWidth: 200 }} />
              ) : (
                <Select
                  {...graduateCourseSelectProps}
                  style={{ minWidth: 200 }}
                />
              )}
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="productable_type"
              key="type"
              title="Tipo"
              render={value => (
                <TextField
                  value={
                    PRODUCTABLE_TYPES.find(type => type.value === value)?.label
                  }
                />
              )}
            />
            <Table.Column
              dataIndex="slug"
              key="slug"
              title="Slug"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="categories"
              key="categories"
              title="Categorias"
              render={categories =>
                categories.map((category: IProductCategory) => (
                  <TagField
                    style={{ border: 'none', margin: 4, cursor: 'pointer' }}
                    color="purple"
                    key={category.id}
                    value={category.name}
                    onClick={() =>
                      edit('ecommerce/products/categories', category.id)
                    }
                  />
                ))
              }
            />
            <Table.Column
              dataIndex="offers"
              key="offers"
              title="Possui oferta ativa?"
              render={offers =>
                offers.some((offer: IOffer) =>
                  ['active', 'hidden'].includes(offer.status)
                ) ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <CloseCircleTwoTone twoToneColor="#D92D20" />
                )
              }
            />
            <Table.Column<IProduct>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
