import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, InputNumber, Select, Typography } from 'antd';
import { IProduct } from 'interfaces/products';
import { IProductCategory } from 'interfaces/product_categories';
import {
  KINDS,
  getConfigFields,
  translateKind
} from 'services/offers/campaigns';
import { TOfferCampaign, TOfferCampaignKind } from 'interfaces/offers';
import { useState } from 'react';

const { Title } = Typography;

export const OfferCampaignCreate = () => {
  const { formProps, saveButtonProps } = useForm<TOfferCampaign>();
  const [kindKey, setKindKey] = useState<TOfferCampaignKind | null>(null);
  const configFields = kindKey ? getConfigFields(kindKey) : new Map();

  const { selectProps: productSelectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: productCategoriesSelectProps } =
    useSelect<IProductCategory>({
      resource: 'products/categories',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    });

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar campanha">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="kind"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select onChange={setKindKey}>
            {KINDS.map(kind => (
              <Select.Option key={kind}>{translateKind(kind)}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        {configFields && (
          <>
            <Title level={5}>Configurações</Title>
            {[...configFields].map(([key, configField]) => (
              <Form.Item
                key={key}
                label={configField.name}
                name={['config', key]}
              >
                {configField.input === 'price' ? (
                  <InputNumber addonBefore="R$" precision={2} />
                ) : configField.input === 'percentage' ? (
                  <InputNumber addonAfter="%" precision={0} />
                ) : configField.input === 'product_ids' ? (
                  <Select {...productSelectProps} mode="multiple" />
                ) : configField.input === 'category_ids' ? (
                  <Select {...productCategoriesSelectProps} mode="multiple" />
                ) : (
                  <Input />
                )}
              </Form.Item>
            ))}
          </>
        )}
      </Form>
    </Create>
  );
};
