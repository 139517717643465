import { Col, Row, Table, TableProps } from 'antd';
import { IForum } from 'interfaces/forum';
import { ShowButton, TagField, TextField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { forumStatusOptions } from 'constants/admin/forum';

export interface ForumTableProps {
  tableProps: TableProps<IForum>;
}

export const ForumTable = ({ tableProps }: ForumTableProps) => {
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        width="15%"
        dataIndex="title"
        key="title"
        title="Título do tópico"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        width="10%"
        dataIndex="author"
        key="author"
        title="Autor"
        render={value => <TextField value={value.name} />}
      />
      <Table.Column<IForum>
        width="20%"
        dataIndex="grandparent"
        key="grandparent"
        title="Local"
        render={(value, record) => (
          <>
            {record?.grandparent_type === 'Graduate::Course' ? (
              <Row>
                <TextField value="Curso:" />
                <TextField className="ml-1" strong value={value.name} />
              </Row>
            ) : (
              <Col>
                <Row>
                  <TextField value="Cód. da turma:" />
                  <TextField className="ml-1" strong value={value.code} />
                </Row>
                <Row style={{ flexWrap: 'unset' }}>
                  <TextField value={`Turma:`} style={{ minWidth: 45 }} />
                  <TextField
                    className="ml-1"
                    strong
                    value={value?.discipline?.name}
                  />
                </Row>
              </Col>
            )}
          </>
        )}
      />
      <Table.Column
        dataIndex="posts_count"
        key="posts_count"
        title="Respostas"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        width="10%"
        dataIndex="created_at"
        key="created_at"
        title="Data"
        render={value => (
          <TextField value={formatDate(value, 'DD/MM/YYYY HH:mm')} />
        )}
      />
      <Table.Column<IForum>
        width="10%"
        dataIndex="last_posted_at"
        key="last_posted_at"
        title="Última atualização"
        render={(value, record) => (
          <TextField
            value={formatDate(value ?? record.created_at, 'DD/MM/YYYY HH:mm')}
          />
        )}
      />
      <Table.Column<IForum>
        dataIndex="status"
        key="status"
        title="Status"
        render={(value, record) => (
          <TagField
            color={
              record.deleted_at
                ? 'red'
                : forumStatusOptions.find(option => option.value === value)
                    ?.color
            }
            value={
              record.deleted_at
                ? 'Excluído'
                : forumStatusOptions.find(option => option.value === value)
                    ?.label
            }
          />
        )}
      />
      <Table.Column<IForum>
        title="Ações"
        dataIndex="actions"
        align="center"
        render={(_, record) => {
          return (
            <ShowButton
              resource="forums"
              hideText
              size="small"
              recordItemId={record.id}
              meta={{ kind: 'classrooms' }}
            />
          );
        }}
      />
    </Table>
  );
};
