import { IClassroomEvaluation } from 'interfaces/classrooms';
import { parseToDayjs } from 'services/date';

export const sanitizeEvaluations = (
  arr: IClassroomEvaluation[] | undefined
): IClassroomEvaluation[] | [] =>
  arr?.filter(obj => obj.kind !== 'replacement') || [];

export const getEvaluationPeriod = (
  requirements: IClassroomEvaluation['requirements']
) => {
  return {
    dateRange: [
      parseToDayjs(
        requirements?.[0]?.rules?.find(rule => rule.operator === 'after')?.value
      ),
      parseToDayjs(
        requirements?.[0]?.rules?.find(rule => rule.operator === 'before')
          ?.value
      )
    ]
  };
};
