import { IDocumentTemplate } from 'interfaces/documentation/document-template';
import { Table } from 'antd';
import { TextField } from '@refinedev/antd';
import { useList } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const categoryMAP = {
  identification: 'Documento de identificação',
  academic: 'Documento academico',
  supplementary: 'Documento complementar'
};

const TabDocumentation = () => {
  const { id: courseId } = useParams();

  const { data: suplementary } = useList<IDocumentTemplate[]>({
    resource: 'documents/templates',

    filters: [
      { field: 'filter[by_course_id]', operator: 'eq', value: courseId }
    ]
  });

  const { data: statics } = useList<IDocumentTemplate[]>({
    resource: 'documents/templates',

    filters: [
      {
        field: 'filter[by_category]',
        operator: 'eq',
        value: ['academic', 'identification']
      }
    ]
  });

  return (
    <Table
      dataSource={[...(statics?.data || []), ...(suplementary?.data || [])]}
    >
      <Table.Column
        title="Categoria"
        dataIndex="category"
        key="category"
        render={(value: keyof typeof categoryMAP) => (
          <TextField value={categoryMAP[value]} />
        )}
      />
      <Table.Column title="Nome do documento" dataIndex="name" key="name" />
      <Table.Column
        title="Obrigatório"
        dataIndex="required"
        key="required"
        render={(value: boolean) => <TextField value={value ? 'Sim' : 'Não'} />}
      />
    </Table>
  );
};

export default TabDocumentation;
