import backCertificate from '../../assets/backgrounds/certificate-back.png';
import frontCertificate from '../../assets/backgrounds/certificate-front.png';
import { ISignaturePartnersGet } from 'interfaces/signature_partners';
import { Space, Typography } from 'antd';

export interface PreviewCertificateProps {
  signaturePartner: ISignaturePartnersGet;
  type: 'back' | 'front';
}

export const PreviewCertificate = ({
  signaturePartner,
  type
}: PreviewCertificateProps) => {
  const background = type === 'front' ? frontCertificate : backCertificate;
  const FrontSignature = () => (
    <Space
      direction="vertical"
      className="absolute bottom-[20px] left-[125px] right-0 w-[150px] items-center !gap-0"
    >
      <img
        src={signaturePartner.front_signature.signature}
        className="w-[100px] h-[60px]"
        alt={signaturePartner.name}
      />
      <div className="border solid border-1 border-black w-[150px]" />
      <Typography.Text strong className="!text-[10px]">
        {signaturePartner.front_signature.title}
      </Typography.Text>
    </Space>
  );

  const BackSignatures = () => (
    <Space
      direction="horizontal"
      className="absolute bottom-[20px] left-[35px] right-0 w-[150px]"
    >
      <Space direction="horizontal" className="!gap-8">
        <Space direction="vertical" className="items-center !gap-0">
          <img
            src={signaturePartner.back_signature.signature}
            className="w-[100px] h-[60px]"
            alt={signaturePartner.back_signature.name}
          />
          <div className="border solid border-1 border-black w-[150px]" />
          <Typography.Text strong className="!text-[10px]">
            {signaturePartner.back_signature.title}
          </Typography.Text>
        </Space>
        <Space direction="vertical" className="items-center !gap-0">
          <img
            src={signaturePartner.record_back_signature.signature}
            className="w-[100px] h-[60px]"
            alt={signaturePartner.record_back_signature.name}
          />
          <div className="border solid border-1 border-black w-[150px]" />
          <Typography.Text strong className="!text-[10px]">
            {signaturePartner.record_back_signature.title}
          </Typography.Text>
        </Space>
      </Space>
    </Space>
  );

  return (
    <div className="w-[400px] h-[300px] relative">
      <img
        src={background}
        className="w-[400px] h-[300px] absolute top-0 left-0 right-0"
        alt="background"
      />
      {type === 'front' && <FrontSignature />}
      {type === 'back' && <BackSignatures />}
    </div>
  );
};
