import { OrderRefundList } from 'pages/ecommerce/order_refunds';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'order_refunds',
  label: 'Reembolso',
  list: OrderRefundList,
  actions: ['list'],
  parentName: 'finance'
};

export default generateResources(resource);
