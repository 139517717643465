import { ACADEMIC_RECORD_STATUSES_MAP } from 'services/graduate-enrollment';
import { IClassroomsEnrollmentsData } from 'services/graduate-enrollment/evaluations';
import { Space, Tag, Typography } from 'antd';
import { useMemo } from 'react';

interface ClasssroomEnrollmentHeaderProps {
  classroomEnrollment: IClassroomsEnrollmentsData;
}

export const ClasssroomEnrollmentHeader = ({
  classroomEnrollment
}: ClasssroomEnrollmentHeaderProps) => {
  const status = useMemo(() => {
    if (classroomEnrollment.academic_record_status) {
      return ACADEMIC_RECORD_STATUSES_MAP[
        classroomEnrollment.academic_record_status
      ];
    }

    return {
      color: 'blue',
      label: 'Em andamento'
    };
  }, [classroomEnrollment.academic_record_status]);

  return (
    <Space size="large" className="justify-between w-full">
      <Typography.Text strong className="flex-1">
        {classroomEnrollment.classroom.discipline.name} - Turma:{' '}
        {classroomEnrollment.classroom.code}
      </Typography.Text>

      <Space size="small" className="min-w-[200px] justify-end text-right">
        {classroomEnrollment.attendance_rate && (
          <Typography.Text>
            Frequência: {`${classroomEnrollment.attendance_rate}%`}
          </Typography.Text>
        )}
        {classroomEnrollment.academic_record_score && (
          <Typography.Text>
            Nota: {classroomEnrollment.academic_record_score || '-'}
          </Typography.Text>
        )}
        <div
          className={
            status.label !== 'Em andamento' ? 'min-w-[90px] text-right' : ''
          }
        >
          <Tag color={status.color}>{status.label}</Tag>
        </div>
      </Space>
    </Space>
  );
};
