import * as Icons from '@ant-design/icons';
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Typography
} from 'antd';
import { Edit, TagField, useForm } from '@refinedev/antd';
import {
  IAssignmentAttempt,
  IAssignmentAttemptCriterionFile,
  IAssignmentAttemptCriterionFileItem
} from 'interfaces/assignments';
import { tagColorByStatus } from '../../../utils/statusColorMapping';
import { translateAttemptStatus } from '../../../services/assignments';

export const AssessmentAttemptEdit = () => {
  const { formProps, saveButtonProps, queryResult } =
    useForm<IAssignmentAttempt>({
      redirect: 'list'
    });

  const attempt = queryResult?.data?.data;

  const disabled = ['failed', 'passed'].includes(attempt?.status ?? '');

  return (
    <Edit
      title="Corrigir tarefa"
      saveButtonProps={{ ...saveButtonProps, disabled }}
    >
      {disabled ? (
        <Alert
          style={{ marginBottom: 20 }}
          description={
            <>
              Essa tarefa já foi avaliada como{' '}
              <TagField
                color={tagColorByStatus(attempt?.status ?? '')}
                value={translateAttemptStatus(attempt?.status ?? '')}
              />
              e não pode ser modificada
            </>
          }
          type="warning"
        />
      ) : (
        <Alert
          style={{ marginBottom: 20 }}
          description={
            <>
              Você está avaliando a tarefa: <b>{attempt?.assignment_name}</b>
              <br />
              do curso/disciplina de: <b>{attempt?.course_name}</b>
            </>
          }
          type="info"
        />
      )}
      <Typography.Title level={5}>
        1. Faça o download dos arquivos necessários para a avaliar
      </Typography.Title>
      {attempt?.criteria_files.map(
        (criteria_file: IAssignmentAttemptCriterionFile) => (
          <>
            <Typography.Title level={5}>
              Critério: {criteria_file.name}
            </Typography.Title>
            {criteria_file.items.map(
              (item: IAssignmentAttemptCriterionFileItem) => (
                <>
                  <Button href={item.url} target="_blank" className="m-2">
                    <Icons.DownloadOutlined />
                    {item.name}
                  </Button>
                </>
              )
            )}
          </>
        )
      )}
      <br />
      <Divider />
      <Typography.Title level={5}>
        2. Avalie o trabalho do aluno
      </Typography.Title>
      <Form disabled={disabled} {...formProps} layout="vertical">
        <Form.Item
          label="Nota (0 - 100)"
          name="score"
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber min={0} max={100} />
        </Form.Item>
        <Form.Item
          label="Feedback"
          name="feedback"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea style={{ width: 500, height: 150 }} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
