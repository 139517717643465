import { IMoney } from 'interfaces/money';

interface currencyIsoFormatsParams {
  currencyIso: 'BRL';
  amount: string;
}

const currencyFormat = {
  BRL: (amount: string) => `R$ ${amount}`
};

const currencyIsoFormats = ({
  currencyIso,
  amount
}: currencyIsoFormatsParams) => {
  const formatFn = currencyFormat[currencyIso];
  return formatFn ? formatFn(amount) : amount;
};

export const formatMoney = ({ cents, currency_iso }: IMoney) =>
  currencyIsoFormats({
    currencyIso: currency_iso,
    amount: (cents / 100).toFixed(2)
  });

export const moneyAsLocaleString = (
  money: IMoney,
  locale = 'pt-BR',
  currency = 'BRL'
) =>
  (money.cents / 100).toLocaleString(locale, {
    style: 'currency',
    currency
  });
