export const tabs = [
  {
    key: 'general',
    tab: 'Visão geral'
  },
  {
    key: 'content',
    tab: 'Conteúdo'
  },
  {
    key: 'interactiveClass',
    tab: 'Aulas interativas'
  },
  {
    key: 'evaluations',
    tab: 'Avaliações'
  },
  {
    key: 'forums',
    tab: 'Fórum'
  },
  {
    key: 'notice-board',
    tab: 'Mural de avisos'
  },
  {
    key: 'supplementary-materials',
    tab: 'Material complementar'
  },
  {
    key: 'students',
    tab: 'Alunos'
  }
];
