import dayjs from 'dayjs';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { DatePicker, Form, Input, Select } from 'antd';
import { ICertificateKey } from 'interfaces/courses';
import { ICourseCertificate } from 'interfaces/course_certificate';
import { IUser } from 'interfaces/users';

export const CertificateCreate = () => {
  const { formProps, saveButtonProps } = useForm<ICourseCertificate>();
  const { selectProps: userSelectProps } = useSelect<IUser>({
    resource: 'users',
    optionLabel: 'email',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[email]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: certificateKeySelectProps } = useSelect<ICertificateKey>(
    {
      resource: 'certificate_keys',
      optionLabel: 'key',
      optionValue: 'key',

      pagination: {
        mode: 'server'
      }
    }
  );

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar certificado">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Usuário"
          name="user_id"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...userSelectProps} />
        </Form.Item>

        <Form.Item
          label="Nome"
          name="recipient_name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Curso"
          name="course_name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Duração (horas)"
          name="duration_in_hours"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Template"
          name="template_key"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...certificateKeySelectProps} />
        </Form.Item>

        <Form.Item
          label="Data de conclusão"
          name="completed_at"
          rules={[
            {
              required: true
            }
          ]}
          getValueProps={value => ({ value: value ? dayjs(value) : '' })}
        >
          <DatePicker />
        </Form.Item>
      </Form>
    </Create>
  );
};
