import { EcommerceDivisionCreate } from '../pages/ecommerce/ecommerce_divisions/create';
import {
  EcommerceDivisionEdit,
  EcommerceDivisionList,
  EcommerceDivisionShow
} from 'pages/ecommerce/ecommerce_divisions';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'ecommerce_divisions',
  label: 'Divisões',
  list: EcommerceDivisionList,
  show: EcommerceDivisionShow,
  create: EcommerceDivisionCreate,
  edit: EcommerceDivisionEdit,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'sales'
};

export default generateResources(resource);
