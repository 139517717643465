import { Col, Row, Typography } from 'antd';
import { IFAQCoordinatorPost } from 'interfaces/faq_coordinators';
import { formatDate } from 'services/date';
import { styled } from '@qcx/ui';

export interface PostProps {
  post: IFAQCoordinatorPost;
}

export const Post = ({ post }: PostProps) => {
  return (
    <Row className="w-full mb-4">
      <Row className="flex items-center justify-between w-full !text-neutral-500">
        <Col>
          <Row className="gap-2">{post.author.name}</Row>
          <Row>
            {formatDate(
              post.updated_at ?? post.created_at,
              'DD/MM/YYYY à[s] HH:mm'
            )}
          </Row>
        </Col>
      </Row>
      <Row className="mt-2 w-full">
        <StyledParagraph>{post.text}</StyledParagraph>
      </Row>
    </Row>
  );
};

const StyledParagraph = styled(Typography.Paragraph, {
  marginBottom: '0 !important',
  whiteSpace: 'pre-line'
});
