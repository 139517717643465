import * as Icons from '@ant-design/icons';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { ClassroomCreateForm } from '../../../classrooms/components/ClassroomCreateForm';
import { ClassroomTable } from '../../../classrooms/components/ClassroomTable';
import { EditButton, useModalForm, useTable } from '@refinedev/antd';
import { IClassroom } from '../../../../interfaces/classrooms';
import { styled } from '@qcx/ui';
import { useParams } from 'react-router-dom';

export const TabClassrooms = () => {
  const { id: disciplineId } = useParams();

  const resource = 'classrooms';

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow
  } = useModalForm<IClassroom>({
    action: 'create',
    resource,
    redirect: false
  });

  const { tableProps } = useTable<IClassroom>({
    resource,

    filters: {
      initial: [
        {
          field: 'filter[by_discipline_id]',
          operator: 'eq',
          value: disciplineId
        }
      ]
    }
  });
  return (
    <>
      <StyledSpace align="center">
        <Typography.Title level={5} style={{ margin: 0, color: 'GrayText' }}>
          Turmas da disciplina
        </Typography.Title>
        <Button icon={<Icons.PlusOutlined />} onClick={() => createModalShow()}>
          Nova turma
        </Button>
      </StyledSpace>
      <ClassroomTable tableProps={tableProps}>
        <Table.Column<IClassroom>
          title="Ações"
          align="right"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  resource={resource}
                />
              </Space>
            );
          }}
        />
      </ClassroomTable>
      <Modal {...createModalProps} title="Nova turma">
        <ClassroomCreateForm
          formProps={createFormProps}
          disciplineId={disciplineId}
        />
      </Modal>
    </>
  );
};

const StyledSpace = styled(Space, {
  my: '$4',
  justifyContent: 'space-between',
  w: '$full'
});
