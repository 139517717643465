import { Card, Col, Progress, Row, Tabs, Tooltip } from 'antd';
import { IEnrollment } from 'interfaces/enrollments';
import { RefreshButton, Show } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { TabAcademicHistory } from './components/TabAcademicHistory';
import { TabCurriculumGrid } from './components/TabCurriculumGrid';
import { TabDeclarations } from './components/TabDeclarations';
import { TabEvaluations } from './components/TabEvaluations/TabEvaluations';
import { TabSummary } from './components/TabSummary';
import { useParams } from 'react-router-dom';
import { useShow } from '@refinedev/core';

export const GraduateEnrollmentShow = () => {
  const params = useParams();
  const enrollmentId = params.id;

  const { queryResult } = useShow<IEnrollment>({
    resource: 'graduate/courses/enrollments',
    id: enrollmentId,
    errorNotification: {
      type: 'error',
      message: 'Erro ao carregar dados. Tente novamente!'
    }
  });

  const result = queryResult?.data?.data;

  return (
    <Show
      title="Visualizar matrícula de pós-graduação"
      contentProps={{ className: '[&>div:bg-red-200' }}
      isLoading={queryResult.isLoading}
      headerButtons={<RefreshButton />}
    >
      {result && (
        <>
          <Card size="small" className="!mb-6">
            <Row>
              <Col xs={24} md={13}>
                <ShowFieldData label="Curso" value={result?.course.name} />
              </Col>
              <Col xs={24} md={8}>
                <ShowFieldData label="Aluno" value={result?.user.name} />
              </Col>
              <Col xs={24} md={3}>
                <ShowFieldData
                  label="Progresso"
                  value={
                    <Tooltip title={`${result?.progress_percentage}%`}>
                      <Progress
                        size="small"
                        showInfo={false}
                        percent={result?.progress_percentage}
                      />
                    </Tooltip>
                  }
                />
              </Col>
            </Row>
          </Card>

          <Tabs defaultActiveKey="summary">
            <Tabs.TabPane tab="Resumo" key="summary">
              <TabSummary enrollment={result} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Grade curricular" key="curriculum_grid">
              <TabCurriculumGrid
                courseId={result?.course.id}
                enrollmentId={result.id}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Histórico acadêmico" key="academic_history">
              <TabAcademicHistory
                courseId={result?.course.id}
                enrollmentId={result?.id}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Atividades e notas" key="evaluations">
              <TabEvaluations courseEnrollmentId={result?.id} />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Declarações" key="declarations">
              <TabDeclarations
                courseEnrollmentId={result?.id}
                user={result?.user}
              />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </Show>
  );
};
