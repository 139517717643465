export const removeDuplicateObjectsById = <T extends { id: string }>(
  arr: T[]
): T[] => {
  const seenIds: Record<string, unknown> = {};

  return arr.filter(item => {
    if (!seenIds[item.id]) {
      seenIds[item.id] = true;
      return true;
    }
    return false;
  });
};
