import FilterForm from 'components/forms/FilterForm';
import { Col, Form, FormProps, Input, Row, Select } from 'antd';

export interface INoticeBoardFilterForm {
  title: string;
  kind: string;
  priority: number;
}

interface UsersFilterFormProps {
  searchFormProps: FormProps<INoticeBoardFilterForm>;
}

export const NoticeBoardListFilter = ({
  searchFormProps
}: UsersFilterFormProps) => {
  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={[16, 16]} className="w-full">
        <Col xs={24} md={12}>
          <Form.Item label="Título" name="title">
            <Input placeholder="Informe o título do aviso" />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item label="Destino" name="kind">
            <Select placeholder="Selecione o tipo" allowClear>
              <Select.Option value="course">Curso avulso</Select.Option>
              <Select.Option value="graduate_course">
                Curso regulado
              </Select.Option>
              <Select.Option value="classroom">Turma</Select.Option>
              <Select.Option value="general">Instituição</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item label="Prioridade" name="priority">
            <Select placeholder="Selecione a prioridade" allowClear>
              <Select.Option value="regular">Normal</Select.Option>
              <Select.Option value="high">Alta</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
