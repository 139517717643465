import * as Icons from '@ant-design/icons';
import { Collapse, Select, Tag } from 'antd';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';
import { IOffer, TOfferStatus } from '../../../../interfaces/offers';
import { OfferStatus } from '../../../../components/offers/OfferStatus';
import { getTenant } from '../../../../services/tenants';
import { moneyAsLocaleString } from '../../../../services/money';

const displayPaymentConfig = (config: Record<string, unknown>) => {
  return config.max_times
    ? `${config.max_times}x, Financiamento: ${config.financing ? '✅' : '❌'}`
    : 'Desabilitado';
};

const daysToLabel = (days: number | null) => {
  if (days === null) {
    return 'Não definido';
  }

  return days > 1 ? `${days} dias` : `${days} dia`;
};

const tenant = getTenant();
const serviceDatesEnabled = !tenant.disabledFeatures?.includes('serviceDates');

export const OffersTab = ({
  offers,
  onStatusChange
}: {
  offers: IOffer[];
  onStatusChange: (offerId: string, status: TOfferStatus) => void;
}) => {
  const status: TOfferStatus[] = ['active', 'disabled', 'hidden'];

  const handleStatusChange = (offerId: string) => (status: TOfferStatus) =>
    onStatusChange(offerId, status);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-10">
      {offers
        ?.sort((offer, _) =>
          ['active', 'hidden'].includes(offer.status) ? -1 : 1
        )
        .map(offer => (
          <div
            className={'bg-white shadow-md rounded-lg max-h-fit'}
            key={offer.id}
          >
            <div className="p-6">
              <div className="mb-4">
                <Select
                  style={{ marginLeft: -11 }}
                  popupMatchSelectWidth={false}
                  variant="borderless"
                  placeholder={<OfferStatus status={offer.status} />}
                  onChange={handleStatusChange(offer.id)}
                  suffixIcon={
                    <Icons.FormOutlined
                      style={{ pointerEvents: 'none', color: 'InfoText' }}
                    />
                  }
                >
                  {status
                    .filter(s => s !== offer.status)
                    .map(status => (
                      <Select.Option key={status} value={status}>
                        <OfferStatus status={status} />
                      </Select.Option>
                    ))}
                </Select>
              </div>
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h2 className="text-xl font-semibold">{offer.name}</h2>
                  {offer.campaign?.name && (
                    <p className="text-sm text-gray-500">
                      {offer.campaign.name}
                    </p>
                  )}
                </div>
              </div>
              <div className="my-2">
                <div className="text-sm text-gray-600">
                  De{' '}
                  <span className="line-through">
                    {moneyAsLocaleString(offer.reference_price)}
                  </span>{' '}
                  por{' '}
                </div>
                <div>
                  <span className="text-xl font-semibold">
                    {moneyAsLocaleString(offer.price)}
                  </span>
                </div>
              </div>
              <span className="text-xs text-gray-500">
                <strong>ID:</strong> {offer.id}
                <div className="ml-1 inline-block">
                  <CopyToClipboard
                    text={offer.id}
                    icon={
                      <Icons.CopyOutlined
                        style={{ fontSize: 12, color: 'GrayText' }}
                      />
                    }
                  />
                </div>
              </span>
            </div>

            <Collapse
              bordered={false}
              expandIconPosition="end"
              style={{ padding: '0 8px' }}
            >
              <Collapse.Panel header="Mais detalhes" key={offer.id}>
                <p>
                  <strong>Período de acesso ao curso:</strong>{' '}
                  {daysToLabel(offer.items[0].time_in_days)}
                </p>

                <p>
                  <strong>Configuração de pagamento:</strong>
                  <div className="flex flex-col gap-2 mt-4">
                    <>
                      <Tag className="mr-2" bordered={false}>
                        <Icons.DollarOutlined /> Pix:{' '}
                        {displayPaymentConfig(offer.checkout_scheme_config.pix)}
                      </Tag>
                      <Tag className="mr-2" bordered={false}>
                        <Icons.BarcodeOutlined /> Boleto:{' '}
                        {displayPaymentConfig(
                          offer.checkout_scheme_config.boleto
                        )}
                      </Tag>
                      <Tag className="mr-2" bordered={false}>
                        <Icons.CreditCardOutlined /> com limite:{' '}
                        {displayPaymentConfig(
                          offer.checkout_scheme_config.credit_card
                        )}
                      </Tag>
                      <Tag className="mr-2" bordered={false}>
                        <Icons.CreditCardTwoTone /> sem limite:{' '}
                        {offer.checkout_scheme_config.financing_credit_card
                          .max_times
                          ? `${offer.checkout_scheme_config.financing_credit_card.max_times}`
                          : '-'}
                        , Habilitado:{' '}
                        {offer.checkout_scheme_config.financing_credit_card
                          .financing
                          ? '✅'
                          : '❌'}
                      </Tag>
                    </>
                  </div>
                </p>
                <p>
                  <strong>Regra de Gateway:</strong> {offer.gateway_rule?.name}
                </p>
                <p>
                  <strong>Regra de Parcelamento:</strong>{' '}
                  {offer?.payment_method_rule?.name}
                </p>
                <p>
                  <strong>Centro de Resultado:</strong>{' '}
                  {offer.profit_center?.code}
                </p>
                {serviceDatesEnabled && (
                  <>
                    <p>
                      <strong>Início do serviço:</strong>{' '}
                      {offer.service_start_at
                        ? new Date(offer.service_start_at).toLocaleDateString()
                        : 'Na compra'}
                    </p>
                    <p>
                      <strong>Duração do serviço:</strong>{' '}
                      {offer.service_duration_in_months
                        ? `${offer.service_duration_in_months} ${
                            offer.service_duration_in_months > 1
                              ? 'meses'
                              : 'mês'
                          }`
                        : ''}
                    </p>
                  </>
                )}
                <p>
                  <strong>Tipo:</strong>{' '}
                  {offer.kind === 'online'
                    ? 'Vitrine'
                    : offer.kind === 'management'
                    ? 'Manual'
                    : '-'}
                </p>
              </Collapse.Panel>
            </Collapse>
          </div>
        ))}
    </div>
  );
};
