import { ClassroomCreateForm } from './components/ClassroomCreateForm';
import { Create, useForm } from '@refinedev/antd';
import { IClassroom } from 'interfaces/classrooms';

export const ClassroomCreate = () => {
  const { formProps, saveButtonProps } = useForm<IClassroom>({
    redirect: 'edit'
  });

  return (
    <Create title="Criar turma" saveButtonProps={saveButtonProps}>
      <ClassroomCreateForm formProps={formProps} />
    </Create>
  );
};
