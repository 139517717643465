import { IResource } from 'interfaces/resources';
import { LibraryContentShow } from 'pages/libraries/contents/show';
import { generateResources } from 'utils/GenerateResouces';

const resourceName = 'library_contents';
const parentName = 'teaching_resources';

const resource: IResource = {
  name: resourceName,
  label: 'Conteúdo',
  showConfig: {
    component: LibraryContentShow,
    path: `${parentName}/libraries/:library_id/contents/:id`
  },
  actions: ['list', 'create', 'edit'],
  parentName,
  hide: true
};

export default generateResources(resource);
