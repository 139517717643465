import {
  GatewayRuleCreate,
  GatewayRuleEdit,
  GatewayRuleList,
  GatewayRuleShow
} from 'pages/ecommerce/gateway_rules';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'offers/gateways/rules',
  label: 'Regras de Gateway',
  list: GatewayRuleList,
  show: GatewayRuleShow,
  edit: GatewayRuleEdit,
  create: GatewayRuleCreate,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'finance'
};

export default generateResources(resource);
