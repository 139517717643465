import * as Icons from '@ant-design/icons';
import AssignmentAttemptLogModal from 'components/modal/evaluations/assignment/AssignmentAttemptLogModal';
import { AssignmentEditScoreModal } from 'components/modal/evaluations/assignment/AssignmentEditScoreModal';
import { Button, Divider, Space, Typography } from 'antd';
import {
  IAssignmentAttempt,
  IAssignmentAttemptCriterionFile,
  IAssignmentAttemptCriterionFileItem
} from 'interfaces/assignments';
import { Show, TagField } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { tagColorByStatus } from '../../../utils/statusColorMapping';
import { translateAttemptStatus } from '../../../services/assignments';
import { useModal, useShow } from '@refinedev/core';

const { Title } = Typography;

export const AssignmentAttemptShow = () => {
  const { queryResult } = useShow<IAssignmentAttempt>();
  const logModal = useModal();
  const editScoreModal = useModal();

  const attempt = queryResult?.data?.data;

  const onViewLogModal = () => {
    logModal.show();
  };

  const onEditScoreModal = () => {
    editScoreModal.show();
  };

  return (
    <Show
      headerButtons={
        <>
          <Button
            icon={<Icons.EyeOutlined />}
            onClick={onEditScoreModal}
            disabled={
              !!attempt?.status &&
              !['passed', 'failed'].includes(attempt?.status)
            }
          >
            Editar nota
          </Button>

          <Button icon={<Icons.EyeOutlined />} onClick={onViewLogModal}>
            Visualizar alterações
          </Button>
        </>
      }
      title="Visualizar tarefa realizada"
    >
      <Space direction="vertical" size="middle">
        <ShowFieldData
          label="Status"
          value={
            <TagField
              color={tagColorByStatus(attempt?.status || '')}
              value={translateAttemptStatus(attempt?.status ?? '')}
            />
          }
        />

        {attempt?.evaluator?.name && (
          <ShowFieldData label="Avaliador" value={attempt?.evaluator?.name} />
        )}

        <ShowFieldData label="Tarefa" value={attempt?.assignment_name} />

        <ShowFieldData label="Curso/Disciplina" value={attempt?.course_name} />

        <ShowFieldData label="Nota" value={attempt?.score ?? '--'} />

        <ShowFieldData label="Feedback" value={attempt?.feedback ?? '--'} />
      </Space>

      <Divider />

      <Title level={5}>Arquivos: </Title>
      <Space direction="vertical" size="middle">
        {attempt?.criteria_files.map(
          (criteria_file: IAssignmentAttemptCriterionFile) => (
            <Space direction="vertical" key={criteria_file.id}>
              <Typography.Text className="font-medium">
                Critério: {criteria_file.name}
              </Typography.Text>

              {criteria_file.items.map(
                (item: IAssignmentAttemptCriterionFileItem) => (
                  <>
                    <Button href={item.url} target="_blank" key={item.id}>
                      <Icons.DownloadOutlined />
                      {item.name}
                    </Button>
                  </>
                )
              )}
            </Space>
          )
        )}
      </Space>

      {attempt?.id && logModal.visible && (
        <AssignmentAttemptLogModal attemptId={attempt.id} modal={logModal} />
      )}

      {attempt?.id && editScoreModal.visible && (
        <AssignmentEditScoreModal attempt={attempt} modal={editScoreModal} />
      )}
    </Show>
  );
};
