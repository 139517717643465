import { IOrderItem } from 'interfaces/orders';
import { Table } from 'antd';
import { TextField } from '@refinedev/antd';
import { formatMoney } from 'services/money';
import { translateStatus } from 'services/order-item';

interface InvoiceTableProps {
  items: IOrderItem[];
}

const InvoiceTable = ({ items }: InvoiceTableProps) => (
  <Table
    dataSource={items}
    rowKey="id"
    pagination={false}
    bordered
    scroll={{ x: true }}
  >
    <Table.Column
      dataIndex="name"
      key="name"
      title="Nome"
      render={name => <TextField value={name} />}
    />
    <Table.Column
      dataIndex="amount"
      key="amount"
      title="Valor"
      render={money => <TextField value={formatMoney(money)} />}
    />
    <Table.Column
      dataIndex="status"
      key="status"
      title="Status"
      render={name => <TextField value={translateStatus(name)} />}
    />
  </Table>
);

export default InvoiceTable;
