import {
  IResource,
  IResourceAndRoutes,
  TResourceAction
} from 'interfaces/resources';
import { RESOURCES } from 'resources';
import { TPermission } from 'interfaces/permissions';
import { me } from 'requests/agents';

const ROLES_KEY = 'auth-permissions';
const SUPER_PERMISSION = '*:*:*';
const ALL = '*';

const format = (resource: string, action: TResourceAction) =>
  [resource, action, ALL].join(':');

export interface ParsedPermission {
  title: string;
  name: string;
  key: string;
  groupActions?: string[];
  children?: ParsedPermission[];
  parentName?: string;
  actions?: IResource['actions'];
}

const predefinedActionsOrder = (actions: string[]) => {
  const predefinedOrder = ['list', 'show', 'create', 'edit', 'delete'];

  return actions.sort((a, b) => {
    const indexA = predefinedOrder.indexOf(a);
    const indexB = predefinedOrder.indexOf(b);
    return (
      (indexA === -1 ? Number.POSITIVE_INFINITY : indexA) -
      (indexB === -1 ? Number.POSITIVE_INFINITY : indexB)
    );
  });
};

export const parsePermissions = (): ParsedPermission[] => {
  return RESOURCES.reduce<ParsedPermission[]>((acc, { resource }) => {
    const { name, actions, label, parentName } = resource as IResource;

    if (!parentName) {
      acc.push({
        title: label,
        name,
        key: name,
        groupActions: [],
        children: []
      });
    } else {
      const parent = acc.find(node => node.name === parentName);

      if (parent?.children && actions) {
        parent.children.push({
          title: label,
          name,
          key: name,
          parentName,
          actions
        });

        if (parent.groupActions) {
          const groupActions = [
            ...new Set([...parent.groupActions, ...actions])
          ];

          parent.groupActions = predefinedActionsOrder(groupActions);
        }
      }
    }

    return acc;
  }, []);
};

export interface TreePermission {
  title: string;
  value: string;
  key: string;
  children?: TreePermission[];
}

export const treePermissions = (
  customPermissions: string[] = []
): TreePermission[] => {
  const tree: TreePermission[] =
    RESOURCES.map((resourceAndRoutes: IResourceAndRoutes) => {
      const resource = resourceAndRoutes.resource as IResource;
      const { name, actions, label } = resource;

      return {
        title: label,
        value: format(name, ALL),
        key: format(name, ALL),
        children: actions?.map((action: string) => ({
          title: format(name, action),
          value: format(name, action),
          key: format(name, action)
        }))
      };
    }) || [];

  const defaultParsedPermissions: TreePermission = {
    title: 'super',
    value: format(ALL, ALL),
    key: format(ALL, ALL),
    children: tree
  };

  const parsedCustomPermissions: TreePermission[] = parseCustomPermissions(
    tree,
    customPermissions
  );

  return [defaultParsedPermissions, ...parsedCustomPermissions];
};

const flattenTreeValues = (nodes: TreePermission[]): string[] =>
  nodes.flatMap(node => [
    node.value,
    ...(node.children ? flattenTreeValues(node.children) : [])
  ]);

const parseCustomPermissions = (
  tree: TreePermission[],
  customPermissions: string[]
): TreePermission[] => {
  const permissionWithoutSuper = customPermissions.filter(
    permission => !permission.includes(SUPER_PERMISSION)
  );

  const allTreeValues = flattenTreeValues(tree);

  return permissionWithoutSuper.reduce<TreePermission[]>(
    (result, permission) => {
      if (!allTreeValues.includes(permission)) {
        result.push({
          title: permission,
          value: permission,
          key: permission
        });
      }
      return result;
    },
    []
  );
};

export const getPermissions = async (): Promise<TPermission[]> => {
  const persistedPermissions = get();

  if (persistedPermissions) {
    return JSON.parse(persistedPermissions);
  }

  const permissions = await load();

  set(permissions);

  return permissions;
};

export const clearPermissions = () => {
  localStorage.removeItem(ROLES_KEY);
};

export const refreshPermissions = async () => {
  const permissions = await load();

  set(permissions);
};

const get = () => localStorage.getItem(ROLES_KEY);

const set = (permissions: TPermission[]) => {
  localStorage.setItem(ROLES_KEY, JSON.stringify(permissions));
};

const load = async (): Promise<TPermission[]> => {
  const { permissions, role_permissions } = await me();

  return [...permissions, ...role_permissions];
};
