import * as Icons from '@ant-design/icons';
import { BaseRecord, useGo, useList } from '@refinedev/core';
import { Button, Table, Tabs } from 'antd';
import { IOrder } from 'interfaces/orders';
import { TextField } from '@refinedev/antd';
import { formatDate } from '../../services/date';
import { formatMoney } from '../../services/money';
import { getInstallmentsFromPlan } from '../../utils/payment';
import { paymentPlanStatus } from '../../services/payment-plan';
import {
  translateGatewayStatusDetail,
  translateKind,
  translateStatus
} from 'services/payments';
import { useCallback } from 'react';

interface InvoiceTableProps {
  order: IOrder;
}

const InvoiceTable = ({ order }: InvoiceTableProps) => {
  const go = useGo();

  const handleClick = useCallback(
    (recordId: string) => () =>
      go({
        to: {
          resource: 'payment_plans',
          action: 'list',
          meta: { order_id: order.id, id: recordId }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order.id]
  );

  const { data } = useList({
    resource: `orders/${order.id}/payment_plans`
  });

  // Planos de pagamento parcelados
  const plans = data?.data.filter(plan => getInstallmentsFromPlan(plan) > 1);

  // Somente pagamento à vista
  const payments = order.payments.filter(payment => payment.installments === 1);

  return (
    <Tabs defaultActiveKey="payments">
      <Tabs.TabPane tab="À vista" key="payments">
        <Table
          dataSource={payments}
          rowKey="id"
          pagination={false}
          bordered
          scroll={{ x: true }}
        >
          <Table.Column
            dataIndex="kind"
            key="kind"
            title="Tipo"
            render={kind => <TextField value={translateKind(kind)} />}
          />
          <Table.Column
            dataIndex="created_at"
            key="created_at"
            title="Data da compra"
            render={created_at => (
              <TextField value={formatDate(created_at, 'L')} />
            )}
          />
          <Table.Column
            dataIndex="amount"
            key="amount"
            title="Valor"
            render={amount => <TextField value={formatMoney(amount)} />}
          />
          <Table.Column
            dataIndex="status"
            key="status"
            title="Status"
            render={name => <TextField value={translateStatus(name)} />}
          />
          <Table.Column
            dataIndex="gateway_status_detail"
            key="gateway_status_detail"
            title="Detalhes"
            render={text => (
              <TextField value={translateGatewayStatusDetail(text)} />
            )}
          />
        </Table>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Parcelados" key="payments_plans">
        <Table
          dataSource={plans}
          rowKey="id"
          pagination={false}
          bordered
          scroll={{ x: true }}
        >
          <Table.Column<BaseRecord>
            dataIndex="kind"
            key="kind"
            title="Tipo"
            render={(_, record) => (
              <TextField value={translateKind(record?.preferences?.kind)} />
            )}
          />
          <Table.Column
            dataIndex="created_at"
            key="created_at"
            title="Data da compra"
            render={created_at => (
              <TextField value={formatDate(created_at, 'L')} />
            )}
          />
          <Table.Column
            dataIndex="amount"
            key="amount"
            title="Valor total"
            render={amount => <TextField value={formatMoney(amount)} />}
          />
          <Table.Column
            dataIndex="installments"
            key="installments"
            title="Qtd. de parcelas"
            render={(_, record) => (
              <TextField value={getInstallmentsFromPlan(record)} />
            )}
          />
          <Table.Column
            dataIndex="status"
            key="status"
            title="Status"
            render={status => <TextField value={paymentPlanStatus(status)} />}
          />
          <Table.Column<BaseRecord>
            title="Ações"
            dataIndex="actions"
            align="center"
            render={(_, record) => {
              return (
                <Button
                  icon={<Icons.EyeOutlined />}
                  size="small"
                  onClick={handleClick(record.id as string)}
                />
              );
            }}
          />
        </Table>
      </Tabs.TabPane>
    </Tabs>
  );
};

export default InvoiceTable;
