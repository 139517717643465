import { IAcademicRecord } from 'interfaces/academic_records';

export const ACADEMIC_RECORD_STATUSES_MAP: Record<
  IAcademicRecord['status'],
  { label: string; color: string }
> = {
  approved: {
    label: 'Aprovado',
    color: 'success'
  },
  failed: {
    label: 'Reprovado',
    color: 'error'
  },
  exempted: {
    label: 'Isento',
    color: 'warning'
  },
  cancelled: {
    label: 'Cancelado',
    color: 'orange'
  },
  inactive: {
    label: 'Inativo',
    color: 'warning'
  },
  progress: {
    label: 'Em andamento',
    color: 'blue'
  },
  attendance_failed: {
    label: 'Reprovado por falta',
    color: 'error'
  }
};
