import { IOrderItem } from 'interfaces/orders';

const STATUS_TRANSLATIONS = new Map([
  ['fulfilled', 'Liberado'],
  ['returned', 'Devolvido'],
  ['pending', 'Pendente de liberação']
]);

export const translateStatus = (status: IOrderItem['status']): string =>
  STATUS_TRANSLATIONS.get(status) || status;
