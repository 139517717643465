import {
  AssignmentCreate,
  AssignmentEdit,
  AssignmentList,
  AssignmentShow
} from 'pages/evaluations/assignments';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'assignments',
  label: 'Atividades',
  list: AssignmentList,
  edit: AssignmentEdit,
  create: AssignmentCreate,
  show: AssignmentShow,
  actions: ['list', 'edit', 'create', 'delete', 'show'],
  parentName: 'evaluations'
};

export default generateResources(resource);
