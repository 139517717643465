import { Button, Input, Table, Tag } from 'antd';
import { DiscountCampaign } from './GeneralRule';
import { HttpError } from '@refinedev/core';
import { IProduct } from 'interfaces/products';
import { Stack } from '@qcx/ui';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable } from '@refinedev/antd';

export const ByProductPaymentPlanDealDiscount = () => {
  const { tableProps, setFilters } = useTable<IProduct, HttpError>({
    resource: 'products',

    pagination: {
      pageSize: 5
    }
  });

  const products = useMemo(
    () => tableProps.dataSource ?? [],
    [tableProps.dataSource]
  );
  const productIds = useMemo(
    () => products?.map(product => `${product.id}`) || [],
    [products]
  );

  const { tableQueryResult: discountResults } = useTable<
    DiscountCampaign,
    HttpError
  >({
    resource: 'discount_campaigns',

    filters: {
      permanent: [
        {
          field: 'filter[rules][resource_type]',
          operator: 'eq',
          value: 'product_id'
        },
        {
          field: 'filter[kind]',
          operator: 'eq',
          value: 'payment_plan_deal'
        },
        {
          field: 'filter[status]',
          operator: 'eq',
          value: 'active'
        },
        {
          field: 'filter[rules][operator]',
          operator: 'eq',
          value: ['!=']
        }
      ]
    }
  });

  const {
    tableQueryResult: productDiscountResults,
    setFilters: setProductDiscountFIlters
  } = useTable<DiscountCampaign, HttpError>({
    resource: 'discount_campaigns',

    filters: {
      initial: [
        {
          field: 'filter[rules][value][]',
          operator: 'eq',
          value: productIds
        }
      ],

      permanent: [
        {
          field: 'filter[rules][resource_type]',
          operator: 'eq',
          value: 'product_id'
        },
        {
          field: 'filter[kind]',
          operator: 'eq',
          value: 'payment_plan_deal'
        },
        {
          field: 'filter[kind]',
          operator: 'eq',
          value: 'payment_plan_deal'
        },
        {
          field: 'filter[rules][operator]',
          operator: 'eq',
          value: ['==']
        },
        {
          field: 'filter[status]',
          operator: 'eq',
          value: 'active'
        }
      ]
    }
  });

  const productDiscount = useMemo(
    () => productDiscountResults?.data?.data || [],
    [productDiscountResults?.data?.data]
  );
  const generalDiscount = useMemo(
    () => discountResults?.data?.data || [],
    [discountResults?.data?.data]
  );

  useEffect(() => {
    setProductDiscountFIlters([
      {
        field: 'filter[rules][resource_type]',
        operator: 'eq',
        value: 'product_id'
      },
      {
        field: 'filter[kind]',
        operator: 'eq',
        value: 'payment_plan_deal'
      },
      {
        field: 'filter[kind]',
        operator: 'eq',
        value: 'payment_plan_deal'
      },
      {
        field: 'filter[rules][operator]',
        operator: 'eq',
        value: ['==']
      },
      {
        field: 'filter[status]',
        operator: 'eq',
        value: 'active'
      },
      {
        field: 'filter[rules][value][]',
        operator: 'eq',
        value: productIds
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIds]);

  const parseProductsResult = (product: IProduct) => {
    const currentProductRules = productDiscount.filter(dis => {
      return dis.rules.find(rule => rule.value?.includes(product.id));
    });
    const generalException = generalDiscount.filter(dis =>
      dis.rules.find(rule => rule.value?.includes(product.id))
    );
    const haveCustomRule =
      currentProductRules?.length && currentProductRules?.length > 0;
    const isGeneralException =
      generalException?.length && generalException?.length > 0;
    const noGeneralRulePresent = generalDiscount?.length === 0;

    if (haveCustomRule) {
      return 'Regra por Produto';
    }

    if (isGeneralException || noGeneralRulePresent) {
      return 'Sem Regra';
    }

    return 'Regra Geral';
  };

  const navigate = useNavigate();

  const createProductRule = (product: IProduct) => {
    navigate(`show/${product.id}`);
  };

  return (
    <Stack
      css={{
        d: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: '$4',
        flexDir: 'column'
      }}
    >
      <Stack css={{ w: '100%', flexDir: 'column', gap: '$4' }}>
        <Stack css={{ w: '$full', flexDir: 'column' }}>
          <p className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
            A Regra por Produto é aplicada à um produto específico.
          </p>
        </Stack>
      </Stack>
      <div className="border-b border-[#EAECF0] w-full">
        <Stack
          css={{
            pb: '$2',
            d: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <p className="m-0 font-semibold text-lg leading-6 text-[rgba(0,0,0,0.85)]">
            Produtos
          </p>
          <Input
            placeholder="Digite aqui o nome do produto"
            className="max-w-[280px]"
            onChange={e => {
              setFilters([
                {
                  field: 'filter[name]',
                  operator: 'eq',
                  value: !!e.currentTarget.value
                    ? e.currentTarget.value
                    : undefined
                }
              ]);
            }}
          />
        </Stack>
        <Table {...tableProps} className="w-full">
          <Table.Column
            key="name"
            dataIndex="name"
            title="Nome do produto"
            className="w-full"
          />
          <Table.Column key="actions" />
          <Table.Column
            key="config"
            title="Tipo de regra"
            render={product => {
              const title = parseProductsResult(product).toString();
              const variant = title.includes('Sem') ? 'red' :  title.includes('Geral') ? undefined : 'magenta';

              return <Tag color={variant}>{title}</Tag>;
            }}
          />
          <Table.Column
            key="actions"
            render={product => {
              const rules = parseProductsResult(product).toString();
              const variant =
                rules.includes('Sem') || rules.includes('geral')
                  ? 'Criar'
                  : 'Ver';

              return (
                <Button
                  onClick={() => createProductRule(product)}
                  type="link"
                >{`${variant} regra`}</Button>
              );
            }}
          />
        </Table>
      </div>
    </Stack>
  );
};
