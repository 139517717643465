import { Col, Row, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IActionLog } from 'interfaces/action_log';
import { List, ShowButton, TextField, useTable } from '@refinedev/antd';

interface IFilter {}

export const ActionLogList = () => {
  const { tableProps } = useTable<IActionLog, HttpError, IFilter>({
    onSearch: _params => {
      const filters: CrudFilters = [];

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex={['resource_kind']}
              key="resource_kind"
              title="Tipo do recurso"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex={['resource_id']}
              key="resource_id"
              title="Id do recurso"
              render={value => <TextField value={value || '-'} />}
            />
            <Table.Column
              dataIndex={['actor']}
              key="resource_info"
              title="Login"
              render={value => (
                <TextField value={value['email'] || value['login']} />
              )}
            />
            <Table.Column
              dataIndex={['actor']}
              key="resource_info"
              title="Tipo de usuário"
              render={value => (
                <TextField value={value['email'] ? 'Aluno' : 'Administrador'} />
              )}
            />
            <Table.Column
              dataIndex={['actor']}
              key="resource_info"
              title="Identificador de usuário"
              render={value => <TextField value={value['id']} />}
            />
            <Table.Column<IActionLog>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <ShowButton hideText size="small" recordItemId={record.id} />
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
