import { Button, Col, Empty, Pagination, Row, Typography } from 'antd';
import { CloseThreadModal } from 'components/modal/faq-coordenator/CloseThreadModal';
import { CreatePostModal } from 'components/modal/faq-coordenator/CreatePostModal';
import {
  IFAQCoordinator,
  IFAQCoordinatorPost
} from 'interfaces/faq_coordinators';
import { Post } from './components/Posts';
import { Show, TagField, useModal } from '@refinedev/antd';
import { statusMap } from 'services/faq-coordinator';
import { styled } from '@qcx/ui';
import { useList, useShow } from '@refinedev/core';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

export const FaqCoordinatorsShow = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const { queryResult } = useShow<IFAQCoordinator>();
  const { data, isLoading, refetch } = queryResult;
  const { data: postData } = useList<IFAQCoordinatorPost>({
    resource: `faq_coordinators/${id}/posts`,
    pagination: {
      current: currentPage,
      pageSize: 10,
      mode: 'server'
    }
  });
  const { data: firstPostData } = useList<IFAQCoordinatorPost>({
    resource: `faq_coordinators/${id}/posts`,
    pagination: {
      current: currentPage,
      pageSize: 1,
      mode: 'server'
    }
  });
  const record = data?.data;
  const postRecords =
    currentPage === 1 ? postData?.data?.slice(1) : postData?.data;

  const {
    show: postCreateModalShow,
    close: postCreateModalClose,
    modalProps: postCreateModalProps
  } = useModal();

  const {
    show: closeTopicModalShow,
    close: closeTopicModalClose,
    modalProps: closeTopicModalProps
  } = useModal();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Show
      isLoading={isLoading}
      title="Visualizar tópico"
      footerButtonProps={{ className: 'float-right' }}
      footerButtons={
        <Row className="mr-2">
          <Pagination
            hideOnSinglePage
            total={postData?.total}
            onChange={handlePageChange}
          />
          <Col className="flex gap-2 place-self-end">
            {record?.status !== 'closed' && (
              <>
                <Button type="default" onClick={closeTopicModalShow}>
                  Marcar como concluído
                </Button>

                <Button
                  type="primary"
                  className="!shadow-none"
                  onClick={postCreateModalShow}
                >
                  Responder
                </Button>
              </>
            )}
          </Col>
        </Row>
      }
    >
      <StyledRow className="mb-4">
        <Typography.Title
          level={4}
          style={{ margin: 0, whiteSpace: 'pre-line' }}
        >
          {record?.title}
        </Typography.Title>
      </StyledRow>
      <Row gutter={[16, 16]} className="w-full mb-2">
        <Col span={12}>
          <StyledParagraph className="!font-light">
            Autor: {record?.author.name}
          </StyledParagraph>
        </Col>
        <Col span={12}>
          <StyledParagraph className="!font-light">
            Curso: {record?.grandparent?.name}
          </StyledParagraph>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="w-full">
        <Col span={12}>
          <StyledParagraph className="!font-light">
            Categoria: {record?.category.name}
          </StyledParagraph>
        </Col>
        <Col span={12}>
          <Row>
            <StyledParagraph className="!font-light">
              Status: &nbsp;
            </StyledParagraph>
            <TagField
              value={statusMap[record?.status as keyof typeof statusMap]?.label}
              color={statusMap[record?.status as keyof typeof statusMap]?.color}
            />
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <StyledParagraph>{firstPostData?.data?.[0]?.text}</StyledParagraph>
      </Row>
      <Typography.Title level={5} className="mt-8">
        Respostas
      </Typography.Title>
      <Row className="w-full mt-5">
        {postRecords && postRecords?.length < 1 && (
          <Row className="w-full flex justify-center">
            <Empty description="Este forum ainda não possui respostas." />
          </Row>
        )}
        {postRecords?.map(post => (
          <Post key={post.id} post={post} />
        ))}
      </Row>
      <CreatePostModal
        forumId={id}
        modalProps={postCreateModalProps}
        modalClose={postCreateModalClose}
        refetch={refetch}
      />
      <CloseThreadModal
        forumId={id}
        modalProps={closeTopicModalProps}
        modalClose={closeTopicModalClose}
        refetch={refetch}
      />
    </Show>
  );
};

const StyledRow = styled(Row, {
  display: 'flex',
  alignItems: 'center'
});

const StyledParagraph = styled(Typography.Paragraph, {
  marginBottom: '0 !important',
  whiteSpace: 'pre-line'
});
