import { Form, Input, Modal, ModalProps, Row, Typography } from 'antd';
import { ReactNode } from 'react';
import { useCreate, useNotification } from '@refinedev/core';
import { useForm } from '@refinedev/antd';

export interface CreatePostModalProps {
  modalClose: () => void;
  modalProps: ModalProps;
  getLocal: () => ReactNode;
  refetch: () => void;
  forumId: string | undefined;
  topicTitle: string | undefined;
}

export const CreatePostModal = ({
  modalProps,
  forumId,
  topicTitle,
  modalClose,
  getLocal,
  refetch
}: CreatePostModalProps) => {
  const { mutateAsync: createPost } = useCreate();
  const { open } = useNotification();
  const { form, formProps } = useForm();

  const handleCreate = async () => {
    const resource = `forums/${forumId}/posts`;

    return form
      .validateFields()
      .then(async () => {
        return createPost({
          resource,
          values: {
            ...form.getFieldsValue()
          },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Resposta criada com sucesso'
          }),
          errorNotification: () => {
            return {
              description: 'Algo deu errado',
              message: 'Não foi possível criar a resposta',
              type: 'error'
            };
          }
        }).then(() => {
          refetch();
          modalClose();
          form.resetFields();
        });
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: 'Favor conferir todos os campos obrigatórios.',
          description: 'Error ao criar registro!'
        });
      });
  };

  return (
    <Modal
      style={{ maxWidth: 600 }}
      {...modalProps}
      title="Responder"
      cancelText="Cancelar"
      okText="Responder"
      onOk={handleCreate}
      className="!w-[1022px]"
      destroyOnClose
    >
      <Typography.Title level={5} className="mt-8">
        {topicTitle}
      </Typography.Title>
      <Row>{getLocal()}</Row>
      <Form
        form={form}
        {...formProps}
        layout="vertical"
        style={{ marginTop: 16 }}
      >
        <Row>
          <Form.Item
            style={{ width: '100%' }}
            name={'text'}
            rules={[
              {
                required: true,
                message: 'Campo texto em branco'
              }
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Escreva sua resposta aqui..."
            />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
