import FilterForm from 'components/forms/FilterForm';
import { Col, Form, FormProps, Input, Row, Select } from 'antd';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { useSelect } from '@refinedev/antd';

export interface IEquivalenciesFilterForm {
  courseId: string;
  by_status: string;
  by_name: string;
  by_email: string;
}

interface EquivalenciesFilterProps {
  searchFormProps: FormProps<IEquivalenciesFilterForm>;
}

export const EquivalenciesFilter = ({
  searchFormProps
}: EquivalenciesFilterProps) => {
  const { selectProps: courseSelectProps } = useSelect<IGraduateCourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={16} className="w-full">
        <Col xs={24} md={8}>
          <Form.Item label="Nome" name="by_name">
            <Input placeholder="Nome do aluno" />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="E-mail" name="by_email">
            <Input placeholder="e-mail do aluno" />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label="Status" name="by_status">
            <Select placeholder="Selecione" allowClear defaultValue="submitted">
              <Select.Option key={'submitted'}>Submetido</Select.Option>
              <Select.Option key={'approved'}>Aprovado</Select.Option>
              <Select.Option key={'rejected'}>Rejeitado</Select.Option>
              <Select.Option key={'canceled'}>Cancelado</Select.Option>
              <Select.Option key={'validating'}>Validando</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className="w-full">
        <Col xs={24} md={16}>
          <Form.Item
            colon
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label="Curso regulado"
            name="courseId"
          >
            <Select
              allowClear
              {...courseSelectProps}
              style={{ minWidth: 250 }}
              placeholder="Selecione ou busque o curso"
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
