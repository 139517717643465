import { IContractEnrollment } from 'interfaces/contracts';
import { Table, TableProps } from 'antd';
import { TextField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { useLink } from '@refinedev/core';

export interface ContractsTableProps {
  tableProps: TableProps<IContractEnrollment>;
}

export const ContractStudentsTable = ({ tableProps }: ContractsTableProps) => {
  const Link = useLink();

  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column<IContractEnrollment>
        dataIndex="user_name"
        key="user_name"
        title="Aluno"
        render={(value, record) => (
          <Link
            to={`/students_teachers/users/show/${record.user_id}`}
            className="!p-0 text-blue-600 hover:text-blue-800"
          >
            {value}
          </Link>
        )}
      />
      <Table.Column
        dataIndex="user_email"
        key="user_email"
        title="E-mail do aluno"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="starts_at"
        key="starts_at"
        title="Data de inclusão"
        render={value => <TextField value={formatDate(value, 'DD/MM/YYYY')} />}
      />
    </Table>
  );
};
