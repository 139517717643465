import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import { IOrder, IOrderItem, IOrderPayment } from 'interfaces/orders';
import { create } from 'requests/payments/refunds';
import { formatDate } from 'services/date';
import { formatMoney } from 'services/money';
import { translateKind } from 'services/payments';
import { useModalReturnType } from '@refinedev/core';
import { useState } from 'react';

interface CreateRefundModalProps {
  modal: useModalReturnType;
  order: IOrder;
  onUpdate: () => void;
}

const approvedPayment = (
  payments: IOrderPayment[]
): IOrderPayment | undefined => {
  return payments.find(payment => payment.status === 'approved');
};

const CreateRefundModal = ({
  modal,
  order,
  onUpdate
}: CreateRefundModalProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = async ({
    motive,
    motiveKind,
    motiveReference,
    amount,
    hermesPayment
  }: {
    motive: string;
    motiveKind: string;
    motiveReference?: string;
    amount: number;
    hermesPayment: string | number;
  }) => {
    setLoading(true);

    try {
      await create({
        hermesPaymentId: String(hermesPayment),
        motive,
        motiveKind,
        motiveReference,
        amount: Math.round(amount * 100),
        orderId: order.id
      });
      modal.close();
      onUpdate();
    } catch {
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const onClick = async () => {
    const { motive, motiveKind, motiveReference, amount, hermesPayment } =
      await form.validateFields();

    onSubmit({ motive, motiveKind, motiveReference, amount, hermesPayment });
  };

  const [hiddenItemSelection, setHiddenItemSelection] = useState<boolean>(true);

  return (
    <Modal
      title="Criar Reembolso"
      visible={modal.visible}
      onCancel={modal.close}
      footer={[
        <Button key="back" onClick={modal.close}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onClick}>
          Criar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item label="Tipo" name="motiveKind" required>
          <Select
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(value: any) =>
              setHiddenItemSelection(value !== 'order_item_refund')
            }
          >
            <Select.Option key={'single_refund'}>Avulso</Select.Option>
            <Select.Option key={'order_item_refund'}>
              Item do pedido
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Item"
          name="motiveReference"
          required
          hidden={hiddenItemSelection}
        >
          <Select>
            {order.items
              .filter(orderItem => orderItem.status === 'fulfilled')
              .map((orderItem: IOrderItem) => (
                <Select.Option key={orderItem.id}>
                  {orderItem.name} ({formatMoney(orderItem.amount)})
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Pagamento" name="hermesPayment" required>
          <Select
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(value: any) => console.log(value)}
          >
            {order?.hermes_order?.payments
              ?.filter(p => {
                return (
                  p.status === 'approved' &&
                  !order.external_payment_refunds?.find(
                    pr =>
                      pr.hermes_payment_id === p.id &&
                      ['approved', 'processing', 'processed'].includes(
                        pr.status
                      )
                  )
                );
              })
              .map((payment: IOrderPayment) => (
                <Select.Option key={payment.id}>
                  {`${formatMoney(payment.amount)} - ${translateKind(
                    payment.kind
                  )} - ${formatDate(payment.approved_at, 'DD/MM/YYYY')}`}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Valor"
          name="amount"
          required
          hidden={!hiddenItemSelection}
        >
          <InputNumber addonBefore="R$" precision={2} />
        </Form.Item>
        <Form.Item label="Motivo" name="motive" required>
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateRefundModal;
