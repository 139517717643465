import * as Icons from '@ant-design/icons';
import { Typography } from 'antd';

export const NotAllowed = () => {
  return (
    <div className="flex flex-col gap-2 justify-center items-center w-full h-full mt-24">
      <Icons.CloseCircleOutlined className="text-red-400 text-6xl" />
      <Typography.Text strong>
        Você não tem permissão para acessar esta página
      </Typography.Text>
    </div>
  );
};
