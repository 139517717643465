import Details from 'components/orders/Details';
import { IOrder } from 'interfaces/orders';
import { Show } from '@refinedev/antd';
import { useShow } from '@refinedev/core';

export const OrderShow = () => {
  const { queryResult } = useShow<IOrder>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar pedido">
      {record && <Details order={record} />}
    </Show>
  );
};
