import { Badge, Space, Table, Tag } from 'antd';
import { DeleteButton, TextField, useTable } from '@refinedev/antd';
import { DeleteOutlined } from '@ant-design/icons';
import { HttpError } from '@refinedev/core';
import { IProductPossession } from 'interfaces/products';
import { IUser } from 'interfaces/users';
import { formatDate } from 'services/date';

interface Props {
  userData: IUser;
}

interface IFilter {
  by_user_id: string;
}

const PossessionTable = ({ userData }: Props) => {
  const { tableProps } = useTable<IProductPossession, HttpError, IFilter>({
    resource: 'products/possessions',

    onSearch: params => {
      const filters: any[] = [];

      filters.push({
        field: 'filter[by_user_id]',
        operator: 'eq',
        value: userData.id
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_user_id]',
          operator: 'eq',
          value: userData.id
        }
      ]
    }
  });

  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="product"
        key="course"
        title="Produto"
        render={({ name }) => <TextField value={name} />}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Início"
        render={createdAt => <TextField value={formatDate(createdAt, 'l')} />}
      />
      <Table.Column
        dataIndex="expires_at"
        key="expires_at"
        title="Expiração"
        render={expiresAt => (
          <TextField value={expiresAt && formatDate(expiresAt, 'l')} />
        )}
      />
      <Table.Column
        align="center"
        dataIndex={['expires_at', 'disabled_at']}
        key="status"
        title="Status"
        render={(expiresAt, record) => {
          return (record.expires_at && record.expires_at > new Date()) ||
            record.disabled_at ? (
            <Tag color="red">Inativo</Tag>
          ) : (
            <Tag color="green">Ativo</Tag>
          );
        }}
      />
      <Table.Column
        key="action"
        title="Ação"
        render={(text, record) => (
          <Space size="middle">
            <DeleteButton
              icon={<DeleteOutlined />}
              size="small"
              resource="products/possessions"
              recordItemId={record.id}
              disabled={record.disabled_at || record.expires_at > new Date()}
              hideText
              confirmTitle="Deseja remover o acesso do usuário a este curso?"
              confirmOkText="Excluir"
              confirmCancelText="Cancelar"
            />
          </Space>
        )}
      />
    </Table>
  );
};

export default PossessionTable;
