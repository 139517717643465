import FilterForm from 'components/forms/FilterForm';
import { Col, DatePicker, Form, FormProps, Input, Row, Select } from 'antd';
import { IFilter } from '../list';

export interface ForumReportsFilterProps {
  searchFormProps: FormProps<IFilter>;
}

export const ForumReportsFilter = ({
  searchFormProps
}: ForumReportsFilterProps) => {
  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={[16, 16]} className="w-full">
        <Col span={8}>
          <Form.Item
            label={
              <span style={{ whiteSpace: 'nowrap' }}>Status do comentário</span>
            }
            name="status"
          >
            <Select placeholder="Selecione um status" defaultValue={null}>
              <Select.Option>Todos</Select.Option>
              <Select.Option key="pending">Pendende</Select.Option>
              <Select.Option key="accepted">Aceito</Select.Option>
              <Select.Option key="rejected">Rejeitado</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Categoria" name="category">
            <Select placeholder="Selecione a categoria..." allowClear>
              <Select.Option value="spam">
                Conteúdo comercial indesejado ou spam
              </Select.Option>
              <Select.Option value="offensive">
                Discurso de ódio ou violência explícita
              </Select.Option>
              <Select.Option value="harassment">
                Assédio ou bullying
              </Select.Option>
              <Select.Option value="misinformation">
                Desinformação
              </Select.Option>
              <Select.Option value="other">Outro</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            colon
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label={
              <span style={{ whiteSpace: 'nowrap' }}>Autor da denúncia</span>
            }
            name="author"
          >
            <Input placeholder="Nome do autor" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="w-full">
        <Col span={8}>
          <Form.Item
            colon
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label="Moderador"
            name="moderator"
          >
            <Input placeholder="Nome do moderador" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label={
              <span style={{ whiteSpace: 'nowrap' }}>
                Data da denúncia (a partir de)
              </span>
            }
            name="startsAt"
          >
            <DatePicker
              className="w-full"
              placeholder="dd/mm/aaaa"
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            label={
              <span style={{ whiteSpace: 'nowrap' }}>
                Data da denúncia (término em)
              </span>
            }
            name="endsAt"
          >
            <DatePicker
              className="w-full"
              placeholder="dd/mm/aaaa"
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
