import { FileDoneOutlined } from '@ant-design/icons';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'evaluations',
  label: 'Avaliações',
  icon: <FileDoneOutlined />
};

export default generateResources(resource);
