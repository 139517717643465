import * as Icons from '@ant-design/icons';
import { Button, Progress, Table, Tooltip } from 'antd';
import { IGraduateCourseEnrollment } from '../../interfaces/graduate_course_enrollments';
import { TextField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { useNavigation } from '@refinedev/core';

interface Props {
  enrollments: IGraduateCourseEnrollment[];
}

const GraduateCourseEnrollmentTable = ({ enrollments }: Props) => {
  const navigate = useNavigation();

  return (
    <Table
      dataSource={enrollments}
      rowKey="id"
      pagination={false}
      bordered
      scroll={{ x: true }}
    >
      <Table.Column
        dataIndex="course"
        key="course"
        title="Curso"
        render={({ name }) => <TextField value={name} />}
      />
      <Table.Column
        dataIndex="registration"
        key="registration"
        title="Matrícula"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="progress_percentage"
        key="progress_percentage"
        title="Progresso"
        render={progress => (
          <Tooltip title={`${progress}%`}>
            <Progress
              percent={progress}
              showInfo={false}
              strokeColor={{
                '0%': '#0322e2',
                '100%': '#08adff'
              }}
            />
          </Tooltip>
        )}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Início"
        render={createdAt => <TextField value={formatDate(createdAt, 'l')} />}
      />
      <Table.Column
        dataIndex="expires_at"
        key="expires_at"
        title="Expiração"
        render={expiresAt => (
          <TextField value={expiresAt && formatDate(expiresAt, 'l')} />
        )}
      />
      <Table.Column<IGraduateCourseEnrollment>
        title="Ações"
        dataIndex="actions"
        align="center"
        render={(_, record) => {
          return (
            <Button
              icon={<Icons.EyeOutlined />}
              size="small"
              onClick={() =>
                navigate.show(`graduate_enrollment`, record.id, 'push')
              }
            />
          );
        }}
      />
    </Table>
  );
};

export default GraduateCourseEnrollmentTable;
