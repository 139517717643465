import { IFAQCoordinator } from 'interfaces/faq_coordinators';
import { ShowButton, TagField, TextField } from '@refinedev/antd';
import { Table, TableProps } from 'antd';
import { formatDate } from 'services/date';
import { statusMap } from 'services/faq-coordinator';

interface FaqCoordinatorTableProps {
  tableProps: TableProps<IFAQCoordinator>;
}

export const FaqCoordinatorTable = ({
  tableProps
}: FaqCoordinatorTableProps) => {
  return (
    <Table {...tableProps}>
      <Table.Column
        dataIndex="title"
        key="title"
        title="Título do tópico"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex={['author', 'name']}
        key="author"
        title="Aluno"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex={['grandparent', 'name']}
        key="course"
        title="Curso"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex={['category', 'name']}
        key="category"
        title="Categoria"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Data"
        render={value => (
          <TextField value={formatDate(value, 'DD/MM/YYYY à[s] HH:mm')} />
        )}
      />
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <TagField
            color={statusMap[value as keyof typeof statusMap].color}
            value={statusMap[value as keyof typeof statusMap].label}
          />
        )}
      />
      <Table.Column<IFAQCoordinator>
        title="Ações"
        dataIndex="actions"
        render={(_, record) => {
          return <ShowButton hideText size="small" recordItemId={record.id} />;
        }}
      />
    </Table>
  );
};
