import {
  CreateButton,
  EditButton,
  List,
  TextField,
  useTable
} from '@refinedev/antd';
import { IPaymentMethodRules } from '../../../interfaces/payment_methods-rules';
import { IResourceComponentsProps } from '@refinedev/core';
import { Table } from 'antd';

export const PaymentMethodRulesList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps } = useTable<IPaymentMethodRules>();

  return (
    <>
      <List headerButtons={<CreateButton> Criar Nova Regra</CreateButton>}>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="name"
            key="name"
            title="Nome da Regra"
            render={value => <TextField value={value} />}
          />

          <Table.Column<IPaymentMethodRules>
            dataIndex={['config', 'credit_card']}
            key="interest"
            title="Cartão de crédito"
            render={value => (value.length > 0 ? `${value.length}x` : '-')}
          />
          <Table.Column<IPaymentMethodRules>
            dataIndex={['config', 'financing_credit_card']}
            key="fee"
            title="Crédito Recorrente"
            render={value => (value.length > 0 ? `${value.length}x` : '-')}
          />
          <Table.Column<IPaymentMethodRules>
            dataIndex={['config', 'pix']}
            key="fee"
            title="Pix"
            render={value => (value.length > 0 ? `${value.length}x` : '-')}
          />
          <Table.Column<IPaymentMethodRules>
            dataIndex={['config', 'boleto']}
            key="fee"
            title="Boleto"
            render={value => (value.length > 0 ? `${value.length}x` : '-')}
          />
          <Table.Column<IPaymentMethodRules>
            dataIndex={'actions'}
            key="actions"
            title="Editar"
            render={(_, record) => (
              <EditButton hideText size="small" recordItemId={record.id} />
            )}
          />
        </Table>
      </List>
    </>
  );
};
