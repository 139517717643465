import qs from 'qs';
import { ISupplementaryMaterial } from '../interfaces/supplementary_materials';
import { getAPI } from './api';

export const list = async (
  params: Record<string, unknown>
): Promise<ISupplementaryMaterial[]> => {
  // TODO params with 0= when we use axios { params }
  const { data } = await getAPI().get(
    `/admin/supplementary_materials?${qs.stringify(params)}`
  );
  return data;
};

export const download_url = async (id: string): Promise<{ url: string }> => {
  const { data } = await getAPI().get(
    `/admin/supplementary_materials/${id}/presign_download`
  );
  return data;
};
