import {
  AgentCreate,
  AgentEdit,
  AgentList,
  AgentShow
} from 'pages/admin/agents';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'agents',
  label: 'Usuários admin',
  list: AgentList,
  edit: AgentEdit,
  create: AgentCreate,
  show: AgentShow,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'configurations'
};

export default generateResources(resource);
