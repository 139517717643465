import { Col, Form, Input, Row, Select } from 'antd';
import { Create, useForm } from '@refinedev/antd';
import { IProfessor } from 'interfaces/professor';

export const ProfessorCreate = () => {
  const { formProps, saveButtonProps } = useForm<IProfessor>();

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar professor">
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="E-mail" name="email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Título" name="title">
              <Select
                placeholder="Selecione um titulo..."
                style={{ minWidth: 400 }}
              >
                <Select.Option value="specialist">Especialista</Select.Option>
                <Select.Option value="master">Mestre</Select.Option>
                <Select.Option value="doctor">Doutor</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
