import { PaymentPlanShow } from '../pages/ecommerce/payment_plan';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'order_payment_plans',
  label: 'Pagamento',
  list: PaymentPlanShow,
  actions: ['list', 'cancel'],
  parentName: 'sales',
  route: 'sales/orders/:order_id/payment_plans/:id',
  hide: true
};

export default generateResources(resource);
