import { Card, Space, Typography } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import { ForumsItem } from './ForumsItem';
import { Link, useParams } from 'react-router-dom';
import { useList } from '@refinedev/core';
import { useMemo } from 'react';
import type { IProfessorAreaForum } from 'interfaces/professor_area/forums';

export const ForumsList = () => {
  const params = useParams();
  const classroomId = params.id;

  const { data } = useList<IProfessorAreaForum>({
    resource: 'professor_area/forums',
    filters: [
      {
        field: 'by_grandparent_id',
        operator: 'eq',
        value: classroomId
      }
    ]
  });

  const hasForums = useMemo(() => {
    if (data) {
      return data.total > 0;
    }
  }, [data]);

  return (
    <Card
      title={
        <Space className="!items-center">
          <CommentOutlined className="text-xl text-neutral-400" />
          <Typography.Text className="text-md font-semibold">
            Fórum da turma
          </Typography.Text>
        </Space>
      }
      extra={<Link to={{ search: '?tab=forums' }}>Ver todas</Link>}
      styles={{
        body: {
          padding: 8
        }
      }}
    >
      <div className="w-full flex flex-col max-h-[180px] overflow-y-auto overflow-x-hide">
        {!hasForums && (
          <Typography.Text className="text-center py-4 text-neutral-500">
            Nenhum forum encontrado
          </Typography.Text>
        )}

        {data?.data.map(forum => (
          <>
            <ForumsItem key={forum.id} />
            <hr className="w-[calc(100%-24px)] mx-auto" />
          </>
        ))}
      </div>
    </Card>
  );
};
