import * as Icons from '@ant-design/icons';
import { Button, Progress, Upload, UploadProps } from 'antd';
import { shallow } from 'zustand/shallow';
import { useCoreUploadStore } from 'store/core-upload';
import { useEffect } from 'react';

export interface CoreUploadProps {
  onChange?: (url: string | undefined) => void;
}

const CoreUpload = ({ onChange }: CoreUploadProps) => {
  const accept = useCoreUploadStore(s => s.accept);

  const { loading, coreId, percentage, setState } = useCoreUploadStore(
    s => ({
      loading: s.loading,
      coreId: s.coreId,
      accept: s.accept,
      percentage: s.percentage,
      file: s.file,
      setState: s.setState
    }),
    shallow
  );

  useEffect(() => {
    if (percentage === 100) {
      onChange?.(coreId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  const props: UploadProps = {
    beforeUpload: file => {
      setState({ file });
      return false;
    },
    accept: accept,
    onRemove: () => setState({ file: null })
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="!flex">
        <Upload {...props} className="w-full" maxCount={1} accept={accept}>
          <Button
            className="!flex items-center justify-center w-full"
            disabled={loading}
          >
            <Icons.UploadOutlined />
            Upload
          </Button>
        </Upload>
        {loading && (
          <div className="w-full flex items-center">
            <Progress percent={percentage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoreUpload;
