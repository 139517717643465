import { Col, Row, Typography } from 'antd';
import { IContract } from 'interfaces/contracts';
import { ReactNode } from 'react';
import { formatDate } from 'services/date';

const { Text } = Typography;

interface TabContractInfoProps {
  contract: IContract;
}

export const TabContractInfo = ({ contract }: TabContractInfoProps) => {
  return (
    <div className="space-y-6">
      <InfoComponent title="Nº do Contrato" data={contract.code} />
      {/* <InfoComponent
        title="Status"
        data={
          <Tag color={CONTRACT_STATUSES_MAP[contract.status].color}>
            {CONTRACT_STATUSES_MAP[contract.status].label}
          </Tag>
        }
      /> */}
      <InfoComponent
        title="Vigência"
        data={`${formatDate(contract.starts_at, 'DD/MM/YYYY')} -> ${formatDate(
          contract.expires_at,
          'DD/MM/YYYY'
        )}`}
      />
      <InfoComponent
        title="Nome da empresa"
        data={contract.company.name || '-'}
      />
      <InfoComponent title="CNPJ" data={contract.company.cnpj || '-'} />
      <InfoComponent title="Curso" data={contract.course_name || '-'} />
      <InfoComponent
        title="Quantidade máxima de alunos"
        data={contract.max_enrollments.toString() || '-'}
      />
      <InfoComponent
        title="Alunos associados"
        data={contract.number_enrollments.toString() || '-'}
      />
    </div>
  );
};

const InfoComponent = ({ title, data }: { title: string; data: ReactNode }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col md={6}>
        <Text>{title}</Text>
      </Col>
      <Col md={16}>
        <Text strong>{data}</Text>
      </Col>
    </Row>
  );
};
