/* eslint-disable unicorn/filename-case */
export const BoletoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M4.444 7.222v5.556m2.778-5.556v3.334m5.556-3.334v3.334m2.777-3.334v5.556M10 7.222v3.334m-2.778 2.222h5.556m-9.97-8.334h14.385c.63 0 1.14.511 1.14 1.141v8.83c0 .63-.51 1.14-1.14 1.14H2.807c-.63 0-1.14-.51-1.14-1.14v-8.83c0-.63.51-1.14 1.14-1.14Z"
      />
    </svg>
  );
};
