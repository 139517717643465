import { Form, InputNumber, Modal, ModalProps, Typography } from 'antd';
import { IAcademicRecord } from 'interfaces/academic_record';
import { IClassroomEnrollment } from 'interfaces/classrooms';
import { useCreate, useNotification, useUpdate } from '@refinedev/core';
import { useForm } from '@refinedev/antd';

export interface UpdateAttendanceModalProps {
  modalClose: () => void;
  modalProps: ModalProps;
  refetch: () => void;
  classroomEnrollment?: IClassroomEnrollment;
  academicRecords?: IAcademicRecord;
  resourceType: 'ClassroomEnrollment' | 'AcademicRecord';
}

export const UpdateAttendanceModal = ({
  modalClose,
  modalProps,
  classroomEnrollment,
  academicRecords,
  resourceType,
  refetch
}: UpdateAttendanceModalProps) => {
  const { mutateAsync: updateAttendanceRate, isLoading: updateLoading } =
    useUpdate();
  const { mutateAsync: createAttendanceRate, isLoading } = useCreate();
  const { open } = useNotification();
  const { form, formProps } = useForm();

  if (!classroomEnrollment && !academicRecords) {
    return null;
  }

  const handleUpdateAttendanceRate = async () => {
    if (!classroomEnrollment) {
      return;
    }
    const resource = `classrooms/${classroomEnrollment?.classroom.id}/enrollments`;

    return form
      .validateFields()
      .then(async () => {
        return updateAttendanceRate({
          resource,
          id: classroomEnrollment?.id,
          values: { ...form.getFieldsValue() },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Frequência do aluno atualizada com sucesso'
          }),
          errorNotification: () => {
            return {
              description: 'Algo deu errado',
              message: 'Não foi possível atualizar a frequência do aluno',
              type: 'error'
            };
          }
        }).then(() => {
          refetch();
          modalClose();
          form.resetFields();
        });
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: 'Favor conferir todos os campos obrigatórios.',
          description: 'Error ao atualizar registro!'
        });
      });
  };

  const handleCreateAttendanceRate = async () => {
    if (!academicRecords) {
      return;
    }
    const resource = `graduate/academic_records/${academicRecords.id}/update_attendance`;

    return form
      .validateFields()
      .then(async () => {
        return createAttendanceRate({
          resource,
          values: { ...form.getFieldsValue() },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Frequência do aluno atualizada com sucesso'
          }),
          errorNotification: () => {
            return {
              description: 'Algo deu errado',
              message: 'Não foi possível atualizar a frequência do aluno',
              type: 'error'
            };
          }
        }).then(() => {
          refetch();
          modalClose();
          form.resetFields();
        });
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: 'Favor conferir todos os campos obrigatórios.',
          description: 'Error ao atualizar registro!'
        });
      });
  };

  const actionFunction =
    resourceType === 'ClassroomEnrollment'
      ? handleUpdateAttendanceRate
      : handleCreateAttendanceRate;

  return (
    <Modal
      style={{ maxWidth: 415 }}
      {...modalProps}
      confirmLoading={isLoading || updateLoading}
      title="Editar frequência"
      cancelText="Cancelar"
      okText="Salvar"
      onOk={actionFunction}
      className="!w-[1022px]"
      destroyOnClose
    >
      <Typography.Paragraph className="!mb-0 opacity-50">
        Aluno:
      </Typography.Paragraph>
      <Typography.Paragraph>
        {classroomEnrollment?.course_enrollment.user.name}
      </Typography.Paragraph>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Frequência"
          name="attendance_rate"
          rules={[
            {
              required: true,
              message: 'Por favor, preencha a frequência.'
            }
          ]}
        >
          <InputNumber
            min={0}
            max={100}
            style={{ width: '100%' }}
            placeholder="0 - 100"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
