import { coursesIcon } from '../assets/icons/courses';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'course_creation',
  label: 'Cursos',
  icon: coursesIcon
};

export default generateResources(resource);
