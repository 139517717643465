import { Collapse, Space, Typography } from 'antd';
import { DocumentPanelBody } from './DocumentPanelBody';
import { IDocument } from 'interfaces/documentation/document';
import {
  IDocumentTemplate,
  IDocumentTemplateCourse,
  IDocumentTemplateKind
} from 'interfaces/documentation/document-template';
import { useMemo } from 'react';

interface DocumentListProps {
  title: string;
  templates: IDocumentTemplate[];
  documents: IDocument[];
  templateCourses: IDocumentTemplateCourse[];
}

export type DocumentsByTemplateData = Record<
  IDocumentTemplateKind,
  DocumentsByTemplateMap
>;

export interface DocumentsByTemplateMap {
  name: string;
  documents: IDocument[];
  required: boolean;
}

const sortDocumentsByUpdatedAt = (documents: IDocument[]) => {
  documents.find(document => document.status === 'approved');

  // sort by updated_at but having the documents with status approved first
  return documents.sort((a, b) => {
    if (a.status === 'approved') {
      return -1;
    }

    if (b.status === 'approved') {
      return 1;
    }

    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  });
};

export const DocumentList = ({
  title,
  templates,
  documents,
  templateCourses
}: DocumentListProps) => {
  const documentsByTemplate = useMemo(() => {
    return templates.reduce(
      (acc: Record<string, DocumentsByTemplateMap>, template) => {
        const { name, id: templateId, required } = template;
        const filteredDocumentsByTemplate = documents.filter(
          document => document.template_id === templateId
        );

        const supplementaryRequired = templateCourses.find(
          templateCourse => templateCourse.template_id === templateId
        )?.required;

        acc[templateId] = {
          name,
          documents: sortDocumentsByUpdatedAt(filteredDocumentsByTemplate),
          required: supplementaryRequired ?? required
        };

        return acc;
      },
      {}
    );
  }, [documents, templateCourses, templates]);

  return (
    <Space direction="vertical" className="w-full border-b pb-6">
      <Typography.Title level={5}>{title}</Typography.Title>

      <Collapse
        expandIconPosition="right"
        className="documents-collapse overflow-hidden"
        style={{ borderRadius: 12 }}
      >
        {Object.entries(documentsByTemplate).map(([_, data], index) => (
          <DocumentPanelBody
            key={index}
            data={data}
            collapsible={data.documents.length > 0 ? 'header' : 'disabled'}
          />
        ))}
      </Collapse>
    </Space>
  );
};
