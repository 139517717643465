import { Edit, useForm } from '@refinedev/antd';
import { Form, Input, Typography } from 'antd';
import { IRole } from 'interfaces/roles';
import { Permissions } from './components/permissions';
import { useMemo } from 'react';

export const RoleEdit = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm<IRole>();

  const permissions = useMemo(
    () => queryResult?.data?.data.permissions,
    [queryResult]
  );

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar regra de acesso">
      <Form {...formProps} layout="vertical">
        <Typography.Title level={4} style={{ marginBottom: 20 }}>
          Informações gerais
        </Typography.Title>

        <Form.Item
          label="Nome do perfil"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Permissões" name="permissions" hidden>
          <Input value={form.getFieldValue('permissions')} hidden />
        </Form.Item>

        <Typography.Title level={4} style={{ margin: '30px 0 20px' }}>
          Regras de acesso
        </Typography.Title>

        <Permissions
          permissions={permissions || []}
          onChange={data => {
            form.setFieldValue('permissions', data);
          }}
        />
      </Form>
    </Edit>
  );
};
