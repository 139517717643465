import { Col, Row, Space, Typography } from 'antd';
import { CreateButton, Show } from '@refinedev/antd';
import { IDocumentation } from 'interfaces/documentation/documentations';
import { IEnrollment } from 'interfaces/enrollments';
import { ShowFieldData } from 'components/ShowFieldData';
import { formatDate } from 'services/date';
import { transformAttributes } from 'services/certificate';
import { useNavigate, useParams } from 'react-router-dom';
import { useOne } from '@refinedev/core';

export const PendingCertificateShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: enrrolmentRequest, isLoading } = useOne<IEnrollment>({
    resource: 'graduate/courses/enrollments',
    id
  });
  const { data: documentationRequest } = useOne<IDocumentation>({
    resource: 'documentations',
    id: enrrolmentRequest?.data?.documentation_id
  });
  const enrrolment = enrrolmentRequest?.data;
  const documents = transformAttributes(documentationRequest?.data?.documents);

  return (
    <Show
      isLoading={isLoading}
      title="Visualizar certificado a emitir"
      headerButtons={
        <CreateButton
          icon={null}
          onClick={() =>
            navigate(
              `/certificate_management/graduate/courses/pending_certificates/create?enrollmentId=${id}`
            )
          }
        >
          Criar e emitir certificado
        </CreateButton>
      }
    >
      <Typography.Title level={5} style={{ margin: '0 0 24px' }}>
        Informações do aluno
      </Typography.Title>
      <Space direction="vertical" className="w-full !gap-4">
        <Row gutter={[16, 16]} className="w-full">
          <Col span={8}>
            <ShowFieldData
              label="Nome de usuário"
              value={enrrolment?.user.name}
            />
          </Col>
          <Col span={8}>
            <ShowFieldData label="E-mail" value={enrrolment?.user.email} />
          </Col>
          <Col span={8}>
            <ShowFieldData
              label="Telefone"
              value={enrrolment?.user.personal_info?.phone_number ?? '--'}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col span={8}>
            <ShowFieldData label="Curso" value={enrrolment?.course?.name} />
          </Col>
        </Row>
      </Space>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Identidade
      </Typography.Title>
      <Space direction="vertical" className="w-full !gap-4">
        <Row gutter={[16, 16]} className="w-full">
          <Col span={12}>
            <ShowFieldData
              label="Nome civil"
              value={documents['Nome completo'] ?? '--'}
            />
          </Col>
          <Col span={12}>
            <ShowFieldData
              label="CPF"
              value={documents['Número do CPF'] ?? '--'}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col span={6}>
            <ShowFieldData
              label="RG"
              value={documents['Número do RG'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="Órgão expeditor"
              value={documents['Órgão emissor'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="UF da identidade"
              value={documents['UF'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="Data de emissão"
              value={
                (documents['Data de Emissão'] &&
                  formatDate(documents['Data de Emissão'], 'DD/MM/YYYY')) ??
                '--'
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col span={6}>
            <ShowFieldData
              label="Nacionalidade"
              value={documents['Nacionalidade'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="Naturalidade"
              value={documents['Naturalidade'] ?? '--'}
            />
          </Col>
        </Row>
      </Space>
    </Show>
  );
};
