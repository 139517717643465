import React from 'react';
import { IAcademicRecord } from '../../../../../interfaces/academic_record';
import { Space, Typography } from 'antd';
import { TagField, TextField } from '@refinedev/antd';
import { formatDate } from '../../../../../services/date';

interface AcademicRecordDetailsProps {
  academic_record: IAcademicRecord;
}

export const AcademicRecordDetails: React.FC<AcademicRecordDetailsProps> = ({
  academic_record
}) => {
  const classroom = academic_record.parent.classroom;
  const course = academic_record.parent.course_enrollment.course;
  const { discipline } = classroom ?? {};
  const durationInMinutes = discipline?.duration_in_minutes ?? 0;

  return (
    <Space direction="vertical" size={16} className="pl-2">
      <Space direction="horizontal" size="middle">
        <Space>
          <Typography.Text strong>Curso de Origem:</Typography.Text>
          <Typography.Text> {course.name} </Typography.Text>
        </Space>
      </Space>
      <Space direction="horizontal" size="middle">
        <Space>
          <Typography.Text strong>Disciplina de Origem:</Typography.Text>
          <Typography.Text> {discipline?.name} </Typography.Text>
        </Space>
      </Space>
      <Space direction="horizontal" size="middle">
        <Space>
          <Typography.Text strong>Código da Turma:</Typography.Text>
          <Typography.Text>{classroom?.code}</Typography.Text>
        </Space>
        <Space>
          <Typography.Text strong>Descrição da Turma:</Typography.Text>
          <Typography.Text>{classroom?.description}</Typography.Text>
        </Space>
      </Space>

      <Space direction="horizontal" size="middle">
        <Space>
          <Typography.Text strong>Carga Horária:</Typography.Text>
          <Typography.Text>{durationInMinutes / 60} horas</Typography.Text>
        </Space>
        <Space>
          <Typography.Text strong>Nota:</Typography.Text>
          <Typography.Text> {academic_record.score} </Typography.Text>
        </Space>

        <Space>
          <Typography.Text strong>
            Data de Finalização da Turma:
          </Typography.Text>
          <Typography.Text>
            {formatDate(classroom.ends_at, 'DD/MM/YYYY - hh:mm')}
          </Typography.Text>
        </Space>
      </Space>
    </Space>
  );
};
