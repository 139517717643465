import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { HttpError } from '@refinedev/core';
import { IGroup } from 'interfaces/epimetheus/groups';
import { useNavigate, useParams } from 'react-router-dom';

export const TagGroupCreate = () => {
  const { libraryId } = useParams();
  const navigate = useNavigate();

  const { formProps, saveButtonProps } = useForm<IGroup, HttpError, IGroup>({
    resource: `prometheus/v2/libraries/${libraryId}/tag_groups`,
    redirect: false,
    successNotification: () => ({
      type: 'success',
      description: 'Sucesso!',
      message: 'Grupo de tags cadastrado com sucesso'
    }),
    errorNotification: () => ({
      type: 'error',
      description: 'Ops!',
      message: 'Erro ao cadastrar grupo de tags. Tente novamente.'
    }),
    onMutationSuccess: () => {
      navigate(`/teaching_resources/libraries/show/${libraryId}?tab=tag_groups`);
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar gerenciador de tag">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
