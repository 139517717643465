import { Badge, Button } from 'antd';
import { DiscountCampaign } from './ResumeDiscountForm';
import { Stack } from '@qcx/ui';
import { useDelete, useInvalidate } from '@refinedev/core';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const ResumeManyDiscount = ({
  discounts,
  onCreate
}: {
  discounts: DiscountCampaign[];
  onCreate?: () => void;
}) => {
  const filterInvoiceDueRules = (value?: string[] | string) => {
    if (Array.isArray(value)) {
      return value.filter(v => v.toString() !== '1')?.length > 0;
    }

    return value?.toString() !== '1';
  };

  const { mutate: mutateDelete } = useDelete();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const dueInvoiceDiscount = discounts.find(d =>
    d?.rules?.find(
      r => r?.resource_type === 'invoice_due' && filterInvoiceDueRules(r?.value)
    )
  );
  const productDiscount = discounts.find(d =>
    d?.rules?.find(
      r =>
        r?.resource_type === 'invoice_due' && !filterInvoiceDueRules(r?.value)
    )
  );
  const { name, status } = productDiscount || dueInvoiceDiscount || {};

  const deleteCampaing = () => {
    setIsLoading(true);
    discounts.map(rule =>
      mutateDelete({
        resource: 'discount_campaigns',
        id: rule.id
      })
    );
  };

  return (
    <Stack
      css={{
        w: '$full',
        maxW: 400,
        h: 'fit-content',
        minH: 200,
        gap: '$6',
        flexWrap: 'wrap',
        '@lg': { flexWrap: 'nowrap' }
      }}
    >
      <Stack
        flow="column"
        css={{
          rounded: '$lg',
          bgColor: '$neutral100',
          p: '$4',
          gap: '$1',
          w: '$full',
          flexDir: 'column',
          justifyContent: 'space-between',
          h: 'fit-content'
        }}
      >
        <div>
          <div className="border-b pb-3">
            <p className="m-0 font-semibold text-base leading-6 text-[rgba(0,0,0,0.85)]">
              Regra Vigente
            </p>
            {status !== 'active' && <Badge color="danger">Inativo</Badge>}
          </div>
          <div className="mt-3">
            <div className="flex justify-between items-center">
              <p className="font-medium text-sm leading-5 text-[#667085]">
                Identificação
              </p>
              <p className="font-normal text-sm leading-5 text-right text-[#1D2939]">
                {name}
              </p>
            </div>
          </div>
          <div className="mt-3">
            <div className="flex justify-between items-center">
              <p className="font-medium text-sm leading-5 text-[#667085]">
                Produto
              </p>
              <p className="font-normal text-sm leading-5 text-right text-[#1D2939]">
                {`${productDiscount?.discount_config?.value || 0}%`}
              </p>
            </div>
          </div>
          <div className="mt-3">
            <div className="flex justify-between items-center">
              <p className="font-medium text-sm leading-5 text-[#667085]">
                Tempo de inadimplência
              </p>
              <p className="font-normal text-sm leading-5 text-right text-[#1D2939]">
                {`${dueInvoiceDiscount?.discount_config?.value || 0}%`}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-evenly justify-self-end gap-3">
          <Button
            disabled={isLoading}
            type="default"
            className="w-full"
            onClick={() => deleteCampaing()}
          >
            Excluir regra
          </Button>
          <Button
            type="primary"
            disabled={isLoading}
            className="w-full"
            onClick={() => (onCreate ? onCreate() : navigate('create'))}
          >
            Criar outra regra
          </Button>
        </div>
      </Stack>
    </Stack>
  );
};
