/* eslint-disable unicorn/filename-case */
export const buildingsIcon = (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66671 6.33333H11.8667C12.6134 6.33333 12.9868 6.33333 13.272 6.47866C13.5229 6.60649 13.7269 6.81046 13.8547 7.06135C14 7.34656 14 7.71993 14 8.46667V13M8.66671 13V3.13333C8.66671 2.3866 8.66671 2.01323 8.52138 1.72801C8.39355 1.47713 8.18958 1.27316 7.93869 1.14532C7.65348 1 7.28011 1 6.53337 1H4.13337C3.38664 1 3.01327 1 2.72805 1.14532C2.47717 1.27316 2.2732 1.47713 2.14537 1.72801C2.00004 2.01323 2.00004 2.3866 2.00004 3.13333V13M14.6667 13H1.33337M4.33337 3.66667H6.33337M4.33337 6.33333H6.33337M4.33337 9H6.33337"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
