import { Col, Form, Row, Select } from 'antd';
import { useSelect } from '@refinedev/antd';
import { useState } from 'react';

export interface LibrarySelectProps {
  onChange?: (value: string | undefined) => void;
}

export const LibrarySelect = ({ onChange }: LibrarySelectProps) => {
  const [libraryId, setLibraryId] = useState<string | undefined>();

  const { selectProps: librarySelectProps } = useSelect({
    resource: 'prometheus/v2/libraries',
    optionLabel: 'name',
    optionValue: 'id',
    onSearch: (value: string) => {
      return [
        {
          field: 'q',
          operator: 'eq',
          value: value
        }
      ];
    }
  });

  const { selectProps: contentSelectProps } = useSelect({
    resource: `prometheus/v2/libraries/${libraryId}/contents`,
    optionLabel: 'name',
    optionValue: 'id',
    onSearch: (value: string) => {
      return [
        {
          field: 'q',
          operator: 'eq',
          value: value
        }
      ];
    }
  });

  const onChangeLibrary = (value: unknown) => {
    setLibraryId(value as string);
  };

  const onChangeContent = (value: unknown) => {
    onChange?.(`${libraryId}:${value}`);
  };

  return (
    <>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="library" label="Biblioteca">
              <Select {...librarySelectProps} onSelect={onChangeLibrary} />
            </Form.Item>
          </Col>
          {libraryId && (
            <Col span={12}>
              <Form.Item name="content" label="Conteúdo">
                <Select {...contentSelectProps} onSelect={onChangeContent} />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
};
