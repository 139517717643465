import FilterForm from '../../../components/forms/FilterForm';
import { Button, Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { CanAccess, CrudFilters, HttpError } from '@refinedev/core';
import { ICoupon } from 'interfaces/coupons';
import { IOrder } from 'interfaces/orders';
import {
  List,
  ShowButton,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { NotAllowed } from '../../../components/NotAllowed';
import { formatDate } from 'services/date';
import { formatMoney } from 'services/money';
import { tagColorByStatus } from '../../../utils/statusColorMapping';
import { translateStatus } from 'services/orders';

interface IFilter {
  orderNumber: string;
  byEmail: string;
  byCpf: string;
  byProduct: string;
  byStatus: string;
  fromAgent: boolean;
}

export const OrderList = () => {
  const { tableProps, searchFormProps } = useTable<IOrder, HttpError, IFilter>({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { byEmail, byCpf, byProduct, fromAgent, byStatus, orderNumber } =
        params;

      filters.push(
        {
          field: 'filter[by_order_number]',
          operator: 'eq',
          value: orderNumber
        },
        {
          field: 'filter[by_email]',
          operator: 'eq',
          value: byEmail
        },
        {
          field: 'filter[by_cpf]',
          operator: 'eq',
          value: byCpf?.replace(/\D/g, '')
        },
        {
          field: 'filter[by_product_name]',
          operator: 'eq',
          value: byProduct
        },
        {
          field: 'filter[from_agent]',
          operator: 'eq',
          value: fromAgent
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: byStatus
        }
      );

      return filters;
    }
  });

  return (
    <CanAccess fallback={<NotAllowed />}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <List>
            <FilterForm searchFormProps={searchFormProps}>
              <Form.Item label="Número" name="orderNumber">
                <Input placeholder="1" />
              </Form.Item>

              <Form.Item label="E-mail" name="byEmail">
                <Input placeholder="e-mail do aluno" />
              </Form.Item>

              <Form.Item label="CPF" name="byCpf">
                <Input placeholder="somente números" />
              </Form.Item>
              <Form.Item label="Produto" name="byProduct">
                <Input placeholder="Nome do produto" />
              </Form.Item>
              <Form.Item label="Status" name="byStatus" className="w-[200px]">
                <Select placeholder="Selecione...">
                  <Select.Option key="initial">Não Recebido</Select.Option>
                  <Select.Option key="created">Criado</Select.Option>
                  <Select.Option key="approved">Aprovado</Select.Option>
                  <Select.Option key="returned">Retornado</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Tipo" name="fromAgent" className="w-[200px]">
                <Select placeholder="Selecione...">
                  <Select.Option key="true">Manual (Gerencial)</Select.Option>
                  <Select.Option key="false">Vitrine</Select.Option>
                </Select>
              </Form.Item>
            </FilterForm>
            <Table {...tableProps} rowKey="id">
              <Table.Column
                dataIndex="order_number"
                key="order_number"
                title="Número"
                render={value => <TextField value={value} />}
              />

              <Table.Column
                dataIndex="user"
                key="user"
                title="Nome do aluno"
                render={({ name }) => <TextField value={name} />}
              />
              <Table.Column
                dataIndex="status"
                key="status"
                title="Status"
                render={value => (
                  <TagField
                    color={tagColorByStatus(value)}
                    value={translateStatus(value)}
                  />
                )}
              />
              <Table.Column
                dataIndex="items"
                key="items"
                title="Produtos"
                render={value => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  return value.map((item: any, idx: number) => (
                    <TagField
                      style={{ border: 'none' }}
                      color="purple"
                      key={`items=${idx}`}
                      value={item.name}
                    />
                  ));
                }}
              />
              <Table.Column
                dataIndex="amount"
                key="amount"
                title="Valor"
                render={amount => <TextField value={formatMoney(amount)} />}
              />
              <Table.Column
                dataIndex="coupon"
                key="coupon"
                title="Cupom"
                render={(coupon: ICoupon) => (
                  <Button href={`/coupons/edit/${coupon?.id}`} target="_blank">
                    {coupon?.code}
                  </Button>
                )}
              />
              <Table.Column
                dataIndex="created_at"
                key="created_at"
                title="Data de criação"
                render={value => <TextField value={formatDate(value)} />}
              />
              <Table.Column<IOrder>
                title="Ações"
                dataIndex="actions"
                render={(_, record) => {
                  return (
                    <Space>
                      <ShowButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </CanAccess>
  );
};
