import FilterForm from 'components/forms/FilterForm';
import { Col, Form, Input } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IAssessmentAttempt } from 'interfaces/assessments';
import { ObjectiveAttemptsTable } from './ObjectiveAttemptsTable';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';

interface IFilter {
  assessment_id: string;
  email: string;
  student: string;
}

export const TabObjectiveAttempts = () => {
  const params = useParams();
  const assessmentId = params.id;

  const { tableProps, searchFormProps } = useTable<
    IAssessmentAttempt,
    HttpError,
    IFilter
  >({
    resource: 'assessments/attempts',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { student, email } = params;

      filters.push(
        {
          field: 'filter[by_assessment_id]',
          operator: 'eq',
          value: assessmentId
        },
        {
          field: 'filter[student]',
          operator: 'eq',
          value: student
        },
        {
          field: 'filter[email]',
          operator: 'eq',
          value: email
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_assessment_id]',
          operator: 'eq',
          value: assessmentId
        }
      ]
    }
  });

  return (
    <>
      <FilterForm searchFormProps={searchFormProps}>
        <Col xs={24} md={11}>
          <Form.Item label="Aluno" name="student">
            <Input placeholder="Insira o nome do aluno" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="E-mail" name="email">
            <Input placeholder="insira o e-mail do aluno" />
          </Form.Item>
        </Col>
      </FilterForm>

      <ObjectiveAttemptsTable
        tableProps={tableProps}
        searchFormProps={searchFormProps}
      />
    </>
  );
};
