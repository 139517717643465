import * as Icons from '@ant-design/icons';
import { Alert, Button, Form, Table, Upload } from 'antd';
import { Create, useForm } from '@refinedev/antd';
import { UserRow } from 'interfaces/bulk_users';
import { parseFile } from 'services/bulk-users';
import { useState } from 'react';

const FileTable = ({ userList }: { userList: UserRow[] }) => (
  <Table
    dataSource={userList}
    columns={[
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email'
      }
    ]}
    pagination={{ pageSize: 5 }}
  />
);

const checkField = (field: string) => field?.length && field.length > 0;

export const BulkUserSuspend = () => {
  const { formProps, saveButtonProps } = useForm({ redirect: false });

  const [userList, setUserList] = useState<UserRow[]>([]);

  const valid = userList.every(({ email }) => {
    return checkField(email);
  });

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onFinish = (values: {}) => {
    formProps.onFinish!({ ...values, bulk: userList });
  };

  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        disabled: !valid || userList.length === 0
      }}
    >
      <Form {...formProps} layout="vertical" onFinish={onFinish}>
        <Form.Item>
          <Upload
            accept="text/csv"
            fileList={[]}
            onRemove={() => setUserList([])}
            beforeUpload={async newFile => {
              const rows = await parseFile(newFile);

              setUserList(rows);

              return false;
            }}
          >
            <Button icon={<Icons.UploadOutlined />}>Escolher arquivo</Button>
          </Upload>
        </Form.Item>
      </Form>
      <FileTable userList={userList} />
      {valid ? (
        <Alert type="warning" message="Usuários serão suspensos" />
      ) : (
        <Alert type="error" message="Arquivo inválido" />
      )}
    </Create>
  );
};
