import * as Icons from '@ant-design/icons';
import FilterForm from '../../../components/forms/FilterForm';
import { Button, Col, Form, Input, Row, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { EditButton, List, TextField, useTable } from '@refinedev/antd';
import { ICourseCertificate } from 'interfaces/course_certificate';
import { getCourseCertificateUrl } from 'requests/certificates';
import { useState } from 'react';

interface IFilter {
  email: string;
}

interface ActionsProps {
  certificateId: string;
}

const DownloadButton = ({ certificateId }: ActionsProps) => {
  const [loading, setLoading] = useState(false);

  const downloadCertificate = async () => {
    setLoading(true);

    try {
      const { url } = await getCourseCertificateUrl({
        certificateId: certificateId
      });

      window.open(url, '_blank');
    } catch {}
    setLoading(false);
  };

  return (
    <div className="flex gap-2">
      <Space>
        <Button
          size="small"
          onClick={downloadCertificate}
          loading={loading}
          icon={<Icons.DownloadOutlined />}
        />
      </Space>
    </div>
  );
};

export const CertificateList = () => {
  const { tableProps, searchFormProps } = useTable<
    ICourseCertificate,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { email } = params;

      filters.push({
        field: 'filter[email]',
        operator: 'eq',
        value: email
      });

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="E-mail" name="email">
              <Input placeholder="e-mail do aluno" />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="user"
              key="email"
              title="E-mail"
              render={({ email }) => <TextField value={email} />}
            />
            <Table.Column
              dataIndex="recipient_name"
              key="recipient_name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="course_name"
              key="course_name"
              title="Curso"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="duration_in_hours"
              key="duration_in_hours"
              title="Duração (horas)"
              render={value => <TextField value={value} />}
            />
            <Table.Column<ICourseCertificate>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <DownloadButton certificateId={record.id} />
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
