import FilterForm from 'components/forms/FilterForm';
import { Col, Form, Input } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { DiscursiveAttemptsTable } from './DiscursiveAttemptsTable';
import { IAssessmentUser } from 'interfaces/assessments';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';

interface IFilter {
  search_name: string;
  search_email: string;
}

export const TabDiscursiveAttempts = () => {
  const params = useParams();
  const assessmentId = params.id;

  const { tableProps, searchFormProps } = useTable<
    IAssessmentUser,
    HttpError,
    IFilter
  >({
    resource: `assessments/${assessmentId}/users`,
    onSearch: params => {
      const filters: CrudFilters = [];
      const { search_name, search_email } = params;

      filters.push(
        {
          field: 'filter[search_name]',
          operator: 'eq',
          value: search_name
        },
        {
          field: 'filter[search_email]',
          operator: 'eq',
          value: search_email
        }
      );

      return filters;
    }
  });

  return (
    <>
      <FilterForm searchFormProps={searchFormProps}>
        <Col xs={24} md={11}>
          <Form.Item label="Aluno" name="search_name">
            <Input placeholder="Insira o nome do aluno" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="E-mail" name="search_email">
            <Input type="email" placeholder="insira o e-mail do aluno" />
          </Form.Item>
        </Col>
      </FilterForm>

      <DiscursiveAttemptsTable tableProps={tableProps} />
    </>
  );
};
