import { Col, Row } from 'antd';
import {
  CompaniesFilter,
  ICompaniesFilter
} from './components/CompaniesFilter';
import { CompaniesTable } from './components/CompaniesTable';
import { CrudFilters, HttpError } from '@refinedev/core';
import { ICompany } from 'interfaces/companies';
import { List, useTable } from '@refinedev/antd';

export const CompaniesList = () => {
  const { tableProps, searchFormProps } = useTable<
    ICompany,
    HttpError,
    ICompaniesFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { by_name, by_cnpj, created_before, created_after } = params;

      filters.push(
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value: by_name
        },
        {
          field: 'filter[by_cnpj]',
          operator: 'eq',
          value: by_cnpj
        },
        {
          field: 'filter[created_before]',
          operator: 'eq',
          value: created_before
        },
        {
          field: 'filter[created_after]',
          operator: 'eq',
          value: created_after
        }
      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <CompaniesFilter searchFormProps={searchFormProps} />
          <CompaniesTable tableProps={tableProps} />
        </List>
      </Col>
    </Row>
  );
};
