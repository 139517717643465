import React, { useCallback } from 'react';
import { Checkbox } from 'antd';
import type { ParsedPermission } from 'services/permissions';

interface PermissionActionColumnProps {
  value: string;
  record: ParsedPermission;
  action: string;
  checked?: boolean;
  disabled?: boolean;
  onCheck: (value: string, parent: string) => void;
}

export const PermissionActionColumn = React.memo(
  (props: PermissionActionColumnProps) => {
    const { action, value, checked, disabled, record, onCheck } = props;

    const checkAction = useCallback(
      (resource: ParsedPermission, action: string) => {
        return Boolean(resource.actions!.includes(action));
      },
      []
    );

    const permission = record as ParsedPermission;

    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          backgroundColor: checkAction(record, action) ? '#fff' : '#F3f3f3'
        }}
      >
        <Checkbox
          disabled={disabled || !checkAction(record, action)}
          name={value}
          checked={checked}
          style={{ opacity: !checkAction(record, action) ? 0.5 : 1 }}
          onChange={() => {
            onCheck(value, permission.parentName!);
          }}
        />
      </div>
    );
  }
);

PermissionActionColumn.displayName = 'PermissionActionColumn';
