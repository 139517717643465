import {
  CreateButton,
  DateField,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { IProductBonus } from 'interfaces/product_bonuses';
import { Space, Table } from 'antd';

export const BonusList = () => {
  const { tableProps } = useTable<IProductBonus>();

  return (
    <List title="Bônus">
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="user"
          key="user"
          title="Usuário"
          render={value => <TextField value={value.name} />}
        />
        <Table.Column
          dataIndex="product"
          key="product"
          title="Produto"
          render={value => <TextField value={value.name} />}
        />
        <Table.Column
          dataIndex="expires_at"
          key="expires_at"
          title="Expiração"
          render={value => <DateField value={value} />}
        />
        <Table.Column<IProductBonus>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
      <CreateButton resource="bulk_product_bonuses">Lote</CreateButton>
    </List>
  );
};
