import { Alert, Space, Typography } from 'antd';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { IGraduateCourseEnrollment } from '../../../../interfaces/graduate_course_enrollments';
import { IUser } from 'interfaces/users';
import { TagField } from '@refinedev/antd';
import { formatDate } from '../../../../services/date';
import {
  getStatusColor,
  translateDocumentationStatus
} from 'services/documentations';

interface StudentInformationProps {
  user?: IUser;
  course?: IGraduateCourse;
  status: string;
  enrollment: IGraduateCourseEnrollment | undefined;
}

export const StudentInformation = ({
  user,
  course,
  enrollment,
  status
}: StudentInformationProps) => {
  return (
    <Space direction="vertical" className="border-b mb-4">
      <Space wrap className="w-full pb-4" size="large">
        <Space direction="horizontal">
          <Typography.Text strong>Nome:</Typography.Text>
          <Typography.Text>{user?.name ?? 'Não disponível'}</Typography.Text>
        </Space>

        <Space direction="horizontal">
          <Typography.Text strong>E-mail:</Typography.Text>
          <Typography.Text>{user?.email ?? 'Não disponível'}</Typography.Text>
        </Space>

        <Space direction="horizontal">
          <Typography.Text strong>CPF:</Typography.Text>
          <Typography.Text>
            {user?.personal_info?.document_info ?? 'Não disponível'}
          </Typography.Text>
        </Space>

        <Space direction="horizontal">
          <Typography.Text strong>Data de início:</Typography.Text>
          <Typography.Text>
            {formatDate(enrollment?.created_at, 'lll') ?? 'Não disponível'}
          </Typography.Text>
        </Space>

        <Space direction="horizontal">
          <Typography.Text strong>Curso:</Typography.Text>
          <Typography.Text>{course?.name}</Typography.Text>
        </Space>
        <Space direction="horizontal">
          <Typography.Text strong>Status da documentação:</Typography.Text>
          <TagField
            color={getStatusColor(status ?? 'unknown')}
            value={translateDocumentationStatus(status ?? 'unknown')}
          />
        </Space>
      </Space>
      <Alert
        message=""
        description={
          <ul
            style={{ margin: 0, paddingInlineStart: '20px', fontSize: 'small' }}
          >
            <li>O aluno deve enviar todos os documentos obrigatórios.</li>
            <li>
              Os documentos devem ser categorizados de acordo com as
              especificações de cada categoria.
            </li>
            <li>
              Os documentos devem ser aprovados pela secretaria após a
              categorização.
            </li>
            <li>
              <strong>
                Todos os documentos obrigatórios devem estar devidamente
                categorizados e aprovados.
              </strong>
            </li>
          </ul>
        }
        type="warning"
        showIcon
        closable
        style={{ marginBottom: 16 }}
      />
    </Space>
  );
};
