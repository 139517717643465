import * as Icons from '@ant-design/icons';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { Alert, Divider, Table, Tag } from 'antd';
import {
  BaseRecord,
  useApiUrl,
  useCan,
  useCustomMutation,
  useList,
  useNavigation,
  useParsed
} from '@refinedev/core';
import { Box, Button, Stack, Text } from '@qcx/ui';
import { Show, TextField, useModal, useTable } from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { formatMoney } from '../../../services/money';
import { getInstallmentsFromPlan } from '../../../utils/payment';
import { invoiceStatus } from '../../../services/invoice';
import { paymentPlanStatus } from '../../../services/payment-plan';
import { translateKind } from '../../../services/payments';
import { useState } from 'react';

const INVOICE_STATUS_MAP: Record<string, string> = {
  initial: 'blue',
  paid: 'green',
  cancelled: 'yellow',
  due: 'red'
};

export const PaymentPlanShow = () => {
  const { params } = useParsed<Record<string, string>>();
  const { show: showModal, modalProps, close: closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const { show } = useNavigation();
  const { data: permission } = useCan({
    resource: 'payment_plans',
    action: 'cancel'
  });

  const { order_id, id } = params as { id: string; order_id: string };
  const { data } = useList({
    resource: `orders/${order_id}/payment_plans`
  });

  const modalCopy = {
    okText: 'Sim, quero interromper',
    bodyTitle: 'Tem certeza que quer interromper as próximas cobranças?',
    bodyText:
      'Ao confirmar, você estará interrompendo todas as cobranças futuras desde plano de pagamento'
  };

  const paymentPlan = data?.data?.find(plan => plan.id === id);
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();

  const { tableProps } = useTable({
    resource: `orders/${order_id}/invoices`,

    filters: {
      permanent: [{ field: 'filter[plans_ids]', operator: 'eq', value: id }]
    }
  });

  const { dataSource, ...tbProps } = tableProps;

  const handlePlanCancelation = () => {
    setLoading(true);
    mutate(
      {
        url: `${apiUrl}/orders/${order_id}/payment_plans/cancel`,
        method: 'post',
        values: {
          payment_plan_ids: [id]
        },
        successNotification: () => {
          return {
            description: 'Tudo certo!',
            message: 'As cobranças foram interrompidas',
            type: 'success'
          };
        },
        errorNotification: () => {
          return {
            description: 'Algo deu errado',
            message: 'Não foi possível concluir esta ação',
            type: 'error'
          };
        }
      },
      {
        onError: error => {
          // eslint-disable-next-line no-console
          console.log(error);
        },
        onSuccess: () => {
          closeModal();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    );

    setLoading(false);
  };

  const paidInstallments = dataSource
    ?.filter(obj => obj.status === 'paid')
    .sort(byDueAt);
  const pendingInstallments = dataSource
    ?.filter(obj => obj.status !== 'paid')
    .sort(byDueAt);

  const paidSubtotal = calculateSubtotal(paidInstallments || []);
  const pendingSubtotal = calculateSubtotal(pendingInstallments || []);

  const pendingInstallmentsIndex = (idx: number) =>
    Number(paidInstallments?.length) + 1 + idx;

  return (
    <>
      <Show
        title={
          <Stack css={{ gap: '$2' }}>
            <Button
              onClick={() => show('orders', order_id)}
              icon={<Icons.ArrowLeftOutlined />}
              variant="neutralLink"
            />

            <Text variant="xl" weight="bold">
              Pagamento parcelado
            </Text>
          </Stack>
        }
        headerButtonProps={{ hidden: true }}
      >
        <PaymentInfo paymentPlan={paymentPlan} />
        <Alert
          message="Interromper ou cancelar as cobranças não impede a emissão de novas notas fiscais. Para interromper a emissão de NF's, vá até o pedido e busque essa opção"
          type="warning"
          showIcon
          style={{ marginBottom: '12px', marginTop: '12px' }}
        />
        <Divider />
        <Stack flow="column" css={{ gap: '$10' }}>
          <Box>
            <Text variant="md" weight="semibold" css={{ d: 'block', mb: '$6' }}>
              Parcelas pagas
            </Text>
            <Table
              dataSource={paidInstallments}
              {...tbProps}
              pagination={{ hideOnSinglePage: true }}
            >
              <Table.Column
                key="installment"
                title="Parcela"
                render={(_, __, idx) => <TextField value={idx + 1} />}
              />
              <Table.Column
                dataIndex="amount"
                key="amount"
                title="Valor"
                render={amount => <TextField value={formatMoney(amount)} />}
              />
              <Table.Column
                dataIndex="due_at"
                key="due_at"
                title="Data do vencimento"
                render={due_at => (
                  <TextField value={formatDate(due_at, 'll')} />
                )}
              />
              <Table.Column
                dataIndex="status"
                key="status"
                title="Status"
                render={status => (
                  <Tag color={INVOICE_STATUS_MAP[status] || 'blue'}>
                    {invoiceStatus(status)}
                  </Tag>
                )}
              />
            </Table>
            <Text weight="semibold" css={{ d: 'block', mt: '$6' }}>
              Total pago:{' '}
              <Text>
                {formatMoney({
                  cents: paidSubtotal,
                  currency_iso: 'BRL'
                })}
              </Text>
            </Text>
            <Divider />
          </Box>

          <Box>
            <Text variant="md" weight="semibold" css={{ d: 'block', mb: '$6' }}>
              Parcelas em aberto
            </Text>
            <Alert
              message="A mudança do status das parcelas pode demorar até 5 minutos após a interrupção das cobranças do financiamento"
              type="info"
              showIcon
              style={{ marginBottom: '12px' }}
            />
            <Stack css={{ gap: '$6' }}>
              <Table
                dataSource={pendingInstallments}
                {...tbProps}
                style={{ width: '100%' }}
                pagination={{ hideOnSinglePage: true }}
              >
                <Table.Column
                  key="installment"
                  title="Parcela"
                  render={(_, __, idx) => (
                    <TextField value={pendingInstallmentsIndex(idx)} />
                  )}
                />
                <Table.Column
                  dataIndex="amount"
                  key="amount"
                  title="Valor"
                  render={amount => <TextField value={formatMoney(amount)} />}
                />
                <Table.Column
                  dataIndex="due_at"
                  key="due_at"
                  title="Data do vencimento"
                  render={due_at => (
                    <TextField value={formatDate(due_at, 'll')} />
                  )}
                />
                <Table.Column
                  dataIndex="status"
                  key="status"
                  title="Status"
                  render={status => (
                    <Tag color={INVOICE_STATUS_MAP[status] || 'blue'}>
                      {invoiceStatus(status)}
                    </Tag>
                  )}
                />
              </Table>

              {permission?.can && paymentPlan?.status !== 'cancelled' && (
                <Box>
                  <Stack
                    flow="column"
                    css={{
                      rounded: '$lg',
                      bgColor: '$neutral100',
                      p: '$6',
                      gap: '$6',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box>
                      <Stack
                        css={{
                          whiteSpace: 'nowrap',
                          justifyContent: 'space-between',
                          gap: '$4'
                        }}
                      >
                        <Text
                          variant="xs"
                          css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                        >
                          À vender ou não emitidas
                        </Text>
                        <Text
                          weight="semibold"
                          variant="xs"
                          css={{ d: 'block' }}
                        >
                          {`${pendingInstallments?.length} parcela(s)`}
                        </Text>
                      </Stack>
                      <Stack
                        css={{
                          whiteSpace: 'nowrap',
                          justifyContent: 'space-between',
                          gap: '$4'
                        }}
                      >
                        <Text
                          variant="xs"
                          css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                        >
                          Valor
                        </Text>
                        <Text
                          weight="semibold"
                          variant="xs"
                          css={{ d: 'block' }}
                        >
                          {formatMoney({
                            cents: pendingSubtotal,
                            currency_iso: 'BRL'
                          })}
                        </Text>
                      </Stack>
                    </Box>

                    <Button variant="danger" onClick={() => showModal()}>
                      Interromper cobranças
                    </Button>
                  </Stack>
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      </Show>
      <ConfirmationModal
        closable={false}
        confirmLoading={loading}
        onOk={handlePlanCancelation}
        okType="danger"
        okText={modalCopy.okText}
        bodyTitle={modalCopy.bodyTitle}
        bodyText={modalCopy.bodyText}
        width={400}
        {...modalProps}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const byDueAt = (a: any, b: any) =>
  new Date(a.due_at).getTime() - new Date(b.due_at).getTime();

const calculateSubtotal = (installments: BaseRecord[]) =>
  installments.reduce((sum, item) => sum + (item.amount?.cents || 0), 0);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PaymentInfo = ({ paymentPlan }: { paymentPlan: any }) => {
  return (
    <Box css={{ d: 'grid', gridCols: '$2', gridGap: '$4' }}>
      <Box>
        <Text variant="xs" weight="semibold" css={{ d: 'block', mb: '$2' }}>
          Data da compra
        </Text>
        <Text variant="xs" css={{ d: 'block' }}>
          {formatDate(paymentPlan?.created_at)}
        </Text>
      </Box>
      <Box>
        <Text variant="xs" weight="semibold" css={{ d: 'block', mb: '$2' }}>
          Forma de pagamento
        </Text>
        <Text variant="xs" css={{ d: 'block' }}>
          {translateKind(paymentPlan?.preferences?.kind)}
        </Text>
      </Box>
      <Box>
        <Text variant="xs" weight="semibold" css={{ d: 'block', mb: '$2' }}>
          Quantidade de parcelas
        </Text>
        <Text variant="xs" css={{ d: 'block' }}>
          {getInstallmentsFromPlan(paymentPlan)}
        </Text>
      </Box>
      <Box>
        <Text variant="xs" weight="semibold" css={{ d: 'block', mb: '$2' }}>
          Valor total
        </Text>
        <Text variant="xs" css={{ d: 'block' }}>
          {paymentPlan?.amount && formatMoney(paymentPlan.amount)}
        </Text>
      </Box>
      <Box>
        <Text variant="xs" weight="semibold" css={{ d: 'block', mb: '$2' }}>
          Situação do parcelamento
        </Text>
        <Text variant="xs" css={{ d: 'block' }}>
          {paymentPlanStatus(paymentPlan?.status)}
        </Text>
      </Box>
    </Box>
  );
};
