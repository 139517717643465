import Icon, { RightOutlined } from '@ant-design/icons';
import { Card, Space, Typography } from 'antd';

interface CounterItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  title: string;
  value: number;
}

export const CounterItem = ({ icon, title, value }: CounterItemProps) => {
  return (
    <Card
      className="w-full flex items-center group rounded-md bg-white hover:bg-neutral-100 cursor-pointer transition"
      styles={{
        body: {
          width: '100%'
        }
      }}
    >
      <Space className="w-full justify-between items-center">
        <Space>
          <Icon component={icon} className="text-xl text-neutral-400" />
          <Typography.Text className="text-md font-semibold">
            {title}
          </Typography.Text>
        </Space>

        <div>
          <Typography.Text className="!text-xl font-semibold whitespace-nowrap flex group-hover:hidden transition">
            {value}
          </Typography.Text>
          <RightOutlined className="text-xl hidden group-hover:flex transition" />
        </div>
      </Space>
    </Card>
  );
};
