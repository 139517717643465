export function convertMinutesToHoursAndMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  return hours === 0
    ? `${mins} minutos`
    : mins === 0
    ? `${hours} horas`
    : `${hours} horas e ${mins} minutos`;
}
