import { IEcommerceDivision } from '../../../interfaces/ecommerce_divisions';
import { IEcommerceProfitCenter } from '../../../interfaces/ecommerce_profit_centers';
import { Show, ShowButton } from '@refinedev/antd';
import { Table, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const EcommerceDivisionShow = () => {
  const { id } = useParams();

  const { queryResult } = useShow<IEcommerceDivision>({
    resource: 'ecommerce/divisions',
    id
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Ação',
      key: 'action',
      render: (text: string, record: IEcommerceProfitCenter) => (
        <ShowButton
          hideText
          resource="ecommerce/ecommerce_profit_centers"
          size="small"
          recordItemId={record.id}
        />
      )
    }
  ];

  return (
    <Show isLoading={isLoading} title="Visualizar divisão">
      <Title level={5}>Código</Title>
      <Text>{record?.code}</Text>
      <Title level={5}>Descrição</Title>
      <Text>{record?.description}</Text>
      {record?.profit_centers && (
        <>
          <Title level={5}>Centros de Resultados</Title>
          <Table
            dataSource={record.profit_centers}
            columns={columns}
            size="small"
          />
        </>
      )}
    </Show>
  );
};
