import ClassroomList from './components/ClassroomList';
import CoursesList from './components/CoursesList';
import ProfessorInfo from './components/ProfessorInfo';
import AgentList from './components/AgentList';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Tabs } from 'antd';
import { IProfessor } from 'interfaces/professor';

export const ProfessorEdit = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IProfessor>({
    redirect: 'edit'
  });

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar professor">
      <Tabs defaultActiveKey="info">
        <Tabs.TabPane tab="Informações Gerais" key="info">
          <Form {...formProps} layout="vertical">
            <ProfessorInfo
              professorData={queryResult?.data?.data}
              type="edit"
            />
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Responsável por turmas" key="classrooms">
          <ClassroomList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Coordenação de Cursos" key="coordenation">
          <CoursesList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Usuário" key="agent">
          <Form {...formProps} layout="vertical">
            <AgentList
              professorData={queryResult?.data?.data}
              type="edit"
            />
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Edit>
  );
};
