import {
  OfferCampaignCreate,
  OfferCampaignEdit,
  OfferCampaignList
} from 'pages/ecommerce/offer_campaigns';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'offer_campaigns',
  label: 'Campanhas',
  list: OfferCampaignList,
  create: OfferCampaignCreate,
  edit: OfferCampaignEdit,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'ecommerce'
};

export default generateResources(resource);
