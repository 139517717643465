import { IForumReports } from 'interfaces/forum_reports';
import { IReportReason } from 'interfaces/reports';
import { REPORT_REASON_MAP } from 'services/report';
import { ShowButton, TagField, TextField } from '@refinedev/antd';
import { Table, TableProps } from 'antd';
import { formatDate } from 'services/date';
import { reportStatusMap } from 'services/forums';

interface ForumReportsTableProps {
  tableProps: TableProps<IForumReports>;
}

export const ForumReportsTable = ({ tableProps }: ForumReportsTableProps) => {
  return (
    <Table {...tableProps}>
      <Table.Column
        dataIndex="text"
        key="text"
        title="Justificativa"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="reason"
        key="reason"
        title="Categoria"
        render={value => (
          <TextField value={REPORT_REASON_MAP[value as IReportReason]} />
        )}
      />
      <Table.Column
        dataIndex={['reporter', 'name']}
        key="author"
        title="Autor da denúncia"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex={['moderator', 'name']}
        key="moderator"
        title="Moderador"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Data da denúncia"
        render={value => (
          <TextField value={formatDate(value, 'DD/MM/YYYY à[s] HH:mm')} />
        )}
      />
      <Table.Column
        dataIndex="updated_at"
        key="updated_at"
        title="Data da moderação"
        render={value => (
          <TextField value={formatDate(value, 'DD/MM/YYYY à[s] HH:mm')} />
        )}
      />
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <TagField
            color={reportStatusMap[value as keyof typeof reportStatusMap].color}
            value={reportStatusMap[value as keyof typeof reportStatusMap].label}
          />
        )}
      />
      <Table.Column<IForumReports>
        title="Ações"
        dataIndex="actions"
        render={(_, record) => {
          return <ShowButton hideText size="small" recordItemId={record.id} />;
        }}
      />
    </Table>
  );
};
