import FilterForm from '../../../components/forms/FilterForm';
import { Col, Form, Row, Select, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { EditButton, List, TextField, useTable } from '@refinedev/antd';
import { TOfferCampaignAsset } from 'interfaces/offers';

interface IFilter {
  name: string;
  status: string;
}

export const OfferCampaignAssetList = () => {
  const { tableProps, searchFormProps } = useTable<
    TOfferCampaignAsset,
    HttpError,
    IFilter
  >({
    resource: 'offers/campaigns/assets',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { status } = params;

      filters.push({
        field: 'filter[status]',
        operator: 'eq',
        value: status
      });

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Status" name="status">
              <Select placeholder="Selecione..." style={{ minWidth: 200 }}>
                <Select.Option key="disabled">Desabilitado</Select.Option>
                <Select.Option key="expired">Expirado</Select.Option>
                <Select.Option key="active">Ativo</Select.Option>
              </Select>
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="alt_text"
              key="alt_text"
              title="Texto (acessibilidade)"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={value => (
                <TextField
                  value={
                    value === 'disabled'
                      ? 'Desabilitado'
                      : value === 'expired'
                      ? 'Expirado'
                      : value === 'active'
                      ? 'Ativo'
                      : '-'
                  }
                />
              )}
            />
            <Table.Column
              dataIndex="desktop_url"
              key="desktop_url"
              title="Imagem desktop"
              render={value => <TextField value={value} />}
            />
            <Table.Column<TOfferCampaignAsset>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    {/*
                    <DeleteButton
                      size="small"
                      recordItemId={record.id}
                      hideText
                      />
                    */}
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
