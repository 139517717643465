import {
  AssessmentQuestionCreate,
  AssessmentQuestionEdit,
  AssessmentQuestionList
} from 'pages/evaluations/assessment_questions';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'assessment_questions',
  label: 'Questões',
  list: AssessmentQuestionList,
  edit: AssessmentQuestionEdit,
  create: AssessmentQuestionCreate,
  actions: ['list', 'edit', 'create', 'delete'],
  parentName: 'evaluations'
};

export default generateResources(resource);
