/* eslint-disable unicorn/filename-case */
export const logoutIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0007 5.33333L14.6673 8M14.6673 8L12.0007 10.6667M14.6673 8H6.00065M10.0007 2.80269C9.15082 2.29218 8.16415 2 7.11176 2C3.92078 2 1.33398 4.68629 1.33398 8C1.33398 11.3137 3.92078 14 7.11176 14C8.16415 14 9.15082 13.7078 10.0007 13.1973"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
