import { PlayCircleOutlined } from '@ant-design/icons';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'teaching_resources',
  label: 'Recursos de Ensino',
  icon: <PlayCircleOutlined />
};

export default generateResources(resource);
