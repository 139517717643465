import { EquivalencyList, EquivalencyShow } from 'pages/academic/equivalencies';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'solicitations/equivalencies',
  label: 'Aproveitamentos',
  list: EquivalencyList,
  show: EquivalencyShow,
  actions: ['list', 'show'],
  parentName: 'secretary'
};

export default generateResources(resource);
