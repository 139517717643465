import * as Icons from '@ant-design/icons';
import { DocumentStatuses } from 'interfaces/documentation/document';
import { ReactNode } from 'react';

interface DocumentStatusesMap {
  color: string;
  icon: ReactNode;
  text: string;
}

export const DOCUMENT_STATUSES_MAP: Record<
  DocumentStatuses,
  DocumentStatusesMap
> = {
  approved: {
    color: '#039855',
    icon: <Icons.CheckCircleOutlined />,
    text: 'Aprovado'
  },
  deleted: {
    color: '#D92D20',
    icon: <Icons.CloseCircleOutlined />,
    text: 'Removido'
  },
  draft: {
    color: '',
    icon: <Icons.CheckCircleOutlined />,
    text: 'Rascunho'
  },
  pending: {
    color: '#d5ca35',
    icon: <Icons.PauseCircleOutlined />,
    text: 'Pendente'
  },
  rejected: {
    color: '#D92D20',
    icon: <Icons.CloseCircleOutlined />,
    text: 'Rejeitado'
  }
};
