import { DiscountCreatePayload } from '../../../../interfaces/discount';
import {
  GeneralPaymentPlanDealsDiscountsForm,
  ManyGeneralPaymentPlanDealsDiscountsForm
} from './create';
import { RenderCurrentDiscount } from './components/RenderCurrentDiscount';
import { RenderManyDiscounts } from './components/RenderManyDiscounts';
import { ResumeCurrentDiscount } from './components/ResumeCurrentDiscount';
import { ResumeDiscountForm } from './components/ResumeDiscountForm';
import { ResumeManyDiscount } from './components/ResumeManyDiscounts';
import {
  ResumeManyDiscountForm,
  RuleTypeI
} from './components/ResumeManyDiscountForm';
import { SetDiscountRuleType } from './components/SetDiscountRuleType';
import { Stack } from '@qcx/ui';
import { useInvalidate, useTable } from '@refinedev/core';
import { useState } from 'react';

export interface DiscountCampaign {
  id: string;
  name: string;
  kind: string;
  status: string;
  discount_config: {
    behavior: string;
    type: string;
    value: string[] | string;
    start_at?: string;
    end_at?: string;
  };
  rules: [
    {
      operator: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any;
      resource_type: string;
      resource_key: string;
      required: string;
    }
  ];
}

export const GeneralPaymentPlanDealsDiscounts = () => {
  const [createData, setCreateData] = useState<DiscountCreatePayload>(
    {} as DiscountCreatePayload
  );
  const [resourceType, setResType] = useState<'product_id' | 'invoice_due'>(
    'product_id'
  );
  const invalidate = useInvalidate();

  const updateCreateData = ({
    products,
    discount_type,
    discount_value,
    invoice_due
  }: {
    products: { id: string; name: string }[];
    discount_type: string;
    discount_value: number;
    invoice_due?: number;
  }) => {
    setCreateData({
      ...createData,
      products,
      discount_type,
      discount_value,
      invoice_due
    });
  };

  const { tableQueryResult: discountResults, setFilters: setDiscountFIlters } =
    useTable<DiscountCampaign>({
      resource: 'discount_campaigns',

      pagination: {
        pageSize: 100
      },

      permanentFilter: [
        {
          field: 'filter[rules][resource_type]',
          operator: 'eq',
          value: 'product_id'
        },
        {
          field: 'filter[rules][operator]',
          operator: 'eq',
          value: '!='
        },
        {
          field: 'filter[kind]',
          operator: 'eq',
          value: 'payment_plan_deal'
        },
        {
          field: 'filter[status]',
          operator: 'eq',
          value: 'active'
        }
      ]
    });

  const originalDiscounts = discountResults?.data?.data ?? [];

  const filterInvoiceDueRules = (value?: string[] | string) => {
    if (Array.isArray(value)) {
      return value.filter(v => v.toString() !== '1')?.length > 0;
    }

    return value?.toString() !== '1';
  };

  const discounts = originalDiscounts.filter(d => {
    return (
      d.rules?.filter(r =>
        resourceType === 'invoice_due'
          ? r?.resource_type === 'invoice_due' &&
            filterInvoiceDueRules(r?.value)
          : r.resource_type === 'invoice_due' &&
            !filterInvoiceDueRules(r?.value)
      )?.length > 0
    );
  });

  const haveActiveDiscounts =
    discounts.length > 0 && !discountResults?.isLoading;

  const setResourceType = (value: string) => {
    setResType(value);
    setDiscountFIlters([
      {
        field: 'filter[rules][resource_type]',
        operator: 'eq',
        value: value
      }
    ]);

    invalidate({
      resource: 'discount_campaigns',
      invalidates: ['resourceAll']
    });
  };

  return (
    <Stack
      css={{
        d: 'flex',
        justifyContent: 'space-between',
        gap: '$10',
        mt: '$4',
        flexDir: haveActiveDiscounts ? 'column' : 'row'
      }}
    >
      {haveActiveDiscounts ? (
        <>
          {discounts.map(discount => {
            return (
              <Stack
                key={discount.id}
                css={{
                  d: 'flex',
                  justifyContent: 'space-between',
                  gap: '$10'
                }}
              >
                <Stack css={{ w: '100%', flexDir: 'column', gap: '$4' }}>
                  <Stack css={{ w: '$full', flexDir: 'column' }}>
                    <p className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
                      A Regra Geral é aplicada à todos os produtos, a menos que
                      ele seja excluído individualmente de cada tipo de
                      desconto.
                    </p>
                    <SetDiscountRuleType
                      onChange={setResourceType}
                      defaultValue={resourceType}
                    />
                  </Stack>
                  <RenderCurrentDiscount
                    discount={discount}
                    resource_type={resourceType}
                  />
                </Stack>
                <ResumeCurrentDiscount discount={discount} />
              </Stack>
            );
          })}
        </>
      ) : (
        !discountResults?.isLoading && (
          <>
            <Stack css={{ w: '100%', flexDir: 'column', gap: '$4' }}>
              <Stack css={{ w: '$full', flexDir: 'column' }}>
                <p className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
                  A Regra Geral é aplicada à todos os produtos, a menos que ele
                  seja excluído individualmente de cada tipo de desconto.
                </p>
                <SetDiscountRuleType onChange={setResourceType} />
              </Stack>
              <GeneralPaymentPlanDealsDiscountsForm
                onChange={updateCreateData}
                type={resourceType}
              />
            </Stack>
            <ResumeDiscountForm operator="!=" {...createData} />
          </>
        )
      )}
    </Stack>
  );
};

export const GeneralManyPaymentPlanDealsDiscounts = () => {
  const [productCreateData, setproductCreateData] =
    useState<DiscountCreatePayload>({});
  const [invoiceDueCreateData, setInvoiceDueCreateData] =
    useState<DiscountCreatePayload>({});

  const [resourceType, setResType] = useState<'product_id' | 'invoice_due'>(
    'product_id'
  );
  const invalidate = useInvalidate();

  const updateCreateData = ({
    productRule,
    invoiceRule
  }: {
    productRule: RuleTypeI;
    invoiceRule: RuleTypeI;
  }) => {
    setInvoiceDueCreateData({
      ...invoiceRule
    });

    setproductCreateData({
      ...productRule
    });
  };

  const { tableQueryResult: discountResults, setFilters: setDiscountFIlters } =
    useTable<DiscountCampaign>({
      resource: 'discount_campaigns',

      pagination: {
        pageSize: 100
      },

      permanentFilter: [
        {
          field: 'filter[rules][resource_type]',
          operator: 'eq',
          value: 'product_id'
        },
        {
          field: 'filter[rules][operator]',
          operator: 'eq',
          value: '!='
        },
        {
          field: 'filter[kind]',
          operator: 'eq',
          value: 'payment_plan_deal'
        },
        {
          field: 'filter[status]',
          operator: 'eq',
          value: 'active'
        }
      ]
    });

  const originalDiscounts = discountResults?.data?.data ?? [];

  const filterInvoiceDueRules = (value?: string[] | string) => {
    if (Array.isArray(value)) {
      return value.filter(v => v.toString() !== '1')?.length > 0;
    }

    return value?.toString() !== '1';
  };

  const discounts = originalDiscounts.filter(d => {
    return (
      d.rules?.filter(
        r =>
          (r?.resource_type === 'invoice_due' &&
            filterInvoiceDueRules(r?.value)) ||
          (r.resource_type === 'invoice_due' &&
            !filterInvoiceDueRules(r?.value))
      )?.length > 0
    );
  });

  const haveActiveDiscounts =
    discounts.length > 0 && !discountResults?.isLoading;

  const setResourceType = (value: string) => {
    setResType(value);
    setDiscountFIlters([
      {
        field: 'filter[rules][resource_type]',
        operator: 'eq',
        value: value
      }
    ]);

    invalidate({
      resource: 'discount_campaigns',
      invalidates: ['resourceAll']
    });
  };

  return (
    <Stack
      css={{
        d: 'flex',
        justifyContent: 'space-between',
        gap: '$10',
        mt: '$4',
        flexDir: haveActiveDiscounts ? 'column' : 'row'
      }}
    >
      {haveActiveDiscounts ? (
        <>
          <Stack
            css={{
              d: 'flex',
              justifyContent: 'space-between',
              gap: '$10'
            }}
          >
            <Stack css={{ w: '100%', flexDir: 'column', gap: '$4' }}>
              <Stack css={{ w: '$full', flexDir: 'column' }}>
                <p className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
                  A Regra Geral é aplicada à todos os produtos, a menos que ele
                  seja excluído individualmente de cada tipo de desconto.
                </p>
                <SetDiscountRuleType
                  onChange={setResourceType}
                  defaultValue={resourceType}
                />
              </Stack>
              <RenderManyDiscounts
                discounts={discounts}
                resource_type={resourceType}
              />
            </Stack>
            <ResumeManyDiscount discounts={discounts} />
          </Stack>
        </>
      ) : (
        !discountResults?.isLoading && (
          <>
            <Stack css={{ w: '100%', flexDir: 'column', gap: '$4' }}>
              <Stack css={{ w: '$full', flexDir: 'column', gap: '$4' }}>
                <p className="font-normal text-sm leading-[22px] text-[rgba(0,0,0,0.45)]">
                  A Regra Geral é aplicada à todos os produtos, a menos que ele
                  seja excluído individualmente de cada tipo de desconto.
                </p>
                <SetDiscountRuleType onChange={setResourceType} />
                <ManyGeneralPaymentPlanDealsDiscountsForm
                  type={resourceType}
                  onChange={updateCreateData}
                />
              </Stack>
            </Stack>
            <ResumeManyDiscountForm
              operator="!="
              productRule={productCreateData}
              timeRule={invoiceDueCreateData}
              rulesToCancel={[]}
              onCreate={() => window.location.reload()}
            />
          </>
        )
      )}
    </Stack>
  );
};
