import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  Row,
  Select,
  Table
} from 'antd';
import {
  DeleteButton,
  TextField,
  useForm,
  useSelect,
  useTable
} from '@refinedev/antd';
import { IClassroomEvaluationEnrollment } from 'interfaces/classroom_evaluation_enrrolment';
import { useCreate } from '@refinedev/core';

export interface ReplacementAssociateStudentModalProps {
  modalProps: ModalProps;
  modalClose: () => void;
  resource: string;
  parentId: string;
}

const ReplacementAssociateStudentModal = ({
  modalProps,
  resource,
  parentId
}: ReplacementAssociateStudentModalProps) => {
  const enrollmentResource = `classrooms/evaluations/${parentId}/enrollments`;
  const { mutateAsync } = useCreate();
  const { form, formProps } = useForm();
  const { tableProps, tableQueryResult } =
    useTable<IClassroomEvaluationEnrollment>({
      resource: enrollmentResource
    });
  const { selectProps } = useSelect({
    resource,
    optionLabel: 'course_enrollment.user.email',
    optionValue: 'id',
    fetchSize: 50,
    onSearch: value => [
      {
        field: 'filter[by_user_email]',
        operator: 'eq',
        value: value
      }
    ]
  });

  const getOptions = () => {
    const data = tableQueryResult.data?.data;

    if (!data) {
      return [];
    }

    const associatedEnrollments = data.map(obj => obj.classroom_enrollment.id);
    const filteredOptions = selectProps.options?.filter(
      obj => !associatedEnrollments?.includes(obj.value as string)
    );

    return filteredOptions || [];
  };

  const handleCreateAssociation = async () => {
    return form.validateFields().then(async () => {
      await mutateAsync({
        resource: enrollmentResource,
        values: {
          ...form.getFieldsValue()
        },
        successNotification: () => ({
          type: 'success',
          description: 'Sucesso!',
          message: 'Aluno associado com sucesso!'
        }),
        errorNotification: () => ({
          type: 'error',
          description: 'Ops!',
          message: 'Não foi possível associar o aluno. Tente novamente.'
        })
      }).finally(() => {
        form.resetFields();
      });
    });
  };

  return (
    <Modal
      {...modalProps}
      title="Associação de alunos"
      className="!w-[1022px]"
      destroyOnClose
      footer={<></>}
    >
      <Form form={form} {...formProps} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col md={8}>
            <Form.Item
              label="Aluno"
              name="classroom_enrollment_id"
              rules={[
                {
                  required: true,
                  message: 'Aluno é obrigatório'
                }
              ]}
            >
              <Select {...selectProps} options={getOptions()} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Justificativa"
              name="justification"
              rules={[
                {
                  required: true,
                  message: 'Justificativa é obrigatória'
                }
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col md={8} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button className="mb-6" onClick={() => handleCreateAssociation()}>
              Adicionar aluno
            </Button>
          </Col>
        </Row>
      </Form>
      <Table {...tableProps}>
        <Table.Column
          dataIndex={[
            'classroom_enrollment',
            'course_enrollment',
            'user',
            'name'
          ]}
          key="user"
          title="Aluno"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="justification"
          key="justification"
          title="Justificativa"
          render={value => <TextField value={value} />}
        />
        <Table.Column<IClassroomEvaluationEnrollment>
          dataIndex="evaluation-actions"
          key="id"
          title="Ações"
          render={(_, record) => {
            return (
              <Row className="flex gap-2">
                <DeleteButton
                  danger={false}
                  hideText
                  size="small"
                  resourceNameOrRouteName={enrollmentResource}
                  recordItemId={record.id}
                  errorNotification={() => ({
                    type: 'error',
                    description: 'Ops!',
                    message:
                      'Não foi possível desassociar o aluno. Tente novamente.'
                  })}
                  successNotification={() => ({
                    type: 'success',
                    description: 'Sucesso!',
                    message: 'Aluno desassociado com sucesso!'
                  })}
                />
              </Row>
            );
          }}
        />
      </Table>
    </Modal>
  );
};

export default ReplacementAssociateStudentModal;
