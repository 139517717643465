import React, { useCallback, useState } from 'react';
import { Alert, Button, Form, Input, InputNumber, Modal } from 'antd';
import { IAssessmentAttempt } from 'interfaces/assessments';
import { ResponseError } from 'interfaces/api-error';
import { useModalReturnType, useUpdate } from '@refinedev/core';

interface Props {
  attempt: IAssessmentAttempt;
  modal: useModalReturnType;
}

export const AssessmentEditScoreModal: React.FC<Props> = ({
  attempt,
  modal
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { mutateAsync: assessmentMutateAsync } =
    useUpdate<IAssessmentAttempt>();

  const onMutateScore = useCallback(async () => {
    await assessmentMutateAsync({
      resource: 'assessments/attempts',
      id: attempt.id,
      values: {
        score: Number(form.getFieldValue('score')),
        justification: form.getFieldValue('justification')
      },
      errorNotification: error => {
        const err = error as unknown as ResponseError;

        if ('code' in err && err.code === 'classrooms_not_available_error') {
          return {
            type: 'error',
            description: 'Turma não ativa',
            message:
              'Não é possível editar a nota pois a turma vinculada a essa avaliação não está mais ativa!'
          };
        }

        return {
          type: 'error',
          description: 'Ops! Algo de errado',
          message: 'Não foi possível editar a nota'
        };
      },
      successNotification: () => ({
        type: 'success',
        description: 'Sucesso!',
        message: 'Nota alterada com sucesso'
      })
    });

    modal.close();
    form.resetFields();
  }, [assessmentMutateAsync, attempt.id, form, modal]);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await form.validateFields();
      await onMutateScore();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [form, onMutateScore]);

  return (
    <Modal
      title="Editar nota da avaliação"
      visible={modal.visible}
      onCancel={modal.close}
      footer={[
        <Button key="back" onClick={() => modal.close()} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        {attempt.assessment.parent_type === 'Graduate::Discipline' && (
          <Alert
            message="Só é permitido a edição de nota de uma avaliação que pertença a uma
          turma ativa."
            className="block !mb-4"
          />
        )}

        <Form.Item
          name="score"
          label="Nota"
          initialValue={attempt.score}
          rules={[
            {
              required: true,
              message: 'Campo obrigatório'
            },
            {
              validator(_, value, callback) {
                if (value === attempt.score) {
                  callback('A nota deve ser diferente da atual');
                }

                callback();
              }
            }
          ]}
        >
          <InputNumber max={100} min={0} />
        </Form.Item>

        <Form.Item
          name="justification"
          label="Justificativa"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório'
            },
            {
              min: 1,
              message: 'Campo ob'
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
