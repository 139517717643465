import * as Icons from '@ant-design/icons';
import { Button, Space, Tag, Tree } from 'antd';
import { ITreeTag } from 'interfaces/epimetheus/tags';
import { ReactNode } from 'react';
import { useNavigation } from '@refinedev/core';
import type { DataNode } from 'antd/es/tree';

interface Props {
  tags: ITreeTag[];
  libraryId: string;
}

const tagToNode = (tag: ITreeTag): DataNode => ({
  key: tag.id,
  title: tag.name,
  icon: <Icons.TagsOutlined />,
  children: tag.tags?.map((child: ITreeTag) => tagToNode(child))
});

const TagTree = ({ tags, libraryId }: Props) => {
  const treeData = tags.map((tag: ITreeTag) => tagToNode(tag));
  const { push } = useNavigation();

  const titleRender = (node: DataNode) => {
    const onClick = () => {
      push(`/teaching_resources/libraries/${libraryId}/tags/edit/${node.key}`);
    };

    return (
      <Space size={8}>
        <Tag color="blue" style={{ margin: 0, border: 0 }}>
          {node.title as ReactNode}
        </Tag>
        |
        <Space size={4} style={{ padding: '4px' }}>
          <Button
            size="small"
            icon={<Icons.FullscreenOutlined />}
            onClick={onClick}
          />
        </Space>
      </Space>
    );
  };

  return (
    <Tree
      treeData={treeData}
      titleRender={titleRender}
      defaultExpandAll={true}
      showIcon
      selectable={false}
      switcherIcon={<Icons.DownOutlined />}
    />
  );
};

export default TagTree;
