import FilterForm from 'components/forms/FilterForm';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Col,
  Form,
  FormProps,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space
} from 'antd';
import { ICourse } from 'interfaces/courses';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { useSelect } from '@refinedev/antd';

export interface IDiscussionThreadsFilter {
  by_grandparent_type: 'Course' | 'Graduate::Discipline';
  by_grandparent_id: string;
  by_status: string;
  by_user_name: string;
  by_user_email: string;
}

interface FilterFormProps {
  searchFormProps: FormProps<IDiscussionThreadsFilter>;
}

export const DiscussionThreadsFilter = ({
  searchFormProps
}: FilterFormProps) => {
  const [selectedType, setSelectedType] = useState('Course');

  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const onChangeType = useCallback((e: RadioChangeEvent) => {
    const value = e.target.value;
    setSelectedType(value);
  }, []);

  const selectProps = useMemo(() => {
    return selectedType === 'Course'
      ? courseSelectProps
      : disciplineSelectProps;
  }, [courseSelectProps, disciplineSelectProps, selectedType]);

  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Space direction="vertical" className="w-full">
        <Row className="w-full">
          <Col span={24}>
            <Form.Item
              label="Tipo de curso"
              name="by_grandparent_type"
              className="!mb-0"
              initialValue="Course"
            >
              <Radio.Group
                onChange={onChangeType}
                value={selectedType}
                defaultValue="Course"
              >
                <Radio value="Course">Curso</Radio>
                <Radio value="Graduate::Discipline">Disciplina</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 0]} className="w-full">
          <Col xs={24} md={12}>
            <Form.Item label="Nome" name="by_grandparent_id">
              <Select placeholder="Nome" {...selectProps} allowClear />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item label="Status" name="by_status">
              <Select placeholder="Selecione o status" defaultValue={null}>
                <Select.Option>Todos</Select.Option>
                <Select.Option key="pending">Não resolvida</Select.Option>
                <Select.Option key="answered">Respondida</Select.Option>
                <Select.Option key="closed">Encerrada</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 0]} className="w-full">
          <Col xs={24} md={12}>
            <Form.Item label="Nome do Aluno" name="by_user_name">
              <Input placeholder="Nome do aluno" />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item label="E-mail" name="by_user_email">
              <Input placeholder="e-mail do aluno" />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </FilterForm>
  );
};
