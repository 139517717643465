import React from 'react';
import { IAcademicRecordEquivalency } from '../../../../../interfaces/academic_record_equivalencies';
import { Show, TagField } from '@refinedev/antd';
import { Space, Tag, Typography } from 'antd';
import { formatDate } from '../../../../../services/date';
import { tagColorByStatus } from '../../../../../utils/statusColorMapping';
import { translateEquivalencyStatus } from '../../index';

interface AcademicRecordEquivalencyDetailsProps {
  record: IAcademicRecordEquivalency;
}

export const AcademicRecordEquivalencyDetails: React.FC<
  AcademicRecordEquivalencyDetailsProps
> = ({ record }) => {
  return (
    <Space direction="vertical" size={16} className="pl-2">
      <Space direction="horizontal" size="middle">
        <Space>
          <Typography.Text strong>Curso de Destino:</Typography.Text>
          <Typography.Text>
            {record.course_enrollment?.course?.name}
          </Typography.Text>
        </Space>
      </Space>
      <Space direction="horizontal" size="middle">
        <Space>
          <Typography.Text strong>Disciplina de Destino:</Typography.Text>
          <Typography.Text> {record.discipline?.name} </Typography.Text>
        </Space>
      </Space>

      <Space direction="horizontal" size="middle">
        <Space>
          <Typography.Text strong>
            Situação da Disciplina no Histórico:
          </Typography.Text>
          <TagField
            color={tagColorByStatus(String(record.status))}
            value={translateEquivalencyStatus(record.status || '')}
          />
        </Space>

        {record.status === 'approved' && (
          <Space>
            <Typography.Text strong>Nota para Histórico:</Typography.Text>
            <Typography.Text> {record.score} </Typography.Text>
          </Space>
        )}
      </Space>
      <Space direction="horizontal" size="middle">
        {record.text && (
          <Space>
            <Typography.Text strong>Observações:</Typography.Text>
            <Typography.Text> {record.text} </Typography.Text>
          </Space>
        )}
        <Space>
          <Typography.Text strong>Responsável:</Typography.Text>
          <Typography.Text>{record.agent.name}</Typography.Text>
          <span style={{ fontSize: 'smaller', color: 'gray' }}>
            {formatDate(String(record.updated_at))}
          </span>
        </Space>
      </Space>
    </Space>
  );
};
