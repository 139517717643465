import { RightOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';

export const ForumsItem = () => {
  return (
    <Space
      size="small"
      className="group w-full p-4 hover:cursor-pointer hover:bg-neutral-100 hover:rounded-lg justify-between transition"
    >
      <Space direction="vertical" className="!gap-0">
        <Typography.Text className="!text-md">
          Como a reforma trabalhista alterou os direitos relacionados às férias
          e ao banco de horas?
        </Typography.Text>
        <Typography.Text className="!text-neutral-500">
          Impactos da Reforma Trabalhista
        </Typography.Text>
        <Typography.Text className="!text-neutral-500">
          10/03/2024 . Alberto Zanini
        </Typography.Text>
      </Space>

      <RightOutlined className="opacity-0 group-hover:opacity-100 transition" />
    </Space>
  );
};
