import FilterForm from '../../../components/forms/FilterForm';
import {
  DeleteButton,
  EditButton,
  List,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { Form, Input, Space, Table } from 'antd';
import { HttpError, useNavigation } from '@refinedev/core';
import { IProductCategory } from 'interfaces/product_categories';

export const ProductCategoryList = () => {
  const { tableProps, searchFormProps } = useTable<
    IProductCategory,
    HttpError,
    { byName: string }
  >({
    onSearch: ({ byName }) => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value: byName
      }
    ]
  });
  const { edit } = useNavigation();

  return (
    <List>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Nome" name="byName">
          <Input placeholder="Nome da categoria..." />
        </Form.Item>
      </FilterForm>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="short_name"
          key="short_name"
          title="Nome Curto"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="slug"
          key="slug"
          title="Slug"
          render={value => <TagField value={value} />}
        />
        <Table.Column
          dataIndex="products_categories"
          key="slug"
          title="Subcategorias"
          render={items =>
            items.map((item: IProductCategory, idx: number) => (
              <TagField
                style={{ border: 'none', margin: 4, cursor: 'pointer' }}
                color="cyan"
                key={`category-${idx}`}
                value={item.name}
                // TODO Implement a typesafe route function
                onClick={() => edit('ecommerce/products/categories', item.id)}
              />
            ))
          }
        />

        <Table.Column<IProductCategory>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButton
                  size="small"
                  recordItemId={record.id}
                  hideText
                  confirmTitle="Deseja excluir a categoria e suas associações?"
                  confirmOkText="Excluir"
                  confirmCancelText="Cancelar"
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
