import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from 'antd';
import { TabContent } from './TabContent';
import { TabGeneral } from './TabGeneral';
import { TabInteractiveClasses } from './TabInteractiveClasses/TabInteractiveClasses';
import { tabs } from './tabs';
import { useCustomSearchParams } from 'hooks/useSearchParams';
import type { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';

export const TabList = ({ record }: { record: IProfessorAreaClassroom }) => {
  const { currentSearchParams: currentTab, setSearchParams } =
    useCustomSearchParams({
      searchParams: 'tab'
    });

  const [activeTabKey, setActiveTabKey] = useState<string>(
    currentTab || 'general'
  );

  const tabContent: Record<string, React.ReactNode> | null = useMemo(() => {
    if (!record) {
      return null;
    }

    return {
      general: <TabGeneral data={record} />,
      content: <TabContent />,
      interactiveClass: <TabInteractiveClasses />
    };
  }, [record]);

  useEffect(() => {
    if (currentTab) {
      setActiveTabKey(currentTab);
    }
  }, [currentTab]);

  const onTabChange = useCallback(
    (tab: string) => {
      setActiveTabKey(tab);
      setSearchParams(tab);
    },
    [setSearchParams]
  );

  return (
    <Card
      tabList={tabs}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      prefixCls="custom-card"
    >
      {tabContent![activeTabKey]}
    </Card>
  );
};
