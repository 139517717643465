import { IDocument } from 'interfaces/documentation/document';
import { IDocumentTemplate } from 'interfaces/documentation/document-template';
import { createStore } from '@qcx/store';

type Modal = 'setKind' | 'approve' | 'reject' | 'show' | null;

export interface useDocumentationStore {
  modal: Modal;
  editDocument?: IDocument;
  showTemplate?: IDocumentTemplate;
  queryHandler: () => Promise<void>;
}

const INITIAL_VALUE: useDocumentationStore = {
  modal: null,
  queryHandler: () => Promise.resolve()
};

export const { Provider, useStore } = createStore(INITIAL_VALUE, ({ set }) => {
  return {
    openModal: (modal: Modal, document?: IDocument) => {
      set({ editDocument: document, modal });
    },

    closeModal: () => {
      set({ modal: null });
    },

    setQueryHandler: (queryHandler: () => Promise<void>) => {
      set({ queryHandler });
    }
  };
});

export const DocumentationProvider = Provider;

export const useDocumentationStore = useStore;
