import { EditButton, ShowButton, TextField } from '@refinedev/antd';
import { ICompany } from 'interfaces/companies';
import { Space, Table, TableProps } from 'antd';
import { formatDate } from 'services/date';

export interface CompaniesTableProps {
  tableProps: TableProps<ICompany>;
}

export const CompaniesTable = ({ tableProps }: CompaniesTableProps) => {
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="name"
        key="name"
        title="Empresa"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="cnpj"
        key="cnpj"
        title="CNPJ"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="responsible_email"
        key="responsible_email"
        title="E-mail"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="starts_at"
        key="starts_at"
        title="Data de cadastro"
        render={value => <TextField value={formatDate(value, 'DD/MM/YYYY')} />}
      />
      <Table.Column<ICompany>
        title="Ações"
        dataIndex="actions"
        align="center"
        render={(_, record) => {
          return (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          );
        }}
      />
    </Table>
  );
};
