import { HttpError } from '@refinedev/core';
import { IAcademicRecord } from 'interfaces/academic_record';
import { Modal, ModalProps, Table } from 'antd';
import { QUESTION_STATE_MAP } from 'services/assessments';
import { TagField, TextField, useTable } from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { translateAcademicRecordEventStatus } from 'services/academic-records';

interface QuestionLogModalProps {
  questionId: string;
  modalProps: ModalProps;
}

export const QuestionLogModal = ({
  questionId,
  modalProps
}: QuestionLogModalProps) => {
  const { tableProps } = useTable<IAcademicRecord, HttpError>({
    resource: `assessments/questions/${questionId}/logs`
  });

  return (
    <Modal
      {...modalProps}
      title="Histórico de Alterações"
      width={1200}
      footer={null}
      destroyOnClose
    >
      <Table
        size="small"
        {...tableProps}
        rowKey={'id'}
        columns={[
          {
            title: 'Alterado em',
            dataIndex: 'created_at',
            key: 'created_at',
            render: createdAt => (
              <TextField value={formatDate(createdAt, 'lll')} />
            )
          },
          {
            title: 'Alterado por',
            dataIndex: 'author',
            key: 'author',
            render: author => <span>{author.name}</span>
          },
          {
            title: 'Evento',
            dataIndex: 'event',
            key: 'event',
            render: (event: string) => (
              <TextField value={translateAcademicRecordEventStatus(event)} />
            )
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
              <>
                {status ? (
                  <div className="flex flex-col gap-1">
                    <span>
                      De:{' '}
                      {status[0] ? (
                        <TagField
                          color={QUESTION_STATE_MAP[status[0]].color}
                          value={QUESTION_STATE_MAP[status[0]].label}
                        />
                      ) : (
                        '-'
                      )}
                    </span>
                    <span>
                      Para:{' '}
                      <TagField
                        color={QUESTION_STATE_MAP[status[1]].color || ''}
                        value={QUESTION_STATE_MAP[status[1]].label}
                      />
                    </span>
                  </div>
                ) : (
                  '-'
                )}
              </>
            )
          },
          {
            title: 'Justificativa',
            dataIndex: 'nullified_feedback',
            key: 'nullified_feedback',
            render: justification => (
              <>
                {justification ? (
                  <div className="flex">
                    <span>
                      <TextField
                        className="!text-gray-500 font-light"
                        value={justification[1] || '-'}
                      />
                    </span>
                  </div>
                ) : (
                  '-'
                )}
              </>
            )
          }
        ]}
      />
    </Modal>
  );
};
