import 'react-quill/dist/quill.snow.css';
import * as Icons from '@ant-design/icons';
import AssetUpload from 'components/AssetUpload';
import ImageSpecifications from 'components/ImageSpecifications';
import ReactQuill from 'react-quill';
import SlugInput from '../../../components/form-items/SlugInput';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Tabs,
  Typography
} from 'antd';
import { Edit, useForm } from '@refinedev/antd';
import { IInstructor } from 'interfaces/instructors';
import { applyImageSizeRestriction } from 'utils/apply-image-size-restriction';
import { shouldApplyImageRestrictions } from 'utils/should-apply-image-restrictions';
import { useState } from 'react';

export const InstructorEdit = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IInstructor>({
    redirect: 'edit'
  });
  const [shouldEditSlug, setShouldEditSlug] = useState<boolean>(false);

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar instrutor">
      <Form {...formProps} layout="vertical">
        <Tabs defaultActiveKey="info">
          <Tabs.TabPane tab="Informação" key="info">
            <ImageSpecifications
              title="Especificações Avatar"
              size="200kb"
              format="jpeg/jpg/png"
            />
            <Form.Item
              label={!shouldApplyImageRestrictions() ? 'Avatar' : ''}
              name="avatar"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="instructor"
                src={queryResult?.data?.data.avatar}
                maxSize={applyImageSizeRestriction('avatar')}
              />
            </Form.Item>
            <Checkbox
              onChange={value => {
                setShouldEditSlug(value.target.checked);
              }}
            >
              Eu assumo a RESPONSABILIDADE de alterar a SLUG e isso poderá
              quebrar o SEO e a experiência do usuário
            </Checkbox>
            <SlugInput disabled={!shouldEditSlug} />
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Cabeçalho"
              name="headline"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Biografia"
              name="bio"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label="Top Score"
              name="top_score"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Highlight Score"
              name="highlight_score"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Conteúdo" key="content">
            <Form.Item name={['info', 'about']} label="Sobre">
              <ReactQuill theme="snow" />
            </Form.Item>
            <Typography.Title level={5}>Redes sociais</Typography.Title>
            <Form.List name={['info', 'socials']}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (
                    <div
                      key={field.key}
                      className="flex gap-4 w-full items-center"
                    >
                      <Form.Item
                        label="Rede social"
                        name={[field.name, 'key']}
                        rules={[{ required: true }]}
                      >
                        <Select className="mb-4 w-full">
                          <Select.Option key="facebook">Facebook</Select.Option>
                          <Select.Option key="twitter">Twitter</Select.Option>
                          <Select.Option key="youtube">Youtube</Select.Option>
                          <Select.Option key="instagram">
                            Instagram
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="URL"
                        name={[field.name, 'url']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Icons.MinusCircleOutlined
                        className="mb-4"
                        onClick={() => remove(field.name)}
                      />
                    </div>
                  ))}
                  <Button
                    type="dashed"
                    className="!flex items-center justify-center"
                    onClick={() => add()}
                    block
                  >
                    <Icons.PlusOutlined />
                    Adicionar rede social
                  </Button>
                </>
              )}
            </Form.List>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Assets" key="assets">
            <Form.Item
              label="Assinatura"
              name={['media', 'signature_for_certificate_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="instructor"
                src={
                  queryResult?.data?.data?.media
                    ?.signature_for_certificate_image
                }
              />
            </Form.Item>
            <Form.Item
              label="Imagem para certificado"
              name={['media', 'picture_for_certificate_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="instructor"
                src={
                  queryResult?.data?.data?.media?.picture_for_certificate_image
                }
              />
            </Form.Item>
            <Form.Item
              label="Imagem para Bio"
              name={['media', 'biography_section_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="instructor"
                src={queryResult?.data?.data?.media?.biography_section_image}
              />
            </Form.Item>
            <Form.Item
              label="Capa"
              name={['media', 'cover_section_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="instructor"
                src={queryResult?.data?.data?.media?.cover_section_image}
              />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Edit>
  );
};
