/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import { Typography, Row, Col } from 'antd';
import { shouldApplyImageRestrictions } from 'utils/should-apply-image-restrictions';

const { Text, Link } = Typography;

type ImageSpecificationsProps = {
  title: string;
  aspectRatio?: string;
  size: string;
  format?: string;
};

const ImageSpecifications = ({
  title,
  aspectRatio,
  size,
  format
}: ImageSpecificationsProps) => {
  return !shouldApplyImageRestrictions() ? null : (
    <div>
      <Text strong>{title}</Text>
      <Row gutter={32} style={{ marginTop: 12, marginBottom: 8 }}>
        {aspectRatio && (
          <Col>
            <Text type="secondary">Proporção: {aspectRatio}</Text>
          </Col>
        )}
        <Col>
          <Text type="secondary">Size: {size}</Text>
        </Col>
        <Col>
          <Text type="secondary">Formato: {format}</Text>
        </Col>
        {format?.includes('jpg') || format?.includes('jpeg') ? (
          <Col>
            <Text type="secondary">
              Otimizar imagem jpg:{' '}
              <Link href="https://squoosh.app/" target="_blank">
                Acesse
              </Link>
            </Text>
          </Col>
        ) : null}

        {format?.includes('png') ? (
          <Col>
            <Text type="secondary">
              Otimizar imagem png:{' '}
              <Link
                href="https://www.iloveimg.com/pt/comprimir-imagem/comprimir-png"
                target="_blank"
              >
                Acesse
              </Link>
            </Text>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default ImageSpecifications;
