import { CreatePendingCertificate } from 'pages/certificates_management/certificates_regulated_courses/pending-certificates';
import { PendingCertificateShow } from 'pages/certificates_management/certificates_regulated_courses/pending-certificates/show';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'graduate/courses/pending_certificates',
  label: 'Certificados cursos regulares',
  show: PendingCertificateShow,
  create: CreatePendingCertificate,
  actions: ['show', 'create'],
  parentName: 'certificate_management'
};

export default generateResources(resource);
