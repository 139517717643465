import { ShowButton } from "@refinedev/antd";
import { Col, Row, Typography } from "antd";
import { IProfessor } from "../../../../interfaces/professor";
const { Text, Title } = Typography;

export interface AgentInfoProps {
  professorData: IProfessor;
  type?: 'show' | 'edit';
}

const AgentInfo = ({ professorData, type }: AgentInfoProps) => {
  return (<>
    {professorData?.agent ? (
      <Row className="border rounded p-2">
        <Title level={5} style={{ margin: '0 0 8px 0' }}>
          Usuário Associado
        </Title>
        { type == 'edit' && (
          <ShowButton
            size="small"
            resource="agents"
            recordItemId={professorData?.agent?.id}
            className="ml-4"
          >
            Visualizar
          </ShowButton>
        )}
        <Row gutter={[16, 16]} className="w-full mb-1">
          <Col>
            <Row>
              <Text style={{ fontWeight: 'bold' }}>Nome</Text>
            </Row>
            <Row className="ml-1">
              <Text>{professorData?.agent?.name ?? 'Não Informado'}</Text>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col>
            <Row>
              <Text style={{ fontWeight: 'bold' }}>Infomações de login</Text>
            </Row>
            <Row className="ml-1">
              <Text>{professorData?.agent?.login}</Text>
            </Row>
          </Col>
        </Row>
      </Row>
    ) : (
      <Row gutter={[16, 16]} className="w-full">
        <Col>
          <Row>
            <Text style={{ fontWeight: 'bold' }}>Usuário Associado</Text>
          </Row>
          <Row>
            <Text>Nenhum usuário do sistema associado com o/a professor(a) {professorData.name}.</Text>
          </Row>
        </Col>
      </Row>
    )}
  </>);
};

export default AgentInfo;
