import * as Icons from '@ant-design/icons';
import { BulkBonusCreate } from 'pages/ecommerce/bulk_bonuses';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'bulk_product_bonuses',
  label: 'Lote Bonus',
  create: BulkBonusCreate,
  actions: ['create'],
  icon: <Icons.GiftOutlined />,
  parentName: 'ecommerce'
};

export default generateResources(resource);
