import AssetUpload from 'components/AssetUpload';
import TabStudents from './components/TabStudents';
import Tree from 'components/courses/Tree';
import {
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Tabs,
  Typography
} from 'antd';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import {
  ICourseRequirement,
  ICourseShow,
  ICourseTree
} from 'interfaces/courses';
import { IInstructor } from 'interfaces/instructors';
import { TabDisciplines } from './components/TabDisciplines';
import { useCallback, useEffect, useState } from 'react';

export const CourseEdit = () => {
  const { formProps, saveButtonProps, queryResult, form } =
    useForm<ICourseShow>({
      redirect: 'edit'
    });
  const [isLoading, _] = useState(false);
  const { selectProps: instructorSelectProps } = useSelect<IInstructor>({
    resource: 'instructors',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: queryResult?.data?.data.instructor_ids,

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const [course, setCourse] = useState<ICourseShow>();
  const [hideSaveButton, setHideSaveButton] = useState(false);

  useEffect(() => {
    setCourse(queryResult?.data?.data);
  }, [queryResult?.data?.data]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (data: any) => {
    formProps.onFinish!({ ...data });
  };

  const updateCourse = useCallback(
    (tree: ICourseTree, updatedRequirement?: ICourseRequirement) => {
      if (!course || !updatedRequirement) {
        return;
      }

      const updatedRequirements = course.requirements.map(req =>
        req.id === updatedRequirement.id ? updatedRequirement : req
      );

      const updatedCourse: ICourseShow = {
        ...course,
        requirements: updatedRequirements,
        tree
      };

      setCourse(updatedCourse);
      form.submit();
    },
    [course, form]
  );

  const handleTabChange = (activeKey: string) => {
    if (activeKey === 'professor') {
      return setHideSaveButton(true);
    }
    return setHideSaveButton(false);
  };

  return (
    <Edit
      title="Editar curso avulso"
      saveButtonProps={saveButtonProps}
      footerButtonProps={{
        style: {
          display: hideSaveButton ? 'none' : 'inline-flex',
          float: 'right'
        }
      }}
    >
      <Tabs defaultActiveKey="info" onTabClick={handleTabChange}>
        <Tabs.TabPane tab="Informação" key="info">
          <Form {...formProps} onFinish={onFinish} layout="vertical">
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Duração (horas)"
              name="duration_in_hours"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={1} />
            </Form.Item>

            <Form.Item
              label="Nota mínima (0 - 100)"
              name="min_evaluation_score"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={0} max={100} />
            </Form.Item>

            <Form.Item label="Instrutores" name="instructor_ids">
              <Select {...instructorSelectProps} mode="multiple" />
            </Form.Item>

            <Form.Item label="Conteúdos gratuitos" name="free_content_ids">
              <Select mode="multiple">
                {course?.tree_contents.map(content => (
                  <Select.Option key={content.id}>{content.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {course && !course.with_disciplines && (
              <>
                <Divider />

                <Typography.Title level={5}>Conteúdo</Typography.Title>

                <Form.Item name="tree">
                  {course && (
                    <Tree
                      course={course}
                      tree={course.tree}
                      loading={isLoading}
                      onUpdate={updateCourse}
                    />
                  )}
                </Form.Item>
              </>
            )}
          </Form>
        </Tabs.TabPane>
        {course && course.with_disciplines && (
          <Tabs.TabPane tab="Disciplinas" key="disciplines">
            <TabDisciplines />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane tab="Alunos" key="students">
          <TabStudents />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Assets" key="assets">
          <Form {...formProps} onFinish={onFinish} layout="vertical">
            <Form.Item
              label="Cabeçalho"
              name={['media', 'header_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="course"
                src={queryResult?.data?.data?.media?.header_image}
              />
            </Form.Item>
            <Form.Item
              label="Card"
              name={['media', 'card_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="course"
                src={queryResult?.data?.data?.media?.card_image}
              />
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Coordenador do curso" key="professor">
          {course && <CourseProfessor course={course} courseType="Course" />}
        </Tabs.TabPane> */}
      </Tabs>
    </Edit>
  );
};
