import FilterForm from '../../../components/forms/FilterForm';
import dayjs from 'dayjs';
import {
  Alert,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tooltip
} from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IOrder, IOrderItem, IOrderRefund } from 'interfaces/orders';
import {
  List,
  ShowButton,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { formatDate, replaceTimeZone } from 'services/date';
import { translateStatus } from 'services/refunds';

interface IFilter {
  order_number: number;
  byCreatedAt: dayjs.Dayjs;
}

const default_filter_date = new Date();
default_filter_date.setDate(default_filter_date.getDate() - 30);

export const OrderNotRefundList = () => {
  const { tableProps, searchFormProps } = useTable<IOrder, HttpError, IFilter>({
    resource: 'order_refunds',
    permanentFilter: [
      {
        field: 'status',
        operator: 'eq',
        value: 'error'
      },
      {
        field: 'without_returned_order',
        operator: 'eq',
        value: true
      }
    ],
    onSearch: params => {
      const filters: CrudFilters = [];
      const { order_number, byCreatedAt } = params;

      filters.push(
        {
          field: 'order_number',
          operator: 'eq',
          value: order_number
        },
        {
          field: 'by_created_at',
          operator: 'eq',
          value: byCreatedAt && replaceTimeZone(byCreatedAt)
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'by_created_at',
          operator: 'eq',
          value: replaceTimeZone(dayjs(dayjs(default_filter_date)))
        }
      ],

      permanent: [
        {
          field: 'status',
          operator: 'eq',
          value: 'error'
        },
        {
          field: 'without_returned_order',
          operator: 'eq',
          value: true
        }
      ]
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Tooltip title="Referente apenas a reembolso total (valor cheio)">
              <Alert
                message="Tentativas realizadas em pedidos que ainda não foram estornados com sucesso."
                type="info"
                showIcon
              />
            </Tooltip>
            <Tooltip title="O tempo de processamento de novas tentativas de reembolso é 24h">
              <Alert
                message="Após o processamento com sucesso de uma nova tentativa, os registros não estarão mais aqui, somente na listagem padrão"
                type="warning"
                showIcon
              />
            </Tooltip>
            <FilterForm searchFormProps={searchFormProps}>
              <Form.Item label="Número do pedido" name="order_number">
                <Input placeholder="Digite aqui o número do pedido" />
              </Form.Item>
              <Form.Item
                colon
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
                label="Criado a partir de"
                name="byCreatedAt"
              >
                <DatePicker
                  className="w-full"
                  style={{ minWidth: 250 }}
                  placeholder="dia/mês/ano"
                  format="DD/MM/YYYY"
                  defaultValue={dayjs(default_filter_date)}
                  value={dayjs(default_filter_date)}
                  allowClear
                />
              </Form.Item>
            </FilterForm>
          </Space>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="order"
              key="order_number"
              title="N. do pedido"
              render={({ order_number }) => (
                <TagField key={order_number} value={order_number} />
              )}
            />
            <Table.Column
              dataIndex="order"
              key="user"
              title="Email do usuário"
              render={({ user }) => (
                <TagField key={user.id} value={user.email} />
              )}
            />
            <Table.Column
              dataIndex="order"
              key="items"
              title="Itens"
              render={({ items }) =>
                items.map((item: IOrderItem) => (
                  <TagField key={item.id} value={translateStatus(item.name)} />
                ))
              }
            />
            <Table.Column
              dataIndex="motive"
              key="motive"
              title="Motivo"
              render={motive => <TextField value={motive} />}
            />
            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Criação"
              render={createdAt => (
                <TextField value={formatDate(createdAt, 'DD/MM/YY HH:mm')} />
              )}
            />
            <Table.Column<IOrderRefund>
              title="Pedido"
              dataIndex="actions"
              align="center"
              render={(_, record) => (
                <Space>
                  <ShowButton
                    hideText
                    resource="orders"
                    recordItemId={record.order.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
