import * as Icons from '@ant-design/icons';
import InnerTagTree from '../TagTree';
import { Button, Divider, Form, Input, Space } from 'antd';
import { ITag, ITreeTag } from 'interfaces/epimetheus/tags';
import { useCreate, useCustom } from '@refinedev/core';
import { useForm } from '@refinedev/antd';
import { useState } from 'react';

interface Props {
  tag: ITag;
  libraryId: string;
}

const TagForm = ({
  tag,
  libraryId,
  onSuccess
}: {
  tag: ITag;
  libraryId: string;
  onSuccess: () => void;
}) => {
  const { form } = useForm({
    resource: `prometheus/v2/libraries/${libraryId}/tags`
  });
  const { mutate } = useCreate<ITag>({
    resource: `prometheus/v2/libraries/${libraryId}/tags`
  });

  const createTag = ({ name }: { name: string }) => {
    return new Promise<ITag>((resolve, reject) => {
      mutate(
        {
          resource: `prometheus/v2/libraries/${libraryId}/tags`,
          values: { parent_id: tag.id, name, group_id: tag.group.id }
        },
        {
          onSuccess: ({ data }) => {
            resolve(data);
            onSuccess();
          },
          onError: error => reject(error)
        }
      );
    });
  };

  const onCreate = () => {
    const name = form.getFieldValue('name');

    return createTag({ name });
  };

  return (
    <Form form={form} layout="horizontal" size="small" onFinish={onCreate}>
      <Space size={8}>
        <Form.Item label="Nome" name="name" style={{ margin: 0 }}>
          <Input />
        </Form.Item>
        <Button
          size="small"
          icon={<Icons.PlusCircleOutlined />}
          htmlType="submit"
        >
          Create
        </Button>
      </Space>
    </Form>
  );
};

const TagTree = ({ tag, libraryId }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [key, setKey] = useState(0);

  const { data } = useCustom<ITreeTag[]>({
    url: `admin/prometheus/v2/libraries/${libraryId}/tags`,
    method: 'get',
    config: {
      filters: [
        {
          field: 'ancestor_id',
          operator: 'eq',
          value: tag.id
        },
        {
          field: 'q',
          operator: 'eq',
          value: searchValue
        },
        {
          field: 'fake',
          operator: 'eq',
          value: key
        }
      ]
    }
  });

  const tags = data?.data || [];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchValue(value);
  };

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Input.Search placeholder="Search" onChange={onChange} />
      <InnerTagTree tags={tags} libraryId={libraryId} />
      <Divider />
      <TagForm
        tag={tag}
        onSuccess={() => setKey(key + 1)}
        libraryId={libraryId}
      />
    </Space>
  );
};

export default TagTree;
