import FilterForm from 'components/forms/FilterForm';
import useSelectCourseByType from 'hooks/useSelectCourseByType';
import { ASSESSMENT_KIND_OPTIONS } from 'services/assessments';
import { Col, Form, Input, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { FormProps } from 'antd/lib';
import { IAssessment } from 'interfaces/assessments';
import { useCallback } from 'react';

export interface IAssessmentsFilter {
  name: string;
  by_parent_id: string;
  by_parent_type: string;
  by_kind: IAssessment['kind'];
}

interface ListAssesmentsFilterProps {
  searchFormProps: FormProps<IAssessmentsFilter>;
}

export const AssesmentsFilter = ({
  searchFormProps
}: ListAssesmentsFilterProps) => {
  const { selectProps, selectedCourseType, setSelectedCourseType } =
    useSelectCourseByType();

  const onChangeType = useCallback(
    (e: RadioChangeEvent) => {
      const value = e.target.value;
      setSelectedCourseType(value);
    },
    [setSelectedCourseType]
  );

  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={[24, 12]} className="w-full">
        <Col span={24}>
          <Form.Item
            label="Tipo de curso"
            name="by_parent_type"
            className="!mb-0"
            initialValue="Course"
          >
            <Radio.Group
              onChange={onChangeType}
              value={selectedCourseType}
              defaultValue="Course"
            >
              <Radio value="Course">Curso</Radio>
              <Radio value="Graduate::Discipline">Disciplina</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 12]} className="w-full">
        <Col xs={24} md={9}>
          <Form.Item
            label={selectedCourseType === 'Course' ? 'Curso' : 'Disciplina'}
            name="by_parent_id"
            className="w-full"
          >
            <Select placeholder="Digite..." allowClear {...selectProps} />
          </Form.Item>
        </Col>

        <Col xs={24} md={9}>
          <Form.Item label="Nome da avaliação" name="name">
            <Input placeholder="Simulado 1" />
          </Form.Item>
        </Col>

        <Col xs={24} md={6}>
          <Form.Item label="Tipo de avaliação" name="by_kind" initialValue="">
            <Select
              options={[
                { label: 'Todos', value: '' },
                ...ASSESSMENT_KIND_OPTIONS
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </FilterForm>
  );
};
