import { ILibraryContent } from 'interfaces/libraries';
import { ShowButton, TextField } from '@refinedev/antd';
import { Space, Table, TableProps, Tag } from 'antd';
import { translateKind } from 'services/libraries/contents';
import { useParams } from 'react-router-dom';

interface ContentsTableProps {
  tableProps: TableProps;
}

export const ContentsTable = ({ tableProps }: ContentsTableProps) => {
  const { id: libraryId } = useParams();
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="id"
        key="id"
        title="ID"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="name"
        key="name"
        title="Nome"
        render={value => <TextField value={value} />}
      />

      <Table.Column
        dataIndex="kind"
        key="kind"
        title="Tipo"
        render={value => <TextField value={translateKind(value)} />}
      />

      <Table.Column
        dataIndex="authors"
        key="authors"
        title="Autores"
        render={value =>
          value.map((author: string) => <Tag key={author}>{author}</Tag>)
        }
      />
      <Table.Column<ILibraryContent>
        title="Ações"
        dataIndex="actions"
        align="center"
        render={(_, record) => {
          return (
            <Space>
              <ShowButton
                hideText
                size="small"
                resource="library_contents"
                recordItemId={record.id}
                meta={{ library_id: libraryId }}
              />
            </Space>
          );
        }}
      />
    </Table>
  );
};
