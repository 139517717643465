import React, { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { createPost } from 'requests/posts';
import { useModalReturnType } from '@refinedev/core';

interface Props {
  threadId: string;
  modal: useModalReturnType;
  onUpdate?: () => void;
}

const PostModal: React.FC<Props> = ({ threadId, modal, onUpdate }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onClose = () => {
    modal.close();
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const { text } = await form.validateFields();

      await createPost({ threadId, text });

      if (onUpdate) {
        onUpdate();
      }
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      title="Resposta"
      visible={modal.visible}
      width={800}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item name="text" rules={[{ required: true }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PostModal;
