import { HttpError, useShow } from '@refinedev/core';
import { IDamStreamOptions, IDamVideo } from 'interfaces/dam_videos';
import { ILive } from 'interfaces/lives';
import { Input, Space, Table, Tabs, Tag, Typography } from 'antd';
import { LiveStatusMap, StatusMap } from 'services/prometheus';
import {
  Show,
  ShowButton,
  TextField,
  useForm,
  useTable
} from '@refinedev/antd';
import { formatDate } from 'services/date';

interface IFilter {
  live: string;
}

export const LiveShow = () => {
  const { id } = useForm<ILive>();

  const { queryResult } = useShow<ILive>({
    resource: 'prometheus/lives',
    id: id!
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { tableProps } = useTable<IDamVideo, HttpError, IFilter>({
    resource: 'prometheus/videos',

    filters: {
      permanent: [{ field: 'filter[live]', operator: 'eq', value: record?.id }]
    }
  });

  if (!record) {
    return null;
  }

  return (
    <Show isLoading={isLoading} title={'Visualizar live: ' + record.title}>
      <div style={{ marginBottom: 20 }}>
        <Tag color={record.stream.state !== 'connected' ? 'error' : 'success'}>
          {LiveStatusMap[record.stream.state]}
        </Tag>
      </div>
      <Tabs defaultActiveKey="rtmps">
        {record?.stream.inputs.map(stream => (
          <Tabs.TabPane
            tab={stream.protocol.toUpperCase()}
            key={stream.protocol}
          >
            {stream.url && (
              <>
                <Typography.Text>URL</Typography.Text>
                <Input disabled={true} value={stream.url} />
              </>
            )}
            {stream.external_stream_id && (
              <>
                <Typography.Text>ID</Typography.Text>
                <Input disabled={true} value={stream.external_stream_id} />
              </>
            )}

            {stream.external_stream_key && (
              <>
                <Typography.Text>Key</Typography.Text>
                <Input disabled={true} value={stream.external_stream_key} />
              </>
            )}

            {stream.passphrase && (
              <>
                <Typography.Text>Passphrase</Typography.Text>
                <Input disabled={true} value={stream.passphrase} />
              </>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
      <Typography.Title level={3} style={{ marginTop: 20 }}>
        Vídeos recentes
      </Typography.Title>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="title"
          key="title"
          title="Titulo"
          render={value => <TextField value={value} />}
        />

        <Table.Column
          dataIndex={'options'}
          key="status"
          title="Status"
          render={values =>
            values.map((value: IDamStreamOptions) => (
              <Tag
                key={value.id}
                color={value.state === 'error' ? 'error' : 'success'}
              >
                {StatusMap[value.state]}
              </Tag>
            ))
          }
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title="Data de registro"
          render={value => <TextField value={formatDate(value)} />}
        />
        <Table.Column<IDamVideo>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  resource="prometheus/videos"
                />
              </Space>
            );
          }}
        />
      </Table>
    </Show>
  );
};
