import { IGraduateModule } from 'interfaces/graduate_courses';
import { Show } from '@refinedev/antd';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const GraduateModuleShow = () => {
  const { queryResult } = useShow<IGraduateModule>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Nome</Title>
      <Text>{record?.name}</Text>
    </Show>
  );
};
