import { AuthProvider } from '@refinedev/core';
import {
  clearSession,
  getRefreshToken,
  getToken,
  setSession,
  setUser
} from 'services/auth';
import { getPermissions } from 'services/permissions';
import { login } from 'requests/auth';
import { me } from '../requests/agents';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const session = await login({ username, password });
      await setSession(session);
      return {
        success: true,
        successNotification: {
          description: 'Sucesso!',
          message: 'Login realizado com sucesso!'
        }
      };
    } catch {
      throw new Error('Email e/ou senha inválidos.');
    }
  },
  logout: async () => {
    clearSession();
    return {
      success: true,
      redirectTo: '/login'
    };
  },
  onError: async error => {
    if (error?.statusCode === 'UNAUTHENTICATED') {
      return {
        logout: true
      };
    }

    return { error };
  },
  check: async () => {
    if (getToken() || getRefreshToken()) {
      return {
        authenticated: true
      };
    }

    return {
      authenticated: false
    };
  },
  getPermissions: getPermissions,
  getIdentity: async () => {
    const token = getToken();
    const refreshToken = getRefreshToken();
    const authed = token || refreshToken;

    if (token && refreshToken) {
      await setSession({ token, refreshToken });
    }

    if (!authed) {
      throw undefined;
    }

    const user = await me();

    await setUser(user);

    return user;
  }
};

export default authProvider;
