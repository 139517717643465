import FilterForm from '../../components/forms/FilterForm';
import dayjs from 'dayjs';
import { ClassroomTable } from './components/ClassroomTable';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table
} from 'antd';
import {
  EditButton,
  List,
  ShowButton,
  useSelect,
  useTable
} from '@refinedev/antd';
import { HttpError } from '@refinedev/core';
import { IClassroom } from 'interfaces/classrooms';
import { IGraduateDiscipline } from '../../interfaces/graduate_disciplines';
import { replaceTimeZone } from '../../services/date';

interface IFilter {
  code: string;
  disciplineId: string;
  current: boolean;
  byAutoEnroll: boolean;
  startsAfter: dayjs.Dayjs;
  endsBefore: dayjs.Dayjs;
}

export const ClassroomList = () => {
  const { tableProps, searchFormProps } = useTable<
    IClassroom,
    HttpError,
    IFilter
  >({
    onSearch: ({
      code,
      disciplineId,
      current,
      byAutoEnroll,
      startsAfter,
      endsBefore
    }) => {
      return [
        {
          field: 'filter[search_code]',
          operator: 'eq',
          value: code
        },
        {
          field: 'filter[by_discipline_id]',
          operator: 'eq',
          value: disciplineId
        },
        {
          field: 'filter[current]',
          operator: 'eq',
          value: current || null
        },
        {
          field: 'filter[by_auto_enroll]',
          operator: 'eq',
          value: byAutoEnroll
        },
        {
          field: 'filter[starts_after]',
          operator: 'eq',
          value: startsAfter && replaceTimeZone(startsAfter)
        },
        {
          field: 'filter[ends_before]',
          operator: 'eq',
          value: endsBefore && replaceTimeZone(endsBefore)
        }
      ];
    }
  });

  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    }
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item
              colon
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              label="Código da turma"
              name="code"
            >
              <Input
                style={{ minWidth: 250 }}
                placeholder="Informe o código da turma"
              />
            </Form.Item>
            <Form.Item
              colon
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              label="Disciplina"
              name="disciplineId"
            >
              <Select
                allowClear
                {...disciplineSelectProps}
                style={{ minWidth: 250 }}
                placeholder="Selecione a disciplina"
              />
            </Form.Item>
            <Form.Item
              colon
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              label="Início a partir de"
              name="startsAfter"
            >
              <DatePicker
                className="w-full"
                style={{ minWidth: 250 }}
                placeholder="dia/mês/ano"
                format="DD/MM/YYYY"
              />
            </Form.Item>

            <Form.Item
              colon
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
              label="Término em"
              name="endsBefore"
            >
              <DatePicker
                className="w-full"
                style={{ minWidth: 250 }}
                placeholder="dia/mês/ano"
                format="DD/MM/YYYY"
              />
            </Form.Item>

            <Row className="w-full" gutter={[12, 12]}>
              <Col>
                <Form.Item label="Turmas ativas" name="current">
                  <Switch />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Com inscrição automática" name="byAutoEnroll">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </FilterForm>

          <ClassroomTable tableProps={tableProps}>
            <Table.Column<IClassroom>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </ClassroomTable>
        </List>
      </Col>
    </Row>
  );
};
