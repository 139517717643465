import { financeIcon } from '../assets/icons/finance';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'finance',
  label: 'Financeiro',
  icon: financeIcon
};

export default generateResources(resource);
