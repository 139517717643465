import { Modal, ModalProps, Typography } from 'antd';
import { useDelete } from '@refinedev/core';
import { useParams } from 'react-router-dom';

export interface DeleteModalProps {
  modalClose: () => void;
  modalProps: ModalProps;
  deleteContext: { resource: string; id: string };
  refresh: () => void;
}

export const DeleteModal = ({
  modalProps,
  modalClose,
  refresh,
  deleteContext
}: DeleteModalProps) => {
  const { id } = useParams();
  const { mutate } = useDelete();

  const deleteCampaing = () => {
    const resource =
      deleteContext.resource === 'post' ? `forums/${id}/posts` : `forums`;
    mutate(
      {
        resource: resource,
        id: deleteContext.id
      },
      {
        onSuccess: () => {
          refresh();
          modalClose();
        }
      }
    );
  };

  return (
    <Modal
      style={{ maxWidth: 400 }}
      {...modalProps}
      closable={false}
      title={`Excluir ${
        deleteContext.resource === 'post' ? 'comentário' : 'tópico'
      }`}
      cancelText="Voltar"
      okText="Excluir"
      className="!w-[1022px]"
      destroyOnClose
      okButtonProps={{ danger: true }}
      onOk={deleteCampaing}
    >
      <Typography.Paragraph>
        Essa ação não pode ser revertida
      </Typography.Paragraph>
    </Modal>
  );
};
