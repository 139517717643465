import { generateResources } from 'utils/GenerateResouces';
import { salesIcon } from '../assets/icons/sales';

const resource = {
  name: 'sales',
  label: 'Vendas',
  icon: salesIcon
};

export default generateResources(resource);
