import { IProduct } from 'interfaces/products';
import { Show } from '@refinedev/antd';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const ProductShow = () => {
  const { queryResult } = useShow<IProduct>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar produto">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Slug</Title>
      <Text>{record?.slug}</Text>
      <Title level={5}>Nome</Title>
      <Text>{record?.name}</Text>
      <Title level={5}>Descrição</Title>
      <Text>{record?.description}</Text>
    </Show>
  );
};
