import {
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Row,
  Select,
  SelectProps,
  Switch,
  Typography
} from 'antd';
import { replaceTimeZone } from 'services/date';
import { useCreate, useNotification } from '@refinedev/core';
import { useEffect } from 'react';
import { useForm } from '@refinedev/antd';

export interface EvaluationCreateModalProps {
  evaluationResource: string;
  modalClose: () => void;
  modalProps: ModalProps;
  assignmentSelectorProps: SelectProps<{ value: string; label: string }>;
  assesmentSelectorProps: SelectProps<{ value: string; label: string }>;
}

const EvaluationCreateModal = ({
  evaluationResource,
  modalClose,
  modalProps,
  assignmentSelectorProps,
  assesmentSelectorProps
}: EvaluationCreateModalProps) => {
  const { mutateAsync: createEvaluation } = useCreate();
  const { form, formProps } = useForm();
  const { open } = useNotification();
  const type = Form.useWatch('evaluation_type', form);
  const kind = Form.useWatch('kind', form);

  const handleCreate = async () => {
    return form
      .validateFields()
      .then(async () => {
        const [starts_at, ends_at] = form.getFieldValue('dateRange');
        const isFinal = form.getFieldValue('kind');
        return createEvaluation({
          resource: evaluationResource,
          values: {
            ...form.getFieldsValue(),
            starts_at: replaceTimeZone(starts_at),
            ends_at: replaceTimeZone(ends_at),
            kind: isFinal ? 'final' : 'regular'
          }
        }).then(() => {
          modalClose();
          form.resetFields();
        });
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: 'Favor conferir todos os campos obrigatórios.',
          description: 'Error ao criar registro!'
        });
      });
  };

  const handleSelectType = () => {
    form.setFieldsValue({ evaluation_id: null });
  };

  useEffect(() => {
    if (kind === 'final') {
      form.setFieldsValue({ weight: null });
    }
  }, [kind, form]);

  return (
    <Modal
      {...modalProps}
      title="Criar nova avaliação"
      cancelText="Cancelar"
      okText="Criar avaliação"
      className="!w-[1022px]"
      onOk={handleCreate}
      destroyOnClose
    >
      <Form form={form} {...formProps} layout="vertical">
        <Typography.Title level={5}>
          Informações gerais da avaliação
        </Typography.Title>

        <Row gutter={[16, 16]}>
          <Col md={8}>
            <Form.Item
              label="Tipo"
              name="evaluation_type"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select onChange={handleSelectType}>
                <Select.Option value="Assessment">Avaliação</Select.Option>
                <Select.Option value="Assignment">Atividade</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Avaliação"
              name="evaluation_id"
              rules={[
                {
                  required: true
                }
              ]}
            >
              {type === 'Assessment' ? (
                <Select
                  notFoundContent={
                    <Empty description="Não há avaliações cadastradas para essa disciplina" />
                  }
                  disabled={!type}
                  {...assesmentSelectorProps}
                  style={{ minWidth: 200 }}
                />
              ) : (
                <Select
                  notFoundContent={
                    <Empty description="Não há atividades cadastradas para essa disciplina" />
                  }
                  disabled={!type}
                  {...assignmentSelectorProps}
                  style={{ minWidth: 200 }}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {!kind && (
            <Col md={8}>
              <Form.Item
                label="Peso"
                name="weight"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <InputNumber min={0} max={100} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          )}
          <Col md={8}>
            <Form.Item
              label="Nota mínima (0 - 100)"
              name="passing_score"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={0} max={100} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Tentativas"
              name="max_attempts"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={0} max={100} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Typography.Title level={5}>Acesso</Typography.Title>
        </Row>
        <Row>
          <Typography.Paragraph>
            O período de acesso da avaliação deve está entre o inicio e fim da
            turma (dd/mm/aaaa - dd/mm/aaaa)
          </Typography.Paragraph>
        </Row>
        <Row>
          <Col md={20}>
            <Form.Item
              label="Acesso"
              name="dateRange"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <DatePicker.RangePicker
                className="w-full"
                showTime
                placeholder={[
                  'Início (horário de Brasília)',
                  'Término (horário de Brasília)'
                ]}
                format="DD/MM/YYYY HH:mm"
              />
            </Form.Item>
          </Col>
          <Col md={20}>
            <Row className="gap-2">
              <Form.Item
                label="Avaliação de recuperação"
                name="kind"
                valuePropName="checked"
                noStyle
              >
                <Switch />
              </Form.Item>
              <Col>
                <Row className="gap-2">
                  <Typography.Title
                    level={5}
                    style={{
                      color: '#626262',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                  >
                    Avaliação de recuperação
                  </Typography.Title>
                </Row>
                <Row className="gap-2">
                  <Typography.Paragraph
                    style={{
                      fontSize: '14px'
                    }}
                  >
                    Essa avaliação deve acontecer após o termino de todas as
                    outras avaliações
                  </Typography.Paragraph>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EvaluationCreateModal;
