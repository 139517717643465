import React from 'react';
import { Card, Space, Typography } from 'antd';
import { IGraduateCourse } from '../../../../interfaces/graduate_courses';
import { IUser } from '../../../../interfaces/users';

interface StudentInfoProps {
  user?: IUser;
  course?: IGraduateCourse;
}

export const StudentInfo: React.FC<StudentInfoProps> = ({ user, course }) => {
  return (
    <Card
      size="small"
      title="Dados do Aluno"
      type="inner"
      style={{ marginTop: 16 }}
    >
      <div style={{ fontSize: 'smaller' }}>
        <Typography.Text strong>Nome: </Typography.Text>
        <Typography.Text>{user?.name}</Typography.Text>
      </div>
      <div style={{ fontSize: 'smaller' }}>
        <Typography.Text strong>E-mail: </Typography.Text>
        <Typography.Text>{user?.email}</Typography.Text>
      </div>
    </Card>
  );
};
