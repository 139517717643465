import axios, { AxiosInstance } from 'axios';
import { ITenant } from 'interfaces/tenants';
import { ResponseError } from 'interfaces/api-error';
import { Tracker } from 'services/tracker';
import {
  clearSession,
  getRefreshToken,
  getToken,
  setSession
} from 'services/auth';
import { getTenant } from 'services/tenants';
import { refresh } from 'requests/auth';

const RETRY_LIMIT = 0;
const UNAUTHORIZED = 401;

const apiMap = new Map<string, AxiosInstance>();
const refreshingMap = new Map<string, Promise<void> | null>();

export const getAPI = () => {
  const tenant = getTenant();

  const api = apiMap.get(tenant.name) || generateAPI(tenant);
  apiMap.set(tenant.name, api);

  return api;
};

const clear = () => {
  clearSession();
  window.location.reload();
};

const handleAdminErrorTriggered = (error: ResponseError, status: number) => {
  Tracker.adminErrorTriggered({
    error_code: error.code || status.toString(),
    error_message: error.message || 'Unknown error',
    screen: window.location.href
  });
};

const generateAPI = (tenant: ITenant) => {
  const api = axios.create({
    baseURL: tenant.apiUrl
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api.interceptors.request.use((config: any) => {
    let { headers } = config;
    const token = getToken();
    const retryCount = config.retryCount || 0;

    headers = headers || {};

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return {
      ...config,
      headers,
      retryCount
    };
  });

  api.interceptors.response.use(
    response => response,
    async error => {
      const { response, config } = error;
      const { retryCount } = config;

      const customError: ResponseError = {
        ...config,
        ...response.data,
        status: response.status
      };

      if (response.status !== UNAUTHORIZED) {
        handleAdminErrorTriggered(customError, response?.status);

        throw customError;
      }

      if (retryCount > RETRY_LIMIT) {
        handleAdminErrorTriggered(customError, response?.status);

        throw customError;
      }

      const refreshToken = getRefreshToken();

      if (!refreshToken) {
        clear();
        throw customError;
      }

      refreshingMap.get(tenant.name) ||
        refreshingMap.set(
          tenant.name,
          refresh({ refreshToken })
            .then(session => {
              setSession(session);
            })
            .finally(() => {
              refreshingMap.set(tenant.name, null);
            })
        );

      const retryConfig = {
        ...config,
        retryCount: retryCount + 1
      };

      try {
        await refreshingMap.get(tenant.name);
        return api(retryConfig);
      } catch {
        clear();
        throw new Error(error);
      }
    }
  );

  return api;
};
