import * as Icons from '@ant-design/icons';
import { Button, Form, FormInstance, Select, Table } from 'antd';
import { IClassroom } from 'interfaces/classrooms';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { TextField, useSelect } from '@refinedev/antd';
import { useCallback, useEffect, useState } from 'react';
import { useNotification } from '@refinedev/core';

interface FormGraduateCoursesProps {
  form: FormInstance;
  onChange: (items: IGraduateCourse[]) => void;
}

export const FormGraduateCourses = ({
  form,
  onChange
}: FormGraduateCoursesProps) => {
  const [items, setItems] = useState<IGraduateCourse[]>([]);
  const notification = useNotification();

  const {
    selectProps: graduateCourseSelectProps,
    queryResult: graduateCouseQueryResult
  } = useSelect<IGraduateCourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const getItems = useCallback(() => {
    if (items) {
      onChange(items);
    }
  }, [items, onChange]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const onAddGraduateCourse = () => {
    const disciplineId = form.getFieldValue('graduate_course');
    const disciplines =
      (graduateCouseQueryResult?.data?.data as IGraduateCourse[]) || undefined;

    if (items.some(course => course.id === disciplineId)) {
      return notification.open?.({
        message: '',
        type: 'error',
        description: 'Esse curso já foi adicionado!'
      });
    }

    const classroom = disciplines?.find(cls => cls.id === disciplineId);

    if (classroom) {
      setItems(prevState => {
        const prevStateArray = prevState || [];
        return [...prevStateArray, classroom];
      });

      form.resetFields(['graduate_course']);
    }
  };

  const removeGraduateCourse = (id: string) => {
    setItems(prevState => prevState.filter(course => course.id !== id));
  };

  return (
    <>
      <div className="flex items-center gap-2 w-full mb-4">
        <Form.Item
          name="graduate_course"
          rules={[
            {
              required: items.length === 0,
              message: 'O curso é obrigatório'
            }
          ]}
          className="w-full !mb-0"
        >
          <Select
            {...graduateCourseSelectProps}
            placeholder="Selecionar curso regulado"
          />
        </Form.Item>
        <Button onClick={onAddGraduateCourse}>Inserir</Button>
      </div>

      <Table dataSource={items} rowKey="id" className="w-full">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Curso"
          render={value => <TextField value={value} />}
        />
        <Table.Column<IClassroom>
          key="action"
          title="Ação"
          align="center"
          width={100}
          render={(text, record) => (
            <Button
              icon={<Icons.DeleteOutlined />}
              size="small"
              onClick={() => removeGraduateCourse(record.id)}
            />
          )}
        />
      </Table>
    </>
  );
};
