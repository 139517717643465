import { ASSESSMENT_KIND_MAP } from 'services/assessments';
import {
  AssesmentsFilter,
  IAssessmentsFilter
} from './components/AssesmentsFilter';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  EditButton,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { IAssessment } from 'interfaces/assessments';
import { IProductBonus } from '../../../interfaces/product_bonuses';
import { Space, Table } from 'antd';

export const AssessmentList = () => {
  const { tableProps, searchFormProps } = useTable<
    IAssessment,
    HttpError,
    IAssessmentsFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { by_parent_id, by_parent_type, name, by_kind } = params;

      filters.push(
        {
          field: 'filter[by_parent_id]',
          operator: 'eq',
          value: by_parent_id
        },
        {
          field: 'filter[by_parent_type]',
          operator: 'eq',
          value: by_parent_type
        },
        {
          field: 'filter[full_name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_kind]',
          operator: 'eq',
          value: by_kind
        }
      );

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'filter[by_parent_type]',
          operator: 'eq',
          value: 'Course'
        }
      ]
    }
  });

  return (
    <List>
      <AssesmentsFilter searchFormProps={searchFormProps} />

      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome da avaliação"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="parent_type"
          key="parent_type"
          title="Tipo de curso"
          render={value => (
            <TextField value={value === 'Course' ? 'Curso' : 'Disciplina'} />
          )}
        />
        <Table.Column
          dataIndex="course_name"
          key="course_name"
          title="Curso/Disciplina"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="kind"
          key="kind"
          title="Tipo de avaliação"
          render={(value: IAssessment['kind']) => (
            <TextField value={ASSESSMENT_KIND_MAP[value] ?? ''} />
          )}
        />
        <Table.Column
          dataIndex="max_attempts"
          key="max_attempts"
          title="Tentativas"
          align="center"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="passing_score"
          key="passing_score"
          title="Nota mínima"
          align="center"
          render={value => <TextField value={value} />}
        />

        <Table.Column<IProductBonus>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
