import { Form, Modal, Select } from 'antd';
import { IApiError } from 'interfaces/error';
import { IDocumentTemplate } from 'interfaces/documentation/document-template';
import { useCallback, useMemo } from 'react';
import { useCreate } from '@refinedev/core';
import { useDocumentationStore } from '../documentation-store';
import { useParams } from 'react-router-dom';

interface ModalSetKindProps {
  templates: IDocumentTemplate[];
}

export const ModalSetKind = ({ templates }: ModalSetKindProps) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { mutateAsync: updateTemplate } = useCreate();
  const modal = useDocumentationStore(s => s.modal);
  const editDocument = useDocumentationStore(s => s.editDocument);
  const queryHandler = useDocumentationStore(s => s.queryHandler);
  const closeModal = useDocumentationStore(s => s.closeModal);

  const options = useMemo(() => {
    return templates.length > 0
      ? templates.map(template => ({
          label: template.name,
          value: template.id
        }))
      : [];
  }, [templates]);

  const handleOk = useCallback(() => {
    if (!editDocument) {
      return null;
    }

    updateTemplate({
      resource: `documentations/${id}/documents/${editDocument.id}/classify`,
      values: { template_id: form.getFieldValue('template_id') },
      errorNotification: error => {
        if (
          typeof error === 'object' &&
          error !== null &&
          (error as IApiError).response.data.code ===
            'documentations_template_already_fulfilled_error'
        ) {
          return {
            type: 'error',
            description: 'Não foi possível categorizar o documento',
            message:
              'Já existe um documento pendente ou aprovado nesta categoria'
          };
        }
        return {
          type: 'error',
          message: 'Ocorreu um erro interno no serviço',
          description: 'Erro ao categorizar documento!'
        };
      },
      successNotification: {
        type: 'success',
        description: 'Categoria alterada com sucesso',
        message: `A categoria do documento foi alterada para "${
          options.find(e => e.value === editDocument.template_id)?.label
        }"`
      }
    })
      .then(() => {
        form.resetFields();
        queryHandler();
        closeModal();
      })
      .catch(() => null);
  }, [
    closeModal,
    editDocument,
    form,
    id,
    options,
    queryHandler,
    updateTemplate
  ]);

  return (
    <Modal
      visible={modal === 'setKind'}
      title={
        editDocument?.template_id
          ? 'Trocar categoria do documento'
          : 'Categorizar documento'
      }
      width={400}
      okText="Salvar"
      onCancel={() => {
        form.resetFields();
        closeModal();
      }}
      onOk={() => handleOk()}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="template_id" label="Tipo de documento">
          <Select
            key={editDocument?.template_id}
            options={options.filter(
              option => option.value !== editDocument?.template_id
            )}
            placeholder="Selecione o tipo"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
