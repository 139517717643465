import { AttemptQuestion } from './components/AttemptQuestion';
import { Card, Divider, Space, Typography } from 'antd';
import { Edit, SaveButton, useNotificationProvider } from '@refinedev/antd';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { IAssessmentAttemptShow } from 'interfaces/assessments';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { IUser } from 'interfaces/users';
import { UserAttemptsBreadcrumbs } from './components/UserAttemptsBreadcrumbs';
import { getAPI } from 'requests/api';
import { useBack, useOne } from '@refinedev/core';
import { useParams } from 'react-router-dom';
import type { ResponseError } from 'interfaces/api-error';

const { Text } = Typography;

const calcTotalScore = (values: number[], totalQuestions: number) => {
  const totalScores = values.reduce((acc, score) => acc + score, 0);
  return totalScores / totalQuestions;
};

export const UserAssessmentAttemptEdit = () => {
  const params = useParams();
  const assessmentId = params?.assessmentId;
  const attemptId = params?.id;
  const notification = useNotificationProvider();
  const [loading, setLoading] = useState(false);
  const back = useBack();

  const { data, isLoading: loadingAttempt } = useOne<IAssessmentAttemptShow>({
    resource: 'assessments/attempts',
    id: attemptId
  });

  const attempt = data?.data;

  const { data: userData, isLoading: loadingUsers } = useOne<IUser>({
    resource: 'users',
    id: attempt?.user_id
  });

  const { data: disciplineData, isLoading: loadingDiscipline } =
    useOne<IGraduateDiscipline>({
      resource: 'graduate_disciplines',
      id: attempt?.assessment.parent_id
    });

  const isLoading = useMemo(
    () => loadingAttempt || loadingUsers || loadingDiscipline,
    [loadingAttempt, loadingDiscipline, loadingUsers]
  );

  const [scores, setScores] = useState<number[]>([]);
  const [totalScore, setTotalScore] = useState(0);

  const questionsAmount = useMemo(
    () => attempt?.assessment.question_amount || 0,
    [attempt?.assessment.question_amount]
  );

  useEffect(() => {
    if (attempt) {
      const initialScores = attempt?.questions.map(e => e.evaluator_score);
      setScores(initialScores);
      setTotalScore(calcTotalScore(initialScores, questionsAmount));
    }
  }, [attempt, questionsAmount]);

  const onCalcTotalScore = useCallback(
    (index: number, value: number) => {
      const updatedScores = [...scores];
      updatedScores[index] = value;
      const calc = calcTotalScore(updatedScores, questionsAmount);
      setScores(updatedScores);
      setTotalScore(calc);
    },
    [scores, questionsAmount]
  );

  const onFinishEvaluation = useCallback(async () => {
    setLoading(true);
    await getAPI()
      .put(`admin/assessments/attempts/${attemptId}/finish_evaluation`)
      .then(() => {
        notification.open?.({
          message: 'Nota pulibacada com sucesso',
          type: 'success',
          description: 'Tudo certo!'
        });

        back();
      })
      .catch(error => {
        const err = error as unknown as ResponseError;

        if (err.code === 'assessments_answers_status_error') {
          return notification.open?.({
            message: 'Existem respostas pendentes de correção.',
            type: 'error',
            description: 'Respostas não corrigidas'
          });
        }

        notification.open?.({
          message: 'Erro ao publicar nota. Tente novamente.',
          type: 'error',
          description: 'Ops! Algo deu errado. '
        });
      })
      .finally(() => setLoading(false));
  }, [attemptId, back, notification]);

  return (
    <Edit
      isLoading={isLoading}
      headerButtonProps={{ hidden: true }}
      footerButtons={({ saveButtonProps }) => (
        <SaveButton
          onClick={onFinishEvaluation}
          {...saveButtonProps}
          loading={loading}
        >
          Publicar nota
        </SaveButton>
      )}
      breadcrumb={
        <UserAttemptsBreadcrumbs
          items={[
            {
              title: 'Tentativas do aluno',
              href: `/evaluations/assessments/${assessmentId}/user_attempts/show/${attempt?.user_id}`
            },
            {
              title: 'Corrigir'
            }
          ]}
        />
      }
      title="Corrigir"
    >
      <Card style={{ marginBottom: 24 }} size="small">
        <Space direction="vertical">
          <Text strong>Avaliação: {attempt?.assessment.name}</Text>

          <Text strong>Disciplina: {disciplineData?.data.name}</Text>

          <Text strong>Aluno: {userData?.data.name}</Text>
        </Space>
      </Card>

      <Space direction="vertical" style={{ width: '100%' }}>
        {attempt?.questions.map((question, index) => (
          <Fragment key={question.id}>
            <AttemptQuestion
              index={index}
              question={question}
              onChangeScore={onCalcTotalScore}
            />
            <Divider />
          </Fragment>
        ))}
      </Space>

      <Card size="small" style={{ backgroundColor: '#F5F5F5', marginTop: 30 }}>
        <Text
          strong
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: 16
          }}
        >
          Nota: {totalScore}
        </Text>
      </Card>
    </Edit>
  );
};
