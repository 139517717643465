import * as Icons from '@ant-design/icons';
import axios from 'axios';
import { Button, Progress, Upload, UploadFile } from 'antd';
import { useCreate } from '@refinedev/core';
import { useEffect, useState } from 'react';

type File = undefined | UploadFile;
type Preview = undefined | string;

export interface ContentUploadProps {
  accept: string | undefined;
  type: 'assignment_instruction' | 'content_supplementary_material';
  onChange?: (url: string | undefined) => void;
  onUpload?: (url: string, downloadUrl: string) => void;
  src?: Preview;
}

interface Asset {
  url: string;
  upload_url: string;
  download_url: string;
}

const ContentUpload = ({
  accept,
  type,
  src,
  onChange,
  onUpload
}: ContentUploadProps) => {
  const [file, setFile] = useState<File | null>();
  const [preview, setPreview] = useState<Preview>(src);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(
    src?.toString()
  );
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { mutate } = useCreate<Asset>();

  const fileExtension = file ? file.name.split('.').pop() : undefined;
  const previewExtension = preview ? preview.split('.').pop() : undefined;

  const createContent = () =>
    new Promise<Asset>((resolve, reject) => {
      mutate(
        {
          resource: 'content_files',
          values: { type, extension: fileExtension },
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Upload de arquivo realizado com sucesso'
          }),
          errorNotification: () => ({
            type: 'error',
            description: 'Ops!',
            message:
              'Não foi possível realizar o upload do arquivo. Tente novamente.'
          })
        },
        {
          onSuccess: ({ data }) => resolve(data),
          onError: error => reject(error)
        }
      );
    });

  const upload = async () => {
    try {
      const { url, upload_url, download_url } = await createContent();

      setUploadPercentage(0);
      setIsUploading(true);

      await axios.put(upload_url, file, {
        onUploadProgress: ({ loaded, total }) => {
          setUploadPercentage(Math.round((100 * loaded) / total));
        }
      });

      setPreview(url);
      setDownloadUrl(download_url);
      onUpload?.(url, download_url);
    } catch {
      setIsUploading(false);
    }

    setIsLoading(false);
  };

  // const remove = () => {
  //   setPreview('');
  //   setFile(null);
  //   setUploadPercentage(0);
  //   setIsUploading(false);
  // };

  useEffect(() => {
    setIsLoading(true);

    if (file) {
      upload();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    setPreview(src);
  }, [src]);

  useEffect(() => {
    onChange?.(preview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview]);

  return (
    <div className="flex flex-col gap-4 w-64">
      {preview && ['png', 'jpg', 'jpeg', 'svg'].includes(previewExtension!) && (
        <img src={preview} className="w-64" alt="asset" />
      )}
      <div className="!flex">
        {downloadUrl && (
          <Button
            className="!flex items-center justify-center w-full mr-2"
            disabled={isLoading}
            href={downloadUrl}
            target="_blank"
          >
            <Icons.DownloadOutlined />
            Download
          </Button>
        )}

        <Upload
          className="w-full"
          maxCount={1}
          accept={accept}
          fileList={[]}
          onRemove={() => setFile(null)}
          beforeUpload={newFile => {
            setFile(newFile);

            return false;
          }}
        >
          <Button
            className="!flex items-center justify-center w-full"
            disabled={isLoading}
          >
            <Icons.UploadOutlined />
            Upload
          </Button>
        </Upload>
        {isUploading && (
          <div className="w-full flex items-center">
            <Progress percent={uploadPercentage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentUpload;
