import { Col, DatePicker, Form, Input, Radio, Row, Typography } from 'antd';
import { Create, useForm } from '@refinedev/antd';
import { FormClassrooms } from './components/FormClassrooms';
import { FormCourses } from './components/FormCourses';
import { FormGraduateCourses } from './components/FormGraduateCourses';
import { IClassroom } from 'interfaces/classrooms';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { INoticeBoard } from 'interfaces/notice_board';
import { useState } from 'react';
import type { ICourse } from 'interfaces/courses';

export const NoticeBoardCreate = () => {
  const { form, formProps, saveButtonProps, onFinish } = useForm<INoticeBoard>({
    redirect: 'list',
    successNotification: () => {
      return {
        description: 'Sucesso!',
        message: 'Aviso criado com sucesso',
        type: 'success'
      };
    }
  });

  const [kind, setKind] = useState<INoticeBoard['kind']>('classroom');

  const [classroomsItems, setClassroomsItems] = useState<IClassroom[]>([]);
  const [graduateCoursesItems, setGraduateCoursesItems] = useState<
    IGraduateCourse[]
  >([]);
  const [coursesItems, setCoursesItems] = useState<ICourse[]>([]);

  const onSubmit = (data: Record<string, unknown>) => {
    let items;

    switch (kind) {
      case 'classroom': {
        items = {
          classroom_ids: classroomsItems.map(classroom => classroom.id)
        };
        break;
      }

      case 'course': {
        items = {
          course_ids: coursesItems.map(course => course.id)
        };
        break;
      }

      case 'graduate_course': {
        items = {
          graduate_course_ids: graduateCoursesItems.map(course => course.id)
        };
        break;
      }

      default: {
        items = {};
      }
    }

    onFinish({ ...data, ...items });
  };

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar mural de aviso">
      <Form {...formProps} layout="vertical" onFinish={onSubmit}>
        <fieldset className="mb-4">
          <Typography.Title level={5} className="block !mb-4">
            Informações do aviso
          </Typography.Title>

          <Form.Item
            name="title"
            label="Título do aviso"
            rules={[{ required: true, message: 'O título é obrigatório' }]}
          >
            <Input placeholder="Informe o título do aviso" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Descrição"
            rules={[{ required: true, message: 'A descrição é obrigatória' }]}
          >
            <Input.TextArea placeholder="Digite uma descrição" />
          </Form.Item>

          <Row gutter={[16, 16]} className="w-full">
            <Col xs={24} md={12}>
              <Form.Item
                name="link_url"
                label="Link"
                rules={[
                  {
                    pattern: new RegExp(/^(https?):\/\/[^\s#$./?].\S*$/),
                    message: 'Insira um link válido'
                  }
                ]}
              >
                <Input placeholder="Insira um link" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item name="link_text" label="Texto do link">
                <Input placeholder="Ex: Acesse agora" />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>

        <fieldset className="mb-4">
          <Typography.Title level={5} className="block !mb-4">
            Publicação e visibilidade
          </Typography.Title>

          <Row gutter={[16, 16]} className="w-full">
            <Col xs={24} md={12}>
              <Form.Item
                name="starts_at"
                label="Data da publicação"
                rules={[
                  { required: true, message: 'A data de início é obrigatória' }
                ]}
              >
                <DatePicker
                  className="w-full"
                  placeholder="Selecione a data"
                  showTime
                  format="DD/MM/YYYY hh:mm"
                />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>

        <fieldset className="mb-4">
          <Typography.Title level={5} className="block !mb-4">
            Prioridade
          </Typography.Title>

          <Form.Item name="priority" initialValue="regular">
            <Radio.Group
              defaultValue="regular"
              options={[
                { label: 'Regular', value: 'regular' },
                { label: 'Alta', value: 'high' }
              ]}
              optionType="button"
              buttonStyle="solid"
              size="middle"
            />
          </Form.Item>
        </fieldset>

        <fieldset>
          <Typography.Title level={5} className="block !mb-3">
            Destino
          </Typography.Title>

          <Form.Item name="kind" initialValue="classroom" className="!mb-3">
            <Radio.Group
              defaultValue="classroom"
              name="kind"
              onChange={e => setKind(e.target.value)}
            >
              <Radio value="classroom">Turma</Radio>
              <Radio value="course">Curso avulso</Radio>
              <Radio value="graduate_course">Curso regulado</Radio>
              <Radio value="general">Instituição</Radio>
            </Radio.Group>
          </Form.Item>

          {kind === 'classroom' && (
            <FormClassrooms form={form} onChange={setClassroomsItems} />
          )}

          {kind === 'course' && (
            <FormCourses form={form} onChange={setCoursesItems} />
          )}

          {kind === 'graduate_course' && (
            <FormGraduateCourses
              form={form}
              onChange={setGraduateCoursesItems}
            />
          )}
        </fieldset>
      </Form>
    </Create>
  );
};
