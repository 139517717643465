import 'dayjs/locale/pt-br';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.locale('pt-BR');
dayjs.extend(timezone);
dayjs.extend(utc);

export const toSaoPauloTimeZone = (dateString: string | undefined) =>
  dayjs(dateString).tz('America/Sao_Paulo');

export const parseToDayjs = (
  dateString: string | undefined,
  format?: string | undefined
) => {
  if (format) {
    return dayjs(dateString).format(format);
  }
  return dayjs(dateString);
};

export const replaceTimeZone = (date: Dayjs) => {
  return `${dayjs(date).format('YYYY-MM-DDTHH:mm:ss')}${dayjs(date)
    .tz('America/Sao_Paulo')
    .format('ZZ')} `;
};

export const formatDate = (dateString: string, format = 'LLL') => {
  return parseDate(dateString).tz('America/Sao_Paulo').format(format);
};

export const parseDate = (dateString: string | undefined) => {
  return dayjs(dateString).locale('pt-BR');
};

export const convertNumberToDate = (dateNumber: number, iso = false) => {
  const cleaned = ('' + dateNumber).replace(/\D/g, '').slice(0, 8);
  const match = cleaned.match(/^(\d{0,2})?(\d{0,2})?(\d{0,4})?$/)!;

  if (iso) {
    return [
      match[2],
      match[2] ? '-' : '',
      match[1],
      match[1] ? '-' : '',
      match[3]
    ].join('');
  }
  return [
    match[1],
    match[1] ? '/' : '',
    match[2],
    match[2] ? '/' : '',
    match[3]
  ].join('');
};

export const getRequirementDays = (weeklyNumber: number) => {
  return weeklyNumber / 60 / 24;
};

export const getRequirementWeeks = (weeklyNumber: number) => {
  return weeklyNumber / 60 / 24 / 7;
};

export const weeksToMinutes = (weeks: number) => {
  const weekInMinutes = 24 * 60 * 7;
  return weeks * weekInMinutes;
};
