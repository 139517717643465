import { getAPI } from './api';

const api = getAPI();
const baseCommentsUrl = '/admin/comments/resource_reports/';
const baseForumsUrl = '/admin/forums/resource_reports/';

export const acceptReport = async (report_id: string) => {
  const { data } = await api.post(`${baseCommentsUrl}${report_id}/approve`);

  return data;
};

export const rejectReport = async (report_id: string) => {
  const { data } = await api.post(`${baseCommentsUrl}${report_id}/reject`);

  return data;
};

export const acceptForumReport = async (report_id: string) => {
  const { data } = await api.post(`${baseForumsUrl}${report_id}/approve`);

  return data;
};

export const rejectForumReport = async (report_id: string) => {
  const { data } = await api.post(`${baseForumsUrl}${report_id}/reject`);

  return data;
};
