import { Button, Tabs } from 'antd';
import { CourseForum } from './components/Forum/CourseForum';
import { CourseInfo } from './components/CourseInfo';
import { CreateTopicModal } from 'components/modal/forum/CreateTopicModal';
import {
  EditButton,
  ListButton,
  RefreshButton,
  Show,
  useModal
} from '@refinedev/antd';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { PlusSquareOutlined } from '@ant-design/icons';
import { getTenant } from 'services/tenants';
import { useShow } from '@refinedev/core';
import { useState } from 'react';

export const GraduateCourseShow = () => {
  const tenant = getTenant();
  const [activeTab, setActiveTab] = useState('info');
  const { queryResult } = useShow<IGraduateCourse>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const {
    show: topicCreateModalShow,
    close: topicCreateModalClose,
    modalProps: topicCreateModalProps
  } = useModal();

  return (
    <Show
      isLoading={isLoading}
      title="Visualizar curso regulado"
      headerButtons={
        <>
          <ListButton />
          {activeTab !== 'forum' ? (
            <>
              <EditButton />
              <RefreshButton />
            </>
          ) : (
            <Button
              icon={<PlusSquareOutlined />}
              type="default"
              onClick={topicCreateModalShow}
            >
              Novo Tópico
            </Button>
          )}
        </>
      }
    >
      <Tabs
        defaultActiveKey={activeTab}
        onChange={activeKey => setActiveTab(activeKey)}
      >
        <Tabs.TabPane tab="Informações gerais" key="info">
          <CourseInfo course={record} />
        </Tabs.TabPane>
        {!tenant.disabledFeatures?.includes('forums') && (
          <Tabs.TabPane tab="Fóruns" key="forum">
            <CourseForum />
          </Tabs.TabPane>
        )}
      </Tabs>
      <CreateTopicModal
        kind="Graduate::Course"
        parent={record}
        modalProps={topicCreateModalProps}
        modalClose={topicCreateModalClose}
      />
    </Show>
  );
};
