import FilterForm from '../../../components/forms/FilterForm';
import {
  DeleteButton,
  EditButton,
  List,
  TextField,
  useTable
} from '@refinedev/antd';
import { Form, Input, Space, Table } from 'antd';
import { HttpError } from '@refinedev/core';
import { IAgent } from 'interfaces/agents';

export const AgentList = () => {
  const { tableProps, searchFormProps } = useTable<
    IAgent,
    HttpError,
    { byName: string }
  >({
    onSearch: ({ byName }) => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value: byName
      }
    ]
  });

  return (
    <List>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Nome" name="byName">
          <Input placeholder="Pesquisar..." />
        </Form.Item>
      </FilterForm>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="login"
          key="login"
          title="Login"
          render={value => <TextField value={value} />}
        />
        <Table.Column<IAgent>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
