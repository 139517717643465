import {
  OfferCampaignAssetCreate,
  OfferCampaignAssetEdit,
  OfferCampaignAssetList
} from 'pages/ecommerce/offer_campaign_assets';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'offer_campaign_assets',
  label: 'Banners',
  list: OfferCampaignAssetList,
  create: OfferCampaignAssetCreate,
  edit: OfferCampaignAssetEdit,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'ecommerce'
};

export default generateResources(resource);
