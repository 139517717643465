export const StatusMap: Record<string, string> = {
  unpublished: 'Não Publicado',
  published: 'Publicado',
  processed: 'Processado',
  pending: 'Pendente',
  uploaded: 'Carregado',
  live: 'Ao Vivo'
};

export const LiveStatusMap: Record<string, string> = {
  connected: 'Conectado',
  disconnected: 'Desconectado'
};

export const FileStatusMap: Record<string, string> = {
  pending_upload: 'Aguardando upload',
  expired_upload: 'Uploaded expirado',
  pending_replication: 'Aguardando replicação',
  replicating: 'Replicando',
  uploaded: 'Armazenado',
  not_found: 'Não encontrado',
  error: 'Erro'
};
