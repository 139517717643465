import * as Icons from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import { IDocument } from 'interfaces/documentation/document';
import { TagField, TextField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import {
  getStatusColor,
  translateDocumentationStatus
} from 'services/documentations';
import { useDocumentationStore } from '../documentation-store';

interface SupplementaryDocumentsProps {
  title: string;
  documents: IDocument[];
}

export const SupplementaryDocuments = ({
  title,
  documents
}: SupplementaryDocumentsProps) => {
  const openModal = useDocumentationStore(s => s.openModal);

  const sortedDocuments = [...documents];
  sortedDocuments.sort((a, b) => {
    if (a.status === 'rejected' && b.status !== 'rejected') {
      return 1;
    } else if (a.status !== 'rejected' && b.status === 'rejected') {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Table
        dataSource={sortedDocuments}
        style={{ width: '100%' }}
        size="small"
        pagination={false}
      >
        <Table.Column
          title="Documento"
          render={value => <TextField value={value.file_name} />}
        />
        <Table.Column
          title="Status"
          render={value => (
            <TagField
              color={getStatusColor(value.status)}
              value={translateDocumentationStatus(value.status)}
            />
          )}
        />
        <Table.Column
          title="Data de envio"
          dataIndex="created_at"
          key="created_at"
          render={value => (
            <TextField value={formatDate(value, 'DD/MM/YYYY - HH:mm')} />
          )}
        />
        <Table.Column<IDocument>
          title="Ações"
          align="center"
          render={(_, record) => {
            const isRejected = record.status === 'rejected';
            return (
              <Space size="middle" style={{ width: '100px' }}>
                <Tooltip title="Visualizar documento">
                  <Button
                    size="small"
                    icon={<Icons.EyeOutlined />}
                    onClick={() => openModal('show', record)}
                  />
                </Tooltip>
                <Tooltip title="Escolher categoria">
                  <Button
                    size="small"
                    icon={<Icons.SwapOutlined />}
                    onClick={() => openModal('setKind', record)}
                    disabled={isRejected}
                  />
                </Tooltip>
                <Tooltip title="Rejeitar documento">
                  <Button
                    size="small"
                    icon={<Icons.CloseOutlined />}
                    onClick={() => openModal('reject', record)}
                    disabled={isRejected}
                  />
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
    </Space>
  );
};
