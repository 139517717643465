import React from 'react';
import { DeleteButton, ShowButton, TagField, TextField } from '@refinedev/antd';
import { IDiscussionThread } from 'interfaces/discussion_threads';
import { Space, Table, TableProps } from 'antd';
import { formatDate } from 'services/date';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateDiscussionStatus } from '..';

interface DiscussionThreadsTableProps {
  tableProps: TableProps<IDiscussionThread>;
}

export const DiscussionThreadsTable = ({
  tableProps
}: DiscussionThreadsTableProps) => {
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex="grandparent_type"
        key="grandparent_type"
        title="Tipo"
        render={value => (
          <TextField value={value === 'Course' ? 'Curso' : 'Disciplina'} />
        )}
      />
      <Table.Column
        dataIndex="grandparent"
        key="grandparent"
        title="Nome"
        render={value => <TextField value={value.name} />}
      />
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <TagField
            color={tagColorByStatus(value)}
            value={translateDiscussionStatus(value)}
          />
        )}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Data de postagem"
        render={value => <TextField value={formatDate(value)} />}
      />
      <Table.Column<IDiscussionThread>
        title="Ações"
        dataIndex="actions"
        render={(_, record) => {
          return (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton size="small" recordItemId={record.id} hideText />
            </Space>
          );
        }}
      />
    </Table>
  );
};
