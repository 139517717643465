import {
  InstructorCreate,
  InstructorEdit,
  InstructorList,
  InstructorShow
} from 'pages/academic/instructors';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'instructors',
  label: 'Instrutores',
  list: InstructorList,
  create: InstructorCreate,
  edit: InstructorEdit,
  show: InstructorShow,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'ecommerce'
};

export default generateResources(resource);
