import { Form, Input } from 'antd';
import { slugify } from '../../utils/slugify';

interface SlugInputProps {
  label?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
}

const SlugInput = ({
  label = 'Slug',
  name = 'slug',
  required = true,
  disabled = false
}: SlugInputProps) => {
  return (
    <Form.Item
      normalize={value => slugify(value)}
      label={label}
      name={name}
      rules={[{ required }]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};

export default SlugInput;
