import * as Icons from '@ant-design/icons';
import { Button, Divider, Radio, Space, Switch, Typography } from 'antd';
import { CreateClassroomModal } from 'components/modal/professor_area/classroom/CreateClassroomModal';
import { IInteractiveClass } from 'interfaces/interactive_classes';
import { ShowClassroomModal } from 'components/modal/professor_area/classroom/ShowClassroomModal';
import { TableRowItem } from './TableRowItem';
import { useModalForm } from '@refinedev/antd';
import { useState } from 'react';

export const TabInteractiveClasses = () => {
  const resource = 'interactive_classes';
  const [filter, setFilter] = useState('progress');
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow
  } = useModalForm<IInteractiveClass>({
    resource,
    action: 'create'
  });

  const {
    modalProps: showModalProps,
    formProps: showFormProps,
    show: showModalShow
  } = useModalForm<IInteractiveClass>({
    resource,
    action: 'edit'
  });

  return (
    <>
      <Space direction="vertical" className="w-full !gap-8">
        <Space className="flex w-full p-2 rounded-md bg-[#F5F5F5]">
          <Radio.Group
            buttonStyle="solid"
            value={filter}
            onChange={e => {
              setFilter(e.target.value);
            }}
          >
            <Radio.Button value="progress">Próximas aulas</Radio.Button>
            <Radio.Button value="finished">Aulas realizadas</Radio.Button>
          </Radio.Group>
          <Switch defaultValue={false} className="!ml-1" />
          <Typography.Paragraph className="!m-0">
            Minhas aulas
          </Typography.Paragraph>
        </Space>
        <Space className="flex w-full justify-between">
          <Space>
            <Typography.Title level={5} className="!m-0">
              Próximas aulas
            </Typography.Title>
            <Space className="flex rounded border solid p-1 h-[22px] w-[24px] justify-center bg-[#00000002]">
              {2}
            </Space>
          </Space>
          <Button
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => createModalShow()}
            size="small"
          >
            Nova aula
          </Button>
        </Space>
      </Space>
      <div>
        <Divider className="w-full !mb-0" />
        <TableRowItem showModal={showModalShow} />
        <TableRowItem showModal={showModalShow} />
      </div>
      <CreateClassroomModal
        modalProps={createModalProps}
        formProps={createFormProps}
      />
      <ShowClassroomModal
        modalProps={showModalProps}
        formProps={showFormProps}
      />
    </>
  );
};
