/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull admin'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 61
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/qconcursos/OPX/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/qconcursos/OPX/implementation/admin)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: 'bc7c3a0b1fc2a6e600791b6962019249',
  development: 'd045a727ef59d7b3927554f9795fed19'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '61',
    branch: 'main',
    source: 'admin',
    versionId: '863f4596-8d50-44fa-9180-45ea89fa7dbe'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  email?: string;
  fbclid?: any;
  gclid?: any;
  /**
   * Indica se o usuário tem faturas atrasadas.
   */
  has_due_invoice?: boolean;
  initial_dclid?: any;
  initial_fbclid?: any;
  initial_gclid?: any;
  initial_ko_click_id?: any;
  initial_msclkid?: any;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_ttclid?: any;
  initial_twclid?: any;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: any;
  referrer?: any;
  referring_domain?: any;
  utm_campaign?: any;
  utm_content?: any;
  utm_medium?: any;
  utm_source?: any;
  utm_term?: any;
}

export interface AdminErrorTriggeredProperties {
  /**
   * Propriedade que recebe o "código técnico" do erro gerado, útil para identificar o tipo de erro.
   */
  error_code: string;
  /**
   * Propriedade que recebe a mensagem de erro exibida para o usuário na interface
   */
  error_message: string;
  /**
   * Propriedade que recebe a tela ou aba em que o erro foi exibido (ex: tela de criação de curso, aba de informações)
   */
  screen: string;
}

export interface AdminMenuClickedProperties {
  /**
   * Propriedade que recebe o nome da aba do menu acessada no menu pelo usuário
   */
  menu_item: string;
  /**
   * Instituição educacional.
   */
  tenant?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdminErrorTriggered implements BaseEvent {
  event_type = 'Admin Error Triggered';

  constructor(
    public event_properties: AdminErrorTriggeredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdminMenuClicked implements BaseEvent {
  event_type = 'Admin Menu Clicked';

  constructor(
    public event_properties: AdminMenuClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Admin Error Triggered
   *
   * [View in Tracking Plan](https://data.amplitude.com/qconcursos/OPX/events/main/latest/Admin%20Error%20Triggered)
   *
   * Evento disparado quando um erro é exibido/disparado para o usuário em qualquer fluxo do admin
   *
   * Owner: Arthur Cesar Bizerra de  Oliveira
   *
   * @param properties The event's properties (e.g. error_code)
   * @param options Amplitude event options.
   */
  adminErrorTriggered(
    properties: AdminErrorTriggeredProperties,
    options?: EventOptions,
  ) {
    return this.track(new AdminErrorTriggered(properties), options);
  }

  /**
   * Admin Menu Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/qconcursos/OPX/events/main/latest/Admin%20Menu%20Clicked)
   *
   * Evento disparado ao usuário clicar no menu do admin.
   *
   * Owner: Arthur Cesar Bizerra de  Oliveira
   *
   * @param properties The event's properties (e.g. menu_item)
   * @param options Amplitude event options.
   */
  adminMenuClicked(
    properties: AdminMenuClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AdminMenuClicked(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
