import React, { useMemo } from 'react';
import { Checkbox, Space, Table, Typography } from 'antd';
import { PermissionActionColumn } from './permission-action-column';
import type { ParsedPermission } from 'services/permissions';

const RESOURCE_ACTIONS_MAP: Record<string, string> = {
  list: 'Listagem',
  show: 'Visualizar',
  create: 'Criar',
  edit: 'Editar',
  delete: 'Deletar',
  slug: 'Slug',
  cancel: 'Cancelar',
  deal: 'Negociar',
  interrupt: 'Interromper'
};

interface PermissionGroupProps {
  permission: ParsedPermission;
  selectedPermission: string[];
  onToggleAll: (name: string, checked: boolean) => void;
  onTogglePermission: (permission: string, parent: string) => void;
  disabled?: boolean;
}

export const PermissionsGroup = React.memo(
  ({
    permission,
    selectedPermission,
    onToggleAll,
    onTogglePermission,
    disabled
  }: PermissionGroupProps) => {
    const allChecked = useMemo(() => {
      if (!permission.children) {
        return false;
      }

      return (
        permission.children.every(child =>
          child.actions?.every(action =>
            selectedPermission.includes(`${child.name}:${action}`)
          )
        ) || selectedPermission.includes(`${permission.name}:*`)
      );
    }, [permission, selectedPermission]);

    return (
      <div key={permission.name}>
        <Space size="middle" style={{ marginBottom: 16 }}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {permission.title}
          </Typography.Text>
          <Checkbox
            disabled={disabled}
            checked={allChecked}
            onChange={e => onToggleAll(permission.name, e.target.checked)}
          >
            Selecionar tudo
          </Checkbox>
        </Space>

        <Table dataSource={permission.children} pagination={false}>
          <Table.Column key="title" dataIndex="title" title="" />

          {permission.groupActions?.map(action => (
            <Table.Column
              key={action}
              dataIndex={action}
              align="center"
              width={80}
              title={RESOURCE_ACTIONS_MAP[action]}
              render={(_, record: ParsedPermission) => (
                <PermissionActionColumn
                  value={`${record.name}:${action}`}
                  record={record}
                  action={action}
                  onCheck={onTogglePermission}
                  disabled={disabled}
                  checked={selectedPermission.includes(
                    `${record.name}:${action}`
                  )}
                />
              )}
            />
          ))}
        </Table>
      </div>
    );
  }
);

PermissionsGroup.displayName = 'PermissionsGroup';
