import dayjs from 'dayjs';
import { FaqCoordinatorFilter } from './components/FaqCoordinatorFilter';
import { FaqCoordinatorTable } from './components/FaqCoordinatorTable';
import { HttpError } from '@refinedev/core';
import { IFAQCoordinator } from 'interfaces/faq_coordinators';
import { List, useTable } from '@refinedev/antd';

export interface IFilter {
  title: string;
  course: string;
  status: string;
  userName: string;
  userEmail: string;
  startsAt: dayjs.Dayjs;
  endsAt: dayjs.Dayjs;
}

export const FaqCoordinatorsList = () => {
  const { tableProps, searchFormProps } = useTable<
    IFAQCoordinator,
    HttpError,
    IFilter
  >({
    onSearch: ({
      title,
      course,
      userName,
      userEmail,
      status,
      startsAt,
      endsAt
    }) => {
      return [
        {
          field: 'filter[by_title]',
          operator: 'eq',
          value: title
        },
        {
          field: 'filter[by_grandparent_id]',
          operator: 'eq',
          value: course
        },
        {
          field: 'filter[by_user_name]',
          operator: 'eq',
          value: userName
        },
        {
          field: 'filter[by_user_email]',
          operator: 'eq',
          value: userEmail
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        },
        {
          field: 'filter[created_after]',
          operator: 'eq',
          value: startsAt
        },
        {
          field: 'filter[created_before]',
          operator: 'eq',
          value: endsAt
        }
      ];
    }
  });

  return (
    <List title="Comunicação coordenação">
      <FaqCoordinatorFilter searchFormProps={searchFormProps} />
      <FaqCoordinatorTable tableProps={tableProps} />
    </List>
  );
};
