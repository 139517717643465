import dayjs from 'dayjs';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { DatePicker, Form, Input, Select, Switch } from 'antd';
import { IProduct } from 'interfaces/products';
import { IProductBonus } from 'interfaces/product_bonuses';
import { IUser } from 'interfaces/users';
import { useState } from 'react';

export const BonusCreate = () => {
  const { formProps, saveButtonProps } = useForm<IProductBonus>();
  const { selectProps: userSelectProps } = useSelect<IUser>({
    resource: 'users',
    optionLabel: 'email',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[email]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: productSelectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const [isAllProducts, setIsAllProducts] = useState(false);

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar bônus">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Usuário"
          name="user_id"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...userSelectProps} />
        </Form.Item>
        <Form.Item
          label="Acesso a todos os cursos"
          name="is_full_products"
          initialValue={false}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Switch onChange={setIsAllProducts} />
        </Form.Item>
        <Form.Item
          label="Produto"
          name="product_id"
          rules={[
            {
              required: !isAllProducts
            }
          ]}
          hidden={isAllProducts}
        >
          <Select {...productSelectProps} />
        </Form.Item>
        <Form.Item
          label="Motivo"
          name="motive"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Expiração"
          name="expires_at"
          getValueProps={value => {
            return {
              value: value ? dayjs(value) : ''
            };
          }}
          rules={[
            {
              required: true
            }
          ]}
        >
          <DatePicker />
        </Form.Item>
      </Form>
    </Create>
  );
};
