import { Button, Empty, Pagination, Row, Space, Typography } from 'antd';
import { CreatePostModal } from 'components/modal/forum/CreatePostModal';
import { DeleteModal } from 'components/modal/forum/DeleteModal';
import { DeleteOutlined } from '@ant-design/icons';
import { HybridInput } from './components/HybridInput';
import { IAgent } from 'interfaces/agents';
import { IClassroom } from 'interfaces/classrooms';
import { IForum, IPost } from 'interfaces/forum';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { Post } from './components/Post';
import { Show, TagField, useModal } from '@refinedev/antd';
import { forumStatusOptions } from 'constants/admin/forum';
import { styled } from '@qcx/ui';
import { useCallback, useState } from 'react';
import { useGetIdentity, useList, useShow, useUpdate } from '@refinedev/core';
import { useParams } from 'react-router-dom';

export const TopicShow = () => {
  const { data: user } = useGetIdentity<IAgent>();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteContext, setDeleteContext] = useState({ resource: '', id: '' });
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [editText, setEditText] = useState<boolean>(false);
  const { mutateAsync: editMutation } = useUpdate();
  const { queryResult } = useShow<IForum>();
  const { data, isLoading, refetch } = queryResult;
  const { data: postData, refetch: refetchPosts } = useList<IPost>({
    resource: `forums/${id}/posts`,
    pagination: {
      current: currentPage,
      pageSize: 10,
      mode: 'server'
    }
  });
  const { data: firstPostData } = useList<IPost>({
    resource: `forums/${id}/posts`,
    pagination: {
      current: currentPage,
      pageSize: 1,
      mode: 'server'
    }
  });
  const record = data?.data;
  const postRecords =
    currentPage === 1 ? postData?.data?.slice(1) : postData?.data;

  const refreshData = useCallback(() => {
    refetch();
    refetchPosts();
  }, [refetch, refetchPosts]);

  const {
    show: postCreateModalShow,
    close: postCreateModalClose,
    modalProps: postCreateModalProps
  } = useModal();

  const {
    show: deleteModalShow,
    close: deleteModalClose,
    modalProps: deleteModalProps
  } = useModal();

  const handleEditTitleInput = useCallback(() => {
    setEditTitle(!editTitle);
  }, [editTitle]);

  const handleEditTextInput = useCallback(() => {
    setEditText(!editText);
  }, [editText]);

  const handleDelete = useCallback(
    (resource: string, id: string) => () => {
      setDeleteContext({ resource, id });
      deleteModalShow();
    },
    [deleteModalShow]
  );

  const handleEditText = useCallback(
    (value: string) => {
      if (firstPostData?.data) {
        editMutation({
          resource: `forums/${id}/posts`,
          id: firstPostData?.data?.[0]?.id,
          values: {
            text: value
          },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Resposta editada com sucesso'
          }),
          errorNotification: () => {
            return {
              description: 'Algo deu errado',
              message: 'Não foi possível editar a resposta',
              type: 'error'
            };
          }
        }).then(() => {
          handleEditTextInput();
          refetch();
        });
      }
    },
    [editMutation, handleEditTextInput, firstPostData, id, refetch]
  );

  const handleEditTitle = useCallback(
    (value: string) => {
      if (id) {
        editMutation({
          resource: `forums`,
          id,
          values: {
            title: value
          },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Título editado com sucesso'
          }),
          errorNotification: () => {
            return {
              description: 'Algo deu errado',
              message: 'Não foi possível editar o título',
              type: 'error'
            };
          }
        }).then(() => {
          handleEditTitleInput();
          refetch();
        });
      }
    },
    [editMutation, handleEditTitleInput, refetch, id]
  );

  const getTopicLocal = useCallback(() => {
    if (record?.grandparent_type === 'Classroom') {
      return (
        <StyledParagraph className="!font-light">
          Tópico na turma:{' '}
          {`${(record?.grandparent as IClassroom).code} > ${
            (record?.grandparent as IClassroom)?.discipline?.name
          }`}
        </StyledParagraph>
      );
    }
    return (
      <StyledParagraph>
        Tópico em: {`${(record?.grandparent as IGraduateCourse)?.name}`}
      </StyledParagraph>
    );
  }, [record]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Show
      isLoading={isLoading}
      title="Visualizar tópico"
      headerButtons={
        data &&
        user?.id === data?.data.author_id && (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={handleDelete('forum', data.data.id)}
          >
            Excluir Tópico
          </Button>
        )
      }
      footerButtons={
        <Pagination total={postData?.total} onChange={handlePageChange} />
      }
    >
      <StyledRow className="mb-4">
        <HybridInput
          showInput={editTitle}
          value={record?.title}
          handleState={handleEditTitleInput}
          handleMutation={handleEditTitle}
        >
          <Typography.Title
            level={4}
            style={{ margin: 0, whiteSpace: 'pre-line' }}
          >
            {record?.title}
          </Typography.Title>
        </HybridInput>
        {!editTitle && record?.author_id === user?.id && (
          <Button
            type="text"
            size="small"
            className="ml-1 !text-neutral-500 !font-light"
            onClick={handleEditTitleInput}
          >
            Editar
          </Button>
        )}
      </StyledRow>
      <Space className="mb-4">
        <StyledParagraph className="!font-light">Status:</StyledParagraph>
        <TagField
          color={
            record?.deleted_at
              ? 'red'
              : forumStatusOptions.find(
                  option => option.value === record?.status
                )?.color
          }
          value={
            record?.deleted_at
              ? 'Excluído'
              : forumStatusOptions.find(
                  option => option.value === record?.status
                )?.label
          }
        />
      </Space>
      <StyledParagraph className="!font-light">
        Autor: {record?.author.name}
      </StyledParagraph>
      {getTopicLocal()}
      <Row className="mt-4">
        <HybridInput
          showInput={editText}
          value={firstPostData?.data?.[0]?.text}
          handleState={handleEditTextInput}
          handleMutation={handleEditText}
        >
          <StyledParagraph>{firstPostData?.data?.[0]?.text}</StyledParagraph>
        </HybridInput>
        {!editText && record?.author_id === user?.id && (
          <Button
            type="text"
            size="small"
            className="ml-1 !text-neutral-500 !font-light"
            onClick={handleEditTextInput}
          >
            Editar
          </Button>
        )}
      </Row>
      <Button type="primary" className="mt-4" onClick={postCreateModalShow}>
        Responder
      </Button>
      <Typography.Title level={5} className="mt-8">
        Respostas
      </Typography.Title>
      <Row className="w-full mt-5">
        {postRecords && postRecords?.length < 1 && (
          <Row className="w-full flex justify-center">
            <Empty description="Este forum ainda não possui respostas." />
          </Row>
        )}
        {postRecords?.map(post => (
          <Post
            key={post.id}
            post={post}
            user={user}
            refetch={refetch}
            deleteComment={handleDelete('post', post.id)}
          />
        ))}
      </Row>
      <CreatePostModal
        forumId={id}
        topicTitle={record?.title}
        modalProps={postCreateModalProps}
        modalClose={postCreateModalClose}
        getLocal={getTopicLocal}
        refetch={refetch}
      />
      <DeleteModal
        modalProps={deleteModalProps}
        modalClose={deleteModalClose}
        deleteContext={deleteContext}
        refresh={refreshData}
      />
    </Show>
  );
};

const StyledRow = styled(Row, {
  display: 'flex',
  alignItems: 'center'
});

const StyledParagraph = styled(Typography.Paragraph, {
  marginBottom: '0 !important',
  whiteSpace: 'pre-line'
});
