import FilterForm from 'components/forms/FilterForm';
import { Col, Form, FormProps, Input, Row } from 'antd';
import { getTenant } from 'services/tenants';

export interface IUsersFilterForm {
  name: string;
  email: string;
  cpf: string;
  by_grandparent_type: 'Course' | 'Graduate::Discipline';
  by_grandparent_id: string;
  estacio_id: string;
}

interface UsersFilterFormProps {
  searchFormProps: FormProps<IUsersFilterForm>;
}

export const UsersListFilter = ({ searchFormProps }: UsersFilterFormProps) => {
  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        <Col xs={24} md={6}>
          <Form.Item label="Nome" name="name">
            <Input placeholder="Nome do aluno" />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item label="E-mail" name="email">
            <Input placeholder="e-mail do aluno" />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item label="CPF" name="cpf">
            <Input placeholder="somente números" />
          </Form.Item>
        </Col>
        {getTenant().name === 'Estácio' && (
          <Col xs={24} md={6}>
            <Form.Item label="ID externo" name="estacio_id">
              <Input placeholder="ID externo do aluno" />
            </Form.Item>
          </Col>
        )}
      </Row>
    </FilterForm>
  );
};
