import { Divider, Table, Typography } from 'antd';
import { IEcommerceProfitCenter } from '../../../interfaces/ecommerce_profit_centers';
import { Show, ShowButton } from '@refinedev/antd';
import { useParams } from 'react-router-dom';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const EcommerceProfitCenterShow = () => {
  const { id } = useParams();

  const { queryResult } = useShow<IEcommerceProfitCenter>({
    resource: 'ecommerce/profit_centers',
    id
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    }
  ];

  return (
    <Show isLoading={isLoading} title="Visualizar centro de resultados">
      <Title level={5}>Código</Title>
      <Text>{record?.code}</Text>
      <Title level={5}>Descrição</Title>
      <Text>{record?.description}</Text>
      {record?.division && (
        <>
          <Title level={5}>Divisão</Title>
          <Text className="pr-2">
            {record?.division?.code} - {record?.division?.description}
          </Text>
          <ShowButton
            hideText
            size="small"
            resource="ecommerce/ecommerce_divisions"
            recordItemId={record.division.id}
          />
          <Divider />
          {record?.offers && (
            <>
              <Title level={5}>Ofertas Associadas</Title>
              <Table
                dataSource={record.offers}
                columns={columns}
                size="small"
              />
            </>
          )}
        </>
      )}
    </Show>
  );
};
