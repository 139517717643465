import dayjs from 'dayjs';
import { ForumReportsFilter } from './components/ForumReportsFilter';
import { ForumReportsTable } from './components/ForumReportsTable';
import { HttpError } from '@refinedev/core';
import { IForumReports } from 'interfaces/forum_reports';
import { List, useTable } from '@refinedev/antd';

export interface IFilter {
  moderator: string;
  status: string;
  author: string;
  category: string;
  startsAt: dayjs.Dayjs;
  endsAt: dayjs.Dayjs;
}

export const ForumReportsList = () => {
  const { tableProps, searchFormProps } = useTable<
    IForumReports,
    HttpError,
    IFilter
  >({
    onSearch: ({ moderator, author, category, status, startsAt, endsAt }) => {
      return [
        {
          field: 'filter[by_moderator_name]',
          operator: 'eq',
          value: moderator
        },
        {
          field: 'filter[by_reporter_name]',
          operator: 'eq',
          value: author
        },
        {
          field: 'filter[by_reason]',
          operator: 'eq',
          value: category
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        },
        {
          field: 'filter[created_after]',
          operator: 'eq',
          value: startsAt
        },
        {
          field: 'filter[created_before]',
          operator: 'eq',
          value: endsAt
        }
      ];
    }
  });

  return (
    <List title="Denúncias fórum">
      <ForumReportsFilter searchFormProps={searchFormProps} />
      <ForumReportsTable tableProps={tableProps} />
    </List>
  );
};
