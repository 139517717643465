import FilterForm from 'components/forms/FilterForm';
import { Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IOrderPaymentPlansSummary } from 'interfaces/orders';
import { List, ShowButton, TextField, useTable } from '@refinedev/antd';
import { formatMoney } from 'services/money';

interface IFilter {
  userName: string[];
  userEmail: string[];
  userDocumentInfo: string[];
  orderNumber: string[];
  productableType: string[];
}

export const OrderPaymentPlansList = () => {
  const { tableProps, searchFormProps } = useTable<
    IOrderPaymentPlansSummary,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const {
        userName,
        userEmail,
        userDocumentInfo,
        orderNumber,
        productableType
      } = params;

      filters.push(
        {
          field: 'filter[order][order_number]',
          operator: 'eq',
          value: orderNumber
        },
        {
          field: 'filter[user][email]',
          operator: 'eq',
          value: userEmail
        },
        {
          field: 'filter[user][document_info]',
          operator: 'eq',
          value: userDocumentInfo
        },
        {
          field: 'filter[order][productable_type]',
          operator: 'eq',
          value: productableType
        },
        {
          field: 'filter[user][name]',
          operator: 'eq',
          value: userName
        }
      );

      return filters;
    },

    resource: `payment_plans`,

    pagination: {
      pageSize: 5
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Número do pedido" name="orderNumber">
              <Input placeholder="Somente números" />
            </Form.Item>
            <Form.Item label="E-mail do aluno" name="userEmail">
              <Input placeholder="E-mail do aluno" />
            </Form.Item>
            <Form.Item label="CPF do aluno" name="userDocumentInfo">
              <Input placeholder="Somente números" />
            </Form.Item>
            <Form.Item label="Nome do aluno" name="userName">
              <Input placeholder="Nome do aluno" />
            </Form.Item>
            <Form.Item
              label="Produto"
              name="productableType"
              className="w-[200px]"
            >
              <Select placeholder="Selecione...">
                <Select.Option key="::Graduate::Course">
                  Curso regulado
                </Select.Option>
                <Select.Option key="::Course">Curso avulso</Select.Option>
              </Select>
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex={['user', 'name']}
              key="user_name"
              title="Nome do aluno"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex={['user', 'document_info']}
              key="document_info"
              title="CPF"
              render={value => <TextField value={value || '-'} />}
            />
            <Table.Column
              dataIndex={['user', 'email']}
              key="email"
              title="Email"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex={['user', 'phone_number']}
              key="phone_number"
              title="Telefone"
              render={value => <TextField value={value || '-'} />}
            />
            <Table.Column
              dataIndex={['order', 'number']}
              key="order_number"
              title="Número do pedido"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex={['order', 'debit_amount']}
              key="debit_amout"
              title="Inadimplência"
              render={value => <TextField value={formatMoney(value)} />}
            />
            <Table.Column<IOrderPaymentPlansSummary>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.order.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
