import {
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Row,
  Select,
  SelectProps,
  Switch,
  Typography
} from 'antd';
import { IClassroomEvaluation } from 'interfaces/classrooms';
import { parseToDayjs, replaceTimeZone } from 'services/date';
import { useForm } from '@refinedev/antd';
import { useNotification, useUpdate } from '@refinedev/core';

export interface EvaluationModalProps {
  evaluationResource: string;
  modalClose: () => void;
  modalProps: ModalProps;
  assignmentSelectorProps: SelectProps<{ value: string; label: string }>;
  assesmentSelectorProps: SelectProps<{ value: string; label: string }>;
  evaluationId: string;
}

const EvaluationEditModal = ({
  evaluationResource,
  modalClose,
  modalProps,
  assignmentSelectorProps,
  assesmentSelectorProps,
  evaluationId
}: EvaluationModalProps) => {
  const { mutateAsync: updateEvaluation } = useUpdate();
  const { open } = useNotification();
  const { form, formProps, queryResult } = useForm<IClassroomEvaluation>({
    action: 'edit',
    resource: evaluationResource,
    id: evaluationId
  });
  const type = Form.useWatch('evaluation_type', form);
  const kind = Form.useWatch('kind', form);

  const handleSelectType = () => {
    form.setFieldsValue({ evaluation_id: null });
  };

  const handleInitialData = () => {
    return {
      dateRange: [
        parseToDayjs(
          queryResult?.data?.data?.requirements?.[0]?.rules?.find(
            rule => rule.operator === 'after'
          )?.value
        ),
        parseToDayjs(
          queryResult?.data?.data?.requirements?.[0]?.rules?.find(
            rule => rule.operator === 'before'
          )?.value
        )
      ],
      kind: queryResult?.data?.data?.kind === 'regular' ? false : true
    };
  };

  const handleUpdateEvaluation = async () => {
    return form
      .validateFields()
      .then(() => {
        const [starts_at, ends_at] = form.getFieldValue('dateRange');
        const isFinal = form.getFieldValue('kind');
        updateEvaluation({
          resource: evaluationResource,
          values: {
            ...form.getFieldsValue(),
            starts_at: replaceTimeZone(starts_at),
            ends_at: replaceTimeZone(ends_at),
            kind: isFinal ? 'final' : 'regular'
          },
          id: evaluationId
        }).then(async () => {
          await queryResult
            ?.refetch()
            .then(
              response => (formProps.initialValues = { ...response.data?.data })
            );
          modalClose();
        });
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: 'Favor conferir todos os campos obrigatórios.',
          description: 'Error ao editar registro!'
        });
      });
  };

  return (
    <Modal
      {...modalProps}
      title="Editar avaliação"
      cancelText="Cancelar"
      okText="Editar avaliação"
      className="!w-[1022px]"
      onOk={handleUpdateEvaluation}
      destroyOnClose
    >
      <Form
        form={form}
        {...formProps}
        initialValues={{
          ...queryResult?.data?.data,
          ...handleInitialData()
        }}
        layout="vertical"
      >
        <Typography.Title level={5}>
          Informações gerais da avaliação
        </Typography.Title>

        <Row gutter={[16, 16]}>
          <Col md={8}>
            <Form.Item
              label="Tipo"
              name="evaluation_type"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select onChange={handleSelectType} disabled>
                <Select.Option value="Assessment">Avaliação</Select.Option>
                <Select.Option value="Assignment">Atividade</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Avaliação"
              name="evaluation_id"
              rules={[
                {
                  required: true
                }
              ]}
            >
              {type === 'Assessment' ? (
                <Select
                  notFoundContent={
                    <Empty description="Não há avaliações cadastradas para essa disciplina" />
                  }
                  disabled
                  {...assesmentSelectorProps}
                  style={{ minWidth: 200 }}
                />
              ) : (
                <Select
                  notFoundContent={
                    <Empty description="Não há atividades cadastradas para essa disciplina" />
                  }
                  disabled
                  {...assignmentSelectorProps}
                  style={{ minWidth: 200 }}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {!kind && (
            <Col md={8}>
              <Form.Item
                label="Peso"
                name="weight"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <InputNumber min={0} max={100} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          )}
          <Col md={8}>
            <Form.Item
              label="Nota mínima (0 - 100)"
              name="passing_score"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={0} max={100} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Tentativas"
              name="max_attempts"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={0} max={100} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Typography.Title level={5}>Acesso</Typography.Title>
        </Row>
        <Row>
          <Typography.Paragraph>
            O período de acesso da avaliação deve está entre o inicio e fim da
            turma (dd/mm/aaaa - dd/mm/aaaa)
          </Typography.Paragraph>
        </Row>
        <Row>
          <Col md={20}>
            <Form.Item
              label="Acesso"
              name="dateRange"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <DatePicker.RangePicker
                className="w-full"
                showTime
                placeholder={[
                  'Início (horário de Brasília)',
                  'Término (horário de Brasília)'
                ]}
                format="DD/MM/YYYY HH:mm"
              />
            </Form.Item>
          </Col>
          <Col md={20}>
            <Row className="gap-2">
              <Form.Item
                label="Avaliação de recuperação"
                name="kind"
                valuePropName="checked"
                noStyle
              >
                <Switch disabled />
              </Form.Item>
              <Col>
                <Row className="gap-2">
                  <Typography.Title
                    level={5}
                    style={{
                      color: '#626262',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                  >
                    Avaliação de recuperação
                  </Typography.Title>
                </Row>
                <Row className="gap-2">
                  <Typography.Paragraph
                    style={{
                      fontSize: '14px'
                    }}
                  >
                    Essa avaliação deve acontecer após o termino de todas as
                    outras avaliações
                  </Typography.Paragraph>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EvaluationEditModal;
