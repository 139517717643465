import { Ampli, LoadOptions } from 'ampli';
import { getUserId } from './auth';
import type { Tenant } from 'interfaces/tenants';

export interface TrackerOptions {
  amplitudeOptions: LoadOptions;
  tenant?: Tenant;
  userId?: string;
}

class TrackerClass extends Ampli {
  currentUser?: { id?: string; email?: string };
  public tenant?: Tenant;
  public brand?: string;

  setCurrentUser(id?: string, email?: string) {
    this.currentUser = { id, email };
  }

  setTenant(tenant?: Tenant) {
    this.tenant = tenant;
  }
}

let CustomTracker: TrackerClass;

export type TrackerType = typeof CustomTracker;

export const setupTracker = async (trackerOptions: TrackerOptions) => {
  const isSSR = typeof window === 'undefined';

  if (isSSR) {
    throw new Error('Tracker cannot be started in SSR mode');
  }

  CustomTracker = new TrackerClass();

  await CustomTracker.load(trackerOptions.amplitudeOptions).promise;

  const userId = getUserId();

  if (userId) {
    CustomTracker.setCurrentUser(userId);
    CustomTracker.identify(userId);
  }

  CustomTracker.setTenant(trackerOptions.tenant);
};

export { CustomTracker as Tracker };
