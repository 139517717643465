import * as Icons from '@ant-design/icons';
import { BulkUserSuspend } from 'pages/academic/bulk_user_suspends';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'bulk_user_suspends',
  label: 'Suspender Lote Aluno',
  create: BulkUserSuspend,
  actions: ['create'],
  icon: <Icons.UserOutlined />,
  parentName: 'academic'
};

export default generateResources(resource);
