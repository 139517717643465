import {
  CompaniesList,
  CompanyCreate,
  CompanyEdit,
  CompanyShow
} from 'pages/admin/companies';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'companies',
  label: 'Empresas parceiras',
  list: CompaniesList,
  create: CompanyCreate,
  edit: CompanyEdit,
  show: CompanyShow,
  actions: ['list', 'show', 'create', 'edit'],
  parentName: 'b2b'
};

export default generateResources(resource);
