import React from 'react';
import { Modal, ModalProps, Space, Typography } from 'antd';

interface Props extends ModalProps {
  bodyTitle: string;
  bodyText: string;
  onUpdate?: () => void;
}

const ConfirmationModal: React.FC<Props> = ({
  bodyTitle,
  bodyText,
  ...modalProps
}) => {
  return (
    <Modal {...modalProps}>
      <Space direction="vertical">
        <Typography.Title level={5} style={{ fontWeight: 500, margin: 0 }}>
          {bodyTitle}
        </Typography.Title>
        <Typography.Text title="fdsfds">{bodyText}</Typography.Text>
      </Space>
    </Modal>
  );
};

export default ConfirmationModal;
