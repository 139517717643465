/* eslint-disable unicorn/filename-case */
export const PixIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <g stroke="#000" strokeWidth="1" clipPath="url(#a)">
        <path d="M14.896 14.637a2.559 2.559 0 0 1-1.817-.752l-2.625-2.625a.5.5 0 0 0-.689 0L7.13 13.895a2.556 2.556 0 0 1-1.817.753h-.518l3.325 3.325a2.659 2.659 0 0 0 3.759 0l3.334-3.335h-.317ZM5.313 5.352c.687 0 1.332.268 1.817.753L9.765 8.74a.488.488 0 0 0 .69 0l2.625-2.625a2.555 2.555 0 0 1 1.816-.753h.316L11.88 2.03a2.659 2.659 0 0 0-3.759 0L4.795 5.354l.518-.002Z" />
        <path d="m17.971 8.12-2.015-2.015a.384.384 0 0 1-.142.029h-.916c-.474 0-.938.192-1.272.527l-2.625 2.625a1.256 1.256 0 0 1-1.781 0L6.585 6.65a1.81 1.81 0 0 0-1.273-.528H4.187a.383.383 0 0 1-.136-.026L2.03 8.12a2.66 2.66 0 0 0 0 3.76l2.022 2.022a.381.381 0 0 1 .135-.027h1.126c.475 0 .938-.191 1.273-.526l2.634-2.636a1.292 1.292 0 0 1 1.782 0l2.625 2.625c.334.335.798.526 1.272.526h.916c.05 0 .098.012.142.03l2.015-2.015a2.659 2.659 0 0 0 0-3.759Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
