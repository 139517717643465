import * as Icons from '@ant-design/icons';
import axios from 'axios';
import { Button, Progress, Upload, UploadFile } from 'antd';
import { useCreate } from '@refinedev/core';
import { useEffect, useState } from 'react';

type File = undefined | UploadFile;

type ContentType = 'video' | 'audio' | 'document' | 'zip';

export interface LibraryContentUploadProps {
  accept: string | undefined;
  type: ContentType;
  onChange?: (path: string | undefined) => void;
  onUpload?: (path: string) => void;
}

interface Content {
  id: string;
  file_name: string;
  path: string;
  upload_url: string;
  download_url: string;
  type: ContentType;
}

const LibraryContentUpload = ({
  accept,
  type,
  onChange,
  onUpload
}: LibraryContentUploadProps) => {
  const [file, setFile] = useState<File | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { mutate } = useCreate<Content>();

  const createContent = () =>
    new Promise<Content>((resolve, reject) => {
      mutate(
        {
          resource: 'prometheus/content_files',
          values: { file_name: file?.name, type: type }
        },
        {
          onSuccess: ({ data }) => resolve(data),
          onError: error => reject(error)
        }
      );
    });

  const upload = async () => {
    try {
      const { path, upload_url } = await createContent();

      setUploadPercentage(0);
      setIsUploading(true);

      await axios.put(upload_url, file, {
        onUploadProgress: ({ loaded, total }) => {
          setUploadPercentage(Math.round((100 * loaded) / total));
        }
      });
      onChange?.(path);
      onUpload?.(path);
    } catch {
      setIsUploading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    if (file) {
      upload();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <div className="flex flex-col gap-4 w-64">
      <div className="!flex">
        <Upload
          className="w-full"
          maxCount={1}
          accept={accept}
          fileList={[]}
          onRemove={() => setFile(null)}
          beforeUpload={newFile => {
            setFile(newFile);

            return false;
          }}
        >
          <Button
            className="!flex items-center justify-center w-full"
            disabled={isLoading}
          >
            <Icons.UploadOutlined />
            Subir conteúdo
          </Button>
        </Upload>
        {isUploading && (
          <div className="w-full flex items-center">
            <Progress percent={uploadPercentage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LibraryContentUpload;
