import {
  ProductSetCreate,
  ProductSetEdit,
  ProductSetList
} from 'pages/ecommerce/product_sets';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'products/sets',
  label: 'Sets',
  list: ProductSetList,
  create: ProductSetCreate,
  edit: ProductSetEdit,
  actions: ['list', 'edit', 'create'],
  parentName: 'ecommerce'
};

export default generateResources(resource);
