import AssetUpload from 'components/AssetUpload';
import ImageSpecifications from 'components/ImageSpecifications';
import SlugInput from '../../../components/form-items/SlugInput';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { IInstructor } from 'interfaces/instructors';
import { applyImageSizeRestriction } from 'utils/apply-image-size-restriction';
import { shouldApplyImageRestrictions } from 'utils/should-apply-image-restrictions';

export const InstructorCreate = () => {
  const { formProps, saveButtonProps } = useForm<IInstructor>();

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar instrutor">
      <Form {...formProps} layout="vertical">
        <ImageSpecifications
          title="Especificações Avatar"
          size="200kb"
          format="jpeg/jpg/png"
        />
        <Form.Item
          label={!shouldApplyImageRestrictions() ? 'Avatar' : ''}
          name="avatar"
          rules={[
            {
              required: true
            }
          ]}
        >
          <AssetUpload
            accept="image/png, image/jpeg"
            type="instructor"
            maxSize={applyImageSizeRestriction('avatar')}
          />
        </Form.Item>
        <SlugInput />
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Cabeçalho"
          name="headline"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Biografia"
          name="bio"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Create>
  );
};
