import { TOfferStatus } from '../../interfaces/offers';
import { TagField } from '@refinedev/antd';

export const OFFER_STATUS_MAP = {
  disabled: { color: 'red', label: 'Desabilitada' },
  expired: { color: 'orange', label: 'Expirada' },
  active: { color: 'green', label: 'Ativa' },
  hidden: { color: 'blue', label: 'Escondida e ativa' },
  default: { color: 'gray', label: '-' }
};

export const OfferStatus = ({
  status,
  borderless
}: {
  status: TOfferStatus;
  borderless?: boolean;
}) => {
  const { color, label } = OFFER_STATUS_MAP[status] || OFFER_STATUS_MAP.default;

  return (
    <TagField
      color={color}
      value={label}
      style={{ border: borderless ? 0 : undefined }}
    />
  );
};
