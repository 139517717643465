import FilterForm from '../../../components/forms/FilterForm';
import { Col, Form, Input, Row, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { ILogDeletedUser } from 'interfaces/log_deleted_users';
import { List, TextField, useTable } from '@refinedev/antd';
import { formatDate } from 'services/date';

interface IFilter {
  email: string;
  deleted_at: string;
}

export const LogDeletedUserList = () => {
  const { tableProps, searchFormProps } = useTable<
    ILogDeletedUser,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { email } = params;

      filters.push({
        field: 'filter[email]',
        operator: 'eq',
        value: email
      });

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="E-mail" name="email">
              <Input placeholder="e-mail do aluno" />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="email"
              key="email"
              title="E-mail"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="text"
              key="text"
              title="Motivo"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="deleted_at"
              key="deleted_at"
              title="Data de exclusão"
              render={value => <TextField value={formatDate(value)} />}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
