import { ProductableType } from 'interfaces/products';

interface ProductableTypesProps {
  label: string;
  value: ProductableType;
}

export const PRODUCTABLE_TYPES: ProductableTypesProps[] = [
  {
    label: 'Curso livre',
    value: 'Course'
  },
  {
    label: 'Curso regulado',
    value: 'Graduate::Course'
  },
  {
    label: 'Set',
    value: 'Products::Set'
  },
  {
    label: 'Combo',
    value: 'Products::Bundle'
  }
];
