import {
  ProductCategoryCreate,
  ProductCategoryEdit,
  ProductCategoryList,
  ProductCategoryShow
} from 'pages/ecommerce/product_categories';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'products/categories',
  label: 'Categorias',
  list: ProductCategoryList,
  create: ProductCategoryCreate,
  edit: ProductCategoryEdit,
  show: ProductCategoryShow,
  actions: ['list', 'show', 'edit', 'create', 'delete', 'slug'],
  parentName: 'ecommerce'
};

export default generateResources(resource);
