import EquivalencyForm from './EquivalencyForm';
import { AcademicRecordInfo } from './AcademicRecordInfo';
import {
  Button,
  Form,
  FormInstance,
  Select,
  Steps,
  Table,
  Typography
} from 'antd';
import { IAcademicRecord } from '../../../../interfaces/academic_record';
import { IGraduateCourseEnrollment } from '../../../../interfaces/graduate_course_enrollments';
import { StudentInfo } from './StudentInfo';
import { TagField, TextField, useSelect } from '@refinedev/antd';
import { formatDate } from '../../../../services/date';
import { getAPI } from '../../../../requests/api';
import { tagColorByStatus } from '../../../../utils/statusColorMapping';
import {
  translateClassroomEnrollmentStatus,
  translateEquivalencyStatus
} from '../index';
import { useEffect, useState } from 'react';

const { Step } = Steps;

export const SearchAcademicRecord = ({
  onChange,
  form
}: {
  onChange: (num: number) => void;
  form: FormInstance;
}) => {
  const [selectedEnrollment, setSelectedEnrollment] = useState<string>('');
  const [academicRecord, setAcademicRecord] = useState<IAcademicRecord[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedAcademicRecord, setSelectedAcademicRecord] = useState<
    any | null
  >(null);

  const { selectProps, queryResult } = useSelect<IGraduateCourseEnrollment>({
    resource: 'graduate/courses/enrollments',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[by_user_email]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const customLabel = (enrollment: IGraduateCourseEnrollment) => {
    return (
      <div className="flex flex-col justify-center">
        <Typography.Text strong>{enrollment.user.name} </Typography.Text>
        <div className="flex gap-2 grow-0 my-2">
          <Typography className="text-xs">
            {`📧 ${enrollment.user.email}`}{' '}
          </Typography>
          <Typography className="text-xs">
            {`📚 ${enrollment.course.name}`}{' '}
          </Typography>
        </div>
      </div>
    );
  };

  const enrollmentOptions = queryResult.data?.data.map(enrollment => ({
    label: customLabel(enrollment),
    value: enrollment.id
  }));

  const columns = [
    {
      dataIndex: ['discipline', 'name'],
      key: 'discipline_name',
      title: 'Dados da Disciplina',
      render: (value: string, record: IAcademicRecord) => (
        <>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Disciplina: </Typography.Text>
            <TextField strong value={value} />
          </div>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Carga Horária: </Typography.Text>
            <TextField
              value={`${Math.floor(
                record.discipline.duration_in_minutes / 60
              )} horas`}
            />
          </div>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Curso: </Typography.Text>
            <TextField value={record.course_enrollment.course.name} />
          </div>
        </>
      )
    },
    {
      dataIndex: ['parent', 'classroom', 'code'],
      key: 'classroom',
      title: 'Dados da Turma',
      render: (value: string, record: IAcademicRecord) => (
        <>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Código da turma: </Typography.Text>
            <TextField value={value} />
          </div>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Descrição da turma: </Typography.Text>
            <TextField value={record.parent.classroom.description} />
          </div>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Nota miníma: </Typography.Text>
            <TextField value={record.parent.classroom.passing_score} />
          </div>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Status da turma: </Typography.Text>
            <TagField
              color={tagColorByStatus(record.parent.classroom.status)}
              value={translateClassroomEnrollmentStatus(
                record.parent.classroom.status
              )}
            />
          </div>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Início em: </Typography.Text>
            <TextField
              value={
                record.parent.classroom.starts_at
                  ? formatDate(record.parent.classroom.starts_at)
                  : '-'
              }
            />
          </div>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Finalizada em: </Typography.Text>
            <TextField
              value={
                record.parent.classroom.ends_at
                  ? formatDate(record.parent.classroom.ends_at)
                  : '-'
              }
            />
          </div>
        </>
      )
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Situação do Aluno',
      render: (value: string, record: IAcademicRecord) => (
        <>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Nota: </Typography.Text>
            <TextField value={record.score} />
          </div>
          <div style={{ fontSize: 'smaller' }}>
            <Typography.Text strong>Situação: </Typography.Text>
            <TagField
              color={tagColorByStatus(record.status)}
              value={translateEquivalencyStatus(record.status)}
            />
          </div>
        </>
      )
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Ações',
      render: (_: any, record: IAcademicRecord) => (
        <Button
          size="small"
          onClick={() => handleAction(record)}
          disabled={!(record.status == 'approved')}
        >
          Selecionar Disciplina
        </Button>
      )
    }
  ];

  const handleAction = async (record: IAcademicRecord) => {
    try {
      setShowForm(true);
      setCurrentStep(1);
      const response = await getAPI().get(
        `admin/graduate/academic_records/${record.id}`
      );
      setSelectedAcademicRecord(response.data);
      onChange(1);
    } catch (error) {
      console.error('Error fetching selected enrollment:', error);
    }
  };

  const findAcademicRecord = async (value: string) => {
    try {
      const response = await getAPI().get(
        `admin/graduate/academic_records?filter[by_course_enrollment_id]=${value}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching academic record:', error);
      return [];
    }
  };

  useEffect(() => {
    if (selectedEnrollment && selectedEnrollment !== '') {
      const fetchData = async () => {
        const academicRecord = await findAcademicRecord(selectedEnrollment);
        setAcademicRecord(academicRecord);
      };
      fetchData();
    }
  }, [selectedEnrollment]);

  const filteredAcademicRecord = academicRecord.filter(
    record => record.parent_type === 'Classrooms::Enrollment'
  );

  return (
    <>
      <Steps current={currentStep} size="default">
        <Step
          title="Selecione a disciplina de origem do aluno"
          style={{ height: '50px' }}
        />
        <Step title="Selecione a disciplina de destino" />
      </Steps>

      {currentStep === 0 && (
        <div>
          <Form.Item label="Buscar Históricos Escolares" name="enrollment_id">
            <Select
              allowClear
              {...selectProps}
              options={enrollmentOptions}
              placeholder="Digite o e-mail do aluno..."
              onChange={value =>
                setSelectedEnrollment(value as unknown as string)
              }
            />
          </Form.Item>
        </div>
      )}

      {currentStep === 1 && <div></div>}

      {showForm ? (
        <div>
          {selectedAcademicRecord && (
            <>
              <StudentInfo
                user={selectedAcademicRecord?.course_enrollment.user}
                course={selectedAcademicRecord?.course_enrollment.course}
              />
              <AcademicRecordInfo academic_record={selectedAcademicRecord} />
              <EquivalencyForm
                user={selectedAcademicRecord?.course_enrollment.user}
                academic_record={selectedAcademicRecord}
                form={form}
              />
            </>
          )}
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={filteredAcademicRecord}
          rowKey="id"
        />
      )}
    </>
  );
};
