import CreateAudioModal from './modals/CreateAudioModal';
import CreateDocumentModal from './modals/CreateDocumentModal';
import CreateVideoModal from './modals/CreateVideoModal';
import {
  AudioOutlined,
  DownOutlined,
  FileTextOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import { MenuProps } from 'antd/lib';
import { useModal } from '@refinedev/core';

interface ContentCreateDropdownProps {
  onFinish?: () => void;
  libraryId: string;
}

export const ContentCreateDropdown = ({
  onFinish,
  libraryId
}: ContentCreateDropdownProps) => {
  const createDocumentModal = useModal();
  const createAudioModal = useModal();
  const createVideoModal = useModal();

  const handleMenuClick: MenuProps['onClick'] = e => {
    const modals = [createDocumentModal, createAudioModal, createVideoModal];

    for (const modal of modals) {
      modal.close();
    }

    switch (e.key) {
      case 'document': {
        createDocumentModal.show();

        break;
      }

      case 'audio': {
        createAudioModal.show();

        break;
      }

      case 'video': {
        createVideoModal.show();

        break;
      }
      // No default
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Áudio',
      key: 'audio',
      icon: <AudioOutlined />
    },
    {
      label: 'Vídeo',
      key: 'video',
      icon: <VideoCameraOutlined />
    },
    {
      label: 'Documento',
      key: 'document',
      icon: <FileTextOutlined />
    }
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  return (
    <>
      <div style={{ paddingBottom: 16 }}>
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              Adicionar conteúdo
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>

      <CreateDocumentModal
        modal={createDocumentModal}
        libraryId={libraryId}
        onFinish={onFinish}
      />
      <CreateAudioModal
        modal={createAudioModal}
        libraryId={libraryId}
        onFinish={onFinish}
      />
      <CreateVideoModal
        modal={createVideoModal}
        libraryId={libraryId}
        onFinish={onFinish}
      />
    </>
  );
};
