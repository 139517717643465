import FilterForm from 'components/forms/FilterForm';
import { Form, Input, Select, Table, Typography } from 'antd';
import { HttpError } from '@refinedev/core';
import { IClassroom } from 'interfaces/classrooms';
import { IGraduateDiscipline } from 'interfaces/graduate_disciplines';
import { TextField, useSelect, useTable } from '@refinedev/antd';
import { useParams } from 'react-router-dom';

interface IFilter {
  code: string;
  disciplineId: string;
}

const ClassroomList = () => {
  const { id } = useParams();
  const { tableProps, searchFormProps } = useTable<
    IClassroom,
    HttpError,
    IFilter
  >({
    resource: `classrooms/professors`,

    onSearch: ({ code, disciplineId }) => {
      return [
        {
          field: 'filter[by_classroom_code]',
          operator: 'eq',
          value: code
        },
        {
          field: 'filter[by_discipline_id]',
          operator: 'eq',
          value: disciplineId
        },
        {
          field: 'filter[by_professor_id]',
          operator: 'eq',
          value: id
        }
      ];
    },

    filters: {
      initial: [
        {
          field: 'filter[by_professor_id]',
          operator: 'eq',
          value: id
        }
      ]
    }
  });
  const { selectProps: disciplineSelectProps } = useSelect<IGraduateDiscipline>(
    {
      resource: 'graduate_disciplines',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server',
        mode: 'server'
      }
    }
  );

  return (
    <>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Turmas
      </Typography.Title>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item
          colon
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
          label="Disciplina"
          name="disciplineId"
        >
          <Select
            allowClear
            {...disciplineSelectProps}
            style={{ minWidth: 250 }}
            placeholder="Selecione a disciplina"
          />
        </Form.Item>
        <Form.Item label="Código da turma" name="code">
          <Input
            style={{ minWidth: 250 }}
            placeholder="Informe o código da turma"
          />
        </Form.Item>
      </FilterForm>
      <Table {...tableProps} rowKey="id">
        <Table.Column<IClassroom>
          dataIndex={['classroom', 'discipline']}
          key="classroom"
          title="Disciplina"
          render={value => <TextField value={value.name} />}
        />
        <Table.Column
          dataIndex={['classroom', 'code']}
          key="code"
          title="Código da turma"
          render={value => <TextField value={value} />}
        />
      </Table>
    </>
  );
};

export default ClassroomList;
