import { dealIcon } from '../assets/icons/deal';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'deal',
  label: 'Planos de pagamento',
  icon: dealIcon
};

export default generateResources(resource);
