/* eslint-disable unicorn/filename-case */
import { ParsedPermission } from 'services/permissions';
import { useCallback, useEffect, useState } from 'react';

export const usePermissions = (
  permissions: string[],
  permissionsList: ParsedPermission[],
  disabled?: boolean,
  onChange?: (permissions: string[]) => void
) => {
  const [selectedPermission, setSelectedPermission] = useState(permissions);

  useEffect(() => {
    if (!disabled) {
      onChange?.(selectedPermission);
    }
  }, [onChange, selectedPermission, disabled]);

  // Utility to add actions for a given set of nodes
  const addActionsFromNodes = (
    nodes: ParsedPermission[],
    expandedPermissions: Set<string>
  ) => {
    for (const node of nodes) {
      if (node.actions) {
        for (const action of node.actions) {
          expandedPermissions.add(`${node.name}:${action}`);
        }
      }
    }
  };

  // Utility to expand permissions for a single resource
  const expandResourcePermissions = (
    resource: string,
    permissionsList: ParsedPermission[],
    expandedPermissions: Set<string>
  ) => {
    for (const list of permissionsList) {
      // Add child actions if the resource matches the list
      if (list.name === resource && list.children) {
        addActionsFromNodes(list.children, expandedPermissions);
      }

      // Add parent actions if the resource matches a child
      const parent = list.children?.find(child => child.name === resource);

      if (parent?.actions) {
        for (const action of parent.actions) {
          expandedPermissions.add(`${resource}:${action}`);
        }
      }
    }
  };

  const expandPermissions = useCallback(() => {
    const expandedPermissions = new Set<string>();

    for (const permission of permissions) {
      // If it's not a wildcard permission, add it directly
      if (!permission.includes('*')) {
        expandedPermissions.add(permission);
        continue;
      }

      // Extract the resource part and expand its permissions
      const [resource] = permission.split(':');
      expandResourcePermissions(resource, permissionsList, expandedPermissions);
    }

    setSelectedPermission([...expandedPermissions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, permissionsList]);

  useEffect(() => {
    expandPermissions();
  }, [expandPermissions]);

  const getChildPermissions = useCallback(
    (parentName: string) =>
      permissionsList
        .find(node => node.name === parentName)
        ?.children?.flatMap(
          child => child.actions?.map(action => `${child.name}:${action}`) || []
        ) || [],
    [permissionsList]
  );

  const toggleAllPermissions = useCallback(
    (name: string, checked: boolean) => {
      setSelectedPermission(prev => {
        const childPermissions = new Set(getChildPermissions(name));
        const updatedPermissions = new Set(prev);

        if (checked) {
          updatedPermissions.add(`${name}:*`);

          for (const permission of childPermissions) {
            updatedPermissions.add(permission);
          }
        } else {
          updatedPermissions.delete(`${name}:*`);

          for (const permission of childPermissions) {
            updatedPermissions.delete(permission);
          }
        }

        return [...updatedPermissions];
      });
    },
    [getChildPermissions]
  );

  const togglePermission = useCallback((permission: string, parent: string) => {
    setSelectedPermission(prev => {
      const updatedPermissions = new Set(prev);
      const [baseName] = permission.split(':');

      if (prev.includes(permission)) {
        updatedPermissions.delete(permission);
        updatedPermissions.delete(`${parent}:*`);
        updatedPermissions.delete(`${baseName}:*`);
      } else {
        updatedPermissions.add(permission);
      }

      return [...updatedPermissions];
    });
  }, []);

  return {
    selectedPermission,
    toggleAllPermissions,
    togglePermission
  };
};
