import React, { useState } from 'react';
import { Col, Form, Row } from 'antd';
import { Create, useForm } from '@refinedev/antd';
import { IAcademicRecordEquivalency } from 'interfaces/academic_record_equivalencies';
import { SearchAcademicRecord } from './components/SearchAcademicRecord';

export const AcademicRecordEquivalencyCreate = () => {
  const { formProps, saveButtonProps, form } =
    useForm<IAcademicRecordEquivalency>({
      successNotification: () => ({
        type: 'success',
        message: 'Equivalência de disciplinas criada com sucesso',
        description: 'Histórico escolar criado com sucesso'
      }),
      errorNotification: () => ({
        type: 'error',
        message: 'Erro ao criar equivalência de disciplinas',
        description: 'Erro'
      })
    });

  const [step, setStep] = useState(0);

  return (
    <Create
      saveButtonProps={{ ...saveButtonProps, disabled: step === 0 }}
      title="Criar Equivalência Interna de Histórico Acadêmico"
    >
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <SearchAcademicRecord onChange={setStep} form={form} />
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
