import Details from 'components/videos-management/Details';
import { IDamVideo } from 'interfaces/dam_videos';
import { Show, useForm } from '@refinedev/antd';
import { useShow } from '@refinedev/core';

export const VideoShow = () => {
  const { id } = useForm<IDamVideo>();

  const { queryResult } = useShow<IDamVideo>({
    resource: 'prometheus/videos',
    id: id!
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title="Visualizar vídeo">
      {record && <Details video={record} show />}
    </Show>
  );
};
