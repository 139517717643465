import { CrudFilters, HttpError, useShow } from '@refinedev/core';
import { IAssignment, IAssignmentUser } from 'interfaces/assignments';
import {
  IShowAssignmentFilter,
  ShowAssignmentsFilter
} from './components/show-filter';
import { Show, useTable } from '@refinedev/antd';
import { ShowAssignmentsTable } from './components/show-table';
import { Typography } from 'antd';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

export const AssignmentShow = () => {
  const { queryResult } = useShow<IAssignment>();
  const { id } = useParams();

  const data = useMemo(() => queryResult.data?.data, [queryResult.data?.data]);

  const { tableProps, searchFormProps } = useTable<
    IAssignmentUser[],
    HttpError,
    IShowAssignmentFilter
  >({
    resource: `assignments/${id}/users`,

    onSearch: params => {
      const filters: CrudFilters = [];
      const { search_email, search_name } = params;

      filters.push(
        {
          field: 'filter[search_email]',
          operator: 'eq',
          value: search_name
        },
        {
          field: 'filter[search_email]',
          operator: 'eq',
          value: search_email
        }
      );

      return filters;
    },

    errorNotification: () => {
      return {
        message: 'Não foi possível carregar a lista de alunos',
        description: 'Erro',
        type: 'error'
      };
    }
  });

  return (
    <Show title="Visualizar atividade">
      <div className="mb-4">
        <Title level={4} className="block !mb-0">
          Tarefa: {data?.name}
        </Title>
        <Text>
          {data?.parent_type === 'Course' ? 'Curso:' : 'Disciplina'}{' '}
          {data?.parent.name}
        </Text>
      </div>

      <ShowAssignmentsFilter searchFormProps={searchFormProps} />

      {data?.id && (
        <ShowAssignmentsTable tableProps={tableProps} assignmentId={data.id} />
      )}
    </Show>
  );
};
