import AssetUpload from 'components/AssetUpload';
import ImageSpecifications from 'components/ImageSpecifications';
import SlugInput from '../../../components/form-items/SlugInput';
import useParentCategoriesSelect from '../../../hooks/useParentCategoriesSelect';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IProductCategory } from 'interfaces/product_categories';
import { applyImageSizeRestriction } from 'utils/apply-image-size-restriction';
import { shouldApplyImageRestrictions } from 'utils/should-apply-image-restrictions';

export const ProductCategoryCreate = () => {
  const { formProps, saveButtonProps } = useForm<IProductCategory>();
  return (
    <Create saveButtonProps={saveButtonProps} title="Criar categoria">
      <Form {...formProps} layout="vertical">
        <ImageSpecifications
          title="Especificações Card"
          size="500kb"
          format="jpeg/jpg/png"
        />
        <Form.Item
          label={!shouldApplyImageRestrictions() ? 'Card' : ''}
          name={['media', 'card_image']}
        >
          <AssetUpload
            accept="image/png, image/jpeg"
            type="product_category"
            maxSize={applyImageSizeRestriction('card')}
          />
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <SlugInput />
        <Form.Item
          label="Nome Curto"
          name="short_name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          className="w-1/2"
          label="Categoria pai"
          name="parent_category_id"
        >
          <Select
            allowClear
            onDeselect={() =>
              formProps.form?.setFieldsValue({
                parent_category_id: null
              })
            }
            {...useParentCategoriesSelect({})}
            placeholder="Caso haja, selecione a categoria pai"
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
