import * as Icons from '@ant-design/icons';
import { Button, Modal, Space, Tag, Typography } from 'antd';
import { IClassroom } from 'interfaces/classrooms';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { IReportShow } from 'interfaces/reports';
import { REPORT_REASON_MAP } from 'services/report';
import { Show } from '@refinedev/antd';
import { acceptForumReport, rejectForumReport } from 'requests/reports';
import { formatDate } from 'services/date';
import { reportStatusMap } from 'services/forums';
import { useCallback, useState } from 'react';
import { useNotification, useShow } from '@refinedev/core';

const { Text } = Typography;

export const ForumReportsShow = () => {
  const { queryResult } = useShow<IReportShow>();
  const { data, isLoading, refetch } = queryResult;
  const report = data?.data as IReportShow;

  const [loading, setLoading] = useState(false);

  const notification = useNotification();
  const [modal, contextHolder] = Modal.useModal();

  const showConfirmApproveReport = () => {
    modal.confirm({
      title: `Excluir ${
        report.resource_type === 'Post' ? 'resposta' : 'tópico'
      }?`,
      content: 'Essa ação não pode ser revertida',
      closable: true,
      maskClosable: true,
      icon: <Icons.ExclamationCircleOutlined style={{ color: 'red' }} />,
      okText: 'Aprovar denúncia',
      okButtonProps: {
        danger: true
      },
      cancelText: 'Voltar',
      autoFocusButton: null,
      onOk() {
        return handleApproveReport();
      }
    });
  };

  const showConfirmRejectReport = () => {
    modal.confirm({
      title: 'Rejeitar denuncia?',
      content: 'Ao confirmar essa ação, a denúncia será arquivada.',
      closable: true,
      maskClosable: true,
      icon: <Icons.ExclamationCircleOutlined style={{ color: 'red' }} />,
      okText: 'Rejeitar denúncia',
      cancelText: 'Voltar',
      autoFocusButton: null,
      onOk() {
        return handleRejectReport();
      }
    });
  };

  const handleApproveReport = useCallback(async () => {
    try {
      setLoading(true);
      await acceptForumReport(report.id);
      notification.open?.({
        message: 'Denúncia aprovada com sucesso.',
        type: 'success',
        description: 'Sucesso!'
      });
    } catch {
      notification.open?.({
        message: 'Ops! Não foi possível aprovar esta denúncia. Tente novamente',
        type: 'error',
        description: 'Erro!'
      });
    } finally {
      setLoading(false);
      refetch();
    }
  }, [notification, report, refetch]);

  const handleRejectReport = useCallback(async () => {
    try {
      setLoading(true);
      await rejectForumReport(report.id);
      notification.open?.({
        message: 'Denúncia rejeitada com sucesso.',
        type: 'success',
        description: 'Sucesso!'
      });
    } catch {
      notification.open?.({
        message:
          'Ops! Não foi possível rejeitar esta denúncia. Tente novamente',
        type: 'error',
        description: 'Erro!'
      });
    } finally {
      setLoading(false);
      refetch();
    }
  }, [notification, refetch, report]);

  const getTopicLocal = useCallback(() => {
    if (!report?.resource) {
      return null;
    }
    const resource =
      'forum' in report.resource ? report.resource?.forum : report.resource;

    if (resource && 'grandparent_type' in resource) {
      if (resource.grandparent_type === 'Classroom') {
        return (
          <Text>
            {`${(resource.grandparent as IClassroom).code} > ${
              (resource.grandparent as IClassroom)?.discipline?.name
            }`}
          </Text>
        );
      }
      return <Text>{(resource.grandparent as IGraduateCourse)?.name}</Text>;
    }
  }, [report]);

  if (!report?.resource) {
    return null;
  }

  return (
    <Show isLoading={isLoading} title="Visualizar denúncia">
      {report && (
        <>
          <div className="flex justify-between w-full mb-4">
            <div>
              <Text strong className="block">
                ID
              </Text>
              <Text>{report.id}</Text>
            </div>

            <div>
              <Text strong className="block">
                Data da denúncia
              </Text>
              <Text>{formatDate(report.created_at)}</Text>
            </div>

            <div>
              <Text strong className="block">
                Última atualização
              </Text>
              <Text>{formatDate(report.updated_at)}</Text>
            </div>

            <div>
              <Text strong className="block">
                Status
              </Text>
              <Tag color={reportStatusMap[report.status].color}>
                {reportStatusMap[report.status].label}
              </Tag>
            </div>
          </div>

          <div className="border p-4 rounded-md mb-8">
            <Space direction="vertical">
              <Space>
                <Text>Autor da denúncia:</Text>
                <Text>{report?.reporter?.name ?? '--'}</Text>
              </Space>
              <Space>
                <Text>E-mail do autor:</Text>
                <Text>{report?.reporter?.email ?? '--'}</Text>
              </Space>
              <Space>
                <Text>Moderador:</Text>
                <Text>{report.moderator?.name || '--'}</Text>
              </Space>
              <Space>
                <Text>Data da moderação:</Text>
                <Text>
                  {report.status === 'pending'
                    ? '--'
                    : formatDate(report.updated_at)}
                </Text>
              </Space>
              <Space>
                <Text>Categoria:</Text>
                <Text>{REPORT_REASON_MAP[report.reason]}</Text>
              </Space>
              <Space direction="vertical">
                <Text>Texto da denúncia:</Text>
                <div className="bg-gray-50 p-3 rounded-md mt-1 block">
                  <Text>{report.text}</Text>
                </div>
              </Space>
            </Space>

            <div className="mt-4">
              <Text strong className="block mb-2">
                {report.resource_type === 'Post'
                  ? 'Resposta denunciada'
                  : 'Tópico denunciado'}
              </Text>

              <div className="border py-4 px-3 rounded-md">
                <Space direction="vertical" className="gap-2">
                  <Space>
                    <Text>
                      Autor{' '}
                      {report.resource_type === 'Post'
                        ? 'da resposta'
                        : 'do tópico'}
                      :
                    </Text>
                    <Text>{report.resource?.author.name}</Text>
                  </Space>
                  <Space>
                    <Text>E-mail do autor:</Text>
                    <Text>{report.resource.author.email}</Text>
                  </Space>
                  <Space>
                    <Text>Data:</Text>
                    <Text>
                      {report?.resource?.created_at
                        ? formatDate(
                            report?.resource?.created_at,
                            'DD/MM/YYYY à[s] HH:mm'
                          )
                        : '--'}
                    </Text>
                  </Space>
                  <Space>
                    <Text>
                      {report.resource_type === 'Post' ? 'Resposta' : 'Tópico'}{' '}
                      em:
                    </Text>
                    <Text>{getTopicLocal()}</Text>
                  </Space>
                  {report.resource_type === 'Thread' &&
                    'title' in report?.resource && (
                      <Space>
                        <Text>Título do tópico:</Text>
                        <Text>{report?.resource?.title}</Text>
                      </Space>
                    )}
                  {report.resource_type === 'Post' &&
                    'forum_id' in report?.resource && (
                      <Space>
                        <Text>Tópico relacionado:</Text>
                        <Text>{report?.resource?.forum_id}</Text>
                      </Space>
                    )}
                  <Space direction="vertical">
                    <Text>
                      Texto{' '}
                      {report.resource_type === 'Post'
                        ? 'da resposta'
                        : 'do tópico'}
                      :
                    </Text>
                    <div className="bg-gray-50 p-3 rounded-md mt-1 block">
                      {'text' in report.resource ? (
                        <Text>{report?.resource?.text}</Text>
                      ) : (
                        <Text>{report?.resource?.title}</Text>
                      )}
                    </div>
                  </Space>
                </Space>
              </div>
            </div>

            {report.status === 'pending' && (
              <div className="flex flex-wrap md:justify-end mt-6 gap-4">
                <Button
                  danger
                  icon={<Icons.InboxOutlined />}
                  onClick={showConfirmRejectReport}
                  loading={loading}
                >
                  Rejeitar denúncia
                </Button>
                <Button
                  danger
                  type="primary"
                  icon={<Icons.ExclamationCircleOutlined />}
                  onClick={showConfirmApproveReport}
                  loading={loading}
                >
                  Aprovar denúncia
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      {contextHolder}
    </Show>
  );
};
