const ACADEMIC_RECORD_STATUSES_MAP = new Map([
  ['approved', 'Aprovada'],
  ['failed', 'Reprovada'],
  ['exempted', 'Isenta']
]);

const ACADEMIC_RECORD_EVENTS_TRANSLATIONS = new Map<string, string>([
  ['create', 'Criado'],
  ['update', 'Atualizado'],
  ['delete', 'Deletado']
]);

export const translateAcademicRecordStatus = (status: string) => {
  return ACADEMIC_RECORD_STATUSES_MAP.get(status);
};

export const translateAcademicRecordEventStatus = (status: string): string =>
  ACADEMIC_RECORD_EVENTS_TRANSLATIONS.get(status) || status;
