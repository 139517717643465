import {
  DatePicker,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
  Space,
  Typography
} from 'antd';
import { FormItem } from './FormItem';
import { IProfessor } from 'interfaces/professor';
import { useSelect } from '@refinedev/antd';

interface CreateClassroomModalProps {
  modalProps: ModalProps;
  formProps: FormProps;
}

export const CreateClassroomModal = ({
  modalProps,
  formProps
}: CreateClassroomModalProps) => {
  const { selectProps: professorSelectProps } = useSelect<IProfessor>({
    resource: 'professors',
    optionLabel: (item: IProfessor) => `${item.name} - (${item.email})`,
    optionValue: 'id',
    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ]
  });

  return (
    <Modal
      {...modalProps}
      title="Nova aula interativa"
      okText="Agendar aula"
      cancelText="Voltar"
    >
      <Form {...formProps}>
        <FormItem
          name="title"
          label="Título da aula"
          rules={[{ required: true, message: 'O título é obrigatório' }]}
        >
          <Input />
        </FormItem>

        <FormItem
          name="description"
          label="Descrição"
          labelCol={{ className: 'w-[150px] p-2' }}
        >
          <Input.TextArea placeholder="Adicionar descrição" />
        </FormItem>

        <Space className="flex !items-start" direction="horizontal">
          <FormItem
            name="start_time"
            label="Data (GMT-03:00)"
            rules={[{ required: true, message: 'A data é obrigatória' }]}
          >
            <DatePicker
              showTime
              format={'DD/MM/YYYY HH:mm'}
              placeholder="dd/mm/aaaa hh:mm"
            />
          </FormItem>
          <Space className="mt-1">
            <Typography.Text className="ml-2 !text-[#00000073]">
              Agendar com 24 horas de antecedência
            </Typography.Text>
          </Space>
        </Space>

        <FormItem
          label="Duração"
          name="title"
          rules={[{ required: true, message: 'A duração é obrigatória' }]}
        >
          <Select>
            <Select.Option value={30}>30 minutos</Select.Option>
            <Select.Option value={60}>1 hora</Select.Option>
            <Select.Option value={90}>1 hora e 30 minutos</Select.Option>
            <Select.Option value={120}>2 horas</Select.Option>
            <Select.Option value={150}>2 horas e 30 minutos</Select.Option>
            <Select.Option value={180}>3 horas</Select.Option>
            <Select.Option value={210}>3 horas e 30 minutos</Select.Option>
            <Select.Option value={240}>4 horas</Select.Option>
            <Select.Option value={270}>4 horas e 30 minutos</Select.Option>
            <Select.Option value={300}>5 horas</Select.Option>
            <Select.Option value={330}>5 horas e 30 minutos</Select.Option>
            <Select.Option value={360}>6 horas</Select.Option>
            <Select.Option value={390}>6 horas e 30 minutos</Select.Option>
            <Select.Option value={420}>7 horas</Select.Option>
            <Select.Option value={450}>7 horas e 30 minutos</Select.Option>
            <Select.Option value={480}>8 horas</Select.Option>
            <Select.Option value={510}>8 horas e 30 minutos</Select.Option>
            <Select.Option value={540}>9 horas</Select.Option>
            <Select.Option value={570}>9 horas e 30 minutos</Select.Option>
            <Select.Option value={600}>10 horas</Select.Option>
            <Select.Option value={630}>10 horas e 30 minutos</Select.Option>
            <Select.Option value={660}>11 horas</Select.Option>
            <Select.Option value={690}>11 horas e 30 minutos</Select.Option>
            <Select.Option value={720}>12 horas</Select.Option>
          </Select>
        </FormItem>

        <FormItem
          name="professor_ids"
          label="Professor"
          rules={[{ required: true, message: 'O professor é obrigatório' }]}
          labelCol={{ className: 'w-[150px]' }}
          labelAlign="left"
        >
          <Select {...professorSelectProps} mode="multiple" />
        </FormItem>
      </Form>
    </Modal>
  );
};
