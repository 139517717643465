import CoreUpload from 'components/upload/CoreUpload';
import React, { useMemo } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { CoreKind } from 'components/upload/UseCoreUpload';
import { ICoreContentOption } from 'interfaces/core_content';
import { IDataContent } from 'interfaces/courses';
import { IProviderOption, PROVIDER_OPTIONS } from './constants';
import { PrometheusVideoSelect } from 'components/prometheus/PrometheusVideoSelect';
import { generateUUID } from 'services/uuid';
import { useCoreUploadStore } from 'store/core-upload';
import { useModalReturnType } from '@refinedev/core';

interface Props {
  modal: useModalReturnType;
  onCreate: (node: IDataContent) => void;
  onClose: () => void;
}

export const CreateContentModal: React.FC<Props> = ({
  modal,
  onClose,
  onCreate
}) => {
  const kind = useCoreUploadStore(s => s.kind);
  const provider = useCoreUploadStore(s => s.provider);
  const setState = useCoreUploadStore(s => s.setState);
  const createCore = useCoreUploadStore(s => s.create);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [form] = Form.useForm();

  const providers = useMemo<IProviderOption[] | undefined>(
    () => [...PROVIDER_OPTIONS[kind]],
    [kind]
  );

  const onCloseModal = () => {
    form.resetFields();
    setState({
      kind: 'video',
      file: null,
      provider: undefined,
      reference: undefined
    });
    modal.close();
    onClose();
  };

  const onSubmit = async () => {
    const { name, core_kind, core_provider, core_reference } =
      await form.validateFields();
    setLoading(true);
    setState({ reference: core_reference });

    try {
      const option: ICoreContentOption = await createCore(name);
      const coreId = core_kind === 'video' ? option.id : option.core_id;

      onCreate({
        name: name,
        title: name,
        core_id: coreId,
        core_kind: core_kind,
        core_reference: option.reference,
        core_provider: core_provider,
        key: generateUUID()
      });

      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Criar conteúdo"
      visible={modal.visible}
      width={1000}
      onCancel={onCloseModal}
      footer={[
        <Button key="back" onClick={onCloseModal} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item name={['core_id']} style={{ height: 0, margin: 0 }}>
          <Input type="hidden" />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Form.Item
              name={['name']}
              label="Nome"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item
              name={['core_kind']}
              label="Tipo"
              initialValue={kind}
              rules={[{ required: true }]}
            >
              <Select
                onChange={(value: CoreKind) => {
                  setState({ kind: value });
                  form.setFields([{ name: 'core_provider', value: null }]);
                  setState({ provider: undefined });
                }}
              >
                <Select.Option key="video">Vídeo</Select.Option>
                <Select.Option key="hyper_text">HTML</Select.Option>
                <Select.Option key="document">PDF</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md={4}>
            <Form.Item
              name={['core_provider']}
              label="Provedor"
              rules={[{ required: true }]}
            >
              <Select
                onChange={value => {
                  const valueToAcceptMap: Record<string, string> = {
                    cloudflare: 'video/mp4',
                    s3: 'application/pdf,application/zip'
                  };

                  if (value in valueToAcceptMap) {
                    setState({ accept: valueToAcceptMap[value] });
                  }
                  setState({ provider: value });
                }}
              >
                {providers
                  ?.filter((provider: IProviderOption) => !provider.disabled)
                  ?.map((provider: IProviderOption) => (
                    <Select.Option key={provider.value}>
                      {provider.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={16}>
            {['url', 'cloudflare_with_link'].includes(provider) && (
              <Form.Item
                name={['core_reference']}
                label="URL"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            )}

            {provider === 'prometheus' && (
              <Form.Item
                name={['core_reference']}
                label="Buscar vídeos"
                rules={[{ required: true }]}
              >
                <PrometheusVideoSelect />
              </Form.Item>
            )}

            {['s3', 'cloudflare'].includes(provider) && (
              <Form.Item name={['core_reference']} required label="Upload">
                <CoreUpload />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
