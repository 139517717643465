import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalProps, Typography } from 'antd';
import { downloadSolicitationDocument } from 'services/equivalences';

interface Props extends ModalProps {
  bodyTitle: string;
  documentId: string;
  onUpdate?: () => void;
}

const EquivalencyDocumentModal: React.FC<Props> = ({
  bodyTitle,
  documentId,
  ...modalProps
}) => {
  const [fileUrl, setFileUrl] = useState<string>('');
  const getFile = useCallback(async () => {
    if (documentId) {
      const fileData = await downloadSolicitationDocument(documentId);
      setFileUrl(fileData.url);
    }
    return null;
  }, [documentId]);

  useEffect(() => {
    getFile();
  }, [documentId, getFile]);

  return (
    <Modal footer={null} {...modalProps} width={900}>
      <Typography.Title level={5} className="mb-2 font-normal">
        {bodyTitle}
      </Typography.Title>

      {!fileUrl ? (
        'Nenhum documento disponível'
      ) : (
        <iframe
          className="w-full h-full sm:min-h-[calc(100vh-210px)]"
          src={fileUrl}
        />
      )}
    </Modal>
  );
};

export default EquivalencyDocumentModal;
