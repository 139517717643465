import { CSSProperties } from 'react';

export const antLayoutSider: CSSProperties = {
  position: 'sticky',
  left: 0,
  top: 0,
  height: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden'
};

export const antLayoutSiderMobile: CSSProperties = {
  position: 'fixed',
  height: '100vh',
  zIndex: 999,
  overflowY: 'scroll',
  overflowX: 'hidden'
};

export const antMenuRoot: CSSProperties = {
  position: 'relative',
  paddingBottom: 60,
  background: 'transparent',
  color: '#ffffff'
};
