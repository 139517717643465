import { Radio, RadioChangeEvent } from 'antd';
import { Stack } from '@qcx/ui';

const discountTypes = [
  {
    key: 'product_id',
    label: 'Produto'
  },
  {
    key: 'invoice_due',
    label: 'Tempo de inadimplência'
  }
];

export const SetDiscountRuleType = ({
  onChange,
  defaultValue
}: {
  onChange: (value: string) => void;
  defaultValue?: string;
}) => {
  const onRadioChange = (e: RadioChangeEvent) => {
    onChange?.(e.target.value);
  };

  return (
    <Stack css={{ d: 'flex', flexDir: 'column', w: '$full', gap: '$10' }}>
      <Stack
        css={{
          w: '$full',
          alignItems: 'center',
          gap: '$3'
          //  justifyContent: 'space-between'
        }}
      >
        <h3 className="m-0 font-semibold text-base leading-6 text-[rgba(0,0,0,0.85)]">
          Desconto por
        </h3>
        <Radio.Group onChange={onRadioChange} defaultValue={defaultValue}>
          {discountTypes.map(discount => {
            return (
              <Radio.Button key={discount.label} value={discount.key}>
                {discount.label}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </Stack>
    </Stack>
  );
};
