import { Card, Col, Progress, Row, Tabs, Tooltip } from 'antd';
import { ICourseEnrollment } from 'interfaces/course_enrollments';
import { RefreshButton, Show } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { TabEvaluations } from './components/TabEvaluations/TabEvaluations';
import { TabSummary } from './components/TabSummary';
import { useParams } from 'react-router-dom';
import { useShow } from '@refinedev/core';

export const CourseEnrollmentSHow = () => {
  const params = useParams();
  const enrollmentId = params.id;

  const { queryResult } = useShow<ICourseEnrollment>({
    resource: 'courses/enrollments',
    id: enrollmentId,
    errorNotification: {
      type: 'error',
      message: 'Erro ao carregar dados. Tente novamente!'
    }
  });

  const result = queryResult?.data?.data as ICourseEnrollment | undefined;

  if (!result) {
    return null;
  }

  return (
    <Show
      title="Visualizar matrícula de curso livre"
      contentProps={{ className: '[&>div:bg-red-200' }}
      isLoading={queryResult.isLoading}
      headerButtons={<RefreshButton />}
    >
      <Card size="small" className="!mb-6">
        <Row>
          <Col xs={24} md={13}>
            <ShowFieldData label="Curso" value={result?.course.name} />
          </Col>
          <Col xs={24} md={8}>
            <ShowFieldData label="Aluno" value={result?.user.name} />
          </Col>
          <Col xs={24} md={3}>
            <ShowFieldData
              label="Progresso"
              value={
                <Tooltip title={`${result?.progress_percentage}%`}>
                  <Progress
                    size="small"
                    showInfo={false}
                    percent={result?.progress_percentage}
                  />
                </Tooltip>
              }
            />
          </Col>
        </Row>
      </Card>

      <Tabs defaultActiveKey="summary">
        <Tabs.TabPane tab="Resumo" key="summary">
          <TabSummary enrollment={result} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Atividades e notas" key="evaluations">
          <TabEvaluations courseEnrollmentId={result?.id} />
        </Tabs.TabPane>
      </Tabs>
    </Show>
  );
};
