import { Box, Stack, Text } from '@qcx/ui';
import { Create, useForm } from '@refinedev/antd';
import { Divider, Form, Input, InputNumber, Select } from 'antd';
import { IMoney } from 'interfaces/money';
import { IOrderPaymentPlansDetail } from 'interfaces/orders';
import { formatMoney } from 'services/money';
import { translateKind } from 'services/payments';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParsed } from '@refinedev/core';

// const INSTALLMENTS = [
//   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
//   23, 24
// ];
const INSTALLMENTS = [1];

export const OrderPaymentPlanDeal = () => {
  const { params } = useParsed<Record<string, string>>();
  const { id } = params as { id: string };

  const location = useLocation();
  const { form, formProps, saveButtonProps } =
    useForm<IOrderPaymentPlansDetail>({
      resource: `payment_plans/${id}/deal`,
      redirect: false,
      onMutationSuccess: () => {
        window.location.pathname = `deal/payment_plans/show/${id}`;
      }
    });

  useEffect(() => {
    if (location.state?.planDealData) {
      form.setFieldsValue(location.state.planDealData);
      // Force 1 installment only for now
      form.setFieldValue('installments', 1);
    }
  }, [location.state, form]);

  const hasPlanData = location.state?.initialPlanData;

  const initialPlansValues = {
    debitInvoicesCount: hasPlanData ? hasPlanData.debitInvoicesCount : '-',
    debitAmount: hasPlanData
      ? formatMoney(hasPlanData.debitAmount as IMoney)
      : '-',
    paymentKind: hasPlanData ? translateKind(hasPlanData.paymentKind) : '-',
    totalInvoices: hasPlanData ? hasPlanData.totalInvoices : '-',
    selectedInstallments: hasPlanData ? hasPlanData.selectedInstallments : '-'
  };

  const validInstallments = (
    _rule: unknown,
    value: number,
    callback: (t?: string) => void
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formValues = form.getFieldsValue() as Record<string, any>;

    if (value && formValues['amount']?.['cents'] / value > 500) {
      callback();
    } else {
      callback('Essa opção de parcelamento está indisponível');
    }
  };

  return (
    <Create
      title="Renegociação de Inadimplência"
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Stack
          css={{
            gap: '$6',
            flexWrap: 'wrap',
            '@lg': { flexWrap: 'nowrap' }
          }}
        >
          <Stack
            flow="column"
            css={{
              rounded: '$lg',
              bgColor: '$neutral100',
              p: '$6',
              gap: '$1',
              justifyContent: 'space-between',
              w: '$full'
            }}
          >
            <Box>
              <Stack
                css={{
                  whiteSpace: 'nowrap',
                  justifyContent: 'space-between',
                  gap: '$4'
                }}
              >
                <Text
                  variant="md"
                  css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                >
                  Valor inadimplente inicial
                </Text>
                <Text
                  weight="semibold"
                  variant="md"
                  css={{ whiteSpace: 'initial' }}
                >
                  {initialPlansValues.debitAmount}
                </Text>
              </Stack>
              <Stack
                css={{
                  whiteSpace: 'nowrap',
                  justifyContent: 'space-between',
                  gap: '$4'
                }}
              >
                <Text
                  variant="md"
                  css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                >
                  Total parcelas renegociadas
                </Text>
                <Text weight="semibold" variant="md" css={{ d: 'block' }}>
                  {initialPlansValues.debitInvoicesCount}
                </Text>
              </Stack>
              <Divider />
              <Stack
                css={{
                  whiteSpace: 'nowrap',
                  justifyContent: 'space-between',
                  gap: '$4'
                }}
              >
                <Text
                  variant="md"
                  css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                >
                  Forma de pagamento original
                </Text>
                <Text
                  weight="semibold"
                  variant="md"
                  css={{ whiteSpace: 'initial' }}
                >
                  {initialPlansValues.paymentKind}
                </Text>
              </Stack>
              <Stack
                css={{
                  whiteSpace: 'nowrap',
                  justifyContent: 'space-between',
                  gap: '$4'
                }}
              >
                <Text
                  variant="md"
                  css={{ d: 'block', color: '$textMuted', mb: '$2' }}
                >
                  Parcelas negociadas
                </Text>
                <Text weight="semibold" variant="md" css={{ d: 'block' }}>
                  {initialPlansValues.selectedInstallments}
                </Text>
              </Stack>
            </Box>
          </Stack>
          <div className="border rounded-lg p-6">
            <Box css={{ w: '100%', '@lg': { minW: '350px' } }}>
              <Form.Item
                label="Valor da negociação"
                name={['amount', 'cents']}
                required
              >
                <InputNumber
                  addonBefore="R$"
                  formatter={value => formatCents(value as number)}
                  parser={value => parseCents(value as string)}
                  step={0.01}
                  precision={2}
                  min={0}
                  style={{ width: '100%' }}
                  required
                />
              </Form.Item>
              <Form.Item
                label="Formato da negociação"
                name={['amount', 'currency_iso']}
                required
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Forma de pagamento"
                name={['preferences', 'kind']}
                required
              >
                <Select>
                  <Select.Option key="boleto" value="boleto">
                    Boleto
                  </Select.Option>
                  {/* <Select.Option key="pix" value="pix">
                    Pix
              </Select.Option> */}
                </Select>
              </Form.Item>
              <Form.Item
                label="Parcelamento"
                name={['installments']}
                rules={[{ validator: validInstallments }]}
                required
              >
                <Select disabled={true}>
                  {INSTALLMENTS.map(value => (
                    <Select.Option key={value} value={value}>
                      {value} parcela(s)
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Split de gateways"
                name={['preferences', 'gateway_config']}
                style={{ display: 'none' }}
                required
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Split de gateways"
                name={['preferences', 'installments']}
                style={{ display: 'none' }}
                required
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Invoices negociadas"
                name={['invoice_ids']}
                style={{ display: 'none' }}
                required
              >
                <Input />
              </Form.Item>
            </Box>
          </div>
          <div
            className="border rounded-lg p-6 w-full"
            style={{ maxW: '350px' }}
          >
            <Box css={{ w: '100%' }}>
              <Stack flow="column" css={{ gap: '$2', w: '$full' }}>
                <Form.Item
                  label="Email do aluno"
                  name={['preferences', 'info', 'payer', 'email']}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Nome do aluno"
                  name={['preferences', 'info', 'payer', 'name']}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="CPF"
                  name={['preferences', 'info', 'payer', 'document', 'code']}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Tipo de documento"
                  style={{ display: 'none' }}
                  name={['preferences', 'info', 'payer', 'document', 'type']}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="CEP"
                  name={['preferences', 'info', 'payer', 'address', 'zip_code']}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Logadouro"
                  name={['preferences', 'info', 'payer', 'address', 'street']}
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Número"
                  name={[
                    'preferences',
                    'info',
                    'payer',
                    'address',
                    'street_number'
                  ]}
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
              </Stack>
              <Stack css={{ gap: '$2', w: '$full', flexWrap: 'wrap' }}>
                <Form.Item
                  label="Complemento"
                  name={[
                    'preferences',
                    'info',
                    'payer',
                    'address',
                    'complement'
                  ]}
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Bairro"
                  name={[
                    'preferences',
                    'info',
                    'payer',
                    'address',
                    'neighborhood'
                  ]}
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="País"
                  name={['preferences', 'info', 'payer', 'address', 'country']}
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
              </Stack>
              <Stack css={{ gap: '$2', w: '$full', flexWrap: 'wrap' }}>
                <Form.Item
                  label="Cidade"
                  name={[
                    'preferences',
                    'info',
                    'payer',
                    'address',
                    'city',
                    'name'
                  ]}
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Código da cidade"
                  name={[
                    'preferences',
                    'info',
                    'payer',
                    'address',
                    'city',
                    'code'
                  ]}
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Estado"
                  name={[
                    'preferences',
                    'info',
                    'payer',
                    'address',
                    'state',
                    'name'
                  ]}
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Código do estado"
                  name={[
                    'preferences',
                    'info',
                    'payer',
                    'address',
                    'state',
                    'code'
                  ]}
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="UF"
                  name={[
                    'preferences',
                    'info',
                    'payer',
                    'address',
                    'state',
                    'uf'
                  ]}
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
              </Stack>
            </Box>
          </div>
        </Stack>
      </Form>
    </Create>
  );
};

const formatCents = (value: number) => {
  if (value) {
    return (value / 100).toFixed(2);
  }
  return '0';
};

const parseCents = (value: string | undefined): number => {
  if (value) {
    return Math.round(Number.parseFloat(value) * 100);
  }
  return 0;
};
