import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Form, Input, Modal, Typography } from 'antd';
import { ICourseRequirement, ICourseShow, IDataNode } from 'interfaces/courses';
import { IRequirement } from 'interfaces/requirements';
import {
  RequirementKind,
  SelectRequirement
} from '../form-items/SelectRequirement';
import { createRequirement, updateRequirement } from 'requests/requirements';
import { getRequirementWeeks, weeksToMinutes } from 'services/date';
import { useModalReturnType } from '@refinedev/core';

interface Props {
  course: ICourseShow;
  modal: useModalReturnType;
  node: IDataNode;
  onUpdate: (node: IDataNode, requirement?: ICourseRequirement) => void;
  onCloseModal: () => void;
}

const CourseNodeModal: React.FC<Props> = ({
  course,
  node,
  modal,
  onUpdate,
  onCloseModal
}) => {
  const [form] = Form.useForm();
  const [requirementKind, setRequirementKind] =
    useState<RequirementKind>('week');
  const [requirement, setRequirement] = useState<IRequirement | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requirements = course.requirements.filter(
      requirement => requirement.resource_id === node.key.toString()
    );

    if (requirements.length > 0 && requirements[0].rules.length > 0) {
      const rule = requirements[0].rules[requirements[0].rules.length - 1];

      const requirementWeeks = getRequirementWeeks(rule?.value);

      if (rule?.kind === 'duration_minutes') {
        setRequirementKind('week');
        form.setFieldsValue({ duration: requirementWeeks });
      }

      if (rule?.kind === 'date') {
        const date = dayjs(rule?.value);
        setRequirementKind('date');
        form.setFieldsValue({ duration: date });
      }

      setRequirement(requirements[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    onCloseModal();
    modal.close();
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const { name: title, duration } = await form.validateFields();
      let selectedDuration: number | string = '';

      const kind = requirementKind === 'date' ? 'date' : 'duration_minutes';

      const updatedNode = {
        ...node,
        title: title
      };

      if (duration === '') {
        onUpdate(updatedNode);
        return;
      }

      if (requirementKind === 'date') {
        selectedDuration = duration.toISOString();
      }

      if (requirementKind === 'week') {
        selectedDuration = weeksToMinutes(duration);
      }
      const createOrUpdateRequirement = requirement?.id
        ? updateRequirement({
            requirementId: requirement.id,
            courseId: course.id,
            nodeId: node.key.toString(),
            duration: selectedDuration,
            key: kind === 'date' ? 'current_date' : 'possession_date',
            kind
          })
        : createRequirement({
            courseId: course.id,
            nodeId: node.key.toString(),
            duration: selectedDuration,
            key: kind === 'date' ? 'current_date' : 'possession_date',
            kind
          });

      createOrUpdateRequirement.then(res => {
        onUpdate(updatedNode, res);
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleInputChange = useCallback(
    (value: RequirementKind) => {
      setRequirementKind(value);
      form.setFieldsValue({ duration: '' });
    },
    [form]
  );

  return (
    <Modal
      title="Editar seção"
      width={1000}
      visible={modal.visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={form.submit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form
        form={form}
        initialValues={{
          name: node.title
        }}
        layout="vertical"
        preserve={false}
        onFinish={onSubmit}
      >
        <Form.Item label="Nome" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Typography.Title level={5} className="pb-2">
          Regra de liberação
        </Typography.Title>

        <SelectRequirement
          kind={requirementKind}
          defaultValue={0}
          onSelect={handleInputChange}
        />
      </Form>
    </Modal>
  );
};

export default CourseNodeModal;
