import { IGateway } from 'interfaces/gateways';
import { IMoney } from 'interfaces/money';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Title, Text, Paragraph } = Typography;

interface DefaultProps {
  value: string;
}

interface MarketplaceFeeProps {
  value: {
    amount?: IMoney;
    percentage?: number;
  };
}

const defaultRender = (key: string) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const Default = ({ value }: DefaultProps) => (
    <>
      <Title level={5}>{key}</Title>
      <Text>{value}</Text>
    </>
  );

  return Default;
};

const GatewayId = ({ value }: DefaultProps) => {
  const { queryResult } = useShow<IGateway>({
    resource: 'hermes/gateways',
    id: value
  });

  const gateway = queryResult?.data?.data;

  return (
    <>
      <Title level={5}>Gateway</Title>
      <Text>
        {gateway?.name} - {gateway?.id}
      </Text>
    </>
  );
};

const MarketplaceFee = ({
  value: { amount, percentage }
}: MarketplaceFeeProps) => (
  <>
    <Title level={5}>Marketplace Fee</Title>
    {amount && <Paragraph>R${amount.cents / 100}</Paragraph>}
    {percentage && <Paragraph>{percentage}%</Paragraph>}
  </>
);

export const RuleConfig = {
  defaultRender,
  GatewayId,
  MarketplaceFee
};

export declare type ElementProps = DefaultProps | MarketplaceFeeProps;
