import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IGraduateCourse, IGraduateModule } from 'interfaces/graduate_courses';
import { useMemo } from 'react';

export const GraduateModuleCreate = () => {
  const { formProps, saveButtonProps } = useForm<IGraduateModule>({
    redirect: 'edit'
  });
  const { selectProps: modulesSelectProps, queryResult: courseQueryResult } =
    useSelect<IGraduateCourse>({
      resource: 'graduate_courses',
      optionLabel: 'name',
      optionValue: 'id',

      onSearch: value => [
        {
          field: 'filter[q]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    });

  const courses = useMemo(() => {
    return courseQueryResult?.data?.data.map(course => {
      const { name, id, external_reference } = course;

      const codExternal = external_reference ? `${external_reference} - ` : '';

      const label = `${codExternal} ${name}`;

      return {
        label,
        value: id
      };
    });
  }, [courseQueryResult]);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Curso" name="course_id" required>
          <Select {...modulesSelectProps} options={courses} />
        </Form.Item>
      </Form>
    </Create>
  );
};
