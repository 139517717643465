import { AttemptsTable } from './AttemptsTable';
import {
  IEvaluationsWithAttemptsData,
  fetchEvaluations
} from 'services/graduate-enrollment/evaluations';
import { Spin, Table } from 'antd';
import { TextField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { getEvaluationPeriod } from 'services/classroom/evaluations';
import { useCallback, useEffect, useState } from 'react';
import { useNotification } from '@refinedev/core';
import type {
  EvaluationKind,
  IClassroomEvaluation
} from 'interfaces/classrooms';

interface EvaluationItemProps {
  openedClassroomId: string;
  classroomEnrollmentId: string;
}

const EVALUATION_KIND_MAP: Record<EvaluationKind, string> = {
  objective: 'Objetiva',
  discursive: 'Discursiva',
  assignment: 'Atividade'
};

export const EvaluationsTable = ({
  openedClassroomId,
  classroomEnrollmentId
}: EvaluationItemProps) => {
  const [evaluations, setEvaluations] = useState<
    IEvaluationsWithAttemptsData[]
  >([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { open } = useNotification();

  const getEvaluations = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await fetchEvaluations(
        openedClassroomId,
        classroomEnrollmentId
      );

      return setEvaluations(res);
    } catch {
      open?.({
        type: 'error',
        message: 'Erro ao recuperar avaliações.',
        description: 'Ops!'
      });
    } finally {
      setIsLoading(false);
    }
  }, [openedClassroomId, open, classroomEnrollmentId]);

  useEffect(() => {
    getEvaluations();
  }, [getEvaluations]);

  const onTableRowExpand = (
    expanded: boolean,
    record: IClassroomEvaluation
  ) => {
    const keys = [];

    if (expanded) {
      keys.push(record.id);
    }

    setExpandedRowKeys(keys);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-16">
        <Spin />
      </div>
    );
  }

  return (
    <Table
      dataSource={evaluations}
      className="w-full"
      size="small"
      style={{ borderRadius: 10, overflow: 'hidden' }}
      rowKey="id"
      pagination={false}
      expandable={{
        expandedRowKeys: expandedRowKeys,
        onExpand: onTableRowExpand,
        expandedRowRender: record => (
          <AttemptsTable
            attempts={record.attempts}
            evaluationType={record.evaluation_type}
          />
        ),
        rowExpandable: record => record.attempts?.length > 0
      }}
    >
      <Table.Column
        title="Atividade"
        dataIndex="name"
        key="name"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        title="Modelo"
        dataIndex="kind"
        key="kind"
        render={value => (
          <TextField
            value={
              value === 'regular'
                ? 'Avaliação'
                : value === 'replacement'
                ? 'Substitutiva'
                : 'Recuperação'
            }
          />
        )}
      />
      <Table.Column
        title="Tipo"
        dataIndex="evaluationKind"
        key="evaluationKind"
        render={(value: EvaluationKind) => (
          <TextField value={EVALUATION_KIND_MAP[value]} />
        )}
      />
      <Table.Column
        title="Período de avaliação"
        dataIndex="requirements"
        key="requirements"
        render={value => {
          const { dateRange } = getEvaluationPeriod(value);
          return (
            <TextField
              value={`${formatDate(
                dateRange[0] as string,
                'DD/MM/YYYY'
              )} - ${formatDate(dateRange[1] as string, 'DD/MM/YYYY')}`}
            />
          );
        }}
      />
      <Table.Column
        title="Tentativas"
        dataIndex="attempts"
        key="attempts"
        align="center"
        render={value => <TextField value={value?.length || '-'} />}
      />
      <Table.Column
        title="Peso"
        dataIndex="weight"
        key="weight"
        align="center"
        render={value => <TextField value={value || '-'} />}
      />
      <Table.Column
        title="Nota mínima"
        dataIndex="passing_score"
        key="passing_score"
        align="center"
        render={value => <TextField value={value || '-'} />}
      />
      <Table.Column
        title="Nota final"
        dataIndex="score"
        key="score"
        align="center"
        className="w-[100px]"
        render={value => (
          <TextField value={value && value > 0 ? value : '0' || '-'} />
        )}
      />
    </Table>
  );
};
