import clsx from 'clsx';
import { Avatar, Button, Dropdown, Space, Typography } from 'antd';
import { BaseKey } from '@refinedev/core';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  // DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  PlayCircleOutlined
} from '@ant-design/icons';
import { MenuProps } from 'antd/lib';
import { TagField } from '@refinedev/antd';
import { useState } from 'react';

// const finishedDropdownItems: MenuProps['items'] = [
//   {
//     key: '1',
//     label: (
//       <Button
//         className="!text-[#000000E0]"
//         type="link"
//         icon={<EyeOutlined className="!text-[#00000073]" />}
//       >
//         Ver dados da aula
//       </Button>
//     )
//   },
//   {
//     key: '2',
//     label: (
//       <Button
//         className="!text-[#000000E0]"
//         type="link"
//         icon={<PlayCircleOutlined className="!text-[#00000073]" />}
//       >
//         Assistir aula gravada
//       </Button>
//     )
//   },
//   {
//     key: '3',
//     label: (
//       <Button
//         className="!text-[#000000E0]"
//         type="link"
//         icon={<DownloadOutlined className="!text-[#00000073]" />}
//       >
//         Baixar relatório de presença
//       </Button>
//     )
//   }
// ];
export interface TableRowItemProps {
  showModal: (id?: BaseKey) => void;
}

export const TableRowItem = ({ showModal }: TableRowItemProps) => {
  const [hovered, setHover] = useState(false);
  const defaultDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<PlayCircleOutlined className="!text-[#00000073]" />}
        >
          Entrar na sala
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<EyeOutlined className="!text-[#00000073]" />}
          onClick={() => showModal('476801ea-9fb6-4512-83fb-3b7d31395b35')}
        >
          Ver dados da aula
        </Button>
      )
    },
    {
      key: '3',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<EditOutlined className="!text-[#00000073]" />}
        >
          Editar aula
        </Button>
      )
    },
    {
      key: '4',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<CloseCircleOutlined className="!text-[#00000073]" />}
        >
          Cancelar aula
        </Button>
      )
    }
  ];

  return (
    <Space className="group flex w-full py-4 px-2 justify-between hover:bg-neutral-100">
      <Space className="!gap-4">
        <Space
          direction="vertical"
          className="flex rounded-md w-[56px] h-[56px] p-2 bg-[#F5F5F5] justify-center items-center !gap-0"
        >
          <Typography.Text className="!m-0 !text-[#000000A6]">
            {12}
          </Typography.Text>
          <Typography.Text className="!m-0 !text-[#000000A6]">
            {'MAR'}
          </Typography.Text>
        </Space>
        <Space direction="vertical">
          <Typography.Title level={5} className="!m-0">
            {'Origem e Evolução do Direito do Trabalho'}
          </Typography.Title>
          <Space>
            <Space className="!text-[#000000A6]">
              <CalendarOutlined />
              <Typography.Text className="!m-0 !text-[#000000A6]">
                {'12/03/2024 13:00'}
              </Typography.Text>
            </Space>
            <Space className="!text-[#000000A6]">
              <ClockCircleOutlined />
              <Typography.Text className="!m-0 !text-[#000000A6]">
                {'1h'}
              </Typography.Text>
            </Space>
            <Space>
              <Avatar.Group
                size={'small'}
                maxStyle={{ color: '#000000E0', backgroundColor: '#F0F0F0' }}
                maxCount={3}
              >
                <Avatar
                  style={{ color: '#1677FF', backgroundColor: '#E6F4FF' }}
                >
                  KM
                </Avatar>
                <Avatar
                  style={{ color: '#1677FF', backgroundColor: '#E6F4FF' }}
                >
                  KS
                </Avatar>
                <Avatar
                  style={{ color: '#1677FF', backgroundColor: '#E6F4FF' }}
                >
                  KD
                </Avatar>
                <Avatar
                  style={{ color: '#1677FF', backgroundColor: '#E6F4FF' }}
                >
                  KF
                </Avatar>
              </Avatar.Group>
            </Space>
          </Space>
        </Space>
      </Space>
      <Space>
        <TagField value="Agendada" color="purple" />
        <Space>
          <Dropdown
            menu={{ items: defaultDropdownItems } as MenuProps}
            placement="bottom"
            onOpenChange={setHover}
            className={clsx(
              'opacity-0 group-hover:opacity-100 group-active:opacity-100',
              hovered && 'opacity-100'
            )}
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        </Space>
      </Space>
    </Space>
  );
};
