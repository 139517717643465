import { UserRow } from 'interfaces/bulk_users';

export const parseFile = async (file: File): Promise<UserRow[]> => {
  const csv = await file.text();

  const [headerRow, ...body] = csv.split('\n').filter(row => row.trim() !== '');

  const headers = headerRow.trim().split(',');
  const namePosition = headers.indexOf('Nome');
  const emailPosition = headers.indexOf('Email');
  const passwordPosition = headers.indexOf('Senha');
  const phoneNumberPosition = headers.indexOf('Telefone');

  return body.map((line: string) => {
    const values = line.trim().split(',');

    return {
      name: values[namePosition],
      email: values[emailPosition],
      password: values[passwordPosition],
      phone_number: values[phoneNumberPosition]
    };
  });
};
