export * from './list';
export * from './show';

const SOLICITATION_STATUS_MAP = {
  submitted: 'Submetida',
  approved: 'Aprovada',
  rejected: 'Rejeitada',
  canceled: 'Cancelada',
  validating: 'Validando',
  unknown: 'Desconhecido'
};

export const translateSolicitationStatus = (text: string): string => {
  const statusKey = text as keyof typeof SOLICITATION_STATUS_MAP;
  return (
    SOLICITATION_STATUS_MAP[statusKey] ?? SOLICITATION_STATUS_MAP['unknown']
  );
};
