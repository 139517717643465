import PrometheusUpload from 'components/PrometheusUpload';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { IDamVideo } from 'interfaces/dam_videos';

export const VideoCreate = () => {
  const { formProps, saveButtonProps } = useForm<IDamVideo>({
    resource: 'prometheus/videos',
    redirect: 'edit',
    action: 'create'
  });

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar vídeo">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Arquivo de video"
          name="file_id"
          rules={[
            {
              required: true
            }
          ]}
        >
          <PrometheusUpload accept="video/mp4" />
        </Form.Item>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
