import FilterForm from '../../../components/forms/FilterForm';
import React from 'react';
import { Col, Form, Input, Row, Select, Space, Table, Tag } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IDamVideo } from 'interfaces/dam_videos';
import { ITag } from 'interfaces/epimetheus/tags';
import { IUser } from 'interfaces/users';
import { List, ShowButton, TextField, useTable } from '@refinedev/antd';
import { StatusMap } from 'services/prometheus';
import { formatDate } from 'services/date';

interface IFilter {
  title: string;
  status: string;
}

export const VideoList = () => {
  const { tableProps, searchFormProps } = useTable<
    IDamVideo,
    HttpError,
    IFilter
  >({
    resource: 'prometheus/videos',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { title, status } = params;

      filters.push(
        {
          field: 'filter[q]',
          operator: 'eq',
          value: title
        },
        {
          field: 'filter[status]',
          operator: 'eq',
          value: status
        }
      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List title="Vídeos">
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Titulo" name="title">
              <Input placeholder="Titulo do video..." />
            </Form.Item>

            <Form.Item label="Status" name="status">
              <Select placeholder="Selecione..." style={{ minWidth: 200 }}>
                <Select.Option key="" selected>
                  Todos
                </Select.Option>
                <Select.Option key="published">Publicado</Select.Option>
                <Select.Option key="unpublished">Não Publicado</Select.Option>
              </Select>
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="title"
              key="title"
              title="Titulo"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="tags"
              key="tags"
              title="Tags"
              render={value =>
                value.map((tag: ITag) => <Tag key={tag.id}>{tag.name}</Tag>)
              }
            />

            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={value => (
                <Tag color={value !== 'published' ? 'error' : 'success'}>
                  {StatusMap[value]}
                </Tag>
              )}
            />

            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Data de registro"
              render={value => <TextField value={formatDate(value)} />}
            />
            <Table.Column<IUser>
              title="Ações"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
