import FilterForm from '../../components/forms/FilterForm';
import { Col, Form, Input, Row, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  EditButton,
  List,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { ICourse } from 'interfaces/courses';
import { IInstructor } from 'interfaces/instructors';

interface IFilter {
  name: string;
}

export const CourseList = () => {
  const { tableProps, searchFormProps } = useTable<ICourse, HttpError, IFilter>(
    {
      onSearch: params => {
        const filters: CrudFilters = [];
        const { name } = params;

        filters.push({
          field: 'filter[name]',
          operator: 'eq',
          value: name
        });

        return filters;
      }
    }
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Nome" name="name">
              <Input placeholder="Nome do curso..." />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="duration_in_hours"
              key="duration_in_hours"
              title="Duração (horas)"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="instructors"
              key="instructors"
              title="Instrutores"
              render={instructors =>
                instructors.map((instructor: IInstructor) => (
                  <TagField key={instructor.id} value={instructor.name} />
                ))
              }
            />
            <Table.Column<ICourse>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
