import * as Icons from '@ant-design/icons';
import React from 'react';
import { Button, Collapse, Table, Typography } from 'antd';
import { IDocument } from 'interfaces/documentation/document';
import { TagField, TextField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import {
  getStatusColor,
  translateDocumentationStatus
} from '../../../../services/documentations';
import { useDocumentationStore } from '../documentation-store';

const { Text } = Typography;

interface DocumentItemHistoryProps {
  documents: IDocument[];
}

export const DocumentItemHistory = ({
  documents
}: DocumentItemHistoryProps) => {
  const openModal = useDocumentationStore(s => s.openModal);

  return (
    <div className="mt-6">
      <Collapse
        bordered={false}
        style={{ backgroundColor: 'transparent' }}
        className="!-mx-4"
      >
        <Collapse.Panel
          header={<Text strong>Histórico de envios</Text>}
          key={1}
        >
          <Table
            dataSource={documents}
            className="documents-history w-full"
            size="small"
            style={{ borderRadius: 10, overflow: 'hidden' }}
            pagination={false}
          >
            <Table.Column
              title="Data de envio"
              className="w-[200px]"
              dataIndex="updated_at"
              key="updated_at"
              render={value => (
                <TextField value={formatDate(value, 'DD/MM/YYYY - HH:mm')} />
              )}
            />
            <Table.Column
              title="Status"
              dataIndex="status"
              className="w-[120px]"
              key="status"
              render={value => (
                <TagField
                  color={getStatusColor(value ?? 'unknown')}
                  value={translateDocumentationStatus(value ?? 'unknown')}
                />
              )}
            />
            <Table.Column
              title="Motivo"
              dataIndex="feedback"
              key="feedback"
              render={value => <TextField value={value} />}
            />
            <Table.Column<IDocument>
              title="Ações"
              align="center"
              className="w-[80px]"
              render={(_, record) => {
                return (
                  <Button
                    size="small"
                    icon={<Icons.EyeOutlined />}
                    onClick={() => openModal('show', record)}
                  />
                );
              }}
            />
          </Table>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
