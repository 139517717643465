/* eslint-disable unicorn/filename-case */
export const ecommerceIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33398 14H10.6673M8.00065 11.3333V14M4.53398 11.3333H11.4673C12.5874 11.3333 13.1475 11.3333 13.5753 11.1153C13.9516 10.9236 14.2576 10.6176 14.4493 10.2413C14.6673 9.81349 14.6673 9.25344 14.6673 8.13333V5.2C14.6673 4.0799 14.6673 3.51984 14.4493 3.09202C14.2576 2.71569 13.9516 2.40973 13.5753 2.21799C13.1475 2 12.5874 2 11.4673 2H4.53398C3.41388 2 2.85383 2 2.426 2.21799C2.04968 2.40973 1.74372 2.71569 1.55197 3.09202C1.33398 3.51984 1.33398 4.0799 1.33398 5.2V8.13333C1.33398 9.25344 1.33398 9.81349 1.55197 10.2413C1.74372 10.6176 2.04968 10.9236 2.426 11.1153C2.85383 11.3333 3.41388 11.3333 4.53398 11.3333Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
