import * as Icons from '@ant-design/icons';
import axios from 'axios';
import { Button, Progress, Upload, UploadFile } from 'antd';
import { useCreate, useNotification } from '@refinedev/core';
import { useEffect, useState } from 'react';

type File = undefined | UploadFile;
type Preview = undefined | string;

interface Props {
  accept: string | undefined;
  onChange?: (url: string | undefined) => void;
  onValidate?: (file: UploadFile) => Promise<boolean>;
  src?: Preview;
  disabled?: boolean;
}

interface Asset {
  url: string;
  upload_url: string;
  id?: string;
  options?: {
    upload_url: string;
  }[];
}

const PrometheusUpload = ({
  accept,
  onChange,
  src,
  onValidate,
  disabled
}: Props) => {
  const [file, setFile] = useState<File | null>();
  const [preview, setPreview] = useState<Preview>(src);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { mutate } = useCreate<Asset>();

  const fileName = file ? file.name : undefined;
  const previewExtension = preview ? preview.split('.').pop() : undefined;

  const { open } = useNotification();

  const createAsset = () => {
    return new Promise<Asset>((resolve, reject) => {
      mutate(
        {
          resource: `prometheus/files`,
          values: { name: fileName, content_type: 'video/mp4' }
        },
        {
          onSuccess: ({ data }) => resolve(data),
          onError: error => reject(error)
        }
      );
    });
  };

  const upload = async () => {
    try {
      const isValid = file && (await isValidFile(file));

      if (file && !isValid) {
        open?.({
          type: 'error',
          message: 'Arquivo inválido!',
          description: 'Verifique a descrição do campo',
          key: `${file.fileName}-error-upload`
        });

        setIsLoading(false);

        return;
      }

      const { id, options } = await createAsset();

      setUploadPercentage(0);
      setIsUploading(true);

      await axios
        .put(options![0].upload_url, file, {
          onUploadProgress: ({ loaded, total }) => {
            setUploadPercentage(Math.round((100 * loaded) / total));
          }
        })
        .then(() => {
          mutate({
            resource: `prometheus/files/${id}/verify`,
            values: {}
          });
        })
        .finally(() => {
          setPreview(id);
        });
    } catch {
      setIsUploading(false);
    }

    setIsLoading(false);
  };

  const isValidFile = async (file: File) => {
    if (onValidate && file) {
      return await onValidate(file);
    }

    return true;
  };

  const remove = () => {
    setPreview('');
    setFile(null);
    setUploadPercentage(0);
    setIsUploading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    if (file) {
      upload();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    setPreview(src);
  }, [src]);

  useEffect(() => {
    onChange?.(preview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview]);

  return (
    <div className="flex flex-col gap-4 w-64">
      {preview && ['png', 'jpg', 'jpeg', 'svg'].includes(previewExtension!) && (
        <img src={preview} className="w-64" alt="asset" />
      )}

      {preview && (
        <Button
          danger
          className="!flex items-center justify-center"
          disabled={isLoading || disabled}
          onClick={remove}
        >
          <Icons.CloseCircleOutlined />
          Remover
        </Button>
      )}

      <Upload
        className="w-full"
        maxCount={1}
        accept={accept}
        fileList={[]}
        onRemove={() => setFile(null)}
        beforeUpload={async newFile => {
          setFile(newFile);

          return false;
        }}
      >
        <Button
          className="!flex items-center justify-center w-full"
          disabled={isLoading || disabled}
        >
          <Icons.UploadOutlined />
          Upload
        </Button>
      </Upload>
      {isUploading && <Progress percent={uploadPercentage} />}
    </div>
  );
};

export default PrometheusUpload;
