import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IGraduateCourse, IGraduateModule } from 'interfaces/graduate_courses';
import { getSelectOptionWithDefault } from 'utils/get-select-options-with-default';
import { removeDuplicateObjectsById } from 'utils/remove-duplicate-objects';
import { useMemo } from 'react';

export const GraduateModuleEdit = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IGraduateModule>({
    redirect: 'edit'
  });

  const {
    selectProps: graduateCourseSelectProps,
    queryResult: courseQueryResult,
    defaultValueQueryResult
  } = useSelect<IGraduateCourse>({
    resource: 'graduate_courses',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: queryResult?.data?.data.course_id,

    onSearch: value => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const courses = useMemo(() => {
    const coursesWithDefault = getSelectOptionWithDefault(
      defaultValueQueryResult,
      courseQueryResult
    );

    if (coursesWithDefault) {
      const corses = removeDuplicateObjectsById(coursesWithDefault);

      return corses.map(course => {
        const { name, id, external_reference } = course;

        const codExternal = external_reference
          ? `${external_reference} - `
          : '';

        const label = `${codExternal} ${name}`;

        return {
          label,
          value: id
        };
      });
    }
  }, [courseQueryResult, defaultValueQueryResult]);

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Nome" name="name" required>
          <Input />
        </Form.Item>
        <Form.Item label="Curso" name="course_id" required>
          <Select {...graduateCourseSelectProps} options={courses} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
