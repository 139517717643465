import { IGraduateCourse } from 'interfaces/graduate_courses';
import { Table } from 'antd';
import { TextField } from '@refinedev/antd';
import type { ICourse } from 'interfaces/courses';

interface ShowTableGraduateCoursesProps {
  courses: (IGraduateCourse | ICourse)[];
}

export const ShowTableCourses = ({
  courses
}: ShowTableGraduateCoursesProps) => {
  return (
    <Table
      dataSource={courses}
      rowKey="id"
      className="w-full"
      pagination={false}
    >
      <Table.Column
        dataIndex="name"
        key="name"
        title="Curso"
        render={value => <TextField value={value} />}
      />
    </Table>
  );
};
