import dayjs from 'dayjs';

export const getYearRangeData = (startYear: number, yearsAhead: number) => {
  const currentYear = dayjs().year();
  const endYear = currentYear + yearsAhead;
  const yearArray = [];

  for (let year = startYear; year <= endYear; year++) {
    yearArray.push({ value: year, label: year });
  }
  return yearArray;
};
