import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Form, InputNumber, Select } from 'antd';

const LABEL_MAP: Record<string, string> = {
  date: 'Em qual data?',
  week: 'Em quantas semanas?'
};

export type RequirementKind = 'date' | 'week';

export interface SelectRequirementProps {
  kind: RequirementKind;
  defaultValue?: number | Dayjs;
  onSelect: (kind: RequirementKind) => void;
  onInputChange?: (value: number | Dayjs) => void;
}

export const SelectRequirement = ({
  kind,
  defaultValue,
  onSelect,
  onInputChange
}: SelectRequirementProps) => {
  return (
    <div className="flex gap-4">
      <Form.Item label="Liberação por:" initialValue={kind}>
        <Select
          defaultValue="week"
          value={kind}
          className="!w-[120px]"
          onChange={onSelect}
        >
          <Select.Option value="week">Semana(s)</Select.Option>
          <Select.Option value="date">Data</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={LABEL_MAP[kind]}
        name="duration"
        initialValue={defaultValue}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        {kind === 'date' ? (
          <DatePicker
            format="DD/MM/YYYY HH:mm"
            showTime
            onChange={value => onInputChange?.(value || dayjs())}
            defaultValue={defaultValue as Dayjs}
            value={defaultValue as Dayjs}
            allowClear
          />
        ) : (
          <InputNumber
            className="!w-full"
            placeholder={'Digite um nº'}
            onChange={onInputChange}
            value={defaultValue as number}
            defaultValue={defaultValue as number}
          />
        )}
      </Form.Item>
    </div>
  );
};
