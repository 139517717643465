import React from 'react';
import { Button, Modal } from 'antd';
import { IDataNode } from 'interfaces/courses';
import { useModalReturnType } from '@refinedev/core';

interface Props {
  modal: useModalReturnType;
  node: IDataNode;
  onUpdate: (node: IDataNode) => void;
}

const DeleteNodeModal: React.FC<Props> = ({ modal, node, onUpdate }) => {
  const onClose = () => {
    // form.resetFields();
    modal.close();
  };

  const onDelete = async () => {
    onUpdate(node);
  };

  return (
    <Modal
      title="Remover nó"
      visible={modal.visible}
      width={1000}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={onDelete}>
          Confirmar
        </Button>
      ]}
    >
      Realmente deseja remover o nó <b>{node.title as string}</b>?
    </Modal>
  );
};

export default DeleteNodeModal;
