/* eslint-disable sonarjs/cognitive-complexity */
import * as Icons from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { ICertificate } from 'interfaces/course_certificate';
import { IDocumentation } from 'interfaces/documentation/documentations';
import { PreviewCertificate } from 'components/certificates/PreviewCertificate';
import { Show } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { downloadCertificate, transformAttributes } from 'services/certificate';
import { formatDate } from 'services/date';
import { useOne, useShow } from '@refinedev/core';

export const CertificateShow = () => {
  const { queryResult } = useShow<ICertificate>();
  const certificate = queryResult?.data?.data as ICertificate | undefined;
  const { data: documentationRequest } = useOne<IDocumentation>({
    resource: 'documentations',
    id: certificate?.documentation_id
  });
  const documents = transformAttributes(documentationRequest?.data?.documents);

  return (
    <Show
      title="Visualizar certificado emitido"
      headerButtons={() => (
        <Button
          icon={<Icons.DownloadOutlined />}
          onClick={() => downloadCertificate(certificate)}
        >
          Fazer download
        </Button>
      )}
    >
      <Typography.Title level={5} style={{ margin: '0 0 24px' }}>
        Informações do aluno
      </Typography.Title>
      <Space direction="vertical" className="w-full !gap-4">
        <Row gutter={[16, 16]} className="w-full">
          <Col span={8}>
            <ShowFieldData
              label="Nome de usuário"
              value={certificate?.user.name}
            />
          </Col>
          <Col span={8}>
            <ShowFieldData label="E-mail" value={certificate?.user.email} />
          </Col>
          <Col span={8}>
            <ShowFieldData
              label="Telefone"
              value={certificate?.user.personal_info.phone_number}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col span={8}>
            <ShowFieldData label="Curso" value={certificate?.course_name} />
          </Col>
        </Row>
      </Space>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Identidade
      </Typography.Title>
      <Space direction="vertical" className="w-full !gap-4">
        <Row gutter={[16, 16]} className="w-full">
          <Col span={12}>
            <ShowFieldData
              label="Nome civil"
              value={documents['Nome completo'] ?? '--'}
            />
          </Col>
          <Col span={12}>
            <ShowFieldData
              label="CPF"
              value={documents['Número do CPF'] ?? '--'}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col span={6}>
            <ShowFieldData
              label="RG"
              value={documents['Número do RG'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="Órgão expeditor"
              value={documents['Órgão emissor'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="UF da identidade"
              value={documents['UF'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="Data de emissão"
              value={
                (documents['Data de Emissão'] &&
                  formatDate(documents['Data de Emissão'], 'DD/MM/YYYY')) ??
                '--'
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          <Col span={6}>
            <ShowFieldData
              label="Nacionalidade"
              value={documents['Nacionalidade'] ?? '--'}
            />
          </Col>
          <Col span={6}>
            <ShowFieldData
              label="Naturalidade"
              value={documents['Naturalidade'] ?? '--'}
            />
          </Col>
        </Row>
      </Space>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Certificado
      </Typography.Title>
      <Space direction="vertical" className="w-full !gap-4">
        <Row gutter={[16, 16]} className="w-full">
          <Col span={12}>
            <ShowFieldData
              label="Data de expedição"
              value={
                certificate?.issued_at
                  ? formatDate(certificate.issued_at, 'DD/MM/YYYY')
                  : '-'
              }
            />
          </Col>
          <Col span={12}>
            <ShowFieldData
              label="Data de registro"
              value={
                certificate?.registered_at
                  ? formatDate(certificate.registered_at, 'DD/MM/YYYY')
                  : '-'
              }
            />
          </Col>
        </Row>
      </Space>
      <Typography.Title level={5} style={{ margin: '24px 0' }}>
        Assinaturas
      </Typography.Title>
      <Space direction="vertical" className="w-full !gap-4">
        <Row gutter={[16, 16]} className="w-full">
          <Col span={24}>
            <ShowFieldData
              label="Modelo"
              value={certificate?.signature_partner?.name}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="w-full">
          {certificate?.signature_partner?.front_signature && (
            <Col span={8}>
              <ShowFieldData
                label="Assinatura Frente 1"
                value={certificate?.signature_partner?.front_signature?.name}
              />
            </Col>
          )}
          {certificate?.signature_partner?.back_signature && (
            <Col span={8}>
              <ShowFieldData
                label="Assinatura Verso"
                value={certificate?.signature_partner?.back_signature?.name}
              />
            </Col>
          )}
          {certificate?.signature_partner?.record_back_signature && (
            <Col span={8}>
              <ShowFieldData
                label="Assinatura Verso Registro"
                value={
                  certificate?.signature_partner?.record_back_signature?.name
                }
              />
            </Col>
          )}
        </Row>
      </Space>
      <Space
        direction="horizontal"
        className="border solid border-1 border-gray rounded-md p-4 mt-8"
      >
        {certificate?.signature_partner?.front_signature && (
          <PreviewCertificate
            type="front"
            signaturePartner={certificate?.signature_partner}
          />
        )}
        {certificate?.signature_partner?.back_signature &&
          certificate?.signature_partner?.record_back_signature && (
            <PreviewCertificate
              type="back"
              signaturePartner={certificate?.signature_partner}
            />
          )}
      </Space>
    </Show>
  );
};
