import Tree from 'components/classroom/Tree';
import { Form } from 'antd';
import { IClassroom } from '../../../interfaces/classrooms';
import { ITree } from 'interfaces/tree';
import { useState } from 'react';

export const ClassroomContent = ({
  classroom,
  onChange
}: {
  classroom: IClassroom;
  onChange?: (data: ITree) => void;
}) => {
  const [contentTree, setContentTree] = useState<ITree>(classroom.content_tree);

  const onUpdateTree = (data: ITree) => {
    setContentTree(data);
    onChange?.(data);
  };

  return (
    <>
      <Form.Item name={['content_tree']}>
        <Tree
          tree={contentTree}
          onUpdate={onUpdateTree}
          classroom={classroom}
        />
      </Form.Item>
    </>
  );
};
