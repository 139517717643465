import { Button } from 'antd';
import {
  ContractStudentsFilter,
  IContractStudentFilter
} from './StudentsFilter';
import { ContractStudentsTable } from './StudentsTable';
import { CrudFilters, HttpError, useCreate, useModal } from '@refinedev/core';
import { IContractEnrollment } from 'interfaces/contracts';
import { PlusOutlined } from '@ant-design/icons';
import { UsersModal } from 'components/modal/UsersModal';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';

export const TabContractStudents = () => {
  const { id: contractId } = useParams();
  const modal = useModal();

  const { tableProps, searchFormProps, tableQueryResult } = useTable<
    IContractEnrollment,
    HttpError,
    IContractStudentFilter
  >({
    resource: `contracts/${contractId}/enrollments`,

    onSearch: params => {
      const filters: CrudFilters = [];
      const { by_user_email, by_user_name, created_after, created_before } =
        params;

      filters.push(
        {
          field: 'filter[by_user_name]',
          operator: 'eq',
          value: by_user_name
        },
        {
          field: 'filter[by_user_email]',
          operator: 'eq',
          value: by_user_email
        },
        {
          field: 'filter[created_after]',
          operator: 'eq',
          value: created_after
        },
        {
          field: 'filter[created_before]',
          operator: 'eq',
          value: created_before
        }
      );

      return filters;
    }
  });

  const { mutateAsync, isLoading } = useCreate();

  const onAddStudent = useCallback(
    async (userId: string) => {
      try {
        return new Promise((resolve, reject) => {
          mutateAsync(
            {
              resource: `contracts/${contractId}/enrollments`,
              values: { user_id: userId },
              successNotification: () => ({
                type: 'success',
                description: 'Sucesso',
                message: 'Aluno adicionado com sucesso'
              }),
              errorNotification: () => {
                return {
                  description: 'Ops!',
                  message: 'Erro ao adicionar aluno. Tente novamente!',
                  type: 'error'
                };
              }
            },
            {
              onSuccess: ({ data }) => resolve(data),
              onError: error => reject(error.message)
            }
          );
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    [contractId, mutateAsync]
  );

  const selectedUsers = useMemo(
    () => tableQueryResult.data?.data.map(user => user.user_id),
    [tableQueryResult.data?.data]
  );

  return (
    <div className="space-y-6">
      <ContractStudentsFilter searchFormProps={searchFormProps} />

      <Button
        type="primary"
        style={{ marginTop: 30, marginLeft: 'auto', display: 'block' }}
        icon={<PlusOutlined />}
        onClick={() => modal.show()}
      >
        Novo aluno
      </Button>

      <ContractStudentsTable tableProps={tableProps} />

      {modal.visible && (
        <UsersModal
          modal={modal}
          onSubmit={onAddStudent}
          selectedUsers={selectedUsers}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
