import axios from 'axios';
import { ICoreContentOption } from 'interfaces/core_content';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  createDocument,
  createDocumentOption,
  createHyperText,
  createHyperTextOption,
  createVideo,
  createVideoOption
} from 'requests/mutation';
import { createStore } from '@qcx/store';

export type CoreKind = 'video' | 'document' | 'hyper_text';
type CoreFunc = (
  title: string
) => Promise<{ id: string; upload: { url: string } }>;

type CoreOptionFunc = (
  id: string,
  provider: string,
  reference?: string
) => Promise<ICoreContentOption>;

interface CoreObject {
  create: CoreFunc;
  createOption: CoreOptionFunc;
  accept: string;
}
type CoreMap = Record<CoreKind, CoreObject>;

export interface useCoreUploadReturnType {
  loading: boolean;
  percentage: number;
  accept: string;
  file: UploadFile | null | undefined;
  coreId: string | undefined;
  provider: string;
  reference: string;
  kind: CoreKind;
  setState: (state: Partial<useCoreUploadReturnType>) => void;
  create: (title: string) => Promise<ICoreContentOption>;
}

const INITIAL_VALUE: useCoreUploadReturnType = {
  loading: false,
  percentage: 0,
  accept: '',
  file: undefined,
  coreId: undefined,
  provider: '',
  reference: '',
  kind: 'video',
  setState: null!,
  create: null!
};

const createCoreMap: CoreMap = {
  video: {
    create: createVideo,
    createOption: createVideoOption,
    accept: 'video/mp4'
  },
  document: {
    create: createDocument,
    createOption: createDocumentOption,
    accept: 'application/pdf'
  },
  hyper_text: {
    create: createHyperText,
    createOption: createHyperTextOption,
    accept: 'application/zip'
  }
};

export const { Provider, useStore } = createStore(
  INITIAL_VALUE,
  ({ set, get }) => {
    return {
      setState: (newValue: Partial<useCoreUploadReturnType>) =>
        set(state => ({ ...state, ...newValue })),

      create: (title: string): Promise<ICoreContentOption> => {
        const { file, provider, reference, kind } = get();

        const coreMap = createCoreMap[kind];
        const createCore: CoreFunc = coreMap.create;

        async function createData(): Promise<ICoreContentOption> {
          try {
            set({ loading: false });

            const { id, upload } = await createCore(title);
            set({ coreId: id });

            if (file) {
              await axios.put(upload.url, file, {
                headers: {
                  'Content-Type': file.type
                },
                onUploadProgress: ({ loaded, total }) => {
                  const percentage = Math.round((100 * loaded) / total);
                  set({ percentage });
                }
              });
            }

            return await coreMap.createOption(id, provider, reference);
          } catch {
            throw new Error('Erro ao criar conteúdo');
          } finally {
            set({ loading: false });
          }
        }

        return createData();
      }
    };
  }
);

export const CoreUploadProvider = Provider;

export const useCoreUploadStore = useStore;
