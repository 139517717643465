import { IFiscalTemplate } from 'interfaces/fiscals';
import { IGateway, TGatewayRuleConfig } from 'interfaces/gateways';
import { IMoney } from 'interfaces/money';
import { Typography } from 'antd';
import { useShow } from '@refinedev/core';

const { Title, Text, Paragraph } = Typography;

interface Props {
  config: TGatewayRuleConfig;
}

interface GatewayProps {
  id: string;
}

interface FiscalTemplateProps {
  id: string;
}

interface MarketplaceFeeProps {
  fee: {
    amount?: IMoney;
    percentage?: number;
  };
}

const Gateway = ({ id }: GatewayProps) => {
  const { queryResult } = useShow<IGateway>({
    resource: 'hermes/gateways',
    id
  });

  const gateway = queryResult?.data?.data;

  return (
    <>
      <Title level={5}>Gateway</Title>
      <Text>
        {gateway?.name} - {id}
      </Text>
    </>
  );
};

const FiscalTemplate = ({ id }: FiscalTemplateProps) => {
  const { queryResult } = useShow<IFiscalTemplate>({
    resource: 'fiscal_templates',
    id
  });

  const fiscal_template = queryResult?.data?.data;

  return (
    <>
      <Title level={5}>Fiscal Template</Title>
      <Text>
        {fiscal_template?.name} - {id}
      </Text>
    </>
  );
};

const MarketplaceFee = ({
  fee: { amount, percentage }
}: MarketplaceFeeProps) => (
  <>
    <Title level={5}>Marketplace Fee</Title>
    {amount?.cents && <Paragraph>R${amount.cents / 100}</Paragraph>}
    {percentage && <Paragraph>{percentage}%</Paragraph>}
  </>
);

const RuleConfig = ({ config }: Props) => {
  const { gateway_id, fiscal_template_id, marketplace_fee } = config;

  return (
    <>
      {gateway_id && <Gateway id={gateway_id} />}
      {fiscal_template_id && <FiscalTemplate id={fiscal_template_id} />}
      {marketplace_fee && <MarketplaceFee fee={marketplace_fee} />}
    </>
  );
};

export default RuleConfig;
