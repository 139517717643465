import { BaseRecord, HttpError } from '@refinedev/core';
import { Create, TextField, useForm } from '@refinedev/antd';
import { Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';

interface ChangePasswordModalProps {
  modalVisibility: boolean;
  changeVisibility: (visibility: boolean) => void;
}

const ChangePasswordModal = ({
  modalVisibility,
  changeVisibility
}: ChangePasswordModalProps) => {
  const { formProps, saveButtonProps, mutationResult, form } = useForm<
    BaseRecord,
    HttpError,
    {
      new_password: string;
      current_password: string;
      password_confirmation: string;
    }
  >({
    resource: 'auth/change_password',
    action: 'create',
    redirect: false
  });

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (mutationResult.data) {
      changeVisibility(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationResult.data]);

  const validate = () => {
    const { new_password, password_confirmation } = form.getFieldsValue();
    const passwordsMatch = new_password === password_confirmation;
    setDisabled(!passwordsMatch);
  };

  return (
    <>
      <Modal
        closable
        visible={modalVisibility}
        width={600}
        onCancel={() => changeVisibility(false)}
        footer={null}
      >
        <Create
          breadcrumb={null}
          goBack={false}
          headerButtons={false}
          headerProps={{ title: 'Alterar senha' }}
          saveButtonProps={{ ...saveButtonProps, disabled }}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Senha atual"
              name="current_password"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input type="password" />
            </Form.Item>

            <Form.Item
              label="Nova senha"
              name="new_password"
              rules={[
                {
                  required: true,
                  min: 8,
                  whitespace: false
                }
              ]}
            >
              <Input type="password" />
            </Form.Item>

            <Form.Item
              label="Confirmar senha"
              name="password_confirmation"
              rules={[
                {
                  required: true,
                  min: 8,
                  whitespace: false
                }
              ]}
            >
              <Input type="password" onChange={validate} />
            </Form.Item>
          </Form>
          <TextField
            type="secondary"
            className="block text-xs mt-8"
            value="* A nova senha deve conter no mínimo 8 caracteres"
          />
        </Create>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
