import FilterForm from 'components/forms/FilterForm';
import { ASSESSMENT_KIND_OPTIONS } from 'services/assessments';
import { Col, Form, FormProps, Input, Select } from 'antd';
import { IAssessment } from 'interfaces/assessments';
import { useSelect } from '@refinedev/antd';

export interface IQuestionFilter {
  statement: string;
  assessment_id: string;
  status: string;
  by_kind: IAssessment['kind'];
}

interface QuestionFilterProps {
  searchFormProps: FormProps<IQuestionFilter>;
}

export const QuestionsFilter = ({ searchFormProps }: QuestionFilterProps) => {
  const { selectProps: assessmentSelectProps } = useSelect<IAssessment>({
    resource: 'assessments',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[full_name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <FilterForm searchFormProps={searchFormProps}>
      <Col xs={24} md={7}>
        <Form.Item label="Questão" name="statement">
          <Input placeholder="Digite o enunciado da questão" />
        </Form.Item>
      </Col>

      <Col xs={24} md={7}>
        <Form.Item label="Curso | Avaliação" name="assessment_id">
          <Select
            placeholder="Digite..."
            style={{ minWidth: 200 }}
            {...assessmentSelectProps}
          />
        </Form.Item>
      </Col>

      <Col xs={24} md={4}>
        <Form.Item label="Status" name="status">
          <Select placeholder="Selecione..." defaultValue={'active'}>
            <Select.Option value={'active'}>Ativo</Select.Option>
            <Select.Option value={'inactive'} key={'inactive'}>
              Inativo
            </Select.Option>
            <Select.Option value={'nullified'} key={'nullified'}>
              Anulado
            </Select.Option>
            <Select.Option value={null} key={'all'}>
              Todos
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24} md={4}>
        <Form.Item label="Tipo" name="by_kind" initialValue="">
          <Select
            options={[
              { label: 'Todos', value: '' },
              ...ASSESSMENT_KIND_OPTIONS
            ]}
          />
        </Form.Item>
      </Col>
    </FilterForm>
  );
};
